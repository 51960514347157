<template>
  <div :class="badgeclass">
    <slot />
  </div>
</template>



<script>
export default {
  name: "Badges",
  props: ["badgeclass"],
};
</script>
