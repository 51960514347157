<template>
    <div class="campaignreportdetail brand">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">{{campaignData.campaign_name}}</text-component>
                <div class="page-header-right">
                    <button-component 
                        @onBtnClick="generateReport" 
                        :class="'btnprimary'">
                        Generate Report
                    </button-component>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="row">
                    <!-- <div class="col-12 col-md-6 col-xl-4">
                        <text-component class="subtitle1">Brands</text-component>
                        <text-component class="left-padding">IM Nutrition</text-component>
                    </div> -->
                    <div class="col-12 col-md-6 col-xl-4">
                        <text-component class="subtitle1">Number of Influencers</text-component>
                        <text-component class="left-padding">{{campaignData.influencers}}</text-component>
                    </div>
                </div>
            </div>
            <div class="box margino margin-top">
                <div class="box-content">
                    <div class="row margino">
                        <text-component class="subtitle1 username">Social Media Insight</text-component>
                    </div>
                    <tab-component
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Instagram'">
                            <text-component class="subtitle1 username col-12">Best Performer</text-component>
                            <div class="row margino socialinsight-info" v-if="byplatform.instagram">
                                <div class="profile-detail-txt col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <img
                                            v-if="byplatform.instagram.user_profile.profile_pic"
                                            :src="byplatform.instagram.user_profile.baseUrl + '/' +byplatform.instagram.user_profile.id + '/' +byplatform.instagram.user_profile.profile_pic"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <img
                                            v-else
                                            src="../../../../assets/image/usericon.png"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <text-component class="dark left-txt txt-margin">{{byplatform.instagram.user_profile.full_name}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino" v-if="byplatform.instagram.num_views">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.instagram.num_views}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino" v-if="byplatform.instagram.num_views">
                                        <text-component class="subtitle2 paddingo col-4">Total Likes</text-component>
                                        <text-component class="dark left-txt">{{byplatform.instagram.num_likes}}</text-component>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrline"/>
                            <text-component class="subtitle1 username col-12">Campaign Stats</text-component>
                            <div class="row margino socialinsight-info">
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Audience</text-component>
                                        <text-component class="dark left-txt">{{byplatform.like}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.views}}</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Facebook'">
                            <text-component class="subtitle1 username col-12">Best Performer</text-component>
                            <div class="row margino socialinsight-info" v-if="byplatform.facebook">
                                <div class="profile-detail-txt col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <img
                                            v-if="byplatform.facebook.user_profile.profile_pic"
                                            :src="byplatform.facebook.user_profile.baseUrl + '/' +byplatform.facebook.user_profile.id + '/' +byplatform.facebook.user_profile.profile_pic"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <img
                                            v-else
                                            src="../../../../assets/image/usericon.png"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <text-component class="dark left-txt txt-margin">{{byplatform.facebook.user_profile.full_name}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino" v-if="byplatform.facebook.num_views">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.facebook.num_views}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Likes</text-component>
                                        <text-component class="dark left-txt">{{byplatform.facebook.num_likes}}</text-component>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrline"/>
                            <text-component class="subtitle1 username col-12">Campaign Stats</text-component>
                            <div class="row margino socialinsight-info">
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Audience</text-component>
                                        <text-component class="dark left-txt">{{byplatform.like}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.views}}</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Twitter'">
                            <text-component class="subtitle1 username col-12">Best Performer</text-component>
                            <div class="row margino socialinsight-info" v-if="byplatform.twitter">
                                <div class="profile-detail-txt col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <img
                                            v-if="byplatform.twitter.user_profile.profile_pic"
                                            :src="byplatform.twitter.user_profile.baseUrl + '/' +byplatform.twitter.user_profile.id + '/' +byplatform.twitter.user_profile.profile_pic"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <img
                                            v-else
                                            src="../../../../assets/image/usericon.png"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <text-component class="dark left-txt txt-margin">{{byplatform.twitter.user_profile.full_name}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino" v-if="byplatform.twitter.num_views">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.twitter.num_views}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Likes</text-component>
                                        <text-component class="dark left-txt">{{byplatform.twitter.num_likes}}</text-component>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrline"/>
                            <text-component class="subtitle1 username col-12">Campaign Stats</text-component>
                            <div class="row margino socialinsight-info">
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Audience</text-component>
                                        <text-component class="dark left-txt">{{byplatform.like}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.views}}</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Youtube'">
                            <text-component class="subtitle1 username col-12">Best Performer</text-component>
                            <div class="row margino socialinsight-info" v-if="byplatform.youtube">
                                <div class="profile-detail-txt col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <img
                                            v-if="byplatform.youtube.user_profile.profile_pic"
                                            :src="byplatform.youtube.user_profile.baseUrl + '/' +byplatform.youtube.user_profile.id + '/' +byplatform.youtube.user_profile.profile_pic"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <img
                                            v-else
                                            src="../../../../assets/image/usericon.png"
                                            class="usericon"
                                            alt="user-image"
                                        />
                                        <text-component class="dark left-txt txt-margin">{{byplatform.youtube.user_profile.full_name}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino" v-if="byplatform.youtube.num_views">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.youtube.num_views}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Likes</text-component>
                                        <text-component class="dark left-txt">{{byplatform.youtube.num_likes}}</text-component>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrline"/>
                            <text-component class="subtitle1 username col-12">Campaign Stats</text-component>
                            <div class="row margino socialinsight-info">
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Audience</text-component>
                                        <text-component class="dark left-txt">{{byplatform.like}}</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt margin-upper col-12 col-md-6 col-xl-4">
                                    <div class="row margino">
                                        <text-component class="subtitle2 paddingo col-4">Total Views</text-component>
                                        <text-component class="dark left-txt">{{byplatform.views}}</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-component>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12 col-xl-6 paddingo graph-main graphalign-left">
                    <div class="box">
                        <div class="graph-txt">
                            <text-component class="subtitle1">Followers</text-component>
                        </div>
                        <graph-component
                            :width="width"
                            :height="height"
                            :axis-min="axismin"
                            :axis-max="axismax"
                            :axisStep="axisStep"
                            :labels="labels"
                            :values="values"
                            :barMargin="barMargin"
                            :names="names">
                        </graph-component>
                    </div>
                </div>
                <div class="col-12 col-xl-6 paddingo graph-main graphalign-right">
                    <div class="box">
                        <div class="graph-txt">
                            <text-component class="subtitle1">Engagement Insight</text-component>
                        </div>
                        <graph-component
                            :width="width"
                            :height="height"
                            :axis-min="axismin"
                            :axis-max="axismax"
                            :axisStep="axisStep"
                            :labels="labels"
                            :values="engagementvalues"
                            :barMargin="barMargin"
                            :names="engagementname">
                        </graph-component>
                    </div>
                </div>
            </div>
            <div class="box margino">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <div class="circlegraph-txt">
                            <text-component class="subtitle1">Audience Demography</text-component>
                        </div>
                        <circle-graph-component
                            :width="circlewidth"
                            :height="circleheight"
                            :values="circlevalues"
                            :names="circlenames"
                            :strokeWidth="strokeWidth">
                        </circle-graph-component>
                    </div>
                    <div class="col-12 col-lg-7 margin-auto">
                        <graph-nogrid-component
                            class="graph-bootom"
                            :width="audiancewidth"
                            :height="audianceheight"
                            :axis-min="audianceaxismin"
                            :axis-max="audianceaxismax"
                            :axisStep="audianceaxisStep"
                            :labels="audiancelabels"
                            :values="audiancevalues"
                            :barMargin="audiancebarMargin"
                            :names="audiancenames"
                            :axisreverse="axisreverse">
                        </graph-nogrid-component>
                    </div>
                </div>
            </div>
            <div class="box margino margin-top">
                <div class="row">
                    <div class="col-12">
                        <div class="graph-txt">
                            <text-component class="subtitle1">Hashtags Usage</text-component>
                        </div>
                        <graph-nohorizintalgrid-component
                            :width="hashtagwidth"
                            :height="hashtagheight"
                            :axis-min="hashtagaxismin"
                            :axis-max="hashtagaxismax"
                            :axisStep="hashtagaxisStep"
                            :labels="hashtaglabels"
                            :values="hashtagvalues"
                            :barMargin="hashtagbarMargin"
                            :names="hashtagnames"
                            :axisreverse="axisreverse">
                        </graph-nohorizintalgrid-component>
                    </div>
                </div>
            </div> -->
            <div class="box paddingo margino margin-top">
                <div class="box-content">
                    <div class="row margino">
                        <text-component class="subtitle1 influencer-txt col-12 paddingo">Influencers</text-component>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :columns="columns"
                                    :rows="rows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <vue-html2pdf
        :show-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="campaignData.campaign_name"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"            
        ref="html2Pdf">
            <section slot="pdf-content" class="campaign-report-pdf row margino">
                <div class="col-12">
                    <div class="pdf-header col-12">
                    <span class="h2">Campaign Report</span>
                    </div>
                    <div class="pdf-content">
                    <!-- Campaign Report -->
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Campaign Name</span>
                        <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
                    </div>
                    <div v-if="campaignData.user" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Brand Name</span>
                        <span class="campaign-detail-txt">{{campaignData.user?campaignData.user.name:'N/A'}}</span>
                    </div>
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">No. of Influencer</span>
                        <span class="campaign-detail-txt">{{campaignData.influencers}}</span>
                    </div>
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Social Media Platforms</span>
                        <span class="campaign-detail-txt">{{getPlatformName(campaignData.social_platform)}}</span>
                    </div>
                    <div class="row margino">
                        <div class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Budget</span>
                        <span class="campaign-detail-txt">{{campaignData.budget}}</span>
                        </div>
                        <div class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Total Influencer Cost</span>
                        <span class="campaign-detail-txt">{{getTotalInfluencerCost()}}</span>
                        </div>
                    </div>
                    <hr/>
                    <div v-if="bestpdata">
                        <div class="campaign-detail-row col-12">
                            <span class="subtitle2 campaign-detail-title">Best Performer Influencer: </span>
                            <span class="campaign-detail-txt">{{bestpdata.social_insight.user_profile?bestpdata.social_insight.user_profile.full_name:'N/A'}}</span>
                        </div>
                        <div class="campaign-detail-row col-12">
                            <span class="subtitle2 campaign-detail-title">Link: </span>
                            <span class="campaign-detail-txt">{{bestpdata.social_insight.link}}</span>
                        </div>
                        <div class="campaign-detail-row col-12">
                            <table class="influencer-list">
                                <tr>
                                    <th v-if="bestpdata.total_likes">Likes</th>
                                    <th>Views</th>
                                </tr>
                                <tr>
                                    <td v-if="bestpdata.total_likes">{{bestpdata.total_likes}}</td>
                                    <td>{{bestpdata.total_views}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                     <div v-else class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Best Performer Influencer: </span>
                        <span class="">No Data Avaliable</span>
                    </div>
                    <hr/>
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Total Campaign Statistics</span>
                        <div v-if="byplatform" class="campaign-detail-row col-12 paddingo" style="padding:0px;padding-top:12px">
                            <table 
                                class="influencer-list"
                                v-if="byplatform.facebook || byplatform.instagram || byplatform.twitter|| byplatform.youtube">
                                <tr>
                                    <th>Platform</th>
                                    <th>Likes</th>
                                    <th>Views</th>
                                </tr>
                                <tr v-if="byplatform.facebook">
                                    <td>Facebook</td>
                                    <td>{{bestpdata.total_likes}}</td>
                                    <td>{{bestpdata.total_views}}</td>
                                </tr>
                                <tr v-if="byplatform.instagram">
                                    <td>Instagram</td>
                                    <td>{{bestpdata.total_likes}}</td>
                                    <td>{{bestpdata.total_views}}</td>
                                </tr>
                                <tr v-if="byplatform.twitter">
                                    <td>Twitter</td>
                                    <td>{{byplatform.twitter.num_likes}}</td>
                                    <td>{{byplatform.twitter.num_views}}</td>
                                </tr>
                                <tr v-if="byplatform.youtube">
                                    <td>Youtube</td>
                                    <td>{{bestpdata.total_likes}}</td>
                                    <td>{{bestpdata.total_views}}</td>
                                </tr>
                            </table>
                            <span v-else class="">No Data Avaliable</span>
                        </div>
                        <span v-else class="">No Data Avaliable</span>
                    </div>
                    <hr/>
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">List of Influencer</span>
                    </div>
                    <div v-if="influencerratingdata.length" class="campaign-detail-row col-12">
                        <table class="influencer-list">
                            <tr>
                                <th>Name</th>
                                <th>Platform</th>
                                <th>Rating</th>
                                <th>Campaign Cost</th>
                            </tr>
                            <tr v-for="(cinfluencer, index) in influencerratingdata" :key="index">
                                <td>{{cinfluencer.full_name}}</td>
                                <td>{{cinfluencer.socialAccounts.toString()}}</td>
                                <td><rating-component :rating="cinfluencer.rating"></rating-component></td>
                                <!-- <td>{{cinfluencer.rating?cinfluencer.rating:'N/A'}}</td> -->
                                <td>{{cinfluencer.final_price}}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="campaign-detail-row col-12">
                        <span class="">No Data Avaliable</span>
                    </div>
                    

                    <!-- Influencer Campaign Report -->
                    <!-- <div v-if="campaignData.user" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Influencer Name</span>
                        <span class="campaign-detail-txt">{{campaignData.user?campaignData.user.name:'N/A'}}</span>
                    </div>
                    <div v-if="campaignData.campaign_name" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Campaign Name</span>
                        <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
                    </div>
                    <div v-if="campaignData.campaign_name" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Brand Name</span>
                        <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
                    </div>
                    <div v-if="campaignData.influencers" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">No. of Influencer</span>
                        <span class="campaign-detail-txt">{{campaignData.influencers}}</span>
                    </div>
                    <div v-if="campaignData.social_platform" class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Social Media Platforms</span>
                        <span class="campaign-detail-txt">{{getSocialPlatform(campaignData.social_platform)}}</span>
                    </div>
                    <div class="row margino">
                        <div v-if="campaignData.budget" class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Influencer Cost</span>
                        <span class="campaign-detail-txt">{{campaignData.budget}}</span>
                        </div>
                        <div v-if="campaignData.social_platform" class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Campaign Rating</span>
                        <span class="campaign-detail-txt">{{campaignData.social_platform}}</span>
                        </div>
                    </div>
                    <hr/>
                    <div v-if="campaignData.social_platform" class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Post URL</span>
                        <span class="campaign-detail-txt">{{campaignData.social_platform}}</span>
                    </div>
                    <hr/>
                    <div class="campaign-detail-row col-12">
                        <span class="subtitle2 campaign-detail-title">Statistics Insights</span>
                    </div> -->
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>


<script>

import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import TabComponent from "../../../../BasicComponents/Tabs/Tabs.vue";
// import GraphComponent from "../../../../BasicComponents/graph/graph.vue";
// import GraphNogridComponent from "../../../../BasicComponents/graph/graphnogrid";
// import GraphNohorizintalgridComponent from "../../../../BasicComponents/graph/graphnohorizontalgrid.vue";
// import CircleGraphComponent from "../../../../BasicComponents/graph/graphcircle.vue";
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import RatingComponent from "../../../../BasicComponents/rating/rating.vue";
import usericon from "./profileicon.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import Rating from "./rating.vue";
import Platform from "./platform.vue";
import { eventBus } from "../../../../main";
import { mapActions,mapGetters } from "vuex";
import VueHtml2pdf from 'vue-html2pdf'
import pdfcss from "../../../../assets/css/campaignreportpdf.scss"

export default {
    props: {},
    components: {
        TextComponent,
        ButtonComponent,
        TabComponent,
        VueHtml2pdf,
        RatingComponent,
        // GraphComponent,
        // CircleGraphComponent,
        // GraphNogridComponent,
        // GraphNohorizintalgridComponent,
        DatatableComponent,
        searchBar
    },
    computed: {
        ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
        ...mapGetters("commonfunction", ["platformData","countryData","cityData"]),
    },
    watch: {
        influencerCategory: {
            immediate: true,
            handler(influencerCategory) {
                this.$data.influencecat = influencerCategory;
            },
        },
        platformData: {
            immediate: true,
            handler(platformData) {
                this.$data.socialplatform = platformData;
                this.platforms = platformData
            },
        },
    },
    data() {
        return {
            influencerratingdata: [],
            socialplatform: [],
            platforms: [],
            campaigninfluencer: [],
            type: "text",
            inputPlaceholder: "Search Influencer",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            dropdownText: "Sub Category",
            statusClear: "Clear",
            clearClass: "btnplain",
            txtclassname: "default",
            btninvitecls: "btnprimary",
            dropCat: "btnbasic",
            dropCatText: "Main Category",
            statusid: "btnstatus",
            filter: "",
            selectedRows: [],
            page: 1,
            per_page: 8,
            selected: "",
            row: "",
            influencername: '',
            email: '',
            h2txtclass: 'h2',
            activetab: 'Instagram',
            maxLimit: 8,
            isHiddenbar: false,
            width: 523,
            height: 295,
            axismin: 0,
            axismax: 500,
            axisStep: 5,
            barMargin: 9,
            strokeWidth: 55,
            circlewidth: 300,
            circleheight: 270,
            audiancewidth: 323,
            audianceheight: 210,
            audianceaxismin: 0,
            audianceaxismax: 500,
            audianceaxisStep: 5,
            audiancebarMargin: 9,
            hashtagwidth: 1050,
            hashtagheight: 340,
            hashtagaxismin: 0,
            hashtagaxismax: 100000,
            hashtagaxisStep: 10,
            hashtagbarMargin: 9,
            axisreverse: true,
            hashtagnames: ["Hashtag"],
            names: [ "Followers" ],
            audiancenames: ["Audience"],
            engagementname: [ "Engagement" ],
            labels: [ '23 May', '24 May', '25 May', '26 May', '27 May', '28 May', '29 May', '30 May', ],
            values: [200, 130, 100, 200, 180, 210, 60, 150],
            engagementvalues: [20000, 18000, 22000, 16000, 8000, 23000, 27000, 22000],
            circlevalues: [ 70, 20, 10 ],
            circlenames: ['Mumbai', 'Delhi', 'Other'],
            audiancelabels: ['Mumbai', 'Delhi', 'Other'],
            audiancevalues: [ 70, 20, 10 ],
            hashtaglabels: ['#imnutrition', '#nutrition', '#healthnfilttness', '#betterself', '#healthybody'],
            hashtagvalues: [90000, 75000, 55000, 45000, 32000],
            dropCatList: [
                { id: "0", value: "Active" },
                { id: "1", value: "Inactive" },
            ],
            tabdata: [
                { id: "1", value: "Instagram" },
                { id: "2", value: "Facebook" },
                { id: "3", value: "Twitter" },
                { id: "4", value: "Youtube" },
            ],
            columns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Influencer's Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Rating",headerAlign: "left", align: "left", component: (Rating)},
                // { label: "Post Type",headerAlign: "left", align: "left", component: (Platform)},
                {
                    label: "Post Type",
                    field: "contain",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },  
                {
                    label: "Post Description",
                    field: "post_description",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },  
            ],
            rows: [],
            bestpdata: [],
            byplatform: [],
            campaignData: [],
            socialdata: []
        };
    },
    methods: {
        ...mapActions("brandcampaign", ["getbestperformer", "getbestperformerbyplatform"]),
        ...mapActions("admincampaignaction", ["getcampaignbasic","getApprovedlist","getinfluencercampaignrating"]),
        ...mapActions("brandinfluencerdata", ["getcampaignsocial", "getposttype"]),

        setinfluencerrating(){
            this.getinfluencercampaignrating({
                campaign_id: this.$route.params.id,
            }).then((response) => {
                this.influencerratingdata = response.data
            }); 
        },

        getPlatformName(pid){
            let plarformname = null
            if(pid.length == 1){
                for (let i = 0; i < this.platforms.length; i++) {
                    if(this.platforms[i].id == pid){
                        plarformname = this.platforms[i].name
                    }
                }
                return plarformname   
            }
            else{
                plarformname = "";
                let splitplatform = pid.split(",");
                // return splitplatform
                for (let i = 0; i < splitplatform.length; i++) {
                    for (let j = 0; j < this.platforms.length; j++) {
                        if(this.platforms[j].id == splitplatform[i]){
                            if(i == splitplatform.length-1){
                                plarformname += this.platforms[j].name
                            }
                            else{
                                plarformname += this.platforms[j].name+', '
                            }
                        }
                    }
                }
                return plarformname
            }
        },

        getTotalInfluencerCost() {
            let totalcost = 0
            for (let i = 0; i < this.campaigninfluencer.length; i++) {
                totalcost += this.campaigninfluencer[i].final_price
            }
            return totalcost.toString()
        },


        getInfluencerList() {
            this.getApprovedlist({
                id: this.$route.params.id,
            }).then((response) => {
                this.campaigninfluencer = response.data;
            });
        },

        getSocialPlatform(ids) {
            if (ids) {
                let multiple = ids.split(",").map((x) => +x);
                let name = this.socialplatform.filter((value) => {
                return multiple.indexOf(value.id) > -1;
                });
                let returnstring = [];
                for (let i = 0; i < name.length; i++) {
                returnstring.push(name[i].name);
                }
                return returnstring.toString();
            }
        },

        generateReport () {
            // console.log(this.$refs.html2Pdf)
            this.$refs.html2Pdf.generatePdf()
            // this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`);
        },

        hasStartedGeneration() {
            console.log(`PDF has started generation`);
        },

        hasGenerated(blobPdf) {
            this.pdfDownloaded = true;
            console.log(`PDF has downloaded yehey`);
            console.log(blobPdf);
        },

        getSocialLinkData() {
            this.getcampaignsocial({id:this.$route.params.id})
            .then((response) => {
                    this.socialdata = response.data
                    this.rows = response.data
                });
        },

        getBestPerformerData() {
            this.getbestperformer({id:this.$route.params.id})
            .then((response) => {
                    
                    if(response.data.social_insight) {
                        this.bestpdata = response.data
                    }
                    else{
                        this.bestpdata = null
                    }
                });
        },

        getBestPerformerByPlatformData() {
            this.getbestperformerbyplatform({id:this.$route.params.id})
            .then((response) => {
                    this.byplatform = response.data
                });
        },

        getCampaignData() {
                this.getcampaignbasic({ id: this.$route.params.id })
                .then((response) => {
                    this.campaignData = response.data;
                })
                .catch((error) => {
                    this.campaignData = [];
                });
        },
    },
    created: function() {
        this.setinfluencerrating();
        this.getInfluencerList();
        this.getBestPerformerData();
        this.getBestPerformerByPlatformData();
        this.getCampaignData();
        this.getSocialLinkData();

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );
    }
};
</script>
