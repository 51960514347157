<template>
    <div class="brand-detail">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Brand Report</text-component>
                <!-- <div class="page-header-right">
                    <button-component :clsname="btnprimary">Generate Report</button-component>
                </div> -->
            </div>
        </div>
        <div class="content">
            <basic-detail></basic-detail>
            <campaign-list></campaign-list>
            <rating-page></rating-page>
        </div>
    </div>
</template>
<script>
import BasicDetail from "./basicdetail.vue";
import CampaignList from "./campaignlist.vue";
import RatingPage from "./ratingpage.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
// import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { isMoment } from 'moment';
export default {
    components: {
        BasicDetail,
        CampaignList,
        RatingPage,
        TextComponent,
        // ButtonComponent
    },
    data() {
        return{
            h2txtclass: 'h2',
            btnprimary: 'btnprimary'
        }
    }
}
</script>