<template>
  <div class="progress">
    <div class="box margino">
      <div class="box-content">
        <div class="row margino paddingo">
          <div class="progress-left row col-12 col-xl-7">
            <div class="progress-left-left col-sm-4 paddingo col-12">
              <!-- <text-component :class="subtitle1txtclass">{{profileinfo.full_name}}</text-component> -->
              <!-- <c-progress-bar :progress="progress"></c-progress-bar> -->
              <div class="row margino">
                <img
                  v-if="profileinfo.profile_pic"
                  :src="profileinfo.base_url + '/' + profileinfo.profile_pic"
                  class="user-img-dashboard"
                  alt="user-image"
                />
                <img
                  v-else
                  src="../../../assets/image/usericon.png"
                  class="user-img-dashboard"
                  alt="user-image"
                />
              </div>
            </div>
            <div class="progress-left-right col-sm-6 col-12" >
              <text-component :class="subtitle1txtclass">{{profileinfo.full_name}}</text-component>
              <rating-component :rating="ratingval"></rating-component>
              <!-- <ul class="profile-tips">
                <li v-for="(tip, index) in tips" :key="index">{{tip.tip}}</li>
              </ul> -->
              <div class="connected-social-acc-logo-main"
                v-if="socialData.length">
                <!-- <div class="row margino">
                  <text-component :class="subtitle1txtclass">Accounts</text-component>
                </div> -->
                <div 
                  v-for="sdata in socialData" :key="sdata.id"
                  class="connected-social-acc-logo"
                  @click="redirectAccount(sdata)"
                  :class="sdata.platform">
                </div>
              </div>
              <div class="connected-social-acc-logo-main"
                v-if="!socialacc.length">
                  <span class="subtitle2">No Accounts Connected</span>
              </div>
            </div>
            <div class="progress-left-right col-sm-2 col-12" >
              <button-component @onBtnClick="goToMyProfile()" :clsname="primaryclass">EDIT</button-component>
            </div>
          </div>
          <div class="progress-right col-12 col-xl-5">
            <text-component :class="subtitle1txtclass">Interest/Genres</text-component>
            <div v-if="isInterestdata.length <= 0">
              <p style="text-align: center;">Currently no interest/genres are Added</p>
            </div>
            <ul v-else class="profile-interest">
              <li 
                v-for="(interest, index) in interestdatastring" 
                :key="index">{{interest.value}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CProgressBar from "../../../BasicComponents/progressbar/progressbar.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import RatingComponent from "../../../BasicComponents/rating/rating.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // CProgressBar,
    TextComponent,
    RatingComponent,
    ButtonComponent
  },
  computed: {
    ...mapGetters("commonfunction", ["percentData"]),
    ...mapGetters("influencerprofile", ["interestData", "interestListing"]),
    ...mapGetters("influencerprofile", ["profileData", "socialData"]),
    

  },
  watch: {
    percentData: {
      immediate: true,
      handler(percentData) {
        this.progress = percentData.total_per;
        this.$data.tips = this.gettips(percentData.tips);
      }
    },
    socialData: {
      immediate: true,
      deep: true,
      handler(socialData) {
        // this.socialacc = socialData
        for (let i = 0; i < socialData.length; i++) {
          if(this.socialacc.includes(socialData[i].platform)) {
            console.log("avaliable")
          }else{
            this.socialacc.push(socialData[i].platform)
          }
        }
        this.socialacc.sort()
      },
    },
    profileData: {
      immediate: true,
      handler(profileData) {
        this.profileinfo = profileData
      }
    },
    interestData: {
      immediate: true,
      deep: true,
      handler(interestData) {
        this.isInterestdata = interestData
        if(interestData) {
          this.$data.interestdatastring = this.getuserinterest(interestData);
          if (interestData.other_interest != null) {
            this.tags = this.setedittags(interestData.other_interest);
          }
          if (interestData.interest) {
            this.selectedval = interestData.interest.split(",");
            this.$data.interestdatastring = this.getuserinterest(interestData);
          }
        }else {
          this.isInterestdata = [];
        }
      },
    },

    interestListing: {
      immediate: true,
      handler(interestListing) {
        // console.log("interest listing:", interestListing);
        this.listingdata = interestListing;
        this.$data.interestedit = this.getlisting(interestListing);
      },
    },
  },
  data() {
    return {
      subtitle1txtclass: "subtitle1",
      progress: "",
      tips: [],
      profileinfo: [],
      socialacc: [],
      interests: [
        { interest: "Arts" },
        { interest: "Fitness & Health" },
        { interest: "Active Lifestyle" },
        { interest: "Business & Startups" },
        { interest: "Home & Garden" },
        { interest: "Gaming" },
        { interest: "Education" },
        { interest: "Finance" }
      ],
      tag: "",
      tags: [],
      listingdata: {},
      selectedval: [],
      isInterestdata: [],
      totalratingavg: [],
      ratingval:null ,
      IsInterestDetailEditable: false,
      classname: "default",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      other: "",
      interestedit: [],
      interestdatastring: [],
    };
  },
  created() {
    this.getuserpercentage();
    this.getinterestlisting();
    this.getuserratingdata();
    this.getuserprofile();
    this.getinterests();
  },
  methods: {
    ...mapActions("commonfunction", ["getuserpercentage"]),
    ...mapActions("influencerprofile", ["getinterests", "getinterestlisting", "getuserrating", "getuserprofile"]),
    ...mapActions("commonfunction", ["getuserpercentage"]),
    gettips(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          tip: value
        })
      })
      return newarray.slice(0,3);
    },
    getuserratingdata() {
      this.getuserrating()
      .then(response=>{
          this.totalratingavg = response.data
          if (this.totalratingavg[0].ratings) {
            this.ratingval = this.totalratingavg[0].ratings.average
          }
      });
    },
    setedittags(data) {
      // "text": "dcs", "tiClasses": [ "ti-valid" ] }
      let newarray = [];
      if (data) {
        data.map((value) => {
          newarray.push({
            text: value,
            tiClasses: ["ti-valid"],
          });
        });
        return newarray;
      } else {
        return newarray;
      }
    },

    getuserinterest(ids) {
      let valuearray = [];
      if (ids.other_interest != null) {
        ids.other_interest.map((value) => {
          valuearray.push({ value: value });
        });
      }
      if (ids.interest) {
        let tagids = ids.interest.split(",");
        // console.log("arrayid:", ids);
        for (let id in tagids) {
          // console.log("selectedid:", tagids[id])
          for (let data in this.listingdata) {
            // console.log("listdata:", this.listingdata[data].id);
            if (tagids[id] == this.listingdata[data].id) {
              valuearray.push({
                value: this.listingdata[data].interest,
              });
            }
          }
        }
      }
      return valuearray;
    },

    interestDetailToggle() {
      if (this.IsInterestDetailEditable == false) {
        this.IsInterestDetailEditable = true;
      } else {
        this.IsInterestDetailEditable = false;
      }
    },

    goToMyProfile(){
      this.$router.push({name: "MyProfile"});
    },

    getlisting(data) {
      let newarray = [];
      data.map((value) => {
        newarray.push({
          id: value.id,
          checkboxname: value.interest,
        });
      });
      return newarray;
    },

    redirectAccount(data){
        // console.log('data clicked',data)
        if(data.platform=='facebook'){
            window.open(`https://www.facebook.com/${data.username}/`, '_blank')
        }

        if(data.platform=='twitter'){
            window.open(`https://twitter.com/${data.username}/`, '_blank')
        }

        if(data.platform=='youtube'){
            window.open(`https://www.youtube.com/channel/${data.username}/`, '_blank')
        }

        if(data.platform=='instagram'){
            window.open(`https://www.instagram.com/${data.username}/`, '_blank')
        }
    }
  }
};
</script>