<template>
  <div>
    <textarea :class="classname" @keydown="keydown" v-on:change="changefunction()" :value="value" v-on:input="updateValue($event.target.value)" :type="type" :disabled="disabledInput" :readonly="isReadonly" :required="isRequired" @keyup="press" @focus="showD" @click="onIClick" :placeholder="inputPlaceholder" :maxlength="maxlength" />
    <div v-if="helptext" class="error-helper-msg">
      <label :class="labelclass">{{helptext}}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "classname",
    "type",
    "labelclass",
    "disabledInput",
    "helptext",
    "options",
    "dropdownPosition",
    "inputPlaceholder",
    "value",
    "isReadonly",
    "isRequired",
    "maxlength",
  ],

  watch: {
    value: {
      immediate: false,
      handler(value) {
        this.$emit('input',value)
      }
    }
  },

  methods: {
    press: function (value) {
      this.$emit('searchItem',value);
      this.$emit('keyup',value);
    },
    updateValue: function (value) {
        this.$emit('input', value)
    },
    showD: function (value) {
        this.$emit('showData', value)
    },
    onIClick: function (value) {
        this.$emit('onInputClick', value)
    },
    changefunction: function (e) {
      this.$emit('change',e)
    },
    keydown:function (e) {
      this.$emit('keydown',e) 
    },
    keyup:function (e) {
      this.$emit('keyup',e) 
    }
  },
};
</script>