<template>
    <div class="notificationpage">
        <div class="box margino paddingo">
            <div class="box-header">
                <text-component :class="subtitle1txtclass">Notifications</text-component>
            </div>
            <div class="box-content">
                <div class="col-12 tab-no-data-class" v-if="notificationData.length == 0">
                    <text-component class="subtitle2">
                        No New Notification Avaliable
                    </text-component>
                </div>
                <ul>
                    <li v-for="notification in notificationData" 
                        :key="notification.id"
                        :class="(notification.read_status == '0')?'unread':'read'"
                        @click="goTonotification(notification.module, notification.relation_id,notification.id)">
                        <img src="../../../assets/image/bell.svg" class="bell-icon"/>
                        <!-- <span class="notfication-data">{{notification.message}}</span> -->
                        <div class="header-notification-text">
                            {{notification.message}}
                        </div>
                        <div class="header-notification-time">
                            {{timeformessage(notification.created_at)}}
                        </div>
                    </li>
                </ul>
                <div class="read-all-notification" >
                    <router-link v-if="notificationData.length != 0" to="notification"><button-component :clsname="btnplainclass">Read All Notifications</button-component></router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import NotificationComponent from "../../BasicComponents/notification/notification.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
// import {eventBus} from '../../main';
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        // NotificationComponent,
        TextComponent,
        ButtonComponent
    },
    computed: {
    ...mapGetters("commonfunction", ["notificationData","notificationstatus"])
    },
    watch: {
        notificationData: {
            immediate: true,
            handler(notificationData) {
                this.$data.notificationData = notificationData;
                // console.log(notificationData)
            }
        }
    },
    data() {
        return {
            subtitle1txtclass: 'subtitle1',
            btnplainclass: 'btnplain',
            notificationdata1: [
            {
                id: '1',
                notification: 'The Fitness campaign you took part is has ended',
            },
            {
                id: '2',
                notification: 'You recived payment from last Fitness Campaign',
            },
            {
                id: '3',
                notification: 'Your password has successfully changed',
            },
        ]
        }
    },
    created() {
        this.isDisplay = false 
        this.getlatestnotifications();
        this.getallnotifications(); 
    },
    methods: {
        ...mapActions("commonfunction",["getlatestnotifications", "getallnotifications"]),

        goTonotification(modules, relation_id, id) {

            this.notificationstatus({
                id: id,
                read_status: '1'
            })

            if(modules == 'Campaign'){
                this.$router.push({name: "agencycamapigndetail",params: { id: relation_id }});
            }
            else if(modules == 'Campaign Chat'){
                this.$router.push({name: "agencycamapigndetail",params: { id: relation_id }});
            }
            else if(modules == 'Chat'){
                this.$router.push({name: "agencysupportchat",params: { id: relation_id }});
            }
        },
    }
}
</script>