<template>
    <div>
        <section class="blog-banner">
            <img src="../../../assets/image/front/BlogHeroBanner.jpg">
            <div>
                <h1>Blogs</h1>
            </div>
        </section>

        <section class="listing-part">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-8">
                        <div class="row">

                            <!-- <div v-for="(blog,index) in blogs" :key="index" class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <router-link :to="{name:'BlogDetail',params:{slug:blog.slug}}"><img :src="getImageURL(blog.baseUrl,blog.banner_image)"></router-link>
                                <router-link :to="{name:'BlogDetail',params:{slug:blog.slug}}">{{blog.title}}</router-link>
                            </div> -->
                            
                            <div
                                class="col-12 col-sm-6 listing-img" 
                                data-aos="zoom-in-down" data-aos-once="true" 
                                data-aos-duration="1000"
                                v-for="(blog, index) in blogs" :key="index"
                                @click="toBlog(blog)">
                                <img v-if="blog.banner_image" :src="blog.baseUrl+ '/' +blog.banner_image">
                                <img v-else src="../../../assets/image/front/B1.png">
                                <a href="">{{blog.title}}</a>
                            </div>
                            <!-- <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B2.png"></a>
                                <a href="">How To 4 Ways to Build a Community with Influencers</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B3.png"></a>
                                <a href="">How to Cultivate the Best Influencer Partnerships Based on Organic Love</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B1.png"></a>
                                <a href="">Tip of the Month: Leveraging Market-Level Insights to Discover Brand Advo…</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B2.png"></a>
                                <a href="">How To 4 Ways to Build a Community with Influencers</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B3.png"></a>
                                <a href="">How to Cultivate the Best Influencer Partnerships Based on Organic Love</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B1.png"></a>
                                <a href="">Tip of the Month: Leveraging Market-Level Insights to Discover Brand Advo…</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B2.png"></a>
                                <a href="">How To 4 Ways to Build a Community with Influencers</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B3.png"></a>
                                <a href="">How to Cultivate the Best Influencer Partnerships Based on Organic Love</a>
                            </div>
                            <div class="col-12 col-sm-6 listing-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href=""><img src="../../../assets/image/front/B1.png"></a>
                                <a href="">Tip of the Month: Leveraging Market-Level Insights to Discover Brand Advo…</a>
                            </div> -->
                        </div>
                        <button @click="getDataLoader" class="next-btn">Next</button>
                    </div>
                    <div class="col-md-12 col-lg-4 searching-part">
                        <div class="pl24">
                            <div class="search-box" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <button><img src="../../../assets/image/front/Search.png"></button>
                                <input @keyup="getSearchBlogData" v-model="searchtext" type="text" placeholder="Search posts" name="">
                            </div>
                        
                            <h3 v-if="isrecentblog" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Recent Blog</h3>

                            <h3 v-else data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Search Result</h3>

                            <div v-if="isrecentblog">
                                <div 
                                    class="search-list" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-for="(latestblog, index) in latestblogs" :key="index"
                                    @click="toBlog(latestblog)">
                                    <span>{{latestblog.title}}</span>
                                </div>
                            </div>

                            <div style="position:relative" v-else>
                                <div v-if="isLoader" class="loadingspinner-outer">
                                    <div class="loadingspinner"></div>
                                </div>
                                
                                <div 
                                    class="search-list" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-for="(search, index) in searchblogdata" :key="index"
                                     @click="toBlog(search)">
                                    <span>{{search.title}}</span>
                                </div>
                                <div 
                                    class="search-list" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-if="searchblogdata == '' && !isLoader">
                                    <span>No Results found.!</span>
                                </div>
                            </div>

                            <!-- <div class="search-list" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href="">How To 4 Ways to Build a Community with Influencers</a>
                            </div>
                            <div class="search-list" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href="">How to Cultivate the Best Influencer Partnerships Based on Organic Love</a>
                            </div> -->
                        </div>  
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:null,
                meta_title:null,
                meta_keyword:null,
                meta_desc:null,
            },
            cms_field:[],
            blogs:[],
            latestblogs: [],
            searchtext:null,
            searchblogdata: [],
            isrecentblog: true,
            isLoader: false,
            blogPages: 1,
        }
    },
    metaInfo() {
        return { 
            title: 'Blogs',
        }
    },
    created(){
        this.getData();
        this.getLatestBlogData();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    // computed: {
    //     ...mapGetters("frontblog", ["blogListLoader"]),
    // },
    // watch:{
    //     blogListLoader:{
    //         immediate:true,
    //         handler(blogListLoader){
                
    //             if(this.blogs == ""){
    //                 this.blogs = blogListLoader;
    //             }
    //             else if(this.blogs != ""){
    //                 for (let i = 0; i < blogListLoader.length; i++) {
    //                     this.blogs.push(blogListLoader[i])
    //                 }
    //             }
    //         }
    //     }
    // },
    methods:{
        ...mapActions("frontblog",["getBlogListWithLoader"]),
        ...mapActions("blogdata",["getlatestblog", "searchblog"]),

        toBlog(blog) {
            this.$router.push({name: "BlogDetail",params: { slug: blog.slug }});
            // console.log(blog.slug)
        },

        getDataLoader(){
            this.blogPages++;
            this.getBlogListWithLoader({page_no: this.blogPages})
            .then(response=>{
                // this.blogs = response.data;
                for (let i = 0; i < response.data.length; i++) {
                    this.blogs.push(response.data[i])
                }
            });
        },

        getData(){
            this.getBlogListWithLoader({page_no: this.blogPages})
            .then(response=>{
                this.blogs = response.data;
            });
            
        },

        getLatestBlogData() {
            this.getlatestblog()
            .then(response=>{
                this.latestblogs = response.data
            });
        },

        getSearchBlogData() {

            if(this.searchtext != ""){
                this.isrecentblog = false
            }
            else{
                this.isrecentblog = true
            }
            this.isLoader = true
            this.searchblog({string: this.searchtext})
            .then(response=>{
                this.searchblogdata = response.data
            }).then(response => {
                    this.isLoader = false
                })
                .catch(err => {
                    this.isLoader = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>

.loadingspinner-outer{
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
}

.loadingspinner {
    margin: 0 auto;
	pointer-events: none;
	width: 2.5em;
	height: 2.5em;
	border: 0.4em solid transparent;
	border-color: #eee;
	border-top-color: #F35F22;
	border-radius: 50%;
	animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
	100% {
			transform: rotate(360deg)
	}
}


</style>