<template>
  <!-- Modal -->
  <form @submit.prevent="handleCampaign">
    <div :class="{'modal-window': true , 'popupopen': isCreateCampaign}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="popUp"
            src="../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass" v-if="editelement == 0 || dashboardcreateelement == '0' || copyelement != '0'">Create Campaign</text-component>
          <text-component :class="h2txtclass" v-else>Update Campaign</text-component>
        </div>
        <div class="row content-align">
          <input-component
            required="true"
            class="txtgroup col-12 col-sm-6 col-md-4"
            :helptext="!$v.item.name.required && submitted ? validationmessage.name: false"
            :labelclass="{'validationerr': !$v.item.name.required && submitted}"
            :classname="{'error': !$v.item.name.required && submitted, 'default': true}"
            v-model="item.name"
          >Campaign Name</input-component>
          <div class="txtgroup col-12 col-sm-6 col-md-4">
            <div class="row">
              <input-component
                class="gen-ratio-left txtgroup padding-topo col-12 col-sm-6"
                :classname="{'default': true}"
                v-model="item.male"
                inputPlaceholder="Male"
              >Gender Ratio</input-component>
              <input-component
                class="gen-ratio-right txtgroup padding-topo col-12 col-sm-6"
                :classname="{'default': true}"
                v-model="item.female"
                inputPlaceholder="Female"
              ></input-component>
            </div>
          </div>
          <input-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            :classname="{'default': true}"
            v-model="item.target_age"
          >Target Age</input-component>
          <select-component
            label="name"
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.country.required && submitted ? validationmessage.country: false"
            :labelclass="{'validationerr': !$v.item.country.required && submitted}"
            :inputclass="{'error': !$v.item.country.required && submitted, 'dropdownmenu': true}"
            :options="country"
            v-model="item.country"
            :multiple="false"
            :placeholder="selectplaceholder"
            @handlevalue="sendCountryid(item.country)"
          >Country</select-component>
          <select-component
            label="name"
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.city.required && submitted ? validationmessage.city: false"
            :labelclass="{'validationerr': !$v.item.city.required && submitted}"
            :inputclass="{'error': !$v.item.city.required && submitted, 'dropdownmenu': true}"
            :options="city"
            v-model="item.city"
            :multiple="true"
            :placeholder="selectplaceholder"
          >Cities</select-component>
          <div class="col-12 col-sm-6 col-md-4 txtgroup datetimepicker row-height">
            <div class="datetimepicker-txt inputtxt">
              <text-component>
                Start Date & End Date
                <sup>*</sup>
              </text-component>
            </div>
            <div class="datetimepicker-main">
              <date-picker
                :class="{'error': !$v.item.daterange.required && submitted}"
                v-model="item.daterange"
                :value-type="valuetype"
                type="date"
                range
                :editable="editable"
                placeholder="Select date range"
              ></date-picker>
            </div>
            <span
              v-if="!$v.item.daterange.required && submitted"
              class="validationerr"
            >{{validationmessage.daterange}}</span>
          </div>
          <input-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            :classname="{'default': true}"
            v-model="item.noofinfluencers"
          >Number of Influencers</input-component>
          <select-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.influencercategory.required && submitted ? validationmessage.influencercategory: false"
            :labelclass="{'validationerr': !$v.item.influencercategory.required && submitted}"
            :inputclass="{'error': !$v.item.influencercategory.required && submitted, 'dropdownmenu': true}"
            :options="influencecat"
            v-model="item.influencercategory"
            :multiple="true"
            :placeholder="selectplaceholder"
          >Influencer category</select-component>
          <select-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.platform.required && submitted ? validationmessage.platform: false"
            :labelclass="{'validationerr': !$v.item.platform.required && submitted}"
            :inputclass="{'error': !$v.item.platform.required && submitted, 'dropdownmenu': true}"
            :options="socialplatform"
            v-model="item.platform"
            :multiple="true"
            :placeholder="selectplaceholder"
          >Social Media Platform</select-component>
          <select-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.created_by.required && submitted ? validationmessage.created_by: false"
            :labelclass="{'validationerr': !$v.item.created_by.required && submitted}"
            :inputclass="{'error': !$v.item.created_by.required && submitted, 'dropdownmenu': true}"
            :options="brandagency"
            v-model="item.created_by"
            :multiple="false"
            :placeholder="selectplaceholder"
          >Brand</select-component>
          <select-component
            class="txtgroup col-12 col-sm-6 col-md-4"
            required="true"
            :helptext="!$v.item.contentformat.required && submitted ? validationmessage.contentformat: false"
            :labelclass="{'validationerr': !$v.item.contentformat.required && submitted}"
            :inputclass="{'error': !$v.item.contentformat.required && submitted, 'dropdownmenu': true}"
            :options="contentfo"
            v-model="item.contentformat"
            :multiple="false"
            :placeholder="selectplaceholder"
          >Content Format</select-component>
          <input-component
            required="true"
            class="txtgroup amountinputbox input-with-toggle col-12 col-sm-6 col-md-4"
            :helptext="!$v.item.budget.required && submitted ? validationmessage.budget: false"
            :labelclass="{'validationerr': !$v.item.budget.required && submitted}"
            :classname="{'error': !$v.item.budget.required && submitted, 'default': true}"
            v-model="item.budget">
            Campaign Budget
            <toggle-switch
                class="toggle-switch"
                v-model="showbudget">Visible to Influencer
            </toggle-switch>
          </input-component>

          <div class="txtgroup col-12 col-sm-6 col-md-6">
            <div class="inputtxt">
              <text-component>
                Campaign Hashtags
                <!-- <sup>*</sup> -->
              </text-component>
            </div>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              @tags-changed="newTags => tags = newTags"
            />
            <!-- <span
              v-if="!tags.length && submitted"
              class="validationerr"
            >{{validationmessage.campaigntags}}</span> -->
          </div>         
          
          <div class="txtgroup col-12 col-sm-6 col-md-6">
            <div class="inputtxt">
              <text-component>
                Brand Hashtags
                <!-- <sup>*</sup> -->
              </text-component>
            </div>
            <vue-tags-input
              v-model="btag"
              :tags="btags"
              @tags-changed="newTags => btags = newTags"
            />
            <!-- <span
              v-if="!btags.length && submitted"
              class="validationerr"
            >{{validationmessage.brandtags}}</span> -->
          </div>
          <div class="txtgroup description col-12 col-sm-6 col-md-6">
            <div class="inputtxt">
              <text-component>
                Brief Description
                <sup>*</sup>
              </text-component>
            </div>
            <textarea
              class="txtarea"
              :class="{'error': !$v.item.description.required && submitted}"
              v-model="item.description"
            ></textarea>
            <span
              v-if="!$v.item.description.required && submitted"
              class="validationerr"
            >{{validationmessage.description}}</span>
          </div>
          <div class="txtgroup description col-12 col-sm-6 col-md-6">
            <div class="inputtxt">
              <text-component>
                Deliverable
                <sup>*</sup>
              </text-component>
            </div>
            <textarea
              class="txtarea"
              :class="{'error': !$v.item.deliverable.required && submitted}"
              v-model="item.deliverable"
            ></textarea>
            <span
              v-if="!$v.item.deliverable.required && submitted"
              class="validationerr"
            >{{validationmessage.deliverable}}</span>
          </div>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component
              buttontype="submit"
              :clsname="btnprimary"
              v-if="editelement == 0 || dashboardcreateelement == '0' || copyelement != '0'"
            >Create</button-component>
            <button-component :clsname="btnprimary" @onBtnClick="handleUpdate" v-else>Update</button-component>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- /Modal -->
</template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import ToggleSwitch from "../../../BasicComponents/Checkbox/switchbox";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    VueTagsInput,
    SelectComponent,
    InputComponent,
    TextComponent,
    ToggleSwitch,
    ButtonComponent
  },
  props: ["editelement", "copyelement", "dashboardcreateelement"],
  computed: {
    ...mapGetters("brandcampaign", [
      "contentFormat",
      "influencerCategory",
      "singleCampaign"
    ]),
    ...mapGetters("commonfunction", ["platformData","countryData", "cityData"]),
    ...mapGetters("adminbrandaction", ["approvedData"])
  },
  watch: {
    singleCampaign: {
      immediate: false,
      handler(singleCampaign) {
        this.item.name = singleCampaign.campaign_name;
        this.item.male = singleCampaign.male_ratio;
        this.item.female = singleCampaign.female_ratio
        this.item.noofinfluencers = singleCampaign.influencers
        this.item.influencercategory = singleCampaign.influencer_category?singleCampaign.influencer_category.split(',').map(x=>+x):[]
        this.item.platform = singleCampaign.social_platform?singleCampaign.social_platform.split(',').map(x=>+x):[]
        this.item.contentformat = singleCampaign.content_format
        this.item.budget = singleCampaign.budget
        this.item.description = singleCampaign.discription
        this.item.country = singleCampaign.country
        this.item.deliverable = singleCampaign.deliverable
        this.item.created_by = singleCampaign.created_by
        this.tags = this.sethashtags(singleCampaign.hashtags)
        this.btags = this.sethashtags(singleCampaign.brand_hashtags)
        this.item.daterange = [
          singleCampaign.start_date.replace(/-/g, "/"),
            singleCampaign.end_date.replace(/-/g, "/")
        ]
        this.getCityByCountry(singleCampaign.country).then(response=>{
          this.item.city = (singleCampaign.city)?singleCampaign.city.split(',').map(x=>+x):[]
          this.item.target_age = singleCampaign.target_age
        })
        this.showbudget = singleCampaign.budget_visibility
      }
    },

    copyelement: {
      immediate: true,
      handler(editelement) {
        if (editelement != 0) {
          this.getsinglecampaign({
            id: editelement
          });
        } else {
          this.item = {
            name: null,
            male: null,
            female: null,
            daterange: null,
            noofinfluencers: null,
            influencercategory: null,
            platform: null,
            contentformat: null,
            budget: null,
            created_by: null,
            country: null,
            city: null,
            target_age: null,
            deliverable: null,
            description: null,
          };
          this.showbudget = false
          this.tags = [];
          this.btags = [];
        }
      }
    },

    editelement: {
      immediate: true,
      handler(editelement) {
        if (editelement != 0) {
          this.getsinglecampaign({
            id: editelement
          });
        } else {
          this.item = {
            name: null,
            male: null,
            female: null,
            daterange: null,
            noofinfluencers: null,
            influencercategory: null,
            platform: null,
            contentformat: null,
            budget: null,
            created_by: null,
            country: null,
            city: null,
            target_age: null,
            deliverable: null,
            description: null
          };
          this.showbudget = false
          this.tags = [];
          this.btags = [];
        }
      }
    },

    contentFormat: {
      immediate: true,
      handler(contentFormat) {
        this.$data.contentfo = this.setcontent(contentFormat);
      }
    },

    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = this.setplatform(platformData);
      }
    },

    approvedData: {
      immediate: true,
      handler(approvedData) {
        this.$data.brandagency = this.setbrandagency(approvedData);
      }
    },

    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = this.setinfluecat(influencerCategory);
      }
    },
    
    countryData: {
      immediate: true,
      handler(countryData) {
        this.$data.country = countryData;
      }
    },

    cityData: {
      immediate: true,
      handler(cityData) {
        this.$data.city = cityData;
      }
    }
  },
  validations: {
    item: {
      name: { required },
      // male: { required },
      // female: { required },
      daterange: { required },
      // noofinfluencers: { required },
      influencercategory: { required },
      platform: { required },
      contentformat: { required },
      budget: { required },
      created_by: { required },
      country: { required },
      city: { required },
      // target_age: { required },
      deliverable: { required },
      description: { required }
    }
  },
  data() {
    return {
      item: {
        name: null,
        male: null,
        female: null,
        daterange: null,
        noofinfluencers: null,
        influencercategory: null,
        platform: null,
        contentformat: null,
        budget: null,
        created_by: null,
        country: null,
        city: null,
        target_age: null,
        deliverable: null,
        description: null
      },
      validationmessage: {
        name: "campaign name is required",
        male: "male ratio is required",
        female: "female ratio is required",
        target_age: "target age is required",
        daterange: "date is required",
        noofinfluencers: "influencer number is required",
        influencercategory: "influencer category is required",
        platform: "social platform is required",
        contentformat: "content format is required",
        budget: "campaign budget is required",
        description: "description is required",
        campaigntags: "campaign hashtags are required",
        created_by: "Brand Agency is required",
        brandtags: "brand hashtags are required",
        country: "country is required",
        city: "city is required",
        deliverable: 'deliverable is required'
      },
      submitted: false,
      showbudget: false,
      valuetype: "YYYY/MM/DD",
      brandagency: [],
      socialplatform: [],
      influencecat: [],
      contentfo: [],
      defaultclassname: "default",
      btag: "",
      tag: "",
      tags: [],
      btags: [],
      value1: [],
      country: [],
      city: [],
      selectplaceholder: "Select...",
      isCreateCampaign: false,
      btnprimary: "btnprimary",
      h2txtclass: "h2",
      invoiceid: "",
      agencyemail: "",
      male: "",
      female: "",
      campaignname: "",
      campaignstatus: "",
      amount: "",
      campaignstatusoption: [],
      campaignnameoption: [],
      editable: "",
      eddata: [],
    };
  },
  methods: {
    ...mapActions("brandcampaign", [
      "createcampaign",
      "getcampaign",
      "getcontentformat",
      "getinfluencercategory",
      "updatecampaign",
      "getsinglecampaign",
    ]),
    ...mapActions("adminbrandaction",["getbrandapproved"]),
    ...mapActions("commonfunction", ["getcity"]),

    capitalizeWord(string) {
      let str = string.trim()
      if(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      else{
          return ""
      }
    },

    sethashtags(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          text: value,
          tiClasses: ["ti-valid"]
        });
      });
      return newarray;
    },

    setplatform(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      return newarray;
    },

    setbrandagency(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      newarray.push({id:"0",label:"Other"})
      return newarray;
    },

    setinfluecat(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      return newarray;
    },

    setcontent(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      return newarray;
    },

    handleCampaign() {
      let vm = this;

      let newarray = [];
      if (this.tags != null) {
        this.tags.map(value => {
          newarray.push(value.text);
        });
      }

      let newarray1 = [];
      if (this.btags != null) {
        this.btags.map(value => {
          newarray1.push(value.text);
        });
      }

      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.createcampaign({
          campaign_name: this.item.name,
          male_ratio: this.item.male,
          female_ratio: this.item.female,
          influencers: this.item.noofinfluencers,
          influencer_category: this.item.influencercategory.toString(),
          social_platform: this.item.platform.toString(),
          content_format: this.item.contentformat,
          hashtags: newarray,
          brand_hashtags: newarray1,
          date: this.item.daterange,
          budget: this.item.budget,
          country: this.item.country,
          city: this.item.city.toString(),
          discription: this.item.description,
          deliverable: this.item.deliverable,
          target_age: this.item.target_age,
          created_by: this.item.created_by,
          budget_visibility: this.showbudget
        })
          .then(response => {
            console.log(response);
            vm.getcampaign();
            this.isCreateCampaign = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    handleUpdate() {
      console.log("Entered in handleUpdate..."+this.item)
      let vm = this;

      let newarray = [];
      if (this.tags != null) {
        this.tags.map(value => {
          newarray.push(value.text);
        });
      }

      let newarray1 = [];
      if (this.btags != null) {
        this.btags.map(value => {
          newarray1.push(value.text);
        });
      }
      
      this.submitted = true;
      
      if (this.$v.item.$invalid) {
        console.log("target_age: "+this.item.target_age)
        console.log("country: "+this.item.country)
        console.log("city: "+this.item.city)
        console.log("deliverable: "+this.item.deliverable)
        this.$v.item.$touch();
      } else {
        this.updatecampaign({
          id: this.editelement,
          campaign_name: this.item.name,
          male_ratio: this.item.male,
          female_ratio: this.item.female,
          influencers: this.item.noofinfluencers,
          influencer_category: this.item.influencercategory.toString(),
          social_platform: this.item.platform.toString(),
          content_format: this.item.contentformat,
          hashtags: newarray,
          brand_hashtags: newarray1,
          date: this.item.daterange,
          budget: this.item.budget,
          country: this.item.country,
          city: this.item.city.toString(),
          discription: this.item.description,
          deliverable: this.item.deliverable,
          target_age: this.item.target_age,
          created_by: this.created_by,
          budget_visibility: this.showbudget
        })
          .then(response => {
            console.log(response);
            vm.getcampaign();
            this.isCreateCampaign = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    popUp() {
      this.isCreateCampaign = !this.isCreateCampaign
      this.submitted=false;
    },
    sendCountryid(data) {
      this.getcity({
        id: data
      });
    },

    getCityByCountry: async function (data) {
      this.getcity({
        id: data
      });
    },

    setEditData() {
      console.log("editdata")
      // this.item.target_age = this.eddata.target_age
      // (this.item.target_age = singleCampaign.target_age),
    },

    setCopyData() {
      console.log("setCopyData")
    }
  },
  created: function() {
    this.getbrandapproved();
    this.getcontentformat();
    this.getinfluencercategory();

    this.$root.$on("openPopup", (row,btnname) => {
     this.eddata = row
     console.log(btnname)
      if(btnname == 'edit'){
        this.setEditData()
      }
      else if(btnname == 'copy'){
        this.setCopyData()
      }
      if (this.isCreateCampaign == true) {
        this.isCreateCampaign = false;
      } else {
        this.isCreateCampaign = true;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("openPopup", this.fn);
  }
};
</script>