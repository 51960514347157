<template>
    <div class="box margino box-first" v-if="socialInsightsData.length > 0">
        <div class="box-content">
            <div class="row">
                <div class="col-6">
                    <div class="last-updated-data-inner">
                        <text-component class="subtitle2 paddingo">Last Updated</text-component>
                        <text-component class="dark mid left-txt"> {{lastSync}}</text-component>
                    </div>
                </div>
                <div class="col-6 last-updated-btn">
                    <button-component @onBtnClick="fetchLatest()" :clsname="btninvitecls">Refresh</button-component>
                </div>
            </div>
            <header-tabs
                class="tabs"
                :tabdata="tabdata"
                :activetab="activetab"
                :maxLimit="maxLimit"
                :isHiddenbar="isHiddenbar">
                <div v-for="(singleSocial,index) in socialInsightsData" :key="index">
                    <div class="tag-bar-bottom-data" v-if="activetab == (singleSocial.plateform+index)">
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='facebook' && singleSocial.data.profile">
                            <div class="row col-12 paddingo margino social-insight-cards">
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.name">      
                                    <div class="profile-detail-card-inner subscriber-total">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.profile.name}}</text-component>
                                            <br><text-component class="dark">Name</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.birthday">      
                                    <div class="profile-detail-card-inner birthday">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/birthday-cake.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.profile.birthday}}</text-component>
                                            <br><text-component class="dark">Birthday</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.age_range">      
                                    <div class="profile-detail-card-inner age-range">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/parental-control.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.profile.age_range}}</text-component>
                                            <br><text-component class="dark">Age Range</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.email">      
                                    <div class="profile-detail-card-inner email">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/card-email.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.profile.email}}</text-component>
                                            <br><text-component class="dark">Email</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.posts.data.length">      
                                    <div class="profile-detail-card-inner total-post">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalcomments.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.posts.data.length}}</text-component>
                                            <br><text-component class="dark">Total Post</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.pages.length">      
                                    <div class="profile-detail-card-inner pages">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/page.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.pages.length}}</text-component>
                                            <br><text-component class="dark">Total Pages</text-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.profile.name">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.profile.birthday">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Birthday</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.birthday}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.profile.age_range">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Age Range</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.age_range}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.profile.email">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Email</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.email}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.posts.data">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Posts</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.posts.data.length}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.pages.length">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Pages</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.pages.length}}</text-component>
                                </div>
                            </div> -->
                        </div>
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='youtube' && singleSocial.data">
                            <div class="row graph-main-row col-12 paddingo margino">
                                <gen-ratio-graph :activeindex="activetab" :gendata="singleSocial.data"></gen-ratio-graph>
                            </div>
                            <div class="row margino col-12 paddingo social-insight-cards">
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.viewCount">      
                                    <div class="profile-detail-card-inner total-views">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalviews.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.viewCount}}</text-component>
                                            <br><text-component class="dark">Total Views</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.commentCount">      
                                    <div class="profile-detail-card-inner total-comments">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalcomments.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.commentCount}}</text-component>
                                            <br><text-component class="dark">Total Comments</text-component>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelDataFromBegin.rows[0][3]">      
                                    <div class="profile-detail-card-inner red-views">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/redviews.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.channelDataFromBegin.rows[0][3]}}</text-component>
                                            <br><text-component class="dark">Red Views</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelData.rows[0][5]">      
                                    <div class="profile-detail-card-inner subscriber-gained">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/video.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][5]}}</text-component>
                                            <br><text-component class="dark">Subscribers Gained</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelData.rows[0][6]">      
                                    <div class="profile-detail-card-inner subscriber-lost">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/subscriberslost.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][6]}}</text-component>
                                            <br><text-component class="dark">Subscribers Lost</text-component>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.subscriberCount">      
                                    <div class="profile-detail-card-inner subscriber-total">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.subscriberCount}}</text-component>
                                            <br><text-component class="dark">Total Subscribers</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.videoCount">      
                                    <div class="profile-detail-card-inner total-video">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalvideos.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.videoCount}}</text-component>
                                            <br><text-component class="dark">Total Videos</text-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <!-- <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.title">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Channel Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.title}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.viewCount">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Views</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.viewCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.commentCount">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Comments</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.commentCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.subscriberCount">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Subscribers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.subscriberCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.videoCount">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Videos</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.videoCount}}</text-component>
                                </div>
                            </div>
                        </div> -->
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='twitter' && singleSocial.data.basic">
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.name">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                </div>
                            </div>
                            <div class="row col-12 paddingo margino social-insight-cards">
                                <!-- <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.name">      
                                    <div class="profile-detail-card-inner subscriber-total">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                            <br><text-component class="dark">Name</text-component>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.screen_name">
                                    <div class="profile-detail-card-inner subscriber-total">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">@{{singleSocial.data.basic.screen_name}}</text-component>
                                            <br><text-component class="dark">Twitter Id</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                    <div class="profile-detail-card-inner total-followers">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                            <br><text-component class="dark">Total Followers</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.friends_count">
                                    <div class="profile-detail-card-inner total-followers">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.friends_count}}</text-component>
                                            <br><text-component class="dark">Total Friends</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.favourites_count">
                                    <div class="profile-detail-card-inner total-likes">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/Likes.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.favourites_count}}</text-component>
                                            <br><text-component class="dark">Total Likes</text-component>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.location">
                                    <div class="profile-detail-card-inner location">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/locations.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.location}}</text-component>
                                            <br><text-component class="dark">Location</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12" v-if="singleSocial.data.basic.description">
                                    <div class="profile-detail-card-inner bio">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/bio.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.description}}</text-component>
                                            <br><text-component class="dark">Bio</text-component>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.name">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.screen_name">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Twitter Id</text-component>
                                    <text-component class="dark mid">@{{singleSocial.data.basic.screen_name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.followers_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Followers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.friends_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Friends</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.friends_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.favourites_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Likes</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.favourites_count}}</text-component>
                                </div>
                            </div> -->
                        </div>
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='instagram' && singleSocial.data.basic">
                            <div class="row col-12 paddingo margino social-insight-cards">
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.name">      
                                    <div class="profile-detail-card-inner subscriber-total">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                            <br><text-component class="dark">User Name</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.media_count">      
                                    <div class="profile-detail-card-inner birthday">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/page.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.media_count}}</text-component>
                                            <br><text-component class="dark">Total Media</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.followers_count">      
                                    <div class="profile-detail-card-inner age-range">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                            <br><text-component class="dark">Total Follower</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.follows_count">      
                                    <div class="profile-detail-card-inner email">
                                        <div class="card-image-div">
                                            <img src="../../../../assets/image/card-email.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                        </div>
                                        <div class="card-detail-txt">
                                            <text-component class="dark mid">{{singleSocial.data.basic.follows_count}}</text-component>
                                            <br><text-component class="dark">Total Follows</text-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.name">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.username">
                                <div class="row p-info">
                                    <text-component class="subtitle2">User Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.username}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.media_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Media</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.media_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.followers_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Followers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="singleSocial.data.basic.follows_count">
                                <div class="row p-info">
                                    <text-component class="subtitle2">Total Follows</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.follows_count}}</text-component>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </header-tabs>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
// import RatingComponent from "../../../../BasicComponents/rating/rating.vue";
import TabComponent from "../../../../BasicComponents/Tabs/Tabs.vue";
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import genRatioGraph from "../genratiograph.vue"
import { eventBus } from "../../../../main";
import { mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        ButtonComponent,
        // TabComponent,
        // RatingComponent,
        HeaderTabs,
        genRatioGraph
    },
    data(){
        return {
            maxLimit: 8,
            isHiddenbar: false,
            activetab: 'Instagram',
            rating: 5,
            btninvitecls: "btnprimary",
            tabdata: [],
            socialInsightsData:[],
            lastSync:null,
        }
    },
    created: function() {
        eventBus.$on("changeActivetab",function(selected) {
            // console.log('selected',selected)
            // this.activetab = selected;
            for (let i = 0; i < this.tabdata.length; i++) {
                if(this.tabdata[i].value == selected || this.tabdata[i].key == selected){
                    this.activetab = selected
                }
            }
        }.bind(this));

        this.getData();
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getsocialinsights","fetchsocialinsights"]),
        
        fetchLatest(){
            this.fetchsocialinsights({id:this.$route.params.id})
            .then(response=>{
                this.socialInsightsData = response.data.data;
                this.lastSync = this.formatDateGlobal(response.data.last_update);
                let tabData = [];
                let socialData = response.data.data;
                for (let i = 0; i < socialData.length; i++) {
                    if(i==0){
                        this.activetab = socialData[i].plateform+i;
                    }
                    tabData.push({id:i,key:socialData[i].plateform+i,value:this.capitalizeWord(socialData[i].plateform)})
                }
                this.tabdata = tabData;
            });
        },

        getData(){
            this.getsocialinsights({id:this.$route.params.id})
            .then(response=>{
                this.socialInsightsData = response.data.data;
                this.lastSync = this.formatDateGlobal(response.data.last_update);
                let tabData = [];
                let socialData = response.data.data;
                for (let i = 0; i < socialData.length; i++) {
                    if(i==0){
                        this.activetab = socialData[i].plateform+i;
                    }
                    tabData.push({id:i,key:socialData[i].plateform+i,value:this.capitalizeWord(socialData[i].plateform)})
                }
                this.tabdata = tabData;
            });
        },

        capitalizeWord(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
}
</script>