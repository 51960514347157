import VueRouter from "vue-router";
import Vue from 'vue';

import AuthLayout from '../layouts/authlayout.vue';

import ThankYou from '../layouts/thankyou.vue';
import Page404 from "../pages/common_users/404";
import ResetPassword from '../pages/influencer/auth/Resetpassword.vue'

import LoginPage from '../pages/influencer/auth/Login.vue';
import ForgotPage from '../pages/influencer/auth/Forgot.vue';
import SignUpPage from '../pages/influencer/auth/SignUp.vue';

import AgencyLoginPage from '../pages/agency/auth/Login.vue';
import AgencyForgotPage from '../pages/agency/auth/Forgot.vue';
import AgencySignUpPage from '../pages/agency/auth/SignUp.vue';

import BrandRegister from '../pages/brand/auth/SignUp.vue';
// import Home from "../pages/cms/home.vue";

import PublisherRegister from '../pages/publisher/auth/SignUp.vue';

import adminRoutes from "./admin";
import influencerRoutes from "./influencer";
import agencyRoutes from "./agency";
import brandRoutes from "./brand";
import publisherRoutes from "./publisher";
import frontend from "./frontend.js";
import AuthnotRequiered from "../utils/AuthnotRequiered";

Vue.use(VueRouter);

const routes = [
  adminRoutes,
  influencerRoutes,
  agencyRoutes,
  brandRoutes,
  publisherRoutes,
  frontend,
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: ThankYou,
  },
  {
    path: "/",
    component: AuthLayout,
    beforeEnter: AuthnotRequiered,
    children: [
      {
        path: "/login",
        name: "Login",
        component: LoginPage,
        meta: {
          title: "Login",
        },
      },
      {
        path: "/forgotpassword",
        name: "ForgotPage",
        component: ForgotPage,
      },
      {
        path: "/resetpassword/:token/:email",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/signup",
        name: "SignUpPage",
        component: SignUpPage,
        meta: {
          title: "Sign Up",
        },
      },
      {
        path: "/agency/login",
        name: "AgencyLoginPage",
        component: AgencyLoginPage,
      },
      {
        path: "/agency/forgotpassword",
        name: "AgencyForgotPage",
        component: AgencyForgotPage,
      },
      {
        path: "/agency/signup",
        name: "AgencySignUpPage",
        component: AgencySignUpPage,
      },
      {
        path: "/brand/signup",
        name: "BrandSignUpPage",
        component: BrandRegister,
      },
      {
        path: "/publisher/signup",
        name: "PublisherSignUpPage",
        component: PublisherRegister,
      },
    ],
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: Home,
  // },
];
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  // linkExactActiveClass: "nav-item active"
  scrollBehavior() {
      return {x: 0, y: 0}
  }
});
router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0);
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
  next();
});

export default router;