<template>
  <div>
    <div class="popover" id="popover-scrollbar">
      <ul class="search-bar-ul">
        <li v-for="data in popoverList" :key="data.id">
          <a>
            <checkbox-field :checkboxselectid="data.id" v-model="cbData" :val="(data.id)?data.id:data.value" class="cbox">{{data.value}}</checkbox-field>
          </a>
        </li>
        <li>
          <a @click="clearCheckbox(); checkLink();">
            <link-button :clsname="clsname">
              <slot />
            </link-button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CheckboxField from "../Checkbox/defaultCheckbox.vue";
import LinkButton from "../Button/Button.vue";
export default {
  components: {
    CheckboxField,
    LinkButton
  },

  props: {
    popoverList: Array,
    checkboxselectid: String,
    clsname: String
  },

  data() {
    return {
      checkId: "",
      cbData: [],
      cb: "btncb",
    };
  },
  watch:{
    cbData:{
      immediate:false,
      handler(cbData){
        this.$emit('input', cbData)
      }
    }
  },

  methods: {
    clearCheckbox: function() {
      // let result = [];
      // let i = 0;
      // let newId = this.popoverList;
      // for (let key in newId) {
      //   result[i] = newId[key];
      //   document.getElementById(this.cb + result[i].id).checked = false;
      // }
      this.cbData = []
    },

    checkLink: function(value) {
      this.$emit("onCheckClick", value);
    },
    click:function(){
      console.log('asdasd clicked')
    }
  }
};
</script>