<template>
    <div class="publisher-dashboard">
        <div class="content-header">
            <span class="h2">Dashboard</span>
        </div>
        <div class="content">
            <request-article></request-article>
        </div>
    </div>
</template>
<script>
import RequestArticle from "./articlerequest.vue"
export default {
   components: {
       RequestArticle
   } 
}
</script>