<template>
    <div class="rating">
        <div class="rating-main" v-for="(star, index) in stars" :key="index" :class="index">
            <div v-if="row.rating%1 == 0">
                <img v-if="index<row.rating" src="../../../../assets/image/starfill.svg" alt="star-fill">
                <img v-if="index>=row.rating" src="../../../../assets/image/star.svg" alt="star">
            </div>
            <div v-if="row.rating%1 !== 0">
                <img v-if="index+1<row.rating && index+1 != getHalfRating" src="../../../../assets/image/starfill.svg" alt="star-fill">
                <img v-if="index+1 == getHalfRating && halfStar == 'full'" src="../../../../assets/image/starfill.svg" alt="star-fill">
                <img v-if="index+1 == getHalfRating && halfStar == 'half'" src="../../../../assets/image/half-fill-star.svg" alt="star-half-fill">
                <img v-if="index+1 == getHalfRating && halfStar == 'none'" src="../../../../assets/image/star.svg" alt="star">
                <img v-if="index+1>row.rating && index+1 != getHalfRating" src="../../../../assets/image/star.svg" alt="star">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["row"],
    data() {
        return{
            stars: 5,
        }
    },
    computed: {
        getHalfRating() {
            return Math.ceil(this.row.rating)
        },
        halfStar() {
            let finalrate = ""
            if(this.row.rating%1 !== 0) {
                let splitrating = this.row.rating%1
                console.log(Math.ceil(splitrating*10))
                if(Math.ceil(splitrating*10) < 3){
                    finalrate = "none"
                }
                else if (Math.ceil(splitrating*10) >= 3 && Math.ceil(splitrating*10) <= 7) {
                    finalrate = "half"
                }
                else if(Math.ceil(splitrating*10) > 7){
                    finalrate = "full"
                }
            }
            return finalrate
        }
    },
}
</script>