<template>
    <div class="menudesktop">
        <div class="searchvendor-content-left smallMenu" id="desktopmenu">
          <div class="menu-inner">
            <div class="main-logo" id="mlogo">
              <img src="../assets/image/main-logo.svg" alt="VidUnit-logo" class="vidunitlogo"/>
            </div>
            <navigation-component :navdata="navdata"></navigation-component>
          </div>
        </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "1",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "publisherdashboard"
        },
        {
          id: "2",
          item: "Publish Content",
          imgpath: "report.svg",
          name: "publishercontentlist"
        },
        {
          id: "3",
          item: "Website Traffic",
          imgpath: "report.svg",
          name: "publisherwebsitetrafficlist"
        },
        {
          id: "4",
          item: "Support",
          imgpath: "chat.svg",
          name: "publishersupport"
        },
      ]
    };
  },
};
</script>
