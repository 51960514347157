<template>
    <div class="user-icon">
        <img src="../../../assets/image/usericon.png" alt="user-image">
    </div>
</template>

<script>
// import { eventBus } from "../../main";
export default {
    props: ["row"],
    components: {
        
    },
    data() {
        return {

        };
    },
};
</script>
