<template>
    <div class="actionbtn">
        <button-component v-if="checkPermission('rating_action')" @onBtnClick="onClick(row)" :clsname="plaincls">Rate Influencer</button-component>
        <button-component v-if="checkPermission('rating_detail')" @onBtnClick="onView(row.id)" class="view-rating" :clsname="plaincls">View</button-component>
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from '../../../BasicComponents/Button/Button.vue';
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      plaincls: 'btnplain'
    };
  },
  methods: {
    onClick() {
      this.$root.$emit('onClick', this.row)
    },
    onView(id){
      this.$router.push({name: "adminratingdetail",params: { id: id }});
    }
  },
};
</script>
