<template>
    <div class="action-btns">
        <img @click="onDelete(row)" src="../../../assets/image/delete.svg" alt="delete-img">
        <img 
            @click="toList(row)" 
            src="../../../assets/image/list.svg" 
            style="margin-left:16px" 
            class="list-img"
            width="16px"
            height="16px">
    </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
export default {
    props: ["row"],
    methods: {
        ...mapActions("publisherdata", ["deletearticlerequest"]),

        toList(row) {
            this.$router.push({name: "adminrequestedarticlelist",params: { id: row.id }});
        },

        onDelete(row) {
            let isDelete = confirm("Are you sure?")
            if(isDelete){
                this.deletearticlerequest({
                id: row.id,
                }).then(response => {
                    this.$root.$emit('adminRequestDeleteClick', row)
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        onApprove(row) {
            console.log(row)
            // this.approveorrejectcontent({
            //     id: row.id,
            //     status: "1",
            // }).then(response => {
            //     this.$root.$emit('adminContentDeleteClick', row)
            //     this.$toast.open({
            //         message: response.message,
            //         type: "success",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            // })
            // .catch(err => {
            //     this.$toast.open({
            //         message: err.response.data.message,
            //         type: "error",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            // });
        },
        onReject(row) {
            console.log(row)
            // this.approveorrejectcontent({
            //     id: row.id,
            //     status: "2",
            // }).then(response => {
            //     this.$root.$emit('adminContentDeleteClick', row)
            //     this.$toast.open({
            //         message: response.message,
            //         type: "success",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            // })
            // .catch(err => {
            //     this.$toast.open({
            //         message: err.response.data.message,
            //         type: "error",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            // });
        },

        onView(row){
            
            this.$root.$emit('adminContentViewClick', row)
        }
    }
}
</script>