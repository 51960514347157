<template>
    <div class="influencer influencer-data brand-data">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Publishers</text-component>
                <div v-if="activetab != 'All Registration'" class="page-header-right">
                    <button-component :clsname="btnprimary">
                        <export-excel
                            class   = "btn btn-default"
                            :data   = "json_data"
                            :fields = "json_fields"
                            worksheet = "My Worksheet"
                            name    = "filename.xls">
                            Generate Report
                        </export-excel>
                    </button-component>
                </div>
            </div>
        </div>
        <div class="box paddingo">
            <div class="box-content">
                <header-tabs
                    :tabdata="tabdata"
                    :activetab="activetab"
                    :maxLimit="maxLimit"
                    :isHiddenbar="isHiddenbar"
                    >
                    <div class="tag-bar-bottom-data" v-if="activetab === 'New Registration'">
                        <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'All Registration'">
                        <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="brandcolumns"
                            :rows="brandrows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                            @onrowclick="toPublisherDetail"
                        ></datatable-component>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'Rejected Registration'">
                        <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="brandrejectcolumns"
                            :rows="brandrejectrows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                        </div>
                    </div>
                </header-tabs>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import Action from "./action.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import DetailAction from "./detail/detailaction.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { eventBus } from "../../../main";
import { mapActions } from "vuex";
export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        TextComponent,
        ButtonComponent
    },
    data() {
        return {
            activetab: "All Registration",
            maxLimit: 9,
            h2txtclass: "h2",
            isHiddenbar: false,
            isChat: false,
            btnprimary: "btnprimary",
            subtitle2class: "subtitle2",
            midclass: "mid",
            txtclassname: "default",
            actionid: "moreactionbtn",
            classname: "default prefixicon",
            page: 1,
            per_page: 8,
            type: "text",
            inputPlaceholder: "Search Publisher",
            filter: "",
            to: "",
            selectedRows: [],
            columns: [
                {
                    label: "Publisher Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Email",
                    field: "email",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Contact",
                    representedAs: row => {
                        return row.prefix +' '+ row.phone;
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Action",
                    headerAlign: "left",
                    align: "left",
                    component: Action,
                },
            ],
            rows: [],
            brandcolumns: [
                {
                    label: "Publisher Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Email",
                    field: "email",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Domain",
                    // field: "domain_name",
                    representedAs: row => {
                        return row.domain_name?row.domain_name:'-';
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Contact",
                    representedAs: row => {
                        return row.prefix +' '+ row.phone;
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Status",
                    representedAs: function (row) {
                        return (row.admin_status==1)?'Active':'Inactive';
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (DetailAction)},
            ],
            brandrejectcolumns: [
                {
                    label: "Publisher Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Email",
                    field: "email",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
                {
                    label: "Contact",
                    representedAs: row => {
                        return row.prefix +' '+ row.phone;
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false,
                },
            ],
            brandrejectrows: [],
            brandrows: [],
            tabdata: [
                { id: "1", value: "All Registration" },
                { id: "2", value: "New Registration" },
                { id: "3", value: "Rejected Registration" },
            ],
            json_fields: {
            'Publisher Name': 'name',
            'Email': 'email',       
            },
            json_data: [],
        };
    },
    watch: {
        activetab:{
            immediate: true,
            handler(activetab){
                if(activetab == 'All Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.brandrows.length; i++) {
                        this.json_data.push(
                            {"name": this.brandrows[i].name, "email": this.brandrows[i].email}
                        )
                    }
                }
                else if (activetab == 'New Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.rows.length; i++) {
                        this.json_data.push(
                            {"name": this.rows[i].name, "email": this.rows[i].email}
                        )
                    }
                }
                else if (activetab == 'Rejected Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.brandrejectrows.length; i++) {
                        this.json_data.push(
                            {"name": this.brandrejectrows[i].name, "email": this.brandrejectrows[i].email}
                        )
                    }
                }
            }
        },
    },
    created: function () {
        if(!this.checkPermission('publisher_action')){
            this.brandcolumns.splice((this.brandcolumns.length-1), 1)
        }
        this.$root.$on("onClick", () => {
        if (this.isChat == false) {
            this.isChat = true;
        } else {
            this.isChat = false;
        }
        });

        this.$root.$on("statuschanged", () => {
        this.getNewRegistration().then((response) => {
            this.$data.rows = response.data;
        });

        this.getAllRegistration().then((response) => {
            this.$data.brandrows = response.data;
        });

        this.getRejectedRegistration().then((response) => {
            this.$data.brandrejectrows = response.data;
        });
        });

        eventBus.$on(
        "changeActivetab",
        function (selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );

        this.getNewRegistration().then((response) => {
            this.$data.rows = response.data;
        });

        this.getAllRegistration().then((response) => {
            this.$data.brandrows = response.data;
            if(this.activetab == 'All Registration'){
                this.json_data = []
                for (let i = 0; i < this.brandrows.length; i++) {
                    this.json_data.push(
                        {"name": this.brandrows[i].name, "email": this.brandrows[i].email}
                    )
                }
            }
        });

        this.getRejectedRegistration().then((response) => {
            this.$data.brandrejectrows = response.data;
        });

        eventBus.$on('afterPublisherAction', function (row) {
            this.getAllRegistration().then((response) => {
                this.$data.brandrows = response.data;
                if(this.activetab == 'All Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.brandrows.length; i++) {
                        this.json_data.push(
                            {"name": this.brandrows[i].name, "email": this.brandrows[i].email}
                        )
                    }
                }
            });
        }.bind(this));
        
    },
    methods: {
        ...mapActions("adminpublisherdata", [
        "getNewRegistration",
        "getAllRegistration",
        "getRejectedRegistration",
        ]),
        
        sendMessege() {
            if (this.isChat == false) {
                this.isChat = true;
            } else {
                this.isChat = false;
            }
        },

        toPublisherDetail(row){
            // console.log(row)
            this.$router.push({name: "publisherdetailpage",params: { id: row.id }});
        }
    },
};
</script>