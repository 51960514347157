<template>
  <form @submit.prevent="influencerInvite">
    <div class="influencerlist">
      <div class="content-header">
        <div class="page-header">
          <text-component :class="h2txtclass">Influencer List</text-component>
          <div class="page-header-right">
            <button-component @onBtnClick="popUp" :clsname="btninvitecls">Add Influencer</button-component>
          </div>
        </div>
      </div>
      <div class="content padding-topo">
        <div class="box paddingo margino">
          <div class="box-content">
            <div class="datatable-main">
              <div class="data-table-header">
                <div class="data-table-header-inner">
                  <search-bar
                    class="header-searchbar"
                    :classname="classname"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    v-model="filter"
                  ></search-bar>
                </div>
              </div>
              <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :actionid="actionid"
                :columns="columns"
                :cboxindeterminatecls="cboxindeterminatecls"
                :actionText="actionText"
                :rows="rows"
                :clsname="clsname"
                :btnText="btnText"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :dropBtn="dropBtn"
                :dropdownText="dropdownText"
                :statusClear="statusClear"
                :clearClass="clearClass"
                :filter="filter"
                :page="page"
                :per_page="per_page"
                @onrowclick="navigateDetail"
              ></datatable-component>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div :class="{'modal-window': true , 'popupopen': isPopupOpen}">
        <div class="modal-window-main">
          <a title="Close" class="modal-close">
            <img
              @click="popUp"
              src="../../../assets/image/cancel.svg"
              class="cancel-icon"
              alt="cancel-icon"
            />
          </a>
          <div class="popheader">
            <text-component :class="h2txtclass">Add Influencer</text-component>
          </div>
          <div class="row">
            <input-component
              class="txtgroup col-12 col-md-6"
              v-model="item.influencername"
              :helptext="!$v.item.influencername.required && submitted ? validationmessage.influencername: false"
              :labelclass="{'validationerr': !$v.item.influencername.required && submitted}"
              :classname="{'error': !$v.item.influencername.required && submitted, 'default': true}"
            >Influencer Name</input-component>
            <input-component
              class="txtgroup col-12 col-md-6"
              v-model="item.email"
              :helptext="(!$v.item.email.required || !$v.item.email.email) && submitted ? validationmessage.email: false"
              :labelclass="{'validationerr': (!$v.item.email.required || !$v.item.email.email) && submitted}"
              :classname="{'error': (!$v.item.email.required || !$v.item.email.email) && submitted, 'default': true}"
            >Email Address</input-component>
          </div>
          <hr class="hrline" />
          <div class="modal-footer">
            <div>
              <button-component buttontype="submit" :clsname="btninvitecls">Save</button-component>
            </div>
          </div>
        </div>
      </div>
      <!-- /Modal -->
    </div>
  </form>
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./actionbtnconsent.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import SelectRow from "./selectrow.vue";
import SelectAll from "./selectall.vue";
import UserIcon from "./profileicon";
import { eventBus } from "../../../main";
import { required, email } from "vuelidate/lib/validators";
import status from "./statusbadge.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    searchBar,
    ButtonComponent,
    InputComponent,
  },
  validations: {
    item: {
      email: { required, email },
      influencername: { required },
    },
  },
  computed: {
    ...mapGetters("manageinfluencer", ["inviteData"]),
  },
  data() {
    return {
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      txtclassname: "default",
      btninvitecls: "btnprimary",
      acname: "Mahnaz Farzin",
      myacimageurl: "alert.svg",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      h2txtclass: "h2",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      page: 1,
      per_page: 8,
      selectedRows: [],
      isPopupOpen: false,
      columns: [
        // { label: "", component: SelectRow, headerComponent: SelectAll },
        {
          label: "Profile Photo",
          component: UserIcon,
          align: "left",
          headerAlign: "left",
        },
        {
          label: "Influencer’s Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Email Address",
          field: "email",
          align: "left",
          headerAlign: "left",
          sortable: false,
        },
        // {
        //   label: "Status",
        //   field: "status",
        //   align: "left",
        //   headerAlign: "left",
        //   sortable: false,
        // },
        { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
        {
          label: "Action",
          headerAlign: "right",
          align: "right",
          component: ActionBtn,
        },
      ],
      rows: [
        //...
        {
          id: "1",
          influencername: "Lungelo Ngcaba",
          emailaddress: "lngcaba@gmail.com",
        },
        {
          id: "2",
          influencername: "Sammy Lawson",
          emailaddress: "sammylawson01@gmail.com",
        },
        {
          id: "3",
          influencername: "Andries Grootoonk",
          emailaddress: "Grootoonk25@gmail.com",
        },
        {
          id: "4",
          influencername: "Diego Morata",
          emailaddress: "moratadiego101@gmail.com",
        },
        {
          id: "5",
          influencername: "Angela Longoria",
          emailaddress: "angelalongoria14@gmail.com",
        },
        //...
      ],
      dropCatList: [
        { id: "0", value: "Active" },
        { id: "1", value: "Inactive" },
      ],
      submitted: false,
      item: {
        influencername: null,
        email: null,
      },
      validationmessage: {
        influencername: "Please enter name",
        email: "please enter valid email",
      },
    };
  },
  methods: {
    ...mapActions("user", ["inviteInfluencer"]),
    ...mapActions("manageinfluencer", [
      "getinfluencerinvitelist",
      "addSelectedIds",
    ]),

    navigateDetail(row) {
      // console.log(row)
      this.$router.push({name: "agencydetailpage",params: { id: row.id }});
    },

    influencerInvite() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.inviteInfluencer({
          name: this.item.influencername,
          email: this.item.email,
        })
          .then((response) => {
            console.log(response);
            vm.isPopupOpen = false;
            this.submitted = false;
            this.item.influencername = null;
            this.item.email = null;
            vm.getinfluencerinvitelist();
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },
    popUp() {
      if (this.isPopupOpen == true) {
        this.isPopupOpen = false;
      } else {
        this.isPopupOpen = true;
      }
    },
  },
  watch: {
    inviteData: {
      immediate: true,
      handler(inviteData) {
        // console.log(inviteData);
        this.$data.rows = inviteData;
      },
    },

    selectedRows: {
      immediate: false,
      handler(selectedRows) {
        // console.log('asdasdasdasdasdasdasdasd')
        let selectedRowsIds = [];
        selectedRows.forEach((row) => {
          selectedRowsIds.push(row.id);
        });
        this.selectedRowsIds = selectedRowsIds;
        this.addSelectedIds(selectedRowsIds);
      },
    },
  },

  created: function () {
    this.getinfluencerinvitelist();
    this.addSelectedIds([]);

    eventBus.$on(
      "selected",
      function (row) {
        // console.log(row);
        if (this.selectedRows.indexOf(row) === -1) {
          this.selectedRows.push(row);
        }
      }.bind(this)
    );

    eventBus.$on(
      "deselected",
      function (row) {
        if (this.selectedRows.indexOf(row) !== -1) {
          let index = this.selectedRows.indexOf(row);

          this.selectedRows.splice(index, 1);
        }
      }.bind(this)
    );

    eventBus.$on(
      "select-all",
      function (selected) {
        console.log(selected);
        this.selectedRows = this.rows.slice(0);
        // Vue.set(this, "selected_rows", this.rows.slice(0));
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function (selected) {
        console.log(selected);
        this.selectedRows = [];
        // Vue.set(this, "selected_rows", []);
      }.bind(this)
    );
  },
};
</script>
