<template>
  
    <div class="signup-page">
      <div class="row margino">
        <div class="col-12 col-md-6 signup-left">
          <div v-if="!otp_screen">
            <form @submit.prevent="handleRegistration">
              <text-component :textclass="h2class">Register as {{registerAs}}</text-component>
              <div class="row rowpadding noaccount">
                <text-component class="mid">Already have an account?</text-component>
                <router-link to="login">
                  <button-component class="btnlogin" :clsname="btnplainclass">Log in</button-component>
                </router-link>
              </div>
              <select-component
                  class="txtgroup"
                  required="true"
                  :helptext="!$v.item.user_role.required && submitted ? validationmessage.user_role: false"
                  :labelclass="{'validationerr': !$v.item.user_role.required && submitted}"
                  :inputclass="{'error': !$v.item.user_role.required && submitted, 'dropdownmenu': true}"
                  :options="user_role_list"
                  v-model="item.user_role"
                  :multiple="false"
                  :placeholder="selectplaceholder"
                  @handlevalue="selectedUser">
                  User Role
              </select-component>
              <select-component
                  v-if="item.user_role==='4'"
                  class="txtgroup"
                  required="true"
                  :helptext="!$v.item.brand_type.required && submitted ? validationmessage.user_role: false"
                  :labelclass="{'validationerr': !$v.item.brand_type.required && submitted}"
                  :inputclass="{'error': !$v.item.brand_type.required && submitted, 'dropdownmenu': true}"
                  :options="typeList"
                  v-model="item.brand_type"
                  :multiple="false"
                  :placeholder="'Select Type'"
                  @handlevalue="selectedUser">
                  Type
              </select-component>
              <input-component
                required="true"
                class="txtgroup"
                v-model="item.fullname"
                :helptext="!$v.item.fullname.required && submitted ? validationmessage.fullname: false"
                :labelclass="{'validationerr': !$v.item.fullname.required && submitted}"
                :classname="{'error': !$v.item.fullname.required && submitted, 'default': true}"
              >{{nametxt}}</input-component>
              <input-component
                required="true"
                class="txtgroup"
                v-model="item.email"
                :helptext="!$v.item.email.required && submitted ? validationmessage.email: false"
                :labelclass="{'validationerr': !$v.item.email.required && submitted}"
                :classname="{'error': !$v.item.email.required && submitted, 'default': true}"
              >Email address</input-component>
              <div class="phnumberbox">
                <input-group-component
                  required="true"
                  v-model="item.mobile_number"
                  :type="phnumberboxtype"
                  :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.mobile_number: false"
                  :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted}"
                  :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
                >Phone number</input-group-component>
                <div class="countrycode">
                  <dropdown-component
                    v-model="item.mobile_prefix"
                    :popoverList="popoverList"
                    :btnid="btnid"
                  >
                    +91
                    <!-- <img src="../../../assets/image/globe.svg" class="globeicon" /> -->
                  </dropdown-component> 
                </div>
              </div>
              <div v-if="item.user_role==='4' && item.brand_type ==='2'">
                <div class="margin-top16">
                    <div class="inputtxt">
                        <text-component>Requirements*</text-component>
                    </div>
                    <text-area-comp v-model="item.requirements"
                        required="true"
                        :helptext="!$v.item.requirements.required && submitted ? validationmessage.requirements: false"
                        :labelclass="{'validationerr': !$v.item.requirements.required && submitted}"
                        :classname="{'error': !$v.item.requirements.required && submitted, 'default': true,'txtarea':true}"
                    ></text-area-comp>
                </div>
                <button-component class="signupbtn" buttontype="submit" :clsname="btnprimaryclass">Submit</button-component>
              </div>
              <div v-else>
                <input-component
                  required="true"
                  class="txtgroup"
                  v-model="item.password"
                  :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
                  :labelclass="{'validationerr': !$v.item.password.required && submitted}"
                  :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
                  :type="passwordtype"
                >Password</input-component>
                <div class="txtgroup">
                  <checkbox-component
                    v-model="item.selectedval"
                    :val="'term'"
                    :checkboxselectid="'termcond'">
                    agree to terms and conditions 
                  </checkbox-component>
                  <span
                    v-if="!item.selectedval && submitted"
                    class="validationerr"
                  >{{validationmessage.terms_condition}}</span>
                </div>
                <button-component class="signupbtn" buttontype="submit" :clsname="btnprimaryclass">Sign Up</button-component>
                <div class="divider">
                  <div class="divider-txt">
                    <span>OR</span>
                  </div>
                  <hr class="hrdivider" />
                </div>
                <div class="row rowpadding marginBottom">
                  <div class="col-6 colpadding innerpad1">
                    <button-component
                      class="social-login-btn-f"
                      :clsname="btnregularclass"
                      @onBtnClick="loginWithFacebook"
                    >
                    <img src="../../../assets/image/FB.png" class="social-btn-img" />
                    </button-component>
                  </div>
                  <div class="col-6 colpadding innerpad2">
                    <button-component
                      class="social-login-btn-g"
                      :clsname="btnregularclass"
                      @onBtnClick="loginWithGoogle"
                    >
                      <img src="../../../assets/image/btn_google_signin_dark_normal_web.png" class="social-btn-img" />
                      <!-- <img src="../../../assets/image/google.jpg" class="social-login-btn-img" />
                      <div class="socialbtntxt">Google</div> -->
                    </button-component>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else>
            <form @submit.prevent="handleOTPValidation">
              <text-component :textclass="h2class">Verify OTP</text-component>
              <div class="row rowpadding noaccount">
                <text-component class="mid">We have sent you an otp at {{verify.mobile_prefix}} xxx-xxx-{{lastfour(verify.mobile_number)}}</text-component>
              </div>
              
              <otp-input-component
                required="true"
                class="txtgroup"
                v-model="verify.otp"
                :helptext="!$v.verify.otp.required && submittedotp ? validationmessage.otp: false"
                :labelclass="{'validationerr': !$v.verify.otp.required && submittedotp}"
                :classname="{'error': !$v.verify.otp.required && submittedotp, 'default': true}"
                :type="passwordtype"
                :otplen="4"
              >OTP</otp-input-component>
              <button-component class="signupbtn" buttontype="submit" :clsname="btnprimaryclass">Verify</button-component>
            </form>
          </div>
        </div>
        <div class="col-6 signup-right"></div>
      </div>
      <phone-popup ref="phonePopupComp" :userid="idForPhone" :verify=verify></phone-popup>
    </div>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button";
import SelectComponent from "../../../BasicComponents/select/select.vue"
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import OtpInputComponent from "../../../BasicComponents/Input Group/otpInput.vue";
import InputGroupComponent from "../../../BasicComponents/Input Group/inputGroup";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";
import CheckboxComponent from "../../../BasicComponents/Checkbox/defaultCheckbox";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import PhonePopup from "./phonePopup.vue";
import Vue from 'vue'


import { initFbsdk } from "@/config/facebook_oAuth.js";
// import axios from 'axios';
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  props: ["type"],
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    OtpInputComponent,
    DropdownComponent,
    InputGroupComponent,
    SelectComponent,
    CheckboxComponent,
    PhonePopup,
    TextAreaComp
  },
  validations: {
    item: {
      fullname: { required },
      email: { required, email },
      mobile_number: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      mobile_prefix: { required },
      password: { 
        required:requiredIf(function(model) {
          if(model.user_role =='4' && model.brand_type=='2'){
            return false
          }else{
            return true
          }
        })
      },
      user_role: { required },
      selectedval: {
        required:requiredIf(function(model) {
          if(model.user_role =='4' && model.brand_type=='2'){
            return false
          }else{
            return true
          }
        })
      },
      brand_type:{
        required:requiredIf(function(model) {
          if(model.user_role =='4' && model.brand_type=='2'){
            return true
            }else{
            return false
          }
        })
      },
      requirements:{
        required:requiredIf(function(model) {
          if(model.user_role =='4' && model.brand_type=='2'){
            return true
            }else{
            return false
          }
        })
      }
    },
    verify:{
      mobile_prefix: { required },
      mobile_number: { 
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      otp: { required },
    },
    
  },
  mounted() {
    initFbsdk();
    // console.log('The id is: ' + this.$route.params.type);
    if(this.$route.params.type){
      this.initSetType(this.$route.params.type)
    }
    
    this.$watch(
      "$refs.phonePopupComp.verify",
      (new_value, old_value) => {
        this.verify.mobile_number = new_value.mobile_number;
        this.verify.mobile_prefix = new_value.mobile_prefix;
      }
    );
    
    this.$watch(
      "$refs.phonePopupComp.otp_screen",
      (new_value, old_value) => (this.otp_screen = new_value)
    );
    
  },
  data() {
    return {
      idForPhone: null,
      item: {
        fullname: null,
        email: null,
        mobile_prefix: '+91',
        mobile_number: null,
        password: null,
        user_role: null,
        selectedval: null,
        brand_type: null,
        requirements: null,
      },
      verify: {
        mobile_prefix: '+91',
        mobile_number: null,
        otp: null,
      },
      validationmessage: {
        fullname: "full name is required",
        email: "please enter valid email",
        mobile_number: "please enter valid phone number with prefix",
        password: "password is required",
        user_role: 'user role is required',
        otp: 'OTP is required',
        terms_condition: 'please agree to terms and conditions',
        requirements: 'Requirements is required'
      },
      submitted: false,
      submittedotp: false,
      otp_screen: false,
      checkphno: "",
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnbasic",
      btnprimaryclass: "btnprimary",
      selectplaceholder: 'Select role...',
      popoverList: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" }
      ],
      user_role_list: [
        {id:'2', label:'Influencer'},
        {id:'3', label:'Influencer Agency'},
        {id:'4', label:'Brand'},
        {id:'5', label:'Publisher'},
        {id:'6', label:'Community Managers'},
      ],
      typeList: [
        {id:'1', label:'Registration'},
        {id:'2', label:'Enquiry'},
      ],
      nametxt: 'Full Name',
      phnumberboxtype: "number",
      inputgroupclassname: "default",
      btnid: "countrybtn",
      registerAs: "an Influencer"
    };
  },
  methods: {
    ...mapActions("user", ["register", "googleregister", "facebookregister","verifyOTPRegLogin","brandEnquiry"]),
    ...mapActions("commonfunction", ["updateShowLoader"]),

    selectedUser(){
      if(this.item.user_role == '2'){
        this.nametxt = "Full Name"
        this.registerAs = "an Influencer"
        this.validationmessage.fullname = "Full name is required"
      }
      else if(this.item.user_role == '3'){
        this.nametxt = "Company Name"
        this.registerAs = "an Influencer Agency"
        this.validationmessage.fullname = "Company name is required"
      }
      else if(this.item.user_role == '4'){
        this.nametxt = "Brand Name"
        this.registerAs = "a Brand"
        this.validationmessage.fullname = "Brand name is required"
      }
      else if(this.item.user_role == '5'){
        this.nametxt = "Publisher name"
        this.registerAs = "a Publisher"
        this.validationmessage.fullname = "Publisher name is required"
      }
      else if(this.item.user_role == '6'){
        this.nametxt = "Full name"
        this.registerAs = "a Community Manager"
        this.validationmessage.fullname = "Full name is required"
      }
      else{
        this.nametxt = "Full Name"
        this.validationmessage.fullname = "full name is required"
      }
    },

    lastfour(value){
      return value?value.substr(value.length - 4):'';
    },

    loginWithGoogle: async function() {
      let vm = this;
      this.updateShowLoader(true);
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          let googleid = GoogleUser.getId();
          let basicprofile = GoogleUser.getBasicProfile();
          let authresponse = GoogleUser.getAuthResponse();
          let name = basicprofile.getName();
          let email = basicprofile.getEmail();
          basicprofile.email = email;
          basicprofile.name = name;
          basicprofile.googleid = googleid;
          vm.googleregister({
            googleid: googleid,
            basicprofile: basicprofile,
            authresponse: authresponse,
            role_id: "2"
          })
            .then(response => {
              // console.log(response);
              vm.updateShowLoader(false);
              vm.handleSocialLogin(response)
              // vm.$router.push({ name: "Thankyou" });
              console.log("checking");
            })
            .catch(err => {
              vm.updateShowLoader(false);
              vm.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
          // console.log("GoogleUser", GoogleUser);
          // console.log("getId", GoogleUser.getId());
          // console.log("getBasicProfile", GoogleUser.getBasicProfile());
          // console.log("getAuthResponse", GoogleUser.getAuthResponse());
        })
        .catch(error => {
          this.updateShowLoader(false);
          console.log("error", error);
        });
    },

    loginWithFacebook() {
      let vm = this;
      this.updateShowLoader(true);
      window.FB.login(response => {
        let authresponse = response;
        console.log("fb response", response);
        window.FB.api("/me?fields=id,email,name", function(response) {
          let basicprofile = response;
          vm.facebookregister({
            authresponse: authresponse,
            basicprofile: basicprofile,
            role_id: "2"
          })
            .then(response => {
              vm.updateShowLoader(false);
              // console.log(response);
              // vm.$router.push({ name: "Thankyou" });
              vm.handleSocialLogin(response)
              console.log("checking");
            })
            .catch(err => {
              vm.updateShowLoader(false);
              vm.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
          // console.log("me response:", response);
          // console.log("Good to see you, " + response.email + ".");
        });
      });
    },

    handleSocialLogin(response){
      // console.log(response);
      const vm = this;
      if(response.data.otp_screen){
        this.otp_screen = true;
        this.verify.mobile_number = response.data.phone;
        this.verify.mobile_prefix = response.data.prefix;
      }else if(response.data.show_phone_popup){
        this.$root.$emit("openPopup");
        this.idForPhone = response.data.id;
      }else{
        if(response.data.access_token){
          if (response.data.role_id == "2") {
            if (response.data.login_count == "0") {
              vm.$router.push({ name: "MyProfile" });
            } else {
              vm.$router.push({ name: "dashboard" });
            }
          } else if (response.data.role_id == "3") {
            if (response.data.login_count == "0") {
              vm.$router.push({ name: "agencyprofile" });
            } else {
              vm.$router.push({ name: "agencydashboard" });
            }
          } else if (response.data.role_id == "1") {
            vm.$router.push({ name: "admindashboard" });
          } else if (response.data.role_id == "5") {
            vm.$router.push({ name: "publisherdashboard" });
          } else if (response.data.role_id == "4") {
            if (response.data.login_count == "0") {
              vm.$router.push({ name: "brandprofile" });
            } else {
              vm.$router.push({ name: "branddashboard" });
            }
          }
        }
      }
    },

    handleRegistration() {
      // this.updateShowLoader(true);
      // return false;
      // this.$root.$emit("openPopup");
      let vm = this;
      this.submitted = true;
      if(this.item.user_role == '4' && this.item.brand_type=='2'){
        if (this.$v.item.$invalid) {
          console.log('Test log',this.$v.item)
          this.$v.item.$touch();
        } else {
          this.updateShowLoader(true);
          this.brandEnquiry({
            name: this.item.fullname,
            prefix: this.item.mobile_prefix,
            phone: this.item.mobile_number,
            email: this.item.email,
            role_id: this.item.user_role,
            requirements: this.item.requirements,
          })
            .then(response => {
              this.updateShowLoader(false);
              console.log(response);
              vm.$toast.open({
                message: response.message,
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            })
            .catch(err => {
              this.updateShowLoader(false);
              vm.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
        }
      }else{
        if (this.$v.item.$invalid) {
          // console.log('asdasdasda',this.$v.item)
          this.$v.item.$touch();
        } else {
          // console.log('asdasdasda111111111111')
          this.updateShowLoader(true);
          this.register({
            name: this.item.fullname,
            prefix: this.item.mobile_prefix,
            phone: this.item.mobile_number,
            email: this.item.email,
            password: this.item.password,
            role_id: this.item.user_role
          })
            .then(response => {
              this.updateShowLoader(false);
              vm.resetFormFields();
              console.log(response);
              vm.otp_screen = true;
              vm.verify.mobile_number = response.data.phone;
              vm.verify.mobile_prefix = response.data.prefix;
              // vm.$router.push({ name: "Thankyou" });
              // console.log("checking");
            })
            .catch(err => {
              this.updateShowLoader(false);
              vm.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
        }
      }
    },

    handleOTPValidation(){
      this.submittedotp = true;
      if (this.$v.verify.$invalid) {
        this.$v.verify.$touch();
      } else {
        let vm = this;
        this.verifyOTPRegLogin({
          prefix: this.verify.mobile_prefix,
          phone: this.verify.mobile_number,
          otp: this.verify.otp,
        })
          .then(response => {
            console.log(response);
            if (response.data.role_id == "2") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "MyProfile" });
              } else {
                vm.$router.push({ name: "dashboard" });
              }
            } else if (response.data.role_id == "3") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "agencyprofile" });
              } else {
                vm.$router.push({ name: "agencydashboard" });
              }
            } else if (response.data.role_id == "1") {
              vm.$router.push({ name: "admindashboard" });
            } else if (response.data.role_id == "5") {
              vm.$router.push({ name: "publisherdashboard" });
            } else if (response.data.role_id == "6") {
              vm.$router.push({ name: "Thankyou" });
            } else if (response.data.role_id == "4") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "brandprofile" });
              } else {
                vm.$router.push({ name: "branddashboard" });
              }
            }
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    resetFormFields(){
      this.item.fullname =  null;
      this.item.email =  null;
      this.item.mobile_prefix =  '+91';
      this.item.mobile_number =  null;
      this.item.password =  null;
      this.item.user_role =  null;
      this.item.selectedval =  null;
      this.item.brand_type =  null;
      this.item.requirements =  null;
    },

    initSetType(type){
      if(type=='publisher'){
        this.item.user_role = '5';
      }else if(type=='brand'){
        this.item.user_role = '4';
      }else{
        this.item.user_role = '2';
      }
      this.selectedUser();
    }
  },
  created(){
    console.log("openPopup")
  }
  // created() {
  //   console.log(window.location.hostname)
  //   console.log(window.location.href)
  //   console.log(window.location.pathname)
  // },
};
</script>