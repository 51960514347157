<template>
    <div class="action-btn">
        <button-component v-if="row.status == '1'" @onBtnClick="handleStatistic(row)" :clsname="btnplain">Statistics</button-component>
        <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isInvite}">
          <div class="modal-window-main">
            <a title="Close" class="modal-close">
              <img
                @click="closepopup"
                src="../../../../assets/image/cancel.svg"
                class="cancel-icon"
                alt="cancel-icon"
              />
            </a>
            <div class="header">
              <text-component class="h2">Statistics</text-component>
            </div>
            <div class="row message-desc">
              <div class="campaign-data-main col-12">
                <text-component class="subtitle2 col-6">Platform</text-component>
                <text-component class="black campaign-data-txt col-6" :class="platformName">{{platformName}}</text-component>
              </div>
              <div class="campaign-data-main col-12">
                <text-component class="subtitle2 col-6">Link</text-component>
                <text-component class="black campaign-data-txt col-6">{{currentrow.link}}</text-component>
              </div>
            </div>
            <hr class="hrline" />
            <div class="header">
              <text-component class="h4">Details</text-component>
            </div>
            <div class="row message-desc">
              <div v-if="isInsta">
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Views</text-component>
                  <text-component
                    class="black campaign-data-txt col-6"
                  >{{statsdata.reach.values[0].value}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Likes</text-component>
                  <text-component
                    class="black campaign-data-txt col-6"
                  >{{statsdata.engagement.values[0].value}}</text-component>
                </div>
              </div>

              <div v-if="isTwitter">
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Likes</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.favorite_count}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Retweets</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.retweet_count}}</text-component>
                </div>
              </div>

              <div v-if="isYoutube">
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Likes</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.likeCount}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Favorites</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.favoriteCount}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Views</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.viewCount}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Comments</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.commentCount}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Dislikes</text-component>
                  <text-component class="black campaign-data-txt col-6">{{statsdata.dislikeCount}}</text-component>
                </div>
              </div>

              <div v-if="isFacebook">
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Views</text-component>
                  <text-component
                    class="black campaign-data-txt col-6"
                  >{{statsdata.post_engaged_users.values[0].value}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2 col-6">Total Likes</text-component>
                  <text-component
                    class="black campaign-data-txt col-6"
                  >{{getTotal(statsdata.post_reactions_by_type_total.values[0].value)}}</text-component>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue"
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["row"],
  components: {
    ButtonComponent,
    TextComponent
  },
  data() {
    return {
      currentrow: {},
      statsdata: {},
      platformName: "",
      btnplain: "btnplain",
      btnprimary: "btnprimary",
      btndestructive: "btndestructive",
      isInvite: false,
      isInsta: false,
      isTwitter: false,
      isYoutube: false,
      isFacebook: false,
    };
  },
  methods:{
     ...mapActions("admincampaignaction", ["getStatistics"]),

    handleStatistic: async function (row) {
      await this.getStatistics({
        id: row.id,
      }).then((response) => {
        this.statsdata = response.data.data;
      });
      this.currentrow = row;
      this.platformName = row.social_platform.name;
      if (row.social_platform.name == "Instagram") {
        this.isInsta = true;
      } else if (row.social_platform.name == "Twitter") {
        this.isTwitter = true;
      } else if (row.social_platform.name == "Youtube") {
        this.isYoutube = true;
      } else if (row.social_platform.name == "Facebook") {
        this.isFacebook = true;
      }

      if (this.isInvite == true) {
        this.isInvite = false;
      } else {
        this.isInvite = true;
      }
    },

    getTotal(data) {
      let sum = 0;
      if (data) {
        for (var el in data) {
          sum += parseFloat(data[el]);
        }
        return sum;
      } else {
        return sum;
      }
    },
    closepopup() {
      this.isInvite = !this.isInvite;
    },
  }
};
</script>