<template>
  <div>
    <div class="tag-bar-main">
      <div class="tag-bar-main-inner">
        <div class="tag-bar-top-main">
          <span
            class="tag-bar-top-data"
            :class="{selectedtab : ( activetab === tdata.value) || ( activetab === tdata.key)}"
            v-for="(tdata, index) in tabdata"
            :key="index"
            @click="getActiveTab((tdata.key)?tdata.key:tdata.value)"
          >
            <div class="innerdata-show" :class="tdata.value" v-if="tdata.id <= maxLimit">{{tdata.value}}</div>
          </span>
          <button @click="aa" v-if="maxLimit < tabdata.length">...</button>
          <div class="long-tagbar-data" v-show="isHiddenbar">
            <div class="long-tagbar-data-inner">
              <ul>
                <li
                  class="tag-bar-top-data"
                  :class="{selectedtab : activetab === tdata.value}"
                  v-for="(tdata, index) in tabdata"
                  :key="index"
                  @click="getActiveTab((tdata.key)?tdata.key:tdata.value)"
                >
                  <div
                    class="innerdata-hidden"
                    v-if="tdata.id > maxLimit"
                  >{{tdata.value}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tag-bar-bottom-main">
          <div class="tag-bar-bottom-main-inner">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '../../main';
export default {
  name: "Tab",
  props: {
    tabdata: Array,
    activetab: String,
    maxLimit: Number,
  },
  data() {
    return {
      isHiddenbar: false
    };
  },
  methods: {
    getActiveTab: function (currentActive) {
        eventBus.$emit("changeActivetab", currentActive);
    },
    
  }
};
</script>