<template>
    <div class="front-home-page">

        <section class="first">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 static top-14" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section1_image" class="Influencer" :src="getImageURL(baseUrl,cms_image.section1_image)">
                        <img v-else class="Influencer" src="../../../assets/image/front/banner.png">
                        <img v-if="cms_image.section1_image" class="hidden-img" :src="getImageURL(baseUrl,cms_image.section1_image)">
                        <img v-else class="hidden-img" src="../../../assets/image/front/banner.png">
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 influencer-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw392">
                            <h1>{{cms_field.section1_title}}</h1>
                            <router-link class="banner-content" :to="{name:'SignUpPage', params: { type: 'influencer' }}">
                                <p>{{cms_field.section1_1_subtitle}}</p>
                                <span>{{cms_field.section1_1_description}}</span>
                                <div class="content-arrow"></div>
                                <!-- <a class="content-arrow" href="#">Get Started</a> -->
                            </router-link>
                            <router-link class="banner-content" :to="{name:'SignUpPage', params: { type: 'publisher' }}">
                                <p>{{cms_field.section1_2_subtitle}}</p>
                                <span>{{cms_field.section1_2_description}}</span>
                                <div class="content-arrow"></div>
                            </router-link>
                            <router-link class="banner-content" :to="{name:'SignUpPage', params: { type: 'brand' }}">
                                <p>{{cms_field.section1_3_subtitle}}</p>
                                <span>{{cms_field.section1_3_description}}</span>
                                <div class="content-arrow"></div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="second">
            <div class="container">
                <div class="social-box">
                    <div class="imgDiv">
                        <a v-bind:href="cms_field.section2_social_facebook"><img src="../../../assets/image/front/facebook.png"></a>
                    </div>
                    <div class="h-line"></div>
                    <div class="imgDiv">
                        <a v-bind:href="cms_field.section2_social_youtube"><img src="../../../assets/image/front/youtube.png"></a>
                    </div>
                    <div class="h-line"></div>
                    <div class="imgDiv">
                        <a v-bind:href="cms_field.section2_social_instagram"><img src="../../../assets/image/front/insta.png"></a>
                    </div>
                    <div class="h-line"></div>
                    <div class="imgDiv">
                        <a v-bind:href="cms_field.section2_social_twitter"><img src="../../../assets/image/front/twitter.png"></a>
                    </div>
                </div>
                <div class="row">
                    <div class="custom-brand-section col-12 col-sm-12 col-md-12 col-lg-7">
                        <carousel  :smartSpeed="450" :animateOut="'fadeOut'" :animateIn="'fadeIn'" :autoplay="true" :nav="false" :dots="false" :responsive="{0:{items:1,nav:false},768:{items:1,nav:false},992:{items:1,nav:false}}">
                            <div>
                                <div class="page-title brand">
                                    <h2>Brands</h2>
                                </div>
                                <div class="brand-img">
                                    <div class="row">
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_1_1_image" :src="getImageURL(baseUrl,cms_image.section2_1_1_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_1_1_title}}</h2>
                                                    <h3>{{cms_field.section2_1_1_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_1_2_image" :src="getImageURL(baseUrl,cms_image.section2_1_2_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_1_2_title}}</h2>
                                                    <h3>{{cms_field.section2_1_2_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_1_3_image" :src="getImageURL(baseUrl,cms_image.section2_1_3_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_1_3_title}}</h2>
                                                    <h>{{cms_field.section2_1_3_counts}}</h>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_1_4_image" :src="getImageURL(baseUrl,cms_image.section2_1_4_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_1_4_title}}</h2>
                                                    <h3>{{cms_field.section2_1_4_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="page-title brand">
                                    <h2>Publisher</h2>
                                </div>
                                <div class="brand-img">
                                    <div class="row">
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_2_1_image" :src="getImageURL(baseUrl,cms_image.section2_2_1_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_2_1_title}}</h2>
                                                    <h3>{{cms_field.section2_2_1_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_2_2_image" :src="getImageURL(baseUrl,cms_image.section2_2_2_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_2_2_title}}</h2>
                                                    <h3>{{cms_field.section2_2_2_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_2_3_image" :src="getImageURL(baseUrl,cms_image.section2_2_3_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_2_3_title}}</h2>
                                                    <h3>{{cms_field.section2_2_3_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_2_4_image" :src="getImageURL(baseUrl,cms_image.section2_2_4_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_2_4_title}}</h2>
                                                    <h3>{{cms_field.section2_2_4_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="page-title brand">
                                    <h2>Influencer</h2>
                                </div>
                                <div class="brand-img">
                                    <div class="row">
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_3_1_image" :src="getImageURL(baseUrl,cms_image.section2_3_1_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_3_1_title}}</h2>
                                                    <h3>{{cms_field.section2_3_1_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_3_2_image" :src="getImageURL(baseUrl,cms_image.section2_3_2_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_3_2_title}}</h2>
                                                    <h3>{{cms_field.section2_3_2_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_3_3_image" :src="getImageURL(baseUrl,cms_image.section2_3_3_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_3_3_title}}</h2>
                                                    <h3>{{cms_field.section2_3_3_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-img col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div class="social_item_bg">
                                                <img src="../../../assets/image/front/c1.png">
                                            </div>
                                            <div class="brand-content">
                                                <div class="con-img">
                                                    <img v-if="cms_image.section2_3_4_image" :src="getImageURL(baseUrl,cms_image.section2_3_4_image)">
                                                    <img v-else src="../../../assets/image/front/bww.png">
                                                </div>
                                                <div class="custom-content">
                                                    <h2>{{cms_field.section2_3_4_title}}</h2>
                                                    <h3>{{cms_field.section2_3_4_counts}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </carousel>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 about-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>{{cms_field.section2_title}}</h2>
                        <!-- <h3>{{cms_field.section2_subtitle}}</h3> -->
                        <div class="editor_content" v-html="cms_field.section2_description"></div>
                        <!-- <h2>About VidUnit</h2>
                        <p>Donec accumsan quam et justo</p>
                        <span>Aenean commodo lacus et congue pulvinar. Integer eget auctor libero, a scelerisque lorem. Vivamus venenatis libero eu lacinia malesuada. Phasellus tempus, eros nec rhoncus fermentum, metus orci fringilla urna, sit amet accumsan lorem urna ac dui. Fusce at est eu nunc lobortis consectetur id et dui. Nam laoreet, metus ac rhoncus vulputate, erat lacus scelerisque diam, at pellentesque leo leo vel diam. Pellentesque vel ex mattis.</span>
                        <span>Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño. El punto de usar Lorem Ipsum es que tiene una distribución más o menos normal de las letras, al contrario de usar textos como por ejemplo Contenido aquí, contenido aquí.</span> -->
                    </div>
                </div>
            </div>
        </section>

        <section class="three">
            <div class="container">
                <div class="row">
                    <div class="col-12 hiw-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>{{cms_field.section3_title}}</h2>
                        <div class="title-content" v-html="cms_field.section3_description"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_1_subtitle}}</h2>
                                    <p>{{cms_field.section3_1_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_2_subtitle}}</h2>
                                    <p>{{cms_field.section3_2_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_3_subtitle}}</h2>
                                    <p>{{cms_field.section3_3_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_4_subtitle}}</h2>
                                    <p>{{cms_field.section3_4_description}}</p>
                                </div>
                            </div>
                        
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 hiw-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section3_image" :src="getImageURL(baseUrl,cms_image.section3_image)">
                        <img v-else src="../../../assets/image/front/VidUnit Banner-F.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section class="four">
            <div class="container">
                <div class="row">
                    <div class="col-12 brand" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>{{cms_field.section4_title}}</h2>
                    </div>
                </div>
                <div class="row mb56" id="one">
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575" >
                            <div class="flex-class pad-66">
                                <img v-if="cms_image.section4_1_image" :src="getImageURL(baseUrl,cms_image.section4_1_image)" id="oneImg">
                                <img v-else src="../../../assets/image/front/HWB.png" id="oneImg">
                                <h3 class="v-text">— 01</h3>
                            </div>
                            <div class="bottom-text">
                                <h4>{{cms_field.section4_1_subtitle}}</h4>
                                <p>{{cms_field.section4_1_description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb56" id="two">		
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                            <div class="flex-class pad-66">
                                <img v-if="cms_image.section4_2_image" :src="getImageURL(baseUrl,cms_image.section4_2_image)" id="twoImg">
                                <img v-else src="../../../assets/image/front/HWB.png" id="twoImg">
                                <h3 class="v-text">— 02</h3>
                            </div>
                            <div class="bottom-text">
                                <h4>{{cms_field.section4_2_subtitle}}</h4>
                                <p>{{cms_field.section4_2_description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                </div>
                <div class="row mb56" id="three">
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                            <div class="flex-class pad-66">
                                <img v-if="cms_image.section4_3_image" :src="getImageURL(baseUrl,cms_image.section4_3_image)" id="threeImg">
                                <img v-else src="../../../assets/image/front/HWB.png" id="threeImg">
                                <h3 class="v-text">— 03</h3>
                            </div>
                            <div class="bottom-text">
                                <h4>{{cms_field.section4_3_subtitle}}</h4>
                                <p>{{cms_field.section4_3_description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb56" id="four">
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                            <div class="flex-class pad-66">
                                <img v-if="cms_image.section4_4_image" :src="getImageURL(baseUrl,cms_image.section4_4_image)" id="fourImg">
                                <img v-else src="../../../assets/image/front/HWB.png" id="fourImg">
                                <h3 class="v-text">— 04</h3>
                            </div>
                            <div class="bottom-text">
                                <h4>{{cms_field.section4_4_subtitle}}</h4>
                                <p>{{cms_field.section4_4_description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                </div>
            </div>
        </section>

        <section class="five">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 our-value" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>{{cms_field.section5_title}}</h2>
                    </div>
                    <div class="round-section">
                        <img src="../../../assets/image/front/ovalogo.png">
                        <div class="row">
                        <div class="section-one" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="mw376">
                                <img v-if="cms_image.section5_1_image" :src="getImageURL(baseUrl,cms_image.section5_1_image)">
                                <img v-else src="../../../assets/image/front/Call.png">
                                <h2>{{cms_field.section5_1_subtitle}}</h2>
                                <p>{{cms_field.section5_1_description}}</p>
                            </div>
                        </div>
                        <div class="section-two" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="mw376">
                                <img v-if="cms_image.section5_2_image" :src="getImageURL(baseUrl,cms_image.section5_2_image)">
                                <img v-else src="../../../assets/image/front/Call.png">
                                <h2>{{cms_field.section5_2_subtitle}}</h2>
                                <p>{{cms_field.section5_2_description}}</p>
                            </div>
                        </div>
                        <div class="section-three" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="mw376">
                                <img v-if="cms_image.section5_3_image" :src="getImageURL(baseUrl,cms_image.section5_3_image)">
                                <img v-else src="../../../assets/image/front/Call.png">
                                <h2>{{cms_field.section5_3_subtitle}}</h2>
                                <p>{{cms_field.section5_3_description}}</p>
                            </div>
                        </div>
                        <div class="section-four" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="mw376">
                                <img v-if="cms_image.section5_4_image" :src="getImageURL(baseUrl,cms_image.section5_4_image)">
                                <img v-else src="../../../assets/image/front/Call.png">
                                <h2>{{cms_field.section5_4_subtitle}}</h2>
                                <p>{{cms_field.section5_4_description}}</p>
                            </div>
                        </div>
                        <div class="section-five" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="mw376">
                                <img v-if="cms_image.section5_5_image" :src="getImageURL(baseUrl,cms_image.section5_5_image)">
                                <img v-else src="../../../assets/image/front/Call.png">
                                <h2>{{cms_field.section5_5_subtitle}}</h2>
                                <p>{{cms_field.section5_5_description}}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="new-five">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="new-five-img">
                            <img v-if="cms_image.section6_image" :src="getImageURL(baseUrl,cms_image.section6_image)">
                            <img v-else src="../../../assets/image/front/gpimage.png">
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>{{cms_field.section6_title}}</h2>
                        <div v-html="cms_field.section6_description"></div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="six">
            <div class="container">
                <div class="row">
                    <div class="col-12 our-client" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>What Our Clients are Saying</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="review-msg">
                            <img src="../../../assets/image/front/Oval1.png">
                            <p>Praesent rhoncus leo sit amet semper rutrum. Aliquam lobortis lorem nisi, sit amet consectetur dolor feugiat quis. Proin sit amet aliquet neque.</p>
                            <h4>Gabriel Moreira</h4>
                            <span>Marketing Manager at Undertone</span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="review-msg">
                            <img src="../../../assets/image/front/Oval1.png">
                            <p>Praesent rhoncus leo sit amet semper rutrum. Aliquam lobortis lorem nisi, sit amet consectetur dolor feugiat quis. Proin sit amet aliquet neque.</p>
                            <h4>Yeray Rosales</h4>
                            <span>Director of Mrketing at Burrow</span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="review-msg">
                            <img src="../../../assets/image/front/Oval1.png">
                            <p>Praesent rhoncus leo sit amet semper rutrum. Aliquam lobortis lorem nisi, sit amet consectetur dolor feugiat quis. Proin sit amet aliquet neque.</p>
                            <h4>Alex Walker</h4>
                            <span>Content Solution Manager at Influmix</span>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        
        <!-- old -->

        <!-- <section class="first">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 static top-14" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>{{cms_field.section1_title}}</h1>
                        <a class="scroll-down">
                            <p>Scroll Down</p>
                            <img src="../../../assets/image/front/Arrow.png">
                        </a>
                        <img v-if="cms_image.section1_image" class="Influencer" :src="getImageURL(baseUrl,cms_image.section1_image)">
                        <img v-else class="Influencer" src="../../../assets/image/front/Influencer.png">
                        <img v-if="cms_image.section1_image" class="hidden-img" :src="getImageURL(baseUrl,cms_image.section1_image)">
                        <img v-else class="hidden-img" src="../../../assets/image/front/Influencer.png">
                        <div class="social-box">
                            <div class="imgDiv">
                                <img src="../../../assets/image/front/facebook.png">
                            </div>
                            <div class="h-line"></div>
                            <div class="imgDiv">
                                <img src="../../../assets/image/front/youtube.png">
                            </div>
                            <div class="h-line"></div>
                            <div class="imgDiv">
                                <img src="../../../assets/image/front/insta.png">
                            </div>
                            <div class="h-line"></div>
                            <div class="imgDiv">
                                <img src="../../../assets/image/front/twitter.png">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 influencer-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw392">
                            <h2>{{cms_field.section1_subtitle}}</h2>
                            <p>{{cms_field.section1_1_subtitle}}</p>
                            <span>{{cms_field.section1_1_description}}</span>
                            <p>{{cms_field.section1_2_subtitle}}</p>
                            <span>{{cms_field.section1_2_description}}</span>
                            <p>{{cms_field.section1_3_subtitle}}</p>
                            <span>{{cms_field.section1_3_description}}</span>
                            <a href="">
                                <button>Get Started</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="second">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 about-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section2_image" :src="getImageURL(baseUrl,cms_image.section2_image)">
                        <img v-else src="../../../assets/image/front/About.png">
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 about-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>{{cms_field.section2_title}}</h1>
                        <p>{{cms_field.section2_subtitle}}</p>
                        <span>{{cms_field.section2_description}}</span>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="three">
            <div class="container">
                <div class="row">
                    <div class="col-12 hiw-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>{{cms_field.section3_title}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_1_subtitle}}</h2>
                                    <p>{{cms_field.section3_1_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_2_subtitle}}</h2>
                                    <p>{{cms_field.section3_2_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_3_subtitle}}</h2>
                                    <p>{{cms_field.section3_3_description}}</p>
                                </div>
                            </div>

                            <div class="sign-content" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="inner-img">
                                    <img src="../../../assets/image/front/Check2.png">
                                    <div class="u-line"></div>
                                </div>					
                                <div class="inner-content">
                                    <h2>{{cms_field.section3_4_subtitle}}</h2>
                                    <p>{{cms_field.section3_4_description}}</p>
                                </div>
                            </div>
                        
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 hiw-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section3_image" :src="getImageURL(baseUrl,cms_image.section3_image)">
                        <img v-else src="../../../assets/image/front/HWI.png">
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="four">
            <div class="container">
                <div class="row">
                    <div class="col-12 brand" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>How It Works - Brand</h1>
                    </div>
                </div>
                <div class="row mb56" id="one">
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575" >
                        <div class="flex-class pad-66">
                            <img v-if="cms_image.section4_1_image" :src="getImageURL(baseUrl,cms_image.section4_1_image)" id="oneImg">
                            <img v-else src="../../../assets/image/front/HWB.png" id="oneImg">
                            <h3 class="v-text">— 01</h3>
                        </div>
                        <div class="bottom-text">
                            <h4>{{cms_field.section4_1_subtitle}}</h4>
                            <p>{{cms_field.section4_1_description}}</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mb56" id="two">
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                        <div class="flex-class pad-66">
                            <img v-if="cms_image.section4_2_image" :src="getImageURL(baseUrl,cms_image.section4_2_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/HWB.png" id="twoImg">
                            <h3 class="v-text">— 02</h3>
                        </div>
                        <div class="bottom-text">
                            <h4>{{cms_field.section4_1_subtitle}}</h4>
                            <p>{{cms_field.section4_1_description}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                </div>
                <div class="row mb56" id="three">
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                        <div class="flex-class pad-66">
                            <img v-if="cms_image.section4_3_image" :src="getImageURL(baseUrl,cms_image.section4_3_image)" id="threeImg">
                            <img v-else src="../../../assets/image/front/HWB.png" id="threeImg">
                            <h3 class="v-text">— 03</h3>
                        </div>
                        <div class="bottom-text">
                            <h4>{{cms_field.section4_1_subtitle}}</h4>
                            <p>{{cms_field.section4_1_description}}</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mb56" id="four">
                    <div class="col-sm-12 col-md-11 col-lg-8">
                        <div class="flex-class flex-575">
                        <div class="flex-class pad-66">
                            <img v-if="cms_image.section4_4_image" :src="getImageURL(baseUrl,cms_image.section4_4_image)" id="fourImg">
                            <img v-else src="../../../assets/image/front/HWB.png" id="fourImg">
                            <h3 class="v-text">— 04</h3>
                        </div>
                        <div class="bottom-text">
                            <h4>{{cms_field.section4_1_subtitle}}</h4>
                            <p>{{cms_field.section4_1_description}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-4"></div>
                </div>
            </div>
        </section> -->

        <!-- <section class="five">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 our-value" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>Our Value Addition</h1>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw376">
                            <img v-if="cms_image.section5_1_image" :src="getImageURL(baseUrl,cms_image.section5_1_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h2>{{cms_field.section5_1_subtitle}}</h2>
                            <p>{{cms_field.section5_1_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw376">
                            <img v-if="cms_image.section5_2_image" :src="getImageURL(baseUrl,cms_image.section5_2_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h2>{{cms_field.section5_2_subtitle}}</h2>
                            <p>{{cms_field.section5_2_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw376">
                            <img v-if="cms_image.section5_3_image" :src="getImageURL(baseUrl,cms_image.section5_3_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h2>{{cms_field.section5_3_subtitle}}</h2>
                            <p>{{cms_field.section5_3_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw376">
                            <img v-if="cms_image.section5_4_image" :src="getImageURL(baseUrl,cms_image.section5_4_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h2>{{cms_field.section5_4_subtitle}}</h2>
                            <p>{{cms_field.section5_4_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="mw376">
                            <img v-if="cms_image.section5_5_image" :src="getImageURL(baseUrl,cms_image.section5_5_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h2>{{cms_field.section5_5_subtitle}}</h2>
                            <p>{{cms_field.section5_5_description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="six">
            <div class="container">
                <div class="row">
                    <div class="col-12 our-client" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>What Our Clients are Saying</h2>
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-12 col-sm-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <carousel :autoplay="true" :nav="false" :responsive="{0:{items:1,nav:false},768:{items:2,nav:false},992:{items:3,nav:false}}">
                            <div class="review-msg" v-for="(row, index) in testimonials" :key="index" >
                                <img v-if="row.image" :src="row.baseUrl+'/'+row.id+'/'+row.image">
                                <img v-else src="../../../assets/image/front/Oval1.png">
                                <p>{{row.message}}</p>
                                <h4>{{row.name}}</h4>
                                <span>{{row.designation}}</span>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import carousel from 'vue-owl-carousel'
import { mapActions,mapGetters } from "vuex";
// window.onscroll = aa()
export default {
    components: {
        carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:'Home',
                meta_title:null,
                meta_keyword:null,
                meta_desc:'Home',
            },
            cms_field:[],
            cms_image:[],
            testimonials:[],
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail?this.cms_detail.meta_title:'Home',
            meta: [
                { name: 'facebook-domain-verification', content: 'qzimztkvpvjsv49absiekvmzdpr5kp'},
                { name: 'description', content:  (this.cms_detail && this.cms_detail.meta_desc)?this.cms_detail.meta_desc:''},
                { name: 'keyword', content:  (this.cms_detail && this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail && this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    mounted(){
        this.getData();
    },
    created(){
        this.getData();
        this.getTestimonialsData();
        window.addEventListener('scroll', this.handleScroll);
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsHome","testimonialsData"]),
    },
    watch:{
        cmsDetailsHome:{
            immediate:true,
            handler(cmsDetailsHome){
                if (cmsDetailsHome) {
                    this.baseUrl = cmsDetailsHome.baseUrl;
                    this.cms_detail = cmsDetailsHome.cms_detail;
                    this.cms_field = cmsDetailsHome.cms_field;
                    this.cms_image = cmsDetailsHome.cms_image;
                }
            }
        },
        testimonialsData:{
            immediate:true,
            handler(testimonialsData){
                this.testimonials = testimonialsData;
            }
        }
    },
    methods:{
        ...mapActions("frontcms",["getCmsData","getTestimonialsData"]),
        getData(){
            this.getCmsData({slug:'home'});
        },
        handleScroll() {
            // var scrollArrow = document.getElementById("scroll-top-arrow");

            var one = document.getElementById("one");
            var oneImg = document.getElementById("oneImg");

            var two = document.getElementById("two");
            var twoImg = document.getElementById("twoImg");

            var three = document.getElementById("three");
            var threeImg = document.getElementById("threeImg");

            var four = document.getElementById("four");
            var fourImg = document.getElementById("fourImg");


            var sticky1 = one.offsetTop;
            var sticky2 = two.offsetTop;
            var sticky3 = three.offsetTop;
            var sticky4 = four.offsetTop;

            // if (window.pageYOffset >= 100) {
            //     scrollArrow.classList.add("d-block");
            // } 
            // else{
            //     scrollArrow.classList.remove("d-block");
            // }
            if (window.pageYOffset >= (sticky1 - 400)) {
                oneImg.classList.add("sticky");
            } 
            if (window.pageYOffset >= (sticky2 - 400)) {
                twoImg.classList.add("sticky");
            } 
            if (window.pageYOffset >= (sticky3 - 400)) {
                threeImg.classList.add("sticky");
            } 
            if (window.pageYOffset >= (sticky4 - 400)) {
                fourImg.classList.add("sticky");
            } 
        }
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>