<template>
    <span :class="textclass" ><slot/></span> 
</template>



<script>
export default {
    name : "Texts",
    props :{
        textclass: String,
    }
}; 
</script>

<style scoped>


</style>