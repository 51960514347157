<template>
    <div class="campaign-detail-page brand-role">
        <div class="content-header">
            <text-component class="h2">Campaign Detail</text-component>
        </div>
        <div class="content">
            <div class="box margino paddingo">
                <!-- <div class="box-header">
                    
                </div> -->
                <div class="box-content">
                    <header-tabs
                        class="campaign-page-tabs"
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Campaign Detail'">
                            <campaign-detail></campaign-detail>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Influencer'">
                            <approved-influencer></approved-influencer>
                        </div>
                         <div class="tag-bar-bottom-data" v-if="activetab === 'Ratings'">
                            <rating-page></rating-page>
                        </div>
                    </header-tabs>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import CampaignDetail from "./campaindetail.vue";
import SuggestedInfluencer from "./suggestedinfluencer.vue"
import AllInfluencer from "./allinfluencer.vue";
import InProgress from "./inprogress.vue";
import ApprovedInfluencer from "./approvedinfluencer.vue";
import RejectedInfluencer from "./rejectedinfluencer.vue";
import RatingPage from "./ratingpage.vue";
import { eventBus } from "../../../../main";
import {mapActions} from 'vuex';
export default {
    components: {
        HeaderTabs,
        TextComponent,
        CampaignDetail,
        ApprovedInfluencer,
        RatingPage
    },
    data() {
        return {
            activetab: 'Campaign Detail',
            maxLimit: 9,
            isHiddenbar: false,
            campaignData: {},
            tabdata: [],
            tabdata0: [
                { id: "1", value: "Campaign Detail" },
            ],
            tabdata1: [
                { id: "1", value: "Campaign Detail" },
                { id: "3", value: "Influencer" },
            ],
            tabdata2: [
                { id: "1", value: "Campaign Detail" },
            ],
            tabdata3: [
                { id: "1", value: "Campaign Detail" },
                { id: "3", value: "Influencer" },
                { id: "7", value: "Ratings" },
            ],
        }
    },
    watch: {
        campaignData:{
            immediate: true,
            handler(campaignData){
                console.log(campaignData)
                if(campaignData.status == '1'){
                    this.tabdata = this.tabdata1
                }
                else if (campaignData.status == '2'){
                    this.tabdata = this.tabdata2
                }
                else if (campaignData.status == '3'){
                    this.tabdata = this.tabdata3
                }
                else {
                    this.tabdata = this.tabdata0
                }
            }
        }
    },
    methods: {
        ...mapActions("admincampaignaction",["getcampaignbasic"]),

        getcampaigndetails() {
            this.getcampaignbasic({
                id: this.$route.params.id
            }).then(response => {
                this.campaignData = response.data;
            })
        }
    },
    created: function() {
        this.getcampaigndetails()
        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );
    },
}
</script>