<template>
    <div class="action-btn">
        <button-component v-if="iscreated == 'Article Not Found.'" :clsname="'btnplain'" @onBtnClick="goCreateArticle(row)">Create Article</button-component>
        <img v-else @click="showArticle(row)" src="../../../assets/image/list.svg" style="margin-left:16px" width="16px" height="16px" alt="list-img" class="list-image">
    </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    props: ["row"],
    components: {
        ButtonComponent
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                this.requestedarticles({id: row.id})
                .then(response=>{
                    this.iscreated = response.message
                });
            }
        }
    },
    data() {
        return {
            iscreated: null
        }
    },
    methods: {
        ...mapActions("publisherdata", ["requestedarticles"]),

        goCreateArticle(row){
            this.$router.push({name: "publishercontentpublish",params: { id: row.id }});
        },

        showArticle(row) {
            this.$root.$emit('showArticle', row)
        },
    }
}
</script>