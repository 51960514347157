<template>
  <div class="campaign-detail-section">
    <div class="row campaign-detail-section-main">
      <div
        class="col-12 campaign-data" ref="printMe"
        :class="{'col-xl-12':!campaignData.created_by,'col-xl-8':campaignData.created_by}"
      >
        <div class="campaign-data-header">
          <text-component class="subtitle2">Campaign Name</text-component>
          <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
          <div class="campaign-data-header-btn">
            <badge-component class="status-badge" :badgeclass="badgeclass">{{badgetext}}</badge-component>
            <div class="" v-if="campaignData.status=='0'">
              <button-component @onBtnClick="updateStatus(1)" :clsname="primarycls">Accept</button-component>
              <button-component @onBtnClick="updateStatus(2)" :clsname="destructivecls">Reject</button-component>
            </div>
            <div class="" v-if="campaignData.status=='1'">
              <button-component @onBtnClick="endCampaign()" :clsname="primarycls">End Campaign</button-component>
            </div>
          </div>
        </div>
        <div class="camp-detail-header row margino">
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Description:</text-component>
            <text-component class="pre-white-space black campaign-data-txt">{{campaignData.discription}}</text-component>
          </div>
        </div>
        <div class="camp-detail-data row margino">
          <div class="campaign-data-main col-12" v-if="campaignData.hashtags">
            <text-component class="subtitle2">Campaign Hashtags</text-component>
            <text-component class="black tags-card campaign-data-txt"
              v-for="(htags,index) in campaignData.hashtags" :key="index"
              :style="{ background: colors[Math.floor(Math.random() * 6)]}">
              {{htags}}
            </text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Start Date & End Date</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Influencer category</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getCategory(campaignData.influencer_category)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Number of Influencers</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Gender Ratio</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}:{{campaignData.female_ratio}} (M:F)</text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Social Media Platform</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Content Format</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getContentFormat(campaignData.content_format)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brands</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.user?campaignData.user.name:'N/A'}}</text-component>
          </div>
          <div class="campaign-data-main col-12" v-if="campaignData.brand_hashtags">
            <text-component class="subtitle2">Brand Hashtags</text-component>
            <text-component class="black tags-card campaign-data-txt"
              v-for="(btags,index) in campaignData.brand_hashtags" :key="index"
              :style="{ background: colors[Math.floor(Math.random() * 6)]}">
              {{btags}}
            </text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Budget</text-component>
            <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
          </div>
          <!-- <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Name</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Gender Ratio-Male</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Gender Ratio-Female</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.female_ratio}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Start Date & End Date</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Number of Influencers</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Taget Age</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.target_age}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Country</text-component>
            <text-component class="black campaign-data-txt">{{getCountryName(campaignData.country)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Cities</text-component>
            <text-component class="black campaign-data-txt">{{getCityName(campaignData.city)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Influencer category</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getCategory(campaignData.influencer_category)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Social Media Platform</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Content Format</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getContentFormat(campaignData.content_format)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Hashtags</text-component>
            <text-component
              class="black campaign-data-txt"
              v-if="campaignData.hashtags"
            >{{campaignData.hashtags.toString()}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Budget</text-component>
            <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brands</text-component>
            <text-component
              class="black campaign-data-txt"
              v-if="campaignData.brand_hashtags"
            >{{campaignData.brand_hashtags.toString()}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brief Description</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.discription}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Deliverable</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.deliverable}}</text-component>
          </div> -->
        </div>
        <div v-if="campaignData.created_by!='0'">
          <div class="campaign-data-header brand">
            <text-component class="subtitle1">Brand detail</text-component>
          </div>
          <div class="row margino" v-if="campaignData.user">
            <div class="campaign-data-main col-12 col-lg-6">
              <text-component class="subtitle2">Brand Name</text-component>
              <text-component class="black campaign-data-txt">{{campaignData.user.name}}</text-component>
            </div>
            <div class="campaign-data-main col-12 col-lg-6">
              <text-component class="subtitle2">Email</text-component>
              <text-component class="black campaign-data-txt">{{campaignData.user.email}}</text-component>
            </div>
            <div class="campaign-data-main col-12 col-lg-6">
              <text-component class="subtitle2">Contact No.</text-component>
              <text-component
                class="black campaign-data-txt"
              >{{campaignData.user.prefix}} {{campaignData.user.phone}}</text-component>
            </div>
          </div>
        </div>
        <div class="row margino" v-if="(campaignData.status == '1') || (campaignData.status == '3')">
          <div class="datatable-main">
            <div class="table-header">
              <text-component class="subtitle1">Campaign Insights</text-component>
            </div>
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main insight"
              :selected-rows="selectedRows"
              :columns="columns"
              :rows="rows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="filter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
        <div class="row margino" v-if="(campaignData.status == '1') || (campaignData.status == '3')">
          <div class="datatable-main">
            <div class="table-header finance">
              <div class="row margino">
                <text-component class="subtitle1 paddingo col-12 col-lg-4">Campaign finance detail</text-component>
                <div class="row margino col-lg-8 paddingo">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Campaign Budget</text-component>
                    <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Total Cost</text-component>
                    <text-component class="black campaign-data-txt">₹ {{getTotalCost(financerows)}}</text-component>
                  </div>
                </div>
              </div>
            </div>
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main finance"
              :selected-rows="selectedRows"
              :columns="financecolumns"
              :rows="financerows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="financefilter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
      </div>
      <div v-if="campaignData.created_by!='0' && campaignData.created_by != null" class="col-4 campaign-chat" id="chatsection">
        <campaign-chat :brandId="campaignData.created_by"></campaign-chat>
        <div @click="openChat" class="chat-btn">
          <img click src="../../../../assets/image/chat.svg" class="chat-btn-img" />
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="campaignData.campaign_name"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"            
      ref="html2Pdf">
      <section slot="pdf-content" class="campaign-report-pdf row margino">
        <div class="col-12">
            <div class="pdf-header col-12">
            <span class="h2">Campaign Report</span>
            </div>
            <div class="pdf-content">
            <!-- Campaign Report -->
            <div class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">Campaign Name</span>
                <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
            </div>
            <div v-if="campaignData.user" class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">Brand Name</span>
                <span class="campaign-detail-txt">{{campaignData.user?campaignData.user.name:'N/A'}}</span>
            </div>
            <div class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">No. of Influencer</span>
                <span class="campaign-detail-txt">{{campaignData.influencers}}</span>
            </div>
            <div class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">Social Media Platforms</span>
                <span class="campaign-detail-txt">{{getPlatformName(campaignData.social_platform)}}</span>
            </div>
            <div class="row margino">
                <div class="campaign-detail-row col-6">
                <span class="subtitle2 campaign-detail-title">Budget</span>
                <span class="campaign-detail-txt">{{campaignData.budget}}</span>
                </div>
                <div class="campaign-detail-row col-6">
                <span class="subtitle2 campaign-detail-title">Total Influencer Cost</span>
                <span class="campaign-detail-txt">{{getTotalInfluencerCost()}}</span>
                </div>
            </div>
            <hr/>
            <div v-if="bestpdata">
                <div class="campaign-detail-row col-12">
                    <span class="subtitle2 campaign-detail-title">Best Performer Influencer: </span>
                    <span class="campaign-detail-txt">{{bestpdata.social_insight.user_profile?bestpdata.social_insight.user_profile.full_name:'N/A'}}</span>
                </div>
                <div class="campaign-detail-row col-12">
                    <span class="subtitle2 campaign-detail-title">Link: </span>
                    <span class="campaign-detail-txt">{{bestpdata.social_insight.link}}</span>
                </div>
                <div class="campaign-detail-row col-12">
                    <table class="influencer-list">
                        <tr>
                            <th v-if="bestpdata.total_likes">Likes</th>
                            <th>Views</th>
                        </tr>
                        <tr>
                            <td v-if="bestpdata.total_likes">{{bestpdata.total_likes}}</td>
                            <td>{{bestpdata.total_views}}</td>
                        </tr>
                    </table>
                </div>
            </div>
              <div v-else class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">Best Performer Influencer: </span>
                <span class="">No Data Avaliable</span>
            </div>
            <hr/>
            <div class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">Total Campaign Statistics</span>
                <div v-if="byplatform" class="campaign-detail-row col-12 paddingo" style="padding:0px;padding-top:12px">
                    <table 
                        class="influencer-list"
                        v-if="byplatform.facebook || byplatform.instagram || byplatform.twitter|| byplatform.youtube">
                        <tr>
                            <th>Platform</th>
                            <th>Likes</th>
                            <th>Views</th>
                        </tr>
                        <tr v-if="byplatform.facebook">
                            <td>Facebook</td>
                            <td>{{bestpdata.total_likes}}</td>
                            <td>{{bestpdata.total_views}}</td>
                        </tr>
                        <tr v-if="byplatform.instagram">
                            <td>Instagram</td>
                            <td>{{bestpdata.total_likes}}</td>
                            <td>{{bestpdata.total_views}}</td>
                        </tr>
                        <tr v-if="byplatform.twitter">
                            <td>Twitter</td>
                            <td>{{byplatform.twitter.num_likes}}</td>
                            <td>{{byplatform.twitter.num_views}}</td>
                        </tr>
                        <tr v-if="byplatform.youtube">
                            <td>Youtube</td>
                            <td>{{bestpdata.total_likes}}</td>
                            <td>{{bestpdata.total_views}}</td>
                        </tr>
                    </table>
                    <span v-else class="">No Data Avaliable</span>
                </div>
                <span v-else class="">No Data Avaliable</span>
            </div>
            <hr/>
            <div class="campaign-detail-row col-12">
                <span class="subtitle2 campaign-detail-title">List of Influencer</span>
            </div>
            <div v-if="influencerratingdata.length" class="campaign-detail-row col-12">
                <table class="influencer-list">
                    <tr>
                        <th>Name</th>
                        <th>Platform</th>
                        <th>Rating</th>
                        <th>Campaign Cost</th>
                    </tr>
                    <tr v-for="(cinfluencer, index) in influencerratingdata" :key="index">
                        <td>{{cinfluencer.full_name}}</td>
                        <td>{{cinfluencer.socialAccounts.toString()}}</td>
                        <td><rating :rating="cinfluencer.rating"></rating></td>
                        <!-- <td>{{cinfluencer.rating?cinfluencer.rating:'N/A'}}</td> -->
                        <td>{{cinfluencer.final_price}}</td>
                    </tr>
                </table>
            </div>
            <div v-else class="campaign-detail-row col-12">
                <span class="">No Data Avaliable</span>
            </div>
            </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': showAcceptLink}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close">
                <img
                    @click="closepopup"
                    src="../../../../assets/image/cancel.svg"
                    class="cancel-icon"
                    alt="cancel-icon"
                    />
                </a>
                <div class="header">
                    <text-component class="h4">Accept Following Link Data</text-component>
                </div>
                <hr>
                <div class="row message-desc">
                    <div class="col-5">
                      <a v-if="selectedForAccept.screenshot" :href="selectedForAccept.screenshot" target="_blank">
                        <img :src="selectedForAccept.screenshot" style="width:100%;" >
                      </a>
                      <a v-if="selectedForAccept.link" :href="selectedForAccept.link" target="_blank">Link</a>
                    </div>
                    <div class="col-7">
                      <form @submit.prevent="handleAccept">
                        <input-component
                          class="txtgroup col-12"
                          v-model="acceptArray.num_likes"
                          required="true"
                          :helptext="!$v.acceptArray.num_likes.required && submitted ? validationmessage.num_likes: false"
                          :labelclass="{'validationerr': !$v.acceptArray.num_likes.required && submitted}"
                          :classname="{'error': !$v.acceptArray.num_likes.required && submitted, 'default': true}"
                        >No. of Likes</input-component>

                        <input-component
                          class="txtgroup col-12"
                          v-model="acceptArray.num_views"
                          required="true"
                          :helptext="!$v.acceptArray.num_views.required && submitted ? validationmessage.num_views: false"
                          :labelclass="{'validationerr': !$v.acceptArray.num_views.required && submitted}"
                          :classname="{'error': !$v.acceptArray.num_views.required && submitted, 'default': true}"
                        >No. of Views/Comments</input-component>

                        <div class="col-12">
                          <button-component :type='"submit"' :clsname="primarycls+' mr-1'">Submit</button-component>
                        <button-component @onBtnClick="closepopup" :clsname="destructivecls">Cancel</button-component>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
  </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import BadgeComponent from "../../../../BasicComponents/Badge/Badge.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import Action from "./campdetailaction.vue";
import usericon from "./campdetailicon.vue";
import FinanceAction from "./campfinanceaction.vue";
import Financeusericon from "./campfinanceicon.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import Rating from "../../../../BasicComponents/rating/rating.vue";
import CampaignChat from "./campaignchat.vue";
import status from "./linkstatusbadge.vue";
import { mapGetters, mapActions } from "vuex";
import VueHtml2pdf from 'vue-html2pdf'
import { required } from "vuelidate/lib/validators";
import pdfcss from "../../../../assets/css/campaignreportpdf.scss"
// import jsPDF from 'jspdf'
// import VueHtml2Canvas from 'vue-html2canvas';


export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
    CampaignChat,
    ButtonComponent,
    BadgeComponent,
    VueHtml2pdf,
    InputComponent,
    Rating
  },
  computed: {
    ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
    ...mapGetters("commonfunction", [
      "platformData",
      "countryData",
      "cityData",
    ]),
  },
  watch: {
    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = influencerCategory;
      },
    },
    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = platformData;
        this.platforms = platformData
      },
    },
  },
  validations: {
    acceptArray: {
      id: { required },
      num_likes: { required },
      num_views: { required },
    }
  },
  data() {
    return {
      colors: ["#AAEEF6", "#FFB480", "#BDC5ED", "#FFE474", "#BBE5B3", "#FFC4BE"],
      submitted: false,
      showAcceptLink: false,
      selectedForAccept: [],
      acceptArray: {
        id:null,
        num_likes:null,
        num_views:null,
      },
      selectedRows: [],
      campaign_id: null,
      campaignData: [],
      influencecat: [],
      socialplatform: [],
      badgeclass: null,
      badgetext: null,
      validationmessage:{
        num_likes:'Please enter No. of likes',
        num_views:'Please enter No. of views'
      },
      inputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      destructivecls: "btndestructive",
      primarycls: "btnprimary",
      financefilter: "",
      page: 1,
      per_page: 8,
      platforms: [],
      columns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "user.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Platform",
          // field: "plateform_id",
          representedAs: row => {
            return this.getPlatformNameSingle(row.plateform_id);
          },
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Link",
          headerAlign: "left",
          headerClass: "width-5",
          align: "left",
          interpolate: true,
          sortable: false,
          representedAs: (row) =>{
            return '<a href="'+row.link+'" style="text-decoration: none; color: #F49342;" target="_blank">link</a>';
          },
          // representedAs: (row) =>
          //   `<a href="http://${row.link}" style="text-decoration: none; color: #F49342;" target="_blank">link</a>`,
        },
        { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
        {
          label: "Action",
          headerAlign: "left",
          align: "left",
          component: Action,
        },
      ],
      rows: [],
      financecolumns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: Financeusericon,
        },
        {
          label: "Influencer's Name",
          field: "user_profile.full_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Amount",
          field: "final_price",
          align: "right",
          headerAlign: "right",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        // { label: "Action",headerAlign: "left", align: "left", component: (FinanceAction)},
      ],
      financerows: [],
      byplatform: [],
      socialdata: [],
      bestpdata: [],
      campaigninfluencer: [],
      influencerratingdata: [],
    };
  },
  created () {
    this.setinfluencerrating()
    this.getInfluencerList();
    this.getData();
    this.getplatform();
    this.getcampaigninsights();
    this.getcampaignfinance();
    this.getSocialLinkData();
    this.getBestPerformerData();
    this.getBestPerformerByPlatformData();

    this.$root.$on("approveorrejectclicked", () => {
      this.getcampaigninsights();
    });

    this.$root.$on("onGenerateReport", () => {
      this.generateReport();
    });

    this.$root.$on("acceptLink", (row) => {
      console.log('acceptLink',row)
      this.selectedForAccept = row;
      this.acceptArray.id = row.id;
      this.acceptArray.num_views = row.num_views;
      this.acceptArray.num_likes = row.num_likes;
      this.showAcceptLink = true;
      // this.copyelement = row.id;
      // this.$root.$emit("openPopup");
    });
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "getcampaignbasic",
      "updateCampaignStatus",
      "CompleteCampaign",
      "getCampaignFinancelist",
      "getApprovedlist",
      "approveInightInfluencer",
      "getinfluencercampaignrating"
    ]),
    ...mapActions("influencercampaign", ["getLinklist"]),
    ...mapActions("commonfunction", ["getcity","getplatform"]),
    ...mapActions("brandinfluencerdata", ["getcampaignsocial"]),
    ...mapActions("brandcampaign", ["getbestperformer", "getbestperformerbyplatform"]),

    setinfluencerrating(){
      this.getinfluencercampaignrating({
        campaign_id: this.$route.params.id,
      }).then((response) => {
        this.influencerratingdata = response.data
      }); 
    },

    getTotalInfluencerCost() {
      let totalcost = 0
      for (let i = 0; i < this.campaigninfluencer.length; i++) {
        totalcost += this.campaigninfluencer[i].final_price
      }
      return totalcost.toString()
    },

    getPlatformNameSingle(pid){
      let plarformname = null
      for (let i = 0; i < this.platforms.length; i++) {
          if(this.platforms[i].id == pid){
            plarformname = this.platforms[i].name
          }
      }
      return plarformname
    },

    getPlatformName(pid){
      let plarformname = null
      if(pid.length == 1){
          for (let i = 0; i < this.platforms.length; i++) {
              if(this.platforms[i].id == pid){
                  plarformname = this.platforms[i].name
              }
          }
          return plarformname   
      }
      else{
          plarformname = "";
          let splitplatform = pid.split(",");
          // return splitplatform
          for (let i = 0; i < splitplatform.length; i++) {
              for (let j = 0; j < this.platforms.length; j++) {
                  if(this.platforms[j].id == splitplatform[i]){
                      if(i == splitplatform.length-1){
                          plarformname += this.platforms[j].name
                      }
                      else{
                          plarformname += this.platforms[j].name+', '
                      }
                  }
              }
          }
          return plarformname
      }
    },

    getInfluencerList() {
      this.getApprovedlist({
        id: this.$route.params.id,
      }).then((response) => {
        this.campaigninfluencer = response.data;
      });
    },

    getBestPerformerByPlatformData() {
      this.getbestperformerbyplatform({id:this.$route.params.id})
      .then((response) => {
        this.byplatform = response.data
      });
    },

    getSocialLinkData() {
      this.getcampaignsocial({id:this.$route.params.id})
      .then((response) => {
        this.socialdata = response.data
      });
    },

    getBestPerformerData() {
      this.getbestperformer({id:this.$route.params.id})
      .then((response) => {       
        if(response.data.social_insight) {
            this.bestpdata = response.data
        }
        else{
            this.bestpdata = null
        }
      });
    },

    generateReport () {
      // console.log(this.$refs.html2Pdf)
      this.$refs.html2Pdf.generatePdf()
      // this.$refs.html2Pdf.generatePdf()
    },
    onProgress(progress) {
        this.progress = progress;
        console.log(`PDF generation progress: ${progress}%`);
    },

    hasStartedGeneration() {
        console.log(`PDF has started generation`);
    },

    hasGenerated(blobPdf) {
        this.pdfDownloaded = true;
        console.log(`PDF has downloaded yehey`);
        console.log(blobPdf);
    },

    getTotalCost(data) {
      let sum = 0;
      if (data) {
        data.map((value) => {
          sum += value.final_price;
        });
        return sum;
      } else {
        return sum;
      }
    },

   getcampaigninsights() {
      this.getLinklist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.rows = response.data;
      }); 
    },

    getcampaignfinance() {
      this.getCampaignFinancelist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.financerows = response.data;
      });
    },

    getData() {
      this.campaign_id = this.$route.params.id;
      this.getcampaignbasic({ id: this.$route.params.id })
        .then((response) => {
          this.campaignData = response.data;
          this.sendCountryid(response.data.country);
          if(this.campaignData.status == '1'){
            this.badgeclass = 'Success-Badge'
            this.badgetext = 'In Progress'
          }
          else if(this.campaignData.status == '2'){
            this.badgeclass = 'Warning-Badge'
            this.badgetext = 'Rejected'
          }
          else if(this.campaignData.status == '3'){
            this.badgeclass = 'Default-Badge'
            this.badgetext = 'Completed'
          }
          else{
            this.badgeclass = 'Attention-Badge'
            this.badgetext = 'Pending'
          }
        })
        .catch((error) => {
          this.campaignData = [];
        });
    },

    updateStatus(status) {
      let data = { campaign_id: this.campaign_id, status: status.toString() };
      this.updateCampaignStatus(data)
        .then((response) => {
          this.getData();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
        });
    },

    endCampaign() {
      let data = {id: this.$route.params.id};
      this.CompleteCampaign(data).then((response) => {
        this.getData();
        this.$toast.open({
          message: response.message,
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "top",
        });
      })
      .catch((err) => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "top",
        });
      });
    },

    sendCountryid(data) {
      if (data) {
        this.getcity({
          id: data,
        });
      }
    },
    getSocialPlatform(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.socialplatform.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getCategory(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.influencecat.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getContentFormat(id) {
      if (id) {
        let name = this.contentFormat.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCountryName(id) {
      if (id) {
        let name = this.countryData.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCityName(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.cityData.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    openChat() {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },

    // print: async function () {
    //     const doc = new jsPDF();
    //     var canvasElement = document.createElement('printMe');
    //     let output = await this.$html2canvas(this.$refs.printMe, { type: 'dataURL'});
    //     console.log('output',output)
    //     doc.addImage(output,'png',20,20);
    //     doc.save("sample.pdf");
    // }
    closepopup(){
      this.showAcceptLink = !this.showAcceptLink;
    },
    clearData(){
      this.acceptArray =  {
        id:null,
        num_likes:null,
        num_views:null
      }
    },

    handleAccept(){
      this.submitted = true;
      if (this.$v.acceptArray.$invalid) {
        this.$v.acceptArray.$touch();
      } else {
        this.approveInightInfluencer(this.acceptArray)
        .then(response => {
          this.getcampaigninsights();
          this.closepopup();
          this.clearData();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("acceptLink", this.fn);
  }
};
</script>

<style lang="scss">
.campaign-detail-section .dtable-main .dtable table tbody tr td:last-child {
  .btnprimary {
    margin-right: 8px;
    padding: 8px 8px;
  }
  .btndestructive {
    padding: 8px 16px;
  }
}
</style>