<template>
    <div class="actionbtn">
        <button-component @onBtnClick="onClick(row.id)" :clsname="plaincls">View Insight</button-component>
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from '../../../../BasicComponents/Button/Button.vue';
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      plaincls: 'btnplain'
    };
  },
  methods: {
    onClick(id) {
      // console.log(id)
      this.$root.$emit('onClick', this.row)
      this.$router.push({name: "campaignreportdetail",params: { id: id }});
    }
  },
};
</script>
