
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState () {
    let categoryData = [];
    let singleCategory = [];
    return{
        currentUser : {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        categoryData: categoryData,
        singleCategory: singleCategory
    }
    
}

const getters = {
    currentUser: state => state.currentUser,
    categoryData: state => state.categoryData,
    singleCategory: state => state.singleCategory
};

const actions = {
    clearAll: ({commit}) =>{
        commit("setCategoryData",[])
        commit("setSingleCategoryData",[])
    },

    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getcategories: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/category")
                .then((response) => {
                    commit("setCategoryData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getsinglecategory: ({ commit },iddata) => {
        let id = iddata.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + `/category/${id}`)
                .then((response) => {
                    commit("setSingleCategoryData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    createcategory: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/category", {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    updatecategory: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + `/category/${id}`, {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    deletecategory: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/category-multiple-delete", { ...iddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setCategoryData: (state, value) => {
        state.categoryData = value;
    },
    setSingleCategoryData: (state, value) => {
        state.singleCategory = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};