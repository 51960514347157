<template>
    <div class="platform" :class="row.platform">
        {{row.platform}}
    </div>
</template>

<script>
export default {
    props: ["row"],
    components: {
        
    },
    data() {
        return {
            
        };
    },
};
</script>
