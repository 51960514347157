
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let consentData = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        consentData: consentData,
    }

}

const getters = {
    currentUser: state => state.currentUser,
    consentData: state => state.consentData
};

const actions = {
    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getconsentlist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-consent")
                .then((response) => {
                    commit("setConsentData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    setinterest: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/influencer-consent", {...iddata, ...AUTH_PARAMS})
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getapprovedconsentlist: ({ commit}) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-consent-status/1")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getrejectedconsentlist: ({ commit}) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-consent-status/2")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getposttype: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/content-format")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setCurrentUser: (state, value) => {
        state.currentUser = value;
    },
    setCurrentCoa: (state, value) => {
        state.currentCOA = value;
    },
    setConsentData: (state, value) => {
        state.consentData = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};