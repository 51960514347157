<template>
    <div class="userdashboard">
        <!-- <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Dashboard</text-component>
            </div>
        </div> -->
        <div class="content">
            <progress-page></progress-page>
            <!-- <notification-page></notification-page> -->
            <!-- <user-interest></user-interest> -->
            <social-account></social-account>
            <campaign-list-page></campaign-list-page>
            <event-calendar-page></event-calendar-page>
            <campaign-invite-page></campaign-invite-page>
        </div>
    </div>
</template>
<script>
import ProgressPage from './progress.vue';
import NotificationPage from './notification.vue';
import CampaignListPage from './campaignlist.vue';
import EventCalendarPage from './eventcalendar.vue';
import CampaignInvitePage from './campaigninvites.vue';
import UserInterest from "./userinterest.vue";
import SocialAccount from "./socialaccount.vue";
import TextComponent from '../../../BasicComponents/Text/Text.vue';
import { mapActions } from "vuex";
export default {
    components: {
        ProgressPage,
        // NotificationPage, 
        CampaignListPage,
        EventCalendarPage,
        CampaignInvitePage,
        // UserInterest,
        SocialAccount,
        // TextComponent
    },
    data() {
        return{h2txtclass: 'h2',

        }
    },
    created:function () {
        this.fetchsocialinsights();
    },
    methods: {
        ...mapActions("influencerprofile", ["fetchsocialinsights"]),
    },
}
</script>