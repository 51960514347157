<template>
    <img v-if="row.status == '0' || row.status == null" @click="Delete(row)" src="../../../assets/image/delete.svg" alt="delete-icon">
</template>
<script>
import { mapGetters,mapActions } from "vuex";
export default {
    props: ['row'],
    methods: {
        ...mapActions("publisherdata", ["deletearticle", "getarticlelist"]),

        Delete(row) {
            let deleterow = confirm("Are you sure?")
            if(deleterow){
                this.deletearticle({id : row.id})
                .then(response=>{
                    this.$root.$emit('deleteClick', row)
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        }
    }
}
</script>