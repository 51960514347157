<template>
    <form class="profileuploader" >
        <div @dragover.prevent class="profileuploader-inner">
            <input :id="fileuploadid" type="file" accept=".doc,.docx" name="fileUpload"  @change="onFileSelected"/>
            <label :for="fileuploadid" id="file-drag" @drop.prevent="onDrop">
                <div class="imagepreviewdiv">
                    <div v-if="filename == null">Drag or Click to Upload to file</div>
                    <div v-else>{{filename}}</div>
                </div>
            </label>
        </div>
    </form> 
</template>



<script>
export default {
    name : "FileUpload",
    props :["imageurl", "fileuploadid", "avatar"],
    data() {
        return{
            selectedFile: null,
            files: [],
            images: [],
            imageasurl: false,
            filename: null
        }
        
    },
    watch:{
        imageurl:{
            immediate:true,
            handler(imageurl){
                this.imageasurl = this.validURL(imageurl)
            }
        }  
    },
    methods: {
        onFileSelected(e){
            this.$emit('selectfile',e);
            this.filename = e.srcElement.files[0].name
        },
        onDrop(e){ 
            this.$emit('selectfile',e);
        },
        validURL(str) {
            // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            // return !!pattern.test(str);
            try {
                new URL(str);
            } catch (_) {
                return false;  
            }

            return true;
        }
    },
    // computed: {
    //     getwidth() {
    //         return this.width
    //     }
    // }
}; 
</script>


<style scoped>

</style>