<template>
  <div>
    <label class="radio-container" id="con">
      <slot />
      <input :disabled="rbtndisable" type="radio" id="rbtn" name="radio" @click="onRbclick"/>
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    rbtndisable: Boolean
  },
  methods: {
    onRbclick: function(value) {
      this.$emit('onRbtnClick',value)
    }
  }
};
</script>