import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import commonfunction from "./modules/commonFunctions";
import influencerprofile from "./modules/influencer/myprofile";
import admininfluencerprofile from "./modules/admin/influencerprofile";
import agency from "./modules/agency/myprofile";
import manageinfluencer from "./modules/agency/manageinfluencer";
import adminpermission from "./modules/admin/permission";
import admingenre from "./modules/admin/genre";
import admincategory from "./modules/admin/category";
import adminrating from "./modules/admin/rating";
import brandcampaign from "./modules/brand/campaign";
import brandprofile from "./modules/brand/myprofile";
import influencerconsent from "./modules/influencer/consent";
import adminbrandaction from "./modules/admin/branddata";
import admincommunitymanageraction from "./modules/admin/communitymanagerdata";

import admininfluenceraction from "./modules/admin/influencerdata";
import admininfluencerAgencyaction from "./modules/admin/influencerAgencydata";
import adminconcentaction from "./modules/admin/concents";
import adminchattaction from "./modules/admin/chat";
import admincampaignaction from "./modules/admin/campaign";
import influencercampaign from "./modules/influencer/campaign";
import agencyinvoice from "./modules/agency/invoice.js";
import influencerinvoice from "./modules/influencer/invoice.js";
import admininvoice from "./modules/admin/invoice.js";
import adminpublisherdata from "./modules/admin/publisherdata.js";
import brandinfluencerdata from "./modules/brand/influencerdata.js";
import cmsdata from "./modules/cms/cmsdata.js";
import blogdata from "./modules/blog/blogdata.js";
import frontcms from "./modules/front/cms.js";
import frontblog from "./modules/front/blog.js";
import publisherdata from "./modules/publisher/publisher.js";
import websitesetting from "./modules/admin/settings.js";
import createPersistedState from "vuex-persistedstate";
import admintestimonial from "./modules/admin/testimonials.js";
import admincelebrityclient from "./modules/admin/celebrityclient.js";
import Vuelidate from 'vuelidate';
// import StarRating from 'vue-star-rating'
  Vue.use(Vuelidate)
  Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    commonfunction,
    influencerprofile,
    admininfluencerprofile,
    agency,
    manageinfluencer,
    adminpermission,
    admingenre,
    admincategory,
    adminrating,
    brandcampaign,
    brandprofile,
    influencerconsent,
    adminbrandaction,
    admincommunitymanageraction,
    admininfluenceraction,
    admininfluencerAgencyaction,
    adminconcentaction,
    adminchattaction,
    admincampaignaction,
    influencercampaign,
    agencyinvoice,
    influencerinvoice,
    adminpublisherdata,
    admininvoice,
    brandinfluencerdata,
    cmsdata,
    blogdata,
    frontcms,
    frontblog,
    publisherdata,
    admintestimonial,
    websitesetting,
    admincelebrityclient,
  },
  plugins: [createPersistedState()],
});