<template>
<div class="influencer-campaign-detail-page">
  <div class="content-header">
    <text-component class="h2">Influencer Campaign Detail</text-component>
    <div class="page-header-right">
      <button-component 
          @onBtnClick="generateReport" 
          :class="'btnprimary'">
          Download Report
      </button-component>
    </div>
  </div>
  <div class="content">
    <div class="box margino">
      <div class="">
        <div class="campaign-detail-section">
          <div class="row campaign-detail-section-main">
            <div
              class="col-12 campaign-data" ref="printMe" style="max-height: calc(100vh - 210px);">
              <div class="campaign-data-header">
                <text-component class="subtitle2">Campaign Name</text-component>
                <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
                <div class="campaign-data-header-btn">
                  <badge-component class="status-badge" :badgeclass="badgeclass">{{badgetext}}</badge-component>
                  <div class="" v-if="campaignData.status=='0'">
                    <button-component @onBtnClick="updateStatus(1)" :clsname="primarycls">Accept</button-component>
                    <button-component @onBtnClick="updateStatus(2)" :clsname="destructivecls">Reject</button-component>
                  </div>
                  <div class="" v-if="campaignData.status=='1'">
                    <button-component @onBtnClick="endCampaign()" :clsname="primarycls">End Campaign</button-component>
                  </div>
                </div>
              </div>
              <div class="camp-detail-header row margino">
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2">Description:</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.discription}}</text-component>
                </div>
              </div>
              <div class="camp-detail-data row margino">
                <div class="campaign-data-main col-12" v-if="campaignData.hashtags">
                  <text-component class="subtitle2">Campaign Hashtags</text-component>
                  <text-component class="black tags-card campaign-data-txt"
                    v-for="(htags,index) in campaignData.hashtags" :key="index"
                    :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                    {{htags}}
                  </text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2">Start Date & End Date</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Influencer category</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getCategory(campaignData.influencer_category)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Number of Influencers</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2">Gender Ratio</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}:{{campaignData.female_ratio}} (M:F)</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2">Social Media Platform</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
                </div>
                <div class="campaign-data-main col-12">
                  <text-component class="subtitle2">Content Format</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getContentFormat(campaignData.content_format)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Brands</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.user?campaignData.user.name:'N/A'}}</text-component>
                </div>
                <div class="campaign-data-main col-12" v-if="campaignData.brand_hashtags">
                  <text-component class="subtitle2">Brand Hashtags</text-component>
                  <text-component class="black tags-card campaign-data-txt"
                    v-for="(btags,index) in campaignData.brand_hashtags" :key="index"
                    :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                    {{btags}}
                  </text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Budget</text-component>
                  <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
                </div>
                <!-- <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Campaign Name</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Gender Ratio-Male</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Gender Ratio-Female</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.female_ratio}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Start Date & End Date</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Number of Influencers</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Taget Age</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.target_age}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Country</text-component>
                  <text-component class="black campaign-data-txt">{{getCountryName(campaignData.country)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Cities</text-component>
                  <text-component class="black campaign-data-txt">{{getCityName(campaignData.city)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Influencer category</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getCategory(campaignData.influencer_category)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Social Media Platform</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Content Format</text-component>
                  <text-component
                    class="black campaign-data-txt"
                  >{{getContentFormat(campaignData.content_format)}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Campaign Hashtags</text-component>
                  <text-component
                    class="black campaign-data-txt"
                    v-if="campaignData.hashtags"
                  >{{campaignData.hashtags.toString()}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Campaign Budget</text-component>
                  <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Brands</text-component>
                  <text-component
                    class="black campaign-data-txt"
                    v-if="campaignData.brand_hashtags"
                  >{{campaignData.brand_hashtags.toString()}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Brief Description</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.discription}}</text-component>
                </div>
                <div class="campaign-data-main col-12 col-lg-6">
                  <text-component class="subtitle2">Deliverable</text-component>
                  <text-component class="black campaign-data-txt">{{campaignData.deliverable}}</text-component>
                </div> -->
              </div>
              <div v-if="campaignData.created_by!='0'">
                <div class="campaign-data-header brand">
                  <text-component class="subtitle1">Brand detail</text-component>
                </div>
                <div class="row margino" v-if="campaignData.user">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brand Name</text-component>
                    <text-component class="black campaign-data-txt">{{campaignData.user.name}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Email</text-component>
                    <text-component class="black campaign-data-txt">{{campaignData.user.email}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Contact No.</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{campaignData.user.prefix}} {{campaignData.user.phone}}</text-component>
                  </div>
                </div>
              </div>
              <div class="row margino" v-if="(campaignData.status == '1') || (campaignData.status == '3')">
                <div class="datatable-main">
                  <div class="table-header">
                    <text-component class="subtitle1">Campaign Insights</text-component>
                  </div>
                  <div class="data-table-header">
                    <div class="data-table-header-inner">
                      <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                      ></search-bar>
                    </div>
                  </div>
                  <datatable-component
                    class="dtable-main insight"
                    :selected-rows="selectedRows"
                    :columns="columns"
                    :rows="linkdata"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="filter"
                    :page="page"
                    :per_page="per_page"
                  ></datatable-component>
                </div>
              </div>
              <!-- <div class="row margino" v-if="(campaignData.status == '1') || (campaignData.status == '3')">
                <div class="datatable-main">
                  <div class="table-header finance">
                    <div class="row margino">
                      <text-component class="subtitle1 paddingo col-12 col-lg-4">Campaign finance detail</text-component>
                      <div class="row margino col-lg-8 paddingo">
                        <div class="campaign-data-main col-12 col-lg-6">
                          <text-component class="subtitle2">Campaign Budget</text-component>
                          <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
                        </div>
                        <div class="campaign-data-main col-12 col-lg-6">
                          <text-component class="subtitle2">Total Cost</text-component>
                          <text-component class="black campaign-data-txt">₹ {{getTotalCost(financerows)}}</text-component>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="data-table-header">
                    <div class="data-table-header-inner">
                      <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                      ></search-bar>
                    </div>
                  </div>
                  <datatable-component
                    class="dtable-main finance"
                    :selected-rows="selectedRows"
                    :columns="financecolumns"
                    :rows="financerows"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="financefilter"
                    :page="page"
                    :per_page="per_page"
                  ></datatable-component>
                </div>
              </div> -->
            </div>
          </div>
          <vue-html2pdf
            :show-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="'campaignData.campaign_name'"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"            
            ref="html2Pdf">
            <section slot="pdf-content" class="campaign-report-pdf row margino">
                <div class="col-12">
                  <div class="pdf-header col-12">
                    <span class="h2">Influencer Campaign Report</span>
                  </div>
                  <div class="pdf-content">
                    <div v-if="influencercampaindata.influencer" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">Influencer Name</span>
                      <span class="campaign-detail-txt">{{influencercampaindata.influencer?influencercampaindata.influencer.name:'N/A'}}</span>
                    </div>
                    <div v-if="campaignData.campaign_name" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">Campaign Name</span>
                      <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
                    </div>
                    <div v-if="campaignData.campaign_name" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">Brand Name</span>
                      <span class="campaign-detail-txt">{{campaignData.campaign_name}}</span>
                    </div>
                    <div v-if="influencercampaindata.cost" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">No. of Influencer</span>
                      <span class="campaign-detail-txt">{{influencercampaindata.cost[0].total_influencers}}</span>
                    </div>
                    <div v-if="campaignData.social_platform" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">Social Media Platforms</span>
                      <span class="campaign-detail-txt">{{getSocialPlatform(campaignData.social_platform)}}</span>
                    </div>
                    <div class="row margino">
                      <div v-if="campaignData.budget" class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Influencer Cost</span>
                        <span class="campaign-detail-txt">{{campaignData.budget}}</span>
                      </div>
                      <div v-if="influencercampaindata.rating" class="campaign-detail-row col-6">
                        <span class="subtitle2 campaign-detail-title">Campaign Rating</span>
                        <span class="campaign-detail-txt">{{influencercampaindata.rating.avg_rating?influencercampaindata.rating.avg_rating:"N/A"}}</span>
                      </div>
                    </div>
                    <hr/>
                    <div v-if="campaignData.social_platform" class="campaign-detail-row col-12">
                      <span class="subtitle2 campaign-detail-title">Statistics Insights</span>
                      <div class="campaign-detail-row col-12">
                        <table class="influencer-list">
                          <tr>
                            <th>Platform</th>
                            <th>Post URL</th>
                            <th>Views</th>
                            <th>Likes</th>
                            <th>content</th>
                          </tr>
                          <tr v-for="(ldata, index) in linkdata" :key="index">
                            <td>{{getPlatformName(ldata.plateform_id)}}</td>
                            <td>{{ldata.link}}</td>
                            <td>{{ldata.num_views}}</td>
                            <td>{{ldata.num_likes}}</td>
                            <td>{{ldata.contain}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import BadgeComponent from "../../../BasicComponents/Badge/Badge.vue";
// import Action from "./campdetailaction.vue";
// import usericon from "./campdetailicon.vue";
// import FinanceAction from "./campfinanceaction.vue";
// import Financeusericon from "./campfinanceicon.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
// import CampaignChat from "./campaignchat.vue";
// import status from "./linkstatusbadge.vue";
import { mapGetters, mapActions } from "vuex";
import VueHtml2pdf from 'vue-html2pdf'
import pdfcss from "../../../assets/css/campaignreportpdf.scss"
// import jsPDF from 'jspdf'
// import VueHtml2Canvas from 'vue-html2canvas';


export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
    // CampaignChat,
    ButtonComponent,
    BadgeComponent,
    VueHtml2pdf
  },
  computed: {
    ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
    ...mapGetters("commonfunction", [
      "platformData",
      "countryData",
      "cityData",
    ]),
  },
  data() {
    return {
      colors: ["#AAEEF6", "#FFB480", "#BDC5ED", "#FFE474", "#BBE5B3", "#FFC4BE"],
      selectedRows: [],
      campaign_id: null,
      campaignData: [],
      influencecat: [],
      socialplatform: [],
      badgeclass: null,
      badgetext: null,
      inputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      destructivecls: "btndestructive",
      primarycls: "btnprimary",
      financefilter: "",
      page: 1,
      per_page: 8,
      socialdata: [],
      linkdata:[],
      platforms: [],
      statsdata:[],
      influencercampaindata: [],
      columns: [
        // {
        //   label: "Profile Photo",
        //   headerAlign: "left",
        //   align: "left",
        //   component: usericon,
        // },
        {
          label: "Influencer's Name",
          field: "full_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Platform",
          // field: "plateform_id",
          representedAs: row => {
            return this.getPlatformName(row.plateform_id);
          },
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        { 
          label: "Link",
          headerAlign: "left",
          headerClass: "width-5",
          align: "left",
          interpolate: true,
          sortable: false,
          representedAs: (row) =>{
            return '<a href="'+row.link+'" style="text-decoration: none; color: #F49342;" target="_blank">link</a>';
          },
          // representedAs: (row) =>
          //   `<a href="http://${row.link}" style="text-decoration: none; color: #F49342;" target="_blank">link</a>`,
        },
        // { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
        // {
        //   label: "Action",
        //   headerAlign: "left",
        //   align: "left",
        //   component: Action,
        // },
      ],
    };
  },
  watch: {
    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = influencerCategory;
      },
    },
    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = platformData;
        this.platforms = platformData
      },
    },
    
    // socialdata: {
    //   immediate: true,
    //   handler(socialdata) {
    //     this.filtersociallink()
    //   },
    // },
  },
  created () {
    this.getData();
    this.getplatform();
    this.getcampaigninsights();
    this.getcampaignfinance();
    this.getSocialLinkData();
    this.getBestPerformerData();
    this.getBestPerformerByPlatformData();

    this.$root.$on("approveorrejectclicked", () => {
      this.getcampaigninsights();
    });
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "getcampaignbasic",
      "updateCampaignStatus",
      "CompleteCampaign",
      "getCampaignFinancelist",
    ]),
    ...mapActions("influencercampaign", ["getLinklist"]),
    ...mapActions("commonfunction", ["getcity", "getplatform"]),
    ...mapActions("brandinfluencerdata", ["getcampaignsocial"]),
    ...mapActions("brandcampaign", ["getbestperformer", "getbestperformerbyplatform"]),
    ...mapActions("admininfluenceraction", ["getinfluencercampaindata"]),

    getPlatformName(pid){
      let plarformname = null
      for (let i = 0; i < this.platforms.length; i++) {
          if(this.platforms[i].id == pid){
            plarformname = this.platforms[i].name
          }
      }
      return plarformname
    },

    filtersociallink(){
      let status = this.$route.params.influencerid;
        if (status) {
            let filterData = this.socialdata.filter(row=>{
              return row.user_id == status
            });
            this.linkdata = filterData;
        }else{
            this.linkdata = [];
        }
    },
     

    generateReport () {
      // console.log(this.$refs.html2Pdf)
      this.$refs.html2Pdf.generatePdf()
      // this.$refs.html2Pdf.generatePdf()
    },
    onProgress(progress) {
        this.progress = progress;
        console.log(`PDF generation progress: ${progress}%`);
    },

    hasStartedGeneration() {
        console.log(`PDF has started generation`);
    },

    hasGenerated(blobPdf) {
        this.pdfDownloaded = true;
        console.log(`PDF has downloaded yehey`);
        console.log(blobPdf);
    },

    getBestPerformerByPlatformData() {
      this.getbestperformerbyplatform({id:this.$route.params.campaignid})
      .then((response) => {
        this.byplatform = response.data
      });
    },

    getSocialLinkData() {
      this.getcampaignsocial({id:this.$route.params.campaignid})
      .then((response) => {
        this.socialdata = response.data
        this.filtersociallink()
      });
    },

    getBestPerformerData() {
      this.getbestperformer({id:this.$route.params.campaignid})
      .then((response) => {
        this.bestpdata = response.data
      });
    },

    getTotalCost(data) {
      let sum = 0;
      if (data) {
        data.map((value) => {
          sum += value.final_price;
        });
        return sum;
      } else {
        return sum;
      }
    },

    getcampaigninsights() {
      this.getLinklist({
        id: this.$route.params.campaignid,
      }).then((response) => {
        this.$data.rows = response.data;
      });
    },

    getcampaignfinance() {
      this.getCampaignFinancelist({
        id: this.$route.params.campaignid,
      }).then((response) => {
        this.$data.financerows = response.data;
      });
    },

    getData() {
      this.campaign_id = this.$route.params.campaignid;
      this.getcampaignbasic({ id: this.$route.params.campaignid })
        .then((response) => {
          this.campaignData = response.data;
          this.sendCountryid(response.data.country);
          if(this.campaignData.status == '1'){
            this.badgeclass = 'Success-Badge'
            this.badgetext = 'In Progress'
          }
          else if(this.campaignData.status == '2'){
            this.badgeclass = 'Warning-Badge'
            this.badgetext = 'Rejected'
          }
          else if(this.campaignData.status == '3'){
            this.badgeclass = 'Default-Badge'
            this.badgetext = 'Completed'
          }
          else{
            this.badgeclass = 'Attention-Badge'
            this.badgetext = 'Pending'
          }
        })
        .catch((error) => {
          this.campaignData = [];
        });
      this.getinfluencercampaindata({influencer_id: this.$route.params.influencerid, campaign_id: this.$route.params.campaignid})
      .then(response => {
        this.influencercampaindata = response.data;
      });
    },

    updateStatus(status) {
      let data = { campaign_id: this.campaign_id, status: status.toString() };
      this.updateCampaignStatus(data)
        .then((response) => {
          this.getData();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
        });
    },

    endCampaign() {
      let data = {id: this.$route.params.campaignid};
      this.CompleteCampaign(data).then((response) => {
        this.getData();
        this.$toast.open({
          message: response.message,
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "top",
        });
      })
      .catch((err) => {
        this.$toast.open({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "top",
        });
      });
    },

    sendCountryid(data) {
      if (data) {
        this.getcity({
          id: data,
        });
      }
    },
    getSocialPlatform(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.socialplatform.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getCategory(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.influencecat.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getContentFormat(id) {
      if (id) {
        let name = this.contentFormat.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCountryName(id) {
      if (id) {
        let name = this.countryData.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCityName(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.cityData.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    openChat() {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },

    // print: async function () {
    //     const doc = new jsPDF();
    //     var canvasElement = document.createElement('printMe');
    //     let output = await this.$html2canvas(this.$refs.printMe, { type: 'dataURL'});
    //     console.log('output',output)
    //     doc.addImage(output,'png',20,20);
    //     doc.save("sample.pdf");
    // }
  },
};
</script>

<style lang="scss">
.campaign-detail-section .dtable-main .dtable table tbody tr td:last-child {
  .btnprimary {
    margin-right: 8px;
    padding: 8px 8px;
  }
  .btndestructive {
    padding: 8px 16px;
  }
}
</style>