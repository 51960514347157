<template>
    <div>
        <badge
            :badgeclass="{'Attention-Badge':(row.status== null),
            'Attention-Badge':(row.status==null),
            'Success-Badge':(row.status=='1') || (row.status=='4'),
            'Warning-Badge':(row.status=='2'),
            'Information-Badge':(row.status=='3'),'badge': true}">
            <!-- {{row.status}} {{row.date}} -->
            <span v-if="row.status == null">Pending</span>
            <span v-if="row.status == '1'">Published</span>
            <span v-if="row.status == '2'">Rejected</span>
            <span v-if="row.status == '3'">Need Improvment</span>
            <span v-if="row.status == '4'">Improved</span>
        </badge>
    </div>
</template>

<script>
import Badge from '../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],

    data() {
        return {
        }
    },
}
</script>