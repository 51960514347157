
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let chargesData = [];
    let bankData = [];
    let profileData = [];
    let interestData = [];
    let interestListing = [];
    let socialData = [];
    let socialInsights = [];
    return {
        chargesData: chargesData,
        bankData: bankData,
        profileData: profileData,
        interestData: interestData,
        interestListing: interestListing,
        socialData: socialData,
        socialInsights: socialInsights
    }

}

const getters = {
    chargesData: state => state.chargesData,
    bankData: state => state.bankData,
    profileData: state => state.profileData,
    interestData: state => state.interestData,
    interestListing: state => state.interestListing,
    socialData: state => state.socialData,
    socialInsights: state => state.socialInsights
};

const actions = {
    clearAll: ({ commit }) => {
        commit("setChargesData", [])
        commit("setBankData", [])
        commit("setProfileData", [])
        commit("setInterestData", [])
        commit("setInterestListing", [])
        commit("setSocialData", [])
        commit("setSocialInsights", [])
    },

    getbankdetail: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-bank-details/" + id)
                .then((response) => {
                    commit("setBankData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getuserprofile: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-profile/" + id)
                .then((response) => {
                    commit("setProfileData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getchargesdetails: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-charges-details/" + id)
                .then((response) => {
                    commit("setChargesData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getinterests: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-interest/" + id)
                .then((response) => {
                    // commit("setInterestData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getinterestlisting: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-interests")
                .then((response) => {
                    commit("setInterestListing", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getsocialaccount: ({ commit },data={}) => {
        let id = data.id?data.id:0; 
        return new Promise((resolve, reject) => {
            axios
              .get(process.env.VUE_APP_ROOT_API + "/get-social-account/" + id)
              .then((response) => {
                commit("setSocialData", response.data.data);
                console.log(response.data);
                resolve(response.data);
              })
              .catch((error) => {
                if (error.response && error.response.code === 401) {
                  return reject(error.response.message);
                }
                reject(error);
              });
        });
    },

    getsocialinsights: ({ commit }, data) => {
        let id = data.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-social-insights/" + id)
                .then((response) => {
                    commit("setSocialInsights", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    fetchsocialinsights: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/fetch-social-insights/" + id)
                .then((response) => {
                    commit("setSocialInsights", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setChargesData: (state, value) => {
        state.chargesData = value;
    },
    setBankData: (state, value) => {
        state.bankData = value;
    },
    setProfileData: (state, value) => {
        state.profileData = value;
    },
    setInterestData: (state, value) => {
        state.interestData = value;
    },
    setInterestListing: (state, value) => {
        state.interestListing = value;
    },
    setSocialData: (state, value) => {
        state.socialData = value;
    },
    setSocialInsights: (state, value) => {
        state.socialInsights = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};