import Vue from 'vue'
import App from './App.vue'
import { VuejsDatatableFactory } from 'vuejs-datatable';
import './assets/developer.scss'
import router from './routes/routes'
export const eventBus = new Vue();
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VTooltip from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css';
import ToggleButton from 'vue-js-toggle-button'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion';
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import VueGraph from 'vue-graph';
import GraphLine3D from 'vue-graph/src/components/line3d.js';
import NoteWidget from 'vue-graph/src/widgets/note.js';
import LegendWidget from 'vue-graph/src/widgets/legends.js';
require("vue-simple-calendar/static/css/default.css")
require("vue-simple-calendar/static/css/holidays-us.css")
// For Front CSS Start
require("./assets/css/front/style.scss");
require("./assets/css/front/style2.scss");
require("./assets/css/front/responsive.scss");
require("./assets/css/front/responsive2.scss");
// For Front CSS End
import store from "./store"
import Vuelidate from 'vuelidate'
import AOS from "aos";
import "aos/dist/aos.css";
import "./utils/mixin.js";
import excel from "vue-excel-export";
import CKEditor from 'ckeditor4-vue';

import GoogleAuth from '@/config/google.js'
// import VueGAPI from "vue-gapi";
import VueMeta from "vue-meta";
// import GoogleAuth from "vue-google-auth";

  
const gauthOption = {
// const gauthOption I {
  // clientId: '395750950903-2kjmg0pq9khbb0jc2jffkd0uvqljofvi.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
  clientId: '998410677357-sgvatuujq6652taio6urjjd9upjboaam.apps.googleusercontent.com',
}
Vue.use(GoogleAuth, gauthOption)
// Vue.googleAuth().load();


// const apiConfig = {
//   apiKey: "AIzaSyArsGu3l-oMZ8GL_-IKyo72Z4hFePUowGM",
//   clientId:
//     "998410677357-sgvatuujq6652taio6urjjd9upjboaam.apps.googleusercontent.com",
//   access_type: "offline",
//   discoveryDocs: [
//     "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest",
//   ],
//   scope:
//     "https://www.googleapis.com/auth/youtube.force-ssl  https://www.googleapis.com/auth/youtube.readonly",
//   prompt: "select_account",
//   // redirectUri: "http://localhost:8080/",
//   // redirectUri: "https://vidunit.magnetoinfotech.com/backend/api/v1/getgoogledata",
//   refreshToken: true,
// };
// Vue.use(VueGAPI, apiConfig);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
Vue.use(excel);

// Vue.use(VueMeta)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
  // I: true,
});

Vue.mixin({
  methods: {
    checkPermission(data) {
      let getpermission = JSON.parse(localStorage.getItem("auth"));
      if (getpermission.permissions) {
        if (getpermission.permissions.indexOf(data) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true ;
      }
    },
  },
});

Vue.use(Vuelidate)
Vue.component(GraphLine3D.name, GraphLine3D);
Vue.component(NoteWidget.name, NoteWidget);
Vue.component(LegendWidget.name, LegendWidget);
Vue.component('CalendarView', CalendarView)
Vue.component('DatePicker', DatePicker)
Vue.component('CalendarViewHeader', CalendarViewHeader)
Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('datetime', Datetime);
Vue.component('v-select', vSelect)
Vue.use(VTooltip)
Vue.use(ToggleButton)
Vue.use(VueGraph)
Vue.use( CKEditor );

Vue.config.productionTip = false

Vue.use(VuejsDatatableFactory);
// localStorage.clear();
// localStorage.setItem("vuex", '');
// console.log("a", localStorage.getItem("vuex"));
new Vue({
  created() {
    AOS.init();
  },
  el: "#app",
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
