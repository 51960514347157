<template>
    <div class="notification">
        <div class="alert-img">
            <img @click="openNotification" src="../../assets/image/bell.svg" class="bell-icon"/>
            <div v-if="count>0" @click="openNotification" class="notification-count">{{count}}</div>
        </div>
        <div class="notification-data-main" v-if="isDisplay">
            <div class="notification-data-header">
                <text-component class="notification-data-header-txt" :textclass="subtitle2class">Notifications</text-component>
                <!-- <div v-if="!isnewnotification">
                    No new Notification Avaliable
                </div> -->
                <button-component 
                    class="notification-data-header-btn" 
                    :clsname="btnplainclass" 
                    @onBtnClick="readAll">
                    Read All
                    <div></div>
                </button-component>
            </div>
            <hr class="hrnotification"/>
            <div class="col-12 tab-no-data-class" style="margin-top:10px" v-if="latestnotification.length == 0">
                <text-component class="subtitle2">
                    No Notification Avaliable
                </text-component>
            </div>
            <ul>
                <li v-for="notification in latestnotification" 
                    :key="notification.id"
                    :class="(notification.read_status == '0')?'unread':'read'"
                    @click="goTonotification(notification.module, notification.relation_id, notification.id)">
                    <img src="../../assets/image/bell.svg"/>
                    <div class="header-notification-text">
                        {{notification.message}}
                    </div>
                    <div class="header-notification-time">
                        {{timeformessage(notification.created_at)}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../BasicComponents/Button/Button.vue";
import {mapGetters, mapActions} from "vuex";
// import {eventBus} from '../../main';
export default {
    props: ["notificationdata", "role"],
    components: {
        TextComponent,
        ButtonComponent
    },
    computed: {
        ...mapGetters("commonfunction",["notificationData"])
    },
    watch: {
        notificationData: {
            immediate: true,
            handler(notificationData) {
                this.$data.latestnotification = notificationData;
                this.count = 0;
                for (let i = 0; i < this.latestnotification.length; i++) {
                   if(this.latestnotification[i].read_status == 0){
                       this.count++
                   }
                }
            }
        }
    },
    data() {
        return{
            isnewnotification: false,
            latestnotification: [],
            isDisplay: false,
            btnplainclass: 'btnplain',
            subtitle2class: 'subtitle2',
            count: '0'
        }
    },
    created() {
        this.getlatestnotifications();
        window.addEventListener("click", this.close);
        this.newNotification()
    },

    beforeDestroy() {
        window.removeEventListener("click", this.close);
    },
    methods: {

        ...mapActions("commonfunction",["getlatestnotifications", "notificationstatus"]),

        goTonotification(modules, relation_id, id) {
            this.notificationstatus({
                id: id,
                read_status: '1'
            })
            .then(response=>{
                console.log(response);
                this.getlatestnotifications();
            });
            if(this.role == "influencer")
            {
                if(modules == 'Campaign'){
                    this.$router.push({name: "influencercampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Campaign Invitation'){
                    this.$router.push({name: "CampaignInvitePage"});
                }
                else if(modules == 'Consent'){
                    this.$router.push({name: "MyConsentPage"});
                }
                else if(modules == 'Campaign Chat'){
                    this.$router.push({name: "influencercampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Chat'){
                    this.$router.push({name: "influencersupportchat",params: { id: relation_id }});
                }
            }
            if(this.role == "admin")
            {
                if(modules == 'Campaign'){
                    this.$router.push({name: "admincampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Campaign Invitation'){
                    this.$router.push({name: "admincampaigns"});
                }
                else if(modules == 'Consent'){
                    this.$router.push({name: "adminconcents"});
                }
                else if(modules == 'Campaign Chat'){
                    this.$router.push({name: "admincampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Chat'){
                    this.$router.push({name: "adminchatdetail",params: { id: relation_id }});
                }
                else if(modules == 'Register New Influencer'){
                    this.$router.push({name: "admininfluencerdata"});
                }
                else if(modules == 'Register New Brand Agency'){
                    this.$router.push({name: "adminbranddata"});
                }
                else if(modules == 'Register New Agency'){
                    this.$router.push({name: "adminagencydata"});
                }
                else if(modules == 'Register New Publisher'){
                    this.$router.push({name: "adinpublisherdata"});
                }
            }
            if(this.role == "brand")
            {
                if(modules == 'Campaign'){
                    this.$router.push({name: "brandcampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Campaign Chat'){
                    this.$router.push({name: "brandcampaigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Chat'){
                    this.$router.push({name: "brandsupportchat",params: { id: relation_id }});
                }
            }
            if(this.role == "agency")
            {
                if(modules == 'Campaign'){
                    this.$router.push({name: "agencycamapigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Campaign Chat'){
                    this.$router.push({name: "agencycamapigndetail",params: { id: relation_id }});
                }
                else if(modules == 'Chat'){
                    this.$router.push({name: "agencysupportchat",params: { id: relation_id }});
                }
            }
        },
        openNotification() {
            if(this.isDisplay == false){
                this.isDisplay = true
            }
            else{
                this.isDisplay = false
            }
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.isDisplay = false;
            }
        },
        newNotification() {
            for (let i = 0; i < this.latestnotification.length; i++) {
            if(this.latestnotification[i].read_status == '0'){
                this.isnewnotification = true
            }
        }
        },
        readAll() {
            this.isDisplay = false;
            this.newNotification()
            // this.$router.push("notification");
            if(this.role == "influencer")
            {
               this.$router.push({name: "NotificationPage"}); 
            }
            if(this.role == "admin")
            {
                this.$router.push({name: "adminnotification"});
            }
            if(this.role == "brand")
            {
                this.$router.push({name: "brandnotifications"});
            }
            if(this.role == "agency")
            {
               this.$router.push({name: "agencynotification"});
            }
        }
    },
}
</script>