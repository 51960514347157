
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let testimonialsData = [];
    return {
        testimonialsData: testimonialsData,
    }

}

const getters = {
    testimonialsData: state => state.testimonialsData,
};

const actions = {
    getTestimonialsData: ({ commit }) => {
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/testimonial")
            .then((response) => {
                console.log(response.data);
                commit("setTestimonialsData", response.data.data);
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    getSingleTestimonialsData: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/testimonial/" + id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    createTestimonialsData: ({ dispatch }, data) => {
        // commit("setLoading", true);
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/testimonial", data, {...AUTH_PARAMS,})
            .then((response) => {
                dispatch("getTestimonialsData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    updateTestimonialsData: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        let formData = data.formData;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/testimonial/" + id, formData, {...AUTH_PARAMS})
            .then((response) => {
                dispatch("getTestimonialsData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    deleteTestimonialsData: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/testimonial/" + id+'/delete',{...AUTH_PARAMS})
            .then((response) => {
                dispatch("getTestimonialsData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
};

const mutations = {
    setTestimonialsData: (state, value) => {
        state.testimonialsData = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};