<template>
    <div class="actionbtn">
        <button-component @onBtnClick="goToDetail(row.id)" :clsname="clsname">View Detail</button-component>
    </div>
</template>

<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
// import { eventBus } from "../../main";
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      clsname: 'btnplain'
    };
  },
  methods:{
    goToDetail(id){
      this.$router.push({name: "influencersupportchat",params: { id: id }});
    }
  }
};
</script>
