<template>
<div>
    <!-- <div class="content-header">

    </div> -->
    <div class="content">
        <personal-profile></personal-profile>
        <change-password></change-password>
        <WebsiteDetail></WebsiteDetail>
    </div>
</div>
</template>
<script>
import PersonalProfile from "./personalprofile.vue";
import ChangePassword from "./changepassword.vue";
import WebsiteDetail from "./websitedetail.vue";
export default {
    components: {
        PersonalProfile,
        ChangePassword,
        WebsiteDetail
    }
}
</script>