<template>
  <div>
    <label :class="lblclass"><slot/></label>
  </div>
</template>

<script>
export default {
    name : "Label",
    props :{
        lblclass: String
    }
}; 
</script>