<template>
    <div class="brandinfluencerdata">
        <div class="content-header">
            <div class="page-header">
            <text-component :class="h2txtclass">Influencers</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page">
                        </datatable-component>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ActionDetail from "./actiondetail.vue";
import Icon from "./profileicon.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { eventBus } from "../../../main";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import {mapGetters, mapActions} from 'vuex';
export default {
    props: {},
    components: {
        DatatableComponent,
        searchBar,
        TextComponent,
    },
    data() {
        return {
        h2txtclass: 'h2',
        isHiddenbar: false,
        isChat: false,
        btnprimary: 'btnprimary',
        subtitle2class: 'subtitle2',
        midclass: 'mid',
        txtclassname: 'default',
        actionid: "moreactionbtn",
        classname: "default prefixicon",
        page: 1,
        per_page: 8,
        type: "text",
        inputPlaceholder: "Search Influencer",
        filter: "",
        to: '',
        selectedRows: [],
        columns: [
            { label: "Profile Photo", headerAlign: "left", component: Icon },
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            { label: "Action", headerAlign: "left", component: ActionDetail },
        ],
        rows: [],
        };
    },
    created: function() {
        this.getData();
    },
    methods: {
        ...mapActions("brandinfluencerdata",["getbrandinfluencer"]),
        getData(){
            this.getbrandinfluencer()
            .then(response=>{
                console.log(response);
                this.rows = response.data
            });
        },
    },
  
};
</script>
