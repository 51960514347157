<template>
    <div class="chat-detail">
        <!-- <div class="content-header">
            <text-component class="h2">Chat Details</text-component>
        </div> -->
        <div class="content paddingo">
            <div class="box margino">
                <div class="box-header">
                    <text-component class="subtitle1">Support Chat</text-component>
                    <div class="support-sync-btn">
                      <button-component @onBtnClick="syncchat" clsname="btnprimary">Sync</button-component>
                    </div>
                </div>
                <div class="box-content">
                    <!-- <div class="sender-detail">
                        <div class="row margino">
                            <div class="paddingo sender-detail-main col-12">
                                <text-component class="subtitle2 sender-detail-title">Brand Name</text-component>
                                <text-component class="sender-detail-txt">Lungelo Ngcaba</text-component>
                            </div>
                            <div class="paddingo sender-detail-main col-12">
                                <text-component class="subtitle2 sender-detail-title">Email</text-component>
                                <text-component class="sender-detail-txt">lungelongcaba@gmail.com</text-component>
                            </div>
                            <div class="paddingo sender-detail-main col-12">
                                <text-component class="subtitle2 sender-detail-title">Contact No.</text-component>
                                <text-component class="sender-detail-txt">+52 77248 92487</text-component>
                            </div>
                        </div>
                    </div> -->
                    <hr class="hr-divider"/>
                    <div class="message-section" id="message-section">
                        <div v-if="chatData.length <= 0">
                            <p style="text-align: center;">Currently no chats avaliavle</p>
                        </div>
                        <div v-else v-for="(row,index) in chatData" :key="index">
                            <div class="incoming">
                                <div class="user-img" v-if="row.user_profile">
                                    <img v-if="row.user_profile.sender_image" :src="getImageUrl(row)" alt="user-img-main">
                                    <img v-else src="../../../../assets/image/usericon.png" alt="user-img-main">
                                </div>
                                <div class="user-img" v-else>
                                    <img src="../../../../assets/image/usericon.png" alt="user-img-main">
                                </div>
                                <div class="message-content">
                                    <div class="user-name-message">
                                        <div class="user-name">
                                            <text-component class="subtitle2" v-if="row.user_profile">{{row.user_profile.sender_name}}</text-component>
                                        </div>
                                        <div class="user-message">
                                            <text-component>{{row.message}}</text-component>
                                        </div>                            
                                    </div>
                                    <div class="message-time">
                                        <text-component class="mid">{{formatDateGlobal(row.created_at)}}</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="send-message box margino" id="sendmsg">
                <text-area-comp v-model="item.message"
                    required="true"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="submitHandler"
                    @keydown.enter.shift.exact="newline"
                    @keydown.enter.alt.exact="newline"
                    :helptext="!$v.item.message.required && submitted ? validationmessage.message: false"
                    :labelclass="{'validationerr': !$v.item.message.required && submitted}"
                    :classname="{'error': !$v.item.message.required && submitted, 'default': true}"
                ></text-area-comp>
                <img src="../../../../assets/image/send.svg" alt="send-icon" class="send-img" @click="submitHandler()">
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue"
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";


export default {
    props:["brandId"],
    components: {
        TextComponent,
        TextAreaComp,
        ButtonComponent
    },
    validations: {
        item: {
            message: { required },
            campaign_id: { required },
            receiver_id: { required }
        }
    },
    data(){
        return{
            item:{
                message:null,
                receiver_id:null,
                campaign_id:null,
            },
            validationmessage: {
                message: "Message is required",
            },
            submitted:false,
            campaignId:null,
            chatData:[],
        }
    },
    created: function() {
        this.getData();
    },
    updated () {
        this.scrollToEnd(); 
    },    
    mounted () {
        this.scrollToEnd();	
    },
    methods:{
        ...mapActions("admincampaignaction", ["getBrandAdminChatDetail","sendMessageBrandAdmin"]),
        getData(){
            this.item.campaign_id = this.$route.params.id;
            this.getBrandAdminChatDetail({id:this.$route.params.id})
            .then(response=>{
                console.log("response.data",response.data);
                this.chatData = response.data;
            })
            .catch(err => {
                this.chatData = []
            });
        },

        syncchat() {
        this.item.campaign_id = this.$route.params.id;
        this.getBrandAdminChatDetail({
            id: this.$route.params.id
        }).then(response => {
            console.log("response.data", response.data);
            this.chatData = response.data;
            this.$toast.open({
                message: response.message,
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top"
            });
            })
            .catch(err => {
                this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
                });
            });
        },
        getImageUrl(row){
            if (row.user_profile) {
                return row.user_profile.baseUrl+'/'+row.user_profile.user_id+'/'+row.user_profile.sender_image;
            }
        },

        scrollToEnd: function() {    	
            var container = this.$el.querySelector("#message-section");
            console.log('container.scrollHeight',container.scrollHeight)
            container.scrollTop = container.scrollHeight;
        },

        submitHandler(){
            this.item.receiver_id = this.brandId;
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.sendMessageBrandAdmin(this.item).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                    this.submitted = false;
                    this.afterSubmit();
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        afterSubmit(){
            this.submitted = false;
            this.item.message = null;
            this.getData();
        },
        newline() {
            this.item.message = `${this.item.message}\n`;
        },
    }
}
</script>