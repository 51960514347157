<template>
  <div v-if="IsChangePasswordEditable" id="chngpassword" class="changepassword boxmargin">
    <form @submit.prevent="changePassword">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Change Password</text-component>
          </div>
          <div class="changepassword-editable">
            <div class="row">
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.currentpassword"
                :helptext="!$v.item.currentpassword.required && submitted ? validationmessage.currentpassword: false"
                :labelclass="{'validationerr': !$v.item.currentpassword.required && submitted}"
                :classname="{'error': !$v.item.currentpassword.required && submitted, 'default': true}"
                :type="passwordtype"
              >Current password</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.password"
                :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
                :labelclass="{'validationerr': !$v.item.password.required && submitted}"
                :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
                :type="passwordtype"
              >New password</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                :helptext="passwordMsg()"
                :labelclass="{'validationerr': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted}"
                :classname="{'error': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted, 'default': true}"
                type="password"
                v-model="item.confirmpassword"
              >Confirm password</input-component>
            </div>
            <hr class="hrline-footer" />
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { mapActions } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent
  },
  validations: {
    item: {
      password: {
        required
      },
      currentpassword: { required },
      confirmpassword: { required, sameAsPassword: sameAs("password") }
    }
  },
  data() {
    return {
      item: {
        password: null,
        confirmpassword: null,
        currentpassword: null
      },
      validationmessage: {
        currentpassword: "current password is required",
        password: "password is required",
        confirmpassword: "confirm password is required",
        sameAsPassword: "password did not match"
      },
      submitted: false,
      passwordtype: "password",
      classname: "default",
      subtitle1class: "subtitle1",
      primaryclass: "btnprimary",
      IsChangePasswordEditable: false
    };
  },
  methods: {
    ...mapActions("brandprofile", ["changepassword"]),

    changePasswordToggle() {
      if (this.IsChangePasswordEditable == false) {
        this.IsChangePasswordEditable = true;
      } else {
        this.IsChangePasswordEditable = false;
      }
      this.scrollToPassword()
    },

    passwordMsg() {
      if (!this.$v.item.confirmpassword.required && this.submitted) {
        return this.validationmessage.confirmpassword;
      } else if (
        !this.$v.item.confirmpassword.sameAsPassword &&
        this.submitted
      ) {
        return this.validationmessage.sameAsPassword;
      } else {
        return false;
      }
    },

    changePassword() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.changepassword({
          old_password: this.item.currentpassword,
          password: this.item.password,
          password_confirmation: this.item.confirmpassword
        })
          .then(response => {
            console.log(response);
            this.IsChangePasswordEditable = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },
    scrollToPassword() {
      setTimeout(function(){
        let changepassdiv  = document.getElementById("chngpassword");
        changepassdiv.scrollIntoView();
      }, 3);
    }
  },
  created() {
    this.$root.$on("changePasswordToggle", () => {
      this.IsChangePasswordEditable = true;
      this.scrollToPassword()
    });
  }
};
</script>