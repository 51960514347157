<template>
    <div class="website-setting-page">
        <div class="box">
            <div class="template-accordion">
                <div class="tabs">
                    <div class="tab">
                        <input type="checkbox" id="website_footer_setting">
                        <label class="tab-label" for="website_footer_setting">Section 1 (Footer)</label>
                        <div class="tab-content">
                            <div class="row margino">
                                <div class="txtgroup col-12">
                                    <div class="inputtxt">
                                        <text-component>Address*</text-component>
                                    </div>
                                    <text-area-comp v-model="settingData.footer_address"
                                        required="true"
                                        :helptext="!$v.settingData.footer_address.required && submitted ? validationmessage.description: false"
                                        :labelclass="{'validationerr': !$v.settingData.footer_address.required && submitted}"
                                        :classname="{'error': !$v.settingData.footer_address.required && submitted, 'default': true,'txtarea':true}"
                                    ></text-area-comp>
                                    <span
                                            v-if="!$v.settingData.footer_address.required && submitted"
                                            class="validationerr"
                                        >{{validationmessage.footer_address}}</span>
                                </div>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_mail"
                                    required="true"
                                    :helptext="!$v.settingData.footer_mail.required && submitted ? validationmessage.footer_mail: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_mail.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_mail.required && submitted, 'default': true}">
                                    Mail
                                </input-component>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_phone"
                                    required="true"
                                    :helptext="!$v.settingData.footer_phone.required && submitted ? validationmessage.footer_phone: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_phone.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_phone.required && submitted, 'default': true}">
                                    Phone
                                </input-component>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_twitter_link"
                                    required="true"
                                    :helptext="!$v.settingData.footer_twitter_link.required && submitted ? validationmessage.footer_twitter_link: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_twitter_link.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_twitter_link.required && submitted, 'default': true}">
                                    Twitter Link
                                </input-component>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_facebook_link"
                                    required="true"
                                    :helptext="!$v.settingData.footer_facebook_link.required && submitted ? validationmessage.footer_facebook_link: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_facebook_link.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_facebook_link.required && submitted, 'default': true}">
                                    Facebook Link
                                </input-component>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_instagram_link"
                                    required="true"
                                    :helptext="!$v.settingData.footer_instagram_link.required && submitted ? validationmessage.footer_instagram_link: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_instagram_link.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_instagram_link.required && submitted, 'default': true}">
                                    Instagram Link
                                </input-component>
                                <input-component
                                    class="txtgroup col-12 col-md-6"
                                    v-model="settingData.footer_linkedin_link"
                                    required="true"
                                    :helptext="!$v.settingData.footer_linkedin_link.required && submitted ? validationmessage.footer_linkedin_link: false"
                                    :labelclass="{'validationerr': !$v.settingData.footer_linkedin_link.required && submitted}"
                                    :classname="{'error': !$v.settingData.footer_linkedin_link.required && submitted, 'default': true}">
                                    LinkedIn Link
                                </input-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hrline" />
            <div class="website-setting-page-footer">
                <button-component @onBtnClick="submitSetting" :clsname="'btnprimary'">Update</button-component>
            </div>
        </div>
    </div>
</template>
<script>
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        InputComponent,
        TextComponent,
        TextAreaComp,
        ButtonComponent
    },
    validations: {
        settingData: {
            footer_address: { required },
            footer_mail: { required },
            footer_phone: { required },
            footer_twitter_link: { required },
            footer_facebook_link: { required },
            footer_instagram_link: { required },
            footer_linkedin_link: { required },
        },
    },
    data() {
        return {
            settingData: {},
            submitted: false,
            validationmessage: {
                footer_address: 'footer address is required',
                footer_mail: 'footer mail is required',
                footer_phone: 'footer phone is required',
                footer_twitter_link: 'footer twitter link is required',
                footer_facebook_link: 'footer facebook link is required',
                footer_instagram_link: 'footer instagram link is required',
                footer_linkedin_link: 'footer linkedin link is required',
            },
        }
    },
    computed:{
        ...mapGetters("websitesetting",["settingsData"]),
    },
    watch:{
        settingsData:{
            immediate:true,
            handler(settingsData){
                // console.log('asd',settingsData.data.setting_data)
                this.settingData = settingsData.data.setting_data;
            }
        }
    },
    methods: {
        ...mapActions("websitesetting",["getSettingsData", "updateSettingsData"]),

        submitSetting() {
            this.submitted = true;
            if (this.$v.settingData.$invalid) {
                this.$v.settingData.$touch();
            } else {
                console.log(this.settingData)
                this.updateSettingsData({
                    setting: this.settingData,
                }).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        getwebsitesettingdata() {
            this.getSettingsData()
            .then(response=>{
                console.log(response.data)
            });
        },
    },

    created() {
        this.getSettingsData()   
    }
}
</script>