<template>
  <div class="user-icon">
    <img v-if="row.profile_pic == null" src="../../../assets/image/usericon.png" alt="user-image" />
    <img v-else :src="row.baseUrl+'/'+row.id+'/'+row.profile_pic" alt="user-image" />
  </div>
</template>

<script>
// import { eventBus } from "../../main";
export default {
  props: ["row"],
  components: {},
  data() {
    return {};
  },
};
</script>
