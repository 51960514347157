import LayoutMain from '../layouts/main.vue';
import CampaignInvitePage from '../pages/influencer/campaign/campaigninvite.vue';
import MyConsentPage from '../pages/influencer/consent/myconsent.vue';
import CampaignListPage from '../pages/influencer/campaign/campaignlist.vue';
import PaymentInvoicePage from '../pages/influencer/payment/paymentinvoice.vue';
import CampaignCalendarPage from '../pages/influencer/campaign/campaigncalendar.vue';
import NotificationPage from '../pages/influencer/notification/notification.vue';
import Dashboard from '../pages/influencer/dashboard/dashboard.vue';
import UserDashboard from '../pages/influencer/dashboard/dashboard.vue';
import MyProfile from '../pages/influencer/profile/myprofile.vue';
import AuthInfluencer from "../utils/AuthInfluencer";
import Support from "../pages/influencer/support/support.vue";
import SupportChat from "../pages/influencer/support/chatdetail.vue"
import CampaignDetail from "../pages/influencer/campaign/campaigndetail.vue";
import CampaignDetailOnly from "../pages/influencer/campaign/campaigndetailonly.vue";
import InfluencerInvoiceDetail from "../pages/influencer/payment/invoicedetail.vue";
import InfluencerRating from "../pages/influencer/rating/ratingdetail.vue";


export default {
  path: "/influencer",
  component: LayoutMain,
  beforeEnter: AuthInfluencer,
  meta: {
    title: "VidUnit",
  },
  children: [
    {
      path: "/influencer/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/influencer/campaigninvite",
      name: "CampaignInvitePage",
      component: CampaignInvitePage,
    },
    {
      path: "/influencer/myconsent",
      name: "MyConsentPage",
      component: MyConsentPage,
    },
    {
      path: "/influencer/campaignlist",
      name: "CampaignListPage",
      component: CampaignListPage,
    },
    {
      path: "/influencer/paymentinvoice",
      name: "PaymentInvoicePage",
      component: PaymentInvoicePage,
    },
    {
      path: "/influencer/campaigncalendar",
      name: "CampaignCalendarPage",
      component: CampaignCalendarPage,
    },
    {
      path: "/influencer/notification",
      name: "NotificationPage",
      component: NotificationPage,
    },
    {
      path: "/influencer/userdashboard",
      name: "UserDashboard",
      component: UserDashboard,
    },
    {
      path: "/influencer/myprofile",
      name: "MyProfile",
      component: MyProfile,
    },
    {
      path: "/influencer/support",
      name: "influencersupport",
      component: Support,
    },
    {
      path: "/influencer/supportchat/:id",
      name: "influencersupportchat",
      component: SupportChat,
    },
    {
      path: "/influencer/campaigndetail/:id",
      name: "influencercampaigndetail",
      component: CampaignDetail,
    },
    {
      path: "/influencer/campaigndetailonly/:id",
      name: "influencercampaigndetailonly",
      component: CampaignDetailOnly,
    },
    {
      path: "/influencer/invoicedetail/:id",
      name: "influencerinvoicedetail",
      component: InfluencerInvoiceDetail,
    },
    {
      path: "/influencer/ratings",
      name: "InfluencerRating",
      component: InfluencerRating,
    },
  ],
};