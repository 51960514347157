<template>
  <div class="userpermission">
    <div class="newuser">
      <div class="content-header">
        <div class="row">
          <div class="col-12">
            <div class="back-path mid">Users</div>
          </div>
          <div class="col-12">
            <text-component :class="headertxtclass">New User</text-component>
          </div>
        </div>
      </div>
      <form @submit.prevent="setuserpermission">
        <div class="content">
          <div class="box">
            <div class="row progressbar">
              <progress-component
                class="processcompleted"
                :progressdata="progressdata1"
                :progressclass="progressclass1"
              ></progress-component>
              <progress-component
                class="processactive"
                :progressdata="progressdata2"
                :progressclass="progressclass2"
              ></progress-component>
            </div>
            <hr class="hr-divider" />
            <div class="box-content">
              <div class="permission-details">
                <div v-for="(permissionmodule, index) in permissiondata" :key="index">
                  <div class="row inner-padding">
                    <div class="col-lg-3 col-12">
                      <text-component :textclass="textclass">{{slugtoname(index)}}</text-component>
                    </div>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-6 row-padding">
                          <toggle-switch
                            class="toggle-switch"
                            v-model="usernonecheckbox[index]"
                            name="usernone"
                          ></toggle-switch>
                          <text-component class="permission-label" :textclass="innerText">None</text-component>
                        </div>
                        <div
                          class="col-lg-3 col-md-3 col-6 row-padding"
                          v-for="(singledata, index2) in permissionmodule"
                          :key="index2"
                        >
                          <toggle-switch
                            class="toggle-switch"
                            v-model="usercheckbox[singledata.id]"
                          ></toggle-switch>
                          <text-component
                            class="permission-label"
                            :textclass="innerText"
                          >{{slugtoname(index2)}}</text-component>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-divider" />
                </div>
                <div class="row inner-padding">
                  <div class="col-lg-12">
                    <button-component
                      :clsname="clsname"
                      buttontype="submit"
                      class="update-btn"
                    >Create User</button-component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ToggleSwitch from "../../../../BasicComponents/Checkbox/switchbox.vue";
import ProgressComponent from "../../../../BasicComponents/Progress/Progress";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TextComponent,
    ToggleSwitch,
    ProgressComponent,
    ButtonComponent
  },
  watch: {
    permissionData: {
      immediate: true,
      handler(permissionData) {
        this.$data.permissiondata = permissionData;
        this.getnonecheckbox();
      }
    },

    usernonecheckbox: {
      immediate: false,
      deep: true,
      handler(usernonecheckbox) {
        let nonearray = Object.keys(usernonecheckbox);
        for (let title of nonearray) {
          if (usernonecheckbox[title]) {
            console.log(title);
            let category = Object.values(this.permissiondata[title]);
            category.map(value => {
              let uncheck = value["id"];
              if (this.usercheckbox[uncheck]) {
                this.usercheckbox[uncheck] = false;
              }
            });
          }
        }
      }
    },

    usercheckbox: {
      immediate: false,
      deep: true,
      handler(usercheckbox) {
        let nonearray = Object.keys(usercheckbox);
        for (let title in this.permissiondata) {
          let isnonecheck = true;
          let titledata = this.permissiondata[title];
          for (let key in titledata) {
            let iddata = titledata[key];
            let inarray = iddata["id"].toString();
            if (nonearray.indexOf(inarray) != -1 && usercheckbox[inarray]) {
              isnonecheck = false;
            }
          }
          if (isnonecheck) {
            this.usernonecheckbox[title] = true;
          } else {
            this.usernonecheckbox[title] = false;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["singleData"]),
    ...mapGetters("adminpermission", ["permissionData"])
  },
  methods: {
    ...mapActions("user", ["singleuserData"]),
    ...mapActions("adminpermission", [
      "getpermission",
      "singleuserpermission",
      "setpermission"
    ]),

    singledata() {
      this.singleuserData({
        id: this.$route.params.id
      }).then(response => {
        console.log(response);
        this.userid = this.$route.params.id;
        this.roleid = response.data.sub_role_id;
        console.log("roleid:", this.roleid);
        this.getuserpermission();
        console.log("checking");
      });
    },

    slugtoname(string) {
      let str = string.replace("_", " ");
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    getnonecheckbox() {
      let permissions = Object.keys(this.permissiondata);
      let usernonecheckbox = {};
      for (let data of permissions) {
        // console.log("datadata:",data)
        // console.log("indexdata:", index);
        usernonecheckbox[data] = true;
      }
      this.usernonecheckbox = usernonecheckbox;
      // console.log(this.usernonecheckbox)
    },

    getuserpermission() {
      let id = this.roleid;
      let type = "role";
      if (id) {
        this.singleuserpermission({
          id: id,
          type: type
        }).then(response => {
          console.log(response);
          this.usercheckbox = response.data;
          console.log("checking");
        });
      }
    },

    setuserpermission() {
      let vm = this;
      this.setpermission({
        id: this.userid,
        type: "user",
        permission: this.usercheckbox
      }).then(response => {
        console.log(response);
        let userid = this.$route.params.id;
        vm.$router.push({ name: "UserSuccess", params: { id: userid } });
        this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
      }).catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    }
  },
  created() {
    this.singledata();
    this.getpermission();
    // this.getuserpermission();
  },
  data() {
    return {
      userid: "",
      roleid: "",
      usernonecheckbox: {},
      usercheckbox: {},
      permissiondata: {},
      midtxtclass: "mid",
      inputcls: "default",
      btnid: "ccode",
      progressclass1: "completed",
      progressclass2: "active",
      btncontinue: "btnprimary",
      pathtxtclass: "mid",
      headertxtclass: "h2",
      classname: "default",
      textclass: "subtitle2 dark",
      vendorpermission: "",
      innerText: "dark",
      clsname: "btnprimary",
      progressdata1: [{ progressid: "1", progressname: "User information" }],
      progressdata2: [{ progressid: "2", progressname: "Assigned permission" }]
    };
  }
};
</script>