<template>
    <div class="actionbtn">
        <button-component @onBtnClick="onAccept(row)" :clsname="primarycls">Accept</button-component>
        <button-component @onBtnClick="onReject(row)" :clsname="destructivecls">Reject</button-component>
        <button-component @onBtnClick="onNegotiate(row)" :clsname="basiccls">Negotiate</button-component>
        <button-component @onBtnClick="onDetail(row)" :clsname="btnplain">Detail</button-component>
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    props: ["row"],
    components: {
        ButtonComponent
    },
    data() {
        return {
            primarycls: 'slim-btnprimary',
            destructivecls: 'slim-btndestructive',
            basiccls: 'slim-btnbasic',
            btnplain: 'btnplain'
        };
    },
    computed: {
        ...mapGetters("influencercampaign", ["inviteData"]),
    },
    watch: {
        inviteData: {
            immediate: true,
            handler(inviteData) {
                this.rows = inviteData;
            }
        }
    },
    methods: {
        ...mapActions("influencercampaign", ["acceptorrejectinvitation", "getpendinginvite"]),
        onDetail(row) {
            this.$router.push({ name: "influencercampaigndetail", params:{id: row.campaign_id}});
        },
        onNegotiate() {
        this.$root.$emit('onNegotiate', this.row)
        },
        onAccept(row) {
            let vm = this;
            this.acceptorrejectinvitation({
                id: row.id,
                status: "1"
            })
            .then(response => {
                this.getpendinginvite()
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        },
        onReject(row) {
            let vm = this;
            this.acceptorrejectinvitation({
                id: row.id,
                status: "2"
            })
            .then(response => {
                this.getpendinginvite()
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        }
    },
};
</script>
