<template>
  <div class="interestdetail boxmargin">
    <form @submit.prevent="handleInterests">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Interest/Genres</text-component>
            <div v-if="!IsInterestDetailEditable" class="myprofile-header-right">
              <button-component 
                @onBtnClick="interestDetailToggle" 
                :clsname="basicclass">
                  <div v-if="isInterestdata.length <= 0">
                    Add
                  </div>
                  <div v-else>
                    Edit
                  </div>
                </button-component>
            </div>
          </div>
          <div v-if="!IsInterestDetailEditable" class="interestdetail-saved">
            <div v-if="isInterestdata.length >= 0">
              <p style="text-align: center;">Currently no interest/genres are Added</p>
            </div>
            <div v-else>
              <div class="row margino">
                <div
                  class="profile-detail-txt col-xl-2 col-md-4 col-sm-6"
                  v-for="(idata, index) in interestdatastring"
                  :key="index"
                >
                  <div class="row">
                    <text-component>{{idata.value}}</text-component>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="IsInterestDetailEditable" class="interestdetail-editable">
            <div class="row">
              <div
                v-for="iedit in interestedit"
                :key="iedit.id"
                class="checkboxcol col-12 col-sm-6 col-md-3"
              >
                <checkbox-component
                  v-model="selectedval"
                  :val="(iedit.id)?iedit.id:iedit.checkboxname"
                  :checkboxselectid="iedit.id"
                >{{iedit.checkboxname}}</checkbox-component>
              </div>
            </div>
            <div class="row">
              <!-- <input-component
                class="txtgroup col-12 col-sm-6 col-md-4"
                :classname="classname"
                v-model="other"
                @tags-changed="newTags => tags = newTags"
              >Other</input-component>
              {{other}} {{tags}}-->
              <div class="txtgroup col-12 col-sm-6 col-md-4">
                <text-component>Other</text-component>
                <vue-tags-input
                  v-model="tag"
                  :tags="tags"
                  @tags-changed="newTags => tags = newTags"
                  :placeholder="'Other Interest'"
                />
              </div>
            </div>
            <hr class="hrline-footer" />
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import CheckboxComponent from "../../../BasicComponents/Checkbox/defaultCheckbox";
// import InputComponent from "../../../BasicComponents/Input Group/TagInputGroup.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    CheckboxComponent,
    // InputComponent,
    VueTagsInput,
  },
  computed: {
    ...mapGetters("influencerprofile", ["interestData", "interestListing"]),
  },
  data() {
    return {
      tag: "",
      tags: [],
      listingdata: {},
      selectedval: [],
      isInterestdata: [],
      IsInterestDetailEditable: false,
      classname: "default",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      other: "",
      interestedit: [],
      interestdatastring: [],
    };
  },
  watch: {
    interestData: {
      immediate: true,
      deep: true,
      handler(interestData) {
        this.isInterestdata = interestData
        if(interestData) {
          this.$data.interestdatastring = this.getuserinterest(interestData);
          if (interestData.other_interest != null) {
            this.tags = this.setedittags(interestData.other_interest);
          }
          if (interestData.interest) {
            this.selectedval = interestData.interest.split(",");
            this.$data.interestdatastring = this.getuserinterest(interestData);
          }
        }else {
          this.isInterestdata = [];
        }
      },
    },

    interestListing: {
      immediate: true,
      handler(interestListing) {
        // console.log("interest listing:", interestListing);
        this.listingdata = interestListing;
        this.$data.interestedit = this.getlisting(interestListing);
      },
    },
  },
  created() {
    this.getinterests();
    this.getinterestlisting();
  },
  methods: {
    ...mapActions("influencerprofile", [
      "interests",
      "getinterests",
      "getinterestlisting",
    ]),
    ...mapActions("commonfunction", ["getuserpercentage"]),

    setedittags(data) {
      // "text": "dcs", "tiClasses": [ "ti-valid" ] }
      let newarray = [];
      if (data) {
        data.map((value) => {
          newarray.push({
            text: value,
            tiClasses: ["ti-valid"],
          });
        });
        return newarray;
      } else {
        return newarray;
      }
    },

    getuserinterest(ids) {
      let valuearray = [];
      if (ids.other_interest != null) {
        ids.other_interest.map((value) => {
          valuearray.push({ value: value });
        });
      }
      if (ids.interest) {
        let tagids = ids.interest.split(",");
        // console.log("arrayid:", ids);
        for (let id in tagids) {
          // console.log("selectedid:", tagids[id])
          for (let data in this.listingdata) {
            // console.log("listdata:", this.listingdata[data].id);
            if (tagids[id] == this.listingdata[data].id) {
              valuearray.push({
                value: this.listingdata[data].interest,
              });
            }
          }
        }
      }
      return valuearray;
    },

    interestDetailToggle() {
      if (this.IsInterestDetailEditable == false) {
        this.IsInterestDetailEditable = true;
      } else {
        this.IsInterestDetailEditable = false;
      }
    },

    getlisting(data) {
      let newarray = [];
      data.map((value) => {
        newarray.push({
          id: value.id,
          checkboxname: value.interest,
        });
      });
      return newarray;
    },

    handleInterests() {
      let newarray = [];
      if (this.tags != null) {
        this.tags.map((value) => {
          newarray.push(value.text);
        });
      }

      let vm = this;
      this.interests({
        interest: this.selectedval.toString(),
        other_interest: newarray,
      })
        .then((response) => {
          console.log(response);
          vm.getinterests();
          this.IsInterestDetailEditable = false;
          this.getuserpercentage()
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
          console.log("checking");
        })
        .catch((err) => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top",
          });
        });
    },
  },
};
</script>