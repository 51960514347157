<template>
    <div class="menumobile" id="mobilemenu">
    <div class="searchvendor-content-left">
      <div class="menu-inner">
        <div class="main-logo">
        <img src="../assets/image/main-logo.png" alt="VidUnit-logo"/>
        </div>
        <navigation-component :navdata="navdata"></navigation-component>
      </div>
    </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "41",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "branddashboard"
        },
        {
          id: "42",
          item: "Report",
          imgpath: "report.svg",
          child: [
            { 
              id: "43", 
              item: "Campaign Report", 
              name: "brandcampaignreport" 
            },
            // { 
            //   id: "44", 
            //   item: "Influencer Report", 
            //   name: "brandinfluencerreport" 
            // },
          ]
        },
        {
          id: "45",
          item: "Campaigns",
          imgpath: "campaign.svg",
          name: "brandcampaigns"
        },
        // {
        //   id: "46",
        //   item: "Influencer",
        //   imgpath: "rating.svg",
        //   name: "brandinfluencerdata"
        // },
        {
          id: "47",
          item: "Rating",
          imgpath: "rating.svg",
          name: "brandrating"
        },
        {
          id: "48",
          item: "Support",
          imgpath: "chat.svg",
          name: "brandsupport"
        },
      ]
    };
  },

  methods: {

  },
};
</script>