<template>
  <div>
    <div class="popover"  id="popover-scrollbar" @mouseleave="hideD">
      <ul class="search-bar-ul">
        <li @click="getValue" v-for="data in popoverList" :key="data.id">
          <a @click="linkClick">{{data.value}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popoverList: Array,
  },

  methods: {
    linkClick: function(value) {
      this.$emit('onLinkClick',value);
    },
    getValue: function(value) {
      this.$emit('onGetValue',value);
    },
    hideD: function (value) {
        this.$emit('hideData', value)
    },
  }
};
</script>