<template>
    <div class="chat influencer-support">
        <div class="content-header">
             <text-component :class="h2txtclass">Support</text-component>
        </div>
        <div class="content">
            <button-component @onBtnClick="togglePopup" class="send-msg-btn" :clsname="btnprimary">Send Messege</button-component>
            <div class="box paddingo margino">
                <div class="box-content">
                    <header-tabs
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'In Process'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="columns"
                                    :rows="ticketRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div> 
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Closed Ticket'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="columns"
                                    :rows="ticketClosedRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div>
                        </div>
                    </header-tabs>
                </div>
            </div>
            <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isChat}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="togglePopup" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="header">
                        <text-component :class="h2txtclass">Send Messege</text-component>
                    </div>
                    <form @submit.prevent="handleMessage">
                        <div class="row message-desc">
                            <div class="col-12">
                                <input-component
                                    required="true"
                                    :helptext="!$v.item.subject.required && submitted ? validationmessage.subject: false"
                                    :labelclass="{'validationerr': !$v.item.subject.required && submitted}"
                                    :classname="{'error': !$v.item.subject.required && submitted, 'default': true}"
                                    v-model="item.subject"
                                >Subject</input-component>
                            </div>
                            <div class="col-12 margin-top">
                                <div class="inputtxt">
                                    <text-component>Messege*</text-component>
                                </div>
                                <text-area-comp v-model="item.message"
                                    required="true"
                                    :helptext="!$v.item.message.required && submitted ? validationmessage.message: false"
                                    :labelclass="{'validationerr': !$v.item.message.required && submitted}"
                                    :classname="{'error': !$v.item.message.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                        <hr class="hrline"/>
                        <div class="modal-footer">
                            <div>
                                <button-component buttontype="submit" :clsname="btnprimary">Send</button-component>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- /Modal -->
        </div>
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import ActionBtn from "./action.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
// import SelectComponent from "../../../BasicComponents/select/select.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import { eventBus } from "../../../main";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        // SelectComponent,
        ButtonComponent,
        TextComponent,
        TextAreaComp,
        InputComponent
    },
    validations: {
        item: {
            message: { required },
            subject: { required }
        }
    },
    data() {
        return {
            submitted:false,
            item:{
                subject:null,
                message:null
            },
            validationmessage: {
                message: "Message is required",
                subject: "Subject is required",
            },
            activetab: "In Process",
            maxLimit: 9,
            h2txtclass: 'h2',
            isHiddenbar: false,
            subject: '',
            isChat: false,
            btnprimary: 'btnprimary',
            subtitle2class: 'subtitle2',
            midclass: 'mid',
            txtclassname: 'default',
            actionid: "moreactionbtn",
            classname: "default prefixicon",
            page: 1,
            per_page: 8,
            type: "text",
            inputPlaceholder: "Search Subject",
            filter: "",
            to: '',
            selectedRows: [],
            tovalue: '',
            selectplaceholder: 'Select...',
            columns: [
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            rows: [],
            ticketRows: [],
            ticketClosedRows: [],
            tabdata: [
                { id: "1", value: "In Process" },
                { id: "2", value: "Closed Ticket"},
            ],
        };
    },
    computed: {
        ...mapGetters("adminchattaction", ["RoleTiocketData","RoleTiocketClosedData"]),
    },
    watch:{
        RoleTiocketData:{
            immediate:true,
            handler(RoleTiocketData){
                this.ticketRows = RoleTiocketData
            }
        },
        RoleTiocketClosedData:{
            immediate:true,
            handler(RoleTiocketClosedData){
                this.ticketClosedRows = RoleTiocketClosedData
            }
        },
    },
    created: function() {
        this.getRoleTiocketData();
        this.getRoleTiocketClosedData();
        this.$root.$on('onClick', () => {
            this.isChat = !this.isChat;
        })

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );
    },
    methods: {
         ...mapActions("adminchattaction", ["getRoleTiocketData","getRoleTiocketClosedData","createMessageRole"]),
        togglePopup() {
            this.isChat = !this.isChat;
            this.submitted = false
            this.item = {message:null,subject:null}
        },
        handleMessage(){
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.createMessageRole(this.item).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                    this.afterSubmit();
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        afterSubmit(){
            this.getRoleTiocketData();
            this.togglePopup();
        }
    },
  
};
</script>
