<template>
	<div class="brand myconsent campaignreport">
		<div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Campaign Report</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                                <status-dropdown
                                    class="header-main-category"
                                    :clsname="dropBtn"
                                    v-model="statusDrop"
                                    :popoverList="dropCatList"
                                    :clearClass="clearClass"
                                    :btnText="'Status'"
                                    >{{statusClear}}</status-dropdown>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :cboxindeterminatecls="cboxindeterminatecls"
                            :actionText="actionText"
                            :rows="rows"
                            :clsname="clsname"
                            :btnText="btnText"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :dropBtn="dropBtn"
                            :dropdownText="dropdownText"
                            :statusClear="statusClear"
                            :clearClass="clearClass"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                            @onrowenter="onEnter"
                            @onrowleave="onLeave">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>


<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import statusDropdown from "../../../../BasicComponents/Dropdown/checkboxDropdown.vue";
import status from "./statusbadge.vue";
import action from "./actionbtn.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {},
    components: {
        DatatableComponent,
        TextComponent,
        searchBar,
        statusDropdown,
    },
    data() {
        return {
            actionid: "moreactionbtn",
            cboxindeterminatecls: "cboxindeterminate",
            actionText: "More actions",
            clsname: "btnsort",
            btnText: "Sort",
            type: "text",
            inputPlaceholder: "Search Campaign Name",
            classname: "default prefixicon",
            defaultclassname: "default",
            dropBtn: "btnbasic",
            dropdownText: "Sub Category",
            statusClear: "Clear",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            acname: "Mahnaz Farzin",
            myacimageurl: "alert.svg",
            dropCat: "btnbasic",
            dropCatText: "Main Category",
            statusid: "btnstatus",
            selectplaceholder: 'Select...',
            filter: "",
            selected: "",
            row: "",
            h2txtclass: 'h2',
            isMobileMenu: false,
            statusDrop: null,
            page: 1,
            per_page: 8,
            selectedRows: [],
            tag: '',
            tags: [],
            value1: [],
            columns: [
                {
                    label: "Campaign Name",
                    field: "campaign_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Campaign Period",
                    // field: "campaignperiod",
                    representedAs: row => {
                        return this.formatDateGlobal(row.start_date)+' to '+this.formatDateGlobal(row.end_date);
                    },
                    align: "left",
                    headerAlign: "left",
                    sortable: false
                },
                { label: "Status", align: "left", headerAlign: "left" , component: (status)},
                { label: "Action",headerAlign: "left" , component: (action)},
            ],
            rows: [],
            apirows: [],
            dropCatList: [
                { id: "0", value: "Pending" },
                { id: "1", value: "In Progress" },
                { id: "2", value: "Rejected" },
                { id: "3", value: "Completed" },
            ],
        };
    },
    computed: {
    ...mapGetters("brandcampaign", ["campaignData"]),
  },
    watch: {
        campaignData: {
            immediate: true,
            handler(campaignData) {
                // this.$data.rows = campaignData;
                this.rows = this.apirows = campaignData;
            }
        },
        statusDrop: {
            immediate: false,
            handler(statusDrop) {
                console.log("statusDrop", statusDrop);
                this.filterData();
            }
        },
    },
    methods: {
        ...mapActions("brandcampaign", ["getcampaign"]),

        onEnter(row) {
            console.log(row)
        },
        onLeave(row) {
            console.log(row)
        },

        filterData: function() {
            let filterstatusDrop = this.statusDrop;
            // console.log("filter:",filterStatus);
            this.rows = this.apirows.filter(row => {
                // let status = ["0","1","2"];
                if (!filterstatusDrop.length) {
                    filterstatusDrop = ["0","1","2","3"];
                }
                return (
                    filterstatusDrop.indexOf(row.status) != -1
                );
            });
        }
    },
    created() {
        this.getcampaign()
    }
};
</script>
