import AdminLayoutMain from '../layoutsadmin/main.vue';
import AdminDashboard from '../pages/admin/dashboard/dashboard.vue';
import Permissions from '../pages/admin/master_data/permission/permissions.vue';
import User from "../pages/admin/master_data/user/user.vue";
import ViewUser from "../pages/admin/master_data/user/viewuser.vue";
import EditUser from "../pages/admin/master_data/user/edituser.vue";
import NewUser from "../pages/admin/master_data/user/newuser.vue";
import NewUserpermission from "../pages/admin/master_data/user/newuserpermission.vue";
import EditUserpermission from "../pages/admin/master_data/user/edituserpermission.vue";
import UserSuccess from "../pages/admin/master_data/user/usersuccess.vue";
import Roles from "../pages/admin/master_data/role/roles.vue";
import CreateRole from "../pages/admin/master_data/role/createrole.vue";
import AdminChat from '../pages/admin/chat/chat.vue';
import AdminInfluencer from '../pages/admin/influencer/influencer.vue';
import AdminNotification from '../pages/admin/notification/notification.vue';
import AdminManageCategory from '../pages/admin/master_data/category/managecatogeries.vue';
import AdminManageGenres from '../pages/admin/master_data/genre/managegenres.vue';
import AuthAdmin from "../utils/AuthAdmin";
import AdminInfluencerData from "../pages/admin/influencer_data/influencer.vue";
import AdminBrandData from "../pages/admin/brand_data/brand.vue";
import AdminCommunityManagerData from "../pages/admin/community_manager_data/community_manager.vue";
import AdminAgencyData from "../pages/admin/agency_data/agency.vue";
import AdminConcents from "../pages/admin/concent/concent.vue";
import AdminCampaigns from "../pages/admin/campaign/campaign.vue";
import AdminInfluencerDetail from "../pages/admin/influencer_data/influencerdetail.vue";
import AdminInfluencerCategory from "../pages/admin/influencer_data/influencercategory.vue";
import AdminRating from "../pages/admin/rating/rating.vue";
import AdminRatingDetail from "../pages/admin/rating/ratingdetail.vue";
import AdminChatDetail from "../pages/admin/chat/chatdetail.vue";
import AdminCampaignDetail from "../pages/admin/campaign/detail/main.vue";
import AdminAgencyDetail from "../pages/admin/agency_data/detail/agencydetail.vue";
import AdminBrandDetail from "../pages/admin/brand_data/detail/branddetail.vue";
import AdminLiveCampaign from "../pages/admin/admin_data/livebrandcampaign.vue";
import AdminPublisherData from "../pages/admin/publisher/publisher.vue";
import AdminInvoice from "../pages/admin/payment/paymentinvoice.vue";
import AdminCmsList from "../pages/admin/cms/cmslist.vue";
import AdminCmsDetail from "../pages/admin/cms/cmsdetail.vue";
import AdminBlogList from "../pages/admin/blog/bloglist.vue";
import AdminBlogDetail from "../pages/admin/blog/blogdetail.vue";
import AdminCreateBlog from "../pages/admin/blog/createblog.vue";
import AdminInvoiceDetail from "../pages/admin/payment/invoicedetail.vue";
import AdminContentList from "../pages/admin/publisher/contentlist.vue";
import AdminWebsiteList from "../pages/admin/publisher/websitelist.vue";
import AdminWebsiteSetting from "../pages/admin/website_setting/websitesetting.vue";
import AdminTestimonialList from "../pages/admin/cms/testimonial/testimoniallist.vue";
import AdminCelebrityClient from "../pages/admin/cms/celebrityclient/celebrityclient.vue";
import AdminContentRequest from "../pages/admin/publisher/contentrequestlist.vue";
import AdminRequestedArticleList from "../pages/admin/publisher/requestedarticlelist.vue";
import AdminInfluencerCampaignDetail from "../pages/admin/campaign/influencercampaigndetail.vue";
import AdminPublisherDetailPage from "../pages/admin/publisher/detail/publisherdetail.vue";
import AdminChangePassword from "../pages/admin/change_password/change_password.vue";

export default {
  path: "/admin",
  component: AdminLayoutMain,
  beforeEnter: AuthAdmin,
  meta: {
    title: "VidUnit",
  },
  children: [
    {
      path: "/admin/dashboard",
      name: "admindashboard",
      component: AdminDashboard,
    },
    {
      path: "/admin/managecategory",
      name: "adminmanagecategory",
      component: AdminManageCategory,
    },
    {
      path: "/admin/managegenres",
      name: "adminmanagegenres",
      component: AdminManageGenres,
    },
    {
      path: "/admin/chat",
      name: "adminchat",
      component: AdminChat,
    },
    {
      path: "/admin/influencer",
      name: "admininfluencer",
      component: AdminInfluencer,
    },
    {
      path: "/admin/notification",
      name: "adminnotification",
      component: AdminNotification,
    },
    {
      path: "/admin/permissions",
      name: "Permissions",
      component: Permissions,
    },
    {
      path: "/admin/user",
      name: "User",
      component: User,
    },
    {
      path: "/admin/viewuser/:id",
      name: "ViewUser",
      component: ViewUser,
    },
    {
      path: "/admin/edituser/:id",
      name: "EditUser",
      component: EditUser,
    },
    {
      path: "/admin/newuser",
      name: "NewUser",
      component: NewUser,
    },
    {
      path: "/admin/newuserpermission/:id",
      name: "NewUserpermission",
      component: NewUserpermission,
    },
    {
      path: "/admin/usersuccess/:id",
      name: "UserSuccess",
      component: UserSuccess,
    },
    {
      path: "/admin/edituserpermission/:id",
      name: "EditUserpermission",
      component: EditUserpermission,
    },
    {
      path: "/admin/roles",
      name: "Roles",
      component: Roles,
    },
    {
      path: "/admin/createrole",
      name: "CreateRole",
      component: CreateRole,
    },
    {
      path: "/admin/influencerdata",
      name: "admininfluencerdata",
      component: AdminInfluencerData,
    },
    {
      path: "/admin/influencercategory",
      name: "admininfluencercategory",
      component: AdminInfluencerCategory,
    },
    {
      path: "/admin/branddata",
      name: "adminbranddata",
      component: AdminBrandData,
    },
    {
      path: "/admin/communitymanagerdata",
      name: "admincommunitymanagerdata",
      component: AdminCommunityManagerData,
    },
    {
      path: "/admin/agencydata",
      name: "adminagencydata",
      component: AdminAgencyData,
    },
    {
      path: "/admin/concents",
      name: "adminconcents",
      component: AdminConcents,
    },
    {
      path: "/admin/campaigns",
      name: "admincampaigns",
      component: AdminCampaigns,
    },
    {
      path: "/admin/influencerdetail/:id",
      name: "admininfluencerdetail",
      component: AdminInfluencerDetail,
    },
    {
      path: "/admin/rating",
      name: "adminrating",
      component: AdminRating,
    },
    {
      path: "/admin/ratingdetail/:id",
      name: "adminratingdetail",
      component: AdminRatingDetail,
    },
    {
      path: "/admin/chatdetail/:id",
      name: "adminchatdetail",
      component: AdminChatDetail,
    },
    {
      path: "/admin/campaigndetail/:id",
      name: "admincampaigndetail",
      component: AdminCampaignDetail,
    },
    {
      path: "/admin/agencydetail/:id",
      name: "adminagencydetail",
      component: AdminAgencyDetail,
    },
    {
      path: "/admin/branddetail/:id",
      name: "adminbranddetail",
      component: AdminBrandDetail,
    },
    {
      path: "/admin/livecampaign",
      name: "Adminlivecampaign",
      component: AdminLiveCampaign,
    },
    {
      path: "/admin/publisher",
      name: "adminpublisherdata",
      component: AdminPublisherData,
    },
    {
      path: "/admin/invoice",
      name: "admininvoice",
      component: AdminInvoice,
    },
    {
      path: "/admin/cmslist",
      name: "admincmslist",
      component: AdminCmsList,
    },
    {
      path: "/admin/cmsdetail/:id",
      name: "admincmsdetail",
      component: AdminCmsDetail,
    },
    {
      path: "/admin/bloglist",
      name: "adminbloglist",
      component: AdminBlogList,
    },
    {
      path: "/admin/blogdetail/:id",
      name: "adminblogdetail",
      component: AdminBlogDetail,
    },
    {
      path: "/admin/createblog",
      name: "admincreateblog",
      component: AdminCreateBlog,
    },
    {
      path: "/admin/invoicedetail/:id",
      name: "admininvoicedetail",
      component: AdminInvoiceDetail,
    },
    {
      path: "/admin/contentlist",
      name: "adminicontentlist",
      component: AdminContentList,
    },
    {
      path: "/admin/websitelist",
      name: "adminwebsitelist",
      component: AdminWebsiteList,
    },
    {
      path: "/admin/settings",
      name: "adminwebsitesetting",
      component: AdminWebsiteSetting,
    },
    {
      path: "/admin/testimonial",
      name: "admintestimoniallist",
      component: AdminTestimonialList,
    },
    {
      path: "/admin/contentrequestlist",
      name: "admincontentrequestlist",
      component: AdminContentRequest,
    },
    {
      path: "/admin/requestedarticlelist/:id",
      name: "adminrequestedarticlelist",
      component: AdminRequestedArticleList,
    },
    {
      path: "/admin/celebrityclient",
      name: "admincelebrityclient",
      component: AdminCelebrityClient,
    },
    {
      path: "/admin/influencercampaigndetail/:campaignid/:influencerid",
      name: "admininfluencercampaigndetail",
      component: AdminInfluencerCampaignDetail,
    },
    {
      path: "/admin/publisherdetail/:id",
      name: "publisherdetailpage",
      component: AdminPublisherDetailPage,
    },
    {
      path: "/admin/change-password",
      name: "adminChangePassword",
      component: AdminChangePassword,
    },
  ],
};
