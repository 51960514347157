<template>
  <div class="rating-page">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">Rating</text-component>
      </div>
    </div>
    <div class="content">
      <div class="box margino paddingo">
        <div class="box-header">
          <text-component class="subtitle1">Influencers</text-component>
        </div>
        <div class="box-content">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :columns="columns"
              :rows="rows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="filter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isRateInfluencer}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="rateInfluencer"
            src="../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Rate Influencer</text-component>
        </div>
        <div class="row content-align">
          <rating-input class="col-12" v-model="rating" @rating="setRating"></rating-input>
          <div class="txtgroup col-12">
            <div class="inputtxt">
              <label>Feedback</label>
            </div>
            <div>
              <textarea class="default"></textarea>
            </div>
          </div>
          <div class="txtgroup col-12">
            <div class="inputtxt">
              <label>Comment</label>
            </div>
            <div>
              <textarea class="default"></textarea>
            </div>
          </div>
          <div class="txtgroup col-12">
            <div class="inputtxt">
              <label>Add Notes</label>
            </div>
            <div>
              <textarea class="default"></textarea>
            </div>
          </div>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="rateInfluencer" :clsname="btnprimary">Submit</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>
<script>
import RatingInput from "../../../BasicComponents/rating/ratinginput.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import ActionBtn from "./actionbtn.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import UserIcon from "./profileicon.vue";
import Rating from "./tablerating.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
    ButtonComponent,
    RatingInput,
  },
  computed: {
    ...mapGetters('brandcampaign',["ratingData"])
  },
  watch: {
    ratingData: {
      immediate: true,
      handler(ratingData) {
        this.$data.rows = ratingData
      }
    }
  },
  data() {
    return {
      isRateInfluencer: false,
      h2txtclass: "h2",
      btnprimary: "btnprimary",
      rating: 4,
      type: "text",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      txtclassname: "default",
      filter: "",
      selected: "",
      row: "",
      email: "",
      page: 1,
      per_page: 8,
      selectedRows: [],
      columns: [
        {
          label: "Profile Photo",
          component: UserIcon,
          align: "left",
          headerAlign: "left",
        },
        {
          label: "Influencer’s Name",
          field: "user_profile.full_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Campaigns",
          field: "influencer_campaign_count",
          headerAlign: "left",
          align: "left",
          sortable: false,
        },
        {
          label: "Rating",
          headerAlign: "left",
          align: "left",
          component: Rating,
        },
        { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
      ],
      rows: [],
    };
  },
  methods: {
    ...mapActions("brandcampaign", ["getCampaignrating"]),

    setRating(star) {
      // console.log(star.toElement.id)
      this.rating = star.toElement.id;
      console.log(this.rating);
      // this.$root.$emit('setRating')
    },
    rateInfluencer() {
      if (this.isRateInfluencer == true) {
        this.isRateInfluencer = false;
      } else {
        this.isRateInfluencer = true;
      }
    },
  },
  created: function () {
    this.$root.$on("onClick", () => {
      if (this.isRateInfluencer == true) {
        this.isRateInfluencer = false;
      } else {
        this.isRateInfluencer = true;
      }
    });

    this.getCampaignrating()
  },
};
</script>