<template>
  <div>
    <div :class="cboxindeterminatecls">
      <input id="checkbox1" type="checkbox" name="cb" checked="checked" class="cbox" @click="onCboxClick"/>
      <label for="checkbox1" class="lblcb">
        <slot/>
      </label>
    </div>
  </div>
</template>

<script>
export default {
    name : "indeterminatecheckbox",
    props :{
        cboxindeterminatecls: String,
    },
    methods: {
        onCboxClick: function(value) {
            this.$emit('onCbxClick',value)
        }
    }
}; 
</script>
