<template>
	<div>
		<div class="influencerlist brand">
			<div class="content-header">
                <div class="page-header">
                    <text-component :class="h2txtclass">Influencer Report</text-component> 
                </div>
            </div>
            <div class="content padding-topo">
                <div class="box paddingo margino">
                    <div class="box-content">
                      <div class="influencer-report-txt">
                        <text-component class="subtitle1">Influencers</text-component> 
                      </div>
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="columns"
                                :cboxindeterminatecls="cboxindeterminatecls"
                                :actionText="actionText"
                                :rows="rows"
                                :clsname="clsname"
                                :btnText="btnText"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :dropBtn="dropBtn"
                                :dropdownText="dropdownText"
                                :statusClear="statusClear"
                                :clearClass="clearClass"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>


<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./actionbtn.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import UserIcon from "./profileicon";

export default {
  props: {},
  components: {
	DatatableComponent,
    TextComponent,
    searchBar,
  },
  data() {
    return {
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      txtclassname: "default",
      btninvitecls: "btnprimary",
      acname: "Mahnaz Farzin",
      myacimageurl: "alert.svg",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      influencername: '',
      email: '',
      h2txtclass: 'h2',
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      page: 1,
      per_page: 8,
      selectedRows: [],
      columns: [
        { label: "Profile Photo", component: UserIcon,align: "left",headerAlign: "left",},
        {
          label: "Influencer’s Name",
          field: "influencername",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false
        },
        {
          label: "Campaign Name",
          field: "campaignname",
          align: "left",
          headerAlign: "left",
          sortable: false
        },
        { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
      ],
      rows: [
        //...
        {
          id: "1",
          influencername: "Lungelo Ngcaba",
          campaignname: "Nutrition Campaign",
        },
        {
          id: "2",
          influencername: "Sammy Lawson",
          campaignname: "Nutrition Campaign",
        },
        {
          id: "3",
          influencername: "Andries Grootoonk",
          campaignname: "Nutrition Campaign",
        },
        {
          id: "4",
          influencername: "Diego Morata",
          campaignname: "Health & Fitness Campaign",
        },
        {
          id: "5",
          influencername: "Angela Longoria",
          campaignname: "Health & Fitness Campaign",
        },
        //...
      ],
      dropCatList: [
        { id: "0", value: "Active" },
        { id: "1", value: "Inactive" },
      ],
    };
  },
};
</script>
