<template>
    <div class="authfooter">
        <div class="footer-main">
            <div class="container">
                <text-component class="footer-left" :textclass="caption">Copyright © 2020 VidUnit</text-component>
                <text-component class="footer-right" :textclass="caption">Made by Magneto IT Solutions</text-component>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../BasicComponents/Text/Text.vue";
export default {
    components: {
        TextComponent
    },
    data () {
        return{
            caption: 'caption light'
        }
    }
}
</script>