<template>
    <div class="box campaign-box margino">
        <div class="box-content">
            <tab-component
                class="campaigns"
                :tabdata="campaigntabdata"
                :activetab="campaignactivetab"
                :maxLimit="maxLimit"
                :isHiddenbar="isHiddenbar">
                <div class="tag-bar-bottom-data" v-if="campaignactivetab === 'Past Campaigns'">
                    <div class="col-12 tab-no-data-class" v-if="pastcampaign.length == 0">
                        <text-component class="subtitle2">
                            No Campaigns Avaliable
                        </text-component>
                    </div>
                    <div class="row margino campaign-outer-padding">
                        <div v-for="(pcampaign, index) in pastcampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding">
                            <div class="campaign-card">
                                <div class="campaign-upper margino">
                                    <text-component class="campaign-name" :class="subtitle2class">{{pcampaign.campaign_name}}</text-component>
                                    <text-component class="campaign-day" :class="midclass">{{pcampaign.days}} Days</text-component>
                                </div>
                                <div class="campaign-lower margino">
                                    <img class="campaign-img" src="../../../../assets/image/calender.svg" alt="calender"/>
                                    <text-component class="campaign-date" :class="midclass">
                                        {{formatDateGlobal(pcampaign.start_date)}} to {{formatDateGlobal(pcampaign.end_date)}}
                                    </text-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tag-bar-bottom-data" v-if="campaignactivetab === 'Live Campaigns'">
                    <div class="col-12 tab-no-data-class" v-if="livecampaign.length == 0">
                        <text-component class="subtitle2">
                            No Campaigns Avaliable
                        </text-component>
                    </div>
                    <div class="row margino campaign-outer-padding">
                       <div v-for="(lcampaign, index) in livecampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding">
                            <div class="campaign-card">
                                <div class="campaign-upper margino">
                                    <text-component class="campaign-name" :class="subtitle2class">{{lcampaign.campaign_name}}</text-component>
                                    <text-component class="campaign-day" :class="midclass">{{lcampaign.days}} Days</text-component>
                                </div>
                                <div class="campaign-lower margino">
                                    <img class="campaign-img" src="../../../../assets/image/calender.svg" alt="calender"/>
                                    <text-component class="campaign-date" :class="midclass">
                                        {{formatDateGlobal(lcampaign.start_date)}} to {{formatDateGlobal(lcampaign.end_date)}}
                                    </text-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tag-bar-bottom-data" v-if="campaignactivetab === 'Upcoming Past Campaigns'">
                    <div class="col-12 tab-no-data-class" v-if="upcomingcampaign.length == 0">
                        <text-component class="subtitle2">
                            No Campaigns Avaliable
                        </text-component>
                    </div>
                    <div class="row margino campaign-outer-padding">
                        <div v-for="(ucampaign, index) in upcomingcampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding">
                            <div class="campaign-card">
                                <div class="campaign-upper margino">
                                    <text-component class="campaign-name" :class="subtitle2class">{{ucampaign.campaign_name}}</text-component>
                                    <text-component class="campaign-day" :class="midclass">{{ucampaign.days}} Days</text-component>
                                </div>
                                <div class="campaign-lower margino">
                                    <img class="campaign-img" src="../../../../assets/image/calender.svg" alt="calender"/>
                                    <text-component class="campaign-date" :class="midclass">
                                        {{formatDateGlobal(ucampaign.start_date)}} to {{formatDateGlobal(ucampaign.end_date)}}
                                    </text-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab-component>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { eventBus } from "../../../../main";
import TabComponent from "../../../../BasicComponents/Tabs/Tabs.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        TextComponent,
        TabComponent
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            midclass: 'dark mid',
            maxLimit: 8,
            isHiddenbar: false,
            campaignactivetab: 'Past Campaigns',
            campaigntabdata: [
                { id: "1", value: "Past Campaigns" },
                { id: "2", value: "Live Campaigns" },
                { id: "3", value: "Upcoming Past Campaigns" },
            ],
            pastcampaign: [],
            upcomingcampaign: [],
            livecampaign: [],
            influencerCampaignData: [],
        };
    },
    methods: {
        ...mapActions("admininfluenceraction",["getinfluencercampaignbydate"]),

        getData() {
            this.getinfluencercampaignbydate({id:this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.influencerCampaignData = response.data
                this.upcomingcampaign = this.influencerCampaignData.upcoming_campaigns;
                this.pastcampaign = this.influencerCampaignData.past_campaigns;
                this.livecampaign = this.influencerCampaignData.live_campaigns;
            });
        }
    },
    created: function() {
        this.getData();

        eventBus.$on("changeActivetab",
            function(selected) {
                for (let i = 0; i < this.campaigntabdata.length; i++) {
                    // console.log(this.campaigntabdata[i].value)
                    if(this.campaigntabdata[i].value == selected){
                        // console.log("true"+selected)
                        this.campaignactivetab = selected;
                    }
                }  
            }.bind(this)
        );
    }
}
</script>