<template>
    <div class="box margino">
        <div class="personaldetail-saved">
            <div class="row margino">
                <img
                    v-if="userdata.profile_pic"
                    :src="userdata.base_url + '/' + userdata.profile_pic"
                    class="user-img"
                    alt="user-image"
                />
                <img
                    v-else
                    src="../../../../assets/image/usericon.png"
                    class="user-img"
                    alt="user-image"
                />
            </div>
            <div class="row margino">
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Full Name</text-component>
                        <text-component :class="'black'">{{userdata.full_name}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Gender</text-component>
                        <text-component :class="'black'">{{capitalizeWord(userdata.gender)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Date of Birth</text-component>
                        <text-component :class="'black'">{{formatDateGlobal(userdata.dob)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Email Address</text-component>
                        <text-component :class="'black'">{{userdata.email}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Contact Number</text-component>
                        <text-component :class="'black'">{{userdata.prefix}} {{userdata.phone}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Country</text-component>
                        <text-component :class="'black'">{{getcountryname(userdata.country)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">City</text-component>
                        <text-component :class="'black'">{{getcityname(userdata.city)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                        <text-component :class="'subtitle2'">Domain Name</text-component>
                        <text-component :class="'black'">{{userdata.domain_name}}</text-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        TextComponent
    },
    computed: {
        ...mapGetters("commonfunction", ["countryData", "cityData"])
    },
    watch: {
        countryData: {
            immediate: true,
            handler(countryData) {
                this.$data.country = countryData;
            }
        },

        cityData: {
            immediate: true,
            handler(cityData) {
                this.$data.city = cityData;
            }
        }
    },
    methods: {
        ...mapActions("publisherdata", ["getuserprofile"]),
        ...mapActions("influencerprofile", ["basicprofile"]),
        ...mapActions("commonfunction", ["getcity", "getcountry"]),



        getcountryname(data) {
            let countryname = this.country.filter(value => {
                return value.id == data;
            });
            if (countryname.length) {
                return countryname[0].name;
            } else {
                return "";
            }
        },

        capitalizeWord(string) {
            if(string){
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
            else{
                return ""
            }
        },

        getcityname(data) {
            let cityname = this.city.filter(value => {
                return value.id == data;
            });
            if (cityname.length) {
                return cityname[0].name;
            } else {
                return "";
            }
        },

        sendCountryid(data) {
            this.getcity({
                id: data
            });
        },
    },
    data() {
        return{
            userdata: [],
            country: [],
            city: [],
        }
    },
    created() {
        this.getcountry()
        this.getuserprofile({id : this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.userdata = response.data
                this.sendCountryid(response.data.country)
            });
        

    }
}
</script>