<template>
    <div class="campaignlist">
        <div class="box margino paddingo">
            <!-- <div class="box-header headerpadding">
                <text-component :class="subtitle1txtclass">Campaign List </text-component>
            </div> -->
            <div class="box-content">
                <header-tabs
                    :tabdata="tabdata"
                    :activetab="activetab"
                    :maxLimit="maxLimit"
                    :isHiddenbar="isHiddenbar">
                    <div class="tag-bar-bottom-data" v-if="activetab === 'Past Campaigns'">
                        <div class="col-12 tab-no-data-class" v-if="pastcampaign.length == 0">
                            <text-component class="subtitle2">
                                No Campaigns Avaliable
                            </text-component>
                        </div>
                        <div class="row margino campaign-outer-padding">
                            <div v-for="(pcampaign, index) in pastcampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding" @dblclick="openCampaign(pcampaign.id)">
                                <div class="campaign-card">
                                    <div class="campaign-upper margino">
                                        <text-component class="campaign-name" :class="subtitle2class">{{pcampaign.campaign_name}}</text-component>
                                        <text-component class="campaign-day" :class="midclass">{{pcampaign.days}} Days</text-component>
                                    </div>
                                    <div class="campaign-lower margino">
                                        <img class="campaign-img" src="../../../assets/image/calender.svg" alt="calender"/>
                                        <text-component class="campaign-date" :class="midclass">
                                            {{formatDateGlobal(pcampaign.start_date)}} to {{formatDateGlobal(pcampaign.end_date)}}
                                        </text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'Live Campaigns'">
                        <div class="col-12 tab-no-data-class" v-if="livecampaign.length == 0">
                            <text-component class="subtitle2">
                                No Campaigns Avaliable
                            </text-component>
                        </div>
                        <div class="row margino campaign-outer-padding">
                            <div v-for="(lcampaign, index) in livecampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding" @dblclick="openCampaign(lcampaign.id)">
                                <div class="campaign-card">
                                    <div class="campaign-upper margino">
                                        <text-component class="campaign-name" :class="subtitle2class">{{lcampaign.campaign_name}}</text-component>
                                        <text-component class="campaign-day" :class="midclass">{{lcampaign.days}} Days</text-component>
                                    </div>
                                    <div class="campaign-lower margino">
                                        <img class="campaign-img" src="../../../assets/image/calender.svg" alt="calender"/>
                                        <text-component class="campaign-date" :class="midclass">
                                            {{formatDateGlobal(lcampaign.start_date)}} to {{formatDateGlobal(lcampaign.end_date)}}
                                        </text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'Upcoming Campaigns'">
                        <div class="col-12 tab-no-data-class" v-if="upcomingcampaign.length == 0">
                            <text-component class="subtitle2">
                                No Campaigns Avaliable
                            </text-component>
                        </div>
                        <div class="row margino campaign-outer-padding">
                            <div v-for="(ucampaign, index) in upcomingcampaign" :key="index" class="col-xl-4 col-lg-6 col-12 campaign-card-padding" @dblclick="openCampaign(ucampaign.id)">
                                <div class="campaign-card">
                                    <div class="campaign-upper margino">
                                        <text-component class="campaign-name" :class="subtitle2class">{{ucampaign.campaign_name}}</text-component>
                                        <text-component class="campaign-day" :class="midclass">{{ucampaign.days}} Days</text-component>
                                    </div>
                                    <div class="campaign-lower margino">
                                        <img class="campaign-img" src="../../../assets/image/calender.svg" alt="calender"/>
                                        <text-component class="campaign-date" :class="midclass">
                                            {{formatDateGlobal(ucampaign.start_date)}} to {{formatDateGlobal(ucampaign.end_date)}}
                                        </text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header-tabs>
            </div>
        </div>
    </div>
</template>


<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import { eventBus } from "../../../main";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {},
  components: {
    TextComponent,
    HeaderTabs
  },
  computed: {
        ...mapGetters("influencercampaign", ["adminCampaignData"]),
    },
    watch: {
        adminCampaignData: {
            immediate: true,
            handler(adminCampaignData) {
                console.log(adminCampaignData)
                this.upcomingcampaign = adminCampaignData.upcoming_campaigns;
                this.pastcampaign = adminCampaignData.past_campaigns;
                this.livecampaign = adminCampaignData.live_campaigns;
            }
        }
    },
    data() {
        return {
            pastcampaign: [],
            upcomingcampaign: [],
            livecampaign: [],
            activetab: "Past Campaigns",
            maxLimit: 9,
            isHiddenbar: false,
            subtitle2class: 'subtitle2',
            midclass: 'mid',

            tabdata: [
                { id: "1", value: "Past Campaigns" },
                { id: "2", value: "Live Campaigns" },
                { id: "3", value: "Upcoming Campaigns" },
            ],

        };
    },
    created: function() {
        this.getadmincampaignbydate()

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );
    },
    methods: {
        ...mapActions("influencercampaign",["getadmincampaignbydate"]),

        openCampaign(id) {
            this.$router.push({ name: "admincampaigndetail", params:{id: id}});
        },
    },
  
};
</script>
