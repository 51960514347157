<template>
  <div>
    <button-component @onBtnClick="goToDetail(row.id)" :clsname="btnplain">Details</button-component>
  </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnplain: "btnplain",
    };
  },
  methods: {
    goToDetail(id){
        console.log(id)
        this.$router.push({name: "adminblogdetail",params: { id: id }});
    }
  }
};
</script>