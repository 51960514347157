<template>
    <div class="userdashboard agency brand">
        <!-- <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Dashboard</text-component>
                <div class="page-header-right">
                    <button-component @onBtnClick="createCampaign" :clsname="btninvitecls">Create Campaign</button-component>
                </div>
            </div>
        </div> -->
        <div class="content padding-topo">
            <!-- <notification-page></notification-page> -->
            <campaign-list-page></campaign-list-page>
            <event-calendar-page></event-calendar-page>
        </div>
        <!-- <popup-page :dashboardcreateelement="dashboardcreateelement"></popup-page> -->
    </div>
</template>
<script>
import NotificationPage from './notification.vue';
import CampaignListPage from './campaignlist.vue';
import EventCalendarPage from './eventcalendar.vue';
import ButtonComponent from '../../../BasicComponents/Button/Button.vue';
import TextComponent from '../../../BasicComponents/Text/Text.vue'; 
// import PopupPage from '../campaign/popup.vue';
// import { eventBus } from "../../main";
export default {
    components: {
        // NotificationPage, 
        CampaignListPage,
        EventCalendarPage,
        // TextComponent,
        // ButtonComponent,
        // PopupPage
    },
    data() {
        return{
            dashboardcreateelement: "0",
            h2txtclass: 'h2',
            btninvitecls: 'btnprimary',
            selectplaceholder: 'Select...',
            value1: []
        }
    },
    methods: {
        createCampaign() {
            this.dashboardcreateelement = "0";
            // this.$root.$emit('openPopup')
        },
    }
}
</script>