
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState () {
    let ratingData = [];
    let singleInfluencerRating = [];
    return{
        currentUser : {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        ratingData: ratingData,
        singleInfluencerRating: singleInfluencerRating
    }
    
}

const getters = {
    currentUser: state => state.currentUser,
    ratingData: state => state.ratingData,
    singleInfluencerRating: state => state.singleInfluencerRating
};

const actions = {
    clearAll: ({ commit }) => {
        commit("setRatingsData", [])
        commit("setSingleInfluencerRating", [])
    },
    
    getratings: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-rating")
                .then((response) => {
                    commit("setRatingsData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    createrating: ({commit,dispatch}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/influencer-rating", {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    dispatch("getratings");
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getRatingByInfulencer: ({commit}, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
              .get(process.env.VUE_APP_ROOT_API + `/influencer-rating/${id}`)
              .then((response) => {
                commit("setSingleInfluencerRating", response.data.data);
                console.log(response.data);
                resolve(response.data);
              })
              .catch((error) => {
                if (error.response && error.response.code === 401) {
                  return reject(error.response.message);
                }
                reject(error);
              });
        });
    },
}

const mutations = {
    setRatingsData: (state, value) => {
        state.ratingData = value;
    },
    setSingleInfluencerRating: (state, value) => {
        state.singleInfluencerRating = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};