<template>
    <div class="vidunit">
        <div class="left-panel">
            <side-bar></side-bar>
            <side-bar-mobile></side-bar-mobile>
        </div>
        <div class="right-panel" id="get-rightpanel-size">
            <div class="header-main">
                <header-bar></header-bar>
            </div>
            <div class="content-wrapper">
                <router-view></router-view>
                <slot/>
            </div>
            <div class="footer-main" id="get-footer-size">
                <footer-bar></footer-bar>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from "./sidebar.vue";
import SideBarMobile from "./sidebarmobile.vue";
import HeaderBar from "./header";
import FooterBar  from "./footer.vue";
export default {
    components: {
        SideBar,
        HeaderBar,
        SideBarMobile,
        FooterBar
    }
}
</script>