<template>
  <div class="influencer-campaign-detail campaigndetailonly">
    <div class="content-header">
      <text-component class="h2">Campaign Details</text-component>
    </div>
    <div class="content">
      <div class="box margino paddingo">
        <div class="box-content">
          <div class="campaign-detail-section">
            <div class="row campaign-detail-section-main margino">
              <div class="col-12 campaign-data margino">
                <!-- <div class="box-header">
                  <text-component class="subtitle1">Campaign Basic detail</text-component>
                </div> -->
                <div class="box-header"
                  style="display:inline-block;width:100%;">
                    <text-component class="subtitle2">Campaign Name</text-component>
                    <text-component class="black campaign-data-txt">{{request.campaign_name}}</text-component>
                  <!-- <text-component class="subtitle1">Campaign Basic detail</text-component> -->
                  <!-- <div class="campaign-data-header-btn">
                    <badge-component class="status-badge" :badgeclass="badgeclass">{{badgetext}}</badge-component>
                  </div> -->
                </div>
                <div class="camp-detail-header row margino">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Description:</text-component>
                    <!-- <text-component class="black campaign-data-txt">{{request.discription}}</text-component> -->
                  </div>
                </div>
                <div class="camp-detail-data row margino">
                  <div class="campaign-data-main col-12" v-if="request.hashtags">
                    <text-component class="subtitle2">Campaign Hashtags</text-component>
                    <text-component class="black tags-card campaign-data-txt"
                      v-for="(htags,index) in request.hashtags" :key="index"
                      :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                      {{htags}}
                    </text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Schedule</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{formatDateGlobal(request.start_date)}} ~ {{formatDateGlobal(request.end_date)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Influencer category</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getCategory(request.influencer_category)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Number of Influencers</text-component>
                    <text-component class="black campaign-data-txt">{{request.influencers}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Gender Ratio</text-component>
                    <text-component class="black campaign-data-txt">{{request.male_ratio}}:{{request.female_ratio}} (M:F)</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Social Media Platform</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getSocialPlatform(request.social_platform)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Content Format</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getContentFormat(request.content_format)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brand</text-component>
                    <text-component class="black campaign-data-txt">{{request.user?request.user.name:'N/A'}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12" v-if="request.brand_hashtags">
                    <text-component class="subtitle2">Brand Hashtags</text-component>
                    <text-component class="black tags-card campaign-data-txt"
                      v-for="(btags,index) in request.brand_hashtags" :key="index"
                      :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                      {{btags}}
                    </text-component>
                  </div>
                </div>







                <!-- <div class="row margino">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Campaign Name</text-component>
                    <text-component class="black campaign-data-txt">{{request.campaign_name}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Gender Ratio-Male</text-component>
                    <text-component class="black campaign-data-txt">{{request.male_ratio}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Gender Ratio-Female</text-component>
                    <text-component class="black campaign-data-txt">{{request.female_ratio}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Start Date & End Date</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{request.start_date}} ~ {{request.end_date}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Number of Influencers</text-component>
                    <text-component class="black campaign-data-txt">{{request.influencers}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Influencer category</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getCategory(request.influencer_category)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Social Media Platform</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getSocialPlatform(request.social_platform)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Content Format</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getContentFormat(request.content_format)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6" v-if="request.hashtags">
                    <text-component class="subtitle2">Campaign Hashtags</text-component>
                    <text-component class="black campaign-data-txt">{{request.hashtags.toString()}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Campaign Budget</text-component>
                    <text-component class="black campaign-data-txt">₹ {{request.budget}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6" v-if="request.brand_hashtags">
                    <text-component class="subtitle2">Brand Hashtags</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{request.brand_hashtags.toString()}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brief Description</text-component>
                    <text-component class="black campaign-data-txt">{{request.discription}}</text-component>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TextComponent,
  },
  computed: {
    ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
    ...mapGetters("commonfunction", ["platformData"]),
    
  },
  watch: {
    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = influencerCategory;
      },
    },
    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = platformData;
      },
    },
  },
  data() {
    return {
      colors: ["#AAEEF6", "#FFB480", "#BDC5ED", "#FFE474", "#BBE5B3", "#FFC4BE"],
      isPageid: false,
      selectplaceholder: "Select...",
      influenceStatus: "",
      h2txtclass: "h2",
      accountoption: [],
      selectedRows: [],
      nputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      destructivecls: "btndestructive",
      primarycls: "btnprimary",
      page: 1,
      per_page: 8,
      inputPlaceholder: "Search Platform",
      request: {},
      socialplatform: [],
      influencecat: [],
      columns: [
        {
          label: "Platform",
          field: "social_platform.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Link",
          headerAlign: "left",
          align: "left",
          representedAs: (row) =>{
            return '<a href="'+row.link+'" style="text-decoration: none; color: #F49342;" target="_blank">'+row.link+'</a>';
          },
          // field: "link",
          sortable: false,
          interpolate: true,
        },
      ],
      rows: [],
    };
  },
  methods: {
    ...mapActions("influencercampaign", ["getsinglecamaigndetails"]),

    getcampaigndetail() {
      this.getsinglecamaigndetails({
        id: this.$route.params.id,
      }).then((response) => {
        this.request = response.data;
      });
    },

    openChat() {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },

    getSocialPlatform(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.socialplatform.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getCategory(ids) {
      console.log("getCategory"+ids)
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.influencecat.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getContentFormat(id) {
      if (id) {
        let name = this.contentFormat.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },
  },
  created() {
    this.getcampaigndetail();
  },
};
</script>