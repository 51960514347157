
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState () {
    let genreData = [];
    let singleGenre = [];
    return{
        currentUser : {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        genreData: genreData,
        singleGenre: singleGenre
    }
    
}

const getters = {
    currentUser: state => state.currentUser,
    genreData: state => state.genreData,
    singleGenre: state => state.singleGenre
};

const actions = {
    clearAll: ({ commit }) => {
        commit("setGenreData", [])
        commit("setSingleGenreData", [])
    },

    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getgenres: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/interest")
                .then((response) => {
                    commit("setGenreData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getsinglegenre: ({ commit },iddata) => {
        let id = iddata.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + `/interest/${id}`)
                .then((response) => {
                    commit("setSingleGenreData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    creategenre: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/interest", {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    updategenre: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + `/interest/${id}`, {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("commonfunction/setLoading", false, { root: true });
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    deletegenre: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/interest-multiple-delete", { ...iddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setGenreData: (state, value) => {
        state.genreData = value;
    },
    setSingleGenreData: (state, value) => {
        state.singleGenre = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};