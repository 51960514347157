<template>
  <div>
    <router-view></router-view>
    <loader></loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "./pages/common_users/loader.vue";

export default {
  name: 'App',

  components: {
    Loader
  },

  data: () => ({
    GoogleAuth:null,
  }),

  created() {
    this.getplatform();
    this.getcountry();
    this.getlanguage();
    this.getlatestnotifications();
    this.getallnotifications();
    this.getWebsiteSettings();
  },
  mounted() {
    this.injectGapi()
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'VidUnit',
    // all titles will be injected into this template
    titleTemplate: '%s | VidUnit'
  },

  methods: {
    ...mapActions("commonfunction",["getplatform", "getcountry", "getlanguage","getlatestnotifications","getallnotifications"]),
    ...mapActions("frontcms",["getWebsiteSettings"]),
    injectGapi(){
      // console.log('injectGapi')
        // this.$gapi.getGapiClient().then((gapi) => {
        //   this.GoogleAuth = gapi.auth2.getAuthInstance();
        //   console.log('gapi',gapi);
        //   console.log(this.GoogleAuth);
        // }).catch((err) => {
        //   console.log('gapi err',err);
        // });
    },
  }
};
</script>
