<template>
  <div class="newuser">
    <div class="content-header">
      <div class="row">
        <div class="col-12">
          <div class="back-path mid">Users</div>
        </div>
        <div class="col-12">
          <text-component :class="headertxtclass">New User</text-component>
        </div>
      </div>
    </div>
    <form @submit.prevent="userinfo">
      <div class="content">
        <div class="box">
          <div class="row progressbar">
            <progress-component :progressdata="progressdata1" :progressclass="progressclass1"></progress-component>
            <progress-component
              class="processupcoming"
              :progressdata="progressdata2"
              :progressclass="progressclass2"
            ></progress-component>
          </div>
          <hr class="hrline" />
          <div class="box-header editheader">
            <text-component>User information</text-component>
          </div>
          <div class="box-content">
            <div class="row">
              <div class="col-md-4 col-12">
                <input-component
                  required="true"
                  class="userinput"
                  v-model="item.name"
                  :helptext="!$v.item.name.required && submitted ? validationmessage.name: false"
                  :labelclass="{'validationerr': !$v.item.name.required && submitted}"
                  :classname="{'error': !$v.item.name.required && submitted, 'default': true}"
                >Full name</input-component>
                <div class="phoneno userinput">
                  <input-component
                    required="true"
                    v-model="item.mobile_number"
                    type="number"
                    :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.mobile_number: false"
                    :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted}"
                    :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
                  >Phone number</input-component>
                  <div class="countrycode1">
                    <dropdown-component
                      v-model="item.mobile_prefix"
                      :popoverList="popoverList"
                      :btnid="btnid"
                    >
                      <img src="../../../../assets/image/globe.svg" class="globeicon" />
                    </dropdown-component>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <!-- <input-component
                  required="true"
                  class="userinput"
                  v-model="item.last_name"
                  :helptext="!$v.item.last_name.required && submitted ? validationmessage.last_name: false"
                  :labelclass="{'validationerr': !$v.item.last_name.required && submitted}"
                  :classname="{'error': !$v.item.last_name.required && submitted, 'default': true}"
                >Last name</input-component>-->
                <select-component
                  required="true"
                  label="role_name"
                  class="userinput"
                  :helptext="!$v.item.sub_role_id.required && submitted ? validationmessage.sub_role_id: false"
                  :labelclass="{'validationerr': !$v.item.sub_role_id.required && submitted}"
                  :inputclass="{'error': !$v.item.sub_role_id.required && submitted, 'dropdownmenu': true}"
                  :options="roleoptions"
                  v-model="item.sub_role_id"
                  :multiple="false"
                >Role</select-component>
              </div>
              <div class="col-md-4 col-12">
                <input-component
                  required="true"
                  class="userinput"
                  v-model="item.email"
                  :helptext="!$v.item.email.required && submitted ? validationmessage.email: false"
                  :labelclass="{'validationerr': !$v.item.email.required && submitted}"
                  :classname="{'error': !$v.item.email.required && submitted, 'default': true}"
                  :type="emailtype"
                >Email address</input-component>
              </div>
            </div>
            <hr class="hrline" />
            <div class="row">
              <div class="col-12">
                <div class="footerbtn">
                  <button-component buttontype="submit" :clsname="btncontinue">Continue</button-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../../BasicComponents/select/select.vue";
import DropdownComponent from "../../../../BasicComponents/Dropdown/dropdownwithtxt.vue";
import ProgressComponent from "../../../../BasicComponents/Progress/Progress";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    InputComponent,
    SelectComponent,
    DropdownComponent,
    ProgressComponent,
    ButtonComponent
  },
  computed: {
    ...mapGetters("user", ["rolesData"])
  },
  watch: {
    rolesData: {
      immediate: true,
      handler(rolesData) {
        this.rolefilter(rolesData);
        // this.$data.roleoptions = rolesData;
      }
    }
  },
  data() {
    return {
      item: {
        name: null,
        // last_name: null,
        email: null,
        mobile_number: null,
        sub_role_id: null,
        mobile_prefix: null
      },
      validationmessage: {
        name: "full name is required",
        // last_name: "last name is required",
        email: "please enter valid email",
        mobile_number: "please enter valid phone number with prefix",
        sub_role_id: "role is required"
      },
      submitted: false,
      headertxtclass: "h2",
      midtxtclass: "mid",
      inputcls: "default",
      btnid: "ccode",
      progressclass1: "active",
      progressclass2: "upcoming",
      emailtype: "email",
      numbertype: "number",
      btncontinue: "btnprimary",
      popoverList: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" }
      ],
      roleoptions: [
        { id: "1", label: "exa1" },
        { id: "2", label: "exa2" },
        { id: "3", label: "exa3" }
      ],
      progressdata1: [{ progressid: "1", progressname: "User information" }],
      progressdata2: [{ progressid: "2", progressname: "Assigned permission" }]
    };
  },
  validations: {
    item: {
      name: { required },
      // last_name: { required },
      email: { required, email },
      mobile_number: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(13)
      },
      sub_role_id: { required },
      mobile_prefix: { required }
    }
  },
  created: function() {
    this.getrolesData();
  },
  methods: {
    ...mapActions("user", ["newuser", "getrolesData"]),

    rolefilter(data) {
      this.$data.roleoptions = data.filter(value => {
        return value.status == "1";
      });
    },

    userinfo() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.newuser({
          name: this.item.name,
          prefix: this.item.mobile_prefix,
          phone: this.item.mobile_number,
          email: this.item.email,
          sub_role_id: this.item.sub_role_id
        }).then(response => {
          console.log(response);
          console.log(response.data.id);
          vm.$router.push({
            name: "NewUserpermission",
            params: { id: response.data.id }
          });
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
          console.log("checking");
        }).catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
      }
    }
  }
};
</script>