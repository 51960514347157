<template>
    <div class="aboutus-edit">
        <div class="template-accordion">
            <div class="tabs">
                <div class="tab">
                    <input type="checkbox" id="chck5">
                    <label class="tab-label" for="chck5">Section 1 (Whats on offer?)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section5_title"
                            required="true"
                            :helptext="!$v.contentData.section5_title.required && submitted ? validationmessage.section5_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section5_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section5_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section5_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section5_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section5_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section5_description}}</span>
                        </div>
                        <profile-uploader
                            :imageurl="getPreview('section5_image')"
                            fileuploadid="section5_image"
                            @selectfile="getfiledata($event,'section5_image')"
                            :avatar="avatar.section5_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck1">
                    <label class="tab-label" for="chck1">Section 2 (Influencer Marketing)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section1_title"
                            required="true"
                            :helptext="!$v.contentData.section1_title.required && submitted ? validationmessage.section1_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section1_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section1_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section1_subtitle"
                            :required="false"
                            :classname="{'default': true}">
                            Sub Title
                        </input-component>
                        <div class="">
                            <div class="inputtxt">
                                <text-component>Description*</text-component>
                            </div>
                            <text-area-comp v-model="contentData.section1_description"
                                required="true"
                                :helptext="!$v.contentData.section1_description.required && submitted ? validationmessage.description: false"
                                :labelclass="{'validationerr': !$v.contentData.section1_description.required && submitted}"
                                :classname="{'error': !$v.contentData.section1_description.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                        </div>
                        <profile-uploader
                            :imageurl="getPreview('section1_image')"
                            fileuploadid="section1_image"
                            @selectfile="getfiledata($event,'section1_image')"
                            :avatar="avatar.section1_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service1_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service2_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service3_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service4_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_5</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service5_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_6</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service6_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_7</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service7_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_8</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service8_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section2_9</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_service9_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck3">
                    <label class="tab-label" for="chck3">Section 3 (Content Creation & Strategy)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section3_title"
                            required="true"
                            :helptext="!$v.contentData.section3_title.required && submitted ? validationmessage.section3_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section3_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section3_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section3_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section3_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section3_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section3_description}}</span>
                        </div>
                        <!-- <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section3_subtitle"
                            required="true"
                            :helptext="!$v.contentData.section3_subtitle.required && submitted ? validationmessage.section3_subtitle: false"
                            :labelclass="{'validationerr': !$v.contentData.section3_subtitle.required && submitted}"
                            :classname="{'error': !$v.contentData.section3_subtitle.required && submitted, 'default': true}">
                            Subtitle
                        </input-component> -->
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_1_subtitle.required && submitted ? validationmessage.section3_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_1_image')"
                                        fileuploadid="section3_1_image"
                                        @selectfile="getfiledata($event,'section3_1_image')"
                                        :avatar="avatar.section3_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_2_subtitle.required && submitted ? validationmessage.section3_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_2_image')"
                                        fileuploadid="section3_2_image"
                                        @selectfile="getfiledata($event,'section3_2_image')"
                                        :avatar="avatar.section3_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_3_subtitle.required && submitted ? validationmessage.section3_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_3_image')"
                                        fileuploadid="section3_3_image"
                                        @selectfile="getfiledata($event,'section3_3_image')"
                                        :avatar="avatar.section3_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_4_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_4_subtitle.required && submitted ? validationmessage.section3_4_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_4_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_4_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_4_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_4_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_4_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_4_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_4_image')"
                                        fileuploadid="section3_4_image"
                                        @selectfile="getfiledata($event,'section3_4_image')"
                                        :avatar="avatar.section3_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <!-- <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_5</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_5_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_5_subtitle.required && submitted ? validationmessage.section3_5_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_5_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_5_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_5_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_5_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_5_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_5_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_5_image')"
                                        fileuploadid="section3_5_image"
                                        @selectfile="getfiledata($event,'section3_5_image')"
                                        :avatar="avatar.section3_5_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_6</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_6_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_6_subtitle.required && submitted ? validationmessage.section3_6_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_6_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_6_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_6_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_6_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_6_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_6_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section3_6_image')"
                                        fileuploadid="section3_6_image"
                                        @selectfile="getfiledata($event,'section3_6_image')"
                                        :avatar="avatar.section3_6_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck4">
                    <label class="tab-label" for="chck4">Section 4 (YouTube Channel Management)</label>
                    <div class="tab-content">
                        <div class="row">
                            <div class="col-12">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section4_title"
                                    required="true"
                                    :helptext="!$v.contentData.section4_title.required && submitted ? validationmessage.section4_title: false"
                                    :labelclass="{'validationerr': !$v.contentData.section4_title.required && submitted}"
                                    :classname="{'error': !$v.contentData.section4_title.required && submitted, 'default': true}">
                                    Title
                                </input-component>
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section4_subtitle"
                                    required="true"
                                    :helptext="!$v.contentData.section4_subtitle.required && submitted ? validationmessage.section4_subtitle: false"
                                    :labelclass="{'validationerr': !$v.contentData.section4_subtitle.required && submitted}"
                                    :classname="{'error': !$v.contentData.section4_subtitle.required && submitted, 'default': true}">
                                    Subtitle
                                </input-component>
                                <!-- <div class="txtgroup">
                                    <div class="inputtxt">
                                        <text-component>Description*</text-component>
                                    </div>
                                    <text-area-comp v-model="contentData.section4_description"
                                        required="true"
                                        :helptext="!$v.contentData.section4_description.required && submitted ? validationmessage.description: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_description.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_description.required && submitted, 'default': true,'txtarea':true}"
                                    ></text-area-comp>
                                </div> -->
                                <div class="txtgroup">
                                    <div class="inputtxt">
                                        <text-component>
                                            Description
                                            <sup>*</sup>
                                        </text-component>
                                    </div>
                                    <ckeditor 
                                        v-model="contentData.section4_description" 
                                        :config="editorConfig"
                                        :class="{'error': !$v.contentData.section4_description.required && submitted}">
                                    </ckeditor>
                                    <span
                                        v-if="!$v.contentData.section4_description.required && submitted"
                                        class="validationerr"
                                    >{{validationmessage.section4_description}}</span>
                                </div>
                                <profile-uploader
                                    :imageurl="getPreview('section4_image')"
                                    fileuploadid="section4_image"
                                    @selectfile="getfiledata($event,'section4_image')"
                                    :avatar="avatar.section4_image"
                                    :width="width"
                                    :height="height"
                                    :camicon="camicon"
                                ></profile-uploader>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset>
                                    <legend>Section5_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_1_subtitle.required && submitted ? validationmessage.section4_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_1_image')"
                                        fileuploadid="section4_1_image"
                                        @selectfile="getfiledata($event,'section4_1_image')"
                                        :avatar="avatar.section4_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset>
                                    <legend>Section5_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_2_subtitle.required && submitted ? validationmessage.section4_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_2_image')"
                                        fileuploadid="section4_2_image"
                                        @selectfile="getfiledata($event,'section4_2_image')"
                                        :avatar="avatar.section4_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset>
                                    <legend>Section5_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_3_subtitle.required && submitted ? validationmessage.section4_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_3_image')"
                                        fileuploadid="section4_3_image"
                                        @selectfile="getfiledata($event,'section4_3_image')"
                                        :avatar="avatar.section4_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck2">
                    <label class="tab-label" for="chck2">Section 5 (Content Acquisition & Distribution)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section2_title"
                            required="true"
                            :helptext="!$v.contentData.section2_title.required && submitted ? validationmessage.section2_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section2_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section2_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <!-- <div class="">
                            <div class="inputtxt">
                                <text-component>Description*</text-component>
                            </div>
                            <text-area-comp v-model="contentData.section2_description"
                                required="true"
                                :helptext="!$v.contentData.section2_description.required && submitted ? validationmessage.description: false"
                                :labelclass="{'validationerr': !$v.contentData.section2_description.required && submitted}"
                                :classname="{'error': !$v.contentData.section2_description.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                        </div> -->
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section2_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section2_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section2_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section2_description}}</span>
                        </div>
                        <profile-uploader
                            :imageurl="getPreview('section2_image')"
                            fileuploadid="section2_image"
                            @selectfile="getfiledata($event,'section2_image')"
                            :avatar="avatar.section2_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import ProfileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";

export default {
    props: ["contentData", "imageData", "imageDataShow", "baseUrl","submitted"],
    components: {
      InputComponent,
      TextComponent,
      TextAreaComp,
      ProfileUploader
    },
    validations: {
        contentData: {

            section1_title: { required },
            // section1_subtitle: { required },
            section1_description: { required },

            section2_title: { required },
            section2_description: { required },
            
            section3_title: { required },
            // section3_subtitle: { required },
            section3_description: { required },
            
            section3_1_subtitle: { required },
            section3_1_description: { required },

            section3_2_subtitle: { required },
            section3_2_description: { required },

            section3_3_subtitle: { required },
            section3_3_description: { required },

            section3_4_subtitle: { required },
            section3_4_description: { required },

            section3_5_subtitle: { required },
            section3_5_description: { required },

            section3_6_subtitle: { required },
            section3_6_description: { required },

            section4_title: { required },
            section4_subtitle: { required },
            section4_description: { required },
            
            section4_1_subtitle: { required },
            section4_1_description: { required },

            section4_2_subtitle: { required },
            section4_2_description: { required },

            section4_3_subtitle: { required },
            section4_3_description: { required },

            section5_title: { required },
            section5_description: { required },
        },
    },
    data() {
        return {
            validationmessage: {
                section1_title: 'title is required',
                section1_subtitle: 'subtitle is required',
                section1_description: 'description is required',
                
                section2_title: 'title is required',
                section2_description: 'description is required',

                section3_title: 'title is required',
                // section3_subtitle: 'subtitle is required',
                section3_description: 'description is required',
                
                section3_1_subtitle: "subtitle is required",
                section3_1_description: 'description is required',

                section3_2_subtitle: "subtitle is required",
                section3_2_description: 'description is required',

                section3_3_subtitle: 'description is required',
                section3_3_description: 'description is required',

                section3_4_subtitle: "subtitle is required",
                section3_4_description: 'description is required',

                // section3_5_subtitle: "subtitle is required",
                // section3_5_description: 'description is required',

                // section3_6_subtitle: "subtitle is required",
                // section3_6_description: 'description is required',

                section4_title: 'title is required',
                section4_subtitle: 'subtitle is required',
                section4_description: 'description is required',
                
                section4_1_subtitle: "subtitle is required",
                section4_1_description: 'description is required',

                section4_2_subtitle: "subtitle is required",
                section4_2_description: 'description is required',

                section4_3_subtitle: "subtitle is required",
                section4_3_description: 'description is required',

                section5_title: 'title is required',
                section5_description: 'description is required',
            },
            // submitted: false,
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: {
                section1_image: null,
                section2_image: null,
                section3_1_image: null,
                section3_2_image: null,
                section3_3_image: null,
                section3_4_image: null,
                // section3_5_image: null,
                // section3_6_image: null,
                section4_image: null,
                section4_1_image: null,
                section4_2_image: null,
                section4_3_image: null,
                section5_image: null,
            },
            width: '100px',
            height: '100px',
            camicon: '-21px',
            smallwidth: '50px',
            smallheight: '50px',
            smallcamicon: '-16px',
            editorConfig: {}
        }
    },
    methods: {
        touch () {
            this.$v.contentData.$touch();
        },
        getfiledata(e,fileName) {
            console.log(fileName)
            let image = e.target.files[0];
            this.imageData.push({file:image,name:fileName})
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
            this.avatar[fileName] = e.target.result;
            };
        },
        getPreview(filename) {
            let returnIs = '';
            if (this.imageDataShow[filename]) {
                returnIs = this.baseUrl+'/'+this.imageDataShow[filename]
            }else{
                returnIs = this.imageurl;
            }
            return returnIs;
        }
    }

}
</script>
