
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState () {
    let campaignData = [];
    let contentFormat = [];
    let influencerCategory = [];
    let singleCampaign = [];
    let ratingData = [];
    let ratingDataDetail = [];
    return{
        currentUser : {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        campaignData: campaignData,
        contentFormat: contentFormat,
        influencerCategory: influencerCategory,
        singleCampaign: singleCampaign,
        ratingData: ratingData,
        ratingDataDetail: ratingDataDetail
    }
    
}

const getters = {
    currentUser: state => state.currentUser,
    campaignData: state => state.campaignData,
    influencerCategory: state => state.influencerCategory,
    contentFormat: state => state.contentFormat,
    singleCampaign: state =>  state.singleCampaign,
    ratingData: state => state.ratingData,
    ratingDataDetail: state => state.ratingDataDetail
};

const actions = {
    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getcampaign: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/campaign")
                .then((response) => {
                    commit("setCampaignData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getcontentformat: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/content-format")
                .then((response) => {
                    commit("setContentFormat", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getinfluencercategory: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/categories")
                .then((response) => {
                    commit("setInfluencerCategory", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getsinglecampaign: ({ commit }, iddata) => {
        let id = iddata.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + `/campaign/${id}`)
                .then((response) => {
                    commit("setSingleCampaignData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    createcampaign: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/campaign", {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    updatecampaign: ({commit}, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + `/campaign/${id}`, {
                    ...data, ...AUTH_PARAMS
                })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    // commit("setSinglePermission", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    deletecampaign: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/campaign-multiple-delete", { ...iddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getCampaignrating: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/brand-campaign-rating")
                .then((response) => {
                    console.log(response.data);
                    commit('setRatingData',response.data.data)
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getbestperformer: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
          axios
            .get(process.env.VUE_APP_ROOT_API + "/best-performer/" + id)
            .then((response) => {
              console.log(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response && error.response.code === 401) {
                return reject(error.response.message);
              }
              reject(error);
            });
        });
    },

    getbestperformerbyplatform: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
          axios
            .get(process.env.VUE_APP_ROOT_API + "/best-performer-by-plateform/" + id)
            .then((response) => {
              console.log(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response && error.response.code === 401) {
                return reject(error.response.message);
              }
              reject(error);
            });
        });
    },

    getrating: ({ commit },ratingid) => {
        let id = ratingid.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/brand-rating-detail/" + id)
                .then((response) => {
                    commit("setRatingDataDetail", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },
}

const mutations = {
    setCurrentUser: (state, value) => {
        state.currentUser = value;
    },
    setCurrentCoa: (state, value) => {
        state.currentCOA = value;
    },
    setCampaignData: (state, value) => {
        state.campaignData = value;
    },
    setContentFormat: (state, value) => {
        state.contentFormat = value;
    },
    setInfluencerCategory: (state, value) => {
        state.influencerCategory = value;
    },
    setSingleCampaignData: (state, value) => {
        state.singleCampaign = value;
    },
    setRatingData: (state, value) => {
        state.ratingData = value;
    },
    setRatingDataDetail: (state, value) => {
        state.ratingDataDetail = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};