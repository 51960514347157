<template>
  <div class="branddetail-campaign-list">
    <div class="box margino">
      <div class="box-header">
        <text-component class="subtitle1">Brand Campaign</text-component>
      </div>
      <div class="box-content">
        <div class="row margino">
          <header-tabs
            class="col-12 paddingo campaignlist"
            :tabdata="tabdata"
            :activetab="activetab"
            :maxLimit="maxLimit"
            :isHiddenbar="isHiddenbar"
          >
            <div class="tag-bar-bottom-data" v-if="activetab === 'Past Campaigns'">
              <div class="col-12 tab-no-data-class" v-if="pastcampaign.length == 0">
                  <text-component class="subtitle2">
                      No Campaigns Avaliable
                  </text-component>
              </div>
              <div class="row margino campaign-outer-padding">
                <div
                  v-for="(pcampaign, index) in pastcampaign"
                  :key="index"
                  class="col-xl-4 col-lg-6 col-12 campaign-card-padding"
                >
                  <div class="campaign-card">
                    <div class="campaign-upper margino">
                      <text-component
                        class="campaign-name"
                        :class="subtitle2class"
                      >{{pcampaign.campaign_name}}</text-component>
                      <text-component class="campaign-day" :class="midclass">{{pcampaign.days}} Days</text-component>
                    </div>
                    <div class="campaign-lower margino">
                      <img
                        class="campaign-img"
                        src="../../../../assets/image/calender.svg"
                        alt="calender"
                      />
                      <text-component
                        class="campaign-date"
                        :class="midclass"
                      >{{formatDateGlobal(pcampaign.start_date)}} to {{formatDateGlobal(pcampaign.end_date)}}</text-component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tag-bar-bottom-data" v-if="activetab === 'Live Campaigns'">
              <div class="col-12 tab-no-data-class" v-if="livecampaign.length == 0">
                  <text-component class="subtitle2">
                      No Campaigns Avaliable
                  </text-component>
              </div>
              <div class="row margino campaign-outer-padding">
                <div
                  v-for="(lcampaign, index) in livecampaign"
                  :key="index"
                  class="col-xl-4 col-lg-6 col-12 campaign-card-padding"
                >
                  <div class="campaign-card">
                    <div class="campaign-upper margino">
                      <text-component
                        class="campaign-name"
                        :class="subtitle2class"
                      >{{lcampaign.campaign_name}}</text-component>
                      <text-component class="campaign-day" :class="midclass">{{lcampaign.days}} Days</text-component>
                    </div>
                    <div class="campaign-lower margino">
                      <img
                        class="campaign-img"
                        src="../../../../assets/image/calender.svg"
                        alt="calender"
                      />
                      <text-component
                        class="campaign-date"
                        :class="midclass"
                      >{{formatDateGlobal(lcampaign.start_date)}} to {{formatDateGlobal(lcampaign.end_date)}}</text-component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tag-bar-bottom-data" v-if="activetab === 'Upcoming Campaigns'">
              <div class="col-12 tab-no-data-class" v-if="upcomingcampaign.length == 0">
                  <text-component class="subtitle2">
                      No Campaigns Avaliable
                  </text-component>
              </div>
              <div class="row margino campaign-outer-padding">
                <div
                  v-for="(ucampaign, index) in upcomingcampaign"
                  :key="index"
                  class="col-xl-4 col-lg-6 col-12 campaign-card-padding"
                >
                  <div class="campaign-card">
                    <div class="campaign-upper margino">
                      <text-component
                        class="campaign-name"
                        :class="subtitle2class"
                      >{{ucampaign.campaign_name}}</text-component>
                      <text-component class="campaign-day" :class="midclass">{{ucampaign.days}} Days</text-component>
                    </div>
                    <div class="campaign-lower margino">
                      <img
                        class="campaign-img"
                        src="../../../../assets/image/calender.svg"
                        alt="calender"
                      />
                      <text-component
                        class="campaign-date"
                        :class="midclass"
                      >{{formatDateGlobal(ucampaign.start_date)}} to {{formatDateGlobal(ucampaign.end_date)}}</text-component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import { eventBus } from "../../../../main";
import { mapGetters, mapActions } from "vuex";
// import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
export default {
  components: {
    TextComponent,
    HeaderTabs
    // ButtonComponent
  },
  data() {
    return {
      pastcampaign: [],
      upcomingcampaign: [],
      livecampaign: [],
      campaignData: [],
      h2txtclass: "h2",
      btnprimary: "btnprimary",
      subtitle2class: "subtitle2",
      activetab: "Past Campaigns",
      maxLimit: 9,
      isHiddenbar: false,
      midclass: "mid",
      tabdata: [
        { id: "1", value: "Past Campaigns" },
        { id: "2", value: "Live Campaigns" },
        { id: "3", value: "Upcoming Campaigns" }
      ]
    };
  },
  created: function() {
    this.getData();

    eventBus.$on(
      "changeActivetab",
      function(selected) {
        // console.log(selected);
        this.activetab = selected;
      }.bind(this)
    );
  },
  methods: {
    ...mapActions("adminbrandaction", ["getbrandcampaignbydate"]),

    getData() {
      this.getbrandcampaignbydate({ id: this.$route.params.id })
        .then(response => {
          console.log(response);
          this.campaignData = response.data;
          this.upcomingcampaign = this.campaignData.upcoming_campaigns;
          this.pastcampaign = this.campaignData.past_campaigns;
          this.livecampaign = this.campaignData.live_campaigns;
        })
        .catch(err => {
          console.log("error msg:" + err);
        });
    }
  }
};
</script>