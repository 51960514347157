<template>
  <div>
    <div class="searchvendor-header">
      <div class="header-left">
        <div class="sidebar-desktop">
          <img
            @click="desktopMenu"
            src="../assets/image/menu.svg"
            alt="menu-icon"
            class="menuicon-desktop"
            id="micondesktop"
          />
        </div>
        <div class="sidebar-mobile">
          <img @click="mobileMenu" src="../assets/image/menu.svg" alt="menu-icon" class="menuicon" />
        </div>
      </div>
      <!-- <div class="header-middle">
                <ul>
                    <li v-for="acinfo in accountinfo" :key=acinfo.id>{{acinfo.infotxt}}&nbsp;({{acinfo.per}}%)</li>
                </ul>
      </div>-->
      <div class="header-right">
        <notification-component :role="'brand'" :notificationdata="notificationdata"></notification-component>
        <div class="myaccount">
          <myac-dropdown-component
            @linkclicked="checkFunction"
            :myacimageurl="myacimageurl"
            :myacmenu="myacmenu"
          >
            {{userinfo.name}}
            <br />
            <span>{{actype}}</span>
          </myac-dropdown-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myacDropdownComponent from "../BasicComponents/Dropdown/myaccountDropdown";
import NotificationComponent from "../BasicComponents/notification/notification.vue";
import { mapActions } from "vuex";
import router from "../routes/routes";
// import TextComponent from "../BasicComponents/Text/Text.vue";
export default {
  components: {
    myacDropdownComponent,
    NotificationComponent
  },
  data() {
    return {
      userinfo: "",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      acname: "BZ Nutrition",
      myacimageurl: "usericon.png",
      actype: "Agency",
      myacmenu: [
        // { id: "0", value: "MyAccount", link: "www.google.com" },
        { id: "1", value: "Edit Profile", link: "www.google.com" },
        // { id: "2", value: "Change Password", link: "www.google.com" },
        { id: "3", value: "Log Out", link: "www.google.com" }
      ],
      // accountinfo: [
      //     {id: "10", infotxt: "Tell us your last name", per: "10"},
      //     {id: "11", infotxt: "Connect your instagram accounts", per: "10"},
      //     {id: "12", infotxt: "Add your bank details", per: "10"},
      // ],
      notificationdata: [
        {
          id: "1",
          notification: "The Fitness campaign you took part is has ended",
          status: "unread"
        },
        {
          id: "2",
          notification: "You recived payment from last Fitness Campaign",
          status: "unread"
        },
        {
          id: "3",
          notification: "Your password has successfully changed",
          status: "read"
        },
        {
          id: "4",
          notification: "The Nutrition campaign you took part is has ended",
          status: "read"
        },
        {
          id: "5",
          notification: "Your request for the consent is approved",
          status: "read"
        }
      ]
    };
  },

  created() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    // console.log("created auth:",auth);
    this.userinfo = auth;
    if (auth.base_url && auth.profile_pic) {
      this.myacimageurl = null;
      this.userpic = auth.base_url + "/" + auth.profile_pic;
    }
  },

  methods: {
    ...mapActions("user", ["signOut"]),

    checkFunction(e) {
      if (e.target.text == "Log Out") {
        this.signOut();
      } else if (e.target.text == "Edit Profile") {
        router.push({ name: "brandprofile" });
      }
    },
    mobileMenu() {
      var x = document.getElementById("mobilemenu");
      x.classList.toggle("mobilemenu-size");
      x.classList.toggle("slide-in-left");
    },
    desktopMenu() {
      var x = document.getElementById("desktopmenu");
      x.classList.toggle("left-panel-size");
      x.style.transition = "all 0.5s ease-in-out";
      var y = document.getElementById("get-rightpanel-size");
      y.classList.toggle("rightpanel-size");
      y.style.transition = "all 0.5s ease-in-out";
      var z = document.getElementById("mlogo");
      z.classList.toggle("mlogo-size");
      z.style.transition = "all 0.5s ease-in-out";
      var p = document.getElementById("get-footer-size");
      p.classList.toggle("footer-size");
      p.style.transition = "all 0.5s ease-in-out";
      var o = document.getElementById("sendmsg");
      o.classList.toggle("sendmsg-size");
      o.style.transition = "all 0.5s ease-in-out";
    }
  }
};
</script>
