<template>
    <div class="graph-circle">
        <graph-pie
                :width="width"
                :height="height"
                :padding-top="0"
                :padding-right="0"
                :padding-bottom="0"
                :padding-left="0"
                :values="values"
                :names="names"
                :styles="styles"
                :data-format="dataFormat"
                :shape="'donut'"
                :strokeWidth="strokeWidth"
                :colors="['#F49342','#FBD4B3','#FDEFE2']">
            <tooltip :names="names"></tooltip>
        </graph-pie>
    </div>
</template>
<script>
export default {
    props: 
    [
        "width",
        "height",
        "values",
        "names",
        "strokeWidth"
    ],
    data() {
        return { 
            styles: {

            }
        }
    },
    methods: {
        dataFormat: function(a, b) {
            if(b) return b + "%";
            return a;
        }
    }
}
</script>