<template>
    <div class="content-list content-publish">
        <div class="content-header">
            <span class="h2">Article publish</span>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="row margino">
                        <div class="row col-12 paddingo">
                            <div class="col-12 col-md-6 col-lg-4">
                                <select-component
                                    class="txtgroup paddingo col-12"
                                    required="true"
                                    :helptext="!$v.item.requesttopic.required && submitted ? validationmessage.requesttopic: false"
                                    :labelclass="{'validationerr': !$v.item.requesttopic.required && submitted}"
                                    :inputclass="{'error': !$v.item.requesttopic.required && submitted, 'dropdownmenu': true}"
                                    :options="requesttopicoptions"
                                    v-model="item.requesttopic"
                                    :multiple="false"
                                    :placeholder="selectplaceholder"
                                    :disabled="true"
                                >Request Topic</select-component>
                                
                                <input-component  
                                    class="txtgroup paddingo col-12"
                                    required="true"
                                    :helptext="!$v.item.content_name.required && submitted ? validationmessage.content_name: false"
                                    :labelclass="{'validationerr': !$v.item.content_name.required && submitted}"
                                    :classname="{'error': !$v.item.content_name.required && submitted, 'default': true}"
                                    v-model="item.content_name">
                                    Article Name
                                </input-component>
                            </div>
                            <div class="improvement-col col-12 col-md-6 col-lg-8">
                                <div v-if="improvement" class="col-12 col-sm-4 col-md-6 col-lg-8">
                                    <div class="row margino">
                                        <span class="subtitle1">Improvement</span> 
                                    </div>
                                    <div class="row margino">
                                        <p class="black">
                                            {{improvement}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 paddingo">
                            <div class="row margino">
                                <file-uploader
                                    class="margin-top col-4 paddingo"
                                    :imageurl="imageurl" 
                                    :fileuploadid="fileuploadid"
                                    @selectfile="selectfile($event)">
                                </file-uploader>
                                <button-component 
                                    class="col-2 content-uoload-btn"
                                    :clsname="btninvitecls"
                                    @onBtnClick="uploadFile">
                                    Upload
                                </button-component>
                            </div>
                        </div>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Content Data
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="item.content_data" 
                                :config="editorConfig"
                                :class="{'error': !$v.item.content_data.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.item.content_data.required && submitted"
                                class="validationerr"
                            >{{validationmessage.content_data}}</span>
                        </div>
                        
                    </div>
                    <hr class="hrline"/>
                    <div class="content-publish-footer">
                        <button-component @onBtnClick="onPreview" :clsname="btninvitecls">Preview</button-component>
                        <button-component @onBtnClick="updateContent" :clsname="btninvitecls">Save</button-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isPreview}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="onPreview" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <span class="subtitle1">Article Name :</span>&nbsp;&nbsp;&nbsp;
                    <span>{{item.content_name}}</span><br/>
                    <span class="subtitle1">Content Data</span>
                    <div v-html="item.content_data"></div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import FileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        ButtonComponent,
        InputComponent,
        FileUploader,
        TextComponent,
        SelectComponent
    },
    validations: {
        item: {
            content_name: { required },
            content_data: { required },
            requesttopic: { required }
        },
    },
    data() {
        return {
            btninvitecls: "btnprimary",
            item: {
                content_name: null,
                content_data: null,
                requesttopic: null
            },
            validationmessage: {
                content_name: "content name id is required",
                content_data: "content data id is required",
                requesttopic: "request topic is required"
                
            },
            submitted: false,
            imageurl: 'doc.svg',
            fileuploadid:'q',
            editorConfig: {},
            requesttopicoptions: [],
            isPreview: false,
            selectedfile: null,
            improvement: null,
            selectplaceholder: 'Select...'
        };
    },
    methods: {
        
        ...mapActions("publisherdata", ["updatearticle", "getarticle", "readarticle", "articlerequestlist"]),

        updateContent() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                console.log(this.item)
                this.updatearticle({
                    id : this.$route.params.id,
                    article_name: this.item.content_name,
                    content: this.item.content_data,
                }).then(response => {
                    this.submitted =false
                    this.$router.push({ name: "publishercontentlist"});
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        uploadFile(){
            // if(this.selectedfile.type)
            const formData = new FormData();
            formData.append("file", this.selectedfile);
            this.readarticle(formData)
            .then(response => {
                this.item.content_data += this.addPTags(response.data.text)
            })
        },

        addPTags(txt) {
            return '<p>' + txt.replace(/\n/g, "</p>\n<p>") + '</p>';
        },

        selectfile(e) {
            this.selectedfile = e.target.files[0];
        },

        getdata() {
            this.getarticle({id : this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.item.content_name = response.data.article_name
                this.item.content_data = response.data.content
                this.item.requesttopic = response.data.request_id
                this.improvement = response.data.comment
            });
        },

        onPreview() {
            if(this.isPreview == false){
                this.isPreview = true
            }
            else{
                this.isPreview = false
            }
        },
    },
    created() {
        this.articlerequestlist()
        .then(response=>{
            console.log(response.data)
            for (let i = 0; i < response.data.length; i++) {
                this.requesttopicoptions.push({'id':response.data[i].id, 'label':response.data[i].topic})
            }
        });
        this.getdata()
    }
}
</script>