<template>
    <div class="edit-image">
        <img id="isedit" class="noteditable" @click="getEditData(row)" src="../../../assets/image/edit.svg" alt="edit-image"/>
    </div>
</template>

<script>
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {

    };
  },
  methods: { 
    getEditData(row) {
      this.$root.$emit('getEditData', row)
      if(this.isEditInvoice == true){
				this.isEditInvoice = false
			}
			else{
				this.isEditInvoice = true	
			}
    }
  },
};
</script>
