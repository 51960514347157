<template>
    <div class="userdashboard agency">
        <!-- <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Dashboard</text-component>
            </div>
        </div> -->
        <div class="content padding-topo">
            <!-- <notification-page></notification-page> -->
            <campaign-list-page></campaign-list-page>
            <event-calendar-page></event-calendar-page>
            <!-- <campaign-invite-page></campaign-invite-page> -->
        </div>
    </div>
</template>
<script>
import NotificationPage from './notification.vue';
import CampaignListPage from './campaignlist.vue';
import EventCalendarPage from './eventcalendar.vue';
import CampaignInvitePage from './campaigninvites.vue';
import TextComponent from '../../../BasicComponents/Text/Text.vue';
export default {
    components: {
        // NotificationPage, 
        CampaignListPage,
        EventCalendarPage,
        // CampaignInvitePage,
        // TextComponent
    },
    data() {
        return{
            h2txtclass: 'h2',
        }
    }
}
</script>