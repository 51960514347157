
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let pendingData = [];
    let approvedData = [];
    let rejectedData = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        pendingData: pendingData,
        approvedData: approvedData,
        rejectedData: rejectedData
    }

}

const getters = {
    currentUser: state => state.currentUser,
    pendingData: state => state.pendingData,
    approvedData: state => state.approvedData,
    rejectedData: state => state.rejectedData
};

const actions = {
    clearAll: ({ commit }) => {
        commit("setPendingData", [])
        commit("setApprovedData", [])
        commit("setRejectedData", [])
    },

    pendingConcentData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/consent/0")
                .then((response) => {
                    if (typeof response !== "undefined" && response.data) {
                        commit("setPendingData", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
    approvedConcentData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/consent/1")
                .then((response) => {
                    if (typeof response !== "undefined" && response.data) {
                        commit("setApprovedData", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
    rejectedConcentData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/consent/2")
                .then((response) => {
                    if (typeof response !== "undefined" && response.data) {
                        commit("setRejectedData", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    approveorrejectconcent: ({ commit, dispatch }, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_ROOT_API + "/consent-status", { ...data, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log('asd coming')
                    dispatch("pendingConcentData");
                    dispatch("approvedConcentData");
                    dispatch("rejectedConcentData");
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(error);
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },
}

const mutations = {
    setPendingData: (state, value) => {
        state.pendingData = value;
    },
    setApprovedData: (state, value) => {
        state.approvedData = value;
    },
    setRejectedData: (state, value) => {
        state.rejectedData = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};