
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    return {
    }

}

const getters = {
    
};

const actions = {
    getarticlelist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/article")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    createarticle: ({ state, commit }, contentdata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article", { ...contentdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getarticle: ({ state, commit }, contentdata) => {
        let id = contentdata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/article/" + id , { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    updatearticle: ({ state, commit }, contentdata) => {
        let id = contentdata.id
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article/" + id , { ...contentdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    deletearticle: ({ state, commit }, contentdata) => {
        let id = contentdata.id
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article/" + id + "/delete", { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getwebsitelist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/website-traffic")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    createwebsitelist: ({ state, commit }, websitelistdata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/website-traffic", websitelistdata, { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getwebsite: ({ state, commit }, websitelistdata) => {
        let id = websitelistdata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/website-traffic/" + id , { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    updatewebsite: ({ state, commit }, websitelistdata) => {
        let id = websitelistdata.get('id')
        console.log(id)
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/website-traffic/" + id , websitelistdata,{ ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    deletewebsite: ({ state, commit }, websitelistdata) => {
        let id = websitelistdata.id
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/website-traffic/" + id + "/delete", { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    approveorrejectcontent: ({ state, commit }, contentstatusdata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-status", {...contentstatusdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    approveorrejectwebsite: ({ state, commit }, websitestatusdata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/website-traffic-status", {...websitestatusdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    readarticle: ({ state, commit }, articledata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-readfile", articledata, { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    websitedetail: ({ state, commit }, websitedata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/user-website-details", { ...websitedata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    websitedetailid: ({ state, commit }, websitedata) => {
        let id = websitedata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-website-details/" + id, { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getwebsitedetail: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/user-website-details")
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    articlerequestlist: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/article-request", { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    articlerequest: ({ state, commit }, articlerequestdata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-request", { ...articlerequestdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    updatearticlerequest: ({ state, commit }, updatearticlerequestdata) => {
        let id = updatearticlerequestdata.id
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-request/" + id, {...updatearticlerequestdata, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    deletearticlerequest: ({ state, commit }, deletearticlerequestdata) => {
        let id = deletearticlerequestdata.id
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-request/" + id + "/delete", { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    requestedarticle: ({ state, commit }, requestedarticle) => {
        let id = requestedarticle.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/articles/" + id , { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    requestedarticles: ({ state, commit }, requestedarticle) => {
        let id = requestedarticle.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/article/" + id , { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    requestedarticlestatus: ({ state, commit }, requestedarticlestatus) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/article-status", {...requestedarticlestatus, ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
    articletraffic: ({ state, commit },articletrafficdata) => {
        let id = articletrafficdata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/article-traffic/" + id, { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    approvedarticle: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-approved-articles" , { ...AUTH_PARAMS })
                .then((response) => {
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    createdarticles: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/created-articles" , { ...AUTH_PARAMS })
                    .then((response) => {
                        console.log(response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (error.response && error.response.code === 401) {
                            return reject(error.response.message);
                        }
                    reject(error);
                });
        });
    },

    getuserprofile: ({ commit },userdata) => {
        let id = userdata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-profile/"+id)
                    .then((response) => {
                        console.log(response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (error.response && error.response.code === 401) {
                            return reject(error.response.message);
                        }
                    reject(error);
                });
            });
        },
    }

const mutations = {
    
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};