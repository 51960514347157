<template>
    <div class="action-btn">
        <button-component :clsname="btnplain">Link</button-component>
    </div>
</template>

<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue"
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      btnplain: 'btnplain'
    };
  },
};
</script>