<template>
    <div>
        <badge
            :badgeclass="{'Attention-Badge':(row.status== null),
            'Attention-Badge':(row.status=='0'),
            'Success-Badge':(row.status=='1'),
            'Warning-Badge':(row.status=='2'),'badge': true}">
            <!-- {{row.status}} {{row.date}} -->
            <span v-if="row.status == '0'">In Request</span>
            <span v-if="row.status == '1'">Published</span>
            <span v-if="row.status == '2'">Rejected</span>
        </badge>
    </div>
</template>

<script>
import Badge from '../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],

    data() {
        return {
        }
    },
}
</script>