
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let consentData = [];
    let inviteData = [];
    let agencyInviteData = [];
    let influencerCampaignData = [];
    let agencyCampaignData = [];
    let brandCampaignData = [];
    let adminCampaignData = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        consentData: consentData,
        inviteData: inviteData,
        agencyInviteData: agencyInviteData,
        influencerCampaignData: influencerCampaignData,
        agencyCampaignData: agencyCampaignData,
        brandCampaignData: brandCampaignData,
        adminCampaignData: adminCampaignData,
    }

}

const getters = {
    currentUser: state => state.currentUser,
    consentData: state => state.consentData,
    inviteData: state => state.inviteData,
    agencyInviteData: state => state.agencyInviteData,
    influencerCampaignData: state => state.influencerCampaignData,
    agencyCampaignData: state => state.agencyCampaignData,
    brandCampaignData: state => state.brandCampaignData,
    adminCampaignData: state => state.adminCampaignData
};

const actions = {
    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getconsentlist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-consent")
                .then((response) => {
                    commit("setConsentData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    setinterest: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/influencer-consent", {...iddata, ...AUTH_PARAMS})
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getpendinginvite: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/influencer-campaign")
                .then((response) => {
                    commit("setInviteData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getagencypendinginvite: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/agency-influencer-campaign")
                .then((response) => {
                    commit("setAgencyInviteData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    acceptorrejectinvitation: ({ commit }, campaigndata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/influencer-campaign-status", {...campaigndata, ...AUTH_PARAMS})
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    negotiateprice: ({ commit }, pricedata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/campaign-negotiate", {...pricedata, ...AUTH_PARAMS})
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getinfluencercampaignbydate: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/campaign-by-date")
                .then((response) => {
                    commit("setInfluencerCampaignData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("setInfluencerCampaignData", []);
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getagencycampaignbydate: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/agency-campaign-by-date")
                .then((response) => {
                    commit("setAgencyCampaignData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("setAgencyCampaignData", []);
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getbrandcampaignbydate: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/brand-campaign-by-date")
                .then((response) => {
                    commit("setBrandCampaignData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("setBrandCampaignData", []);
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
    
    getadmincampaignbydate: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/admin-campaign-by-date")
                .then((response) => {
                    commit("setAdminCampaignData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getsinglecamaigndetails: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/campaign/"+id)
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getInfluencerChatDetail: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/influencer-chat-detail", {...iddata, ...AUTH_PARAMS})
            .then((response) => {
                commit('commonfunction/setLoading', false, {root: true})
                resolve(response.data);
            })
            .catch((error) => {
                commit('commonfunction/setLoading', false, {root: true})
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    sendMessageInfluencer: ({ commit }, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
            .post(process.env.VUE_APP_ROOT_API + "/campaign-chat", {
                ...data,
                ...AUTH_PARAMS,
            })
            .then((response) => {
                commit('commonfunction/setLoading', false, {root: true})
                resolve(response.data);
            })
            .catch((error) => {
                commit('commonfunction/setLoading', false, {root: true})
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    getLinklist: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/campaign-link/" + id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    getVerifiedaccounts: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
            .get(process.env.VUE_APP_ROOT_API + "/influencer-social-account")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    sendlink: ({ commit }, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
            .post(process.env.VUE_APP_ROOT_API + "/campaign-link", data, {
                ...AUTH_PARAMS,
            })
            .then((response) => {
                commit('commonfunction/setLoading', false, {root: true})
                resolve(response.data);
            })
            .catch((error) => {
                commit('commonfunction/setLoading', false, {root: true})
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    getInfluencerStatus: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
            axios
            .get(process.env.VUE_APP_ROOT_API + "/campaign-detail/" + id )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },

    getsocialaccountbyid: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/influencer-campaign-social-account/" + id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
}

const mutations = {
    setCurrentUser: (state, value) => {
        state.currentUser = value;
    },
    setCurrentCoa: (state, value) => {
        state.currentCOA = value;
    },
    setConsentData: (state, value) => {
        state.consentData = value;
    },
    setInviteData: (state, value) => {
        state.inviteData = value;
    },
    setAgencyInviteData: (state, value) => {
        state.agencyInviteData = value;
    },
    setInfluencerCampaignData: (state, value) => {
        state.influencerCampaignData = value;
    },
    setAgencyCampaignData: (state, value) => {
        state.agencyCampaignData = value;
    },
    setBrandCampaignData: (state, value) => {
        state.brandCampaignData = value;
    },
    setAdminCampaignData: (state, value) => {
        state.adminCampaignData = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};