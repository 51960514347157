<template>
  <form @submit.prevent="handleLogin">
    <div class="login-page">
      <div class="row">
        <div class="col-12 col-md-6 login-left">
          <text-component :textclass="h2class">Log in</text-component>
          <div class="row rowpadding noaccount">
            <text-component class="mid">No account yet?</text-component>
            <router-link to="signup">
              <button-component class="btnsignup" :clsname="btnplainclass">Sign up</button-component>
            </router-link>
          </div>
          <input-component
            required="true"
            class="txtgroup"
            v-model="item.email"
            :helptext="(!$v.item.email.required || !$v.item.email.email) && submitted ? validationmessage.email: false"
            :labelclass="{'validationerr': (!$v.item.email.required || !$v.item.email.email) && submitted}"
            :classname="{'error': (!$v.item.email.required || !$v.item.email.email) && submitted, 'default': true}"
          >Email address</input-component>
          <input-component
            required="true"
            class="txtgroup"
            v-model="item.password"
            :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
            :labelclass="{'validationerr': !$v.item.password.required && submitted}"
            :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
            :type="passwordtype"
          >Password</input-component>
          <router-link to="forgotpassword">
            <button-component class="forgotpass" :clsname="btnplainclass">Forgot password?</button-component>
          </router-link>
          <br />
          <button-component class="loginbtn" buttontype="submit" :clsname="btnregularclass">Log in</button-component>
        </div>
        <div class="col-6 login-right"></div>
      </div>
    </div>
  </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent
  },
  validations: {
    item: {
      email: { required, email },
      password: { required }
    }
  },
  data() {
    return {
      item: {
        email: null,
        password: null
      },
      validationmessage: {
        email: "please enter valid email",
        password: "password is required"
      },
      submitted: false,
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnprimary"
    };
  },

  methods: {
    ...mapActions("user", ["login"]),

    handleLogin() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.login({
          email: this.item.email,
          password: this.item.password
        })
          .then(response => {
            console.log(response);
            vm.$router.push({ name: "dashboard" });
            console.log("checking");
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    }
  },
};
</script>