<template>
    <div class="influencer influencer-data admin-role">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Influencers</text-component>
            </div>
        </div>
        <div class="box paddingo">
            <!-- <div class="box-header headerpadding">
                <text-component :class="h2txtclass">Influencers </text-component>
            </div> -->
            <div class="box-content">
                <header-tabs
                    :tabdata="tabdata"
                    :activetab="activetab"
                    :maxLimit="maxLimit"
                    :isHiddenbar="isHiddenbar">
                    <div class="tag-bar-bottom-data" :class="activetab" v-if="activetab === 'New Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="columns"
                                :rows="rows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div> 
                    </div>
                    <div class="tag-bar-bottom-data" :class="activetab" v-if="activetab === 'All Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                    <maincategory-dropdown
                                        class="header-main-category"
                                        :clsname="dropCat"
                                        v-model="statusDrop"
                                        :popoverList="dropCatList">
                                        Platform
                                    </maincategory-dropdown>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="allinfluencercolumns"
                                :rows="influencerrows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" :class="activetab" v-if="activetab === 'Rejected Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="influencerrejectcolumns"
                                :rows="influencerrejectrows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div>
                    </div>
                </header-tabs>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import Action from "./action.vue";
import platform from "./influencerplatform.vue"
import ActionDetail from "./actiondetail.vue";
import Profilephoto from "./profileicon.vue";
import MaincategoryDropdown from "../../../BasicComponents/Dropdown/dropdown.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { eventBus } from "../../../main";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import {mapGetters, mapActions} from 'vuex';
export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        TextComponent,
        MaincategoryDropdown
    },
    computed: {
        ...mapGetters("admininfluenceraction",["pendingData","approvedData","rejectedData"])
    },
    watch: {
        pendingData: {
            immediate: true,
            handler(pendingData) {
                this.$data.rows = pendingData
            }
        },

        approvedData: {
            immediate: true,
            handler(approvedData) {
                this.allregrow = approvedData
                this.filterData()
            }
        },

        rejectedData: {
            immediate: true,
            handler(rejectedData) {
                this.$data.influencerrejectrows = rejectedData
            }
        },

        statusDrop: {
            immediate: true,
            handler(statusDrop) {
                this.filterData();
            }
        },
    },
    data() {
        return {
        activetab: "All Registration",
        maxLimit: 9,
        h2txtclass: 'h2',
        isHiddenbar: false,
        isChat: false,
        btnprimary: 'btnprimary',
        subtitle2class: 'subtitle2',
        midclass: 'mid',
        txtclassname: 'default',
        actionid: "moreactionbtn",
        classname: "default prefixicon",
        page: 1,
        per_page: 6,
        type: "text",
        inputPlaceholder: "Search Influencer",
        filter: "",
        to: '',
        selectedRows: [],
        dropCat: "btnbasic",
        statusDrop: "All",
        allregrow: [],
        dropCatList: [
            { id: "4", value: "All" },
            { id: "0", value: "Instagram" },
            { id: "1", value: "Facebook" },
            { id: "2", value: "Twitter" },
            { id: "3", value: "Youtube" },
        ],
        columns: [
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                // field: "phone",
                representedAs: function (row) {
                    return (row.phone)?row.prefix+' '+row.phone:'-';
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            { label: "Action",headerAlign: "left", align: "left", component: (Action)},
        ],
        rows: [],
        influencercolumns: [
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Profile Completeness",
                representedAs: function (row) {
                    return row.total_per+'%';
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Connected Social Accounts",
                field: "user_social_account_count",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            { label: "Action",headerAlign: "left", align: "left", component: (ActionDetail)},
        ],
        allinfluencercolumns: [
            { label: "Profile Photo",headerAlign: "left", align: "left", component: (Profilephoto)},
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                // field: "phone",
                representedAs: function (row) {
                    return row.prefix+row.phone;
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Status",
                representedAs: function (row) {
                    return (row.admin_status==1)?'Active':'Inactive';
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            // {
            //     label: "Profile Completeness",
            //     // field: "profilecompleteness",
            //     representedAs: function (row) {
            //         return row.total_per+'%';
            //     },
            //     align: "left",
            //     headerAlign: "left",
            //     headerClass: "class-in-header second-class",
            //     sortable: false
            // },
            // {
            //     label: "Connected Social Accounts",
            //     field: "user_social_account_count",
            //     align: "left",
            //     headerAlign: "left",
            //     headerClass: "class-in-header second-class",
            //     sortable: false
            // },
            { label: "Platforms",headerAlign: "left", align: "left", component: (platform)},
            { label: "Action",headerAlign: "left", align: "left",headerClass: "width250", component: (ActionDetail)},
        ],
        influencerrejectcolumns: [
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                // field: "phone",
                representedAs: function (row) {
                    return (row.phone)?row.prefix+' '+row.phone:'-';
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },

        ],
        influencerrejectrows: [],
        influencerrows: [
            //...
            {
                id: '1',
                influencername: 'Lungelo Ngcaba',
                email: 'lungelongcaba@gmail.com',
                profilecompleteness: 100,
                socialaccount: 'Facebook, Twitter'

            },
            {
                id: '2',
                influencername: 'Sammy Lawson',
                email: 'sammylawson@gmail.com'
            },
            {
                id: '3',
                influencername: 'Andries Grootoonk',
                email: 'amdriesgrootoonk@gmail.com'
            },
            {
                id: '4',
                influencername: 'Diego Morata',
                email: 'diegomorata@gmail.com'
            },
            {
                id: '4',
                influencername: 'Angela Longoria',
                email: 'angelalongoria@gmail.com'
            },

            //...
        ],
        tabdata: [
            { id: "1", value: "All Registration" },
            { id: "2", value: "New Registration" },
            { id: "3", value: "Rejected Registration" },
        ],
        };
    },
    created: function() {
        if(!this.checkPermission('influencer_detail') && !this.checkPermission('influencer_action') ){
            this.allinfluencercolumns.splice((this.allinfluencercolumns.length-1), 1)
        }
        this.$root.$on('onClick', () => {
            if(this.isChat == false){
                this.isChat = true
            }
            else{
                this.isChat = false
            }
        })

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );

        this.getinfluencerpending();
        this.getinfluencerapproved();
        this.getinfluencerrejected();
    },
    methods: {
        ...mapActions("admininfluenceraction",["getinfluencerpending","getinfluencerapproved","getinfluencerrejected"]),

        filterData(){
            let status = this.statusDrop.toLowerCase();
            if (status &&  status != "all") {
                let filterData = this.allregrow.filter(row=>{
                // for (let i = 0; i < row.user_social_account.length; i++) {
                //    console.log(row.user_social_account[i])
                // }
                let findmatch = 0
                row.user_social_account.forEach(element => {
                    if(status == element.platform){
                        findmatch = 1
                    }
                });
                return findmatch;
                });
                this.influencerrows = filterData;
            }else{
                this.influencerrows = this.allregrow;
            }
        },

        // platformTextToId(statusText){
        //     if (statusText == "Instagram") {
        //         return "1";
        //     }else if (statusText == "Facebook") {
        //         return "2";
        //     }else if (statusText == "Twitter") {
        //         return "3";
        //     }else if (statusText == "Youtube") {
        //         return "4";
        //     }else{
        //         return "";
        //     }
        // },

        sendMessege() {
            if(this.isChat == false){
                this.isChat = true
            }
            else{
                this.isChat = false
            }
        }
    },
  
};
</script>
