<template>
	<div class="paymentinvoice admin">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Manage Payments</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="sbarclassname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                                <maincategory-dropdown
                                    class="header-main-category"
                                    v-model="statusDrop"
                                    :clsname="dropCat"
                                    :popoverList="dropCatList">
                                    Status
                                </maincategory-dropdown>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :cboxindeterminatecls="cboxindeterminatecls"
                            :actionText="actionText"
                            :rows="rows"
                            :clsname="clsname"
                            :btnText="btnText"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :dropBtn="dropBtn"
                            :dropdownText="dropdownText"
                            :statusClear="statusClear"
                            :clearClass="clearClass"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                            @onrowenter="onEnter"
                            @onrowleave="onLeave"
                            @onrowclick="navigateDetail">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <form @submit.prevent="handleEditInvoice">
            <div :class="{'modal-window': true , 'popupopen': isEditInvoice}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="editInvoice" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <text-component :class="h2txtclass">Edit Invoice</text-component>
                    <div class="row">
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            disabledInput="disabled"
                            :helptext="!$v.edititem.invoice_number.required && editsubmitted ? createvalidationmessage.invoice_number: false"
                            :labelclass="{'validationerr': !$v.edititem.invoice_number.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.invoice_number.required && editsubmitted, 'default': true}"
                            v-model="edititem.invoice_number">
                            Invoice Id
                        </input-component>
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            disabledInput="disabled"
                            :helptext="!$v.edititem.influencername.required && editsubmitted ? createvalidationmessage.influencername: false"
                            :labelclass="{'validationerr': !$v.edititem.influencername.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.influencername.required && editsubmitted, 'default': true}"
                            v-model="edititem.influencername">
                            Influencer Name
                        </input-component>
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            disabledInput="disabled"
                            :helptext="!$v.edititem.campaignname.required && editsubmitted ? createvalidationmessage.campaignname: false"
                            :labelclass="{'validationerr': !$v.edititem.campaignname.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.campaignname.required && editsubmitted, 'default': true}"
                            v-model="edititem.campaignname">
                            Campaign Name
                        </input-component>
                        <!-- <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="campaignnameoption"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            disabled="disabled"
                            :helptext="!$v.edititem.campaignname.required && editsubmitted ? createvalidationmessage.campaignname: false"
                            :labelclass="{'validationerr': !$v.edititem.campaignname.required && editsubmitted}"
                            :inputclass="{'error': !$v.edititem.campaignname.required && editsubmitted, 'dropdownmenu': true}"
                            v-model="edittempcampaign">
                            Campaign Name 
                        </select-component> -->
                        <date-time-picker
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :inputid="inputid" 
                            required="true"
                            :helptext="!$v.edititem.invoicedate.required && editsubmitted ? createvalidationmessage.invoicedate: false"
                            :labelclass="{'validationerr': !$v.edititem.invoicedate.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.invoicedate.required && editsubmitted, 'default': true}"
                            v-model="edititem.invoicedate">
                            Date of Invoice
                            </date-time-picker>
                        <input-component
                            class="txtgroup amountinputbox col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.edititem.amount.required && editsubmitted ? createvalidationmessage.amount: false"
                            :labelclass="{'validationerr': !$v.edititem.amount.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.amount.required && editsubmitted, 'default': true}"
                            v-model="edititem.amount"
                            :disabledInput = "'disabled'">
                            Amount
                        </input-component>
                        <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="statusoption"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.edititem.status.required && editsubmitted ? createvalidationmessage.status: false"
                            :labelclass="{'validationerr': !$v.edititem.status.required && editsubmitted}"
                            :inputclass="{'error': !$v.edititem.status.required && editsubmitted, 'dropdownmenu': true}"
                            v-model="edititem.status">
                            Status 
                        </select-component>
                        <div class="txtgroup upload-doc col-12 col-sm-6 col-md-4">
                            <span v-if="attachmentlink == null" class="subtitle1">No Attachment Avaliable</span>
                            <a v-else :href="attachmentlink">
                                <span class="subtitle1">Click To Download Attachment</span>
                            </a>
                        </div>
                    </div>
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component buttontype="submit" :clsname="btninvitecls">Save</button-component>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- /Modal -->
    </div>
        
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import DateTimePicker from "../../../BasicComponents/Datetime/datetime.vue";
import MaincategoryDropdown from "../../../BasicComponents/Dropdown/dropdown.vue";
import SelectRow from "./selectrow";
import SelectAll from "./selectall";
import StatusBadge from "./statusbadge";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import EditComponent from './editpaymentstatus.vue';
import { eventBus } from "../../../main";
import { required, numeric } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    props: {},
    components: {
        DatatableComponent,
        TextComponent,
        searchBar,
        MaincategoryDropdown,
        ButtonComponent,
        InputComponent,
        SelectComponent,
        DateTimePicker
    },
    validations: {
        edititem: {
            invoiceid: { required },
            invoice_number: { required },
            influencername: { required },
            campaignname: { required },
            invoicedate: { required },
            amount: { required },
            status: { required }
        }
    },
    watch: {
        statusDrop: {
            immediate: true,
            handler(statusDrop) {
                this.filterData();
            }
        },

        selected_rows: function() {
            for (var row of this.rows) {
                // console.log(this.rows);
                if (this.selectedRows.indexOf(row) === -1) {
                return;
                }
            }
            console.log(this.selectedRows);

            eventBus.$emit("all-selected");
        }
    },
    data() {
        return {
        edititem: {
            invoiceid: null,
            influencername: null,
            campaignname: null,
            invoicedate: null,
            amount: null,
            status: null,
            invoice_number: null,
            campaign_id: null,
            influencer_id: null,
            email: null,
            prefix: null,
            number: null
        },
        createvalidationmessage: {
            invoiceid: "Invoice id is required",
            influencername: "Influencer name id is required",
            campaignname: "Campaign name is required",
            invoicedate: "Invoice date is required",
            amount: "Amount is required",
            status: "Status is required",
        },
        attachmentlink: null,
        statusDrop: null,
        createsubmitted: false,
        editsubmitted: false,
        actionid: "moreactionbtn",
        cboxindeterminatecls: "cboxindeterminate",
        actionText: "More actions",
        clsname: "btnsort",
        btnText: "Sort",
        type: "text",
        selectplaceholder: 'Select...',
        inputPlaceholder: "Search Invoice ID",
        classname: "default",
        sbarclassname: 'default prefixicon',
        dropBtn: "btnbasic",
        dropdownText: "Sub Category",
        statusClear: "Clear",
        clearClass: "btnplain",
        btninvitecls: "btnprimary",
        dropCat: "btnbasic",
        dropCatText: "Main Category",
        statusid: "btnstatus",
        filter: "",
        selected: "",
        row: "",
        h2txtclass: 'h2',
        h4txtclass: 'h4',
        isMobileMenu: false,
        dateclassname: 'default',
        page: 1,
        per_page: 8,
        selectedRows: [],
        followupid: [],
        ProfileData: [],
        phnumberboxtype: "number",
        inputid: 'startdate',
        btnid: 'countrybtn',
        editbtnid: 'editcountrybtn',
        isEditInvoice: false,
        tempcampaign: null,
        edittempcampaign: null,
        campaigndata: [],
        influencerdata: [],
        columns: [
            {
                label: "Invoice ID",
                field: "invoice_number",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Influencer Name",
                field: "users.name",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Campaign Name",
                field: "campaigns.campaign_name",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Date of Invoice",
                // field: "invoice_date",
                representedAs: row => {
                    return this.formatDateGlobal(row.invoice_date);
                },
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Total Amount",
                field: "amount",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            { label: "", component: EditComponent, align: "right" },
            {   
                label: "Status",
                component: StatusBadge,
                align: 'left',
                headerAlign: 'left',
                sortable: false
            },
        ],
        rows: [],
        datarows: [],
        dropCatList: [
            { id: "3", value: "All" },
            { id: "0", value: "Created" },
            { id: "1", value: "In Process" },
            { id: "2", value: "Paid" },
        ],
        campaignnameoption: [
            {id: '0', label: 'a'},
            {id: '1', label: 'b'},
            {id: '2', label: 'c'}
        ],
        influencerlist: [],
        countrylist: [
            { id: '0', value: '+91' },
            { id: '1', value: '+92' },
            { id: '2', value: '+93' },
            { id: '3', value: '+94' }
        ],
        statusoption: [
            {id: '0', label: 'Created'},
            {id: '1', label: 'In Process'},
            {id: '2', label: 'Paid'},
        ]
        };
    },
    methods: {
        ...mapActions("admininvoice",["getadmininvoice"]),
        ...mapActions("influencerinvoice",["updateinvoice"]),

        navigateDetail(row){
            this.$router.push({name: "admininvoicedetail",params: { id: row.id }});
        },

        handleEditInvoice(){
            this.editsubmitted = true;
            if (this.$v.edititem.$invalid) {
                this.$v.edititem.$touch();
            } else {
                console.log("data"+this.edititem.status)

                const formData = new FormData();
                // let attachment = this.editselectedfile;
                let Data = {
                    id: this.edititem.invoiceid,
                    campaign_id: this.edititem.campaign_id,
                    amount: this.edititem.amount,
                    invoice_date: this.edititem.invoicedate,
                    status: this.edititem.status,
                    influencer_id: this.edititem.influencer_id,
                    email: this.edititem.email,
                    prefix: this.edititem.prefix,
                    phone: this.edititem.number,
                };
                formData.append("Data", JSON.stringify(Data));
                // formData.append("attachment", attachment);
                formData.append("id", this.edititem.invoiceid,);

                this.updateinvoice(formData).then(response => {
                    this.isEditInvoice = false
                    this.editsubmitted =false
                    this.getInvoicedata()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        getInvoicedata() {
            this.getadmininvoice()
            .then(response=>{
                console.log(response);
                // this.rows = response.data
                this.datarows = response.data
                this.filterData();
                // console.log(this.rows)
            });
        },
        onEnter(row) {
            // console.log(row)
            row.editable = "true"
        },
        onLeave(row) {
            // console.log(row)
            row.editable = "false"
        },
        editInvoice() {
            if(this.isEditInvoice == true){
				this.isEditInvoice = false
			}
			else{
				this.isEditInvoice = true	
			}
        },

        filterData(){
            let status = this.statusTextToId(this.statusDrop);
            if (status) {
                let filterData = this.datarows.filter(row=>{
                return row.status == status
                });
                this.rows = filterData;
            }else{
                this.rows = this.datarows;
            }
        },

        statusTextToId(statusText){
            if (statusText=="Paid") {
                return "2";
            }else if (statusText=="Created") {
                return "0";
            }else if (statusText=="In Process") {
                return "1";
            }else{
                return "";
            }
        },

        redirectFun(row) {
            this.$router.push({name: "admininvoicedetail",params: { id: row.id }});
        }
    },
    created: function() {
        if(!this.checkPermission('invoice_update')){
            this.columns.splice((this.columns.length-1), 1)
        }
        this.getInvoicedata()
        this.$root.$on('getEditData', (row) => {
            // console.log('row',row)
            this.editInvoice();
            this.edititem.invoiceid = row.id
            this.edititem.invoice_number = row.invoice_number
            this.edititem.influencername = (row.users && row.users.name)?row.users.name:'';
            this.edititem.campaignname = (row.campaigns && row.campaigns.campaign_name)?row.campaigns.campaign_name:'';
            this.edititem.invoicedate = row.invoice_date.substring(0, 10)
            this.edititem.amount = row.amount
            this.edititem.status = row.status
            this.edititem.campaign_id = row.campaign_id
            this.edititem.influencer_id = row.influencer_id
            this.edititem.email = row.email
            this.edititem.prefix = row.prefix
            this.edititem.number = row.phone
            if(row.attachment){
                this.attachmentlink = row.campaigns.invoiceAttachmentUrl+"/"+row.id+"/"+row.attachment
            }
            else{
                this.attachmentlink = null
            }
        })

        eventBus.$on("onrowclick",function(row) { 
            this.redirectFun(row)
        }.bind(this)
        );

        eventBus.$on(
        "selected",
        function(row) {
            // console.log(row);
            if (this.selectedRows.indexOf(row) === -1) {
            this.selectedRows.push(row);
            }
        }.bind(this)
        );

        eventBus.$on(
        "deselected",
        function(row) {
            if (this.selectedRows.indexOf(row) !== -1) {
            let index = this.selectedRows.indexOf(row);

            this.selectedRows.splice(index, 1);
            }
        }.bind(this)
        );

        eventBus.$on(
        "select-all",
        function(selected) {
            console.log(selected);
            this.selectedRows = this.rows.slice(0);
            // Vue.set(this, "selected_rows", this.rows.slice(0));
        }.bind(this)
        );

        eventBus.$on(
        "deselect-all",
        function(selected) {
            console.log(selected);
            this.selectedRows = [];
            // Vue.set(this, "selected_rows", []);
        }.bind(this)
        );
    },
    beforeDestroy() {
        this.$root.$off("onrowclick", this.fn);
    }
};
</script>
