<template>
  <div class="suggested-influencer-section">
    <div class="datatable-main">
      <div class="data-table-header">
        <div class="data-table-header-inner">
          <search-bar
            class="header-searchbar"
            :classname="classname"
            :type="type"
            :inputPlaceholder="inputPlaceholder"
            v-model="filter"
          ></search-bar>
        </div>
      </div>
      <datatable-component
        class="dtable-main"
        :selected-rows="selectedRows"
        :columns="columns"
        :rows="rows"
        :type="type"
        :inputPlaceholder="inputPlaceholder"
        :classname="classname"
        :filter="filter"
        :page="page"
        :per_page="per_page"
      ></datatable-component>
    </div>
    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isInvite}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="sendInvite"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Request For Campaign</text-component>
        </div>
        <div class="row message-desc">
          <input-component
            class="txtgroup col-12"
            v-model="item.budget"
            required="true"
            :helptext="!$v.item.budget.required && submitted ? validationmessage.budget: false"
            :labelclass="{'validationerr': !$v.item.budget.required && submitted}"
            :classname="{'error': !$v.item.budget.required && submitted, 'default': true}"
          >Influencer Budget</input-component>
          <select-component
            class="txtgroup col-12"
            required="true"
            :helptext="!$v.item.account.required && submitted ? validationmessage.account: false"
            :labelclass="{'validationerr': !$v.item.account.required && submitted}"
            :inputclass="{'error': !$v.item.account.required && submitted, 'dropdownmenu': true}"
            :options="accountoption"
            v-model="item.account"
            :multiple="true"
            :placeholder="selectplaceholder"
          >Accounts</select-component>
          <select-component
            class="txtgroup col-12"
            required="true"
            :helptext="!$v.item.type.required && submitted ? validationmessage.type: false"
            :labelclass="{'validationerr': !$v.item.type.required && submitted}"
            :inputclass="{'error': !$v.item.type.required && submitted, 'dropdownmenu': true}"
            :options="typeoption"
            v-model="item.type"
            :multiple="false"
            :placeholder="selectplaceholder"
          >Type</select-component>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="handleSendinvite" :clsname="btnprimary">Send Invite</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>
<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import Action from "./suggestinflueaction.vue";
import usericon from "./suggestinflueicon.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import SelectComponent from "../../../../BasicComponents/select/select.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DatatableComponent,
    searchBar,
    TextComponent,
    SelectComponent,
    InputComponent,
    ButtonComponent,
  },
  computed: {
    ...mapGetters("admincampaignaction", ["allInfluencer"]),
  },
  watch: {
    allInfluencer: {
      immediate: true,
      handler(allInfluencer) {
        this.$data.rows = allInfluencer;
      },
    },
  },
  validations: {
    item: {
      budget: { required },
      account: { required },
      type: { required },
    },
  },
  data() {
    return {
      item: {
        budget: null,
        account: null,
        type: null,
      },
      validationmessage: {
        budget: "influencer budget is required ",
        account: "account is required ",
        type: "type budget is required ",
      },
      submitted: false,
      currentrowid: null,
      selectedRows: [],
      inputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      h2txtclass: "h2",
      inputclass: "default",
      financefilter: "",
      page: 1,
      per_page: 8,
      accountvalue: "",
      typevalue: "",
      isInvite: false,
      selectplaceholder: "Select...",
      btnprimary: "btnprimary",
      columns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Email",
          field: "email",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Action",
          headerAlign: "left",
          align: "left",
          component: Action,
        },
      ],
      rows: [
        {
          name: "Lungelo Ngcaba",
          email: "lungelongcaba@gmail.com",
        },
        {
          name: "Sammy Lawson",
          email: "sammylawson@gmail.com",
        },
      ],
      accountoption: [],
      typeoption: [],
    };
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "getAllinfluncerlist",
      "sendInvitation",
      "getVerifiedaccounts",
      "getPosttype",
    ]),

    getallinfluencerlist() {
      this.getAllinfluncerlist({
        id: this.$route.params.id,
      });
    },

    sendInvite(row) {
      this.currentrowid = row.id;
      this.getVerifiedaccounts({
        id: row.id,
      }).then((response) => {
        this.accountoption = this.setdata(response.data);
      });
      this.getPosttype().then((response) => {
        this.typeoption = this.settypedata(response.data);
      });
      if (this.isInvite == true) {
        this.isInvite = false;
      } else {
        this.isInvite = true;
      }
    },

    setdata(data) {
      let newArray = [];
      data.map((value) => {
        newArray.push({
          id: value.id,
          label: value.name,
        });
      });
      return newArray;
    },

    settypedata(data) {
      let newArray = [];
      data.map((value) => {
        newArray.push({
          id: value.id,
          label: value.name,
        });
      });
      return newArray;
    },

    handleSendinvite() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.sendInvitation({
          campaign_id: this.$route.params.id,
          influencer_id: this.currentrowid,
          offered_price: this.item.budget,
          account: this.item.account.toString(),
          content_type: this.item.type,
        })
          .then((response) => {
            vm.getallinfluencerlist();
            this.isInvite = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },
  },
  created: function () {
    this.$root.$on("onClick", (row) => {
      this.sendInvite(row);
    });

    this.getallinfluencerlist();
  },
};
</script>