
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let inviteData = [];
    let selectedIds = [];
    let categoryData = [];
    let registerInfluencer = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        inviteData: inviteData,
        selectedIds: selectedIds,
        categoryData: categoryData,
        registerInfluencer: registerInfluencer
    }

}

const getters = {
    currentUser: state => state.currentUser,
    inviteData: state => state.inviteData,
    selectedIds: state => state.selectedIds,
    categoryData: state => state.categoryData,
    registerInfluencer: state => state.registerInfluencer
};

const actions = {
    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    getinfluencerinvitelist: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-invited-influencer")
                .then((response) => {
                    commit("setInviteData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    deleteinfluencer: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/delete-influencer", { ...iddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    deletecategoryinfluencer: ({ commit }, iddata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/add-url", { ...iddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    addSelectedIds: ({ commit }, data) => {
        commit("setSelectedIds", data);
    },

    getcategories: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/categories")
                .then((response) => {
                    commit("setCategoryData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getregisteredinfluencer: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/category-influencer")
                .then((response) => {
                    commit("setRegisterInfluencer", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    assigncattoinfluencer: ({ commit }, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/create-influencer-category",{...data, ...AUTH_PARAMS})
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    resolve(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setCurrentUser: (state, value) => {
        state.currentUser = value;
    },
    setCurrentCoa: (state, value) => {
        state.currentCOA = value;
    },
    setInviteData: (state, value) => {
        state.inviteData = value;
    },
    setSelectedIds: (state, value) => {
        state.selectedIds = value;
    },
    setCategoryData: (state, value) => {
        state.categoryData = value;
    },
    setRegisterInfluencer: (state, value) => {
        state.registerInfluencer = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};