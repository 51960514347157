<template>
    <div class="socialinsight boxmargin">
        <div class="box margino paddingo">
            <div class="box-content">
                <div class="myprofile-header insight-head-padding">
                    <text-component class="myprofile-header-txt" :class="subtitle1class">Social Account Insights</text-component>
                    <div class="myprofile-header-right">
                        <span v-if="lastSync">Last Synced : {{lastSync}} </span>
                        <button-component @onBtnClick="refresh()" :clsname="basicclass">Refresh</button-component>
                    </div>
                </div>
                <div class="margino">
                    <header-tabs
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div v-for="(singleSocial,index) in socialInsightsData" :key="index">
                            <div class="tag-bar-bottom-data" v-if="activetab == (singleSocial.plateform+index)">
                                <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='facebook' && singleSocial.data.profile">
                                    <!-- {{singleSocial.data}} -->
                                    <div class="row col-12 paddingo margino social-insight-cards">
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.name">      
                                            <div class="profile-detail-card-inner subscriber-total">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.profile.name}}</text-component>
                                                    <br><text-component class="dark">Name</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.birthday">      
                                            <div class="profile-detail-card-inner birthday">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/birthday-cake.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.profile.birthday}}</text-component>
                                                    <br><text-component class="dark">Birthday</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.age_range!=undefined">      
                                            <div class="profile-detail-card-inner age-range">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/parental-control.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.profile.age_range}}</text-component>
                                                    <br><text-component class="dark">Age Range</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.profile.email">      
                                            <div class="profile-detail-card-inner email">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/card-email.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.profile.email}}</text-component>
                                                    <br><text-component class="dark">Email</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.posts && singleSocial.data.posts.data.length">      
                                            <div class="profile-detail-card-inner total-post">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalcomments.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.posts.data.length}}</text-component>
                                                    <br><text-component class="dark">Total Post</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.pages && singleSocial.data.pages.length">      
                                            <div class="profile-detail-card-inner pages">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/page.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.pages.length}}</text-component>
                                                    <br><text-component class="dark">Total Pages</text-component>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.name">
                                        <div class="row margino">
                                            <text-component class="dark">Name</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.profile.name}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.birthday">
                                        <div class="row margino">
                                            <text-component class="dark">Birthday</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.profile.birthday}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.age_range">
                                        <div class="row margino">
                                            <text-component class="dark">Age Range</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.profile.age_range}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.email">
                                        <div class="row margino">
                                            <text-component class="dark">Email</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.profile.email}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.posts.data">
                                        <div class="row margino">
                                            <text-component class="dark">Total Posts</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.posts.data.length}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.pages.length">
                                        <div class="row margino">
                                            <text-component class="dark">Total Pages</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.pages.length}}</text-component>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='youtube' && singleSocial.data">                    
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.title">
                                        <div class="row margino">
                                            <text-component class="dark subtitle2">Channel Name</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.title}}</text-component>
                                        </div>
                                    </div>
                                    <div class="row graph-main-row col-12 paddingo margino">
                                        <gen-ratio-graph :activeindex="activetab" :gendata="socialInsightsData"></gen-ratio-graph>
                                    </div>
                                    <div class="row col-12 paddingo margino social-insight-cards">
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.viewCount">      
                                            <div class="profile-detail-card-inner total-views">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalviews.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.viewCount}}</text-component>
                                                    <br><text-component class="dark">Total Views</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelDataFromBegin.rows[0][4]">      
                                            <div class="profile-detail-card-inner total-comments">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalcomments.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.channelDataFromBegin.rows[0][4]}}</text-component>
                                                    <br><text-component class="dark">Total Comments</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelDataFromBegin.rows[0][3]">      
                                            <div class="profile-detail-card-inner red-views">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/redviews.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.channelDataFromBegin.rows[0][3]}}</text-component>
                                                    <br><text-component class="dark">Red Views</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelData.rows[0][5]">      
                                            <div class="profile-detail-card-inner subscriber-gained">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/video.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][5]}}</text-component>
                                                    <br><text-component class="dark">Subscribers Gained</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.channelData.rows[0][6]">      
                                            <div class="profile-detail-card-inner subscriber-lost">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/subscriberslost.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][6]}}</text-component>
                                                    <br><text-component class="dark">Subscribers Lost</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.subscriberCount">      
                                            <div class="profile-detail-card-inner subscriber-total">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.subscriberCount}}</text-component>
                                                    <br><text-component class="dark">Total Subscribers</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="singleSocial.data.videoCount">      
                                            <div class="profile-detail-card-inner total-video">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalvideos.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.videoCount}}</text-component>
                                                    <br><text-component class="dark">Total Videos</text-component>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.viewCount">
                                        <div class="row margino">
                                            <text-component class="dark">Total Views</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.viewCount}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.channelDataFromBegin.rows[0][4]">
                                        <div class="row margino">
                                            <text-component class="dark">Total Comments</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.channelDataFromBegin.rows[0][4]}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.channelDataFromBegin.rows[0][3]">
                                        <div class="row margino">
                                            <text-component class="dark">Red Views</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.channelDataFromBegin.rows[0][3]}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.channelData.rows[0][5]">
                                        <div class="row margino">
                                            <text-component class="dark">Subscribers Gained</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][5]}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.channelData.rows[0][6]">
                                        <div class="row margino">
                                            <text-component class="dark">Subscribers Lost</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.channelData.rows[0][6]}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.subscriberCount">
                                        <div class="row margino">
                                            <text-component class="dark">Total Subscribers</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.subscriberCount}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.videoCount">
                                        <div class="row margino">
                                            <text-component class="dark">Total Videos</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.videoCount}}</text-component>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='twitter' && singleSocial.data.basic">
                                    <!-- {{singleSocial.data}} -->
                                    <div class="row col-12 paddingo margino social-insight-cards">
                                        <!-- <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.name">      
                                            <div class="profile-detail-card-inner subscriber-total">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                                    <br><text-component class="dark">Name</text-component>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.screen_name">
                                            <div class="profile-detail-card-inner subscriber-total">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">@{{singleSocial.data.basic.screen_name}}</text-component>
                                                    <br><text-component class="dark">Twitter Id</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                            <div class="profile-detail-card-inner total-followers">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                                    <br><text-component class="dark">Total Followers</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.friends_count">
                                            <div class="profile-detail-card-inner total-followers">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.friends_count}}</text-component>
                                                    <br><text-component class="dark">Total Friends</text-component>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.favourites_count">
                                            <div class="profile-detail-card-inner total-likes">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/Likes.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.favourites_count}}</text-component>
                                                    <br><text-component class="dark">Total Likes</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-6" v-if="singleSocial.data.basic.location">
                                            <div class="profile-detail-card-inner location">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/locations.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.location}}</text-component>
                                                    <br><text-component class="dark">Location</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12" v-if="singleSocial.data.basic.description">
                                            <div class="profile-detail-card-inner bio">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/bio.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.description}}</text-component>
                                                    <br><text-component class="dark">Bio</text-component>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.name">
                                        <div class="row margino">
                                            <text-component class="dark">Name</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.screen_name">
                                        <div class="row margino">
                                            <text-component class="dark">Twitter Id</text-component>
                                            <text-component class="dark mid">@{{singleSocial.data.basic.screen_name}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Followers</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.friends_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Friends</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.friends_count}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.favourites_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Likes</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.favourites_count}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.location">
                                        <div class="row margino">
                                            <text-component class="dark">Location</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.location}}</text-component>
                                        </div>
                                    </div> -->
                                    <!-- <div class="profile-detail-txt col-12" v-if="singleSocial.data.basic.description">
                                        <div class="row margino">
                                            <text-component class="dark">Description</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.description}}</text-component>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='instagram' && singleSocial.data.basic">
                                    <!-- {{singleSocial.data}} -->
                                    <div class="row col-12 margino">
                                        <text-component class="dark">Name</text-component>
                                        <text-component style="padding-left:10px" class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                    </div>
                                    <div class="row col-12 paddingo margino social-insight-cards">
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.username">      
                                            <div class="profile-detail-card-inner subscriber-total">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/totalsubscribers.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.username}}</text-component>
                                                    <br><text-component class="dark">User Name</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.media_count!=undefined">
                                            <div class="profile-detail-card-inner birthday">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/page.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.media_count}}</text-component>
                                                    <br><text-component class="dark">Total Media</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.followers_count!=undefined">      
                                            <div class="profile-detail-card-inner age-range">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/friends.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                                    <br><text-component class="dark">Total Follower</text-component>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-detail-card col-12 col-sm-6 col-xl-4" v-if="singleSocial.data.basic.follows_count!=undefined">      
                                            <div class="profile-detail-card-inner email">
                                                <div class="card-image-div">
                                                    <img src="../../../assets/image/card-email.svg" alt="card-icon" class="card-image" width="50px" height="50px">
                                                </div>
                                                <div class="card-detail-txt">
                                                    <text-component class="dark mid">{{singleSocial.data.basic.follows_count}}</text-component>
                                                    <br><text-component class="dark">Total Follows</text-component>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.name">
                                        <div class="row margino">
                                            <text-component class="dark">Name</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.username">
                                        <div class="row margino">
                                            <text-component class="dark">User Name</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.username}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.media_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Media</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.media_count}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Followers</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.follows_count">
                                        <div class="row margino">
                                            <text-component class="dark">Total Follows</text-component>
                                            <text-component class="dark mid">{{singleSocial.data.basic.follows_count}}</text-component>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tag-bar-bottom-data" v-if="activetab === 'Instagram'">
                            <div class="row margino socialinsight-info">
                                <div class="profile-detail-txt col-12 col-xl-6">
                                    <div class="row margino">
                                        <text-component class="dark">Followers</text-component>
                                        <text-component class="dark mid">27,501</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt col-12 col-xl-6">
                                    <div class="row margino">
                                        <text-component class="dark">Category</text-component>
                                        <text-component class="dark mid">Fitness & Health</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt col-12 col-xl-6">
                                    <div class="row margino">
                                        <text-component class="dark">Engagement Level</text-component>
                                        <text-component class="dark mid">High</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt col-12 col-xl-6">
                                    <div class="row margino">
                                        <text-component class="dark">Active Audience</text-component>
                                        <text-component class="dark mid">19,781</text-component>
                                    </div>
                                </div>
                                <div class="profile-detail-txt col-12 col-xl-6">
                                    <div class="row margino">
                                        <text-component class="dark">Most Viewed Post</text-component>
                                        <a href="#" class="link"><text-component class="dark mid">Link for the post</text-component></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Facebook'">
                            Facebook
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Twitter'">
                            Twitter
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Youtube'">
                            Youtube
                        </div> -->
                    </header-tabs>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-xl-6 paddingo graph-main graphalign-left">
                <div class="box">
                    <div class="graph-txt">
                        <text-component class="subtitle1">Followers</text-component>
                    </div>
                    <graph-component
                        :width="width"
                        :height="height"
                        :axis-min="axismin"
                        :axis-max="axismax"
                        :axisStep="axisStep"
                        :labels="labels"
                        :values="values"
                        :barMargin="barMargin"
                        :names="names">
                    </graph-component>
                </div>
            </div>
            <div class="col-12 col-xl-6 paddingo graph-main graphalign-right">
                <div class="box">
                    <div class="graph-txt">
                        <text-component class="subtitle1">Engagement Insight</text-component>
                    </div>
                    <graph-component
                        :width="width"
                        :height="height"
                        :axis-min="axismin"
                        :axis-max="axismax"
                        :axisStep="axisStep"
                        :labels="labels"
                        :values="engagementvalues"
                        :barMargin="barMargin"
                        :names="engagementname">
                    </graph-component>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
// import GraphComponent from "../../../BasicComponents/graph/graph.vue";
import CircleGraphComponent from "../../../BasicComponents/graph/graphcircle.vue";
import genRatioGraph from "./genratiograph.vue"
import { eventBus } from "../../../main";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        TextComponent,
        HeaderTabs,
        ButtonComponent,
        genRatioGraph,
        // CircleGraphComponent,
        // GraphComponent
    },
    data() {
        return{
            colors: ['#FFE6EA','#E2E7FB','#E0FAEE','#FAEAAB','#C2F9FF','#FBE1FF'],
            circlewidth: 300,
            circleheight: 270,
            circlevalues: [ 70, 20, 10 ],
            circlenames: ['Mumbai', 'Delhi', 'Other'],
            strokeWidth: 55,
            subtitle1class: 'subtitle1',
            subtitle2class: 'subtitle2',
            captionclass: 'caption',
            black: 'dark',
            plainclass: 'btnplain',
            basicclass: 'btnprimary',
            activetab: 'twitter0',
            maxLimit: 8,
            isHiddenbar: false,
            width: 580,
            height: 295,
            axismin: 0,
            axismax: 500,
            axisStep: 5,
            barMargin: 9,
            names: [ "Followers" ],
            engagementname: [ "Engagement" ],
            labels: [ '23 May', '24 May', '25 May', '26 May', '27 May', '28 May', '29 May', '30 May', ],
            values: [
                [ 200, 130, 100, 200, 180, 210, 60, 150 ]
            ],
            engagementvalues: [
                [ 20000, 18000, 22000, 16000, 8000, 23000, 27000, 22000 ]
            ],
            tabdata: [
                { id: "1", value: "Instagram" },
                { id: "2", value: "Facebook" },
                { id: "3", value: "Twitter" },
                { id: "4", value: "Youtube" },
            ],
            socialInsightsData:[],
            lastSync:null,
        }
    },
    created: function() {
        eventBus.$on("changeActivetab",function(selected) {
            this.activetab = selected;
        }.bind(this));
        this.getsocialinsights();
        this.fetchLatest()
    },
    computed:{
        ...mapGetters("influencerprofile", ["socialInsights"]),
    },
    watch:{
        socialInsights:{
            immediate:true,
            handler(socialInsights){
                if(socialInsights.data){
                    let socialData = socialInsights.data;
                    this.socialInsightsData = socialData;
                    this.lastSync = this.formatDateGlobal(socialInsights.last_update)
                    let tabData = [];
                    for (let i = 0; i < socialData.length; i++) {
                        tabData.push({id:i,key:socialData[i].plateform+i,value:this.capitalizeWord(socialData[i].plateform)})
                    }
                    this.tabdata = tabData;
                }
            }
        }
    },
    methods: {
        ...mapActions("influencerprofile", ["getsocialinsights","fetchsocialinsights"]),
        capitalizeWord(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        fetchLatest(){
            this.fetchsocialinsights()
        },
        
        setgraphvalues() {
            console.log(this.socialInsightsData)
        },

        refresh(){
            this.fetchsocialinsights()
            .then(response => {
            console.log(response);
            this.setgraphvalues()
            this.$toast.open({
                message: response.message,
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
                });
            });
        }
    },
}
</script>