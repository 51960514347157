<template>
  <div>
    <div class="myaccountdropdown">
        <drop-button :clsname="clsname" @onBtnClick="showMyac">
          <img v-if="myacimageurl" :src="require(`../../assets/image/${myacimageurl}`)" class="dropdown-image">
          <img v-else :src="userpic" class="dropdown-image">
            <div class="btntxt"><slot/></div>
        </drop-button>
        <div class="myacdata" v-show="popData">
            <div class="myacdata-inner">
                <ul class="myacdata-ul">
                    <li class="myacdata-li" v-for="acmenu in myacmenu" :key="acmenu.id">
                        <a @click="checkLink">{{acmenu.value}}</a>
                    </li>
                </ul>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import DropButton from "../Button/Button.vue";
export default {
  components: {
    DropButton
  },

  data() {
    return {
      popData: false,
      clsname: 'default'
    };
  },

  props: [
    "myacmenu",
    "acname",
    "myacimageurl",
    "userpic"
  ],

  created() {
    window.addEventListener("click", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },

  methods: {
    checkLink: function (value) {
      this.$emit('linkclicked',value);
      this.popData = false;
    },
    showMyac: function() {
      if(this.popData == false){
        this.popData = true;
      }
      else{
        this.popData = false;
      }
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.popData = false;
      }
    }
  }
};
</script>