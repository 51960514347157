<template>
  <div class="manage manage-category">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">Manage Genres</text-component>
        <div class="page-header-right" v-if="checkPermission('genre_create')">
          <button-component @onBtnClick="createGenre" :clsname="btninvitecls">Create Genre</button-component>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box paddingo margino">
        <div class="box-content">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :actionid="actionid"
              :columns="columns"
              :cboxindeterminatecls="cboxindeterminatecls"
              :actionText="actionText"
              :rows="rows"
              :btnText="btnText"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :dropBtn="dropBtn"
              :dropdownText="dropdownText"
              :statusClear="statusClear"
              :clearClass="clearClass"
              :filter="filter"
              :page="page"
              :per_page="per_page"
              @onrowenter="onEnter"
              @onrowleave="onLeave"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isCreateGenre}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="createGenre"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Create Genre</text-component>
        </div>
        <!-- <fileupload-component :title="title" :imageurl="imageurl" :fileuploadid="fileuploadid"></fileupload-component> -->
        <div class="row margin-top">
          <!-- <input-component
                        class="txtgroup col-12"
                        :classname="txtclassname"
                        v-model="categoryid">
                        Genre ID
          </input-component>-->
          <input-component
            required="true"
            class="txtgroup col-12"
            :helptext="!$v.item.genrename.required && submitted ? validationmessage.genrename: false"
            :labelclass="{'validationerr': !$v.item.genrename.required && submitted}"
            :classname="{'error': !$v.item.genrename.required && submitted, 'default': true}"
            v-model="item.genrename"
          >Genre Name</input-component>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="handleGenre" :clsname="btninvitecls">Create</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isEditGenre}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="editGenre"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Edit Genre</text-component>
        </div>
        <!-- <fileupload-component :title="title" :imageurl="imageurl" :fileuploadid="fileuploadid"></fileupload-component> -->
        <div class="row margin-top">
          <!-- <input-component
                        class="txtgroup col-12"
                        :classname="txtclassname"
                        v-model="categoryid">
                        Genre ID
          </input-component>-->
          <input-component
            required="true"
            class="txtgroup col-12"
            :helptext="!$v.edit.editgenrename.required && editsubmitted ? validationmessage.editgenrename: false"
            :labelclass="{'validationerr': !$v.edit.editgenrename.required && editsubmitted}"
            :classname="{'error': !$v.edit.editgenrename.required && editsubmitted, 'default': true}"
            v-model="edit.editgenrename"
          >Genre Name</input-component>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="EditGenre" :clsname="btninvitecls">Update</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>


<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./actiondelete.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import { required } from "vuelidate/lib/validators";
import EditComponent from "./editgenre.vue";
import { mapGetters, mapActions } from "vuex";
// import usericon from "./profileicon.vue";
// import FileuploadComponent from "../../../BasicComponents/fileupload/fileupload.vue";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    searchBar,
    InputComponent,
    ButtonComponent
    // FileuploadComponent
  },
  validations: {
    item: {
      genrename: { required }
    },
    edit: {
      editgenrename: { required }
    }
  },
  computed: {
    ...mapGetters("admingenre", ["genreData"])
  },
  watch: {
    genreData: {
      immediate: true,
      handler(genreData) {
        this.$data.rows = genreData;
      }
    }
  },
  created() {
    if(!this.checkPermission('genre_delete')){
        this.columns.splice((this.columns.length-1), 1)
    }
    this.$root.$on("getEditData", row => {
      if (this.isEditGenre == true) {
        this.isEditGenre = false;
      } else {
        this.isEditGenre = true;
        this.rowid = row.id;
        let id = row.id;
        this.getsinglegenre({ id: id }).then(response => {
          this.edit.editgenrename = response.data.interest;
        });
      }
    });

    this.getgenres();
  },
  beforeDestroy() {
    this.$root.$off("getEditData", this.fn);
  },
  data() {
    return {
      item: {
        genrename: null
      },
      edit: {
        editgenrename: null
      },
      validationmessage: {
        genrename: "genre name is required",
        editgenrename: "genre name is required"
      },
      submitted: false,
      editsubmitted: false,
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      selectplaceholder: "Select...",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      txtclassname: "default",
      btninvitecls: "btnprimary",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      imageurl: "usericon.png",
      fileuploadid: "genreupload",
      title: "Genre image",
      filter: "",
      selected: "",
      row: "",
      rowid: null,
      category: "",
      h2txtclass: "h2",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      isCreateGenre: false,
      isEditGenre: false,
      sector: "",
      categoryname: "",
      categoryid: "",
      page: 1,
      per_page: 8,
      selectedRows: [],
      columns: [
        // { label: "Genre Picture",headerAlign: "left", align: "left", component: (usericon)},
        {
          label: "Genre ID",
          field: "interest_id",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class width-15",
          sortable: false
        },
        {
          label: "Genre Name",
          field: "interest",
          align: "left",
          headerAlign: "left",
          sortable: false
        },
        {
          label: "",
          headerClass: "width-5",
          component: EditComponent,
          align: "right"
        },
        {
          label: "Action",
          headerAlign: "right",
          headerClass: "width-5",
          align: "right",
          component: ActionBtn
        }
      ],
      rows: [],
      dropCatList: [
        { id: "0", value: "Active" },
        { id: "1", value: "Inactive" }
      ]
    };
  },
  methods: {
    ...mapActions("admingenre", [
      "creategenre",
      "updategenre",
      "getgenres",
      "getsinglegenre"
    ]),

    // setEditable(data) {
    //     // let newarray = [];
    //     data.map(value => {
    //         value.push({
    //             editable: "false"
    //         })
    //     })
    //     console.log(data)
    //     return data;
    // },

    onEnter(row) {
      // console.log(row)
      row.editable = true;
    },
    onLeave(row) {
      // console.log(row)
      row.editable = false;
    },

    editGenre() {
      if (this.isEditGenre == true) {
        this.isEditGenre = false;
      } else {
        this.isEditGenre = true;
      }
    },

    createGenre() {
      if (this.isCreateGenre == false) {
        this.isCreateGenre = true;
      } else {
        this.isCreateGenre = false;
      }
    },

    handleGenre() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.creategenre({
          interest: this.item.genrename
        })
          .then(response => {
            console.log(response);
            vm.getgenres();
            this.isCreateGenre = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    EditGenre() {
      let vm = this;
      this.editsubmitted = true;
      if (this.$v.edit.$invalid) {
        this.$v.edit.$touch();
      } else {
        this.updategenre({
          interest: this.edit.editgenrename,
          id: this.rowid
        })
          .then(response => {
            console.log(response);
            vm.getgenres();
            this.isEditGenre = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    }
  }
};
</script>
