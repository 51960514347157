import axios from "axios";

const AUTH_PARAMS = {
    grant_type: "password",
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
};

function initialState() {
    let categoryData = [];
    let singleCategory = [];
    let allInfluencer = [];
    return {
        categoryData: categoryData,
        singleCategory: singleCategory,
        allInfluencer: allInfluencer
    };
}

const getters = {
    categoryData: (state) => state.categoryData,
    singleCategory: (state) => state.singleCategory,
    allInfluencer: state => state.allInfluencer
};

const actions = {
  clearAll: ({ commit }) => {
    commit("setCategoryData", []);
    commit("setSingleCategoryData", []);
    commit("setAllInfluencer", []);
  },

  getcampaignbasic: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinprocesscampaign: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_ROOT_API + "/campaign-influencer-inprocess/" + id
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getapprovedcampaign: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_ROOT_API + "/campaign-influencer-approved/" + id
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getrejectedcampaign: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_ROOT_API + "/campaign-influencer-rejected/" + id
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getBrandAdminChatDetail: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-chat-detail-brand/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendMessageBrandAdmin: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-chat", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getMessageInfluencerAdmin: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-chat", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendMessageInfluencerAdmin: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-chat", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  updateCampaignStatus: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-status", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
  CompleteCampaign: ({ commit }, data) => {
    let id = data.id;
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-complete/" + id, {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getAllinfluncerlist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-influencer/" + id)
        .then((response) => {
          commit("setAllInfluencer", response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendInvitation: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-invitation", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getVerifiedaccounts: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer-social-account/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getPosttype: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/content-format")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getInprocesslist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer-in-process/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getInprocessAdminChatDetail: ({ commit }, iddata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/influencer-chat-detail", {
          ...iddata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendMessageInprocessAdmin: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-chat", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getApprovedlist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer-accepted/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getRejectedlist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer-rejected/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getSuggestedlist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/suggested-influencer/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getConcentlist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-consent/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getCampaignFinancelist: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-financial-detail/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendFinalAmount: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-final-amount", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  approverejectInightInfluencer: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-link-status", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  approveInightInfluencer: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/campaign-link-approve", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcampaignrating: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-rating/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getbrandlivecampaigns: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/live-campaigns")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcategories: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/categories")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getStatistics: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-single-post-insights/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinfluencercampaignrating: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/single-campaign-rating", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
    setCategoryData: (state, value) => {
        state.categoryData = value;
    },
    setSingleCategoryData: (state, value) => {
        state.singleCategory = value;
    },
    setAllInfluencer: (state, value) => {
        state.allInfluencer = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions,
};
