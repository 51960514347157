
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    return {
    }

}

const getters = {
    
};

const actions = {
    getcampaignname: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-campaigns-list")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getinvoice: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/invoice")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    updateinvoice: ({ state, commit }, invoicedata) => {
        commit('commonfunction/setLoading', true, {root: true})
        let id = invoicedata.get("id");
        // console.log(invoicedata.influencerid)
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/invoice/" + id, invoicedata, { ...invoicedata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    followup: ({ state, commit }, followupdata) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/followup-invoice", { ...followupdata, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};