<template>
	<div class="paymentinvoice agency">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Invoice & Payments</text-component>
                <div class="page-header-right">
                    <button-component v-if="datarows.length > 0 && selectedRows.length > 0" @onBtnClick="sendFollowup" :clsname="dropBtn">Follow Up</button-component>
                    <button-component @onBtnClick="createInvoice" :clsname="btninvitecls">Create Invoice</button-component>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <!-- <div class="box-header headerpadding">
                    
                </div> -->
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="sbarclassname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                                <maincategory-dropdown
                                    class="header-main-category"
                                    v-model="statusDrop"
                                    :clsname="dropCat"
                                    :popoverList="dropCatList">
                                    Status
                                </maincategory-dropdown>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :cboxindeterminatecls="cboxindeterminatecls"
                            :actionText="actionText"
                            :rows="rows"
                            :clsname="clsname"
                            :btnText="btnText"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :dropBtn="dropBtn"
                            :dropdownText="dropdownText"
                            :statusClear="statusClear"
                            :clearClass="clearClass"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                            @onrowclick="navigateDetail">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <form @submit.prevent="handleCreateInvoice">
            <div :class="{'modal-window': true , 'popupopen': isCreateInvoice}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="createInvoice" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <text-component :class="h2txtclass">Create Invoice</text-component>
                    <div class="row">
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.createitem.name.required && createsubmitted ? createvalidationmessage.name: false"
                            :labelclass="{'validationerr': !$v.createitem.name.required && createsubmitted}"
                            :classname="{'error': !$v.createitem.name.required && createsubmitted, 'default': true}"
                            v-model="createitem.name">
                            Agency Name
                        </input-component>
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.createitem.email.required && createsubmitted ? createvalidationmessage.email: false"
                            :labelclass="{'validationerr': !$v.createitem.email.required && createsubmitted}"
                            :classname="{'error': !$v.createitem.email.required && createsubmitted, 'default': true}"
                            v-model="createitem.email">
                            Agency Email Id
                        </input-component>
                        <div class="txtgroup phnumberbox col-12 col-sm-6 col-md-4">
                            <input-component  
                                :type="phnumberboxtype" 
                                required="true"
                                :helptext="!$v.createitem.number.required && createsubmitted ? createvalidationmessage.number: false"
                                :labelclass="{'validationerr': !$v.createitem.number.required && createsubmitted}"
                                :classname="{'error': !$v.createitem.number.required && createsubmitted, 'default': true}"
                                v-model="createitem.number">
                                User Contact Number
                            </input-component>
                            <div class="countrycode">
                                <dropdown-component
                                    v-model="createitem.prefix"
                                    :popoverList="countrylist" 
                                    :btnid="btnid">
                                    <img src="../../../assets/image/iconglobe.svg" class="globeicon">
                                </dropdown-component>
                            </div>
                        </div>
                        <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="campaignnameoption"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.createitem.campaignname.required && createsubmitted ? createvalidationmessage.campaignname: false"
                            :labelclass="{'validationerr': !$v.createitem.campaignname.required && createsubmitted}"
                            :inputclass="{'error': !$v.createitem.campaignname.required && createsubmitted, 'dropdownmenu': true}"
                            v-model="tempcampaign">
                            Campaign Name 
                        </select-component>
                        <date-time-picker
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :inputid="inputid" 
                            required="true"
                            :helptext="!$v.createitem.invoicedate.required && createsubmitted ? createvalidationmessage.invoicedate: false"
                            :labelclass="{'validationerr': !$v.createitem.invoicedate.required && createsubmitted}"
                            :classname="{'error': !$v.createitem.invoicedate.required && createsubmitted, 'default': true}"
                            v-model="createitem.invoicedate">
                            Date of Invoice
                        </date-time-picker>
                        <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="influencerlist"
                            :multiple="true"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.influencername.required && createsubmitted ? createvalidationmessage.influencername: false"
                            :labelclass="{'validationerr': !$v.influencername.required && createsubmitted}"
                            :inputclass="{'error': !$v.influencername.required && createsubmitted, 'dropdownmenu': true}"
                            v-model="influencername"
                            @handlevalue="getfinalamount">
                            Name of Influencer
                        </select-component>
                        <!-- <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="campaignstatusoption"
                            v-model="bankingdetail"
                            :multiple="false"
                            :placeholder="selectplaceholder">
                            Banking Details
                        </select-component> -->
                        <!-- <input-component
                            class="txtgroup amountinputbox col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.createitem.amount.required && createsubmitted ? createvalidationmessage.amount: false"
                            :labelclass="{'validationerr': !$v.createitem.amount.required && createsubmitted}"
                            :classname="{'error': !$v.createitem.amount.required && createsubmitted, 'default': true}"
                            v-model="createitem.amount"
                            :disabledInput = "'disabled'">
                            Amount
                        </input-component> -->
                    </div>
                    <div class="row margino">
                        <div class="col-12 paddingo">
                            <!-- <file-uploader
                                class="col-4"
                                :imageurl="imageurl" 
                                :fileuploadid="fileuploadid"
                                @selectfile="createselectfile($event)"
                                :filename="createfilename">
                            </file-uploader> -->
                        </div>
                    </div>
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component buttontype="submit" :clsname="dropBtn">Save</button-component>
                            <button-component @onBtnClick.prevent="successInvoice" :clsname="btninvitecls">Save & Share</button-component>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- /Modal -->
        <!-- Modal -->
        <form @submit.prevent="handleEditInvoice">
            <div :class="{'modal-window': true , 'popupopen': isEditInvoice}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="editInvoice" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <text-component :class="h2txtclass">Edit Invoice</text-component>
                    <div class="row">
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.edititem.name.required && editsubmitted ? createvalidationmessage.name: false"
                            :labelclass="{'validationerr': !$v.edititem.name.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.name.required && editsubmitted, 'default': true}"
                            v-model="edititem.name">
                            Agency Name
                        </input-component>
                        <input-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.edititem.email.required && editsubmitted ? createvalidationmessage.email: false"
                            :labelclass="{'validationerr': !$v.edititem.email.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.email.required && editsubmitted, 'default': true}"
                            v-model="edititem.email">
                            Agency Email Id
                        </input-component>
                        <div class="txtgroup phnumberbox col-12 col-sm-6 col-md-4">
                            <input-component  
                                :type="phnumberboxtype" 
                                required="true"
                                :helptext="!$v.edititem.number.required && editsubmitted ? createvalidationmessage.number: false"
                                :labelclass="{'validationerr': !$v.edititem.number.required && editsubmitted}"
                                :classname="{'error': !$v.edititem.number.required && editsubmitted, 'default': true}"
                                v-model="edititem.number">
                                User Contact Number
                            </input-component>
                            <div class="countrycode">
                                <dropdown-component
                                    v-model="edititem.prefix"
                                    :popoverList="countrylist" 
                                    :btnid="btnid">
                                    <img src="../../../assets/image/iconglobe.svg" class="globeicon">
                                </dropdown-component>
                            </div>
                        </div>
                        <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="campaignnameoption"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.edititem.campaignname.required && editsubmitted ? createvalidationmessage.campaignname: false"
                            :labelclass="{'validationerr': !$v.edititem.campaignname.required && editsubmitted}"
                            :inputclass="{'error': !$v.edititem.campaignname.required && editsubmitted, 'dropdownmenu': true}"
                            v-model="edittempcampaign">
                            Campaign Name 
                        </select-component>
                        <date-time-picker
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :inputid="inputid" 
                            required="true"
                            :helptext="!$v.edititem.invoicedate.required && editsubmitted ? createvalidationmessage.invoicedate: false"
                            :labelclass="{'validationerr': !$v.edititem.invoicedate.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.invoicedate.required && editsubmitted, 'default': true}"
                            v-model="edititem.invoicedate">
                            Date of Invoice
                            </date-time-picker>
                        <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="influencerlist"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.edititem.influencername.required && editsubmitted ? createvalidationmessage.influencername: false"
                            :labelclass="{'validationerr': !$v.edititem.influencername.required && editsubmitted}"
                            :inputclass="{'error': !$v.edititem.influencername.required && editsubmitted, 'dropdownmenu': true}"
                            v-model="edititem.influencername">
                            Name of Influencer
                        </select-component>
                        <!-- <select-component
                            class="txtgroup col-12 col-sm-6 col-md-4"
                            :options="campaignstatusoption"
                            v-model="bankingdetail"
                            :multiple="false"
                            :placeholder="selectplaceholder">
                            Banking Details
                        </select-component> -->
                        <input-component
                            class="txtgroup amountinputbox col-12 col-sm-6 col-md-4"
                            required="true"
                            :helptext="!$v.edititem.amount.required && editsubmitted ? createvalidationmessage.amount: false"
                            :labelclass="{'validationerr': !$v.edititem.amount.required && editsubmitted}"
                            :classname="{'error': !$v.edititem.amount.required && editsubmitted, 'default': true}"
                            v-model="edititem.amount"
                            :disabledInput = "'disabled'">
                            Amount
                        </input-component>
                    </div>
                    <!-- <div class="row margino">
                        <div class="col-12 paddingo">
                            <file-uploader
                                class="col-4"
                                :imageurl="imageurl" 
                                :fileuploadid="'editfileupload'"
                                @selectfile="editselectfile($event)"
                                :filename="editfilename">
                            </file-uploader>
                        </div>
                    </div> -->
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component buttontype="submit" :clsname="btninvitecls">Save</button-component>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- /Modal -->
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isSuccessInvoice}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="successInvoiceNoSend" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="success-image">
                        <img src="../../../assets/image/success.png" alt="success-image">
                    </div>
                    <div class="success-txt">
                        <text-component :class="h4txtclass">Invoice mail sent successfully!</text-component>
                    </div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
        
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import DropdownComponent from '../../../BasicComponents/Dropdown/dropdownwithtxt';
import SelectComponent from "../../../BasicComponents/select/select.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import DateTimePicker from "../../../BasicComponents/Datetime/datetime.vue";
import MaincategoryDropdown from "../../../BasicComponents/Dropdown/dropdown.vue";
// import FileUploader from "./fileupload.vue";
import SelectRow from "./selectrow";
import SelectAll from "./selectall";
import StatusBadge from "./statusbadge";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import EditComponent from './editpaymentstatus.vue';
import { eventBus } from "../../../main";
import { required, numeric } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    props: {},
    components: {
        DatatableComponent,
        TextComponent,
        searchBar,
        MaincategoryDropdown,
        ButtonComponent,
        InputComponent,
        SelectComponent,
        DropdownComponent,
        DateTimePicker,
        // FileUploader
    },
    validations: {
        createitem: {
            name: { required },
            email: { required },
            prefix: { required },
            number: { required },
            campaignname: { required },
            invoicedate: { required },
            influencername: { required },
            amount: { required },
        },
        edititem: {
            name: { required },
            email: { required },
            prefix: { required },
            number: { required },
            campaignname: { required },
            invoicedate: { required },
            influencername: { required },
            amount: { required },
        },
        influencername: { required }
    },
    computed: {
    ...mapGetters("influencerprofile", ["profileData"])
    },
    watch: {
        profileData: {
            immediate: true,
            handler(profileData) {
                this.ProfileData = profileData;
                this.createitem.email = this.ProfileData.email
                this.createitem.prefix = this.ProfileData.prefix
                this.createitem.number = this.ProfileData.phone
            }
        },
        statusDrop: {
            immediate: true,
            handler(statusDrop) {
                this.filterData();
            }
        },
        'tempcampaign': {
           handler: 'getInfluencer',
        },
        'edittempcampaign': {
           handler: 'getEditInfluencer',
        },

        selected_rows: function() {
            for (var row of this.rows) {
                // console.log(this.rows);
                if (this.selectedRows.indexOf(row) === -1) {
                return;
                }
            }
            console.log(this.selectedRows);

            eventBus.$emit("all-selected");
        }
    },
    data() {
        return {
        createitem: {
            name: null,
            email: null,
            prefix: null,
            number: null,
            campaignname: null,
            invoicedate: null,
            influencername: null,
            amount: null,
        },
        edititem: {
            invoiceid: null,
            name: null,
            email: null,
            prefix: null,
            number: null,
            campaignname: null,
            invoicedate: null,
            influencername: null,
            amount: null,
            influencerid: null
        },
        createvalidationmessage: {
            name: "Agency name is required",
            email: "Agency email id is required",
            prefix: "Country code is required",
            number: "Contact number is required",
            campaignname: "Campaign name is required",
            invoicedate: "Invoice date is required",
            influencername: "Influencer name is required",
            amount: "Amount is required",
        },
        influencername: [],
        statusDrop: null,
        createsubmitted: false,
        editsubmitted: false,
        actionid: "moreactionbtn",
        cboxindeterminatecls: "cboxindeterminate",
        actionText: "More actions",
        clsname: "btnsort",
        btnText: "Sort",
        type: "text",
        selectplaceholder: 'Select...',
        inputPlaceholder: "Search Invoice ID",
        classname: "default",
        sbarclassname: 'default prefixicon',
        dropBtn: "btnbasic",
        dropdownText: "Sub Category",
        statusClear: "Clear",
        clearClass: "btnplain",
        btninvitecls: "btnprimary",
        dropCat: "btnbasic",
        dropCatText: "Main Category",
        statusid: "btnstatus",
        filter: "",
        selected: "",
        row: "",
        h2txtclass: 'h2',
        h4txtclass: 'h4',
        isMobileMenu: false,
        dateclassname: 'default',
        page: 1,
        per_page: 8,
        selectedRows: [],
        followupid: [],
        ProfileData: [],
        phnumberboxtype: "number",
        inputid: 'startdate',
        btnid: 'countrybtn',
        editbtnid: 'editcountrybtn',
        saveshare: false,
        isCreateInvoice: false,
        isEditInvoice: false,
        isSuccessInvoice: false,
        tempcampaign: null,
        edittempcampaign: null,
        campaigndata: [],
        influencerdata: [],
        imageurl: 'doc.svg',
        fileuploadid:'q',
        editfilename: null,
        createfilename: null,
        selectedfile: '',
        columns: [
            { label: "", component: SelectRow, headerComponent: SelectAll },
            {
                label: "Invoice ID",
                field: "invoice_number",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Influencer ID",
                field: "influencer_id",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Campaign Name",
                field: "campaigns.campaign_name",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Date of Invoice",
                // field: "invoice_date",
                representedAs: row => {
                        return this.formatDateGlobal(row.invoice_date);
                    },
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Total Amount",
                field: "amount",
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            { label: "", component: EditComponent, align: "right" },
            {   
                label: "Status",
                component: StatusBadge,
                align: 'left',
                headerAlign: 'left',
                sortable: false
            },
        ],
        rows: [],
        datarows: [],
        dropCatList: [
            { id: "3", value: "All" },
            { id: "0", value: "Created" },
            { id: "1", value: "In Process" },
            { id: "2", value: "Paid" },
        ],
        campaignnameoption: [],
        influencerlist: [],
        countrylist: [
            { id: '0', value: '+91' },
            { id: '1', value: '+92' },
            { id: '2', value: '+93' },
            { id: '3', value: '+94' }
        ],
        };
    },
    methods: {
        ...mapActions("influencerinvoice",["getinvoice", "updateinvoice", "followup"]),
        ...mapActions("agencyinvoice",["getcampaignname", "getinfluencername", "createinvoice", "createmultipleinvoice"]),
        ...mapActions("influencerprofile",["getuserprofile"]),
        ...mapActions("commonfunction",["getinvoiceamount"]),

        createselectfile(e) {
            this.selectedfile = e.target.files[0];
            this.createfilename = e.target.files[0].name;
        },
        editselectfile(e) {
            this.selectedfile = e.target.files[0];
            this.editfilename = e.target.files[0].name;
        },

        getfinalamount() {
            console.log(this.createitem.influencername)
            this.getinvoiceamount({
                id: this.createitem.campaignname,
                influencer_id:this.createitem.influencername
            })
            .then(response=>{
                console.log(response.data)
                this.createitem.amount = response.data.final_price
            });
        },

        handleCreateInvoice(){
            this.createsubmitted = true;
            if (this.$v.createitem.$invalid && this.$v.influencername.$invalid) {
                this.$v.createitem.$touch();
            } else {
                // console.log(this.createitem)
                let invoiceids = ''
                for (let i = 0; i < this.influencername.length; i++) {
                    invoiceids += this.influencerlist[i].id+','
                }
                invoiceids = invoiceids.trim()
                this.createmultipleinvoice({
                    campaign_id: this.createitem.campaignname,
                    email: this.createitem.email,
                    prefix: this.createitem.prefix,
                    phone: this.createitem.number,
                    invoice_date: this.createitem.invoicedate,
                    influencer_id: invoiceids.substring(0, invoiceids.length-1),
                }).then(response => {
                    this.isCreateInvoice = false
                    this.createsubmitted =false
                    this.saveshare = true
                    this.getInvoicedata()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        handleCreateInvoice1(){
            this.createsubmitted = true;
            if (this.$v.createitem.$invalid && this.$v.influencername.$invalid) {
                this.$v.createitem.$touch();
            } else {
                // console.log(this.createitem)
                let invoiceids = ''
                for (let i = 0; i < this.influencername.length; i++) {
                    invoiceids += this.influencerlist[i].id+','
                }
                invoiceids = invoiceids.trim()
                this.createmultipleinvoice({
                    campaign_id: this.createitem.campaignname,
                    email: this.createitem.email,
                    prefix: this.createitem.prefix,
                    phone: this.createitem.number,
                    invoice_date: this.createitem.invoicedate,
                    influencer_id: invoiceids.substring(0, invoiceids.length-1),
                }).then(response => {
                    this.isCreateInvoice = false
                    this.createsubmitted =false
                    this.saveshare = true
                    this.isSuccessInvoice = true
                    this.getInvoicedata()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        handleEditInvoice(){
            console.log("Entered "+this.edititem.campaignname)
            this.editsubmitted = true;
            if (this.$v.edititem.$invalid) {
                this.$v.edititem.$touch();
            } else {

                console.log("edit"+this.edititem.influencername)
                this.updateinvoice({
                    id: this.edititem.invoiceid,
                    email: this.edititem.email,
                    prefix: this.edititem.prefix,
                    phone: this.edititem.number,
                    campaign_id: this.edititem.campaignname,
                    invoice_date: this.edititem.invoicedate,
                    amount: this.edititem.amount,
                    influencer_id: this.edititem.influencername,
                }).then(response => {
                    this.isEditInvoice = false
                    this.editsubmitted =false
                    this.getInvoicedata()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        getInvoicedata() {
            this.getinvoice()
            .then(response=>{
                console.log(response);
                // this.rows = response.data
                this.datarows = response.data
                this.filterData();
                // console.log(this.rows)
            });
        },
        getCampaignData() {
            this.getcampaignname()
            .then(response=>{
                console.log(response);
                this.campaigndata = response.data
                this.campaignnameoption = [];
                for (let i = 0; i < this.campaigndata.length; i++) {
                    this.campaignnameoption.push(
                        {"id": this.campaigndata[i].id, "label": this.campaigndata[i].campaign_name}
                    );
                }
            });
        },
        getInfluencer() {
            this.createitem.influencername= null
            this.influencername = []
            this.createitem.campaignname = this.tempcampaign
            this.influencerlist = [];
            let newcampaign = this.createitem.campaignname
            this.getinfluencername({id:newcampaign})
            .then(response => {
                    console.log(response);
                    this.influencerdata = response.data
                    console.log(this.influencerdata)
                    for (let i = 0; i < this.influencerdata.length; i++) {
                    this.influencerlist.push(
                        {"id": this.influencerdata[i].id, "label": this.influencerdata[i].name}
                    );
                    this.influencername.push(
                        this.influencerdata[i].id
                    );
                }
                })
        },
        getEditInfluencer(row) {
            // alert(this.edittempcampaign)
            this.edititem.campaignname = this.edittempcampaign
            this.influencerlist = [];
            let newcampaign = this.edititem.campaignname
            this.getinfluencername({id:newcampaign})
            .then(response => {
                    console.log(response);
                    this.influencerdata = response.data
                    for (let i = 0; i < this.influencerdata.length; i++) {
                    this.influencerlist.push(
                        {"id": this.influencerdata[i].id, "label": this.influencerdata[i].name}
                    );
                    for (let k = 0; k < this.influencerdata.length; k++) {
                        if(this.edititem.influencername == this.influencerdata[k].id){
                            this.edititem.influencername = this.influencerdata[k].id
                        }
                        else{
                            this.edititem.influencername = null
                        }
                    }
                }
                })
        },
        sendFollowup() {
            for (let j = 0; j < this.selectedRows.length; j++) {
                this.followupid.push(this.selectedRows[j].id)
            }
            console.log(this.followupid)
                this.followup({
                    invoiceIds: this.followupid
                }).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
                this.followupid = []
                this.selectedRows = [];
                let cboxes = document.getElementsByClassName("data-checkbox")
                for (let i = 0; i < cboxes.length; i++) {
                    cboxes[i].checked = false
                }
        },
        // onEnter(row) {
        //     // console.log(row)
        //     row.editable = "true"
        // },
        // onLeave(row) {
        //     // console.log(row)
        //     row.editable = "false"
        // },
        createInvoice() {
            if(this.isCreateInvoice == true){
				this.isCreateInvoice = false
			}
			else{
				this.isCreateInvoice = true	
			}
        },
        successInvoice() {
            this.handleCreateInvoice1()
        },
        successInvoiceNoSend(){
            if(this.isSuccessInvoice == true){
				this.isSuccessInvoice = false
			}
			else{
				this.isSuccessInvoice = true	
			}
        },
        editInvoice() {
            if(this.isEditInvoice == true){
				this.isEditInvoice = false
			}
			else{
				this.isEditInvoice = true	
			}
        },
        getagencyname(){
            let auth = JSON.parse(localStorage.getItem("auth"));
            console.log("created auth:",auth);
            this.createitem.name = auth.name
            this.edititem.name = auth.name
        },

        filterData(){
            let status = this.statusTextToId(this.statusDrop);
            if (status) {
                let filterData = this.datarows.filter(row=>{
                return row.status == status
                });
                this.rows = filterData;
            }else{
                this.rows = this.datarows;
            }
        },

        navigateDetail(row){
            this.$router.push({name: "agencyinvoicedetail",params: { id: row.id }});
        },

        statusTextToId(statusText){
            if (statusText=="Paid") {
                return "2";
            }else if (statusText=="Created") {
                return "0";
            }else if (statusText=="In Process") {
                return "1";
            }else{
                return "";
            }
        }
    },
    created: function() {
        this.getInvoicedata()
        this.getagencyname()
        this.getCampaignData();
        this.getuserprofile();
        var utc = new Date().toJSON().slice(0,10).replace(/-/g,'-');
        this.createitem.invoicedate = utc;
        this.$root.$on('getEditData', (row) => {
            console.log(row)
            this.editInvoice();
            this.edititem.invoiceid = row.id
            this.edititem.influencerid = row.influencer_id
            this.edititem.email = row.email
            this.edititem.prefix = row.prefix
            this.edititem.number = row.phone
            this.edittempcampaign = row.campaign_id
            this.getEditInfluencer(row)
            this.edititem.invoicedate = row.invoice_date.substring(0, 10)
            this.edititem.amount = row.amount
            this.edititem.influencername = row.influencer_id
        })

        // eventBus.$on("onrowclick",function(row) { 
        //     this.redirectFun(row)
        // }.bind(this)
        // );

        // this.$root.$on("navigateDetail", (row) => {
        //     this.redirectFun(row)
        // });

        eventBus.$on(
        "selected",
        function(row) {
            // console.log(row);
            if (this.selectedRows.indexOf(row) === -1) {
            this.selectedRows.push(row);
            }
        }.bind(this)
        );

        eventBus.$on(
        "deselected",
        function(row) {
            if (this.selectedRows.indexOf(row) !== -1) {
            let index = this.selectedRows.indexOf(row);

            this.selectedRows.splice(index, 1);
            }
        }.bind(this)
        );

        eventBus.$on(
        "select-all",
        function(selected) {
            console.log(selected);
            this.selectedRows = this.rows.slice(0);
            // Vue.set(this, "selected_rows", this.rows.slice(0));
        }.bind(this)
        );

        eventBus.$on(
        "deselect-all",
        function(selected) {
            console.log(selected);
            this.selectedRows = [];
            // Vue.set(this, "selected_rows", []);
        }.bind(this)
        );

    },
    beforeDestroy() {
        this.$root.$off("navigateDetail", this.fn);
    }
};
</script>
