<template>
    <div class="rating-page">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Rating</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino paddingo">
                <div class="box-header">
                    <text-component class="subtitle1">Influencers</text-component>
                </div>
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isRateInfluencer}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close"><img @click="rateInfluencer" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                <div class="header">
                    <text-component :class="h2txtclass">Rate Influencer</text-component>
                </div>
                <form @submit.prevent="handleRating">
                    <div class="row content-align">
                        <rating-input class="col-12" v-model="item.rating"
                            required="true"
                            :helptext="!$v.item.rating.required && submitted ? validationmessage.rating: false"
                            :labelclass="{'validationerr': !$v.item.rating.required && submitted}"
                            :classname="{'error': !$v.item.rating.required && submitted, 'default': true}"
                        ></rating-input>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Feedback</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.feedback"
                                    required="true"
                                    :helptext="!$v.item.feedback.required && submitted ? validationmessage.feedback: false"
                                    :labelclass="{'validationerr': !$v.item.feedback.required && submitted}"
                                    :classname="{'error': !$v.item.feedback.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Comment</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.comment"
                                    required="true"
                                    :helptext="!$v.item.comment.required && submitted ? validationmessage.comment: false"
                                    :labelclass="{'validationerr': !$v.item.comment.required && submitted}"
                                    :classname="{'error': !$v.item.comment.required && submitted, 'default': true}"   
                                ></text-area-comp>
                            </div>
                        </div>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Add Notes</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.note"
                                    required="true"
                                    :helptext="!$v.item.note.required && submitted ? validationmessage.note: false"
                                    :labelclass="{'validationerr': !$v.item.note.required && submitted}"
                                    :classname="{'error': !$v.item.note.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                    </div>
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component buttontype="submit" :clsname="btnprimary">Submit</button-component>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    <!-- /Modal -->
    </div>
</template>
<script>
import RatingInput from "../../../BasicComponents/rating/ratinginput.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue"
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue"
import ActionBtn from "./actionbtn.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import UserIcon from "./profileicon.vue";
import { required } from "vuelidate/lib/validators";
import Rating from "./tablerating.vue";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        DatatableComponent,
        searchBar,
        ButtonComponent,
        RatingInput,
        TextAreaComp
    },
    validations: {
        item: {
            influencer_id: { required },
            rating: { required },
            feedback: { required },
            comment: { required },
            note: { required }
        }
    },
    data() {
        return{
            submitted:false,
            item:{
                influencer_id:null,
                rating:null,
                feedback:null,
                comment:null,
                note:null
            },
            validationmessage: {
                influencer_id: "Influencer is required",
                rating: "Rating is required",
                feedback: "Feedback is required",
                comment: "Comment is required",
                note: "Note is required"
            },
            isRateInfluencer: false,
            h2txtclass: 'h2',
            btnprimary: 'btnprimary',
            type: "text",
            inputPlaceholder: "Search Influencer",
            classname: "default prefixicon",
            txtclassname: "default",
            filter: "",
            selected: "",
            row: "",
            email: '',
            page: 1,
            per_page: 8,
            selectedRows: [],
            columns: [
                { label: "Profile Photo", component: UserIcon,align: "left",headerAlign: "left",},
                {
                    label: "Influencer’s Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                // {
                //     label: "Campaign Name",
                //     field: "campaignname",
                //     align: "left",
                //     headerAlign: "left",
                //     sortable: false
                // },
                { label: "Rating",headerAlign: "left", align: "left", component: (Rating)},
                {
                    label: "No. of Rating",
                    // field: "ratings.number_of_rating",
                    representedAs: function (row) {
                        return (row.ratings)?row.ratings.number_of_rating:'0';
                    },
                    align: "center",
                    headerAlign: "center",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "No. of Campaign",
                    // field: "ratings.number_of_campaign",
                    representedAs: function (row) {
                        return (row.ratings)?row.influencer_campaign_count:'0';
                    },
                    align: "center",
                    headerAlign: "center",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            rows: [],
        }
    },
    computed:{
        ...mapGetters("adminrating",["ratingData"]),
    },
    watch: {
        ratingData:{
            immediate:true,
            handler(ratingData){
                this.rows = ratingData;
            }
        }
    },
    methods: {
        ...mapActions("adminrating",["createrating","getratings"]),
        rateInfluencer() {
            if(this.isRateInfluencer == true){
                this.isRateInfluencer = false
            }
            else{
                this.isRateInfluencer = true	
            }
        },
        reserRatingForm(){
            this.item.rating = null
            this.item.feedback = null
            this.item.comment = null
            this.item.note = null
            this.submitted = false;
        },
        handleRating(){
            console.log('asdsad')
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.createrating(this.item).then(response => {
                    this.isRateInfluencer = false;
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        }
    },
    created: function() {
        if(!this.checkPermission('rating_action') && !this.checkPermission('rating_detail')){
            this.columns.splice((this.columns.length-1), 1)
        }
        this.getratings();
        this.$root.$on('onClick', (row) => {
            this.reserRatingForm();
            this.item.influencer_id = row.id;
            this.isRateInfluencer = true
            // if(this.isRateInfluencer == true){
            //     this.isRateInfluencer = false
            // }
            // else{
            //     this.isRateInfluencer = true	
            // }
        })
    }
}
</script>