<template>
  <div class="actionbtn">
    <button-component
      clsname="btnbasic"
      v-if="interest == '0' || interest == null"
      @onBtnClick="sendinterest(row)"
    >Send Consent</button-component>
    <button-component clsname="btnprimary" v-else @onBtnClick="unsendinterest(row)">Withdraw</button-component>
    <button-component clsname="btnplain" @onBtnClick="detail(row)">Details</button-component>
  </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  watch: {
    row:{
      immediate:true,
      handler(row){
        this.interest = row.interested 
      }
    }
  },
  data() {
    return {
      interest: ""
    };
  },

  methods: {
    ...mapActions("influencerconsent", ["setinterest", "getconsentlist"]),
    detail(row) {
      this.$router.push({ name: "influencercampaigndetailonly", params:{id: row.id}});
    },

    sendinterest(row) {
      let vm = this;
      this.setinterest({
        campaign_id: row.id,
        interested: "1"
      })
        .then(response => {
          vm.getconsentlist();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },

    unsendinterest(row) {
      let vm = this;
      this.setinterest({
        campaign_id: row.id,
        interested: "0"
      })
        .then(response => {
          vm.getconsentlist();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    }
  }
};
</script>
