<template>
    <img class="article-edit-img"  @click="navigatePage(row)" src="../../../../assets/image/edit.svg" alt="edit-icon">
</template>
<script>
export default {
    props: ['row'],
    methods: {
        navigatePage(row) {
            // console.log(row.id)
            // this.$router.push({ name: "publishercontentpublishedit", params:{id: row.id}});
            this.$root.$emit('getEditData', row)
        }
    }
}
</script>