<template>
  <div class="influ-name" style="cursor:pointer" @click="opencategory(row.id)">
    {{row.name}}
  </div>
</template>
<script>
// import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
// import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnprimary: "btnprimary",
      btndestructive: "btndestructive",
      isPopupOpen: false,
      h2txtclass: 'h2',
    };
  },
  methods: {
    opencategory(row) {
        this.$root.$emit('openCategoryshow', row)
    }
  }
};
</script>