<template>
    <div class="chat">
        <!-- <div class="content-header">
            <div class="page-header-right">
                <button-component :clsname="btnprimary">View Detail</button-component>
            </div>
        </div> -->
        <div class="content">
            <button-component v-if="checkPermission('support_create')" @onBtnClick="togglePopup" class="send-msg-btn" :clsname="btnprimary">Send Messege</button-component>
            <div class="box paddingo margino">
                <div class="box-content">
                    <header-tabs
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Influencer'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="columns"
                                    :rows="InfluencerRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div> 
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Brands'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="brandcolumns"
                                    :rows="BrandRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Publisher'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="publichercolumns"
                                    :rows="PublisherRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Agency'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="agencycolumns"
                                    :rows="AgencyRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Closed Ticket'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                        <search-bar
                                            class="header-searchbar"
                                            :classname="classname"
                                            :type="type"
                                            :inputPlaceholder="inputPlaceholder"
                                            v-model="filter">
                                        </search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :actionid="actionid"
                                    :columns="closedchatcolumns"
                                    :rows="ClosedChatRows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page">
                                </datatable-component>
                            </div>
                        </div>
                    </header-tabs>
                </div>
            </div>
            <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isChat}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="togglePopup" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="header">
                        <text-component :class="h2txtclass">Send Messege</text-component>
                    </div>
                    <form @submit.prevent="handleMessage">
                        <div class="row margin-top">
                            <select-component
                                class="txtgroup col-12"
                                :options="role_option"
                                v-model="newMessageRole"
                                :multiple="false"
                                :placeholder="selectplaceholder">
                                Select Role
                            </select-component>
                        </div>
                        <div class="row">
                            <select-component
                                class="txtgroup col-12"
                                :options="tooption"
                                v-model="item.receiver_id"
                                :multiple="false"
                                :helptext="!$v.item.receiver_id.required && submitted ? validationmessage.receiver_id: false"
                                :labelclass="{'validationerr': !$v.item.receiver_id.required && submitted}"
                                :inputclass="{'error': !$v.item.receiver_id.required && submitted, 'dropdownmenu': true}"
                                :placeholder="selectplaceholder">
                                To*
                            </select-component>
                            <div class="col-12">
                                <input-component
                                    required="true"
                                    :helptext="!$v.item.subject.required && submitted ? validationmessage.subject: false"
                                    :labelclass="{'validationerr': !$v.item.subject.required && submitted}"
                                    :classname="{'error': !$v.item.subject.required && submitted, 'default': true}"
                                    v-model="item.subject"
                                >Subject</input-component>
                            </div>
                            <div class="col-12 margin-top">
                                <div class="inputtxt">
                                    <text-component>Messege*</text-component>
                                </div>
                                <text-area-comp v-model="item.message"
                                    required="true"
                                    :helptext="!$v.item.message.required && submitted ? validationmessage.message: false"
                                    :labelclass="{'validationerr': !$v.item.message.required && submitted}"
                                    :classname="{'error': !$v.item.message.required && submitted, 'default': true}"
                                ></text-area-comp>
                                <!-- <textarea v-model="item.message"></textarea> -->
                            </div>
                        </div>
                        <hr class="hrline"/>
                        <div class="modal-footer">
                            <div>
                                <button-component buttontype="submit" :clsname="btnprimary">Send</button-component>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- /Modal -->
        </div>
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import usericon from "./profileicon.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import ActionBtn from "./action.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import RadioComponent from "../../../BasicComponents/Radio Button/defaultRadio.vue"
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { eventBus } from "../../../main";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";

export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        SelectComponent,
        ButtonComponent,
        TextComponent,
        TextAreaComp,
        InputComponent
        // RadioComponent
    },
    validations: {
        item: {
            receiver_id: { required },
            message: { required },
            subject: { required }
        }
    },
    computed: {
        ...mapGetters("adminchattaction", ["InfluencerChatData","AgencyChatData","BrandChatData","PublisherChatData","ClosedChatData"]),
    },
    data() {
        return {
            submitted:false,
            item:{
                receiver_id:null,
                message:null,
                subject:null
            },
            validationmessage: {
                receiver_id: "Receiver is required",
                message: "Message is required",
                subject: "Subject is required",
            },
            newMessageRole:null,
            activetab: "Influencer",
            maxLimit: 9,
            h2txtclass: 'h2',
            isHiddenbar: false,
            isChat: false,
            btnprimary: 'btnprimary',
            subtitle2class: 'subtitle2',
            midclass: 'mid',
            txtclassname: 'default',
            actionid: "moreactionbtn",
            classname: "default prefixicon",
            page: 1,
            per_page: 6,
            type: "text",
            inputPlaceholder: "Search Influencer or Subject",
            filter: "",
            to: '',
            selectedRows: [],
            tovalue: '',
            selectplaceholder: 'Select...',
            tooption: [],
            role_option: [
                { id: "2", label: "Influencer" },
                { id: "3", label: "Agency" },
                { id: "4", label: "Brands" },
                { id: "5", label: "Publisher" },
            ],

            columns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Influencer's Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            agencycolumns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Agency Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            publichercolumns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Publisher Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            brandcolumns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Brand Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            closedchatcolumns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Subject",
                    field: "subject",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Created At",
                    representedAs: row => {
                        return this.formatDateGlobal(row.created_at);
                    },
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            rows: [],
            InfluencerRows:[],
            AgencyRows:[],
            BrandRows:[],
            PublisherRows:[],
            ClosedChatRows:[],
            tabdata: [
                { id: "2", value: "Influencer" },
                { id: "3", value: "Agency" },
                { id: "4", value: "Brands" },
                { id: "5", value: "Publisher" },
                { id: "5", value: "Closed Ticket" },
            ],
        };
    },
    created: function() {
        if(!this.checkPermission('support_detail')){
            this.columns.splice((this.columns.length-1), 1)
            this.agencycolumns.splice((this.agencycolumns.length-1), 1)
            this.publichercolumns.splice((this.publichercolumns.length-1), 1)
            this.brandcolumns.splice((this.brandcolumns.length-1), 1)
            this.closedchatcolumns.splice((this.closedchatcolumns.length-1), 1)
        }
        this.$root.$on('onClick', () => {
            if(this.isChat == false){
                this.isChat = true
            }
            else{
                this.isChat = false
            }
        })
        this.getInfluencerChatData();
        this.getAgencyChatData();
        this.getBrandChatData();
        this.getPublisherChatData();
        this.getClosedChatData();

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );
    },
    watch:{
        newMessageRole:{
            immediate:false,
            handler(newMessageRole){
                this.setReceiverOption();
            }
        },
        InfluencerChatData:{
            immediate:true,
            handler(InfluencerChatData){
                this.InfluencerRows = InfluencerChatData;
            }
        },
        AgencyChatData:{
            immediate:true,
            handler(AgencyChatData){
                this.AgencyRows = AgencyChatData;
            }
        },
        BrandChatData:{
            immediate:true,
            handler(BrandChatData){
                this.BrandRows = BrandChatData;
            }
        },
        PublisherChatData:{
            immediate:true,
            handler(PublisherChatData){
                this.PublisherRows = PublisherChatData;
            }
        },
        ClosedChatData:{
            immediate:true,
            handler(ClosedChatData){
                console.log("ClosedChatDatas",ClosedChatData)
                this.ClosedChatRows = ClosedChatData;
            }
        },
    },
    methods: {
        ...mapActions("adminchattaction", [
            "getInfluencerChatData",
            "getAgencyChatData",
            "getBrandChatData",
            "getPublisherChatData",
            "getClosedChatData",
            "createMessage",
            "getUserListByRole",
        ]),
        togglePopup() {
            this.isChat = !this.isChat
            this.submitted = false
            this.item = {receiver_id:null,message:null,subject:null}
            this.newMessageRole = null;
        },
        
        getChangedValue(val) {
            this.newMessageRole = val;
        },
        
        setReceiverOption() {
            let role = this.newMessageRole;
            this.getUserListByRole({id:role}).then(response=>{
                let data = response.data;
                let dataArray = [];
                if(data.length){
                    for (let i = 0; i < data.length; i++) {
                        dataArray.push({id:data[i].id,label:data[i].name})
                    }
                }
                this.tooption = dataArray;
                this.item.receiver_id = null
            })
        },

        handleMessage(){
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.createMessage(this.item).then(response => {
                    // this.isChat = false;
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                    this.submitted = false;
                    this.afterSubmit();
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        afterSubmit(){
            let roleId = this.newMessageRole;
            if(roleId==2){
                this.getInfluencerChatData();
            }else if (roleId==3) {
                this.getAgencyChatData();
            }else if (roleId==4) {
                this.getBrandChatData();
            }else if (roleId==5) {
                this.getPublisherChatData();
            }
            this.togglePopup();
        }
    },
  
};
</script>
