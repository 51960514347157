<template>
  <div @mouseover="inputmouseover">
    <label-component class="inputtxt">
      <slot /><sup v-if="required">*</sup>
    </label-component>
    <input-component
      :inputPlaceholder="inputPlaceholder"
      v-model="inputGroup"
      :dropdownPosition="dropdownPosition"
      :options="options"
      :classname="classname"
      :type="type"
      :maxlength="maxlength"
      :disabledInput="disabledInput"
      @searchItem="keypushed"
      @change="changefunction()"
      @keypress="keypress()"
    ></input-component>
    <div v-if="helptext" class="error-helper-msg">
      <label :class="labelclass">{{helptext}}</label>
    </div>
  </div>
</template>

<script>
import LabelComponent from "../Label/Label.vue";
import InputComponent from "../Input/Input";

export default {
  props: [
    "required",
    "classname",
    "labelclass",
    "helptext",
    "options",
    "dropdownPosition",
    "inputPlaceholder",
    "type",
    "value",
    "maxlength",
    "disabledInput"
  ],
  data() {
    return {
      // inputGroup: ""
    };
  },
  components: {
    LabelComponent,
    InputComponent
  },
  computed: {
    inputGroup: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  // watch: {
  //   inputGroup() {
  //     this.$emit("input", this.inputGroup);
  //   }
  // },
  methods: {
    changefunction() {
      this.$emit('change')
      // console.log("emit:")
    },
    keypress() {
      this.$emit('keypress')
      // console.log("emit:")
    },

    keypushed() {
      this.$emit('keypushed')
      // console.log("emit:")
    },

    inputmouseover(value) {
      this.$emit("mouseover", value);
    }
  }
};
</script>