
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let percentData = [];
    let platformData = [];
    let countryData = [];
    let cityData = [];
    let languageData = [];
    let notificationData = [];
    let allNotification = [];
    return {
      currentUser: {
        id: null,
        name: null,
        email: null,
        mobile: null,
        password: null,
      },
      loading: false,
      showLoader: false,
      percentData: percentData,
      platformData: platformData,
      countryData: countryData,
      cityData: cityData,
      languageData: languageData,
      // editProfileNumberChange: false,
      notificationData: notificationData,
      allNotification: allNotification,
    };

}

const getters = {
    currentUser: state => state.currentUser,
    loading: state => state.loading,
    showLoader: state => state.showLoader,
    percentData: state => state.percentData,
    platformData: state => state.platformData,
    countryData: state => state.countryData,
    cityData: state => state.cityData,
    languageData: state => state.languageData,
    notificationData: state => state.notificationData,
    // editProfileNumberChange: state => state.editProfileNumberChange,
    allNotification: state => state.allNotification
};

const actions = {
  clearAll: ({ commit }) => {
    commit("setNotificationData", []);
    commit("setAllNotificationData", []);
  },

  fetchData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/me")
        .then((response) => {
          console.log(response);
          if (typeof response !== "undefined" && response.data) {
            console.log("setting current user", response.data.data);
            commit("setCurrentUser", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          console.log("user.js:106 my error");
          console.log(error);

          commit("setIsAuthenticated", false);
          commit("setCurrentUser", null);
          commit("setCurrentCoa", null);

          window.localStorage.setItem("auth", null);
          reject(error);
        });
    });
  },

  getuserpercentage: ({ commit }) => {
    // commit('setLoading', true)
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-completion-percentage")
        .then((response) => {
          // commit('setLoading', false)
          commit("setPercentageData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // commit('setLoading', false)
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getplatform: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/social-platform")
        .then((response) => {
          commit("setPlatformData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcountry: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/country")
        .then((response) => {
          commit("setCountryData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcity: ({ commit }, countryid) => {
    let id = countryid.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + `/city/${id}`)
        .then((response) => {
          commit("setCityData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getlanguage: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/language")
        .then((response) => {
          commit("setLanguageData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getlatestnotifications: ({ commit }) => {
    if (
      localStorage.getItem("auth") != null &&
      localStorage.getItem("auth").length > 5
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_API + "/latest-notification")
          .then((response) => {
            commit("setNotificationData", response.data.data);
            console.log(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.code === 401) {
              return reject(error.response.message);
            }
            reject(error);
          });
      });
    }
  },

  getallnotifications: ({ commit }) => {
    if (
      localStorage.getItem("auth") != null &&
      localStorage.getItem("auth").length > 5
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_API + "/notification")
          .then((response) => {
            commit("setAllNotificationData", response.data.data);
            console.log(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.code === 401) {
              return reject(error.response.message);
            }
            reject(error);
          });
      });
    }
  },

  readallnotifications: ({ commit }, readstatus) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/change-notification-status", {
          ...readstatus,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinvoiceamount: ({ commit }, invoicedata) => {
    let id = invoicedata.id;
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_ROOT_API + "/influencer-campaign-detail/" + id,
          { ...invoicedata, ...AUTH_PARAMS }
        )
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  notificationstatus: ({ commit }, notificationstatusdata) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/notification-status", {
          ...notificationstatusdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  // editprofilenumberchange: ({ commit }, status) => {
  //   commit("setEditProfileNumberChange", status);
  // },

  // updateShowLoader:
  updateShowLoader: ({ commit }, data) => {
    commit("setShowLoader", data);
  },
};

const mutations = {
  setCurrentUser: (state, value) => {
    state.currentUser = value;
  },
  setLoading: (state, value) => {
    console.log("value", state);
    state.loading = value;
  },
  setShowLoader: (state, value) => {
    state.showLoader = value;
  },
  setCurrentCoa: (state, value) => {
    state.currentCOA = value;
  },
  setPercentageData: (state, value) => {
    state.percentData = value;
  },
  setPlatformData: (state, value) => {
    state.platformData = value;
  },
  setCountryData: (state, value) => {
    state.countryData = value;
  },
  setCityData: (state, value) => {
    state.cityData = value;
  },
  setLanguageData: (state, value) => {
    state.languageData = value;
  },
  setNotificationData: (state, value) => {
    state.notificationData = value;
  },
  setAllNotificationData: (state, value) => {
    state.allNotification = value;
  },
  // setEditProfileNumberChange: (state, value) => {
  //     state.editProfileNumberChange = value;
  // }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};