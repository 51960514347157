<template>
  <div class="usersuccess">
    <div class="content-header">
      <div class="row">
        <div class="col-12">
          <router-link class="remove-underline" :to="{name: 'User'}"><div class="back-path mid">Users</div></router-link>
        </div>
        <div class="col-12">
          <text-component :class="headertxtclass">New User</text-component>
        </div>
      </div>
    </div>

    <div class="content">
        <div class="box">
            <div class="align-center"><text-component :class="successclass">Success!</text-component></div>
            <p class="dark align-center">You have invited {{user.name}} as {{user.role.role_name}}.</p>
            <p class="dark align-center">Create password link has been sent on registered email ID. Link will be valid for 24 hours.</p>
        </div>
    </div>
  </div>
</template>

<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TextComponent
  },
  computed: {
    ...mapGetters("user", ["singleData"])
  },
  created() {
    // console.log(this.$route.params.id);
    this.singledata();
  },
  methods: {
    ...mapActions("user", ["singleuserData"]),

    singledata() {
      this.singleuserData({
        id: this.$route.params.id
      }).then(response => {
        console.log(response);
        console.log("checking");
      });
    },
  },
  watch: {
    singleData: {
      immediate: true,
      handler(singleData) {
        this.$data.user = singleData;
      }
    }
  },
  data() {
    return {
      user: "",
      headertxtclass: "h2",
      successclass: "subtitle1 dark"
    };
  }
};
</script>