<template>
    <div class="vidunit-frontpage">
        <section class="my-grid">
            <div class="vidunit-frontpage-header">
                <header-bar></header-bar>
            </div>
            <div class="content-wrapper">
                <router-view></router-view>
            </div>
            <div class="vidunit-frontpage-footer">
                <footer-bar></footer-bar>
            </div>
        </section>
    </div>
</template>
<script>
import HeaderBar from "./header.vue";
import FooterBar from "./footer.vue";
export default {
    components: {
        HeaderBar,
        FooterBar
    },
}
</script>