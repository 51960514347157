<template>
    <div class="suggested-influencer-section">
        <div class="datatable-main">
            <div class="data-table-header">
                <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter">
                    </search-bar>
                </div>
            </div>
            <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :columns="columns"
                :rows="rows"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :filter="filter"
                :page="page"
                :per_page="per_page">
            </datatable-component>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isInvite}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="sendInvite" src="../../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="header">
                        <text-component :class="h2txtclass">Request For Campaign</text-component>
                    </div>
                    <div class="row message-desc">
                        <input-component
                            class="txtgroup col-12"
                            :classname="inputclass">
                            Influencer Budget
                        </input-component>
                        <select-component
                            class="txtgroup col-12"
                            :options="accountoption"
                            v-model="accountvalue"
                            :multiple="false"
                            :placeholder="selectplaceholder">
                            Accounts
                        </select-component>
                        <select-component
                            class="txtgroup col-12"
                            :options="typeoption"
                            v-model="typevalue"
                            :multiple="false"
                            :placeholder="selectplaceholder">
                            Type
                        </select-component>
                    </div>
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component @onBtnClick="sendInvite" :clsname="btnprimary">Send Invite</button-component>
                        </div>
                    </div>
                </div>
            </div>
        <!-- /Modal --> 
    </div>
</template>
<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import Action from "./suggestinflueaction.vue";
import usericon from "./suggestinflueicon.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import SelectComponent from "../../../../BasicComponents/select/select.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
export default {
    components: {
        DatatableComponent,
        searchBar,
        TextComponent,
        SelectComponent,
        InputComponent,
        ButtonComponent
    },
    data() {
        return {
            selectedRows: [],
            inputPlaceholder: 'Search Influencer',
            type: "text",
            classname: "default prefixicon",
            filter: "",
            h2txtclass: 'h2',
            inputclass: 'default',
            financefilter: '',
            page: 1,
            per_page: 8,
            accountvalue: '',
            typevalue: '',
            isInvite: false,
            selectplaceholder: 'Select...',
            btnprimary: 'btnprimary',
            columns: [
                { label: "Profile Photo",headerAlign: "left", align: "left", component: (usericon)},
                {
                    label: "Influencer's Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Email",
                    field: "email",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (Action)},
            ],
            rows: [
                {
                    name: 'Lungelo Ngcaba',
                    email: 'lungelongcaba@gmail.com'
                },
                {
                    name: 'Sammy Lawson',
                    email: 'sammylawson@gmail.com'
                },
            ],
            accountoption: [
                { id: "0", label: "Instagram" },
                { id: "1", label: "Facebook" },
                { id: "2", label: "Youtube" },
                { id: "3", label: "Twitter" },
            ],
            typeoption: [
                { id: "0", label: "Video" },
                { id: "1", label: "Post" },
            ],
        }
    },
    methods: {
        sendInvite() {
            if(this.isInvite == true){
                this.isInvite = false
            }
            else{
                this.isInvite = true	
            }
        }
    },
    created: function() {
        this.$root.$on('onClick', (row) => {
            this.sendInvite()
        })
    }
}
</script>