<template>
  <div class="personaldetail agency">
    <form @submit.prevent="submitProfile">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Personal Details</text-component>
            <div class="myprofile-header-right">
              <button-component
                @onBtnClick="changePasswordToggle"
                :clsname="plainclass"
              >Change Password</button-component>
              <button-component
                v-if="!IsPersonalDetailEditable"
                @onBtnClick="personalDetailToggle"
                :clsname="basicclass"
              >Edit</button-component>
            </div>
          </div>
          <div v-if="!IsPersonalDetailEditable" class="personaldetail-saved">
            <div class="row margino">
              <img
                v-if="request.profile_pic"
                :src="request.base_url + '/' + request.profile_pic"
                class="user-img"
                alt="user-image"
              />
              <img
                v-else
                src="../../../assets/image/usericon.png"
                class="user-img"
                alt="user-image"
              />
            </div>
            <div class="row margino">
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Brand Name</text-component>
                  <text-component class="p-value dark">{{request.full_name}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Industry</text-component>
                  <text-component class="p-value dark">{{request.industry}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Agency Registration Number</text-component>
                  <text-component class="p-value dark">{{request.agency_reg_num}}</text-component>
                </div>
              </div>
              <!-- <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Email Address</text-component>
                  <text-component class="p-value dark">{{request.email}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Phone Number</text-component>
                  <text-component class="p-value dark">{{request.prefix}} {{request.phone}}</text-component>
                </div>
              </div> -->
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">GST Number</text-component>
                  <text-component class="p-value dark">{{request.gst_number}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">City</text-component>
                  <text-component class="p-value dark">{{getcityname(request.city)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Country</text-component>
                  <text-component class="p-value dark">{{getcountryname(request.country)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component class="p-title" :class="subtitle2class">Address</text-component>
                  <text-component class="p-value dark">{{request.address}}</text-component>
                </div>
              </div>
            </div>
            <div class="row margino"></div>
          </div>
          <div v-if="IsPersonalDetailEditable" class="personaldetail-editable">
            <div class="row margino">
              <profile-uploader
                :imageurl="imageurl"
                :fileuploadid="fileuploadid"
                @selectfile="getfiledata"
                :avatar="avatar"
              ></profile-uploader>
            </div>
            <div class="row edit-detail-upper">
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.fullname"
                :helptext="!$v.item.fullname.required && submitted ? validationmessage.fullname: false"
                :labelclass="{'validationerr': !$v.item.fullname.required && submitted}"
                :classname="{'error': !$v.item.fullname.required && submitted, 'default': true}"
              >Brand Name</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.regnumber"
                :helptext="!$v.item.regnumber.required && submitted ? validationmessage.regnumber: false"
                :labelclass="{'validationerr': !$v.item.regnumber.required && submitted}"
                :classname="{'error': !$v.item.regnumber.required && submitted, 'default': true}"
              >Agency Registration Number</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.industry"
                :helptext="!$v.item.industry.required && submitted ? validationmessage.industry: false"
                :labelclass="{'validationerr': !$v.item.industry.required && submitted}"
                :classname="{'error': !$v.item.industry.required && submitted, 'default': true}"
              >Industry</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                disabledInput="true"
                v-model="item.email"
                :helptext="!$v.item.email.required && submitted ? validationmessage.email: false"
                :labelclass="{'validationerr': !$v.item.email.required && submitted}"
                :classname="{'error': !$v.item.email.required && submitted, 'default': true}"
              >Email Address</input-component>
              <div class="txtgroup phnumberbox col-12 col-sm-6 col-md-4">
                <input-component
                  required="true"
                  v-model="item.mobile_number"
                  :type="phnumberboxtype"
                  :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.mobile_number: false"
                  :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted}"
                  :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
                >Contact number</input-component>
                <div class="countrycode">
                  <dropdown-component
                    v-model="item.mobile_prefix"
                    :popoverList="countrylist"
                    :btnid="btnid"
                  >
                    <img src="../../../assets/image/globe.svg" class="globeicon" />
                  </dropdown-component>
                </div>
              </div>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.gst"
                :helptext="!$v.item.gst.required && submitted ? validationmessage.gst: false"
                :labelclass="{'validationerr': !$v.item.gst.required && submitted}"
                :classname="{'error': !$v.item.gst.required && submitted, 'default': true}"
              >GST</input-component>
              
              <select-component
                label="name"
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                :options="country"
                :helptext="!$v.item.country.required && submitted ? validationmessage.country: false"
                :labelclass="{'validationerr': !$v.item.country.required && submitted}"
                :inputclass="{'error': !$v.item.country.required && submitted, 'dropdownmenu': true}"
                v-model="item.country"
                @handlevalue="sendCountryid(item.country)"
                :placeholder="selectplaceholder"
                :multiple="false"
              >Country</select-component>
              <select-component
                label="name"
                class="txtgroup col-12 col-sm-6 col-md-4"
                :options="city"
                required="true"
                :helptext="!$v.item.city.required && submitted ? validationmessage.city: false"
                :labelclass="{'validationerr': !$v.item.city.required && submitted}"
                :inputclass="{'error': !$v.item.city.required && submitted, 'dropdownmenu': true}"
                v-model="item.city"
                :placeholder="selectplaceholder"
                :multiple="false"
              >City</select-component>
              <div class="txtgroup col-12 col-sm-6 col-md-4">
                <text-component class="inputtxt">
                  Address
                  <sup>*</sup>
                </text-component>
                <textarea
                  v-model="item.address"
                  :class="{'error': !$v.item.address.required && submitted, 'txtarea default': true}"
                ></textarea>
                <span
                  v-if="!$v.item.address.required && submitted"
                  class="validationerr"
                >{{validationmessage.address}}</span>
              </div>
            </div>
            <hr class="hrline-footer" />
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";
import ProfileUploader from "./fileupload.vue";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent,
    DropdownComponent,
    ProfileUploader
  },
  computed: {
    ...mapGetters("brandprofile", ["profileData"]),
    ...mapGetters("commonfunction", ["countryData", "cityData"])
  },
  watch: {
    profileData: {
      immediate: true,
      handler(profileData) {
        this.$data.request = profileData;
        this.item.fullname = this.request.full_name;
        this.item.email = this.request.email;
        this.item.mobile_number = this.request.phone;
        this.item.mobile_prefix = this.request.prefix;
        this.item.country = this.request.country;
        this.sendCountryid(profileData.country);
        this.item.city = this.request.city;
        this.item.regnumber = this.request.agency_reg_num;
        this.item.address = this.request.address;
        this.item.industry = this.request.industry;
        this.item.gst = this.request.gst_number;
        if (profileData.profile_pic != null) {
          this.avatar = profileData.base_url + "/" + profileData.profile_pic;
        }
      }
    },
    countryData: {
      immediate: true,
      handler(countryData) {
        this.$data.country = countryData;
      }
    },

    cityData: {
      immediate: true,
      handler(cityData) {
        this.$data.city = cityData;
      }
    }
  },
  validations: {
    item: {
      fullname: { required },
      email: { required, email },
      industry: { required },
      gst: { required },
      mobile_number: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(13)
      },
      mobile_prefix: { required },
      regnumber: { required },
      country: { required },
      city: { required },
      address: { required }
    }
  },
  data() {
    return {
      request: {},
      item: {
        fullname: null,
        regnumber: null,
        industry: null,
        gst: null,
        email: null,
        mobile_number: null,
        country: null,
        city: null,
        mobile_prefix: '+91',
        address: null
      },
      validationmessage: {
        fullname: "full name is required",
        industry: "industry is required",
        gst: "gst number is required",
        email: "please enter valid email",
        mobile_number: "please enter valid phone number with prefix",
        country: "country is required",
        city: "gender is required",
        regnumber: "registration number is required",
        address: "address is required"
      },
      submitted: false,
      IsPersonalDetailEditable: false,
      avatar: null,
      classname: "default",
      inputid: "startdate",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      selectplaceholder: "Select...",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      btnid: "countrybtn",
      phnumberboxtype: "number",
      chargecbid: "1",
      bartercbid: "2",
      imageurl: "usericon.png",
      fileuploadid: "profileuploader",
      brandname: "",
      indstry: "",
      agencyregistration: "",
      email: "",
      phno: "",
      gst: "",
      city: [],
      country: [],
      address: "",
      countrylist: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" }
      ],
      genderoption: [
        {
          id: "1",
          label: "Male"
        },
        {
          id: "2",
          label: "Female"
        },
        {
          id: "3",
          label: "Others"
        }
      ]
    };
  },
  created() {
    this.getuserprofile();
  },
  methods: {
    ...mapActions("brandprofile", ["basicprofile", "getuserprofile"]),
    ...mapActions("commonfunction", ["getcity"]),

    getfiledata(e) {
      this.filedata = e.target.files[0];
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.avatar = e.target.result;
      };
    },

    submitProfile() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        const formData = new FormData();
        let attachment = this.filedata;
        formData.append("profile_pic", attachment);
        let Data = {
          full_name: this.item.fullname,
          email: this.item.email,
          phone: this.item.mobile_number,
          country: this.item.country,
          city: this.item.city,
          prefix: this.item.mobile_prefix,
          address: this.item.address,
          agency_reg_num: this.item.regnumber,
          industry: this.item.industry,
          gst_number: this.item.gst
        };
        formData.append("Data", JSON.stringify(Data));
        this.basicprofile(formData)
          .then(response => {
            console.log(response);
            vm.getuserprofile();
            this.IsPersonalDetailEditable = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    getcountryname(data) {
      let name = this.country.filter(value => {
        return value.id == data;
      });
      if (name.length) {
        return name[0].name;
      } else {
        return "";
      }
    },

    getcityname(data) {
      let name = this.city.filter(value => {
        return value.id == data;
      });
      if (name.length) {
        return name[0].name;
      } else {
        return "";
      }
    },

    sendCountryid(data) {
      this.getcity({
        id: data
      });
    },
    personalDetailToggle() {
      if (this.IsPersonalDetailEditable == false) {
        this.IsPersonalDetailEditable = true;
      } else {
        this.IsPersonalDetailEditable = false;
      }
    },
    changePasswordToggle() {
      this.$root.$emit("changePasswordToggle");
    }
  }
};
</script>