import axios from "axios";

const AUTH_PARAMS = {
  grant_type: "password",
  client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
  client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
};

function initialState() {
    let cmsDetailsHome = [];
    let cmsDetailsContactUs = [];
    let cmsDetailsTermsAndConditions = [];
    let cmsDetailsAboutUs = [];
    let websiteSettings = [];
    let testimonialsData = [];
    let cmsDetailsOurService = [];
    let cmsDetailsSolution = [];
    let cmsDetailsPrivacyPolicy = [];
    let cmsDetailsTermsOfUse = [];
    return {
      cmsDetailsHome: cmsDetailsHome,
      cmsDetailsContactUs: cmsDetailsContactUs,
      cmsDetailsTermsAndConditions: cmsDetailsTermsAndConditions,
      cmsDetailsAboutUs: cmsDetailsAboutUs,
      websiteSettings: websiteSettings,
      testimonialsData: testimonialsData,
      cmsDetailsOurService: cmsDetailsOurService,
      cmsDetailsSolution: cmsDetailsSolution,
      cmsDetailsPrivacyPolicy: cmsDetailsPrivacyPolicy,
      cmsDetailsTermsOfUse: cmsDetailsTermsOfUse,
    };
}

const getters = {
  cmsDetailsHome: (state) => state.cmsDetailsHome,
  cmsDetailsContactUs: (state) => state.cmsDetailsContactUs,
  cmsDetailsTermsAndConditions: (state) => state.cmsDetailsTermsAndConditions,
  cmsDetailsAboutUs: (state) => state.cmsDetailsAboutUs,
  websiteSettings: (state) => state.websiteSettings,
  testimonialsData: (state) => state.testimonialsData,
  cmsDetailsOurService: (state) => state.cmsDetailsOurService,
  cmsDetailsSolution: (state) => state.cmsDetailsSolution,
  cmsDetailsPrivacyPolicy: (state) => state.cmsDetailsPrivacyPolicy,
  cmsDetailsTermsOfUse: (state) => state.cmsDetailsTermsOfUse,
};

const actions = {
    getCmsData: ({ commit },data) => {
        let slug = data.slug;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/cms-data/"+slug)
                .then((response) => {
                    let cammelCaseName = response.data.data.cms_detail.cammelcasename;
                    commit("setCMSDetails"+cammelCaseName, response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },
    getWebsiteSettings: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/settings")
                .then((response) => {
                    console.log("response", response.data.data);
                    commit("setWebsiteSettings", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },

    getTestimonialsData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/testimonial")
                .then((response) => {
                    commit("setTestimonialsData", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },
};

const mutations = {
    setCMSDetailsHome: (state, value) => {
        state.cmsDetailsHome = value;
    },
    setCMSDetailsContactUs: (state, value) => {
        state.cmsDetailsContactUs = value;
    },
    setCMSDetailsTermsAndConditions: (state, value) => {
        state.cmsDetailsTermsAndConditions = value;
    },
    setCMSDetailsAboutUs: (state, value) => {
        state.cmsDetailsAboutUs = value;
    },
    setCMSDetailsOurServices: (state, value) => {
        state.cmsDetailsOurService = value;
    },
    setCMSDetailsSolution: (state, value) => {
        state.cmsDetailsSolution = value;
    },
    setCMSDetailsPrivacyPolicy: (state, value) => {
        state.cmsDetailsPrivacyPolicy = value;
    },
    setCMSDetailsTermsOfUse: (state, value) => {
        state.cmsDetailsTermsOfUse = value;
    },
    setWebsiteSettings: (state, value) => {
        state.websiteSettings = value;
    },
    setTestimonialsData: (state, value) => {
        state.testimonialsData = value;
    }
};

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  getters: getters,
  actions: actions,
};
