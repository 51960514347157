<template>
    <div class="edit-image" v-if="checkPermission('campaign_action')">
        <img src="../../../assets/image/copy.svg" @click="getCopyData(row)" alt="copy-image" class="copy"/>
    </div>
</template>

<script>
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {

    };
  },
  methods: { 
    getCopyData(row) {
      this.$root.$emit('getCopyData', row)
      // console.log(row)
    },
  },
};
</script>
