<template>
    <div class="edit-image">
        <img v-if="checkPermission('invoice_update')"  id="isedit" class="noteditable" @click="getEditData(row)" src="../../../assets/image/edit.svg" alt="edit-image"/>
    </div>
</template>

<script>
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {

    };
  },
  methods: { 
    getEditData(row) {
      console.log('asdasdasdasdasdas')
      this.$root.$emit('getEditData', row)
    }
  },
};
</script>
