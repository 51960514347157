<template>
    <div class="action-image">
        <img src="../../../../assets/image/usericon.png" alt="user-image" class="user-img"/>
    </div>
</template>

<script>
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {
      
    };
  },
};
</script>
