import PublisherLayoutMain from "../layoutspublisher/main.vue";
import Support from "../pages/publisher/support/support.vue";
import SupportChat from "../pages/publisher/support/chatdetail.vue"
import PublisherDashboard from "../pages/publisher/dashboard/dashboard.vue";
import AuthPublisher from "../utils/AuthPublisher";
import ContentList from "../pages/publisher/content/contentlist.vue";
import ContentPublish from "../pages/publisher/content/contentpublish.vue";
import ContentPublishEdit from "../pages/publisher/content/contentpublishedit.vue";
import WebsiteTraffic from "../pages/publisher/content/websitetraffic.vue";
import WebsiteTrafficList from "../pages/publisher/content/websitetrafficlist.vue";
import WebsiteTrafficEdit from "../pages/publisher/content/websitetrafficedit.vue";
import MyProfile from "../pages/publisher/profile/myprofile.vue";

export default {
  path: "/publisher",
  component: PublisherLayoutMain,
  beforeEnter: AuthPublisher,
  children: [
    {
      path: "/publisher/dashboard",
      name: "publisherdashboard",
      component: PublisherDashboard,
    },
    {
      path: "/publisher/contentlist",
      name: "publishercontentlist",
      component: ContentList,
    },
    {
      path: "/publisher/contentpublish",
      name: "publishercontentpublish",
      component: ContentPublish,
    },
    {
      path: "/publisher/contentpublishedit/:id",
      name: "publishercontentpublishedit",
      component: ContentPublishEdit,
    },
    {
      path: "/publisher/websitetraffic",
      name: "publisherwebsitetraffic",
      component: WebsiteTraffic,
    },
    {
      path: "/publisher/websitetrafficedit/:id",
      name: "publisherwebsitetrafficedit",
      component: WebsiteTrafficEdit,
    },
    {
      path: "/publisher/websitetrafficlist",
      name: "publisherwebsitetrafficlist",
      component: WebsiteTrafficList,
    },
    {
      path: "/publisher/profile",
      name: "publisherprofile",
      component: MyProfile,
    },
    {
      path: "/publisher/support",
      name: "publishersupport",
      component: Support,
    },
    {
      path: "/publisher/supportchat/:id",
      name: "publishersupportchat",
      component: SupportChat,
    },
  ],
};