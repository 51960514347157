<template>
    <div class="influencer influencer-data admin-role-concent">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Consents</text-component>
                <div class="page-header-right">
                <button-component :clsname="btnprimary">
                    <export-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "Consents.xls">
                        Generate Report
                    </export-excel>
                </button-component>
            </div>
        </div>
    </div>
    <div class="box paddingo">
        <div class="box-content">
            <header-tabs
            :tabdata="tabdata"
            :activetab="activetab"
            :maxLimit="maxLimit"
            :isHiddenbar="isHiddenbar"
            >
            <div class="tag-bar-bottom-data" v-if="activetab === 'Pending Consents'">
                <div class="datatable-main">
                <div class="data-table-header">
                    <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                    ></search-bar>
                    </div>
                </div>
                <datatable-component
                    class="dtable-main"
                    :selected-rows="selectedRows"
                    :actionid="actionid"
                    :columns="columns"
                    :rows="rows"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="filter"
                    :page="page"
                    :per_page="per_page"
                ></datatable-component>
                </div>
            </div>
            <div class="tag-bar-bottom-data" v-if="activetab === 'Approved Consents'">
                <div class="datatable-main">
                <div class="data-table-header">
                    <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                    ></search-bar>
                    </div>
                </div>
                <datatable-component
                    class="dtable-main"
                    :selected-rows="selectedRows"
                    :actionid="actionid"
                    :columns="influencercolumns"
                    :rows="influencerrows"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="filter"
                    :page="page"
                    :per_page="per_page"
                ></datatable-component>
                </div>
            </div>
            <div class="tag-bar-bottom-data" v-if="activetab === 'Rejected Consents'">
                <div class="datatable-main">
                <div class="data-table-header">
                    <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                    ></search-bar>
                    </div>
                </div>
                <datatable-component
                    class="dtable-main"
                    :selected-rows="selectedRows"
                    :actionid="actionid"
                    :columns="influencerrejectcolumns"
                    :rows="influencerrejectrows"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="filter"
                    :page="page"
                    :per_page="per_page"
                ></datatable-component>
                </div>
            </div>
            </header-tabs>
        </div>
    </div>

    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isInvite}">
        <div class="modal-window-main">
            <a title="Close" class="modal-close">
            <img
                @click="sendInvite"
                src="../../../assets/image/cancel.svg"
                class="cancel-icon"
                alt="cancel-icon"
            />
            </a>
            <div class="header">
            <text-component :class="h2txtclass">Request For Campaign</text-component>
            </div>
            <div class="row message-desc">
            <input-component
                class="txtgroup col-12"
                v-model="item.budget"
                required="true"
                :helptext="!$v.item.budget.required && submitted ? validationmessage.budget: false"
                :labelclass="{'validationerr': !$v.item.budget.required && submitted}"
                :classname="{'error': !$v.item.budget.required && submitted, 'default': true}"
            >Influencer Budget</input-component>
            <select-component
                class="txtgroup col-12"
                required="true"
                :helptext="!$v.item.account.required && submitted ? validationmessage.account: false"
                :labelclass="{'validationerr': !$v.item.account.required && submitted}"
                :inputclass="{'error': !$v.item.account.required && submitted, 'dropdownmenu': true}"
                :options="accountoption"
                v-model="item.account"
                :multiple="true"
                :placeholder="selectplaceholder"
            >Accounts</select-component>
            <select-component
                class="txtgroup col-12"
                required="true"
                :helptext="!$v.item.type.required && submitted ? validationmessage.type: false"
                :labelclass="{'validationerr': !$v.item.type.required && submitted}"
                :inputclass="{'error': !$v.item.type.required && submitted, 'dropdownmenu': true}"
                :options="typeoption"
                v-model="item.type"
                :multiple="false"
                :placeholder="selectplaceholder"
            >Type</select-component>
            </div>
            <hr class="hrline" />
            <div class="modal-footer">
            <div>
                <button-component @onBtnClick="handleSendinvite" :clsname="btnprimary">Send Invite</button-component>
            </div>
            </div>
        </div>
    </div>
    <!-- /Modal -->
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import Action from "./action.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { eventBus } from "../../../main";
import StatusBadge from "./statusbadge.vue";
import usericon from "./profileicon.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        InputComponent,
        ButtonComponent,
        SelectComponent,
        TextComponent
    },
    computed: {
        ...mapGetters("adminconcentaction", [
        "pendingData",
        "approvedData",
        "rejectedData",
        ]),
    },
    watch: {
        pendingData: {
            immediate: true,
            handler(pendingData) {
                this.$data.rows = pendingData;
            },
        },

        approvedData: {
            immediate: true,
            handler(approvedData) {
                this.$data.influencerrows = approvedData;
            },
        },

        rejectedData: {
            immediate: true,
            handler(rejectedData) {
                this.$data.influencerrejectrows = rejectedData;
            },
        },
        activetab:{
            immediate: true,
            handler(activetab){
                // "Influencer's Name": 'name',
                // "Campaign Name": 'campaignname',
                // 'Status': 'status',       
                if(activetab == 'Pending Consents'){
                    this.json_data = []
                    for (let i = 0; i < this.rows.length; i++) {
                        let xlsstatus = '';
                        if(this.rows[i].admin_status == 1){
                            xlsstatus = 'Approved'
                        }
                        else if(this.rows[i].admin_status == 2){
                            xlsstatus = 'Rejected'
                        }
                        else {
                            xlsstatus = 'Pending'
                        }
                        this.json_data.push(
                            {
                                "name": this.rows[i].name,
                                "campaignname": this.rows[i].campaign_name,
                                "status": xlsstatus
                            }
                        )
                    }
                }
                else if (activetab == 'Approved Consents'){
                    this.json_data = []
                    for (let i = 0; i < this.influencerrows.length; i++) {
                        let xlsstatus = '';
                        if(this.influencerrows[i].admin_status == 1){
                            xlsstatus = 'Approved'
                        }
                        else if(this.influencerrows[i].admin_status == 2){
                            xlsstatus = 'Rejected'
                        }
                        else {
                            xlsstatus = 'Pending'
                        }
                        this.json_data.push(
                            {
                                "name": this.influencerrows[i].name,
                                "campaignname": this.influencerrows[i].campaign_name,
                                "status": xlsstatus
                            }
                        )
                    }
                }
                else if (activetab == 'Rejected Consents'){
                    this.json_data = []
                    for (let i = 0; i < this.influencerrejectrows.length; i++) {
                        let xlsstatus = '';
                        if(this.influencerrejectrows[i].admin_status == 1){
                            xlsstatus = 'Approved'
                        }
                        else if(this.influencerrejectrows[i].admin_status == 2){
                            xlsstatus = 'Rejected'
                        }
                        else {
                            xlsstatus = 'Pending'
                        }
                        this.json_data.push(
                            {
                                "name": this.influencerrejectrows[i].name,
                                "campaignname": this.influencerrejectrows[i].campaign_name,
                                "status": xlsstatus
                            }
                        )
                    }
                }
            }
        },
    },
    validations: {
        item: {
        budget: { required },
        account: { required },
        type: { required },
        },
    },
    data() {
        return {
        item: {
            budget: null,
            account: null,
            type: null,
        },
        validationmessage: {
            budget: "influencer budget is required ",
            account: "account is required ",
            type: "type is required ",
        },
        submitted: false,
        isInvite: false,
        selectplaceholder: "Select...",
        accountoption: [],
        typeoption: [],
        activetab: "Pending Consents",
        maxLimit: 9,
        h2txtclass: "h2",
        isHiddenbar: false,
        isChat: false,
        btnprimary: "btnprimary",
        subtitle2class: "subtitle2",
        midclass: "mid",
        txtclassname: "default",
        actionid: "moreactionbtn",
        classname: "default prefixicon",
        page: 1,
        per_page: 8,
        currentrowidinvite: null,
        type: "text",
        inputPlaceholder: "Search Influencer",
        filter: "",
        to: "",
        selectedRows: [],
        currentuserid: null,
        columns: [
            {
                label: "Profile Photo",
                headerAlign: "left",
                align: "left",
                component: usericon,
            },
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Campaign Name",
                field: "campaign_name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Status",
                align: "left",
                headerAlign: "left",
                component: StatusBadge,
                sortable: false,
            },
            {
                label: "Action",
                headerAlign: "left",
                align: "left",
                show:true,
                component: Action,
            },
        ],
        rows: [],
        influencercolumns: [
            {
                label: "Profile Photo",
                headerAlign: "left",
                align: "left",
                component: usericon,
            },
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Campaign Name",
                field: "campaign_name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Status",
                align: "left",
                headerAlign: "left",
                component: StatusBadge,
                sortable: false,
            },
        ],
        influencerrejectcolumns: [
            {
                label: "Profile Photo",
                headerAlign: "left",
                align: "left",
                component: usericon,
            },
            {
                label: "Influencer's Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Campaign Name",
                field: "campaign_name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false,
            },
            {
                label: "Status",
                align: "left",
                headerAlign: "left",
                component: StatusBadge,
                sortable: false,
            },
        ],
        influencerrejectrows: [],
        influencerrows: [],
        tabdata: [
            { id: "1", value: "Pending Consents" },
            { id: "2", value: "Approved Consents" },
            { id: "3", value: "Rejected Consents" },
        ],
        json_fields: {
            "Influencer's Name": 'name',
            "Campaign Name": 'campaignname',
            'Status': 'status',       
        },
        json_data: [],
        };
    },
    created: function () {
        if(!this.checkPermission('consent_action')){
            this.columns.splice((this.columns.length-1), 1)
        }
        this.$root.$on("onApprove", (row) => {
        this.currentrowidinvite = row.campaign_id
        this.sendInvite(row);
        });

        this.$root.$on("onClick", () => {
        if (this.isChat == false) {
            this.isChat = true;
        } else {
            this.isChat = false;
        }
        });

        eventBus.$on(
        "changeActivetab",
        function (selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );

        this.pendingConcentData();
        this.approvedConcentData();
        this.rejectedConcentData();
    },
    methods: {
        // ...mapActions("admininfluenceraction",["getinfluencerpending","getinfluencerapproved","getinfluencerrejected"]),
        ...mapActions("adminconcentaction", [
        "pendingConcentData",
        "approvedConcentData",
        "rejectedConcentData",
        "approveorrejectconcent"
        ]),
        ...mapActions("admincampaignaction", [
        "sendInvitation",
        "getVerifiedaccounts",
        "getPosttype",
        ]),

        handleApprove() {
        let vm = this;
        this.approveorrejectconcent({
            id: this.currentrowid,
            status: "1"
        })
            .then(response => {
            // vm.pendingConcentData();
            // vm.approvedConcentData();
            // vm.rejectedConcentData();
            this.$toast.open({
                message: response.message,
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top"
            });
            })
            .catch(err => {
            this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
            });
            });
        },

        handleSendinvite() {
        let vm = this;
        this.submitted = true;
        if (this.$v.item.$invalid) {
            this.$v.item.$touch();
        } else {
            this.sendInvitation({
            campaign_id: this.currentrowidinvite,
            influencer_id: this.currentuserid,
            offered_price: this.item.budget,
            account: this.item.account.toString(),
            content_type: this.item.type,
            })
            .then((response) => {
                vm.handleApprove();
                vm.getallinfluencerlist();
                this.isInvite = false;
                this.$toast.open({
                message: response.message,
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top",
                });
            })
            .catch((err) => {
                this.isInvite = false;
                this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top",
                });
            });
        }
        },

        sendInvite(row) {
        this.currentrowid = row.id
        this.currentuserid = row.user_id;
        this.getVerifiedaccounts({
            id: row.user_id,
        }).then((response) => {
            this.accountoption = this.setdata(response.data);
        });
        this.getPosttype().then((response) => {
            this.typeoption = this.settypedata(response.data);
        });
        if (this.isInvite == true) {
            this.isInvite = false;
        } else {
            this.isInvite = true;
        }
        },

        setdata(data) {
        let newArray = [];
        data.map((value) => {
            newArray.push({
            id: value.id,
            label: value.name,
            });
        });
        return newArray;
        },

        settypedata(data) {
        let newArray = [];
        data.map((value) => {
            newArray.push({
            id: value.id,
            label: value.name,
            });
        });
        return newArray;
        },

        sendMessege() {
        if (this.isChat == false) {
            this.isChat = true;
        } else {
            this.isChat = false;
        }
        },
    },
};
</script>
