<template>
    <div>
        <badge :badgeclass="{'Attention-Badge':(row.interested== null),'Default-Badge':(row.interested=='0'),'Success-Badge':(row.interested=='1')}">
            <!-- {{row.interested}} {{row.date}} -->
            <span v-if="row.interested == null">Pending</span>
            <span v-if="row.interested == '1'">Interested</span>
            <span v-if="row.interested == '0'">withdrawal</span>
        </badge>
    </div>
</template>

<script>
import Badge from '../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],

    data() {
        return {
        }
    },
}
</script>