<template>
    <div class="chat-detail brand-support">
        <div class="content-header">
            <text-component class="h2">{{ticketDetail.subject}} Ticket Details</text-component>
            <div class="page-header-right">
                <button-component @onBtnClick="syncchat" clsname="btnprimary">Sync</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <!-- <div class="box-header">
                    <text-component class="subtitle1">Sender Details</text-component>
                    <button-component :clsname="btnprimary">Close Chat</button-component>
                </div> -->
                <div class="box-content">
                    <!-- <div class="sender-detail">
                        <div class="row margino">
                            <div class="paddingo sender-detail-main col-12 col-md-6 col-xl-4">
                                <text-component class="subtitle2 sender-detail-title">Sender's Name</text-component>
                                <text-component class="sender-detail-txt">Lungelo Ngcaba</text-component>
                            </div>
                            <div class="paddingo sender-detail-main col-12 col-md-6 col-xl-4">
                                <text-component class="subtitle2 sender-detail-title">Email</text-component>
                                <text-component class="sender-detail-txt">lungelongcaba@gmail.com</text-component>
                            </div>
                            <div class="paddingo sender-detail-main col-12 col-md-6 col-xl-4">
                                <text-component class="subtitle2 sender-detail-title">Contact No.</text-component>
                                <text-component class="sender-detail-txt">+52 77248 92487</text-component>
                            </div>
                        </div>
                    </div>
                    <hr class="hr-divider"/> -->
                    <div class="message-section" id="message-section">
                        <div v-for="(chatRow,index) in detailChat" :key="index">
                            <div class="incoming">
                                <div class="user-img">
                                    <img v-if="chatRow.user_profile.sender_image" :src="getImageUrl(chatRow)" alt="user-img-main">
                                    <img v-else src="../../../assets/image/usericon.png" alt="user-img-main">
                                    <!-- <img src="../../../assets/image/usericon2.png" alt="user-img-main"> -->
                                </div>
                                <div class="message-content">
                                    <div class="user-name-message">
                                        <div class="user-name">
                                            <text-component class="subtitle2">{{chatRow.user_profile.sender_name}}</text-component>
                                        </div>
                                        <div class="user-message">
                                            <text-component>{{chatRow.message}}</text-component>
                                        </div>                            
                                    </div>
                                    <div class="message-time">
                                        <text-component class="mid">{{timeformessage(chatRow.created_at)}}</text-component>
                                        <!-- <text-component class="mid">30-July-2020 20:00</text-component> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="send-message box margino" id="sendmsg" v-if="ticketDetail.ticket_status=='0'">
                <text-area-comp v-model="item.message"
                    required="true"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="submitHandler"
                    @keydown.enter.shift.exact="newline"
                    @keydown.enter.alt.exact="newline"
                    :helptext="!$v.item.message.required && submitted ? validationmessage.message: false"
                    :labelclass="{'validationerr': !$v.item.message.required && submitted}"
                    :classname="{'error': !$v.item.message.required && submitted, 'default': true}"
                ></text-area-comp>
                <img src="../../../assets/image/send.svg" alt="send-icon" class="send-img" @click="submitHandler()">
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue"
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        TextComponent,
        TextAreaComp,
        ButtonComponent
    },
    validations: {
        item: {
            message: { required },
            ticket_id: { required }
        }
    },
    data() {
        return {
            submitted:false,
            btnprimary: 'btnprimary',
            item:{
                message:null,
                receiver_id:null,
                ticket_id:null,
            },
            validationmessage: {
                message: "Message is required",
            },
            detailChat:[],
            ticketDetail:[]
        }
    },
    created:function () {
        this.singledata();
    },
    updated () {
        this.scrollToEnd(); 
    },    
    mounted () {
        this.scrollToEnd();	
    },
    methods:{
        ...mapActions("adminchattaction", ["sendMessage","getDetailChatData"]),
        singledata() {
            this.getDetailChatData({
                id: this.$route.params.id
            }).then(response => {
                // console.log("response1",response)
                if (response.data) {
                    this.detailChat = response.data.chat;
                    this.ticketDetail = response.data.support;
                    this.item.ticket_id = this.$route.params.id;
                    this.item.receiver_id = response.data.support.receiver_id;
                }
            });
        },

        syncchat() {
            this.getDetailChatData({
                id: this.$route.params.id
            }).then(response => {
                if (response.data) {
                    this.detailChat = response.data.chat;
                    this.ticketDetail = response.data.support;
                    this.item.ticket_id = this.$route.params.id;
                    this.item.receiver_id = response.data.support.receiver_id;
                }
                this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
            });
        },

        scrollToEnd: function() {    	
            var container = this.$el.querySelector("#message-section");
            console.log('container.scrollHeight',container.scrollHeight)
            container.scrollTop = container.scrollHeight;
        },

        getImageUrl(rowData){
            if (rowData.user_profile.sender_image) {
                return rowData.user_profile.baseUrl+'/'+rowData.user_profile.user_id+'/'+rowData.user_profile.sender_image
            }else{
                return "";
            }
        },
        submitHandler(){
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.sendMessage(this.item).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                    this.submitted = false;
                    this.afterSubmit();
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        afterSubmit(){
            this.submitted = false;
            this.item.message = null;
            this.singledata();
        },
        newline() {
            this.item.message = `${this.item.message}\n`;
        },
    }
}
</script>