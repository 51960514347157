<template>
    <div class="action-btn">
        <button-component @onBtnClick="onChat(row)" :clsname="btnplain" class="tbl-chat-btn">Chat</button-component>
        <button-component @onBtnClick="onClick(row)" :clsname="btnplain">Final Amount</button-component>
    </div>
</template>

<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue"
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      btnplain: 'btnplain'
    };
  },
  methods: {
    onClick() {
      this.$root.$emit('onClick', this.row)
    },
     onChat() {
      this.$root.$emit('onChat', this.row)
    },
  }
};
</script>