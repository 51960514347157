<template>
    <div>
        <section class="service-banner">
            <img src="../../../assets/image/front/solution_Banner.jpg">
        </section>

        <section class="join-vidunit">
            <div class="container">
                <div class="row">
                    <div class="col-12 pink">
                        <h1>{{cms_field.section1_title}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 service-forward" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000" v-html="cms_field.section1_description">
                    </div>
                </div>

                <div class="border-section">
                    <div class="row">
                        <div class="service-join solution col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section1_1_image" :src="getImageURL(baseUrl,cms_image.section1_1_image)">
                            <img v-else src="../../../assets/image/front/Regional.png">
                            <h3>{{cms_field.section1_1_subtitle}}</h3>
                        </div>
                        <div class="service-join solution col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section1_2_image" :src="getImageURL(baseUrl,cms_image.section1_2_image)">
                            <img v-else src="../../../assets/image/front/Ideation.png">
                            <h3>{{cms_field.section1_2_subtitle}}</h3>
                        </div>
                        <div class="service-join solution col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section1_3_image" :src="getImageURL(baseUrl,cms_image.section1_3_image)">
                            <img v-else src="../../../assets/image/front/Amplification.png">
                            <h3>{{cms_field.section1_3_subtitle}}</h3>
                        </div>
                        <div class="service-join solution col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section1_4_image" :src="getImageURL(baseUrl,cms_image.section1_4_image)">
                            <img v-else src="../../../assets/image/front/Multiple.png">
                            <h3>{{cms_field.section1_4_subtitle}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="service-our-team solution">
            <div class="container">
                <section class="top-second-img">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <div class="hiw-content service-cus-title">
                                    <h1>{{cms_field.section2_title}}</h1>
                                </div>
                                <div class="service-cus-content">
                                    <p>{{cms_field.section2_description}}</p>
                                </div>
                            </div>
                            <div class="service-img col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <img v-if="cms_image.section2_image" :src="getImageURL(baseUrl,cms_image.section2_image)">
                                <img v-else src="../../../assets/image/front/Business.png">
                            </div>
                        </div>
                    </div>
                </section>
                <div class="service-box">
                    <div class="row">
                        <div class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="solution-team-box">
                                <img v-if="cms_image.section2_1_image" :src="getImageURL(baseUrl,cms_image.section2_1_image)">
                                <img v-else src="../../../assets/image/front/know.png">
                                <h5>{{cms_field.section2_1_subtitle}}</h5>
                                <p>{{cms_field.section2_1_description}}</p>
                            </div>
                        </div>
                        <div class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="solution-team-box">
                                <img v-if="cms_image.section2_2_image" :src="getImageURL(baseUrl,cms_image.section2_2_image)">
                                <img v-else src="../../../assets/image/front/Comprehensivenew.png">
                                <h5>{{cms_field.section2_2_subtitle}}</h5>
                                <p>{{cms_field.section2_2_description}}</p>
                            </div>
                        </div>
                        <div class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="solution-team-box">
                                <img v-if="cms_image.section2_3_image" :src="getImageURL(baseUrl,cms_image.section2_3_image)">
                                <img v-else src="../../../assets/image/front/Database.png">
                                <h5>{{cms_field.section2_3_subtitle}}</h5>
                                <p>{{cms_field.section2_3_description}}</p>
                            </div>
                        </div>
                        <div class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="solution-team-box">
                                <img v-if="cms_image.section2_4_image" :src="getImageURL(baseUrl,cms_image.section2_4_image)">
                                <img v-else src="../../../assets/image/front/Campaign_Dashboard.png">
                                <h5>{{cms_field.section2_4_subtitle}}</h5>
                                <p>{{cms_field.section2_4_description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="solution-channel">
            <div class="container">
                <div class="row">
                <div class="col-12 pink">
                    <h1>{{cms_field.section5_title}}</h1>
                </div>
                </div>
                <div class="row">
                <div class="col-sm-12 col-md-7 col-lg-8" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <div class="youtube-main-content" v-html="cms_field.section5_description"></div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section5_1_image" :src="getImageURL(baseUrl,cms_image.section5_1_image)">
                                <img v-else src="../../../assets/image/front/Execute_Large.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section5_1_subtitle}}</h3>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section5_2_image" :src="getImageURL(baseUrl,cms_image.section5_2_image)">
                                <img v-else src="../../../assets/image/front/Analytics.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section5_2_subtitle}}</h3>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section5_3_image" :src="getImageURL(baseUrl,cms_image.section5_3_image)">
                                <img v-else src="../../../assets/image/front/Creative.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section5_3_subtitle}}</h3>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution-content-img col-sm-12 col-md-5 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <img v-if="cms_image.section5_image" :src="getImageURL(baseUrl,cms_image.section5_image)">
                    <img v-else src="../../../assets/image/front/Images.png">
                </div>
                </div>
            </div>
        </section>


        <section class="service-channel solution">
            <div class="container">
                <div class="row">
                <div class="youtube-content-img col-sm-12 col-md-4 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <img v-if="cms_image.section4_image" :src="getImageURL(baseUrl,cms_image.section4_image)">
                    <img v-else src="../../../assets/image/front/Talent_Agency.png">
                </div>
                <div class="col-sm-12 col-md-8 col-lg-9" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <div class="row">
                    <div class="col-12 white">
                        <h1>{{cms_field.section4_title}}</h1>
                    </div>
                    </div>
                    <div class="youtube-main-content" v-html="cms_field.section4_description"></div>
                    <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="youtube-service">
                        <div class="youtube-img">
                            <img v-if="cms_image.section4_1_image" :src="getImageURL(baseUrl,cms_image.section4_1_image)">
                            <img v-else src="../../../assets/image/front/Creative.png">
                        </div>
                        <div class="Youtube-content">
                            <h3>{{cms_field.section4_1_subtitle}}</h3>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="youtube-service">
                        <div class="youtube-img">
                            <img v-if="cms_image.section4_2_image" :src="getImageURL(baseUrl,cms_image.section4_2_image)">
                            <img v-else src="../../../assets/image/front/Creative.png">
                        </div>
                        <div class="Youtube-content">
                            <h3>{{cms_field.section4_2_subtitle}}</h3>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="youtube-service">
                        <div class="youtube-img">
                            <img v-if="cms_image.section4_3_image" :src="getImageURL(baseUrl,cms_image.section4_3_image)">
                            <img v-else src="../../../assets/image/front/Creative.png">
                        </div>
                        <div class="Youtube-content">
                            <h3>{{cms_field.section4_3_subtitle}}</h3>
                        </div>
                        </div>
                    </div>         
                    </div>
                </div>
                </div>
            </div>
        </section>
        <!-- old -->
        <!-- 
        <section class="product-review">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="pr24">
                            <div class="pink top-30 bottom-84" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h1>{{cms_field.section1_title}}</h1>
                            </div>
                            <div class="h6-p" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h6>{{cms_field.section1_subtitle}}</h6>
                                <div v-html="cms_field.section1_description"></div>
                                <p>Aenean commodo lacus et congue pulvinar. Integer eget auctor libero, a scelerisque lorem. Vivamus venenatis libero eu lacinia malesuada. Phlus tempus, eros nec rhoncus fermentum, metus orci fringilla urna, sit amet an lorem urna ac dui. Fusce at est eu nunc lobortis consectetur id et dui. Nam laoreet, metus ac rhoncus vulputate, erat lacus scelerisque, tsque leo leo vel diam. Pellentesque vel ex mattis.</p>
                                <div class="ul">
                                    <div class="li">Aenean commodo lacus et congue pulvinar. Integer eget auctor ibero, a scelerisque lorem.</div>
                                    <div class="li">Nam laoreet, metus ac rhoncus vulputate, erat lacus scelerisque, tsqu leo leo vel diam. Pellentesque vel ex mattis.</div>
                                    <div class="li">Fusce at est eu nunc lobortis consectetur id et dui. Nam laoreet, met.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 img-section" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section1_image" :src="getImageURL(baseUrl,cms_image.section1_image)">
                        <img v-else src="../../../assets/image/front/ProductReview.png">
                    </div>
                </div>
            </div>
        </section>


        <section class="business">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="pr24 business-p">
                            <div class="white bottom-73" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h1>{{cms_field.section2_title}}</h1>
                            </div>
                            <div data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <p class="p">{{cms_field.section2_description}}</p>
                            </div>
                    
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 img-section" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section2_image" :src="getImageURL(baseUrl,cms_image.section2_image)">
                        <img v-else src="../../../assets/image/front/Business.png">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="i_content pr24">
                            <img v-if="cms_image.section2_1_image" :src="getImageURL(baseUrl,cms_image.section2_1_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h6>{{cms_field.section2_1_subtitle}}</h6>
                            <p>{{cms_field.section2_1_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="i_content pr24">
                            <img v-if="cms_image.section2_2_image" :src="getImageURL(baseUrl,cms_image.section2_2_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h6>{{cms_field.section2_2_subtitle}}</h6>
                            <p>{{cms_field.section2_2_description}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="i_content pr24">
                            <img v-if="cms_image.section2_3_image" :src="getImageURL(baseUrl,cms_image.section2_3_image)">
                            <img v-else src="../../../assets/image/front/VAIcon.png">
                            <h6>{{cms_field.section2_3_subtitle}}</h6>
                            <p>{{cms_field.section2_3_description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="brand-section">
            <div class="container">
                <div class="text-center">
                    <div class="pink bottom-95" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h1>{{cms_field.section3_title}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-7" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="pr24">
                            <h6>{{cms_field.section3_subtitle}}</h6>
                            <p>{{cms_field.section3_description}}</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="pl16">
                            <div class="row">
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_1_image" :src="getImageURL(baseUrl,cms_image.section3_1_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_2_image" :src="getImageURL(baseUrl,cms_image.section3_2_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_3_image" :src="getImageURL(baseUrl,cms_image.section3_3_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_4_image" :src="getImageURL(baseUrl,cms_image.section3_4_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_5_image" :src="getImageURL(baseUrl,cms_image.section3_5_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                                <div class="col-6 col-sm-4 col-md-4 img-section bottom24">
                                    <img v-if="cms_image.section3_6_image" :src="getImageURL(baseUrl,cms_image.section3_6_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="talent-agency">
            <div class="container">
                <div class="row"> 
                
                    <div class=" col-sm-12 order-md-22 col-md-7 col-lg-8">
                        <div class="pl24">
                            <div class="white top-30 bottom-100" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h1>{{cms_field.section4_title}}</h1>
                            </div>
                            <div class="h6-p" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h6>{{cms_field.section4_subtitle}}</h6>
                                <p>{{cms_field.section4_description}}</p>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <div class="i_content">
                                        <img v-if="cms_image.section4_1_image" :src="getImageURL(baseUrl,cms_image.section4_1_image)">
                                        <img v-else src="../../../assets/image/front/VAIcon.png">
                                        <h6>{{cms_field.section4_1_subtitle}}</h6>
                                        <p>{{cms_field.section4_1_description}}</p>
                                    </div>
                                </div>  
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <div class="i_content">
                                        <img v-if="cms_image.section4_2_image" :src="getImageURL(baseUrl,cms_image.section4_2_image)">
                                        <img v-else src="../../../assets/image/front/VAIcon.png">
                                        <h6>{{cms_field.section4_2_subtitle}}</h6>
                                        <p>{{cms_field.section4_2_description}}</p>
                                    </div>
                                </div>  
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <div class="i_content">
                                        <img v-if="cms_image.section4_3_image" :src="getImageURL(baseUrl,cms_image.section4_3_image)">
                                        <img v-else src="../../../assets/image/front/VAIcon.png">
                                        <h6>{{cms_field.section4_3_subtitle}}</h6>
                                        <p>{{cms_field.section4_3_description}}</p>
                                    </div>
                                </div>  
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <div class="i_content">
                                        <img v-if="cms_image.section4_4_image" :src="getImageURL(baseUrl,cms_image.section4_4_image)">
                                        <img v-else src="../../../assets/image/front/VAIcon.png">
                                        <h6>{{cms_field.section4_4_subtitle}}</h6>
                                        <p>{{cms_field.section4_4_description}}</p>
                                    </div>
                                </div>  
                                    
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-12 order-md-11 col-md-5 col-lg-4 img-section add-top-50" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section4_image" :src="getImageURL(baseUrl,cms_image.section4_image)">
                        <img v-else src="../../../assets/image/front/TalentAgency.png">
                    </div>
                </div>
            </div>
        </section>



        <section class="marketing-agency">
            <div class="container">
                <div class="pink bottom-100" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <h1>{{cms_field.section5_title}}</h1>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-7">
                        <div class="width-760 h6-p">
                            <div data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h6>{{cms_field.section5_subtitle}}</h6>
                                <p>{{cms_field.section5_description}}</p>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6 box-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <img v-if="cms_image.section5_1_image" :src="getImageURL(baseUrl,cms_image.section5_1_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                    <h6>{{cms_field.section5_1_subtitle}}</h6>
                                    <p>{{cms_field.section5_1_description}}</p>
                                </div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6 box-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                    <img v-if="cms_image.section5_2_image" :src="getImageURL(baseUrl,cms_image.section5_2_image)">
                                    <img v-else src="../../../assets/image/front/HWB.png">
                                    <h6>{{cms_field.section5_2_subtitle}}</h6>
                                    <p>{{cms_field.section5_2_description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 text-right fix-img" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section5_image" :src="getImageURL(baseUrl,cms_image.section5_image)">
                        <img v-else src="../../../assets/image/front/AboutImage.png">
                    </div>
                </div>
            </div>
        </section>



        <section class="Leading-light">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="white bottom-95" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <h1>{{cms_field.section6_title}}</h1>
                        </div>
                        <div class="h6-p" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <h6>{{cms_field.section6_subtitle}}</h6>
                            <p>{{cms_field.section6_description}}</p>
                        </div>
                    </div> 
                </div>
                <div class="row">
                    <div class="col-12">
                        <h6 class="heading">Muchos paquetes de autoedición</h6>
                    </div>
                    <div class="col-12">
                        <div class="diplsy-section" style="margin:0px -24px">
                            <carousel style="width:100%; overflow:hidden;" :autoplay="false" :nav="false" :responsive="{0:{items:1,nav:false},768:{items:2,nav:false},992:{items:3,nav:false}}">
                                <div v-for="(client,index) in celebrityclientdata" :key="index" style="margin:0 24px">
                                    <div class="white-box">
                                        <img src="../../../assets/image/front/Alicia_Stanger14.png">
                                        <h5>{{client.account_id}}</h5>
                                        <h6>{{client.account_name}}</h6>
                                        <p>{{client.description}}</p>
                                        <div class="h-line"></div>
                                        <div class="m-number">
                                            <div class="inner-sec"><h6>{{client.media}}</h6><p>Media</p></div>
                                            <div class="inner-sec"><h6>{{client.followers}}</h6><p>Followers</p></div>
                                            <div class="inner-sec"><h6>{{client.following}}</h6><p>Following</p></div>
                                        </div>
                                    </div>
                                </div>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
// import carousel from 'vue-owl-carousel'
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:'Solution',
                meta_title:null,
                meta_keyword:null,
                meta_desc:'Solution',
            },
            cms_field:[],
            cms_image:[],
            testimonials:[],
            celebrityclientdata: []
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail?this.cms_detail.name:'Solution',
            meta: [
                { name: 'description', content:  (this.cms_detail)?this.cms_detail.meta_desc:''},
                { name: 'keyword', content:  (this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    mounted(){
        this.getData();
    },
    created(){
        this.getData();
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsSolution"]),
        ...mapGetters("admincelebrityclient",["celebrityClient"])
    },
    watch:{
        cmsDetailsSolution:{
            immediate:true,
            handler(cmsDetailsSolution){
                if (cmsDetailsSolution) {
                    this.baseUrl = cmsDetailsSolution.baseUrl;
                    this.cms_detail = cmsDetailsSolution.cms_detail;
                    this.cms_field = cmsDetailsSolution.cms_field;
                    this.cms_image = cmsDetailsSolution.cms_image;
                }
            }
        },

        celebrityClient:{
            immediate:true,
            handler(celebrityClient){
                this.celebrityclientdata = celebrityClient;
            }
        }
    },
    methods:{
        ...mapActions("frontcms",["getCmsData"]),
        ...mapActions("admincelebrityclient",["getCelebrityClient"]),
        getData(){
            this.getCmsData({slug:'solution'});
            this.getCelebrityClient();
        }
    }
}
</script>