
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    return {
    }

}

const getters = {
    
};

const actions = {

    getbrandinfluencer: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/brand-influencers")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getcampaignsocial: ({ commit },socialdata) => {
        let id = socialdata.id
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/campaign-social-link/" + id)
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getposttype: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/content-format")
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

}

const mutations = {
    
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};