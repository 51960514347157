<template>
  <div>
    <div class="content-header">
      <div>
        <label class="h2 header-content-left">Users</label>
        <router-link to="newuser" v-if="checkPermission('users_create')">
          <button-component class="header-content-right" :clsname="createuser">Create User</button-component>
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="user">
        <div class="box">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
                <date-dropdown
                  class="header-date-dropdown"
                  :btnid="datebtnid"
                  v-model="statusDroprole"
                  :clsname="dropDate"
                  :popoverList="dropDateList"
                  :clearClass="clearClass"
                  :btnText="dateDropText"
                >{{statusClear}}</date-dropdown>
                <status-dropdown
                  class="header-status"
                  :clsname="dropBtn"
                  v-model="statusDrop"
                  :popoverList="popoverList"
                  :clearClass="clearClass"
                  :btnText="dropdownText"
                >{{statusClear}}</status-dropdown>
                <!-- <sort-button
                  :btnid="statusid"
                  class="header-sortbutton"
                  :popoverList="sortList"
                  :clsname="clsname"
                  :btnText="btnText"
                ></sort-button> -->
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :actionid="actionid"
              :sortList="sortList"
              :columns="columns"
              :cboxindeterminatecls="cboxindeterminatecls"
              :actionText="actionText"
              :actionList="actionList"
              :rows="rows"
              :clsname="clsname"
              :btnText="btnText"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :dropBtn="dropBtn"
              :popoverList="popoverList"
              :dropdownText="dropdownText"
              :statusClear="statusClear"
              :clearClass="clearClass"
              :filter="filter"
              :page="page"
              :per_page="per_page"
              :no_data_msg="no_data_msg"
              @onrowclick="viewuserInfo"
              @actiondrop="getactiondropvalue"
              nodatamsg= "Currently, No users in the list"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import SelectRow from "./selectrow.vue";
import SelectAll from "../../../../BasicComponents/Createaccount/selectall.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
// import sortButton from "../../BasicComponents/Dropdown/sortableDropdown.vue";
import statusDropdown from "../../../../BasicComponents/Dropdown/checkboxDropdown.vue";
import DateDropdown from "../../../../BasicComponents/Dropdown/checkboxDropdown.vue";
import StatusBadge from "./userstatusbadge.vue";
import { eventBus } from "../../../../main";
import { mapGetters, mapActions } from "vuex";
import router from "../../../../routes/routes";
import ActionBtn from "./actionbtnconsent.vue";
export default {
  components: {
    ButtonComponent,
    // sortButton,
    searchBar,
    statusDropdown,
    DateDropdown,
    DatatableComponent
  },
  computed: {
    ...mapGetters("user", ["userData", "rolesData"])
  },
  data() {
    return {
      page: 1,
      per_page: 10,
      createuser: "btnprimary",
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      inputPlaceholder: "Search user",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Status",
      statusClear: "Clear",
      clearClass: "btnplain",
      btninvitecls: "btnprimary",
      acname: "Mahnaz Farzin",
      myacimageurl: "alert.svg",
      dropCat: "btnbasic",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      dateDropText: "Role",
      dropDate: "btnbasic",
      datebtnid: "datebtnid",
      no_data_msg:"Currently, No users available.",
      dropDateList: [],
      selectedRows: [],
      columns: [
        // { label: "", component: SelectRow, headerComponent: SelectAll },
        {
          label: "User ID",
          field: "id",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: true
        },
        {
          label: "Name",
          align: "left",
          headerAlign: "left",
          field: "name",
          sortable: true
        },
        {
          label: "Email",
          align: "left",
          headerAlign: "left",
          field: "email",
          sortable: false
        },
        {
          label: "Phone number",
          align: "right",
          headerAlign: "right",
          // field: "phone",
          representedAs: function(row) {
            return row.prefix + "-" + row.phone;
          },
          sortable: false
        },
        {
          label: "Role",
          align: "left",
          headerAlign: "left",
          field: "role.role_name",
          sortable: true
        },
        {
          label: "Status",
          align: "left",
          headerAlign: "left",
          component: StatusBadge,
          sortable: false
        },
        {
          label: "Action",
          headerAlign: "right",
          align: "right",
          component: ActionBtn
        }
      ],
      rows: [
        //...
        {
          id: "1",
          user_id: "27834",
          name: "Dameon Peterson",
          email: "dameonpeterson@email.com",
          phone_number: "+973 1234 5678",
          role: "Sales Manager",
          status: "Active"
        },
        {
          id: "2",
          user_id: "65472",
          name: "Emmalynn Mazzia",
          email: "emmalynn mazzia@email.com",
          phone_number: "+973 1234 5678",
          role: "System Customizer",
          status: "Active"
        },
        {
          id: "3",
          user_id: "58319",
          name: "Lumir Sacharov",
          email: "lumirsacharov@email.com",
          phone_number: "+973 1234 5678",
          role: "Sales Person",
          status: "Inactive"
        },
        {
          id: "4",
          user_id: "52673",
          name: "Phawta Tuntayakul",
          email: "phawta tuntayakul@email.com",
          phone_number: "+973 1234 5678",
          role: "Sales Executive",
          status: "Active"
        }
        //...
      ],
      popoverList: [
        { id: "0", value: "Inactive" },
        { id: "1", value: "Active" },
      ],
      actionList: [
        // { id: "0", value: "Active" },
        // { id: "1", value: "Inactive" },
        // { id: "2", value: "Delete user" }
      ],
      sortList: [
        { id: "0", value: "User ID (ascending)" },
        { id: "1", value: "User ID (descending)" },
        { id: "2", value: "Name (A-Z)" },
        { id: "3", value: "Name (Z-A)" },
        { id: "4", value: "Role (A-Z)" },
        { id: "5", value: "Role (Z-A)" }
      ],
      apirows: [],
      statusDrop: [],
      statusDroprole: [],
      selectedRowsIds: []
    };
  },

  watch: {
    statusDrop: {
      immediate: false,
      handler(statusDrop) {
        console.log("statusDrop", statusDrop);
        this.filterData();
      }
    },
    statusDroprole: {
      immediate: false,
      handler(statusDroprole) {
        console.log("statusDroprole", statusDroprole);
        this.filterData();
      }
    },
    selectedRows: {
      immediate: false,
      handler(selectedRows) {
        // console.log('asdasdasdasdasdasdasdasd')
        let selectedRowsIds = [];
        selectedRows.forEach(row => {
          selectedRowsIds.push(row.id);
        });
        this.selectedRowsIds = selectedRowsIds;
        this.addSelectedIds(selectedRowsIds);
      }
    },

    rolesData: {
      immediate: true,
      handler(rolesData) {
        // this.$data.apirows = rolesData;
        // this.filterData();
        console.log(rolesData);
        this.dropDateList = this.getroles(rolesData)
      }
    },

    selected_rows: function() {
      for (var row of this.rows) {
        // console.log(this.rows);
        if (this.selectedRows.indexOf(row) === -1) {
          return;
        }
      }
      console.log(this.selectedRows);

      eventBus.$emit("all-selected");
    },

    userData: {
      immediate: true,
      handler(userData) {
        this.$data.apirows = userData;
        this.filterData();
      }
    }
  },
  created: function() {
    if(!this.checkPermission('users_delete')){
        this.columns.splice((this.columns.length-1), 1)
    }
    eventBus.$on(
      "selected",
      function(row) {
        // console.log(row);
        if (this.selectedRows.indexOf(row) === -1) {
          this.selectedRows.push(row);
        }
      }.bind(this)
    );

    eventBus.$on(
      "deselected",
      function(row) {
        if (this.selectedRows.indexOf(row) !== -1) {
          let index = this.selectedRows.indexOf(row);

          this.selectedRows.splice(index, 1);
        }
      }.bind(this)
    );

    eventBus.$on(
      "select-all",
      function(selected) {
        console.log(selected);
        this.selectedRows = this.rows.slice(0);
        // Vue.set(this, "selected_rows", this.rows.slice(0));
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function(selected) {
        console.log(selected);
        this.selectedRows = [];
        // Vue.set(this, "selected_rows", []);
      }.bind(this)
    );

    this.getuserData();
    this.getrolesData();
    this.addSelectedIds([]);
  },

  methods: {
    ...mapActions("user", ["getuserData","addSelectedIds", "multidelete", "getrolesData", "useractive", "userinactive"]),

    getroles(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          value: value.role_name
        })
      })
      return newarray;
    },

    getactiondropvalue(value) {
      let vm = this;
      if (value.target.text == "Delete user") {
        this.multidelete({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getuserData();
        });
      } 
      else if (value.target.text == "Active") {
        console.log("add active method")
        this.useractive({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getuserData();
        });
      } else if (value.target.text == "Inactive") {
        console.log("add inactive method")
        this.userinactive({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getuserData();
        });
      }
    },

    viewuserInfo: function(e) {
      
      // router.push({ name: "ViewUser", params: { id: e.id } });
      // console.log(e);
    },

    filterData: function() {
      // let filterStatus = this.activetab;
      let filterrole = this.statusDroprole;
      let filterstatusDrop = this.statusDrop;
      // console.log("filter:",filterStatus);
      this.rows = this.apirows.filter(row => {
        let status = ["0","1", "2"];
        // let request_status = ["0", "1", "2"];
        // if (filterStatus == "Unpaid") {
        //   paid_status = ["0"];
        // } else if (filterStatus == "Paid") {
        //   paid_status = ["1"];
        // } 
        if (!filterstatusDrop.length) {
          filterstatusDrop = ["0","1", "2"];
        }
        return (
          status.indexOf(row.status) != -1 &&
          (!filterrole.length || (row.role && filterrole.indexOf(row.role.id) != -1)) &&
          // request_status.indexOf(row.status) != -1 &&
          filterstatusDrop.indexOf(row.status) != -1
        );
      });
    }
  }
};
</script>