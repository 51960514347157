<template>
    <div class="testimonial testimonial-list celebrity-client-list">
        <div class="content-header">
            <span class="h2">Celebrity Client List</span>
            <div class="page-header-right">
                <button-component @onBtnClick="createTestimonial" :clsname="'btnprimary'">Create Client</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter"
                                ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isView}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="togglePopUp" src="../../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="header">
                        <text-component :class="'h2'">{{item.id?'Edit':'Create'}} Client</text-component>
                    </div>
                    <div class="row margino">
                        <div class="col-12">
                            <div class="row icon-id-row">
                                <div class="col-4">
                                    <profile-uploader
                                        :imageurl="imageurl"
                                        :fileuploadid="fileuploadid"
                                        @selectfile="getfiledata"
                                        :avatar="avatar"
                                    ></profile-uploader>
                                </div>
                                <div class="col-12 col-sm-8">
                                    <input-component
                                        class="txtgroup col-12 paddingo"
                                        v-model="item.account_id"
                                        required="true"
                                        :helptext="!$v.item.account_id.required && submitted ? validationmessage.account_id: false"
                                        :labelclass="{'validationerr': !$v.item.account_id.required && submitted}"
                                        :classname="{'error': !$v.item.account_id.required && submitted, 'default': true}">
                                        Account ID
                                    </input-component>
                                </div>
                            </div>
                        </div>
                        <input-component
                            class="txtgroup col-12"
                            v-model="item.account_name"
                            required="true"
                            :helptext="!$v.item.account_name.required && submitted ? validationmessage.account_name: false"
                            :labelclass="{'validationerr': !$v.item.account_name.required && submitted}"
                            :classname="{'error': !$v.item.account_name.required && submitted, 'default': true}">
                            Account Name
                        </input-component>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <text-component>Description*</text-component>
                            </div>
                            <text-area-comp v-model="item.description"
                                required="true"
                                :helptext="!$v.item.description.required && submitted ? validationmessage.description: false"
                                :labelclass="{'validationerr': !$v.item.description.required && submitted}"
                                :classname="{'error': !$v.item.description.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                            <span
                                v-if="!$v.item.description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.message}}</span>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="txtgroup col-12 col-sm-6">
                                    <input-component
                                        class="txtgroup col-12 paddingo"
                                        v-model="item.media"
                                        required="true"
                                        :helptext="!$v.item.media.required && submitted ? validationmessage.media: false"
                                        :labelclass="{'validationerr': !$v.item.media.required && submitted}"
                                        :classname="{'error': !$v.item.media.required && submitted, 'default': true}">
                                        Media
                                    </input-component>
                                </div>
                                <div class="txtgroup col-12 col-sm-6">
                                    <input-component
                                        class="txtgroup col-12 paddingo"
                                        v-model="item.followers"
                                        required="true"
                                        :helptext="!$v.item.followers.required && submitted ? validationmessage.followers: false"
                                        :labelclass="{'validationerr': !$v.item.followers.required && submitted}"
                                        :classname="{'error': !$v.item.followers.required && submitted, 'default': true}">
                                        Followers
                                    </input-component>
                                </div>
                                <div class="txtgroup col-12">
                                    <input-component
                                        class="txtgroup col-12 paddingo"
                                        v-model="item.following"
                                        required="true"
                                        :helptext="!$v.item.following.required && submitted ? validationmessage.following: false"
                                        :labelclass="{'validationerr': !$v.item.following.required && submitted}"
                                        :classname="{'error': !$v.item.following.required && submitted, 'default': true}">
                                        Following
                                    </input-component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="hrline" />
                    <div class="modal-footer">
                        <div>
                            <button-component v-if="item.id" @onBtnClick="handleTestimonialUpdate" :clsname="btninvitecls">Update</button-component>
                            <button-component v-else @onBtnClick="handleTestimonial" :clsname="btninvitecls">Create</button-component>
                        </div>
                    </div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import profileicon from "./profileicon.vue";
import EditBtn from "./editicon.vue";
import Action from "./delete.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
import ProfileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        DatatableComponent,
        searchBar,
        ProfileUploader,
        ButtonComponent,
        InputComponent,
        TextComponent,
        TextAreaComp
    },
    validations: {
        item: {
            account_id: { required },
            account_name: { required },
            description: { required },
            media: { required },
            followers: { required },
            following: { required },
        },
    },
    data() {
        return {
            item: {
                account_id: null,
                account_name: null,
                description: null,
                media: null,
                followers: null,
                following: null,
            },
            validationmessage: {
                account_id: "account id is required",
                account_name: 'Name is required',
                description: 'Message is required',
                media: "media is required",
                followers: "followers is required",
                following: "following is required",
            },
            type: "text",
            inputPlaceholder: "Search client",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            isView: false,
            submitted: false,
            rowviewdata: [],
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: null,
            filedata: null,
            columns: [
                { label: "Icon", headerAlign: "left", align: "left", component: profileicon },
                {
                    label: "Account Id",
                    field: "account_id",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Name",
                    field: "account_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Media",
                    field: "media",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Followers",
                    field: "followers",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Following",
                    field: "following",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "", headerAlign: "left", align: "right", component: EditBtn },
                // { label: "", headerAlign: "left", align: "right", component: EditBtn },
                { label: "Action", headerAlign: "left", align: "left", component: Action },
            ],
            rows: [],
        };
    },
    computed:{
        ...mapGetters("admincelebrityclient",["celebrityClient"])
    },
    watch:{
        celebrityClient:{
            immediate:true,
            handler(celebrityClient){
                this.rows = celebrityClient;
            }
        }
    },
    methods: {
        ...mapActions("admincelebrityclient",
        ["getCelebrityClient",
        "createCelebrityClient",
        "updateCelebrityClient",
        "getSingleCelebrityClient",
        "deleteCelebrityClient"]),

        getfiledata(e) {
            this.filedata = e.target.files[0];
            let image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.avatar = e.target.result;
            };
        },

        createTestimonial() {
            this.item = {
                name: null,
                designation: null,
                message: null,
            }
            this.submitted = false;
            this.togglePopUp()
        },

        togglePopUp() {
            this.isView = !this.isView;
        },

        handleTestimonial(){
            let vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                const formData = new FormData();
                let attachment = this.filedata;
                formData.append("image", attachment);
                formData.append("Data", JSON.stringify(this.item));

                this.createCelebrityClient(formData)
                .then(response => {
                    this.togglePopUp();
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        handleTestimonialUpdate(){
            let vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                const formData = new FormData();
                let attachment = this.filedata;
                formData.append("image", attachment);
                formData.append("Data", JSON.stringify(this.item));

                this.updateCelebrityClient({'id':this.item.id,'formData':formData})
                .then(response => {
                    this.togglePopUp();
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        onEditRow(id){
            this.getSingleCelebrityClient({id:id})
            .then(response=>{
                console.log('response',response)
                this.item = response.data;
                if (response.data.image) {
                    this.avatar = response.data.base_url + "/" + response.data.image;
                }
                this.togglePopUp();
            });
        }
    },
    created() {
        this.getCelebrityClient();

        // this.$root.$on("adminContentDeleteClick", () => {
        //     this.getarticlelistdata()
        // });
        this.$root.$on("getEditCelebrity", (row) => {
            this.onEditRow(row.id);
        });
    },
    // beforeDestroy() {
    //     this.$root.$off("getEditCelebrity", this.fn);
    // },
}
</script>