import router from '../routes/routes'

export default (to, from, next) => {
  if (localStorage.getItem('auth') != null && localStorage.getItem('auth').length > 5) {
    let auth = localStorage.getItem("auth");
    let authData = JSON.parse(auth);
    if (authData.role_id == 1) {
      router.push({ name: "admindashboard" });
    } else if (authData.role_id == 2) {
      router.push({ name: "dashboard" });
    } else if (authData.role_id == 3) {
      router.push({ name: "agencydashboard" });
    } else if (authData.role_id == 4) {
      router.push({ name: "branddashboard" });
    } else if (authData.role_id == 5) {
      router.push({ name: "publisherdashboard" });
    }
    
} else {
    next()
  }
}
