<template>
  <div class="campaign-detail-section in-progress">
    <div class="row campaign-detail-section-main">
      <div class="col-12 col-xl-8 campaign-data" :class="{'campaign-size': !isChat}">
        <div class="row margino">
          <div class="datatable-main">
            <!-- <div class="table-header">
                            <text-component class="subtitle1">Campaign Insights</text-component>
            </div>-->
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :columns="columns"
              :rows="rows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="filter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
      </div>
      <div v-if="isChat" class="col-4 campaign-chat" id="chatsection">
        <inprocess-chat
          :influencerId="currentrow.influencer_id"
          :senderName="currentrow.user_profile.full_name"
          :influencer_status="currentrow.user_profile.influencer_status"
        ></inprocess-chat>
        <div @click="openChat" class="chat-btn">
          <img click src="../../../../assets/image/chat.svg" class="chat-btn-img" />
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isFinalAmount}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="sendAmount"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Final Amount</text-component>
        </div>
        <div class="row margino negotiated-amount-txt">
          <div class="col-12 paddingo negotiated-amount-data">
            <text-component class="subtitle2">Original Amount</text-component>
            <text-component class="black amount-txt">₹ {{currentrow.offered_price}}</text-component>
          </div>
          <div class="col-12 paddingo negotiated-amount-data">
            <text-component class="subtitle2">Negotiated Amount</text-component>
            <text-component class="black amount-txt">₹ {{currentrow.bargain_price}}</text-component>
          </div>
        </div>
        <div class="row">
          <input-component
            class="txtgroup col-12"
            v-model="item.amount"
            required="true"
            :helptext="!$v.item.amount.required && submitted ? validationmessage.amount: false"
            :labelclass="{'validationerr': !$v.item.amount.required && submitted}"
            :classname="{'error': !$v.item.amount.required && submitted, 'default': true}"
          >Final Amount</input-component>
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="handleSendamount" :clsname="btnprimary">Save</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import Action from "./inprocessaction.vue";
import usericon from "./inprocessicon.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import InprocessChat from "./inprecesschat.vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
    InprocessChat,
    ButtonComponent,
    InputComponent,
  },
  validations: {
    item: {
      amount: { required },
    },
  },
  data() {
    return {
      item: {
        amount: null,
      },
      validationmessage: {
        amount: "final amount is required",
      },
      submitted: false,
      currentrow: {},
      selectedRows: [],
      campaignData: [],
      inputPlaceholder: "Search Influencer",
      type: "text",
      h2txtclass: "h2",
      classname: "default prefixicon",
      filter: "",
      inputclass: "default",
      financefilter: "",
      page: 1,
      per_page: 8,
      btnprimary: "btnprimary",
      isFinalAmount: false,
      isChat: false,
      columns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "user_profile.full_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
            label: "Offered price",
            field: "offered_price",
            align: "left",
            headerAlign: "left",
            headerClass: "class-in-header second-class",
            sortable: false
        },
        {
          label: "Negotiated amount",
          field: "bargain_price",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
            label: "Final price",
            field: "final_price",
            align: "left",
            headerAlign: "left",
            headerClass: "class-in-header second-class",
            sortable: false
        },
        {
          label: "Action",
          headerAlign: "left",
          align: "left",
          component: Action,
        },
      ],
      rows: [],
    };
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "getInprocesslist",
      "sendFinalAmount",
    ]),

    handleSendamount() {
      const vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.sendFinalAmount({
          campaign_id: this.$route.params.id,
          influencer_id: this.currentrow.influencer_id,
          amount: this.item.amount,
        })
          .then((response) => {
            this.isFinalAmount = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
            this.submitted = false;
            this.afterSubmit();
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },

    afterSubmit() {
      this.submitted = false;
      this.item.amount = null;
      this.getinprocessslist();
    },

    getinprocessslist() {
      this.getInprocesslist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.rows = response.data;
      });
    },

    openChat(row) {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },
    sendAmount() {
      if (this.isFinalAmount == true) {
        this.isFinalAmount = false;
      } else {
        this.isFinalAmount = true;
      }
    },
  },
  created: function () {
    this.$root.$on("onClick", (row) => {
      this.currentrow = row;
      this.sendAmount();
    });
    this.$root.$on("onChat", (row) => {
      this.currentrow = row;
      this.isChat = true;
    });

    this.getinprocessslist();
  },
};
</script>