<template>
  <div class="createrole">
    <div class="content-header">
      <div class="back-path">
        <router-link class="remove-underline" :to="{name: 'Roles'}">
          <text-component :class="pathtxtclass">Roles</text-component>
        </router-link>
      </div>
      <div class="row">
        <div class="col-md-12">
          <text-component :class="headertxtclass" class="create-role-heading">Create Role</text-component>
        </div>
      </div>
    </div>

    <form @submit.prevent="setrolepermission">
      <div class="content">
        <div class="box">
          <div class="row input-padding">
            <div class="col-lg-4">
              <input-component
                required="true"
                v-model="item.role_name"
                :helptext="!$v.item.role_name.required && submitted ? validationmessage.role_name: false"
                :labelclass="{'validationerr': !$v.item.role_name.required && submitted}"
                :classname="{'error': !$v.item.role_name.required && submitted, 'default': true}"
              >Role</input-component>
            </div>
          </div>
          <hr class="hr-divider" />
          <div class="permission-details">
            <div v-for="(permissionmodule, index) in permissiondata" :key="index">
              <div class="row inner-padding">
                <div class="col-lg-3 col-12">
                  <text-component :textclass="textclass">{{slugtoname(index)}}</text-component>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-6 row-padding">
                      <toggle-switch
                        class="toggle-switch"
                        name="usernone"
                        v-model="rolenonecheckbox[index]"
                      ></toggle-switch>
                      <text-component class="permission-label" :textclass="innerText">None</text-component>
                    </div>
                    <div
                      class="col-lg-3 col-md-3 col-6 row-padding"
                      v-for="(singledata, index2) in permissionmodule"
                      :key="index2"
                    >
                      <toggle-switch class="toggle-switch" v-model="rolecheckbox[singledata.id]"></toggle-switch>
                      <text-component
                        class="permission-label"
                        :textclass="innerText"
                      >{{slugtoname(index2)}}</text-component>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="hr-divider" />
            </div>
            <div class="row inner-padding">
              <div class="col-lg-12">
                <button-component
                  :clsname="clsname"
                  buttontype="submit"
                  class="update-btn"
                >Create Role</button-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>



<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import ToggleSwitch from "../../../../BasicComponents/Checkbox/switchbox.vue";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    InputComponent,
    ButtonComponent,
    ToggleSwitch
  },

  computed: {
    ...mapGetters("adminpermission", ["permissionData"])
  },

  watch: {
    permissionData: {
      immediate: true,
      handler(permissionData) {
        this.$data.permissiondata = permissionData;
        this.getnonecheckbox();
      }
    },

    rolecheckbox: {
      immediate: false,
      deep: true,
      handler(rolecheckbox) {
        let nonearray = Object.keys(rolecheckbox);
        for (let title in this.permissiondata) {
          let isnonecheck = true;
          let titledata = this.permissiondata[title];
          for (let key in titledata) {
            let iddata = titledata[key];
            let inarray = iddata["id"].toString();
            if (nonearray.indexOf(inarray) != -1 && rolecheckbox[inarray]) {
              isnonecheck = false;
            }
          }
          if (isnonecheck) {
            this.rolenonecheckbox[title] = true;
          } else {
            this.rolenonecheckbox[title] = false;
          }
        }
      }
    },

    rolenonecheckbox: {
      immediate: false,
      handler(rolenonecheckbox) {
        let nonearray = Object.keys(rolenonecheckbox);
        for (let title of nonearray) {
          for (let title2 in this.permissiondata) {
            if (title == title2) {
              // console.log(this.permissiondata[title]);
              let category = Object.values(this.permissiondata[title]);
              category.map(value => {
                // console.log(value.id);
                for (let uncheck in this.rolecheckbox) {
                  if (uncheck == value.id) {
                    // console.log("matchhed");
                    this.rolecheckbox[uncheck] = false;
                  }
                }
              });
            }
          }
        }
      }
    }
  },

  data() {
    return {
      rolenonecheckbox: {},
      rolecheckbox: {},
      permissiondata: {},
      item: {
        role_name: null
      },
      validationmessage: {
        role_name: "role is required"
      },
      submitted: false,
      pathtxtclass: "mid",
      headertxtclass: "h2",
      classname: "default",
      textclass: "subtitle2 dark",
      vendorpermission: "",
      innerText: "dark",
      clsname: "btnprimary"
    };
  },

  validations: {
    item: {
      role_name: { required }
    }
  },

  created() {
    this.getpermission();
  },

  methods: {
    ...mapActions("user", ["newrole"]),
    ...mapActions("adminpermission", ["getpermission", "setpermission"]),

    slugtoname(string) {
      let str = string.replace("_", " ");
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    setrolepermission() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.newrole({
          role_name: this.item.role_name
        }).then(response => {
          console.log(response);
          let roleid = response.data.id;
          if (roleid) {
            this.setpermission({
              id: roleid,
              type: "role",
              permission: this.rolecheckbox
            })
              .then(response => {
                console.log(response);
                vm.$router.push({ name: "Roles" });
                this.$toast.open({
                  message: response.message,
                  type: "success",
                  duration: 5000,
                  dismissible: true,
                  position: "top"
                });
                console.log("checking");
              })
              .catch(err => {
                this.$toast.open({
                  message: err.response.data.message,
                  type: "error",
                  duration: 5000,
                  dismissible: true,
                  position: "top"
                });
              });
          }
        });
      }
    },

    getnonecheckbox() {
      let permissions = Object.keys(this.permissiondata);
      let rolenonecheckbox = {};
      for (let data of permissions) {
        // console.log("datadata:",data)
        // console.log("indexdata:", index);
        rolenonecheckbox[data] = true;
      }
      this.rolenonecheckbox = rolenonecheckbox;
      // console.log(this.usernonecheckbox)
    }
  }
};
</script>