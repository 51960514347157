<template>
  <div class="notificationpage">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">Notifications</text-component>
      </div>
    </div>
    <div class="content">
      <div class="box margino paddingo">
        <div class="box-content">
          <ul>
            <li
              v-for="notification in allnotification"
              :key="notification.id"
              :class="(notification.read_status == '0')?'unread':'read'"
              @click="goTonotification(notification.module, notification.relation_id)">
              <img src="../../../assets/image/bell.svg" />
              {{notification.message}}
              <div class="header-notification-time">
                {{timeformessage(notification.created_at)}}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <notification-component></notification-component> -->
  </div>
</template>
<script>
// import NotificationComponent from "../../BasicComponents/notification/notification.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
// import {eventBus} from '../../main';
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // NotificationComponent,
    TextComponent
  },
  computed: {
    ...mapGetters("commonfunction", ["allNotification", "notificationData"])
  },
  watch: {
    allNotification: {
      immediate: true,
      handler(allNotification) {
        this.$data.allnotification = allNotification;
      }
    },
    notificationData: {
      immediate: true,
      handler(notificationData) {
          this.$data.notificationData = notificationData;
      }
    }
  },
  data() {
    return {
      allnotification: [],
      h2txtclass: "h2",
      notificationdata1: [
        {
          id: "1",
          notification: "The Fitness campaign you took part is has ended",
          status: "unread"
        },
        {
          id: "2",
          notification: "You recived payment from last Fitness Campaign",
          status: "unread"
        },
        {
          id: "3",
          notification: "Your password has successfully changed",
          status: "read"
        },
        {
          id: "4",
          notification: "The Nutrition campaign you took part is has ended",
          status: "read"
        },
        {
          id: "5",
          notification: "Your request for the consent is approved",
          status: "read"
        }
      ]
    };
  },
  created() {
    this.markAllRead()
  },

  methods: {
    ...mapActions("commonfunction",["readallnotifications","getallnotifications", "getlatestnotifications"]),

    goTonotification(modules, relation_id) {
      if(modules == 'Campaign'){
        this.$router.push({name: "admincampaigndetail",params: { id: relation_id }});
      }
      else if(modules == 'Campaign Invitation'){
        this.$router.push({name: "admincampaigns"});
      }
      else if(modules == 'Consent'){
        this.$router.push({name: "adminconcents"});
      }
      else if(modules == 'Campaign Chat'){
        this.$router.push({name: "admincampaigndetail",params: { id: relation_id }});
      }
      else if(modules == 'Chat'){
        this.$router.push({name: "adminchatdetail",params: { id: relation_id }});
      }
      else if(modules == 'Register New Influencer'){
        this.$router.push({name: "admininfluencerdata"});
      }
      else if(modules == 'Register New Brand Agency'){
        this.$router.push({name: "adminbranddata"});
      }
      else if(modules == 'Register New Agency'){
        this.$router.push({name: "adminagencydata"});
      }
      else if(modules == 'Register New Publisher'){
        this.$router.push({name: "adinpublisherdata"});
      } 
    },

    markAllRead() {
      this.readallnotifications({
        read_status: "1"
      })
        .then(response => {
          this.getallnotifications();
          this.getlatestnotifications();
          // this.$toast.open({
          //   message: response.message,
          //   type: "success",
          //   duration: 5000,
          //   dismissible: true,
          //   position: "top"
          // });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    }
  }
};
</script>