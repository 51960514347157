
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let settingsData = [];
    return {
        settingsData: settingsData,
    }

}

const getters = {
    settingsData: state => state.settingsData,
};

const actions = {
  getSettingsData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/settings")
        .then((response) => {
          console.log(response.data);
          commit("setSettingsData", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  updateSettingsData: ({ commit }, data) => {
    // commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/setting-update", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  changepassword: ({ state, commit }, passwordData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/change-password", {
          ...passwordData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
  setSettingsData: (state, value) => {
    state.settingsData = value;
  },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};