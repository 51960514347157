<template>
    <div class="platform-icons">
        <div 
            v-for="(platform, index) in accounts" :key="index"
            @click="redirectAccount(platform)"
            :class="platform.platform"
            class="icons">
        </div>
    </div>
</template>
<script>
export default {
    props:["row"],
    data() {
        return {
            platforms:[],
            accounts: []
        }
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                this.accounts = this.row.user_social_account
                console.log('accounts',this.accounts)
                this.platforms = []
                for (let i = 0; i < this.accounts.length; i++) {
                    if(this.platforms == []){
                        this.platforms.push(this.accounts[i].platform)
                    }
                    else{
                        if(!this.platforms.includes(this.accounts[i].platform)){
                            this.platforms.push(this.accounts[i].platform)
                        }
                    } 
                }
            }
        }
    },
    methods:{
        redirectAccount(data){
            // console.log('data clicked',data)
            if(data.platform=='facebook'){
                window.open(`https://www.facebook.com/${data.username}/`, '_blank')
            }

            if(data.platform=='twitter'){
                window.open(`https://twitter.com/${data.username}/`, '_blank')
            }

            if(data.platform=='youtube'){
                window.open(`https://www.youtube.com/channel/${data.username}/`, '_blank')
            }

            if(data.platform=='instagram'){
                window.open(`https://www.instagram.com/${data.username}/`, '_blank')
            }
        }
    }
}
</script>