<template>
    <div class="influencer influencer-data brand-data">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Community Manager</text-component>
                <div class="page-header-right">
                    <button-component :clsname="btnprimary">
                        <export-excel
                            class   = "btn btn-default"
                            :data   = "json_data"
                            :fields = "json_fields"
                            worksheet = "My Worksheet"
                            name    = "Community_Manager_Registration.xls">
                            Generate Report
                        </export-excel>
                    </button-component>
                </div>
            </div>
        </div>
        <div class="box paddingo">
            <!-- <div class="box-header headerpadding">
                <text-component :class="subtitle1txtclass">Campaign List </text-component>
            </div> -->
            <div class="box-content">
                <header-tabs
                    :tabdata="tabdata"
                    :activetab="activetab"
                    :maxLimit="maxLimit"
                    :isHiddenbar="isHiddenbar">
                    <div class="tag-bar-bottom-data" v-if="activetab === 'New Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="columns"
                                :rows="rows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div> 
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'All Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="brandcolumns"
                                :rows="brandrows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div>
                    </div>
                    <div class="tag-bar-bottom-data" v-if="activetab === 'Rejected Registration'">
                        <div class="datatable-main">
                            <div class="data-table-header">
                                <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter">
                                    </search-bar>
                                </div>
                            </div>
                            <datatable-component
                                class="dtable-main"
                                :selected-rows="selectedRows"
                                :actionid="actionid"
                                :columns="brandrejectcolumns"
                                :rows="brandrejectrows"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                :classname="classname"
                                :filter="filter"
                                :page="page"
                                :per_page="per_page">
                            </datatable-component>
                        </div>
                    </div>
                </header-tabs>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import Action from "./action.vue";
import DetailAction from "./detail/detailaction.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { eventBus } from "../../../main";
import {mapGetters, mapActions} from 'vuex';
export default {
    props: {},
    components: {
        HeaderTabs,
        DatatableComponent,
        searchBar,
        TextComponent,
        ButtonComponent
    },
    computed: {
        ...mapGetters("admincommunitymanageraction",["pendingData","approvedData","rejectedData"])
    },
    watch: {
        pendingData: {
            immediate: true,
            handler(pendingData) {
                this.$data.rows = pendingData
            }
        },

        approvedData: {
            immediate: true,
            handler(approvedData) {
                this.$data.brandrows = approvedData
            }
        },

        rejectedData: {
            immediate: true,
            handler(rejectedData) {
                this.$data.brandrejectrows = rejectedData
            }
        },

        activetab:{
            immediate: true,
            handler(activetab){
                if(activetab == 'All Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.brandrows.length; i++) {
                        this.json_data.push(
                            {"name": this.brandrows[i].name, "email": this.brandrows[i].email}
                        )
                    }
                }
                else if (activetab == 'New Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.rows.length; i++) {
                        this.json_data.push(
                            {"name": this.rows[i].name, "email": this.rows[i].email}
                        )
                    }
                }
                else if (activetab == 'Rejected Registration'){
                    this.json_data = []
                    for (let i = 0; i < this.brandrejectrows.length; i++) {
                        this.json_data.push(
                            {"name": this.brandrejectrows[i].name, "email": this.brandrejectrows[i].email}
                        )
                    }
                }
            }
        },
    },
    data() {
        return {
        activetab: "All Registration",
        maxLimit: 9,
        h2txtclass: 'h2',
        isHiddenbar: false,
        isChat: false,
        btnprimary: 'btnprimary',
        subtitle2class: 'subtitle2',
        midclass: 'mid',
        txtclassname: 'default',
        actionid: "moreactionbtn",
        classname: "default prefixicon",
        page: 1,
        per_page: 8,
        type: "text",
        inputPlaceholder: "Search Brand",
        filter: "",
        to: '',
        selectedRows: [],
        columns: [
            {
                label: "Manager Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                representedAs: function (row) {
                    return row.prefix+row.phone;
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            { label: "Action",headerAlign: "left", align: "left", component: (Action)},
        ],
        rows: [
            //...
            {
                id: '1',
                brandname: 'IM Nutrition',
                email: 'imnutrition@gmail.com'
            },
            {
                id: '2',
                brandname: 'Nutrition',
                email: 'nutrition@gmail.com'
            },
            {
                id: '3',
                brandname: 'AZ Nutrition',
                email: 'aznutrition@gmail.com'
            },

            //...
        ],
        brandcolumns: [
            {
                label: "Manager Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                representedAs: function (row) {
                    return row.prefix+row.phone;
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Status",
                representedAs: function (row) {
                    return (row.admin_status==1)?'Active':'Inactive';
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            { label: "Action",headerAlign: "left", align: "left", component: (DetailAction)},
        ],
        brandrejectcolumns: [
            {
                label: "Manager Name",
                field: "name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Email",
                field: "email",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Contact",
                representedAs: function (row) {
                    return row.prefix+row.phone;
                },
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
        ],
        brandrejectrows: [],
        brandrows: [],
        tabdata: [
            { id: "1", value: "All Registration" },
            { id: "2", value: "New Registration" },
            { id: "3", value: "Rejected Registration" },
        ],
        json_fields: {
            'Brand Name': 'name',
            'Email': 'email',       
        },
        json_data: [],
        };
        
    },
    created: function() {
        if(!this.checkPermission('brand_detail') && !this.checkPermission('brand_action') ){
            this.brandcolumns.splice((this.brandcolumns.length-1), 1)
        }
        this.$root.$on('onClick', () => {
            if(this.isChat == false){
                this.isChat = true
            }
            else{
                this.isChat = false
            }
        })

        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );

        this.getcommunitymanagerpending();
        this.getcommunitymanagerapproved();
        this.getcommunitymanagerrejected();

    },
    methods: {
        ...mapActions("admincommunitymanageraction",["getcommunitymanagerpending","getcommunitymanagerapproved","getcommunitymanagerrejected"]),

        sendMessege() {
            if(this.isChat == false){
                this.isChat = true
            }
            else{
                this.isChat = false
            }
        }
    },
  
};
</script>