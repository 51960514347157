<template>
    <div class="agencydetail-basic-detail">
        <div class="box margino">
            <!-- <div class="box-header">
                <text-component class="subtitle1">Agency Basic Detail</text-component>
            </div> -->
            <div class="box-content">
                <div class="row margino">
                    <img v-if="profileData.profile_pic" :src="profileData.base_url + '/' + profileData.profile_pic" class="user-img" alt="user-image"/>
                    <img v-else src="../../../../assets/image/usericon.png" class="user-img" alt="user-image"/>
                </div>
                <div class="row margino">
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Agency Name</text-component>
                            <text-component class="p-value dark">{{profileData.full_name}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Agency Registration Number</text-component>
                            <text-component class="p-value dark">{{profileData.agency_reg_num}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Email Address</text-component>
                            <text-component class="p-value dark">{{profileData.email}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Contact number</text-component>
                            <text-component class="p-value dark">{{profileData.prefix}} {{profileData.phone}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">City</text-component>
                            <text-component class="p-value dark">{{getcityname(profileData.city)}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Country</text-component>
                            <text-component class="p-value dark">{{getcountryname(profileData.country)}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-xl-6 paddingo">
                        <div class="row p-info margino">
                            <text-component class="p-title" :class="subtitle2class">Address</text-component>
                            <text-component class="p-value dark">{{profileData.address}}</text-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";
// import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
export default {
    components: {
        TextComponent,
        // ButtonComponent
    },
    data() {
        return{
            h2txtclass: 'h2',
            btnprimary: 'btnprimary',
            subtitle2class: 'subtitle2',
            profileData: [],
            country:[],
            city:[]
        }
    },
    computed: {
        ...mapGetters("commonfunction", ["countryData", "cityData"])
    },
    watch: {
        countryData: {
            immediate: true,
            handler(countryData) {
                this.$data.country = countryData;
            }
        },
        cityData: {
            immediate: true,
            handler(cityData) {
                this.$data.city = cityData;
            }
        }
    },
    methods: {
        ...mapActions("admininfluencerprofile", ["getuserprofile"]),
        getData(){
            this.getuserprofile({id:this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.profileData = response.data
            });
        },

        getcountryname(data) {
            let countryname = this.country.filter(value => {
                return value.id == data;
            });
            if (countryname.length) {
                return countryname[0].name;
            } else {
                return "";
            }
        },

        getcityname(data) {
            let cityname = this.city.filter(value => {
                return value.id == data;
            });
            if (cityname.length) {
                return cityname[0].name;
            } else {
                return "";
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>