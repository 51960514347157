<template>
  <div>
    <select v-if="dropdownPosition=='left'" class="connecteddropdownleft">
      <option v-for="option in options" :key="option.value" :value="option.value">{{option.label}}</option>
    </select>
    <input :class="classname" v-on:change="changefunction()" :value="value" v-on:input="updateValue($event.target.value)" :type="type" :disabled="disabledInput" :readonly="isReadonly" :required="isRequired" @keyup="press"  @focus="showD" @click="onIClick" :placeholder="inputPlaceholder" :maxlength="maxlength" />
    <div v-if="helptext" class="error-helper-msg">
      <label :class="labelclass">{{helptext}}</label>
    </div>
    <select v-if="dropdownPosition=='right'" class="connecteddropdown">
      <option v-for="option in options" :key="option.value" :value="option.value">{{option.label}}</option>
    </select>  
  </div>
</template>

<script>
export default {
  props: [
    "classname",
    "type",
    "labelclass",
    "disabledInput",
    "helptext",
    "options",
    "dropdownPosition",
    "inputPlaceholder",
    "value",
    "isReadonly",
    "isRequired",
    "maxlength",
  ],

  watch: {
    value: {
      immediate: false,
      handler(value) {
        this.$emit('change',value)
      }
    }
  },

  methods: {
    press: function (value) {
      this.$emit('searchItem',value);
    },
    updateValue: function (value) {
        this.$emit('input', value)
    },
    showD: function (value) {
        this.$emit('showData', value)
    },
    onIClick: function (value) {
        this.$emit('onInputClick', value)
    },
    changefunction: function (e) {
      this.$emit('change',e)
    }
  },
};
</script>