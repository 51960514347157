<template>
	<div class="footer">
		<div class="footer-left">
			<text-component class="light">Copyright © {{momentYear()}} VidUnit</text-component>
		</div>
		<div class="footer-right">
      <!-- <text-component class="light">Made by Magneto IT Solutions</text-component> -->
		</div>
	</div>
</template>

<script>
import TextComponent from '../BasicComponents/Text/Text.vue';
import moment from "moment";
export default {
  components: {
    TextComponent
  },
  data() {
    return {
      footerhelptext: "Help",
      footerprivacytext: "Privacy",
      footertermstext: "Terms"
    }
  },
  methods: {
      momentYear(){
          return moment(new Date()).format('YYYY')
      }
  },
}
</script>