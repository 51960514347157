<template>
  <div class="bankdetail boxmargin">
    <form @submit.prevent="handleBanakdetails">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Bank Details</text-component>
            <div v-if="!IsBankDetailEditable" class="myprofile-header-right">
              <button-component 
                @onBtnClick="bankDetailToggle" 
                :clsname="basicclass">
                <div v-if="isBankdetailadded.length <= 0">
                  Add
                </div>
                <div v-else>
                  Edit
                </div>
              </button-component>
            </div>
          </div>
          <div v-if="!IsBankDetailEditable" class="bankdetail-saved">
            <div v-if="isBankdetailadded.length <= 0">
              <p style="text-align: center;">Currently no bank accounts are Added</p>
            </div>
            <div v-else class="row margino">
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Full Account Name</text-component>
                  <text-component :class="black">{{request.account_name}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Account Number</text-component>
                  <text-component :class="black">{{hideaccnumber()}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Bank Name</text-component>
                  <text-component :class="black">{{request.bank_name}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">IFSC Code</text-component>
                  <text-component :class="black">{{request.ifsc_code}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Contact Number</text-component>
                  <text-component :class="black">{{request.contact}}</text-component>
                </div>
              </div>
            </div>
          </div>
          <div v-if="IsBankDetailEditable" class="bankdetail-editable">
            <div class="row edit-detail">
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.fullaccname"
                :helptext="!$v.item.fullaccname.required && submitted ? validationmessage.fullaccname: false"
                :labelclass="{'validationerr': !$v.item.fullaccname.required && submitted}"
                :classname="{'error': !$v.item.fullaccname.required && submitted, 'default': true}"
              >Full Account Name</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.accnumber"
                :helptext="!$v.item.accnumber.required && submitted ? validationmessage.accnumber: false"
                :labelclass="{'validationerr': !$v.item.accnumber.required && submitted}"
                :classname="{'error': !$v.item.accnumber.required && submitted, 'default': true}"
              >Account Number</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.bankname"
                :helptext="!$v.item.bankname.required && submitted ? validationmessage.bankname: false"
                :labelclass="{'validationerr': !$v.item.bankname.required && submitted}"
                :classname="{'error': !$v.item.bankname.required && submitted, 'default': true}"
              >Bank Name</input-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.ifsccode"
                :helptext="!$v.item.ifsccode.required && submitted ? validationmessage.ifsccode: false"
                :labelclass="{'validationerr': !$v.item.ifsccode.required && submitted}"
                :classname="{'error': !$v.item.ifsccode.required && submitted, 'default': true}"
              >IFSC Code</input-component>
              <div class="txtgroup phnumberbox col-12 col-sm-6 col-md-4">
                <input-component
                  required="true"
                  :type="phnumberboxtype"
                  v-model="item.phonenumber"
                  :helptext="(!$v.item.phonenumber.required || !$v.item.phonenumber.minLength || !$v.item.phonenumber.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.phonenumber: false"
                  :labelclass="{'validationerr': (!$v.item.phonenumber.required || !$v.item.phonenumber.minLength || !$v.item.phonenumber.maxLength || !$v.item.mobile_prefix.required) && submitted}"
                  :classname="{'error': (!$v.item.phonenumber.required || !$v.item.phonenumber.minLength || !$v.item.phonenumber.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
                >Contact Number</input-component>
                <div class="countrycode">
                  <dropdown-component
                    :popoverList="countrylist"
                    v-model="item.mobile_prefix"
                    :btnid="btnid"
                  >
                    +91
                    <!-- <img src="../../../assets/image/globe.svg" class="globeicon" /> -->
                  </dropdown-component>
                </div>
              </div>
            </div>
            <hr class="hrline-footer" />
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";
import { mapGetters, mapActions } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    DropdownComponent,
  },
  validations: {
    item: {
      fullaccname: { required },
      accnumber: { required },
      phonenumber: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(13),
      },
      mobile_prefix: { required },
      bankname: { required },
      ifsccode: { required },
    },
  },
  computed: {
    ...mapGetters("influencerprofile", ["bankData"]),
  },
  data() {
    return {
      request: {},
      item: {
        fullaccname: null,
        accnumber: null,
        bankname: null,
        ifsccode: null,
        mobile_prefix: '+91',
        phonenumber: null,
      },
      validationmessage: {
        fullaccname: "full account name is required",
        accnumber: "account number is required",
        bankname: "bank name is required",
        ifsccode: "ifsc code is required",
        phonenumber: "please enter valid phone number with prefix",
      },
      submitted: false,
      isBankdetailadded: [],
      classname: "default",
      IsBankDetailEditable: false,
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      phnumberboxtype: "number",
      btnid: "bankcountrybtn",
      countrylist: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" },
      ],
      bankdata: [
        {
          title: "Full Account Name",
          value: "Jacqueline Hart",
        },
        {
          title: "Account Number",
          value: "••••••••••••9854",
        },
        {
          title: "Bank Name",
          value: "ICICI Bank",
        },
        {
          title: "IFSC Code",
          value: "ICICI12548765",
        },
        {
          title: "Contact Number",
          value: "+91 98542 12587",
        },
      ],
    };
  },
  watch: {
    bankData: {
      immediate: true,
      handler(bankData) {
        if (bankData) {
          this.isBankdetailadded = bankData
          this.$data.request = bankData;
          this.item.fullaccname = bankData.account_name;
          this.item.accnumber = bankData.account_number;
          this.item.bankname = bankData.bank_name;
          this.item.ifsccode = bankData.ifsc_code;
          if(bankData.prefix){
            this.item.mobile_prefix = bankData.prefix;
          }
          else{
            this.item.mobile_prefix = '+91';
          }
          this.item.phonenumber = bankData.contact;
        } else {
          this.isBankdetailadded = [];
        }
      },
    },
  },
  created() {
    this.getbankdetail();
  },
  methods: {
    ...mapActions("influencerprofile", ["bankdetail", "getbankdetail"]),
    ...mapActions("commonfunction", ["getuserpercentage"]),

    hideaccnumber() {
      try {
        var trailingCharsIntactCount = 4;
        let str = this.request.account_number;
        str =
          new Array(str.length - trailingCharsIntactCount + 1).join("x") +
          str.slice(-trailingCharsIntactCount);
        return str;
      } catch (error) {
        console.log(error)
      }
    },

    bankDetailToggle() {
      if (this.IsBankDetailEditable == false) {
        this.IsBankDetailEditable = true;
      } else {
        this.IsBankDetailEditable = false;
      }
    },

    handleBanakdetails() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.bankdetail({
          account_name: this.item.fullaccname,
          account_number: this.item.accnumber,
          bank_name: this.item.bankname,
          ifsc_code: this.item.ifsccode,
          prefix: this.item.mobile_prefix,
          contact: this.item.phonenumber,
        })
          .then((response) => {
            console.log(response);
            vm.getbankdetail();
            this.IsBankDetailEditable = false;
            this.getuserpercentage()
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
            console.log("checking");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },
  },
};
</script>