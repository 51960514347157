<template>
  <div>
    <div :class="{'loader-spin':true,'active':active}">
      <img src="../../assets/image/loader.gif" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // ...mapGetters("adminbrandaction", ["loading"]),
    // ...mapGetters("admininfluenceraction", ["loading"]),
    ...mapGetters("commonfunction", ["loading","showLoader"]),
  },
  watch: {
    loading: {
      immediate: true,
      handler(loading) {
        // this.active = loading;
      },
    },
    
    showLoader: {
      immediate: true,
      handler(showLoader) {
        this.active = showLoader;
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>
<style scoped>
.loader-spin {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 999;
  text-align: center;
  display: none;
}
.loader-spin.active {
  display: block;
}
</style>