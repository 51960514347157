<template>
    <div class="myconsent influencer-role">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">My Consent</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <div class="box-content">
                    <header-tabs
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'List'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter"
                                    ></search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :columns="columns"
                                    :rows="rows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page"
                                ></datatable-component>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data approved" v-if="activetab === 'Approved'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter"
                                    ></search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :columns="acceptrejectcolumns"
                                    :rows="approvedrows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page"
                                ></datatable-component>
                            </div>
                        </div>
                        <div class="tag-bar-bottom-data rejected" v-if="activetab === 'Rejected'">
                            <div class="datatable-main">
                                <div class="data-table-header">
                                    <div class="data-table-header-inner">
                                    <search-bar
                                        class="header-searchbar"
                                        :classname="classname"
                                        :type="type"
                                        :inputPlaceholder="inputPlaceholder"
                                        v-model="filter"
                                    ></search-bar>
                                    </div>
                                </div>
                                <datatable-component
                                    class="dtable-main"
                                    :selected-rows="selectedRows"
                                    :columns="acceptrejectcolumns"
                                    :rows="rejectedrows"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    :classname="classname"
                                    :filter="filter"
                                    :page="page"
                                    :per_page="per_page"
                                ></datatable-component>
                            </div>
                        </div>
                    </header-tabs>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import NavigationComponent from "../Navigation/Navigation";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./actionbtnconsent.vue";
import StatusBadge from "./statusbadge.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import { eventBus } from "../../../main";
// import MaincategoryDropdown from "../../../BasicComponents/Dropdown/dropdown.vue";
import { mapGetters, mapActions } from "vuex";
// import { eventBus } from "../../main";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    searchBar,
    HeaderTabs
    // MaincategoryDropdown
  },
  computed: {
    ...mapGetters("influencerconsent", ["consentData"])
  },
  watch: {
    consentData: {
      immediate: true,
      handler(consentData) {
        this.$data.rows = consentData;
      }
    }
  },
  data() {
        return {
            type: "text",
            inputPlaceholder: "Search Campaign Name",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            h2txtclass: "h2",
            activetab: "List",
            maxLimit: 9,
            isHiddenbar: false,
            isMobileMenu: false,
            page: 1,
            per_page: 6,
            selectedRows: [],
            tabdata: [
                { id: "1", value: "List" },
                { id: "2", value: "Approved" },
                { id: "3", value: "Rejected" },
            ],
            columns: [
                {
                    label: "Campaign Name",
                    field: "campaign_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Description",
                    field: "discription",
                    align: "left",
                    headerAlign: "left",
                    sortable: false
                },
                { label: "Status", headerAlign: "left", align: "right", component: StatusBadge },
                { label: "Action", headerAlign: "left", align: "right", component: ActionBtn }
            ],
            acceptrejectcolumns: [
                {
                    label: "Campaign Name",
                    field: "campaigns.campaign_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Description",
                    field: "campaigns.discription",
                    align: "left",
                    headerAlign: "left",
                    sortable: false
                },
            ],
            rows: [],
            approvedrows: [],
            rejectedrows: [],
            dropCatList: [
                { id: "0", value: "Active" },
                { id: "1", value: "Inactive" }
            ]
        };
  },
  created() {
    this.getconsentlist();
    this.getApproved();
    this.getRejected();

    eventBus.$on(
      "changeActivetab",
      function(selected) {
          // console.log(selected);
          this.activetab = selected;
      }.bind(this)
    );
  },
  methods: {
    ...mapActions("influencerconsent", ["getconsentlist", "getapprovedconsentlist", "getrejectedconsentlist"]),

    getApproved() {
      this.getapprovedconsentlist()
      .then((response) => {
        this.approvedrows = response.data;
        console.log(this.influenceStatus);
      });
    },

    getRejected() {
      this.getrejectedconsentlist()
      .then((response) => {
        this.rejectedrows = response.data;
        console.log(this.influenceStatus);
      });
    },
  }
};
</script>
