<template>
    <div class="notificationpage">
        <div class="box margino">
            <div class="box-header interest-title-header">
                <text-component :class="'subtitle1'">Interest/Genres</text-component>
            </div>
            <div class="box-content">
                <div class="interestdetail-saved">
                    <div v-if="isInterestdata.length <= 0">
                        <p style="text-align: center;">Currently no interest/genres are Added</p>
                    </div>
                    <div v-else class="row margino">
                        <div
                            class="profile-detail-txt col-xl-2 col-md-4 col-sm-6"
                            v-for="(idata, index) in interestdatastring"
                            :key="index"
                            >
                            <div class="row">
                                <text-component>{{idata.value}}</text-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TextComponent,
  },
  computed: {
    ...mapGetters("influencerprofile", ["interestData", "interestListing"]),
  },
  data() {
    return {
      tag: "",
      tags: [],
      listingdata: {},
      selectedval: [],
      isInterestdata: [],
      IsInterestDetailEditable: false,
      classname: "default",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      other: "",
      interestedit: [],
      interestdatastring: [],
    };
  },
  watch: {
    interestData: {
      immediate: true,
      deep: true,
      handler(interestData) {
        this.isInterestdata = interestData
        if(interestData) {
          this.$data.interestdatastring = this.getuserinterest(interestData);
          if (interestData.other_interest != null) {
            this.tags = this.setedittags(interestData.other_interest);
          }
          if (interestData.interest) {
            this.selectedval = interestData.interest.split(",");
            this.$data.interestdatastring = this.getuserinterest(interestData);
          }
        }else {
          this.isInterestdata = [];
        }
      },
    },

    interestListing: {
      immediate: true,
      handler(interestListing) {
        // console.log("interest listing:", interestListing);
        this.listingdata = interestListing;
        this.$data.interestedit = this.getlisting(interestListing);
      },
    },
  },
  created() {
    this.getinterests();
    this.getinterestlisting();
  },
  methods: {
    ...mapActions("influencerprofile", [
      "getinterests",
      "getinterestlisting",
    ]),
    ...mapActions("commonfunction", ["getuserpercentage"]),

    setedittags(data) {
      // "text": "dcs", "tiClasses": [ "ti-valid" ] }
      let newarray = [];
      if (data) {
        data.map((value) => {
          newarray.push({
            text: value,
            tiClasses: ["ti-valid"],
          });
        });
        return newarray;
      } else {
        return newarray;
      }
    },

    getuserinterest(ids) {
      let valuearray = [];
      if (ids.other_interest != null) {
        ids.other_interest.map((value) => {
          valuearray.push({ value: value });
        });
      }
      if (ids.interest) {
        let tagids = ids.interest.split(",");
        // console.log("arrayid:", ids);
        for (let id in tagids) {
          // console.log("selectedid:", tagids[id])
          for (let data in this.listingdata) {
            // console.log("listdata:", this.listingdata[data].id);
            if (tagids[id] == this.listingdata[data].id) {
              valuearray.push({
                value: this.listingdata[data].interest,
              });
            }
          }
        }
      }
      return valuearray;
    },

    interestDetailToggle() {
      if (this.IsInterestDetailEditable == false) {
        this.IsInterestDetailEditable = true;
      } else {
        this.IsInterestDetailEditable = false;
      }
    },

    getlisting(data) {
      let newarray = [];
      data.map((value) => {
        newarray.push({
          id: value.id,
          checkboxname: value.interest,
        });
      });
      return newarray;
    },
  },
};
</script>