<template>
  <div class="manage manage-category">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">Manage Category</text-component>
        <div class="page-header-right" v-if="checkPermission('categories_create')">
          <button-component @onBtnClick="addCategory" :clsname="btninvitecls">Add Category</button-component>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box paddingo margino">
        <div class="box-content">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :actionid="actionid"
              :columns="columns"
              :cboxindeterminatecls="cboxindeterminatecls"
              :actionText="actionText"
              :rows="rows"
              :clsname="clsname"
              :btnText="btnText"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :dropBtn="dropBtn"
              :dropdownText="dropdownText"
              :statusClear="statusClear"
              :clearClass="clearClass"
              :filter="filter"
              :page="page"
              :per_page="per_page"
              @onrowenter="onEnter"
              @onrowleave="onLeave"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isAddCatrgory}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="addCategory"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Add Category</text-component>
        </div>
        <div class="row margin-top">
          <!-- <input-component
                        class="txtgroup col-12"
                        :classname="txtclassname"
                        v-model="categoryid">
                        Category ID
          </input-component>-->
          <input-component
            required="true"
            class="txtgroup col-12"
            :helptext="!$v.item.category.required && submitted ? validationmessage.category: false"
            :labelclass="{'validationerr': !$v.item.category.required && submitted}"
            :classname="{'error': !$v.item.category.required && submitted, 'default': true}"
            v-model="item.category"
          >Category Name</input-component>
          <!-- <select-component
                        class="txtgroup col-12"
                        :options="sectorindustry"
                        v-model="sector"
                        :placeholder="selectplaceholder"
                        :multiple="false">
                        Sector/Industry
          </select-component>-->
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="handleAddCategory" :clsname="btninvitecls">Add</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isEditCatrgory}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="editCategory"
            src="../../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <div class="header">
          <text-component :class="h2txtclass">Update Category</text-component>
        </div>
        <div class="row margin-top">
          <!-- <input-component
                        class="txtgroup col-12"
                        :classname="txtclassname"
                        v-model="categoryid">
                        Category ID
          </input-component>-->
          <input-component
            required="true"
            class="txtgroup col-12"
            :helptext="!$v.edit.editcategory.required && editsubmitted ? validationmessage.editcategory: false"
            :labelclass="{'validationerr': !$v.edit.editcategory.required && editsubmitted}"
            :classname="{'error': !$v.edit.editcategory.required && editsubmitted, 'default': true}"
            v-model="edit.editcategory"
          >Category Name</input-component>
          <!-- <select-component
                        class="txtgroup col-12"
                        :options="sectorindustry"
                        v-model="sector"
                        :placeholder="selectplaceholder"
                        :multiple="false">
                        Sector/Industry
          </select-component>-->
        </div>
        <hr class="hrline" />
        <div class="modal-footer">
          <div>
            <button-component @onBtnClick="updateCategory" :clsname="btninvitecls">Update</button-component>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>


<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./actiondelete.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import EditComponent from "./editcategory.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
// import router from "../../../../routes/routes";
// import SelectComponent from "../../../../BasicComponents/select/select.vue";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    searchBar,
    InputComponent,
    // SelectComponent,
    ButtonComponent
  },
  computed: {
    ...mapGetters("admincategory", ["categoryData"])
  },
  watch: {
    categoryData: {
      immediate: true,
      handler(categoryData) {
        this.$data.rows = categoryData;
      }
    },
  },
  created() {
    if(!this.checkPermission('categories_delete')){
        this.columns.splice((this.columns.length-1), 1)
    }
    this.$root.$on("getEditData", row => {
      if (this.isEditCatrgory == true) {
        this.isEditCatrgory = false;
      } else {
        this.isEditCatrgory = true;
        // console.log(row)
        this.rowid = row.id;
        let id = row.id;
        this.getsinglecategory({ id: id }).then(response => {
          this.edit.editcategory = response.data.name;
        });
      }
    });

    this.getcategories();
  },
  beforeDestroy() {
    this.$root.$off("getEditData", this.fn);
  },
  validations: {
    item: {
      category: { required }
    },
    edit: {
      editcategory: { required }
    }
  },
  data() {
    return {
      item: {
        category: null
      },
      edit: {
        editcategory: null
      },
      validationmessage: {
        category: "category name is required",
        editcategory: "category name is required"
      },
      submitted: false,
      editsubmitted: false,
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      selectplaceholder: "Select...",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      txtclassname: "default",
      btninvitecls: "btnprimary",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      category: "",
      h2txtclass: "h2",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      isAddCatrgory: false,
      isEditCatrgory: false,
      sector: "",
      categoryname: "",
      categoryid: "",
      page: 1,
      per_page: 8,
      selectedRows: [],
      columns: [
        {
          label: "Category ID",
          field: "category_id",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class width-15",
          sortable: false
        },
        {
          label: "Category Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          sortable: false
        },
        {
          label: "",
          headerClass: "width-5",
          component: EditComponent,
          align: "right"
        },
        {
          label: "Action",
          headerAlign: "right",
          align: "right",
          headerClass: "width-5",
          component: ActionBtn
        }
      ],
      rows: [
        //...
        {
          id: "1",
          categoryid: "CI54001",
          categoryname: "Category 1",
          sectorindustry: "Health & Fitness"
        },
        {
          id: "2",
          categoryid: "CI54002",
          categoryname: "Category 2",
          sectorindustry: "Travelling"
        },
        {
          id: "3",
          categoryid: "CI54003",
          categoryname: "Category 3",
          sectorindustry: "Fashion"
        },
        {
          id: "4",
          categoryid: "CI54004",
          categoryname: "Category 4",
          sectorindustry: "Personal Vlog"
        }

        //...
      ],
      dropCatList: [
        { id: "0", value: "Active" },
        { id: "1", value: "Inactive" }
      ],
      sectorindustry: [
        {
          id: "1",
          label: "Male"
        },
        {
          id: "2",
          label: "Female"
        },
        {
          id: "3",
          label: "Others"
        }
      ]
    };
  },
  methods: {
    ...mapActions("admincategory", [
      "createcategory",
      "updatecategory",
      "getcategories",
      "getsinglecategory"
    ]),

    addCategory() {
      if (this.isAddCatrgory == false) {
        this.isAddCatrgory = true;
      } else {
        this.isAddCatrgory = false;
      }
    },

    handleAddCategory() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.createcategory({
          name: this.item.category
        })
          .then(response => {
            console.log(response);
            vm.getcategories();
            this.isAddCatrgory = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    updateCategory() {
      let vm = this;
      this.editsubmitted = true;
      if (this.$v.edit.$invalid) {
        this.$v.edit.$touch();
      } else {
        this.updatecategory({
          name: this.edit.editcategory,
          id: this.rowid
        })
          .then(response => {
            console.log(response);
            vm.getcategories();
            this.isEditCatrgory = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    onEnter(row) {
      // console.log(row)
      row.editable = true;
    },
    onLeave(row) {
      // console.log(row)
      row.editable = false;
    },

    editCategory() {
      if (this.isEditCatrgory == true) {
        this.isEditCatrgory = false;
      } else {
        this.isEditCatrgory = true;
      }
    }
  }
};
</script>
