<template>
    <div class="aboutus-edit">
        <div class="template-accordion">
            <div class="tabs">
                <div class="tab">
                    <input type="checkbox" id="chck1">
                    <label class="tab-label" for="chck1">Section 1 (Roles in VidUnit)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section1_title"
                            required="true"
                            :helptext="!$v.contentData.section1_title.required && submitted ? validationmessage.section1_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section1_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section1_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <!-- <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section1_subtitle"
                            required="true"
                            :helptext="!$v.contentData.section1_subtitle.required && submitted ? validationmessage.section1_subtitle: false"
                            :labelclass="{'validationerr': !$v.contentData.section1_subtitle.required && submitted}"
                            :classname="{'error': !$v.contentData.section1_subtitle.required && submitted, 'default': true}">
                            Sub Title
                        </input-component> -->
                        <profile-uploader
                            :imageurl="getPreview('section1_image')"
                            fileuploadid="section1_image"
                            @selectfile="getfiledata($event,'section1_image')"
                            :avatar="avatar.section1_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                        <div class="row">
                            <div class="col-12 col-md-6 col-xl-4">
                                <fieldset>
                                    <legend>Section1_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section1_1_subtitle.required && submitted ? validationmessage.section1_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section1_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section1_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section1_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section1_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section1_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section1_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                                <fieldset>
                                    <legend>Section1_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section1_2_subtitle.required && submitted ? validationmessage.section1_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section1_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section1_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section1_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section1_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section1_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section1_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                                <fieldset>
                                    <legend>Section1_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section1_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section1_3_subtitle.required && submitted ? validationmessage.section1_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section1_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section1_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section1_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section1_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section1_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section1_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck2">
                    <label class="tab-label" for="chck2">Section 2 (About VidUnit)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section2_title"
                            required="true"
                            :helptext="!$v.contentData.section2_title.required && submitted ? validationmessage.section2_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section2_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section2_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section2_subtitle"
                            required="true"
                            :classname="{'default': true}">
                            Sub Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section2_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section2_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section2_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section2_description}}</span>
                        </div>
                        <!-- <profile-uploader
                            :imageurl="getPreview('section2_image')"
                            fileuploadid="section2_image"
                            @selectfile="getfiledata($event,'section2_image')"
                            :avatar="avatar.section2_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader> -->
                        <br>
                        <text-component>Social Media Links</text-component>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section2_social_facebook"
                                    required="true"
                                    :helptext="false"
                                    :classname="{'default': true}">
                                    Facebook Link
                                </input-component>
                            </div>
                            <div class="col-12 col-md-6">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section2_social_youtube"
                                    required="true"
                                    :helptext="false"
                                    :classname="{'default': true}">
                                    Youtube Link
                                </input-component>
                            </div>
                            <div class="col-12 col-md-6">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section2_social_instagram"
                                    required="true"
                                    :helptext="false"
                                    :classname="{'default': true}">
                                    Instagram Link
                                </input-component>
                            </div>
                            <div class="col-12 col-md-6">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section2_social_twitter"
                                    required="true"
                                    :helptext="false"
                                    :classname="{'default': true}">
                                    Twitter Link
                                </input-component>
                            </div>
                        </div>
                        <br><br>
                        <text-component>Brand Countings</text-component>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_1_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_1_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_1_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_1_1_image')"
                                        fileuploadid="section2_1_1_image"
                                        @selectfile="getfiledata($event,'section2_1_1_image')"
                                        :avatar="avatar.section2_1_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_1_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_2_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_2_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_1_2_image')"
                                        fileuploadid="section2_1_2_image"
                                        @selectfile="getfiledata($event,'section2_1_2_image')"
                                        :avatar="avatar.section2_1_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_1_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_3_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_3_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_1_3_image')"
                                        fileuploadid="section2_1_3_image"
                                        @selectfile="getfiledata($event,'section2_1_3_image')"
                                        :avatar="avatar.section2_1_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_1_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_4_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_4_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_1_4_image')"
                                        fileuploadid="section2_1_4_image"
                                        @selectfile="getfiledata($event,'section2_1_4_image')"
                                        :avatar="avatar.section2_1_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                        <br><br>
                        <text-component>Publisher Countings</text-component>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_2_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_1_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_1_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_2_1_image')"
                                        fileuploadid="section2_2_1_image"
                                        @selectfile="getfiledata($event,'section2_2_1_image')"
                                        :avatar="avatar.section2_2_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_2_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_2_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_2_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_2_2_image')"
                                        fileuploadid="section2_2_2_image"
                                        @selectfile="getfiledata($event,'section2_2_2_image')"
                                        :avatar="avatar.section2_2_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_2_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_3_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_3_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_2_3_image')"
                                        fileuploadid="section2_2_3_image"
                                        @selectfile="getfiledata($event,'section2_2_3_image')"
                                        :avatar="avatar.section2_2_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_2_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_4_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_4_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_2_4_image')"
                                        fileuploadid="section2_2_4_image"
                                        @selectfile="getfiledata($event,'section2_2_4_image')"
                                        :avatar="avatar.section2_2_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                        <br><br>
                        <text-component>Influencer Countings</text-component>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_3_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_1_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_1_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_3_1_image')"
                                        fileuploadid="section2_3_1_image"
                                        @selectfile="getfiledata($event,'section2_3_1_image')"
                                        :avatar="avatar.section2_3_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_3_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_2_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_2_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_3_2_image')"
                                        fileuploadid="section2_3_2_image"
                                        @selectfile="getfiledata($event,'section2_3_2_image')"
                                        :avatar="avatar.section2_3_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_3_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_3_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_3_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_3_3_image')"
                                        fileuploadid="section2_3_3_image"
                                        @selectfile="getfiledata($event,'section2_3_3_image')"
                                        :avatar="avatar.section2_3_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-3">
                                <fieldset>
                                    <legend>Section2_3_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_4_title"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_4_counts"
                                        required="true"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Counts
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_3_4_image')"
                                        fileuploadid="section2_3_4_image"
                                        @selectfile="getfiledata($event,'section2_3_4_image')"
                                        :avatar="avatar.section2_3_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck3">
                    <label class="tab-label" for="chck3">Section 3 (How It Works - Influencer)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section3_title"
                            required="true"
                            :helptext="!$v.contentData.section3_title.required && submitted ? validationmessage.section3_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section3_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section3_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section3_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section3_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section3_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section3_description}}</span>
                        </div>
                        <profile-uploader
                            :imageurl="getPreview('section3_image')"
                            fileuploadid="section3_image"
                            @selectfile="getfiledata($event,'section3_image')"
                            :avatar="avatar.section3_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_1_subtitle.required && submitted ? validationmessage.section3_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_2_subtitle.required && submitted ? validationmessage.section3_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_3_subtitle.required && submitted ? validationmessage.section3_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section3_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_4_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section3_4_subtitle.required && submitted ? validationmessage.section3_4_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section3_4_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section3_4_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section3_4_description"
                                            required="true"
                                            :helptext="!$v.contentData.section3_4_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section3_4_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section3_4_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="chck4">
                    <label class="tab-label" for="chck4">Section 4 (How It Works - Brand)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section4_title"
                            required="true"
                            :helptext="!$v.contentData.section4_title.required && submitted ? validationmessage.section4_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section4_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section4_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <!-- <sup>*</sup> -->
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section4_description" 
                                :config="editorConfig">
                            </ckeditor>
                            <!-- <span
                                v-if="!$v.contentData.section4_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section4_description}}</span> -->
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_1_subtitle.required && submitted ? validationmessage.section4_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_1_image')"
                                        fileuploadid="section4_1_image"
                                        @selectfile="getfiledata($event,'section4_1_image')"
                                        :avatar="avatar.section4_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_2_subtitle.required && submitted ? validationmessage.section4_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_2_image')"
                                        fileuploadid="section4_2_image"
                                        @selectfile="getfiledata($event,'section4_2_image')"
                                        :avatar="avatar.section4_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_3_subtitle.required && submitted ? validationmessage.section4_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_3_image')"
                                        fileuploadid="section4_3_image"
                                        @selectfile="getfiledata($event,'section4_3_image')"
                                        :avatar="avatar.section4_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <legend>Section4_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section4_4_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section4_4_subtitle.required && submitted ? validationmessage.section4_4_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section4_4_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section4_4_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section4_4_description"
                                            required="true"
                                            :helptext="!$v.contentData.section4_4_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section4_4_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section4_4_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section4_4_image')"
                                        fileuploadid="section4_4_image"
                                        @selectfile="getfiledata($event,'section4_4_image')"
                                        :avatar="avatar.section4_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="homesection5">
                    <label class="tab-label" for="homesection5">Section 5 (Our Value Addition)</label>
                    <div class="tab-content">
                        <div class="row">
                            <div class="col-12">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section5_title"
                                    required="true"
                                    :helptext="!$v.contentData.section5_title.required && submitted ? validationmessage.section5_title: false"
                                    :labelclass="{'validationerr': !$v.contentData.section5_title.required && submitted}"
                                    :classname="{'error': !$v.contentData.section5_title.required && submitted, 'default': true}">
                                    Title
                                </input-component>
                                <div class="txtgroup">
                                    <div class="inputtxt">
                                        <text-component>
                                            Description
                                            <!-- <sup>*</sup> -->
                                        </text-component>
                                    </div>
                                    <ckeditor 
                                        v-model="contentData.section5_description" 
                                        :config="editorConfig">
                                    </ckeditor>
                                    <!-- <span
                                        v-if="!$v.contentData.section5_description.required && submitted"
                                        class="validationerr"
                                    >{{validationmessage.section5_description}}</span> -->
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Section5_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section5_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section5_1_subtitle.required && submitted ? validationmessage.section5_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section5_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section5_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section5_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section5_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section5_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section5_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section5_1_image')"
                                        fileuploadid="section5_1_image"
                                        @selectfile="getfiledata($event,'section5_1_image')"
                                        :avatar="avatar.section5_1_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Section5_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section5_2_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section5_2_subtitle.required && submitted ? validationmessage.section5_2_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section5_2_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section5_2_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section5_2_description"
                                            required="true"
                                            :helptext="!$v.contentData.section5_2_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section5_2_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section5_2_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section5_2_image')"
                                        fileuploadid="section5_2_image"
                                        @selectfile="getfiledata($event,'section5_2_image')"
                                        :avatar="avatar.section5_2_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Section5_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section5_3_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section5_3_subtitle.required && submitted ? validationmessage.section5_3_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section5_3_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section5_3_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section5_3_description"
                                            required="true"
                                            :helptext="!$v.contentData.section5_3_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section5_3_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section5_3_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section5_3_image')"
                                        fileuploadid="section5_3_image"
                                        @selectfile="getfiledata($event,'section5_3_image')"
                                        :avatar="avatar.section5_3_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Section5_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section5_4_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section5_4_subtitle.required && submitted ? validationmessage.section5_4_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section5_4_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section5_4_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section5_4_description"
                                            required="true"
                                            :helptext="!$v.contentData.section5_4_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section5_4_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section5_4_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section5_4_image')"
                                        fileuploadid="section5_4_image"
                                        @selectfile="getfiledata($event,'section5_4_image')"
                                        :avatar="avatar.section5_4_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6">
                                <fieldset>
                                    <legend>Section5_5</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section5_5_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section5_5_subtitle.required && submitted ? validationmessage.section5_5_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section5_5_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section5_5_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section5_5_description"
                                            required="true"
                                            :helptext="!$v.contentData.section5_5_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section5_5_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section5_5_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section5_5_image')"
                                        fileuploadid="section5_5_image"
                                        @selectfile="getfiledata($event,'section5_5_image')"
                                        :avatar="avatar.section5_5_image"
                                        :width="smallwidth"
                                        :height="smallheight"
                                        :camicon="smallcamicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="homesection6">
                    <label class="tab-label" for="homesection6">Section 6 (Grow @ publisher)</label>
                    <div class="tab-content">
                        <div class="row">
                            <div class="col-12">
                                <input-component
                                    class="txtgroup col-12"
                                    v-model="contentData.section6_title"
                                    required="true"
                                    :helptext="!$v.contentData.section6_title.required && submitted ? validationmessage.section6_title: false"
                                    :labelclass="{'validationerr': !$v.contentData.section6_title.required && submitted}"
                                    :classname="{'error': !$v.contentData.section6_title.required && submitted, 'default': true}">
                                    Title
                                </input-component>
                                <div class="txtgroup">
                                    <div class="inputtxt">
                                        <text-component>
                                            Description
                                            <sup>*</sup>
                                        </text-component>
                                    </div>
                                    <ckeditor 
                                        v-model="contentData.section6_description" 
                                        :config="editorConfig"
                                        :class="{'error': !$v.contentData.section6_description.required && submitted}">
                                    </ckeditor>
                                    <span
                                        v-if="!$v.contentData.section6_description.required && submitted"
                                        class="validationerr"
                                    >{{validationmessage.section6_description}}</span>
                                </div>
                                <profile-uploader
                                    :imageurl="getPreview('section6_image')"
                                    fileuploadid="section6_image"
                                    @selectfile="getfiledata($event,'section6_image')"
                                    :avatar="avatar.section6_image"
                                    :width="width"
                                    :height="height"
                                    :camicon="camicon"
                                ></profile-uploader>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import ProfileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";

export default {
    props: ["contentData", "imageData", "imageDataShow", "baseUrl","submitted"],
    components: {
      InputComponent,
      TextComponent,
      TextAreaComp,
      ProfileUploader
    },
    validations: {
        contentData: {
            section1_title: { required },
            // section1_subtitle: { required },
            section1_1_subtitle: { required },
            section1_1_description: { required },

            section1_2_subtitle: { required },
            section1_2_description: { required },

            section1_3_subtitle: { required },
            section1_3_description: { required },

            section2_title: { required },
            // section2_subtitle: { required },
            section2_description: { required },
            
            section3_title: { required },
            section3_description: { required },

            section3_1_subtitle: { required },
            section3_1_description: { required },

            section3_2_subtitle: { required },
            section3_2_description: { required },

            section3_3_subtitle: { required },
            section3_3_description: { required },

            section3_4_subtitle: { required },
            section3_4_description: { required },
            
            section4_title: { required },
            // section4_description: { required },
            
            section4_1_subtitle: { required },
            section4_1_description: { required },

            section4_2_subtitle: { required },
            section4_2_description: { required },

            section4_3_subtitle: { required },
            section4_3_description: { required },

            section4_4_subtitle: { required },
            section4_4_description: { required },

            section5_title: { required },
            // section5_description: { required },
            
            section5_1_subtitle: { required },
            section5_1_description: { required },

            section5_2_subtitle: { required },
            section5_2_description: { required },

            section5_3_subtitle: { required },
            section5_3_description: { required },

            section5_4_subtitle: { required },
            section5_4_description: { required },

            section5_5_subtitle: { required },
            section5_5_description: { required },

            section6_title: { required },
            section6_description: { required },
        },
    },
    data() {
        return {
            validationmessage: {
                section1_title: 'title is required',
                section1_subtitle: 'subtitle is required',

                section1_1_subtitle: "subtitle is required",
                section1_1_description: 'description is required',

                section1_2_subtitle: "subtitle is required",
                section1_2_description: 'description is required',

                section1_3_subtitle: 'description is required',
                section1_3_description: 'description is required',

                section2_title: 'title is required',
                section2_subtitle: 'subtitle is required',
                section2_description: 'description is required',
                
                section3_title: 'title is required',
                section3_description: 'description is required',
                
                section3_1_subtitle: "subtitle is required",
                section3_1_description: 'description is required',

                section3_2_subtitle: "subtitle is required",
                section3_2_description: 'description is required',

                section3_3_subtitle: 'description is required',
                section3_3_description: 'description is required',

                section3_4_subtitle: "subtitle is required",
                section3_4_description: 'description is required',

                section4_title: 'title is required',
                section4_description: 'description is required',
                
                section4_1_subtitle: "subtitle is required",
                section4_1_description: 'description is required',

                section4_2_subtitle: "subtitle is required",
                section4_2_description: 'description is required',

                section4_3_subtitle: 'description is required',
                section4_3_description: 'description is required',

                section4_4_subtitle: "subtitle is required",
                section4_4_description: 'description is required',

                section5_title: 'title is required',
                section5_description: 'description is required',
                
                section5_1_subtitle: "subtitle is required",
                section5_1_description: 'description is required',

                section5_2_subtitle: "subtitle is required",
                section5_2_description: 'description is required',

                section5_3_subtitle: 'description is required',
                section5_3_description: 'description is required',

                section5_4_subtitle: "subtitle is required",
                section5_4_description: 'description is required',

                section5_5_subtitle: "subtitle is required",
                section5_5_description: 'description is required',

                section6_title: 'title is required',
                section6_description: 'description is required',
            },
            // submitted: false,
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: {
                section1_image: null,
                section2_image: null,
                section2_1_1_image: null,
                section2_1_2_image: null,
                section2_1_3_image: null,
                section2_1_4_image: null,
                section2_2_1_image: null,
                section2_2_2_image: null,
                section2_2_3_image: null,
                section2_2_4_image: null,
                section2_3_1_image: null,
                section2_3_2_image: null,
                section2_3_3_image: null,
                section2_3_4_image: null,
                section3_image: null,
                section4_1_image: null,
                section4_2_image: null,
                section4_3_image: null,
                section4_4_image: null,
                section5_1_image: null,
                section5_2_image: null,
                section5_3_image: null,
                section5_4_image: null,
                section5_5_image: null,
                section6_image: null,
            },
            width: '100px',
            height: '100px',
            camicon: '-21px',
            smallwidth: '50px',
            smallheight: '50px',
            smallcamicon: '-16px',
            editorConfig: {}
        }
    },
    methods: {
        touch () {
            this.$v.contentData.$touch();
        },
        getfiledata(e,fileName) {
            console.log(fileName)
            let image = e.target.files[0];
            this.imageData.push({file:image,name:fileName})
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
            this.avatar[fileName] = e.target.result;
            };
        },
        getPreview(filename) {
            let returnIs = '';
            if (this.imageDataShow[filename]) {
                returnIs = this.baseUrl+'/'+this.imageDataShow[filename]
            }else{
                returnIs = this.imageurl;
            }
            return returnIs;
        }
    }

}
</script>
