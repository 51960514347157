<template>
    <div class="progressbar">
        <div id="points-progress" class="semicircle">
            <svg width="100%" height="100%" viewBox="0 0 100 53">
                <path id="placeholder" d="M 50,50 m -47,0 a 47,47 0 1 1 94,0" :stroke="lightColor" stroke-width="6" stroke-linecap="round" fill-opacity="0" style="opacity: 0.2"></path>           
                <path :stroke-dashoffset="getPercentage" id="pd-line" d="M 50,50 m -47,0 a 47,47 0 1 1 94,0" :stroke="darkColor" stroke-width="6" fill-opacity="0" stroke-linecap="round"  stroke-dasharray= "147.708, 147.708"></path>
            </svg>
            <div class="percentages">
                <span id="progress-percentage" class="h1">{{progress}}</span>
                <span class="m_minus5 h1">&nbsp;%</span>
            </div>
            <div class="bottom-0-100">
                <p class="subtitle2">0%</p>
                <p class="subtitle2">100%</p>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name : "progressbar",
    props :["progress"],
    data() {
        return{
            darkColor: '#F49342',
            lightColor: '#FFC58B'
        }
    },
    // methods: {
    //     getPercentage: function () {
    //         var strokeDasharray = 147.708;
    //         var pdLine = document.getElementById('pd-line'),
    //             progressPercentage = 100;


    //             var sdMinus = (strokeDasharray * progressPercentage) / 100;
    //             var strokeDashoffset = strokeDasharray - sdMinus;


    //             var pos = 147;

    //             var id = setInterval(frame, 2);
    //             console.log(id)

    //             function frame() {
    //                 console.log("POS: "+pos)
    //                 console.log("ID: "+id)
    //                 if (pos == Math.floor(strokeDashoffset)) {
    //                     clearInterval(id);
    //                     pdLine.setAttribute("stroke-dashoffset", strokeDashoffset)
    //                 } else {
    //                     pos--;
    //                     pdLine.setAttribute("stroke-dashoffset", pos)
    //                 }
    //             }
    //     }
    // },
    computed: {
        getPercentage: function () {
            var strokeDasharray = 147.708;
            var progressPercentage = this.progress;
            var dashoffset;


                var sdMinus = (strokeDasharray * progressPercentage) / 100;
                var strokeDashoffset = strokeDasharray - sdMinus;


                var pos = 147;

                // var id = setInterval(frame, 2);
                // function frame() {
                //     if (pos == Math.floor(strokeDashoffset)) {
                //         clearInterval(id);
                //         // pdLine.setAttribute("stroke-dashoffset", strokeDashoffset)
                //         dashoffset = strokeDashoffset;
                //     } else {
                //         pos--;
                //         // pdLine.setAttribute("stroke-dashoffset", pos)
                //         dashoffset = pos;
                //     }
                // }
                for (let i = pos; i >= 0; i--) {
                    if (i == Math.floor(strokeDashoffset)) {
                        // clearInterval(id);
                        // pdLine.setAttribute("stroke-dashoffset", strokeDashoffset)
                        dashoffset = strokeDashoffset;
                    } else {
                        // pos--;
                        // pdLine.setAttribute("stroke-dashoffset", pos)
                        dashoffset = strokeDashoffset;
                    }
                }
                    // console.log(dashoffset)
            return dashoffset;
        }
    }
}; 
</script>