<template>
  <div>
    <drop-button :clsname="clsname" @onBtnClick="showPop"><img src="../../assets/image/dropdown.svg" class="dropdown-image">
      <span :id="btnid"><slot/></span>
    </drop-button>
    <default-popover :popoverList="popoverList" @onLinkClick="selectVal" v-show="popModal" class="display-dropdown"></default-popover>
  </div>
</template>

<script>
import DefaultPopover from "../Popover/popover.vue";
import DropButton from "../Button/Button.vue";
export default {
  components: {
    DefaultPopover,
    DropButton
  },

  data() {
    return {
      popModal: false
    };
  },

  props: {
    popoverList: Array,
    clsname: String,
    btnid: String
  },

  created() {
    window.addEventListener("click", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },

  methods: {
    showPop: function() {
      if(this.popModal == false){
        this.popModal = true;
      }
      else{
        this.popModal = false;
      }
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.popModal = false;
      }
    },

    selectVal: function(value) {
        let selectedValue = value.target.text;
        // document.getElementById(this.btnid).innerText = selectedValue;
        this.$emit('input', selectedValue)
        this.popModal = false;
    }
  }
};
</script>