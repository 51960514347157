<template>
    <div class="box article-request">
        <div class="box-header">
            <span class="subtitle1">Article Request</span>
        </div>
        <div class="box-content">
            <div class="datatable-main">
                <div class="data-table-header">
                    <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter"
                    ></search-bar>
                    </div>
                </div>
                <datatable-component
                    class="dtable-main"
                    :selected-rows="selectedRows"
                    :columns="columns"
                    :rows="rows"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    :classname="classname"
                    :filter="filter"
                    :page="page"
                    :per_page="per_page"
                ></datatable-component>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isPreview}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="onPreview('cancle')" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <span class="subtitle1">Article Name :</span>&nbsp;&nbsp;&nbsp;
                    <span>{{popupdata.article_name}}</span><br/>
                    <span class="subtitle1">Content Data</span>
                    <div v-html="popupdata.content"></div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>
<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue"
import Action from "./articlerequestaction.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        DatatableComponent,
        searchBar,
        // ButtonComponent,
        // TextComponent,
        // InputComponent
    },
    data() {
        return {
            type: "text",
            inputPlaceholder: "Search Request Name",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            popupdata: [],
            createdarticle: null,
            isedit: false,
            currenteditid: null,
            isContentRequest: false,
            submitted: false,
            isPreview: false,
            columns: [
                {
                    label: "Topic",
                    field: "topic",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Description",
                    field: "description",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action", headerAlign: "left", align: "left", component: Action },
            ],
            rows: [],
        };
    },
    methods: {
        ...mapActions("publisherdata", ["requestedarticles","articlerequest", "articlerequestlist","requestedarticle","createdarticles"]),

        articlerequestlistdata() {
            this.articlerequestlist()
            .then(response=>{
                this.rows = response.data
            });
        },
        createdArticle() {
            this.createdarticles()
            .then(response=>{
                this.createdarticle = response.data
            });
        },

        onPreview(btn) {
            if(this.isPreview == false){
                this.isPreview = true
            }
            else{
                this.isPreview = false
            }
            if(btn == "cancle"){
                this.popupdata = []
            }
        },

    },
    created() {
        this.articlerequestlistdata()
        this.createdArticle()
        this.$root.$on("onrequestEdit", (row) => {
            this.isedit = true
            this.showEditPopup()
            this.item.topic = row.topic
            this.item.description = row.description
            this.currenteditid = row.id
        });
        this.$root.$on("adminRequestDeleteClick", (row) => {
            this.articlerequestlistdata()
        });

        this.$root.$on("showArticle", (row) => {
            this.requestedarticles({id: row.id})
            .then(response=>{
                this.popupdata = response.data
            });
            this.onPreview()
        });
    }
}
</script>