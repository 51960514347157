<template>
    <div>
        <section class="blog-banner">
            <img src="../../../assets/image/front/BlogHeroBanner.jpg">
            <div>
                <h1>Blogs</h1>
            </div>
        </section>

        <section class="listing-part">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-8 blog-detail-img" >
                        <h2 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">{{blogDetail.title}}</h2>

                        <div class="date-social" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p><span>By: {{blogDetail.author}}</span>|<span>{{formatDateGlobal(blogDetail.created_at)}}</span></p>
                            <div class="social">
                            <a href="">
                                <img src="../../../assets/image/front/pb.svg">
                            </a>
                            <a href="">
                                <img src="../../../assets/image/front/fbb.svg">
                            </a>
                            <a href="">
                                <img src="../../../assets/image/front/tb.svg">
                            </a>
                            <a href="">
                                <img src="../../../assets/image/front/lb.svg">
                            </a>
                            </div>
                        </div>
                        <img v-if="blogDetail.banner_image" :src="blogDetail.baseUrl+ '/' +blogDetail.banner_image" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-else src="../../../assets/image/front/BD1.png" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">

                        <div class="w648" v-html="blogDetail.description">
                            <!-- {{blogDetail.description}} -->



                            <!-- <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">86% of Fortune 500 companies report that communities provide insight into customer needs. These insights can impact product roadmaps, influence customer service guidelines, and help marketers understand their audience better.</p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Building a community around a topic relevant to your brand can be an extremely powerful tool that marketers can easily leverage. Within these communities influencers are often the ones leading the conversations and encouraging new members to join. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">If you are focused on building a community around your brand, then these are the steps you should be taking to integrate influencers into your strategy.</p>

                            <h4 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">1. Understand what motivates your community</h4>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">86% of Fortune 500 companies report that communities provide insight into customer needs. These insights can impact product roadmaps, influence customer service guidelines, and help marketers understand their audience better. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Building a community around a topic relevant to your brand can be an extremely powerful tool that marketers can easily leverage. Within these communities influencers are often the ones leading the conversations and encouraging new members to join. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">If you are focused on building a community around your brand, then these are the steps you should be taking to integrate influencers into your strategy.</p>

                            <h4 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">2. Host a chat with an influencer</h4>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Whether your community lives on Facebook, LinkedIn Groups or elsewhere, finding a way to congregate your community through a live chat via Zoom (or another video solution) can make a huge splash. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">A live event with an influencer will not only give you the opportunity to activate your community, but it will also bring value to the influencer, and get your community in front of their audience. Influencers want to grow their own communities. By offering them time directly in front of your community, your influencers get the opportunity to increase their following while adding value to yours. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">This is particularly important to keep in mind when building an ambassador program. Live events can be mutually beneficial when they are done correctly and are a great way to build your brand advocates organically.</p>

                            <img src="../../../assets/image/front/BD2.png" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">

                            <h4 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">3. Ask an influencer to host a product launch or community event</h4>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Partnering with an influencer to have them host a product launch or community event is a great way for community members to take their engagement to the next level. By asking an influencer to host this event, not only do you spark the interest of your community with the opportunity to hear from an influencer, but you also show social proof that this is an event they won’t want to miss. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Events like these also present a great opportunity for you to identify and build relationships with members of your community who have an impactful social footprint. If they are already involved in your community initiatives, chances are high they’ll be excited for the opportunity to help it grow.</p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Another great way to grow your community and event attendance is by inviting members to bring a friend or coworker to the event as well. The more the merrier!</p>

                            <h4 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">4. Master the art of cross-promotion</h4>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">There are dozens of ways to cross-promote with influencers. One way is appearing on each other’s podcasts and livestreams. You could also promote each other through your newsletter or other outreach initiative. This is a great way to grow your respective audiences by sharing information that is beneficial to both communities. </p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Another option is building some shared promotions into your contracts with influencers. This is especially useful when you spend the time to identify the right influencers to work with.</p>

                            <p data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Understanding how influencers can help social marketers engage with and build communities online is one use case for influencer marketing. From event planning to strategic product placement, influencers are the key to unlocking your brand's full potential.</p> -->

                            <div class="center-line"></div>

                            <div class="leave-reply-box" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <h6>Leave A Reply</h6>
                                <p>Your email address will not be published.</p>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <input type="text" placeholder="Your Name*" name="">
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <input type="text" placeholder="Your Email*" name="">
                                    </div>
                                    <div class="col-sm-12">
                                        <textarea placeholder="Your Comment"></textarea>
                                    </div>
                                    <div class="col-sm-12 text-right">
                                        <input type="submit" value="Post Comment" name="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 searching-part">
                        <div class="pl24">
                            <div class="search-box" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <button> <img src="../../../assets/image/front/Search.png"> </button>
                                <input @keyup="getSearchBlogData" v-model="searchtext" type="text" placeholder="Search posts" name="">
                            </div>
                            <h3 v-if="isrecentblog" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Recent Blog</h3>

                            <h3 v-else data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">Search Result</h3>

                            <div v-if="isrecentblog">
                                <div 
                                    class="search-list pointer" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-for="(latestblog, index) in latestblogs" :key="index"
                                    @click="toBlog(latestblog)">
                                    <span>{{latestblog.title}}</span>
                                </div>
                            </div>

                            <div style="position:relative" v-else>
                                <div v-if="isLoader" class="loadingspinner-outer">
                                    <div class="loadingspinner"></div>
                                </div>
                                
                                <div 
                                    class="search-list" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-for="(search, index) in searchblogdata" :key="index"
                                    @click="toBlog(search)">
                                    <span>{{search.title}}</span>
                                </div>
                                <div 
                                    class="search-list" 
                                    data-aos="zoom-in-down" 
                                    data-aos-once="true" 
                                    data-aos-duration="1000"
                                    v-if="searchblogdata == '' && !isLoader">
                                    <span>No Results found.!</span>
                                </div>
                            </div>
                            <!-- <div class="search-list" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href="">How To 4 Ways to Build a Community with Influencers</a>
                            </div>
                            <div class="search-list" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                                <a href="">How to Cultivate the Best Influencer Partnerships Based on Organic Love</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:null,
                meta_title:null,
                meta_keyword:null,
                meta_desc:null,
            },
            cms_field:[],
            blogDetail:{},
            latestblogs: [],
            searchtext: null,
            searchblogdata: [],
            isrecentblog: true,
            isLoader: false
        }
    },
    metaInfo() {
        return { 
            title: 'Blogs',
        }
    },
    created(){
        this.getData();
        this.getLatestBlogData()
    },
    methods:{
        ...mapActions("frontblog",["getBlogDetail"]),
        ...mapActions("blogdata",["getlatestblog", "searchblog"]),

        getData(){
            this.getBlogDetail({
                slug:this.$route.params.slug
            }).then(response=>{
                this.blogDetail = response.data;
            });
        },

        getLatestBlogData() {
            this.getlatestblog()
            .then(response=>{
                this.latestblogs = response.data
            });
        },

        toBlog(blog) {
            this.$router.push({name: "BlogDetail",params: { slug: blog.slug }});
            this.getData()
        },

        getSearchBlogData() {

            if(this.searchtext != ""){
                this.isrecentblog = false
            }
            else{
                this.isrecentblog = true
            }
            this.isLoader = true
            this.searchblog({string: this.searchtext})
            .then(response=>{
                this.searchblogdata = response.data
            }).then(response => {
                    this.isLoader = false
                })
                .catch(err => {
                    this.isLoader = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>

.loadingspinner-outer{
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
}

.loadingspinner {
    margin: 0 auto;
	pointer-events: none;
	width: 2.5em;
	height: 2.5em;
	border: 0.4em solid transparent;
	border-color: #eee;
	border-top-color: #F35F22;
	border-radius: 50%;
	animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
	100% {
			transform: rotate(360deg)
	}
}


</style>