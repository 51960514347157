<template>
  <form @submit.prevent="checkemail">
    <div class="forgot-page">
      <div class="row rowpadding">
        <div class="col-12 col-md-6 forgot-left">
          <text-component :textclass="h2class">Forgot password</text-component>
          <div class="row rowpadding noaccount">
            <text-component
              class="mid"
            >To reset your password, Enter the email address you use to sign in to VidUnit.</text-component>
          </div>
          <input-component
            required="true"
            class="txtgroup"
            :helptext="(!$v.item.email.required || !$v.item.email.email) && submitted ? validationmessage.email: false"
            :labelclass="{'validationerr': (!$v.item.email.required || !$v.item.email.email) && submitted}"
            :classname="{'error': (!$v.item.email.required || !$v.item.email.email) && submitted, 'default': true}"
            v-model="item.email"
          >Email Address</input-component>
          <button-component class="submitbtn" buttontype="submit" :clsname="btnregularclass">Submit</button-component>
          <span v-if="verified" class="mid reset-message">Reset link has been sent to your mail id</span><br>
          <text-component class="mid">Return to</text-component>
          <router-link to="login">
            <button-component class="btnlogin" :clsname="btnplainclass">Log in</button-component>
          </router-link>
        </div>
        <div class="col-6 forgot-right"></div>
      </div>
    </div>
  </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent
  },
  data() {
    return {
      item: {
        email: null
      },
      validationmessage: {
        email: "please enter valid email"
      },
      submitted: false,
      verified: false,
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnprimary"
    };
  },
  validations: {
    item: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    ...mapActions("user", ["verifyemail"]),

    checkemail() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        // let vm = this;
        this.verifyemail({
          email: this.item.email
        }).then(response => {
          this.verified = true;
          console.log(response.data);
          // let userid = response.data.id;
          // vm.$router.push({ name: "ResetPassword" });
          console.log("checking");
        });
      }
    }
  }
};
</script>