<template>
    <div>
        <admin-layout-main v-if="role_id==1">
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </admin-layout-main>
        <influencer-layout-main v-else-if="role_id==2">
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </influencer-layout-main>
        <agency-layout-main v-else-if="role_id==3">
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </agency-layout-main>
        <brand-layout-main v-else-if="role_id==4">
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </brand-layout-main>
        <publisher-layout-main v-else-if="role_id==5">
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </publisher-layout-main>
        <div v-else>
            <div class="col-12 text-center">
                <h1>404!</h1>
            </div>
        </div>
    </div>
</template>
<script>
import AdminLayoutMain from '../../layoutsadmin/main.vue';
import InfluencerLayoutMain from '../../layouts/main.vue';
import AgencyLayoutMain from '../../layoutsagency/main.vue';
import BrandLayoutMain from '../../layoutsbrand/main.vue';
import PublisherLayoutMain from '../../layoutspublisher/main.vue';

export default {
    components: {
        AdminLayoutMain,
        InfluencerLayoutMain,
        AgencyLayoutMain,
        BrandLayoutMain,
        PublisherLayoutMain,
    },
    data(){
        return {
            role_id:null
        }
    },
    created: function() {
        let auth = localStorage.getItem("auth");
        if (auth) {
            let authData = JSON.parse(auth);
            this.role_id = authData.role_id;
        }
    },
}
</script>