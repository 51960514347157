<template>
	<div>
		<div class="influencerreportdetail brand">
			<div class="content-header">
                <div class="page-header">
                    <text-component :class="h2txtclass">Influencer Report</text-component> 
                    <!-- <div class="page-header-right">
                        <button-component :clsname="btninvitecls">Generate Report</button-component>
                    </div> -->
                </div>
            </div>
            <div class="content">
                <div class="box margino">
                    <div class="box-content">
                        <div class="row margino">
                            <img src="../../../../assets/image/usericon.png" class="usericon" alt="usericon">
                            <text-component class="h4 username">Influencer Report</text-component>
                        </div>
                        <tab-component
                            class="tabs"
                            :tabdata="tabdata"
                            :activetab="activetab"
                            :maxLimit="maxLimit"
                            :isHiddenbar="isHiddenbar">
                            <div class="tag-bar-bottom-data" v-if="activetab === 'Instagram'">
                                <div class="row margino socialinsight-info">
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Followers</text-component>
                                            <text-component class="dark mid left-txt">27,501</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Active Audience</text-component>
                                            <text-component class="dark mid left-txt">19,781</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Campaign Post's Likes</text-component>
                                            <text-component class="dark mid left-txt">Fitness & Health</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Hashtag Appearance</text-component>
                                            <text-component class="dark mid left-txt">26,547</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Engagement Level</text-component>
                                            <text-component class="dark mid left-txt">High</text-component>
                                        </div>
                                    </div>
                                    <div class="profile-detail-txt col-12 col-xl-6">
                                        <div class="row margino">
                                            <text-component class="subtitle2 paddingo col-4">Rating</text-component>
                                            <rating-component :rating="rating"></rating-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tag-bar-bottom-data" v-if="activetab === 'Facebook'">
                                Facebook
                            </div>
                            <div class="tag-bar-bottom-data" v-if="activetab === 'Twitter'">
                                Twitter
                            </div>
                            <div class="tag-bar-bottom-data" v-if="activetab === 'Youtube'">
                                Youtube
                            </div>
                        </tab-component>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12 col-xl-6 paddingo graph-main graphalign-left">
                        <div class="box">
                            <div class="graph-txt">
                                <text-component class="subtitle1">Followers</text-component>
                            </div>
                            <graph-component
                                :width="width"
                                :height="height"
                                :axis-min="axismin"
                                :axis-max="axismax"
                                :axisStep="axisStep"
                                :labels="labels"
                                :values="values"
                                :barMargin="barMargin"
                                :names="names">
                            </graph-component>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 paddingo graph-main graphalign-right">
                        <div class="box">
                            <div class="graph-txt">
                                <text-component class="subtitle1">Engagement Insight</text-component>
                            </div>
                            <graph-component
                                :width="width"
                                :height="height"
                                :axis-min="axismin"
                                :axis-max="axismax"
                                :axisStep="axisStep"
                                :labels="labels"
                                :values="engagementvalues"
                                :barMargin="barMargin"
                                :names="engagementname">
                            </graph-component>
                        </div>
                    </div>
                </div> -->
            </div>
		</div>
	</div>
</template>


<script>

import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import TabComponent from "../../../../BasicComponents/Tabs/Tabs.vue";
import RatingComponent from "../../../../BasicComponents/rating/rating.vue";
import GraphComponent from "../../../../BasicComponents/graph/graph.vue";
import { eventBus } from "../../../../main";

export default {
  props: {},
  components: {
    TextComponent,
    // ButtonComponent,
    TabComponent,
    RatingComponent,
    // GraphComponent
  },
  data() {
    return {
        type: "text",
        inputPlaceholder: "Search Influencer",
        classname: "default prefixicon",
        dropBtn: "btnbasic",
        dropdownText: "Sub Category",
        statusClear: "Clear",
        clearClass: "btnplain",
        txtclassname: "default",
        btninvitecls: "btnprimary",
        dropCat: "btnbasic",
        dropCatText: "Main Category",
        statusid: "btnstatus",
        rating: 5,
        filter: "",
        selected: "",
        row: "",
        influencername: '',
        email: '',
        h2txtclass: 'h2',
        activetab: 'Instagram',
        maxLimit: 8,
        isHiddenbar: false,
        width: 540,
        height: 295,
        axismin: 0,
        axismax: 500,
        axisStep: 5,
        barMargin: 9,
        names: [ "Followers" ],
        engagementname: [ "Engagement" ],
        labels: [ '23 May', '24 May', '25 May', '26 May', '27 May', '28 May', '29 May', '30 May', ],
        values: [
            [ 200, 130, 100, 200, 180, 210, 60, 150 ]
        ],
        engagementvalues: [
            [ 20000, 18000, 22000, 16000, 8000, 23000, 27000, 22000 ]
        ],
        dropCatList: [
            { id: "0", value: "Active" },
            { id: "1", value: "Inactive" },
        ],
        tabdata: [
            { id: "1", value: "Instagram" },
            { id: "2", value: "Facebook" },
            { id: "3", value: "Twitter" },
            { id: "4", value: "Youtube" },
        ],
    };
  },
  created: function() {
    eventBus.$on(
      "changeActivetab",
      function(selected) {
        // console.log(selected);
        this.activetab = selected;
      }.bind(this)
    );
  }
};
</script>
