<template>
    <div class="myprofile brand">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">My Profile</text-component>
            </div>
        </div>
        <div class="content">
            <personal-detail></personal-detail>
            <change-password></change-password>
        </div>
    </div>
</template>
<script>
import PersonalDetail from './personaldetail';
import ChangePassword from './changepassword';
import TextComponent from "../../../BasicComponents/Text/Text.vue";
export default {
    components: {
        PersonalDetail,
        ChangePassword,
        TextComponent
    },
    data() {
        return{
            h2txtclass: 'h2',            
        }
    }
}
</script>