<template>
    <div class="action-image">
        <img src="../../../assets/image/delete.svg" @click="clickEvent" alt="delete-image" class="delete"/>
        <button-component @onBtnClick="goToDetail(row.id)" :clsname="btnplain">Details</button-component>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue"

export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      btnplain: 'btnplain'
    };
  },

  methods: {
    ...mapActions("brandcampaign",["deletecampaign", "getcampaign"]),

    clickEvent: function() {
      var deleterow = confirm("Are you sure?");
      if(deleterow){
        let vm = this;
        let id = [];
        id.push(this.row.id);
        this.deletecampaign({ ids: id })
          .then(response => {
            console.log(response);
            vm.getcampaign();
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    goToDetail(id){
      this.$router.push({name: "brandcampaigndetail",params: { id: id }});
    }
  }
};
</script>
