<template>
    <div class="campaign-detail-page">
        <div class="content-header">
            <text-component class="h2">Campaign Detail</text-component>
            <div class="page-header-right">
                <button-component 
                    @onBtnClick="onGenerateReport" 
                    :class="'btnprimary'"
                    v-if="activetab === 'Campaign Detail'">
                    Download Report
                </button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino paddingo">
                <!-- <div class="box-header">
                    
                </div> -->
                <div class="box-content">
                    <header-tabs
                        class="campaign-page-tabs"
                        :tabdata="tabdata"
                        :activetab="activetab"
                        :maxLimit="maxLimit"
                        :isHiddenbar="isHiddenbar">
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Campaign Detail'">
                            <campaign-detail></campaign-detail>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Suggested Influencer'">
                            <suggested-influencer></suggested-influencer>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'All Influencer'">
                           <all-influencer></all-influencer> 
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'In Process'">
                            <in-progress></in-progress>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Approved Influencer'">
                            <approved-influencer></approved-influencer>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Rejected Influencer'">
                            <rejected-influencer></rejected-influencer>
                        </div>
                        <div class="tag-bar-bottom-data" v-if="activetab === 'Concents'">
                            <conscents></conscents>
                        </div>
                         <div class="tag-bar-bottom-data" v-if="activetab === 'Ratings'">
                            <rating-page></rating-page>
                        </div>
                    </header-tabs>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import CampaignDetail from "./campaindetail.vue";
import SuggestedInfluencer from "./suggestedinfluencer.vue"
import AllInfluencer from "./allinfluencer.vue";
import InProgress from "./inprogress.vue";
import ApprovedInfluencer from "./approvedinfluencer.vue";
import RejectedInfluencer from "./rejectedinfluencer.vue";
import Conscents from "./concents.vue";
import RatingPage from "./ratingpage.vue";
import { eventBus } from "../../../../main";
import {mapActions} from 'vuex';
export default {
    components: {
        HeaderTabs,
        ButtonComponent,
        TextComponent,
        CampaignDetail,
        SuggestedInfluencer,
        AllInfluencer,
        InProgress,
        ApprovedInfluencer,
        RejectedInfluencer,
        Conscents,
        RatingPage
    },
    data() {
        return {
            campaignData: {},
            activetab: 'Campaign Detail',
            maxLimit: 9,
            isHiddenbar: false,
            tabdata: [],
            tabdata0: [
                { id: "1", value: "Campaign Detail" },
            ],
            tabdata1: [
                { id: "1", value: "Campaign Detail" },
                { id: "2", value: "Suggested Influencer" },
                { id: "3", value: "All Influencer" },
                { id: "4", value: "In Process" },
                { id: "5", value: "Approved Influencer" },
                { id: "6", value: "Rejected Influencer" },
                { id: "7", value: "Concents" },
            ],
            tabdata2: [
                { id: "1", value: "Campaign Detail" },
                { id: "2", value: "Suggested Influencer" },
                { id: "3", value: "All Influencer" },
                { id: "4", value: "In Process" },
                { id: "5", value: "Approved Influencer" },
                { id: "6", value: "Rejected Influencer" },
                { id: "7", value: "Concents" },
            ],
            tabdata3: [
                { id: "1", value: "Campaign Detail" },
                { id: "5", value: "Approved Influencer" },
                { id: "6", value: "Rejected Influencer" },
                { id: "8", value: "Ratings" },
            ],
        }
    },
    created: function() {
        eventBus.$on(
        "changeActivetab",
        function(selected) {
            // console.log(selected);
            this.activetab = selected;
        }.bind(this)
        );

        this.getcampaigndetails()
    },
    watch: {
        campaignData:{
            immediate: true,
            handler(campaignData){
                if(campaignData.status == '1'){
                    this.tabdata = this.tabdata1
                }
                else if (campaignData.status == '2'){
                    this.tabdata = this.tabdata2
                }
                else if (campaignData.status == '3'){
                    this.tabdata = this.tabdata3
                }
                else {
                    this.tabdata = this.tabdata0
                }
            }
        }
    },
    methods: {
        ...mapActions("admincampaignaction",["getcampaignbasic"]),

        onGenerateReport() {
            this.$root.$emit('onGenerateReport')
        },

        getcampaigndetails() {
            this.getcampaignbasic({
                id: this.$route.params.id
            }).then(response => {
                this.campaignData = response.data;
            })
        }
    }
}
</script>