<template>
    <div class="campaigninvite">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Campaign Invitations</text-component> 
            </div>
        </div>
        <div class="content">
            <div class="box paddingo margino">
                <!-- <div class="box-header headerpadding">
                    
                </div> -->
                <div class="box-content">
                    <div class="datatable-main">
                        <datatable-component
                        class="dtable-main"
                        :selected-rows="selectedRows"
                        :actionid="actionid"
                        :columns="columns"
                        :cboxindeterminatecls="cboxindeterminatecls"
                        :actionText="actionText"
                        :rows="rows"
                        :clsname="clsname"
                        :btnText="btnText"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        :classname="classname"
                        :dropBtn="dropBtn"
                        :dropdownText="dropdownText"
                        :statusClear="statusClear"
                        :clearClass="clearClass"
                        :filter="filter"
                        :page="page"
                        :per_page="per_page">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isNegotiate}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="campaignNegotiate" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <text-component :class="h2txtclass">Negotiate</text-component>
                    <form @submit.prevent="sendNegotiate">
                        <div class="row margino negotiate-popup-header">
                            <text-component class="subtitle1">Actual Price</text-component>
                            <text-component class="negotiate-txt">₹ {{actualprice}}</text-component>
                        </div>
                        <div class="row">
                            <input-component
                                class="txtgroup col-12"
                                v-model="item.negotiatedprice"
                                :helptext="(!$v.item.negotiatedprice.required || !$v.item.negotiatedprice.numeric) && submitted ? validationmessage.negotiatedprice: false"
                                :labelclass="{'validationerr': (!$v.item.negotiatedprice.required || !$v.item.negotiatedprice.numeric) && submitted}"
                                :classname="{'error': (!$v.item.negotiatedprice.required || !$v.item.negotiatedprice.numeric) && submitted, 'default': true}">
                                Negotiate Price
                            </input-component>
                        </div>
                        <hr class="hrline"/>
                        <div class="modal-footer">
                            <div>
                                <button-component buttontype="submit" :clsname="btninvitecls">Negotiate</button-component>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ActionBtn from "./actionbtn.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { eventBus } from "../../../main";
import { required, numeric } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {},
    components: {
        DatatableComponent,
        TextComponent,
        InputComponent,
        ButtonComponent
    },
    validations: {
        item: {
            negotiatedprice: { required, numeric },
        }
    },
    computed: {
        ...mapGetters("influencercampaign", ["inviteData"]),
    },
    watch: {
        inviteData: {
            immediate: true,
            handler(inviteData) {
                this.rows = inviteData;
                console.log('inviteData',inviteData)
            }
        }
    },
    created() {
        this.getpendinginvite()

        this.$root.$on('onNegotiate', (row) => {
            this.campaignNegotiate();
            this.getpendinginvite()
            this.current_id = row.id
            this.actualprice = row.offered_price
        })
    },
    methods: {
         ...mapActions("influencercampaign",["getpendinginvite", "negotiateprice"]),
        sendNegotiate(row) {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                let vm = this;
                this.negotiateprice({
                id: this.current_id,
                amount: this.item.negotiatedprice
                })
                .then(response => {
                    this.getpendinginvite()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                    this.isNegotiate = false
                    this.submitted = false
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        campaignNegotiate() {
            if(this.isNegotiate == true){
                this.isNegotiate = false
            }
            else{
                this.isNegotiate = true	
            }
        }
    },
    data() {
        return {
        item: {
            negotiatedprice: null,
        },
        validationmessage: {
            negotiatedprice: "please enter valid price",
        },
        current_id: null,
        submitted: false,
        isNegotiate: false,
        price: '',
        actualprice: '',
        actionid: "moreactionbtn",
        cboxindeterminatecls: "cboxindeterminate",
        actionText: "More actions",
        clsname: "btnsort",
        btnText: "Sort",
        type: "text",
        inputPlaceholder: "Search vendor",
        txtclassname: "default",
        classname: "default prefixicon",
        dropBtn: "btnbasic",
        dropdownText: "Sub Category",
        statusClear: "Clear",
        clearClass: "btnplain",
        btninvitecls: "btnprimary",
        acname: "Mahnaz Farzin",
        myacimageurl: "alert.svg",
        dropCat: "btnbasic",
        dropCatText: "Main Category",
        statusid: "btnstatus",
        filter: "",
        selected: "",
        row: "",
        h2txtclass: 'h2',
        companyname: "Glan Pharma Pvt. Ltd.",
        isMobileMenu: false,
        page: 1,
        per_page: 8,
        selectedRows: [],
        columns: [
            { label: "Action",headerAlign: "left" , component: (ActionBtn)},
            {
                label: "Campaign Name",
                field: "campaigns.campaign_name",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Campaign Period",
                representedAs: row => {
                    return this.formatDateGlobal(row.campaigns.start_date)+" to "+this.formatDateGlobal(row.campaigns.end_date);
                },
                align: "left",
                headerAlign: "left",
                sortable: false
            },
            {
                label: "Offered price",
                field: "offered_price",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Negotiated price",
                field: "bargain_price",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            {
                label: "Final price",
                field: "final_price",
                align: "left",
                headerAlign: "left",
                headerClass: "class-in-header second-class",
                sortable: false
            },
            
        ],
        rows: [],
        };
    },
};
</script>
