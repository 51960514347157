<template>
    <form @submit.prevent="handleOTPSubmit">
        <div :class="{'modal-window': true , 'popupopen': isPhonePopup}">
            <div class="modal-window-main signup-page">
                <a title="Close" class="modal-close">
                    <img
                        @click="popUp"
                        src="../../../assets/image/cancel.svg"
                        class="cancel-icon"
                        alt="cancel-icon"
                    />
                </a>
                <div class="header">
                    <text-component :class="'h2'">Enter OTP</text-component>
                </div>
                <div class="">
                    <otp-input-component
                        required="true"
                        class="txtgroup"
                        v-model="verify.otp"
                        :helptext="!$v.verify.otp.required && submittedotp ? validationmessage.otp: false"
                        :labelclass="{'validationerr': !$v.verify.otp.required && submittedotp}"
                        :classname="{'error': !$v.verify.otp.required && submittedotp, 'default': true}"
                        :type="'text'"
                        :otplen="4"
                    >OTP</otp-input-component>
                </div>
                <div class="modal-footer margin-top16">
                    <div>
                        <button-component buttontype="submit" :clsname="'btnprimary'">Verify</button-component>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import OtpInputComponent from "../../../BasicComponents/Input Group/otpInput.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        ButtonComponent,
        OtpInputComponent,
    },
    validations: {
        verify:{
            otp: { required },
        }
    },
    // computed: {
    //     ...mapGetters("brandcampaign", [
    //     "contentFormat",
    //     "influencerCategory",
    //     "singleCampaign"
    //     ]),
    //     ...mapGetters("commonfunction", ["platformData","countryData", "cityData"]),
    // },
    data() {
        return {
            otp_screen:false,
            isPhonePopup:false,
            submittedotp: false,
            verify:{
                otp: null,
            },
            validationmessage:{
                otp:"OTP is required"
            }
        }
    },
    methods:{
        ...mapActions("user", ["verifyOTPProfile"]),
        ...mapActions("influencerprofile", ["getuserprofile"]),
        handleUpdate(){
            console.log('handle')
        },

        popUp(){
            if (this.isPhonePopup == true) {
                this.isPhonePopup = false;
            } else {
                this.isPhonePopup = true;
            }  
        },
        handleOTPSubmit(){
            // this.otp_screen = true;
            this.submittedotp = true;
            if (this.$v.verify.$invalid) {
                this.$v.verify.$touch();
            } else {
                let vm = this;
                this.verifyOTPProfile({
                    otp: this.verify.otp
                })
                .then(response => {
                    vm.verify.otp = null;
                    vm.popUp();
                    vm.getuserprofile()
                    // vm.$router.push({ name: "Thankyou" });
                    // console.log("checking");
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                });
            }
        }
    },
    created: function() {
        console.log('created')
        this.$root.$on("openPopup", () => {
            console.log("openPopup")
            if (this.isPhonePopup == true) {
                this.isPhonePopup = false;
            } else {
                this.isPhonePopup = true;
            }
        });
    },
    beforeDestroy() {
        this.$root.$off("openPopup", this.fn);
    }
    
}
</script>   