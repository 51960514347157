<template>
  <div>
    <drop-button :clsname="clsname" @onBtnClick="showSortPop">
      <img src="../../assets/image/dropdown.svg" class="dropdown-image" />
      <span id="btn">{{btnText}}</span>
    </drop-button>
    <check-pop
      :popoverList="popoverList"
      v-show="checkPopModal"
      v-model="cbData"
      :clsname="clearClass"
      @onCheckClick="checkPopModal = false"
      class="display-dropdown"
    >
      <slot />
    </check-pop>
  </div>
</template>

<script>
import DropButton from "../Button/Button.vue";
import CheckPop from "../Popover/popoverCheckbox.vue";
export default {
  components: {
    DropButton,
    CheckPop
  },

  data() {
    return {
      checkPopModal: false,
      cbData: []
    };
  },
  watch:{
    cbData:{
      immediate:false,
      handler(cbData){
        this.$emit('input', cbData)
      }
    }
  },

  props: {
    clsname: String,
    btnText: String,
    popoverList: Array,
    clearClass: String
  },

  created() {
    window.addEventListener("click", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },

  methods: {
    showSortPop: function() {
      if (this.checkPopModal == false) {
        this.checkPopModal = true;
      } else {
        this.checkPopModal = false;
      }
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.checkPopModal = false;
      }
    }
  }
};
</script>