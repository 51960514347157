
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let profileData = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        profileData: profileData,
    }

}

const getters = {
    currentUser: state => state.currentUser,
    profileData: state => state.profileData,
};

const actions = {

    clearAll: ({ commit }) => {
        commit("setCurrentUser", [])
        commit("setCurrentCoa", [])
        commit("setProfileData", [])
    },

    fetchData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/me")
                .then((response) => {
                    console.log(response);
                    if (typeof response !== "undefined" && response.data) {
                        console.log("setting current user", response.data.data);
                        commit("setCurrentUser", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log("user.js:106 my error");
                    console.log(error);

                    commit("setIsAuthenticated", false);
                    commit("setCurrentUser", null);
                    commit("setCurrentCoa", null);

                    window.localStorage.setItem("auth", null);
                    reject(error);
                });
        });
    },

    changepassword: ({ state, commit }, passwordData) => {
        commit('commonfunction/setLoading', true, { root: true })
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/change-password", { ...passwordData, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, { root: true })
                    console.log(state);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, { root: true })
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    basicprofile: ({ commit }, profiledata) => {
        commit('commonfunction/setLoading', true, { root: true })
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/user-profile", profiledata, { ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, { root: true })
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, { root: true })
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getuserprofile: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/get-user-profile")
                .then((response) => {
                    commit("setProfileData", response.data.data);
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setCurrentUser: (state, value) => {
        state.currentUser = value;
    },
    setCurrentCoa: (state, value) => {
        state.currentCOA = value;
    },
    setProfileData: (state, value) => {
        state.profileData = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};