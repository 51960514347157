<template>
    <div>
        <badge class="badge" :badgeclass="StatusClass">{{StatusText}}</badge>
        <!-- <badge class="badge" :badgeclass="{'Attention-Badge':(row.status=='0'),'Success-Badge':(row.status=='1')}">{{(row.status == 0)?'Pending':'Approved'}}</badge> -->
    </div>
</template>

<script>
import Badge from '../../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],

    watch:{
        row:{
            immediate:true,
            handler(row){
                if (row.status=='1') {
                    this.StatusText ='In Progress';
                    this.StatusClass ='Success-Badge';
                }else if (row.status=='2') {
                    this.StatusText ='Rejected';
                    this.StatusClass ='Warning-Badge';
                }else if (row.status=='3') {
                    this.StatusText ='Completed';
                    this.StatusClass ='Default-Badge';
                }else{
                    this.StatusText ='Pending';
                    this.StatusClass ='Attention-Badge';
                }
                
            }
        }
    },
    data() {
        return {
            StatusText:'Pending',
            StatusClass:'Attention-Badge',
        }
    },
}
</script>