<template>
    <div>
        <section class="about-banner">
            <img src="../../../assets/image/front/About_Banner.png">
        </section>

        <section class="top-m-img who-are-we-section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section1_image" :src="getImageURL(baseUrl,cms_image.section1_image)" id="twoImg">
                        <img v-else src="../../../assets/image/front/AboutImage.png">
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="pl24 about-us-content pink">
                            <h1>{{cms_field.section1_title}}</h1>
                            <div class="editor_content" v-html="cms_field.section1_description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="meet-our-team">
            <div class="container">
                <div class="hiw-content ceo-title">
                    <h1>Let’s Put a Face to the Name</h1>
                </div>
                <section class="top-second-img">
                    <div class="container">
                    <div class="row">
                        <div class="ceo-img col-sm-12 col-md-12 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section2_1_image" :src="getImageURL(baseUrl,cms_image.section2_1_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-7" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="ceo-content">
                            <h3>{{cms_field.section2_1_subtitle}}</h3>
                            <h5>{{cms_field.section2_1_role}}</h5>
                            <p>{{cms_field.section2_1_description}}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <div class="row">
                    <div v-if="cms_field.section2_2_subtitle && cms_field.section2_2_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_2_image" :src="getImageURL(baseUrl,cms_image.section2_2_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_2_subtitle}}</h4>
                            <small>{{cms_field.section2_2_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_3_subtitle && cms_field.section2_3_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_3_image" :src="getImageURL(baseUrl,cms_image.section2_3_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_3_subtitle}}</h4>
                            <small>{{cms_field.section2_3_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_4_subtitle && cms_field.section2_4_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_4_image" :src="getImageURL(baseUrl,cms_image.section2_4_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_4_subtitle}}</h4>
                            <small>{{cms_field.section2_4_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_5_subtitle && cms_field.section2_5_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_5_image" :src="getImageURL(baseUrl,cms_image.section2_5_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_5_subtitle}}</h4>
                            <small>{{cms_field.section2_5_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_6_subtitle && cms_field.section2_6_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_6_image" :src="getImageURL(baseUrl,cms_image.section2_6_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_6_subtitle}}</h4>
                            <small>{{cms_field.section2_6_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_7_subtitle && cms_field.section2_7_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_7_image" :src="getImageURL(baseUrl,cms_image.section2_7_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_7_subtitle}}</h4>
                            <small>{{cms_field.section2_7_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_8_subtitle && cms_field.section2_8_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_8_image" :src="getImageURL(baseUrl,cms_image.section2_8_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_8_subtitle}}</h4>
                            <small>{{cms_field.section2_8_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_9_subtitle && cms_field.section2_9_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_9_image" :src="getImageURL(baseUrl,cms_image.section2_9_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_9_subtitle}}</h4>
                            <small>{{cms_field.section2_9_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_10_subtitle && cms_field.section2_10_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_10_image" :src="getImageURL(baseUrl,cms_image.section2_10_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_10_subtitle}}</h4>
                            <small>{{cms_field.section2_10_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_11_subtitle && cms_field.section2_11_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_11_image" :src="getImageURL(baseUrl,cms_image.section2_11_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_11_subtitle}}</h4>
                            <small>{{cms_field.section2_11_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_12_subtitle && cms_field.section2_12_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_12_image" :src="getImageURL(baseUrl,cms_image.section2_12_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_12_subtitle}}</h4>
                            <small>{{cms_field.section2_12_role}}</small>
                        </div>
                    </div>
                    <div v-if="cms_field.section2_13_subtitle && cms_field.section2_13_role" class="emp-id col-sm-12 col-md-6 col-lg-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="team-box">
                            <img v-if="cms_image.section2_13_image" :src="getImageURL(baseUrl,cms_image.section2_13_image)" id="twoImg">
                            <img v-else src="../../../assets/image/front/OT1.png">
                            <h4>{{cms_field.section2_13_subtitle}}</h4>
                            <small>{{cms_field.section2_13_role}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="join-vidunit">
            <div class="container">
                <div class="row">
                <div class="col-12 pink">
                    <h1>{{cms_field.section3_title}}</h1>
                </div>
                </div>
                <div class="row">
                    <div class="col-12 Looking-forward" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000" v-html="cms_field.section3_description"></div>
                </div>

                <div class="border-section bullets">
                    <div class="row">
                        <div v-if="cms_field.section3_role1_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role1_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role4_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role4_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role7_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role7_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role10_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role10_title}}</p>
                        </div>
                    </div>
                </div>

                <div class="border-section bullets">
                    <div class="row">
                        <div v-if="cms_field.section3_role2_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role2_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role5_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role5_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role8_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role8_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role11_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role11_title}}</p>
                        </div>
                    </div>
                </div>

                <div class="border-section bullets">
                    <div class="row">
                        <div v-if="cms_field.section3_role3_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role3_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role6_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role6_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role9_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role9_title}}</p>
                        </div>
                        <div v-if="cms_field.section3_role12_title" class="join col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <p>{{cms_field.section3_role12_title}}</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        <section class="read-our-blog">
            <div class="container">
                <div class="row">
                    <div class="col-12 center-content white">
                        <h1>Read Our Blogs</h1>
                    </div>
                </div>
                <div class="row">
                    <div 
                        class="col-sm-12 col-md-6 col-lg-4" 
                        data-aos="zoom-in-down" 
                        data-aos-once="true" 
                        data-aos-duration="1000"
                        v-for="(latestblog, index) in latestblogs" :key="index"
                        @click="toBlog(latestblog)">
                        <img v-if="latestblog.banner_image" :src="latestblog.baseUrl+ '/' +latestblog.banner_image">
                        <img v-else src="../../../assets/image/front/B1.png">
                        <p>{{latestblog.title}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button @click="goToBlogs()">Read All</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{},
            cms_field:[],
            cms_image:[],
            latestblogs: []
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail?this.cms_detail.meta_title:'',
            meta: [
                { name: 'description', content:  (this.cms_detail)?this.cms_detail.meta_desc:''},
                { name: 'keyword', content:  (this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    created(){
        this.getData();
        this.getLatestBlogData()
    },
    mounted(){
        this.getData();
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsAboutUs"]),
    },
    watch:{
        cmsDetailsAboutUs:{
            immediate:true,
            handler(cmsDetailsAboutUs){
                if (cmsDetailsAboutUs) {
                    this.baseUrl = cmsDetailsAboutUs.baseUrl;
                    this.cms_detail = cmsDetailsAboutUs.cms_detail;
                    this.cms_field = cmsDetailsAboutUs.cms_field;
                    this.cms_image = cmsDetailsAboutUs.cms_image;
                }
            }
        }
    },
    methods:{
        ...mapActions("frontcms",["getCmsData"]),
        ...mapActions("blogdata",["getlatestblog"]),
        
        toBlog(blog) {
            this.$router.push({name: "BlogDetail",params: { slug: blog.slug }});
            // console.log(blog)
        },

        getData(){
            this.getCmsData({slug:'about-us'});
        },

        getLatestBlogData() {
            this.getlatestblog()
            .then(response=>{
                this.latestblogs = response.data
            });
        },
        goToBlogs(){
            this.$router.push({name: "Blogs"});
        }
    }
}
</script>
