<template>
    <div class="row col-12 paddingo margino" v-if="graphdata.rows[0]">
        <div class="col-12 col-lg-6 paddingo graph-main">
            <div class="graph-main-inner">
                <graph-bar
                    :width="300"
                    :height="350"
                    :axis-min="0"
                    :axis-max="50"
                    :labels="labels"
                    :values="values">
                    <note :text="'Viewer By Gender'"></note>
                    <tooltip :names="names" :position="'left'"></tooltip>
                    <legends :names="names" :filter="true"></legends>
                </graph-bar>
            </div>
        </div>
        <!-- <div class="col-4 paddingo">
            <circle-graph-component
                :width="circlewidth"
                :height="circleheight"
                :values="circlevalues"
                :names="circlenames"
                :strokeWidth="strokeWidth">
            </circle-graph-component>
        </div> -->
        <div class="col-12 col-lg-6 paddingo graph-main">
            <div class="graph-main-inner">
                <graph-pie
                    :width="320"
                    :height="300"
                    :padding-top="100"
                    :padding-bottom="0"
                    :padding-left="0"
                    :padding-right="0"
                    :values="pievalues"
                    :names="pienames"
                    :show-text-type="'inside'">
                    <!-- <legends :names="pienames"></legends> -->
                    <tooltip :names="pienames"></tooltip>
                </graph-pie>
            </div>
        </div>
    </div>
</template>
<script>
import CircleGraphComponent from "../../../BasicComponents/graph/graphcircle.vue";
export default {
    props: ['gendata', 'activeindex'],
    components: {
        // CircleGraphComponent
    },
    data() {
        return{
            pievalues: [ 0, 0 ],
            pienames: ['Male', 'Female'],

            circlewidth: 400,
            circleheight: 270,
            circlevalues: [ 0, 0,],
            circlenames: ['Male', 'Female'],
            strokeWidth: 55,
            totalmale: null,
            totalfemale: null,
            graphdata: [],
            labels: [],
            names: [ "Male", "Female" ],
            values: [
                [],
                [],
            ]
        }
    },
    created() {
        this.graphdata = this.gendata.age_gender

        for (let i = 0; i < this.graphdata.rows.length; i++) {
            if(this.graphdata.rows[i][1].trim() == 'male') {
                this.values[0].push(this.graphdata.rows[i][2])
                this.circlevalues[0] += this.graphdata.rows[i][2]
                this.pievalues[0] += this.graphdata.rows[i][2]
            }
            if(this.graphdata.rows[i][1].trim() == 'female') {
                this.values[1].push(this.graphdata.rows[i][2])
                this.circlevalues[1] += this.graphdata.rows[i][2]
                this.pievalues[1] += this.graphdata.rows[i][2]
            }
        }

        for (let i = 0; i < this.graphdata.rows.length; i++) {
            if(this.labels.length == '0'){
                this.labels.push(this.graphdata.rows[i][0])
            }
            else{
                for (let j = 0; j < this.labels.length; j++) {
                   if(this.labels[j] != this.graphdata.rows[i][0]) {
                       this.labels.push(this.graphdata.rows[i][0])
                   }
                }
            }
            if(this.graphdata.rows[0][1] == 'male'){
                this.values[0].push(this.graphdata.rows[0][2])
            }
        }

        this.labels.pop()
    }
}
</script>