import axios from "axios";

const AUTH_PARAMS = {
  grant_type: "password",
  client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
  client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
};

function initialState() {
  let InfluencerChatData = [];
  let AgencyChatData = [];
  let BrandChatData = [];
  let PublisherChatData = [];
  let ClosedChatData = [];
  let RoleTiocketData = [];
  let RoleTiocketClosedData = [];
  return {
    InfluencerChatData: InfluencerChatData,
    AgencyChatData: AgencyChatData,
    BrandChatData: BrandChatData,
    PublisherChatData: PublisherChatData,
    ClosedChatData: ClosedChatData,
    RoleTiocketData: RoleTiocketData,
    RoleTiocketClosedData: RoleTiocketClosedData
  };
}

const getters = {
  InfluencerChatData: (state) => state.InfluencerChatData,
  AgencyChatData: (state) => state.AgencyChatData,
  BrandChatData: (state) => state.BrandChatData,
  PublisherChatData: (state) => state.PublisherChatData,
  ClosedChatData: (state) => state.ClosedChatData,
  RoleTiocketData: (state) => state.RoleTiocketData,
  RoleTiocketClosedData: (state) => state.RoleTiocketClosedData,
};

const actions = {
  clearAll: ({ commit }) => {
    commit("setInfluencerChatData", [])
    commit("setAgencyChatData", [])
    commit("setBrandChatData", [])
    commit("setPublisherChatData", [])
    commit("setClosedChatData", [])
    commit("setRoleTiocketData", [])
    commit("setRoleTiocketClosedData", [])
  },

  getInfluencerChatData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-chat/2")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setInfluencerChatData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getAgencyChatData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-chat/3")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setAgencyChatData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getBrandChatData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-chat/4")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setBrandChatData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getPublisherChatData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-chat/5")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setPublisherChatData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getClosedChatData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-close-chat")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setClosedChatData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  createMessage: ({ commit }, data) => {
    commit('commonfunction/setLoading', true, {root: true})
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/support-ticket", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit('commonfunction/setLoading', false, {root: true})
          resolve(response.data);
        })
        .catch((error) => {
          commit('commonfunction/setLoading', false, {root: true})
          console.log(error);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  sendMessage: ({ commit }, data) => {
    commit('commonfunction/setLoading', true, {root: true})
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/chat", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit('commonfunction/setLoading', false, {root: true})
          resolve(response.data);
        })
        .catch((error) => {
          commit('commonfunction/setLoading', false, {root: true})
          console.log(error);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  closeTicket: ({ commit }, data) => {
    commit('commonfunction/setLoading', true, {root: true})
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/support-ticket-action", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit('commonfunction/setLoading', false, {root: true})
          resolve(response.data);
        })
        .catch((error) => {
          commit('commonfunction/setLoading', false, {root: true})
          console.log(error);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getUserListByRole: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + `/chat-user/${id}`)
        .then((response) => {
          // console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getDetailChatData: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + `/chat-detail/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getRoleTiocketData: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/support-ticket/0")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setRoleTiocketData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getRoleTiocketClosedData: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/support-ticket/1")
        .then((response) => {
          if (typeof response !== "undefined" && response.data) {
            commit("setRoleTiocketClosedData", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  createMessageRole: ({ commit }, data) => {
    commit('commonfunction/setLoading', true, {root: true})
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/role-support-ticket", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit('commonfunction/setLoading', false, {root: true})
          resolve(response.data);
        })
        .catch((error) => {
          commit('commonfunction/setLoading', false, {root: true})
          console.log(error);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
  setInfluencerChatData: (state, value) => {
    state.InfluencerChatData = value;
  },
  setAgencyChatData: (state, value) => {
    state.AgencyChatData = value;
  },
  setBrandChatData: (state, value) => {
    state.BrandChatData = value;
  },
  setPublisherChatData: (state, value) => {
    state.PublisherChatData = value;
  },
  setClosedChatData: (state, value) => {
    state.ClosedChatData = value;
  },
  setRoleTiocketData: (state, value) => {
    state.RoleTiocketData = value;
  },
  setRoleTiocketClosedData: (state, value) => {
    state.RoleTiocketClosedData = value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  getters: getters,
  actions: actions,
};
