<template>
  <div class="influencer">
    <div class="box paddingo">
      <!-- <div class="box-header headerpadding">
                <text-component :class="subtitle1txtclass">Campaign List </text-component>
      </div>-->
      <div class="box-content">
        <header-tabs
          :tabdata="tabdata"
          :activetab="activetab"
          :maxLimit="maxLimit"
          :isHiddenbar="isHiddenbar"
        >
          <div class="tag-bar-bottom-data" v-if="activetab === 'Top Influencers'">
            <div class="datatable-main">
              <div class="data-table-header">
                <div class="data-table-header-inner">
                  <search-bar
                    class="header-searchbar"
                    :classname="classname"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    v-model="filter"
                  ></search-bar>
                </div>
              </div>
              <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :actionid="actionid"
                :columns="columns"
                :rows="rows"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :filter="filter"
                :page="page"
                :per_page="per_page"
                @onrowclick="navigateDetail"
              ></datatable-component>
            </div>
          </div>
          <div class="tag-bar-bottom-data" v-if="activetab === 'Top Earning Influencers'">
            <div class="datatable-main">
              <div class="data-table-header">
                <div class="data-table-header-inner">
                  <search-bar
                    class="header-searchbar"
                    :classname="classname"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    v-model="filter"
                  ></search-bar>
                </div>
              </div>
              <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :actionid="actionid"
                :columns="brandcolumns"
                :rows="brandrows"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :filter="filter"
                :page="page"
                :per_page="per_page"
                @onrowclick="navigateDetail"
              ></datatable-component>
            </div>
          </div>
          <!-- <div class="tag-bar-bottom-data" v-if="activetab === 'Recently Joined Influencers'">
            <div class="datatable-main">
              <div class="data-table-header">
                <div class="data-table-header-inner">
                  <search-bar
                    class="header-searchbar"
                    :classname="classname"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    v-model="filter"
                  ></search-bar>
                </div>
              </div>
              <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :actionid="actionid"
                :columns="brandcolumns"
                :rows="brandrows"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :filter="filter"
                :page="page"
                :per_page="per_page"
              ></datatable-component>
            </div>
          </div> -->
        </header-tabs>
      </div>
    </div>
  </div>
</template>


<script>
import HeaderTabs from "../../../BasicComponents/Tabs/Tabs.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import usericon from "./profileicon.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import Rating from "./rating.vue";
import Platform from "./platform.vue";
import { eventBus } from "../../../main";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {},
  components: {
    HeaderTabs,
    DatatableComponent,
    searchBar,
  },
  computed: {
    ...mapGetters("admininfluenceraction", ["topInfluencers", "topEarning"]),
  },
  watch: {
    topInfluencers: {
      immediate: true,
      handler(topInfluencers) {
        this.$data.rows = topInfluencers;
      },
    },

    topEarning: {
      immediate: true,
      handler(topEarning) {
        this.$data.brandrows = topEarning;
      },
    },
  },
  data() {
    return {
      activetab: "Top Influencers",
      maxLimit: 9,
      h2txtclass: "h2",
      isHiddenbar: false,
      isChat: false,
      btnprimary: "btnprimary",
      subtitle2class: "subtitle2",
      midclass: "mid",
      txtclassname: "default",
      actionid: "moreactionbtn",
      classname: "default prefixicon",
      page: 1,
      per_page: 8,
      type: "text",
      inputPlaceholder: "Search Influencer",
      filter: "",
      to: "",
      selectedRows: [],
      columns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        // {
        //   label: "Platform",
        //   headerAlign: "left",
        //   align: "left",
        //   component: Platform,
        // },
        // {
        //   label: "Followers/Subscribers",
        //   field: "subscriber",
        //   align: "left",
        //   headerAlign: "left",
        //   headerClass: "class-in-header second-class",
        //   sortable: false,
        // },
        {
          label: "Rating",
          headerAlign: "left",
          align: "left",
          component: Rating,
        },
      ],
      rows: [],
      brandcolumns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        // {
        //   label: "Platform",
        //   headerAlign: "left",
        //   align: "left",
        //   component: Platform,
        // },
        {
          label: "Total Earning",
          representedAs: (row) => `₹ ${row.earning}`,
          align: "left",
          headerAlign: "left",
          interpolate: true,
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Rating",
          headerAlign: "left",
          align: "left",
          component: Rating,
        },
      ],
      brandrows: [],
      tabdata: [
        { id: "1", value: "Top Influencers" },
        { id: "2", value: "Top Earning Influencers" },
        // { id: "3", value: "Recently Joined Influencers" },
      ],
    };
  },
  created: function () {
    this.$root.$on("onClick", () => {
      if (this.isChat == false) {
        this.isChat = true;
      } else {
        this.isChat = false;
      }
    });

    eventBus.$on(
      "changeActivetab",
      function (selected) {
        // console.log(selected);
        this.activetab = selected;
      }.bind(this)
    );

    this.getTopInfluencers();
    this.getTopEarning();
  },
  methods: {
    ...mapActions("admininfluenceraction", [
      "getTopInfluencers",
      "getTopEarning",
    ]),

    navigateDetail(row) {
      this.$router.push({name: "admininfluencerdetail",params: { id: row.id }});
    },

    sendMessege() {
      if (this.isChat == false) {
        this.isChat = true;
      } else {
        this.isChat = false;
      }
    },
  },
};
</script>
