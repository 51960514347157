<template>
  <div>
    <input :id="cb+checkboxselectid" type="checkbox" name="cb" class="cbox" :value="val" v-model="checked" @change="onCboxClick"/>
    <label :for="cb+checkboxselectid" class="lblcb">
      <slot/>
    </label>
  </div>
</template>


<script>
export default {
  props: ["value","checkboxselectid","val"],
  data() {
    return{
      cb:'btncb' + Math.random(),
      checkedProxy: false
    }
  },
  computed: {
    checked:{
      get() {
        return this.value
      },
      set (value) {
        this.checkedProxy = value
      }
    }
  },
  methods: {
    onCboxClick: function() {
      this.$emit('input', this.checkedProxy)
    }
  }
}
</script>