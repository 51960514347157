<template>
    <div class="aboutus-edit">
         <div class="template-accordion">
            <div class="tabs">
                <div class="tab">
                    <input type="checkbox" id="aboutus1">
                    <label class="tab-label" for="aboutus1">Section 1 (About VidUnit)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section1_title"
                            required="true"
                            :helptext="!$v.contentData.section1_title.required && submitted ? validationmessage.section1_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section1_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section1_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section1_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section1_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section1_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.description}}</span>
                        </div>
                        <!-- <div class="">
                            <div class="inputtxt">
                                <text-component>Description*</text-component>
                            </div>
                            <text-area-comp v-model="contentData.section1_description"
                                required="true"
                                :helptext="!$v.contentData.section1_description.required && submitted ? validationmessage.description: false"
                                :labelclass="{'validationerr': !$v.contentData.section1_description.required && submitted}"
                                :classname="{'error': !$v.contentData.section1_description.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                        </div> -->
                        <profile-uploader
                            :imageurl="getPreview('section1_image')"
                            fileuploadid="section1_image"
                            @selectfile="getfiledata($event,'section1_image')"
                            :avatar="avatar.section1_image"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="aboutus2">
                    <label class="tab-label" for="aboutus2">Section 2 (Meet Our Team)</label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section2_title"
                            required="true"
                            :helptext="!$v.contentData.section2_title.required && submitted ? validationmessage.section2_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section2_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section2_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <fieldset>
                                    <legend>Section2_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_subtitle"
                                        required="true"
                                        :helptext="!$v.contentData.section2_1_subtitle.required && submitted ? validationmessage.section2_1_subtitle: false"
                                        :labelclass="{'validationerr': !$v.contentData.section2_1_subtitle.required && submitted}"
                                        :classname="{'error': !$v.contentData.section2_1_subtitle.required && submitted, 'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_1_role"
                                        required="true"
                                        :helptext="!$v.contentData.section2_1_role.required && submitted ? validationmessage.section2_1_role: false"
                                        :labelclass="{'validationerr': !$v.contentData.section2_1_role.required && submitted}"
                                        :classname="{'error': !$v.contentData.section2_1_role.required && submitted, 'default': true}">
                                        Role
                                    </input-component>
                                    <div class="">
                                        <div class="inputtxt">
                                            <text-component>Description*</text-component>
                                        </div>
                                        <text-area-comp v-model="contentData.section2_1_description"
                                            required="true"
                                            :helptext="!$v.contentData.section2_1_description.required && submitted ? validationmessage.description: false"
                                            :labelclass="{'validationerr': !$v.contentData.section2_1_description.required && submitted}"
                                            :classname="{'error': !$v.contentData.section2_1_description.required && submitted, 'default': true,'txtarea':true}"
                                        ></text-area-comp>
                                    </div>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_1_image')"
                                        fileuploadid="section2_1_image"
                                        @selectfile="getfiledata($event,'section2_1_image')"
                                        :avatar="avatar.section2_1_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_2</legend>
                                    <img class="delete-member" v-if="contentData.section2_2_subtitle || contentData.section2_2_role || imageDataShow.section2_2_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_2')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_2_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_2_image')"
                                        fileuploadid="section2_2_image"
                                        @selectfile="getfiledata($event,'section2_2_image')"
                                        :avatar="avatar.section2_2_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_3</legend>
                                    <img class="delete-member" v-if="contentData.section2_3_subtitle || contentData.section2_3_role || imageDataShow.section2_3_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_3')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_3_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_3_image')"
                                        fileuploadid="section2_3_image"
                                        @selectfile="getfiledata($event,'section2_3_image')"
                                        :avatar="avatar.section2_3_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_4</legend>
                                    <img class="delete-member" v-if="contentData.section2_4_subtitle || contentData.section2_4_role || imageDataShow.section2_4_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_4')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_4_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_4_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_4_image')"
                                        fileuploadid="section2_4_image"
                                        @selectfile="getfiledata($event,'section2_4_image')"
                                        :avatar="avatar.section2_4_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_5</legend>
                                    <img class="delete-member" v-if="contentData.section2_5_subtitle || contentData.section2_5_role || imageDataShow.section2_5_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_5')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_5_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_5_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_5_image')"
                                        fileuploadid="section2_5_image"
                                        @selectfile="getfiledata($event,'section2_5_image')"
                                        :avatar="avatar.section2_5_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_6</legend>
                                    <img class="delete-member" v-if="contentData.section2_6_subtitle || contentData.section2_6_role || imageDataShow.section2_6_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_6')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_6_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_6_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_6_image')"
                                        fileuploadid="section2_6_image"
                                        @selectfile="getfiledata($event,'section2_6_image')"
                                        :avatar="avatar.section2_6_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_7</legend>
                                    <img class="delete-member" v-if="contentData.section2_7_subtitle || contentData.section2_7_role || imageDataShow.section2_7_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_7')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_7_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_7_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_7_image')"
                                        fileuploadid="section2_7_image"
                                        @selectfile="getfiledata($event,'section2_7_image')"
                                        :avatar="avatar.section2_7_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_8</legend>
                                    <img class="delete-member" v-if="contentData.section2_8_subtitle || contentData.section2_8_role || imageDataShow.section2_8_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_8')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_8_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_8_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_8_image')"
                                        fileuploadid="section2_8_image"
                                        @selectfile="getfiledata($event,'section2_8_image')"
                                        :avatar="avatar.section2_8_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_9</legend>
                                    <img class="delete-member" v-if="contentData.section2_9_subtitle || contentData.section2_9_role || imageDataShow.section2_9_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_9')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_9_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_9_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_9_image')"
                                        fileuploadid="section2_9_image"
                                        @selectfile="getfiledata($event,'section2_9_image')"
                                        :avatar="avatar.section2_9_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_10</legend>
                                    <img class="delete-member" v-if="contentData.section2_10_subtitle || contentData.section2_10_role || imageDataShow.section2_10_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_10')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_10_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_10_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_10_image')"
                                        fileuploadid="section2_10_image"
                                        @selectfile="getfiledata($event,'section2_10_image')"
                                        :avatar="avatar.section2_10_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_11</legend>
                                    <img class="delete-member" v-if="contentData.section2_11_subtitle || contentData.section2_11_role || imageDataShow.section2_11_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_11')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_11_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_11_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_11_image')"
                                        fileuploadid="section2_11_image"
                                        @selectfile="getfiledata($event,'section2_11_image')"
                                        :avatar="avatar.section2_11_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_12</legend>
                                    <img class="delete-member" v-if="contentData.section2_12_subtitle || contentData.section2_12_role || imageDataShow.section2_12_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_12')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_12_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_12_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_12_image')"
                                        fileuploadid="section2_12_image"
                                        @selectfile="getfiledata($event,'section2_12_image')"
                                        :avatar="avatar.section2_12_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <fieldset>
                                    <legend>Section2_13</legend>
                                    <img class="delete-member" v-if="contentData.section2_13_subtitle || contentData.section2_13_role || imageDataShow.section2_13_image" src="../../../../assets/image/delete.svg" alt="delete-image" @click="deleteTeamData('section2_13')" />
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_13_subtitle"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section2_13_role"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Role
                                    </input-component>
                                    <profile-uploader
                                        :imageurl="getPreview('section2_13_image')"
                                        fileuploadid="section2_13_image"
                                        @selectfile="getfiledata($event,'section2_13_image')"
                                        :avatar="avatar.section2_13_image"
                                        :width="width"
                                        :height="height"
                                        :camicon="camicon"
                                    ></profile-uploader>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <input type="checkbox" id="aboutus3">
                    <label class="tab-label" for="aboutus3">Section 3 (Join VidUnit) </label>
                    <div class="tab-content">
                        <input-component
                            class="txtgroup col-12"
                            v-model="contentData.section3_title"
                            required="true"
                            :helptext="!$v.contentData.section3_title.required && submitted ? validationmessage.section3_title: false"
                            :labelclass="{'validationerr': !$v.contentData.section3_title.required && submitted}"
                            :classname="{'error': !$v.contentData.section3_title.required && submitted, 'default': true}">
                            Title
                        </input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="contentData.section3_description" 
                                :config="editorConfig"
                                :class="{'error': !$v.contentData.section3_description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.contentData.section3_description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.section3_description}}</span>
                        </div>
                        <!-- <div class="">
                            <div class="inputtxt">
                                <text-component>Description*</text-component>
                            </div>
                            <text-area-comp v-model="contentData.section3_description"
                                required="true"
                                :helptext="!$v.contentData.section3_description.required && submitted ? validationmessage.description: false"
                                :labelclass="{'validationerr': !$v.contentData.section3_description.required && submitted}"
                                :classname="{'error': !$v.contentData.section3_description.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                        </div> -->
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_1</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role1_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_2</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role2_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_3</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role3_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_4</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role4_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_5</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role5_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_6</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role6_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_7</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role7_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_8</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role8_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_9</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role9_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_10</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role10_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_11</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role11_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <fieldset>
                                    <legend>Section3_12</legend>
                                    <input-component
                                        class="txtgroup col-12"
                                        v-model="contentData.section3_role12_title"
                                        :required="false"
                                        :helptext="false"
                                        :classname="{'default': true}">
                                        Title
                                    </input-component>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import ProfileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";

export default {
    props: ["contentData", "imageData", "imageDataShow","baseUrl","submitted"],
    components: {
      InputComponent,
      TextComponent,
      TextAreaComp,
    //   ButtonComponent,
      ProfileUploader
    },
    validations: {
        contentData: {
            section1_title: { required },
            section1_description: { required },

            section2_title: { required },

            section2_1_subtitle: { required },
            section2_1_role: { required },
            section2_1_description: { required },

            // section2_2_subtitle: { required },
            // section2_2_role: { required },
            // section2_2_description: { required },

            // section2_3_subtitle: { required },
            // section2_3_role: { required },
            // section2_3_description: { required },

            section3_title: { required },
            section3_description: { required },

            // section3_role1_title: { required },
            // section3_role1_description: { required },

            // section3_role2_title: { required },
            // section3_role2_description: { required },

            // section3_role3_title: { required },
            // section3_role3_description: { required },
        },
    },
    data() {
        return {
            validationmessage: {
                section1_title: 'title is required',
                section1_description: 'description is required',

                section2_title: 'title is required',

                section2_1_subtitle: "subtitle is required",
                section2_1_role: "Role is required",
                section2_1_description: 'description is required',

                section2_2_subtitle: "subtitle is required",
                section2_2_role: "Role is required",
                section2_2_description: 'description is required',

                section2_3_subtitle: 'subtitle is required',
                section2_3_role: 'Role is required',
                section2_3_description: 'description is required',

                section3_title: 'title is required',
                section3_description: 'description is required',

                // section3_role1_title: 'role title is required',
                // section3_role1_description: 'role description is required',

                // section3_role2_title: 'role title is required',
                // section3_role2_description: 'role description is required',

                // section3_role3_title: 'role title is required',
                // section3_role3_description: 'role description is required',
            },
            // submitted: false,
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: {
                section1_image:null,
                section2_1_image:null,
                section2_2_image:null,
                section2_3_image:null,
                section2_4_image:null,
                section2_5_image:null,
                section2_6_image:null,
                section2_7_image:null,
                section2_8_image:null,
                section2_9_image:null,
                section2_10_image:null,
                section2_11_image:null,
                section2_12_image:null,
                section2_13_image:null,
            },
            width: '100px',
            height: '100px',
            camicon: '-21px',
            smallwidth: '50px',
            smallheight: '50px',
            smallcamicon: '-16px',
            editorConfig: {}
        }
    },
    methods: {
        touch () {
            this.$v.contentData.$touch();
        },
        getfiledata(e,fileName) {
            let image = e.target.files[0];
            this.imageData.push({file:image,name:fileName})
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
            this.avatar[fileName] = e.target.result;
            };
        },
        getPreview(filename) {
            let returnIs = '';
            if (this.imageDataShow[filename]) {
                returnIs = this.baseUrl+'/'+this.imageDataShow[filename]
            }else{
                returnIs = this.imageurl;
            }
            return returnIs;
        },

        deleteTeamData(section){
            if(confirm('Are you sure to delete this data?')){
                this.contentData[section+'_subtitle'] = null
                this.contentData[section+'_role'] = null
                this.contentData[section+'_image'] = null
                this.imageDataShow[section+'_image'] = null
            }
        }

    }

}
</script>