import router from "../../routes/routes";
import axios from "axios";
import { setupAxiosDefaults } from "@/utils/AxiosSetup";

const AUTH_PARAMS = {
  grant_type: 'password',
  client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
  client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

const initialState = () => {
  let currentUser = {
    id: null,
    name: null,
    email: null,
    mobile: null,
    password: null
  };
  let isAuthenticated = false;
  let rolesAll = [];
  let signupResponse = [];
  let signupId = null;
  let selectedIds = [];
  let userData = [];
  let rolesData = [];
  let singleData = [];
  // let chargesData = [];
  // let bankData = [];
  // let profileData = [];
  // let interestData = [];
  let auth = window.localStorage.getItem("auth");

  if (auth !== null || auth !== "undefined") {
    auth = JSON.parse(auth);
    if (auth) {
      // console.log('auth')
      // console.log(auth)
      // console.log(auth.access_token);
      isAuthenticated = auth.access_token !== null;
      setupAxiosDefaults(auth);
    }
  }

  return {
    currentUser: currentUser,
    isAuthenticated: isAuthenticated,
    rolesAll: rolesAll,
    signupId: signupId,
    signupResponse: signupResponse,
    selectedIds: selectedIds,
    userData: userData,
    rolesData: rolesData,
    singleData: singleData,
    // chargesData: chargesData,
    // bankData: bankData,
    // profileData: profileData,
    // interestData: interestData
  };
};

const getters = {
  currentUser: state => state.currentUser,
  rolesAll: state => state.rolesAll,
  signupId: state => state.signupId,
  signupResponse: state => state.signupResponse,
  selectedIds: state => state.selectedIds,
  userData: state => state.userData,
  rolesData: state => state.rolesData,
  singleData: state => state.singleData
  // chargesData: state => state.chargesData,
  // bankData: state => state.bankData,
  // profileData: state => state.profileData,
  // interestData: state => state.interestData
};

const actions = {
  clearData: ({ dispatch }) => {
    dispatch("influencerprofile/clearAll", true, { root: true });
    dispatch("agency/clearAll", true, { root: true });
    dispatch("brandprofile/clearAll", true, { root: true });
    dispatch("commonfunction/clearAll", true, { root: true });
  },

  register: ({ commit }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/register", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          commit("setSignupId", response.data.data.id);
          commit("setSignupResponse", response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  brandEnquiry: ({ commit }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/brand-enquiry", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          commit("setSignupId", response.data.data.id);
          commit("setSignupResponse", response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  verify: ({ commit, dispatch }, otpData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify", {
          ...otpData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          dispatch("user/setAuth", response.data.data, { root: true });
          dispatch("commonfunction/getcategorydata");
          setupAxiosDefaults(response.data.data);
          commit(
            "setIsAuthenticated",
            response.data.data.access_token !== null
          );
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  verifyemail: ({ state, commit }, emailData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/password/email", {
          ...emailData,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  resetpassword: ({ state, commit }, passwordData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/password/reset", {
          ...passwordData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  changepassword: ({ state, commit }, passwordData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/change-password", {
          ...passwordData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  company: ({ state }, companyData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/company", companyData, {
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(state);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  category: ({ state }, categoryData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/company-category-details", {
          ...categoryData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(state);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcategorydata: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/categories")
        .then((response) => {
          commit("setCategoryData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  newuser: ({ state, commit }, newuserData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user", {
          ...newuserData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  addSelectedIds: ({ commit }, data) => {
    commit("setSelectedIds", data);
  },

  googleregister: ({ commit, dispatch }, googledata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/sociallogin/google", {
          ...googledata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (response.data.data.access_token) {
            dispatch("user/setAuth", response.data.data, { root: true });
            dispatch("commonfunction/getlatestnotifications", true, {
              root: true,
            });
            dispatch("commonfunction/getallnotifications", true, {
              root: true,
            });

            setupAxiosDefaults(response.data.data);

            commit(
              "setIsAuthenticated",
              response.data.data.access_token !== null
            );
            // dispatch("user/fetchData", null, { root: true });
          }
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  facebookregister: ({ commit, dispatch }, facebookdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/sociallogin/facebook", {
          ...facebookdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (response.data.data.access_token) {
            dispatch("user/setAuth", response.data.data, { root: true });
            dispatch("commonfunction/getlatestnotifications", true, {
              root: true,
            });
            dispatch("commonfunction/getallnotifications", true, {
              root: true,
            });

            setupAxiosDefaults(response.data.data);

            commit(
              "setIsAuthenticated",
              response.data.data.access_token !== null
            );
            // dispatch("user/fetchData", null, { root: true });
          }
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  updateSingleuser: ({ state, commit }, updateuserData) => {
    commit("commonfunction/setLoading", true, { root: true });
    let user_id = updateuserData.id;
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.VUE_APP_ROOT_API + `/user/${user_id}`, {
          ...updateuserData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  newrole: ({ state, commit }, newroleData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/roles", {
          ...newroleData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getuserData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/user")
        .then((response) => {
          commit("setUserData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getrolesData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/roles")
        .then((response) => {
          commit("setRolesData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getcountryData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/country", {
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("setCountryData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getUomData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/uom", {
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("setUomData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  singleuserData: ({ commit }, id) => {
    let user_id = id.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + `/user/${user_id}`)
        .then((response) => {
          commit("setSingleId", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  multidelete: ({ commit }, iddata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-multiple-delete", {
          ...iddata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  rolemultidelete: ({ commit }, iddata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/role-multiple-delete", {
          ...iddata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  emailinvitation: ({ dispatch }, newuserData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/invitation", {
          ...newuserData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          dispatch("user/getemaillisting");
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getemaillisting: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/invitation")
        .then((response) => {
          commit("setEmailListing", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  fetchData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/me")
        .then((response) => {
          console.log(response);
          if (typeof response !== "undefined" && response.data) {
            console.log("setting current user", response.data.data);
            commit("setCurrentUser", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          console.log("user.js:106 my error");
          console.log(error);

          commit("setIsAuthenticated", false);
          commit("setCurrentUser", null);
          commit("setCurrentCoa", null);

          window.localStorage.setItem("auth", null);
          reject(error);
        });
    });
  },

  setAuth: ({ state }, auth) => {
    console.log(state);
    console.log(auth);
    console.log(JSON.stringify(auth));
    window.localStorage.setItem("auth", JSON.stringify(auth));
  },

  login: ({ commit, dispatch }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    console.log(user);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/login", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (response.data.data.access_token) {
            dispatch("user/setAuth", response.data.data, { root: true });
            dispatch("commonfunction/getlatestnotifications", true, {
              root: true,
            });
            dispatch("commonfunction/getallnotifications", true, {
              root: true,
            });

            setupAxiosDefaults(response.data.data);

            commit(
              "setIsAuthenticated",
              response.data.data.access_token !== null
            );
            // dispatch("user/fetchData", null, { root: true });
          }
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  verifyOTPRegLogin: ({ commit, dispatch }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    console.log(user);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verifyOTPRegLogin", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (response.data.data.access_token) {
            if (response.data.data.role_id!=6) {
              dispatch("user/setAuth", response.data.data, { root: true });
              dispatch("commonfunction/getlatestnotifications", true, {
                root: true,
              });
              dispatch("commonfunction/getallnotifications", true, {
                root: true,
              });
  
              setupAxiosDefaults(response.data.data);
  
              commit(
                "setIsAuthenticated",
                response.data.data.access_token !== null
              );
            }
          }
          // dispatch("user/fetchData", null, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  verifyOTPProfile: ({ commit, dispatch }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    console.log(user);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verifyOTPProfile", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          // dispatch("user/fetchData", null, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  addPhoneNumber: ({ commit, dispatch }, user) => {
    commit("commonfunction/setLoading", true, { root: true });
    console.log(user);
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/addPhoneNumber", {
          ...user,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          // if (response.data.data.access_token) {
          //   dispatch("user/setAuth", response.data.data, { root: true });
          //   dispatch("commonfunction/getlatestnotifications", true, {
          //     root: true,
          //   });
          //   dispatch("commonfunction/getallnotifications", true, {
          //     root: true,
          //   });

          //   setupAxiosDefaults(response.data.data);

          //   commit(
          //     "setIsAuthenticated",
          //     response.data.data.access_token !== null
          //   );
          // }
          // dispatch("user/fetchData", null, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  inviteInfluencer: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/invite-influencer", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(commit);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  signOut: ({ commit, state, dispatch }) => {
    // commit('commonfunction/setLoading', true, {root: true})
    return new Promise((resolve) => {
      if (state.isAuthenticated) {
        let auth = window.localStorage.getItem("auth");
        // console.log("1")

        if (auth !== null || auth !== "undefined") {
          // console.log("2");
          auth = JSON.parse(auth);
          // dispatch("user/setAuth", null, { root: true });
          window.localStorage.setItem("auth", null);
          dispatch("clearData");
          commit("setIsAuthenticated", false);
          // commit('commonfunction/setLoading', false, {root: true})
          router.push({ name: "Login" });
          return resolve(true);
          // console.log(auth)
          // if (auth) {
          //   axios
          //     .post(process.env.VUE_APP_ROOT_API + "/logout")
          //     .then((response) => {
          //       console.log(response);
          //       dispatch("user/setAuth", null, { root: true });
          //       commit("setIsAuthenticated", false);
          //       router.push({ name: "home" });
          //       return resolve(true);
          //     })
          //     .catch((error) => {
          //       console.error("Logout Error: ", error);
          //       return reject(error);
          //     })
          //     .finally(() => {
          //       commit("setLoading", false);
          //     });
          // }
        }
        // commit('commonfunction/setLoading', false, {root: true})
      }
      // commit('commonfunction/setLoading', false, {root: true})
      // dispatch("user/setAuth", null, { root: true });
      // resolve(true);
    });
  },

  refreshToken: ({ commit }) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX + "/oauth/token/refresh"
        )
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          commit("setCurrentUser", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          reject(error);
        });
    });
  },
  inviteDelOrReSend: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/invitation/actions", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(commit);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
  setCurrentUser: (state, value) => {
    state.currentUser = value;
  },
  setCurrentCoa: (state, value) => {
    state.currentCOA = value;
  },
  setRolesAll: (state, value) => {
    state.rolesAll = value;
  },
  setIsAuthenticated: (state, value) => {
    state.isAuthenticated = value;
  },
  setSignupId: (state, value) => {
    state.signupId = value;
  },
  setSignupResponse: (state, value) => {
    state.signupResponse = value;
  },
  setUserData: (state, value) => {
    state.userData = value;
  },
  setRolesData: (state, value) => {
    state.rolesData = value;
  },
  setSelectedIds: (state, value) => {
    state.selectedIds = value;
  },
  setSingleId: (state, value) => {
    state.singleData = value;
  },
  // setChargesData: (state, value) => {
  //   state.chargesData = value;
  // },
  // setBankData: (state, value) => {
  //   state.bankData = value;
  // },
  // setProfileData: (state, value) => {
  //   state.profileData =  value;
  // },
  // setInterestData: (state, value) => {
  //   state.interestData = value;
  // }
};

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  getters: getters,
  actions: actions
};
