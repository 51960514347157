import axios from "axios";

const AUTH_PARAMS = {
  grant_type: "password",
  client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
  client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
};

function initialState() {
    let blogList = [];
    let blogListLoader = [];
    return {
      blogList: blogList,
      blogListLoader: blogListLoader,
    };
}

const getters = {
  blogList: (state) => state.blogList,
  blogListLoader: (state) => state.blogListLoader,
};

const actions = {
    getBlogList: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/blog-list")
                .then((response) => {
                    commit("setBlogList", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },

    getBlogListWithLoader: ({ commit },loaddata) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/blog-page", { ...loaddata, ...AUTH_PARAMS })
                .then((response) => {
                    commit("setBlogListLoader", response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },

    getBlogDetail: ({commit},data) => {
        let slug = data.slug;
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/blog-data/"+slug)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });        
    },
};

const mutations = {
    setBlogList: (state, value) => {
        state.blogList = value;
    },
    setBlogListLoader: (state, value) => {
        state.blogListLoader = value;
    },
};

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  getters: getters,
  actions: actions,
};
