<template>
  <div @mouseover="selectmouseover" class="selectmenu">
    <div class="selectmenutxt">
      <text-component :class="txtclass">
        <slot />
        <sup v-if="required">*</sup>
      </text-component>
    </div>
    <div :class="inputclass?inputclass:'dropdownmenu'">
      <v-select
        :label="label"
        :multiple="multiple"
        @input="setSelected"
        :placeholder="placeholder"
        :options="options"
        :components="{Deselect,OpenIndicator}"
        :reduce="country => country.id"
        :disabled="disabled"
        v-model="selectedvalue"
      ></v-select>
    </div>
    <div v-if="helptext">
      <label :class="labelclass">{{helptext}}</label>
    </div>
  </div>
</template>
<script>
import TextComponent from "../Text/Text.vue";
export default {
  props: ["disabled","options", "placeholder", "multiple","value", "label","inputclass","required","helptext",
    "labelclass",],
  components: {
    TextComponent
  },
  data() {
    return {
      // selectedvalue: null,
      isOpen: false,
      txtclass: "dark",
      content: this.value,
      Deselect: {
        render: createElement => createElement("span", " ")
      },
      OpenIndicator: {
        render: createElement => createElement("span", " ")
      }
    };
  },
  methods: {
    setSelected(value) {
      this.$emit("handlevalue", value);
    },
    selectmouseover(value) {
      this.$emit("mouseover", value);
    }
  },
  computed: {
    selectedvalue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>