<template>
    <form @submit.prevent="handlePhoneSubmit">
        <div :class="{'modal-window': true , 'popupopen': isPhonePopup}">
            <div class="modal-window-main signup-page">
                <a title="Close" class="modal-close">
                    <img
                        @click="popUp"
                        src="../../../assets/image/cancel.svg"
                        class="cancel-icon"
                        alt="cancel-icon"
                    />
                </a>
                <div class="header">
                    <text-component :class="'h2'">Add Phone Number</text-component>
                </div>
                <div class="phnumberbox">
                    <input-group-component
                    required="true"
                    v-model="item.mobile_number"
                    :type="phnumberboxtype"
                    :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submittedphone  ? validationmessage.mobile_number: false"
                    :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submittedphone }"
                    :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submittedphone , 'default': true}"
                    >Phone number</input-group-component>
                    <div class="countrycode">
                        <dropdown-component
                            v-model="item.mobile_prefix"
                            :popoverList="popoverList"
                            :btnid="'countrybtn'"
                        >
                            +91
                            <!-- <img src="../../../assets/image/globe.svg" class="globeicon" /> -->
                        </dropdown-component>
                    </div>
                </div>
                <div class="modal-footer margin-top16">
                    <div>
                        <button-component buttontype="submit" :clsname="'btnprimary'">Add</button-component>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputGroupComponent from "../../../BasicComponents/Input Group/inputGroup";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";

import { mapActions } from "vuex";
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
    components: {
        TextComponent,
        ButtonComponent,
        InputGroupComponent,
        DropdownComponent,
    },
    props: ["userid","verify"],
    // computed: {
    //     ...mapGetters("brandcampaign", [
    //     "contentFormat",
    //     "influencerCategory",
    //     "singleCampaign"
    //     ]),
    //     ...mapGetters("commonfunction", ["platformData","countryData", "cityData"]),
    // },
    
    validations: {
        item: {
            // email: { required, email },
            mobile_number: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(13)
            },
            mobile_prefix: { required },
        }
    },
    data() {
        return {
            otp_screen:false,
            isPhonePopup:false,
            submittedphone: false,
            item:{
                email: null,
                mobile_prefix: '+91',
                mobile_number: null,
                password: null,
                user_role: null,
            },
            phnumberboxtype: "number",
            popoverList: [
                { id: "0", value: "+91" },
                { id: "1", value: "+92" },
                { id: "2", value: "+93" },
                { id: "3", value: "+94" }
            ],
            validationmessage: {
                mobile_number: "please enter valid phone number with prefix",
                password: "password is required",
                user_role: 'user role is required',
                otp: 'OTP is required',
                terms_condition: 'please agree to terms and conditions',
                requirements: 'Requirements is required'
            },
        }
    },
    methods:{
        ...mapActions("user", ["addPhoneNumber"]),
        handleUpdate(){
            console.log('handle')
        },

        popUp(){
            if (this.isPhonePopup == true) {
                this.isPhonePopup = false;
            } else {
                this.isPhonePopup = true;
            }  
        },
        handlePhoneSubmit(){
            // this.otp_screen = true;
            this.submittedphone = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                let vm = this;
                this.addPhoneNumber({
                    prefix: this.item.mobile_prefix,
                    phone: this.item.mobile_number,
                    id: this.userid
                })
                .then(response => {
                    this.popUp();
                    this.otp_screen = true;
                    this.verify.mobile_number = response.data.phone;
                    this.verify.mobile_prefix = response.data.prefix;
                    // vm.$router.push({ name: "Thankyou" });
                    // console.log("checking");
                })
                .catch(err => {
                    this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                });
            }
        }
    },
    created: function() {
        // console.log('created')
        this.$root.$on("openPopup", () => {
            console.log("openPopup")
            if (this.isPhonePopup == true) {
                this.isPhonePopup = false;
            } else {
                this.isPhonePopup = true;
            }
        });
    },
    beforeDestroy() {
        this.$root.$off("openPopup", this.fn);
    }
    
}
</script>