<template>
    <div class="actionbtn">
        <router-link to="influencerreportdetail"><button-component @onBtnClick="onClick(row)" :clsname="plaincls">View Insight</button-component></router-link>
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from '../../../../BasicComponents/Button/Button.vue';
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      plaincls: 'btnplain'
    };
  },
  methods: {
    onClick() {
      this.$root.$emit('onClick', this.row)
    }
  },
};
</script>
