<template>
  <div class="delete-img">
    <img @click="toDelete(row.id)" src="../../../assets/image/delete.svg" alt="delete-icon">
    <img @click="toEdit(row.id)" src="../../../assets/image/edit.svg" alt="edit-icon">
  </div>
</template>
<script>
// import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // ButtonComponent
  },
  props: ["row"],
  data() {
    return {
  
    };
  },
  methods: {
    ...mapActions("blogdata", ["deleteblog", "getbloglist"]),
    
    toEdit(id) {
      this.$router.push({name: "adminblogdetail",params: { id: id }});
    },

    toDelete(id){
      let deleteform = confirm("Are you sure?")
      if(deleteform){
        console.log(id)
        // this.$router.push({name: "adminblogdetail",params: { id: id }});
        this.deleteblog({id: id})
        .then(response => {
            console.log(response);
            this.getbloglist()
            this.$root.$emit('deleteClick', id)
            this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
            });
        })
        .catch(err => {
            this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
            });
        });
      }
    }
  }
};
</script>