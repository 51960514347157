<template>
  <div class="menudesktop">
    <div class="searchvendor-content-left smallMenu" id="desktopmenu">
      <div class="menu-inner">
        <div class="main-logo" id="mlogo">
          <img src="../assets/image/main-logo.png" alt="VidUnit-logo" class="vidunitlogo" />
        </div>
        <navigation-component :navdata="navdata"></navigation-component>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent,
  },
  created() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    // console.log('this.auth',auth)
    this.role = auth.role_id;
    this.sub_role = auth.sub_role_id;
    this.permissions = auth.permissions;
    // console.log(auth.permissions);
    this.getPermissionCheck();
  },

  data() {
    return {
      navdata: [
        {
          id: "16",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "admindashboard",
        },
        {
          id: "1",
          item: "Admin Data",
          imgpath: "admindata.png",
          child: [
            {
              id: "3",
              item: "Support",
              name: "adminchat",
              permKey: "support_list",
            },
            {
              id: "4",
              item: "Influencers",
              name: "admininfluencer",
              permKey: "influencer_list",
            },
            {
              id: "5",
              item: "Live Brand Campaigns",
              name: "Adminlivecampaign",
              permKey: "campaign_list",
            },
          ],
        },
        {
          id: "6",
          item: "Master Data",
          imgpath: "sidebar_master_data.svg",
          child: [
            {
              id: "7",
              item: "Manage Permissions",
              name: "Permissions",
              permKey: "permissions_list",
            },
            {
              id: "8",
              item: "Manage Roles",
              name: "Roles",
              permKey: "roles_list",
            },
            {
              id: "9",
              item: "Manage Users",
              name: "User",
              permKey: "users_list",
            },
            // {
            //   id: "10",
            //   item: "Manage Genres",
            //   name: "adminmanagegenres",
            //   permKey: "genre_list",
            // },
            {
              id: "12",
              item: "Manage Categories",
              name: "adminmanagecategory",
              permKey: "categories_list",
            },
          ],
        },
        {
          id: "13",
          item: "Influencers",
          imgpath: "sidebar_influencer.svg",
          // name: "admininfluencerdata",
          child: [
            {
              id: "131",
              item: "Influencers",
              name: "admininfluencerdata",
              permKey: "influencer_list",
            },
            {
              id: "132",
              item: "Influencer Categories",
              name: "admininfluencercategory",
              permKey: "influencer_category_list",
            },
          ],
        },
        {
          id: "14",
          item: "Agency Influencers",
          imgpath: "sidebar_influencer_agency.svg",
          name: "adminagencydata",
          permKey: "agency_influencer_list",
        },
        {
          id: "15",
          item: "Brands",
          imgpath: "sidebar_brand.svg",
          name: "adminbranddata",
          permKey: "brand_list",
        },
        // {
        //   id: "23",
        //   item: "Com. Manager",
        //   name: "admincommunitymanagerdata",
        //   imgpath: "sidebar_brand.svg",
        //   permKey: "community_manager_list",
        // },
        {
          id: "17",
          item: "Consents",
          imgpath: "sidebar_concents.svg",
          name: "adminconcents",
          permKey: "consent_list",
        },
        {
          id: "18",
          item: "Campaigns",
          imgpath: "sidebar_campaign.svg",
          name: "admincampaigns",
          permKey: "campaign_list",
        },
        {
          id: "19",
          item: "Rating",
          imgpath: "sidebar_rating.svg",
          name: "adminrating",
          permKey: "rating_list",
        },
        {
          id: "20",
          item: "Publishers",
          imgpath: "sidebar_publisher.svg",
          permKey: "publisher_list",
          child: [
            {
              id: "201",
              item: "Publisher List",
              name: "adminpublisherdata",
            },
            {
              id: "202",
              item: "Article List",
              name: "adminicontentlist",
            },
            {
              id: "203",
              item: "Website Traffic",
              name: "adminwebsitelist",
            },
            {
              id: "204",
              item: "Article Request",
              name: "admincontentrequestlist",
            },
          ],
        },
        {
          id: "21",
          item: "Manage Payments",
          imgpath: "invoice.svg",
          name: "admininvoice",
          permKey: "invoice_list",
        },
        {
          id: "22",
          item: "Front Data",
          imgpath: "sidebar_master_data.svg",
          permKey: "front_data_list",
          child: [
            {
              id: "221",
              item: "Website Setting List",
              name: "adminwebsitesetting",
            },
            {
              id: "222",
              item: "Testimonial List",
              name: "admintestimoniallist",
            },
            {
              id: "223",
              item: "Celebrity Client List",
              name: "admincelebrityclient",
            },
            {
              id: "224",
              item: "CMS List",
              name: "admincmslist",
            },
            {
              id: "225",
              item: "Blog List",
              name: "adminbloglist",
            },
          ],
        },
      ],
    };
  },
  methods: {
    getPermissionCheck: function () {
      console.log('this.role',this.role)
      let navdata = [];
      if (this.role == 1) {
        navdata = this.navdata;
        if(this.sub_role!=undefined){
          for (let nav in navdata) {
            // console.log('nav',navdata[nav].item)
            if (navdata[nav].permKey) {
              navdata[nav].hasPermission = this.checkHasPermission(
                navdata[nav].permKey
              );
            } else {
              // navdata[nav].hasPermission = true;
            }
            if (navdata[nav].child) {
              let havePermission = false;
              let childis = navdata[nav].child;
              for (let child in childis) {
                navdata[nav].child[child].permKey;
                if (navdata[nav].child[child].permKey) {
                  navdata[nav].child[child].hasPermission = this.checkHasPermission(
                    navdata[nav].child[child].permKey
                  );
                  if(navdata[nav].child[child].hasPermission){
                    havePermission = true;
                  }
                } else {
                  navdata[nav].child[child].hasPermission = true;
                  havePermission = true;
                }
              }
              if(navdata[nav].hasPermission==undefined){
                navdata[nav].hasPermission = havePermission;
              }
            }
          }
        }else{
          for (let nav in navdata) {
            // console.log('nav',navdata[nav].item)
            navdata[nav].hasPermission = true;
            if (navdata[nav].child) {
              let childis = navdata[nav].child;
              for (let child in childis) {
                navdata[nav].child[child].hasPermission = true;
              }
            }
          }
        }
        this.navdata = navdata
        console.log('this.navdata',this.navdata)
      }
    },
    // getPermissionCheck: function () {
    //   console.log('this.role',this.sub_role)
    //   let navdata = [];
    //   if (this.role == 3) {
    //     navdata = this.navdata2;
    //   } else if (this.role == 2) {
    //     navdata = this.navdata;
    //   }
    //   for (let nav in navdata) {
    //     // console.log('nav',navdata[nav].item)
    //     if (navdata[nav].permKey) {
    //       navdata[nav].hasPermission = this.checkHasPermission(
    //         navdata[nav].permKey
    //       );
    //     } else {
    //       navdata[nav].hasPermission = true;
    //     }
    //     if (navdata[nav].child) {
    //       let childis = navdata[nav].child;
    //       for (let child in childis) {
    //         navdata[nav].child[child].permKey;
    //         if (navdata[nav].child[child].permKey) {
    //           navdata[nav].child[child].hasPermission = this.checkHasPermission(
    //             navdata[nav].child[child].permKey
    //           );
    //         } else {
    //           navdata[nav].child[child].hasPermission = true;
    //         }
    //       }
    //     }
    //   }
    //   if (this.role == 3) {
    //     this.navdata2 = navdata;
    //   } else if (this.role == 2) {
    //     this.navdata = navdata;
    //   }
    //   console.log('this.navdata',this.navdata)
    // },
    checkHasPermission: function (item) {
      let returnis = false;
      let permissions = this.permissions;
      if (permissions.indexOf(item) != -1 || this.sub_role == 0) {
        returnis = true;
      }
      return returnis;
    },
  },
};
</script>
