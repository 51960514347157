<template>
  <div>
    <div class="progres-main">
      <div class="progres-main-inner" v-for="pdata in progressdata" :key="pdata.progressid">

        <div :class="progressclass">
          <div class="progressnumber">
            {{ pdata.progressid }}
          </div>
          <div class="progressnumber-finished">
            <span>L</span>
          </div>
          <div class="progressname">
            {{pdata.progressname}}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
    name : "Progress",
    props :{
        progressdata: Array,
        progressclass: String
    }
}; 
</script>


