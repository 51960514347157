<template>
  <div class="campaign-detail-section">
    <div class="row campaign-detail-section-main">
      <div class="col-12 col-xl-8 campaign-data">
        <div class="campaign-data-header">
          <text-component class="subtitle2">Campaign Name</text-component>
          <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
          <div class="campaign-data-header-btn">
            <badge-component class="status-badge" :badgeclass="badgeclass">{{badgetext}}</badge-component>
          </div>
        </div>
        <div class="camp-detail-header row margino">
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Description:</text-component>
            <text-component class="black pre-white-space campaign-data-txt">{{campaignData.discription}}</text-component>
          </div>
        </div>
        <div class="camp-detail-data row margino">
          <div class="campaign-data-main col-12" v-if="campaignData.hashtags">
            <text-component class="subtitle2">Campaign Hashtags</text-component>
            <text-component class="black tags-card campaign-data-txt"
              v-for="(htags,index) in campaignData.hashtags" :key="index"
              :style="{ background: colors[Math.floor(Math.random() * 6)]}">
              {{htags}}
            </text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Start Date & End Date</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Influencer category</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getCategory(campaignData.influencer_category)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Number of Influencers</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Gender Ratio</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}:{{campaignData.female_ratio}} (M:F)</text-component>
          </div>
          <div class="campaign-data-main col-12">
            <text-component class="subtitle2">Social Media Platform</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Content Format</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getContentFormat(campaignData.content_format)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brand</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.user.name}}</text-component>
          </div>
          <div class="campaign-data-main col-12" v-if="campaignData.brand_hashtags">
            <text-component class="subtitle2">Brand Hashtags</text-component>
            <text-component class="black tags-card campaign-data-txt"
              v-for="(btags,index) in campaignData.brand_hashtags" :key="index"
              :style="{ background: colors[Math.floor(Math.random() * 6)]}">
              {{btags}}
            </text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Budget</text-component>
            <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
          </div>
          <!-- <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Name</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.campaign_name}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Gender Ratio-Male</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.male_ratio}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Gender Ratio-Female</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.female_ratio}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Start Date & End Date</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{formatDateGlobal(campaignData.start_date)}} ~ {{formatDateGlobal(campaignData.end_date)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Number of Influencers</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.influencers}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Taget Age</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.target_age}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Country</text-component>
            <text-component class="black campaign-data-txt">{{getCountryName(campaignData.country)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Cities</text-component>
            <text-component class="black campaign-data-txt">{{getCityName(campaignData.city)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Influencer category</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getCategory(campaignData.influencer_category)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Social Media Platform</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getSocialPlatform(campaignData.social_platform)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Content Format</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{getContentFormat(campaignData.content_format)}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Hashtags</text-component>
            <text-component
              class="black campaign-data-txt"
              v-if="campaignData.hashtags && campaignData.hashtags.length"
            >{{campaignData.hashtags.toString()}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Campaign Budget</text-component>
            <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brands</text-component>
            <text-component
              class="black campaign-data-txt"
              v-if="campaignData.brand_hashtags && campaignData.brand_hashtags.length"
            >{{campaignData.brand_hashtags.toString()}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brief Description</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.discription}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Deliverable</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.deliverable}}</text-component>
          </div> -->
        </div>
        <div class="campaign-data-header brand">
          <text-component class="subtitle1">Brand detail</text-component>
        </div>
        <div class="row margino" v-if="campaignData.user">
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Brand Name</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.user.name}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Email</text-component>
            <text-component class="black campaign-data-txt">{{campaignData.user.email}}</text-component>
          </div>
          <div class="campaign-data-main col-12 col-lg-6">
            <text-component class="subtitle2">Contact No.</text-component>
            <text-component
              class="black campaign-data-txt"
            >{{campaignData.user.prefix}} {{campaignData.user.phone}}</text-component>
          </div>
        </div>
        <div class="row margino">
          <div class="datatable-main">
            <div class="table-header">
              <text-component class="subtitle1">Campaign Insights</text-component>
            </div>
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :columns="columns"
              :rows="rows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="filter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
        <div class="row margino">
          <div class="datatable-main">
            <div class="table-header finance">
              <div class="row margino">
                <text-component class="subtitle1 paddingo col-12 col-lg-4">Campaign finance detail</text-component>
                <div class="row margino col-lg-8 paddingo">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Campaign Budget</text-component>
                    <text-component class="black campaign-data-txt">₹ {{campaignData.budget}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Total Cost</text-component>
                    <text-component class="black campaign-data-txt">₹ {{getTotalCost(financerows)}}</text-component>
                  </div>
                </div>
              </div>
            </div>
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :columns="financecolumns"
              :rows="financerows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="financefilter"
              :page="page"
              :per_page="per_page"
            ></datatable-component>
          </div>
        </div>
      </div>
      <div class="col-4 campaign-chat" id="chatsection">
        <campaign-chat :brandId="campaignData.created_by"></campaign-chat>
        <div @click="openChat" class="chat-btn">
          <img click src="../../../../assets/image/chat.svg" class="chat-btn-img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import BadgeComponent from "../../../../BasicComponents/Badge/Badge.vue";
import Action from "./campdetailaction.vue";
import usericon from "./campdetailicon.vue";
import FinanceAction from "./campfinanceaction.vue";
import Financeusericon from "./campfinanceicon.vue";
import Link from "./campdetaillink.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import CampaignChat from "./campaignchat.vue";
import status from "./linkstatusbadge.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
    CampaignChat,
    BadgeComponent
  },
  computed: {
    ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
    ...mapGetters("commonfunction", [
      "platformData",
      "countryData",
      "cityData",
    ]),
  },
  data() {
    return {
      colors: ["#AAEEF6", "#FFB480", "#BDC5ED", "#FFE474", "#BBE5B3", "#FFC4BE"],
      selectedRows: [],
      campaign_id: null,
      campaignData: [],
      influencecat: [],
      socialplatform: [],
      badgeclass: null,
      badgetext: null,
      inputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      financefilter: "",
      page: 1,
      per_page: 8,
      columns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: usericon,
        },
        {
          label: "Influencer's Name",
          field: "user.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Platform",
          field: "social_platform.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Link",
          headerAlign: "left",
          align: "left",
          headerClass: "width-5",
          interpolate: true,
          sortable: false,
          representedAs: (row) =>
            `<a href="http://${row.link}" style="text-decoration: none; color: #F49342;" target="_blank">link</a>`,
        },
        { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
        {
          label: "Action",
          headerAlign: "left",
          align: "left",
          component: Action,
        },
      ],
      rows: [],
      financecolumns: [
        {
          label: "Profile Photo",
          headerAlign: "left",
          align: "left",
          component: Financeusericon,
        },
        {
          label: "Influencer's Name",
          field: "user_profile.full_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Amount",
          field: "final_price",
          align: "right",
          headerAlign: "right",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        // { label: "Action",headerAlign: "left", align: "left", component: (FinanceAction)},
      ],
      financerows: [],
    };
  },
  watch: {
    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = influencerCategory;
      },
    },
    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = platformData;
      },
    },
  },
  created: function () {
    this.getData();
    this.getcampaigninsights();
    this.getcampaignfinance();
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "getcampaignbasic",
      "getCampaignFinancelist",
    ]),
    ...mapActions("commonfunction", ["getcity"]),
    ...mapActions("influencercampaign", ["getLinklist"]),

    getTotalCost(data) {
      let sum = 0;
      if (data) {
        data.map((value) => {
          sum += value.final_price;
        });
        return sum;
      } else {
        return sum;
      }
    },

    getcampaigninsights() {
      this.getLinklist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.rows = response.data;
      });
    },

    getcampaignfinance() {
      this.getCampaignFinancelist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.financerows = response.data;
      });
    },

    getData() {
      this.campaign_id = this.$route.params.id;
      this.getcampaignbasic({ id: this.$route.params.id })
        .then((response) => {
          this.campaignData = response.data;
          this.sendCountryid(response.data.country);
          if(this.campaignData.status == '1'){
            this.badgeclass = 'Success-Badge'
            this.badgetext = 'In Progress'
          }
          else if(this.campaignData.status == '2'){
            this.badgeclass = 'Warning-Badge'
            this.badgetext = 'Rejected'
          }
          else if(this.campaignData.status == '3'){
            this.badgeclass = 'Default-Badge'
            this.badgetext = 'Completed'
          }
          else{
            this.badgeclass = 'Attention-Badge'
            this.badgetext = 'Pending'
          }
        })
        .catch((error) => {
          this.campaignData = [];
        });
    },

    sendCountryid(data) {
      if (data) {
        this.getcity({
          id: data,
        });
      }
    },
    getSocialPlatform(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.socialplatform.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getCategory(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.influencecat.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getContentFormat(id) {
      if (id) {
        let name = this.contentFormat.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCountryName(id) {
      if (id) {
        let name = this.countryData.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },

    getCityName(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.cityData.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    openChat() {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },
  },
};
</script>