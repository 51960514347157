import BrandLayoutMain from '../layoutsbrand/main.vue';
import BrandProfile from '../pages/brand/profile/myprofile.vue';
import BrandDashboard from '../pages/brand/dashboard/dashboard.vue';
import Brandnotification from '../pages/brand/notification/notification.vue';
import BrandCampaigns from '../pages/brand/campaign/campaign.vue';
import BrandInfluencerReport from '../pages/brand/report/influencer/influencerreport.vue';
import BrandInfluencerReportDetail from '../pages/brand/report/influencer/influencerreportdetail.vue';
import BrandCampaignReport from '../pages/brand/report/campaign/campaignreport.vue';
import BrandCampaignReportDetail from '../pages/brand/report/campaign/campaignreportdetail.vue';
import AuthBrandAgency from "../utils/AuthBrandAgency";
import grapgcircle from "../BasicComponents/graph/graphcircle.vue";
import BrandRating from "../pages/brand/rating/rating.vue";
import BrandRatingDetail from "../pages/brand/rating/ratingdetail.vue";
import Support from "../pages/brand/support/support.vue";
import SupportChat from "../pages/brand/support/chatdetail.vue"
import BrandCampaignDetail from "../pages/brand/campaign/detail/main.vue";
import BrandInfluencerData from "../pages/brand/influencer/influencer.vue";
import BrandInfluencerDetail from "../pages/brand/influencer/influencerdetail.vue";

export default {
    path: "/brand",
    component: BrandLayoutMain,
    beforeEnter:AuthBrandAgency,
    meta: {
        title: "VidUnit",
    },
    children: [
        {
            path: "/brand/myprofile",
            name: "brandprofile",
            component: BrandProfile,
        },
        {
            path: "/brand/dashboard",
            name: "branddashboard",
            component: BrandDashboard,
        },
        {
            path: "/brand/notification",
            name: "brandnotifications",
            component: Brandnotification,
        },
        {
            path: "/brand/campaigns",
            name: "brandcampaigns",
            component: BrandCampaigns,
        },
        {
            path: "/brand/influencerreport",
            name: "brandinfluencerreport",
            component: BrandInfluencerReport,
        },
        {
            path: "/brand/influencerreportdetail",
            name: "influencerreportdetail",
            component: BrandInfluencerReportDetail,
        },
        {
            path: "/brand/brandcampaignreport",
            name: "brandcampaignreport",
            component: BrandCampaignReport,
        },
        {
            path: "/brand/campaignreportdetail/:id",
            name: "campaignreportdetail",
            component: BrandCampaignReportDetail,
        },
        {
            path: "/brand/campaigndetail/:id",
            name: "brandcampaigndetail",
            component: BrandCampaignDetail,
        },
        {
            path: "/brand/grapgcircle",
            name: "grapgcircle",
            component: grapgcircle,
        },
        {
            path: "/brand/rating",
            name: "brandrating",
            component: BrandRating,
        },
        {
            path: "/brand/ratingdetail/:id",
            name: "brandratingdetail",
            component: BrandRatingDetail,
        },
        {
            path: "/brand/support",
            name: "brandsupport",
            component: Support,
        },
        {
            path: "/brand/supportchat/:id",
            name: "brandsupportchat",
            component: SupportChat,
        },
        {
            path: "/brand/influencerdata",
            name: "brandinfluencerdata",
            component: BrandInfluencerData,
        },
        {
            path: "/brand/influencerdetail/:id",
            name: "brandifluencerdetail",
            component: BrandInfluencerDetail,
        },
    ]
}