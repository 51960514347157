<template>
	<div>
		<div class="influencerreportdetail brand campaignlist">
			<div class="content-header">
                <div class="page-header">
                    <text-component :class="h2txtclass">Influencer Report</text-component> 
                    <!-- <div class="page-header-right">
                        <button-component :clsname="btninvitecls">Generate Report</button-component>
                    </div> -->
                </div>
            </div>
            <div class="content">
                <personal-detail></personal-detail>
                <social-accounts></social-accounts>
                <social-insights></social-insights>
                <charges-detail></charges-detail>
                <bank-detail></bank-detail>
                <interest-detail></interest-detail>
                <campaign-detail></campaign-detail>
            </div>
		</div>
	</div>
</template>


<script>

import TextComponent from "../../../BasicComponents/Text/Text.vue";
// import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import TabComponent from "../../../BasicComponents/Tabs/Tabs.vue";
// import RatingComponent from "../../../BasicComponents/rating/rating.vue";
import PersonalDetail from "./detail/personaldetial.vue";
import SocialInsights from "./detail/socialinsights.vue";
import SocialAccounts from "./detail/socialaccounts.vue";
import ChargesDetail from "./detail/chargesdetail.vue";
import BankDetail from "./detail/bankdetail.vue";
import InterestDetail from "./detail/interestdetail.vue";
import CampaignDetail from "./detail/campaigndetail.vue";
import { eventBus } from "../../../main";
// import SocialAccounts from './detail/social-accounts.vue';

export default {
    props: {},
    components: {
        TextComponent,
        // ButtonComponent,
        // TabComponent,
        // RatingComponent,
        PersonalDetail,
        SocialInsights,
        // SocialAcount,
        ChargesDetail,
        BankDetail,
        InterestDetail,
        CampaignDetail,
        SocialAccounts,
    },
    data() {
        return {
            btninvitecls: "btnprimary",
            h2txtclass: 'h2',
        };
    },
};
</script>
