<template>
    <div class="menumobile" id="mobilemenu">
    <div class="searchvendor-content-left">
      <div class="menu-inner">
        <div class="main-logo">
          <img src="../assets/image/main-logo.png" alt="VidUnit-logo"/>
        </div>
        <navigation-component :navdata="navdata"></navigation-component>
      </div>
    </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "51",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "publisherdashboard"
        },
        {
          id: "52",
          item: "Publish Content",
          imgpath: "report.svg",
          name: "publishercontentlist"
        },
        {
          id: "53",
          item: "Website Traffic",
          imgpath: "report.svg",
          name: "publisherwebsitetrafficlist"
        },
        {
          id: "54",
          item: "Support",
          imgpath: "chat.svg",
          name: "publishersupport"
        },
      ]
    };
  },

  methods: {

  },
};
</script>