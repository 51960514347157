<template>
    <div class="edit-image" v-if="checkPermission('categories_update')">
        <img @click="getEditData(row)" class="noteditable" src="../../../../assets/image/edit.svg" alt="edit-image"/>
    </div>
</template>

<script>
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {

    };
  },
  methods: { 
    getEditData(row) {
      this.$root.$emit('getEditData', row)
    }
  },
};
</script>
