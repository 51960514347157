<template>
  <form @submit.prevent="handleRegistration">
    <div class="signup-page">
      <div class="row">
        <div class="col-12 col-md-6 signup-left">
          <text-component :textclass="h2class">Register as a Publisher</text-component>
          <div class="row rowpadding noaccount">
            <text-component class="mid">Already have an account?</text-component>
            <router-link to="login">
              <button-component class="btnlogin" :clsname="btnplainclass">Log in</button-component>
            </router-link>
          </div>
          <input-component
            required="true"
            class="txtgroup"
            v-model="item.fullname"
            :helptext="!$v.item.fullname.required && submitted ? validationmessage.fullname: false"
            :labelclass="{'validationerr': !$v.item.fullname.required && submitted}"
            :classname="{'error': !$v.item.fullname.required && submitted, 'default': true}"
          >Brand Name</input-component>
          <input-component
            required="true"
            class="txtgroup"
            v-model="item.email"
            :helptext="!$v.item.email.required && submitted ? validationmessage.email: false"
            :labelclass="{'validationerr': !$v.item.email.required && submitted}"
            :classname="{'error': !$v.item.email.required && submitted, 'default': true}"
          >Email address</input-component>
          <div class="phnumberbox">
            <input-group-component
              required="true"
              v-model="item.mobile_number"
              :type="phnumberboxtype"
              :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.mobile_number: false"
              :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted}"
              :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
            >Phone number</input-group-component>
            <div class="countrycode">
              <dropdown-component
                v-model="item.mobile_prefix"
                :popoverList="popoverList"
                :btnid="btnid"
              > 
                +91
                <!-- <img src="../../../assets/image/globe.svg" class="globeicon" /> -->
              </dropdown-component>
            </div>
          </div>
          <input-component
            required="true"
            class="txtgroup"
            v-model="item.password"
            :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
            :labelclass="{'validationerr': !$v.item.password.required && submitted}"
            :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
            :type="passwordtype"
          >Password</input-component>
          <button-component class="signupbtn" buttontype="submit" :clsname="btnprimaryclass">Sign Up</button-component>
          <div class="divider">
            <div class="divider-txt">
              <span>OR</span>
            </div>
            <hr class="hrdivider" />
          </div>
          <div class="row rowpadding">
            <div class="col-6 colpadding innerpad1">
              <button-component
                class="social-login-btn-f"
                :clsname="btnregularclass"
                @onBtnClick="loginWithFacebook"
              >
                <img src="../../../assets/image/facebook.jpg" class="social-login-btn-img" />
                <div class="socialbtntxt">Facebook</div>
              </button-component>
            </div>
            <div class="col-6 colpadding innerpad2">
              <button-component
                class="social-login-btn-g"
                :clsname="btnregularclass"
                @onBtnClick="loginWithGoogle"
              >
                <img src="../../../assets/image/google.jpg" class="social-login-btn-img" />
                <div class="socialbtntxt">Google</div>
              </button-component>
            </div>
          </div>
        </div>
        <div class="col-6 signup-right"></div>
      </div>
    </div>
  </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import InputGroupComponent from "../../../BasicComponents/Input Group/inputGroup";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";

import { initFbsdk } from "@/config/facebook_oAuth.js";
// import axios from 'axios';
import { mapActions } from "vuex";
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    InputGroupComponent
  },
  validations: {
    item: {
      fullname: { required },
      email: { required, email },
      mobile_number: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(13)
      },
      mobile_prefix: { required },
      password: { required }
    }
  },
  mounted() {
    initFbsdk();
  },
  data() {
    return {
      item: {
        fullname: null,
        email: null,
        mobile_prefix: '+91',
        mobile_number: null,
        password: null
      },
      validationmessage: {
        fullname: "full name is required",
        email: "please enter valid email",
        mobile_number: "please enter valid phone number with prefix",
        password: "password is required"
      },
      submitted: false,
      checkphno: "",
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnbasic",
      btnprimaryclass: "btnprimary",
      popoverList: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" }
      ],
      phnumberboxtype: "number",
      inputgroupclassname: "default",
      btnid: "countrybtn"
    };
  },

  methods: {
    ...mapActions("user", ["register", "googleregister", "facebookregister"]),

    loginWithGoogle: async function() {
      let vm = this;
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          let googleid = GoogleUser.getId();
          let basicprofile = GoogleUser.getBasicProfile();
          let authresponse = GoogleUser.getAuthResponse();
          let name = basicprofile.getName();
          let email = basicprofile.getEmail();
          basicprofile.email = email;
          basicprofile.name = name;
          basicprofile.googleid = googleid;
          vm.googleregister({
            googleid: googleid,
            basicprofile: basicprofile,
            authresponse: authresponse,
            role_id: "5"
          })
            .then(response => {
              console.log(response);
              vm.$router.push({ name: "Thankyou" });
              console.log("checking");
            })
            .catch(err => {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
          console.log("GoogleUser", GoogleUser);
          console.log("getId", GoogleUser.getId());
          console.log("getBasicProfile", GoogleUser.getBasicProfile());
          console.log("getAuthResponse", GoogleUser.getAuthResponse());
        })
        .catch(error => {
          console.log("error", error);
        });
    },

    loginWithFacebook() {
      let vm = this;
      window.FB.login(response => {
        let authresponse = response;
        console.log("fb response", response);
        window.FB.api("/me?fields=email,name", function(response) {
          let basicprofile = response;
          vm.facebookregister({
            authresponse: authresponse,
            basicprofile: basicprofile,
            role_id: "5"
          })
            .then(response => {
              console.log(response);
              vm.$router.push({ name: "Thankyou" });
              console.log("checking");
            })
            .catch(err => {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top"
              });
            });
          // console.log("me response:", response);
          // console.log("Good to see you, " + response.email + ".");
        });
      });
    },

    handleRegistration() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.register({
          name: this.item.fullname,
          prefix: this.item.mobile_prefix,
          phone: this.item.mobile_number,
          email: this.item.email,
          password: this.item.password,
          role_id: "5"
        })
          .then(response => {
            console.log(response);
            vm.$router.push({ name: "Thankyou" });
            console.log("checking");
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    }
  }
};
</script>