<template>
  <div class="personaldetail boxmargin">
    <form @submit.prevent="handleCharges">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Charges Details</text-component>
            <div class="myprofile-header-right">
              <button-component
                v-if="!IsChargeDetailEditable"
                @onBtnClick="chargeDetailToggle"
                :clsname="basicclass">
                <div v-if="isChargedata.length <= 0">
                  Add
                </div>
                <div v-else>
                  Edit
                </div>
                </button-component>
            </div>
          </div>
          <div v-if="!IsChargeDetailEditable" class="personaldetail-saved">
            <div v-if="isChargedata.length <= 0">
              <p style="text-align: center;">Currently no charges are Added</p>
            </div>
            <div v-else class="row margino chargedetail" v-for="(data, index) in chargesdata" :key="index">
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Platform</text-component>
                  <text-component :class="black">{{getplatformname(data.platform)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Language</text-component>
                  <text-component :class="black">{{getlanguagename(data.language)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Minimum Charges</text-component>
                  <text-component :class="black">₹{{data.min_charge}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Maximum Charges</text-component>
                  <text-component :class="black">₹{{data.max_charge}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Charges inclusive GST</text-component>
                  <text-component :class="black">{{getInclusiveGst(data.inclusive_gst)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row">
                  <text-component :class="subtitle2class">Available for Barter</text-component>
                  <text-component :class="black">{{getBarter(data.barter)}}</text-component>
                </div>
              </div>
            </div>
            <div class="row margino"></div>
          </div>
          <div v-if="IsChargeDetailEditable" class="personaldetail-editable">
            <div v-for="(data, index) in item" :key="index">
              <div class="row">
                <div class="row col-11 edit-detail">
                  <select-component
                    label="name"
                    class="txtgroup col-12 col-sm-6 col-lg-3"
                    :options="platform"
                    required="true"
                    :helptext="!$v.item.$each[index].platform.required && submitted ? validationmessage.platform: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].platform.required && submitted}"
                    :inputclass="{'error': !$v.item.$each[index].platform.required && submitted, 'dropdownmenu': true}"
                    v-model="item[index].platform"
                    :multiple="false"
                    :placeholder="selectplaceholder"
                  >Platform</select-component>
                  <select-component
                    label="language"
                    class="txtgroup col-12 col-sm-6 col-lg-3"
                    :options="language"
                    v-model="item[index].language"
                    required="true"
                    :helptext="!$v.item.$each[index].language.required && submitted ? validationmessage.language: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].language.required && submitted}"
                    :inputclass="{'error': !$v.item.$each[index].language.required && submitted, 'dropdownmenu': true}"
                    :multiple="false"
                    :placeholder="selectplaceholder"
                  >Language</select-component>
                  <input-component
                    required="true"
                    type='number'
                    class="txtgroup amountinputbox col-12 col-sm-6 col-lg-3"
                    :helptext="(!$v.item.$each[index].min_charge.required) && submitted ? validationmessage.min_charge:((!$v.item.$each[index].min_charge.minValue) && submitted ? validationmessage.min_charge_minVal: false)"
                    :labelclass="{'validationerr': (!$v.item.$each[index].min_charge.required || !$v.item.$each[index].min_charge.minValue) && submitted}"
                    :classname="{'error': (!$v.item.$each[index].min_charge.required || !$v.item.$each[index].min_charge.minValue) && submitted, 'default': true}"
                    v-model="item[index].min_charge"
                  >Minimun Charges</input-component>
                  <input-component
                    required="true"
                    class="txtgroup amountinputbox col-12 col-sm-6 col-lg-3"
                    :helptext="(!$v.item.$each[index].max_charge.required) && submitted ? validationmessage.max_charge:((!$v.item.$each[index].max_charge.minValue) && submitted ? validationmessage.max_charge_minVal: false)"
                    :labelclass="{'validationerr': (!$v.item.$each[index].max_charge.required || !$v.item.$each[index].max_charge.minValue) && submitted}"
                    :classname="{'error': (!$v.item.$each[index].max_charge.required || !$v.item.$each[index].max_charge.minValue) && submitted, 'default': true}"
                    v-model="item[index].max_charge"
                  >Maximum Charges</input-component>
                  <div class="row width margino">
                    <checkbox-component
                      class="col-6 col-xl-3 col-lg-4 col-md-6 col-sm-6"
                      v-model="item[index].inclusive_gst"
                      :checkboxselectid="chargecbid"
                    >Charges inclusive GST</checkbox-component>
                    <checkbox-component
                      class="col-6 col-xl-3 col-lg-4 col-md-6 col-sm-6"
                      :checkboxselectid="bartercbid"
                      v-model="item[index].barter"
                    >Available for Barter</checkbox-component>
                  </div>
                </div>
                <div class="col-1 edit-icon" 
                  v-if="index < Object.keys(item).length - 1 && item.length != 1">
                  <!-- <img
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="edit-icon-main"
                    @click="addrowclicked()"
                  /> -->
                  <img
                    src="../../../assets/image/minus.svg"
                    alt="plus-circle"
                    class="edit-icon-main plus"
                    @click="removerowclicked(data,index)"
                  />
                </div>
                <div class="col-1 edit-icon" 
                  v-if="index == Object.keys(item).length - 1 && item.length != 1">
                  <img
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="edit-icon-main plus"
                    @click="addrowclicked()"
                  />
                  <img
                    src="../../../assets/image/minus.svg"
                    alt="plus-circle"
                    class="edit-icon-main minus"
                    @click="removerowclicked(data,index)"
                  />
                </div>
                <div class="col-1 edit-icon" 
                  v-if="index == Object.keys(item).length - 1 && item.length == 1">
                  <img
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="edit-icon-main plus"
                    @click="addrowclicked()"
                  />
                  <!-- <img
                    src="../../../assets/image/minus.svg"
                    alt="plus-circle"
                    class="edit-icon-main minus"
                    @click="removerowclicked(data,index)"
                  /> -->
                </div>
              </div>
              <hr class="hrline-footer" />
            </div>
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import CheckboxComponent from "../../../BasicComponents/Checkbox/defaultCheckbox";
import { mapGetters, mapActions } from "vuex";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
  },
  validations: {
    item: {
      $each: {
        platform: { required },
        // activity: { required },
        language: { required },
        min_charge: { required, minValue: minValue(1) },
        max_charge: { required, minValue: minValue(1) },
      },
    },
  },
  computed: {
    ...mapGetters("influencerprofile", ["chargesData"]),
    ...mapGetters("commonfunction", ["platformData", "languageData" , "percentData"]),
  },
  watch: {
    chargesData: {
      immediate: true,
      handler(chargesData) {
        if (chargesData.length) {
          this.isChargedata = chargesData
          this.chargesdata = chargesData;
          for (let data in chargesData) {
            this.item[data] = {
              platform: chargesData[data].platform,
              activity: chargesData[data].activity,
              language: chargesData[data].language,
              min_charge: chargesData[data].min_charge,
              max_charge: chargesData[data].max_charge,
              inclusive_gst: chargesData[data].inclusive_gst,
              barter: chargesData[data].barter,
            };

            if (this.item[data].inclusive_gst == "0") {
              this.item[data].inclusive_gst = false;
            }
            if (this.item[data].barter == "0") {
              this.item[data].barter = false;
            }
          }
        }
        else{
          this.isChargedata = []
        }
      },
    },

    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.platform = platformData;
      },
    },

    languageData: {
      immediate: true,
      handler(languageData) {
        this.$data.language = languageData;
      },
    },

    watch: {
    percentData: {
      immediate: true,
      handler(percentData) {
        this.progress = percentData.total_per;
        this.$data.tips = this.gettips(percentData.tips);
      }
    }
  },
  },
  data() {
    return {
      chargesdata: {},
      item: [
        {
          platform: null,
          // activity: null,
          language: null,
          min_charge: null,
          max_charge: null,
          inclusive_gst: null,
          barter: null,
        },
      ],
      validationmessage: {
        platform: "platform is required",
        activity: "activity is required",
        language: "language is required",
        min_charge: "minimum charge is required",
        min_charge_minVal: "minimum charge need to greater than 0",
        max_charge: "maximum charge is required",
        max_charge_minVal: "maximum charge need to greater than 0",
      },
      submitted: false,
      addonerow: false,
      isChargedata: [],
      IsChargeDetailEditable: false,
      classname: "default",
      inputid: "startdate",
      genderval: "",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      btnid: "countrybtn",
      phnumberboxtype: "number",
      chargecbid: "1",
      bartercbid: "2",
      imageurl: "usericon.png",
      fileuploadid: "profileuploader",
      selectplaceholder: "Select...",
      usermail: "",
      gender: "",
      dob: "",
      email: "",
      phno: "",
      country: "",
      city: "",
      platform: [],
      activity: "",
      language: [],
      mincharge: "",
      maxcharge: "",
      countrylist: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" },
      ],
      genderoption: [
        {
          id: "1",
          label: "Male",
        },
        {
          id: "2",
          label: "Female",
        },
        {
          id: "3",
          label: "Others",
        },
      ],

      chargedata: [
        {
          child: [
            {
              title: "Platform",
              value: "Instagram",
            },
            {
              title: "Activity",
              value: "1 Static Post + 1 Story",
            },
            {
              title: "Language",
              value: "English",
            },
            {
              title: "Minimum Charges",
              value: "₹15,000",
            },
            {
              title: "Maximum Charges",
              value: "₹20,000",
            },
          ],
        },
        {
          child: [
            {
              title: "Platform",
              value: "Youtube",
            },
            {
              title: "Activity",
              value: "Integrated Video",
            },
            {
              title: "Language",
              value: "English",
            },
            {
              title: "Minimum Charges",
              value: "₹20,000",
            },
            {
              title: "Maximum Charges",
              value: "₹25,000",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getchargesdetails();
  },
  methods: {
    ...mapActions("influencerprofile", ["charges", "getchargesdetails"]),
    ...mapActions("commonfunction", ["getuserpercentage"]),

    getplatformname(data) {
      let name = this.platform.filter((value) => {
        return value.id == data;
      });
      return name[0].name;
    },

    getlanguagename(data) {
      let name = this.language.filter((value) => {
        return value.id == data;
      });
      return name[0].language;
    },

    getInclusiveGst(data){
      if(data == 0){
        return 'No'
      }
      else{
        return 'Yes'
      }
    },

    getBarter(data){
      if(data == 0){
        return 'No'
      }
      else{
        return 'Yes'
      }
    },

    addrowclicked() {
      // console.log("itm:", this.item);
      // console.log("add row clicked");
      this.item.push({});
      // console.log("itm:", this.item);
    },

    removerowclicked(data,index) {
      console.log(index)
      // this.keywords.splice(item - 1, 1)
      this.item.splice(index, 1);
      // for (let i = 0; i < this.item.length; i++) {
      //   console.log(data)
      //   if(this.item[i].id == data.id)
      //   {
      //     this.item.splice(i,1)
      //     // console.log("Matched"+this.item[i].id)
      //   }
      // }
    },

    chargeDetailToggle() {
      if (this.IsChargeDetailEditable == false) {
        this.IsChargeDetailEditable = true;
      } else {
        this.IsChargeDetailEditable = false;
      }
    },
    changePasswordToggle() {
      this.$root.$emit("changePasswordToggle");
    },

    getchargesext() {
      this.getchargesdetails()
      .then((response) => {
        this.$data.chargesdata = response.data;
          for (let data in this.chargesData) {
            this.item[data] = {
              platform: this.chargesData[data].platform,
              activity: this.chargesData[data].activity,
              language: this.chargesData[data].language,
              min_charge: this.chargesData[data].min_charge,
              max_charge: this.chargesData[data].max_charge,
              inclusive_gst: this.chargesData[data].inclusive_gst,
              barter: this.chargesData[data].barter,
            };

            if (this.item[data].inclusive_gst == "0") {
              this.item[data].inclusive_gst = false;
            }
            if (this.item[data].barter == "0") {
              this.item[data].barter = false;
            }
          }
      });
    },

    gerpercentage() {
      this.getuserpercentage()
    },

    handleCharges() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        console.log(this.$v.item);
        this.$v.item.$touch();
      } else {
        this.item.forEach((element) => {
          console.log("element:", element);
          if (element.inclusive_gst == 1) {
            element.inclusive_gst = "1";
          } else {
            element.inclusive_gst = "0";
          }
          if (element.barter == 1) {
            element.barter = "1";
          } else {
            element.barter = "0";
          }
        });
        this.charges({
          chargesDetails: this.item,
        })
          .then((response) => {
            console.log(response);
            vm.submitted = false;
            vm.getchargesdetails();
            vm.IsChargeDetailEditable = false;
            this.getchargesext()
            this.gerpercentage()
            this.item = []
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          })
          .catch((err) => {
            vm.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },
  },
};
</script>