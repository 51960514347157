<template>
    <div class="action-btn">
        <button-component
            v-if="row.status == '1'"
            @onBtnClick="handleStatistic(row)"
            :clsname="btnplain"
            >Statistics</button-component>
        <!-- <button-component
            v-if="row.status == '0'"
            @onBtnClick="handleApprove(row)"
            :clsname="btnplain"
            >Approve</button-component>
            <button-component
            v-if="row.status == '0'"
            @onBtnClick="handleReject(row)"
            :clsname="btnplain"
            >Reject</button-component>-->
        <maincategory-dropdown
            v-if="(row.status == '0' || row.status == '3')"
            class="header-main-category"
            :clsname="'btnbasic'"
            v-model="statusDrop"
            :popoverList="dropCatList"
            >Status</maincategory-dropdown>
        <text-component v-if="row.status == '2'" class="subtitle1">Rejected</text-component>
        <!-- Modal -->
        <div :class="{'modal-window statistics': true , 'popupopen': isInvite}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close">
                <img
                    @click="closepopup"
                    src="../../../../assets/image/cancel.svg"
                    class="cancel-icon"
                    alt="cancel-icon"
                    />
                </a>
                <div class="header">
                    <text-component class="h2">Statistics</text-component>
                </div>
                <div class="row message-desc">
                    <div class="campaign-data-main col-12">
                        <text-component class="subtitle2 col-6">Platform</text-component>
                        <text-component
                            class="black campaign-data-txt col-6"
                            :class="platformName"
                            >{{platformName}}</text-component>
                    </div>
                    <div class="campaign-data-main col-12">
                        <text-component class="subtitle2 col-6">Link</text-component>
                        <a :href="currentrow.link" target="_blank">
                          <text-component class="black campaign-data-txt col-6">{{currentrow.link}}</text-component>
                        </a>
                    </div>
                </div>
                <hr class="hrline" />
                <div class="header">
                    <text-component class="h4">Details</text-component>
                </div>
                <div class="row message-desc">
                    <div class="row col-12 paddingo margino" v-if="isInsta">
                        <div class="row margino statistics-cards">
                            <div 
                                class="profile-detail-card col-12 col-sm-6 col-xl-3" 
                                v-if="statsdata && statsdata.engagement.values[0].value"
                                >
                                <div class="profile-detail-card-inner total-likes">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/Likes.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.engagement.values[0].value}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Likes</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.reach.values[0].value"
                                >
                                <div class="profile-detail-card-inner total-views">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/totalviews.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.reach.values[0].value}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Views</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Views</text-component>
                            <text-component
                              class="black campaign-data-txt col-6"
                            >{{statsdata.reach.values[0].value}}</text-component>
                            </div>
                            <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Likes</text-component>
                            <text-component
                              class="black campaign-data-txt col-6"
                            >{{statsdata.engagement.values[0].value}}</text-component>
                            </div>-->
                    </div>
                    <div class="row col-12 paddingo margino" v-if="isTwitter">
                        <div class="row col-12 paddingo margino statistics-cards">
                            <div 
                                class="profile-detail-card col-12 col-sm-6 col-xl-3" 
                                v-if="statsdata && statsdata.favorite_count"
                                >
                                <div class="profile-detail-card-inner total-likes">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/Likes.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.favorite_count}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Likes</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.retweet_count"
                                >
                                <div class="profile-detail-card-inner retweet">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/retweets.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.retweet_count}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Retweets</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Likes</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.favorite_count}}</text-component>
                        </div>
                        <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Retweets</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.retweet_count}}</text-component>
                        </div> -->
                    </div>
                    <div class="row col-12 paddingo margino" v-if="isYoutube">
                        <div class="row margino statistics-cards">
                            <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="statsdata && statsdata.likeCount">
                                <div class="profile-detail-card-inner total-likes">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/Likes.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.likeCount}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Likes</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.favoriteCount"
                                >
                                <div class="profile-detail-card-inner favorite">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/Favorite.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.favoriteCount}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Favorites</text-component>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-detail-card col-12 col-sm-6 col-xl-3" v-if="statsdata && statsdata.viewCount">
                                <div class="profile-detail-card-inner total-views">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/totalviews.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.viewCount}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Views</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.commentCount"
                                >
                                <div class="profile-detail-card-inner total-comments">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/totalcomments.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.commentCount}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Comments</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.dislikeCount"
                                >
                                <div class="profile-detail-card-inner dislikes">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/dislikes.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.dislikeCount}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Dislikes</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Likes</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.likeCount}}</text-component>
                            </div>
                            <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Favorites</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.favoriteCount}}</text-component>
                            </div>
                            <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Views</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.viewCount}}</text-component>
                            </div>
                            <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Comments</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.commentCount}}</text-component>
                            </div>
                            <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Dislikes</text-component>
                            <text-component class="black campaign-data-txt col-6">{{statsdata.dislikeCount}}</text-component>
                            </div>-->
                    </div>
                    <div class="row col-12 paddingo margino" v-if="isFacebook">
                        <div class="row margino statistics-cards">
                            <div 
                                class="profile-detail-card col-12 col-sm-6 col-xl-3" 
                                v-if="statsdata && statsdata.post_reactions_by_type_total.values[0].value"
                                >
                                <div class="profile-detail-card-inner total-likes">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/Likes.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.post_reactions_by_type_total.values[0].value}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Likes</text-component>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="profile-detail-card col-12 col-sm-6 col-xl-3"
                                v-if="statsdata && statsdata.post_engaged_users.values[0].value"
                                >
                                <div class="profile-detail-card-inner total-views">
                                    <div class="card-image-div">
                                        <img
                                            src="../../../../assets/image/totalviews.svg"
                                            alt="card-icon"
                                            class="card-image"
                                            width="50px"
                                            height="50px"
                                            />
                                    </div>
                                    <div class="card-detail-txt">
                                        <text-component class="dark mid">{{statsdata.post_engaged_users.values[0].value}}</text-component>
                                        <br />
                                        <text-component class="dark">Total Views</text-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Views</text-component>
                            <text-component
                                class="black campaign-data-txt col-6"
                                >{{statsdata.post_engaged_users.values[0].value}}</text-component>
                        </div>
                        <div class="campaign-data-main col-12">
                            <text-component class="subtitle2 col-6">Total Likes</text-component>
                            <text-component
                                class="black campaign-data-txt col-6"
                                >{{getTotal(statsdata.post_reactions_by_type_total.values[0].value)}}</text-component>
                        </div> -->
                    </div>

                    <div class="row col-12 paddingo margino statistics-cards" v-if="!statsdata">
                      <div 
                          class="profile-detail-card col-12 col-sm-6 col-xl-3" 
                          v-if="clickedData.num_likes"
                          >
                          <div class="profile-detail-card-inner total-likes">
                              <div class="card-image-div">
                                  <img
                                      src="../../../../assets/image/Likes.svg"
                                      alt="card-icon"
                                      class="card-image"
                                      width="50px"
                                      height="50px"
                                      />
                              </div>
                              <div class="card-detail-txt">
                                  <text-component class="dark mid">{{clickedData.num_likes}}</text-component>
                                  <br />
                                  <text-component class="dark">Total Likes</text-component>
                              </div>
                          </div>
                      </div>
                      <div 
                          class="profile-detail-card col-12 col-sm-6 col-xl-3" 
                          v-if="clickedData.num_views"
                          >
                          <div class="profile-detail-card-inner total-views">
                              <div class="card-image-div">
                                  <img
                                      src="../../../../assets/image/totalviews.svg"
                                      alt="card-icon"
                                      class="card-image"
                                      width="50px"
                                      height="50px"
                                      />
                              </div>
                              <div class="card-detail-txt">
                                  <text-component class="dark mid">{{clickedData.num_views}}</text-component>
                                  <br />
                                  <text-component class="dark">Total Views/Comments</text-component>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import MaincategoryDropdown from "../../../../BasicComponents/Dropdown/dropdown.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["row"],
  components: {
    ButtonComponent,
    TextComponent,
    MaincategoryDropdown
  },
  data() {
    return {
      clickedData:{},
      currentrow: {},
      statusDrop: null,
      statsdata: {},
      platformName: "",
      btnplain: "btnplain",
      btnprimary: "btnprimary",
      btndestructive: "btndestructive",
      isInvite: false,
      isInsta: false,
      isTwitter: false,
      isYoutube: false,
      isFacebook: false,
      dropCatList: [
        { id: "1", value: "Accept" },
        { id: "2", value: "Reject" },
        { id: "3", value: "Need Improvement" }
      ]
    };
  },
  watch: {
    statusDrop: {
      immediate: false,
      handler(statusDrop) {
        if(this.statusDrop != null){
          let status = this.getIdByName(statusDrop);
          let id = this.row.id;
          this.handleAction(id, status);
          // console.log(statusDrop)
        }
      }
    }
  },
  methods: {
    ...mapActions("admincampaignaction", [
      "approverejectInightInfluencer",
      "getStatistics"
    ]),

    handleStatistic: async function(row) {
      await this.getStatistics({
        id: row.id
      }).then(response => {
        // console.log(response.data);
        this.clickedData = response.data
        this.statsdata = response.data.data;
      });
      this.currentrow = row;
      this.platformName = row.social_platform.name;
      if (row.social_platform.name == "Instagram") {
        this.isInsta = true;
      } else if (row.social_platform.name == "Twitter") {
        this.isTwitter = true;
      } else if (row.social_platform.name == "Youtube") {
        this.isYoutube = true;
      } else if (row.social_platform.name == "Facebook") {
        this.isFacebook = true;
      }

      // if (this.isInvite == true) {
      //   this.isInvite = false;
      // } else {
      //   this.isInvite = true;
      // }
      console.log('this.isInvite',this.isInvite)
      this.isInvite = !this.isInvite;
    },

    getTotal(data) {
      let sum = 0;
      if (data) {
        for (var el in data) {
          sum += parseFloat(data[el]);
        }
        return sum;
      } else {
        return sum;
      }
    },

    closepopup() {
      if (this.isInvite == true) {
        this.isInvite = false;
      } else {
        this.isInvite = true;
      }
    },

    getIdByName(name) {
      let dropdown = this.dropCatList.filter(row => {
        return row.value == name;
      });
      if (dropdown.length) {
        return dropdown[0].id;
      } else {
        return "";
      }
    },

    // handleApprove(row) {
    //   const vm = this;
    //   this.approverejectInightInfluencer({
    //     id: row.id,
    //     status: "1",
    //   })
    //     .then((response) => {
    //       this.$root.$emit("approveorrejectclicked");
    //       this.$toast.open({
    //         message: response.message,
    //         type: "success",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top",
    //       });
    //     })
    //     .catch((err) => {
    //       this.$toast.open({
    //         message: err.response.data.message,
    //         type: "error",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top",
    //       });
    //     });
    // },

    // handleReject(row) {
    //   const vm = this;
    //   this.approverejectInightInfluencer({
    //     id: row.id,
    //     status: "2",
    //   })
    //     .then((response) => {
    //       this.$root.$emit("approveorrejectclicked");
    //       this.$toast.open({
    //         message: response.message,
    //         type: "success",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top",
    //       });
    //     })
    //     .catch((err) => {
    //       this.$toast.open({
    //         message: err.response.data.message,
    //         type: "error",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top",
    //       });
    //     });
    // },
    handleAction(id, status) {
      const vm = this;
      this.statusDrop = null
      if(this.row.type=='manual' && status==1){
        this.$root.$emit('acceptLink',this.row);
      }else{
        this.approverejectInightInfluencer({
          id: id,
          status: status
        })
        .then(response => {
          this.$root.$emit("approveorrejectclicked");
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
      }
    }
  }
};
</script>