<template>
  <div class="myprofile publisher-profile boxmargin">
    <form @submit.prevent="submitProfile">
      <div class="box margino">
        <div class="box-content">
          <div class="myprofile-header">
            <text-component class="myprofile-header-txt" :class="subtitle1class">Personal Details</text-component>
            <div class="myprofile-header-right">
              <button-component
                @onBtnClick="changePasswordToggle"
                :clsname="plainclass"
              >Change Password</button-component>
              <button-component
                v-if="!IsPersonalDetailEditable"
                @onBtnClick="personalDetailToggle"
                :clsname="basicclass"
              >Edit</button-component>
            </div>
          </div>
          <div v-if="!IsPersonalDetailEditable" class="personaldetail-saved">
            <div class="row margino">
              <img
                v-if="request.profile_pic"
                :src="request.base_url + '/' + request.profile_pic"
                class="user-img"
                alt="user-image"
              />
              <img
                v-else
                src="../../../assets/image/usericon.png"
                class="user-img"
                alt="user-image"
              />
            </div>
            <div class="row margino">
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Full Name</text-component>
                  <text-component :class="black">{{request.full_name}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Gender</text-component>
                  <text-component :class="black">{{capitalizeWord(request.gender)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Date of Birth</text-component>
                  <text-component :class="black">{{formatDateGlobal(request.dob)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Email Address</text-component>
                  <text-component :class="black">{{request.email}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Contact Number</text-component>
                  <text-component :class="black">{{request.prefix}} {{request.phone}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Country</text-component>
                  <text-component :class="black">{{getcountryname(request.country)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">City</text-component>
                  <text-component :class="black">{{getcityname(request.city)}}</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                <div class="row p-info">
                  <text-component :class="subtitle2class">Domain Name</text-component>
                  <text-component :class="black">{{request.domain_name}}</text-component>
                </div>
              </div>
            </div>
            <div class="row margino"></div>
          </div>
          <div v-if="IsPersonalDetailEditable" class="personaldetail-editable">
            <div class="row margino">
              <profile-uploader
                :imageurl="imageurl"
                :fileuploadid="fileuploadid"
                @selectfile="getfiledata"
                :avatar="avatar"
              ></profile-uploader>
            </div>
            <div class="row edit-detail-upper">
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.fullname"
                :helptext="!$v.item.fullname.required && submitted ? validationmessage.fullname: false"
                :labelclass="{'validationerr': !$v.item.fullname.required && submitted}"
                :classname="{'error': !$v.item.fullname.required && submitted, 'default': true}"
              >Full Name</input-component>
              <select-component
                class="txtgroup col-12 col-sm-6 col-md-4"
                required="true"
                :helptext="!$v.item.gender.required && submitted ? validationmessage.gender: false"
                :labelclass="{'validationerr': !$v.item.gender.required && submitted}"
                :inputclass="{'error': !$v.item.gender.required && submitted, 'dropdownmenu': true}"
                :options="genderoption"
                v-model="item.gender"
                :multiple="false"
                :placeholder="selectplaceholder"
              >Gender</select-component>
              <div class="col-12 col-sm-6 col-md-4 txtgroup datetimepicker row-height">
                <div class="datetimepicker-txt inputtxt">
                  <text-component>
                    Date of Birth
                    <sup>*</sup>
                  </text-component>
                </div>
                <div class="datetimepicker-main">
                  <datetime
                    :class="{'error': !$v.item.dob.required && submitted, 'date-time-picker': true}"
                    :input-id="inputid"
                    :input-class="classname"
                    :max-datetime="today_date"
                    v-model="item.dob"
                    value-zone="local" zone="local"
                  ></datetime>
                  <span
                    v-if="!$v.item.dob.required && submitted"
                    class="validationerr"
                  >{{validationmessage.dob}}</span>
                </div>
              </div>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                disabledInput="true"
                v-model="item.email"
                :helptext="!$v.item.email.required && submitted ? validationmessage.email: false"
                :labelclass="{'validationerr': !$v.item.email.required && submitted}"
                :classname="{'error': !$v.item.email.required && submitted, 'default': true}"
              >Email Address</input-component>
              <div class="txtgroup phnumberbox col-12 col-sm-6 col-md-4">
                <input-component
                  required="true"
                  v-model="item.mobile_number"
                  :type="phnumberboxtype"
                  :helptext="(!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted ? validationmessage.mobile_number: false"
                  :labelclass="{'validationerr': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted}"
                  :classname="{'error': (!$v.item.mobile_number.required || !$v.item.mobile_number.minLength || !$v.item.mobile_number.maxLength || !$v.item.mobile_prefix.required) && submitted, 'default': true}"
                >Phone number</input-component>
                <div class="countrycode">
                  <dropdown-component
                    :popoverList="countrylist"
                    v-model="item.mobile_prefix"
                    :btnid="btnid"
                  >
                    <img src="../../../assets/image/globe.svg" class="globeicon" />
                  </dropdown-component>
                </div>
              </div>
              <select-component
                label="name"
                class="txtgroup col-12 col-sm-6 col-md-4"
                required="true"
                :helptext="!$v.item.country.required && submitted ? validationmessage.country: false"
                :labelclass="{'validationerr': !$v.item.country.required && submitted}"
                :inputclass="{'error': !$v.item.country.required && submitted, 'dropdownmenu': true}"
                :options="country"
                v-model="item.country"
                :multiple="false"
                :placeholder="selectplaceholder"
                @handlevalue="sendCountryid(item.country)"
              >Country</select-component>
              <select-component
                label="name"
                class="txtgroup col-12 col-sm-6 col-md-4"
                required="true"
                :helptext="!$v.item.city.required && submitted ? validationmessage.city: false"
                :labelclass="{'validationerr': !$v.item.city.required && submitted}"
                :inputclass="{'error': !$v.item.city.required && submitted, 'dropdownmenu': true}"
                :options="city"
                v-model="item.city"
                :multiple="false"
                :placeholder="selectplaceholder"
              >City</select-component>
              <input-component
                required="true"
                class="txtgroup col-12 col-sm-6 col-md-4"
                v-model="item.domain_name"
                :helptext="!$v.item.domain_name.required && submitted ? validationmessage.domain_name: false"
                :labelclass="{'validationerr': !$v.item.domain_name.required && submitted}"
                :classname="{'error': !$v.item.domain_name.required && submitted, 'default': true}"
              >Domain Name</input-component>
            </div>
            <hr class="hrline-footer" />
            <div class="width edit-footer">
              <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import DropdownComponent from "../../../BasicComponents/Dropdown/dropdownwithtxt";
import ProfileUploader from "./fileupload.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent,
    DropdownComponent,
    ProfileUploader
  },
  computed: {
    ...mapGetters("influencerprofile", ["profileData"]),
    ...mapGetters("commonfunction", ["countryData", "cityData"])
  },
  validations: {
    item: {
      fullname: { required },
      email: { required, email },
      mobile_number: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(13)
      },
      mobile_prefix: { required },
      dob: { required },
      gender: { required },
      country: { required },
      city: { required },
      domain_name: { required },
    }
  },
  watch: {
    profileData: {
      immediate: true,
      handler(profileData) {
        this.$data.request = profileData;
        this.item.fullname = this.request.full_name;
        this.item.dob = this.setDateFn(this.request.dob);
        this.item.email = this.request.email;
        this.item.mobile_number = this.request.phone;
        this.item.mobile_prefix = this.request.prefix;
        this.item.gender = this.request.gender;
        this.item.country = profileData.country;
        this.sendCountryid(profileData.country);
        this.item.city = profileData.city;
        this.item.domain_name = profileData.domain_name
        if (profileData.profile_pic) {
          console.log(profileData.profile_pic)
          this.avatar = profileData.base_url + "/" + profileData.profile_pic;
        }
      }
    },

    countryData: {
      immediate: true,
      handler(countryData) {
        this.$data.country = countryData;
      }
    },

    cityData: {
      immediate: true,
      handler(cityData) {
        this.$data.city = cityData;
      }
    }
  },
  data() {
    return {
      request: {},
      item: {
        fullname: null,
        dob: null,
        email: null,
        mobile_number: null,
        gender: null,
        country: null,
        city: null,
        mobile_prefix: '+91',
        domain_name: null,
      },
      validationmessage: {
        fullname: "full name is required",
        email: "please enter valid email",
        mobile_number: "please enter valid phone number with prefix",
        gender: "gender is required",
        country: "country is required",
        city: "gender is required",
        dob: "gender is required",
        domain_name: "domain name is required",
      },
      country: [],
      city: [],
      submitted: false,
      IsPersonalDetailEditable: false,
      classname: "default",
      inputid: "startdate",
      genderval: "",
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      plainclass: "btnplain",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      btnid: "countrybtn",
      phnumberboxtype: "number",
      chargecbid: "1",
      bartercbid: "2",
      imageurl: "usericon.png",
      fileuploadid: "profileuploader",
      selectplaceholder: "Select...",
      usermail: "",
      gender: "",
      dob: "",
      email: "",
      phno: "",
      platform: "",
      activity: "",
      language: "",
      mincharge: "",
      maxcharge: "",
      filedata: "",
      avatar: null,
      today_date: moment().format("YYYY-MM-DD"),
      countrylist: [
        { id: "0", value: "+91" },
        { id: "1", value: "+92" },
        { id: "2", value: "+93" },
        { id: "3", value: "+94" }
      ],
      genderoption: [
        {
          id: "1",
          label: "Male"
        },
        {
          id: "2",
          label: "Female"
        },
        {
          id: "3",
          label: "Others"
        }
      ],
      personaldata: [
        {
          title: "Full Name",
          value: "Jacqueline Hart"
        },
        {
          title: "Gender",
          value: "Female"
        },
        {
          title: "Date of Birth",
          value: "June 1 1990"
        },
        {
          title: "Email Address",
          value: "jacquelinehart@gmail.com"
        },
        {
          title: "Contact Number",
          value: "+91 98542 12587"
        },
        {
          title: "Country",
          value: "India"
        },
        {
          title: "City",
          value: "Ahmedabad"
        }
      ]
    };
  },
  created() {
    this.getuserprofile();
  },
  methods: {
    ...mapActions("influencerprofile", ["basicprofile", "getuserprofile"]),
    ...mapActions("commonfunction", ["getcity", "getuserpercentage"]),

    setDateFn: function(getdate,type=""){
      let momentDate = moment(getdate);
      return momentDate.format("YYYY-MM-DD");
    },

    getcountryname(data) {
      let countryname = this.country.filter(value => {
        return value.id == data;
      });
      if (countryname.length) {
        return countryname[0].name;
      } else {
        return "";
      }
    },

    capitalizeWord(string) {
      if(string){
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
      else{
          return ""
      }
    },

    getcityname(data) {
      let cityname = this.city.filter(value => {
        return value.id == data;
      });
      if (cityname.length) {
        return cityname[0].name;
      } else {
        return "";
      }
    },

    sendCountryid(data) {
      this.getcity({
        id: data
      });
    },

    personalDetailToggle() {
      if (this.IsPersonalDetailEditable == false) {
        this.IsPersonalDetailEditable = true;
      } else {
        this.IsPersonalDetailEditable = false;
      }
    },
    changePasswordToggle() {
      this.$root.$emit("changePasswordToggle");
    },

    getfiledata(e) {
      this.filedata = e.target.files[0];
      console.log(e.target.files[0]);
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.avatar = e.target.result;
      };
    },

    submitProfile() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        const formData = new FormData();
        let attachment = this.filedata;
        formData.append("profile_pic", attachment);
        let Data = {
          full_name: this.item.fullname,
          dob: this.item.dob,
          email: this.item.email,
          phone: this.item.mobile_number,
          gender: this.item.gender,
          country: this.item.country,
          city: this.item.city,
          prefix: this.item.mobile_prefix,
          domain_name: this.item.domain_name
        };
        formData.append("Data", JSON.stringify(Data));
        this.basicprofile(formData)
          .then(response => {
            console.log(response);
            vm.getuserprofile();
            this.IsPersonalDetailEditable = false;
            this.getuserpercentage()
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    }
  }
};
</script>