<template>
    <div class="invoice-detail-page paddingo">
        <div class="content-header">
            <span class="h2">Invoice Detail Page</span>
            <div class="page-header-right">
                <button-component @onBtnClick="generateReport" :class="'btnprimary'">Download</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="row margino">
                        <div class="col-6 invoice-header-left">
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">Invoice No:</span>
                                <span class="invoice-title-value">{{invoicedata.invoice.invoice_number}}</span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">Date:</span>
                                <span class="invoice-title-value">{{formatDateGlobal(invoicedata.invoice.invoice_date).substr(0,10)}}</span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title col-12 paddingo">To</span>
                                <span class="invoice-title-value col-12">
                                    <address>
                                        VidUnit Media Pvt Ltd.
                                        603, Embassy, Shashtri Nagar
                                        Andheri  West, Mumbai-40053
                                    </address>
                                </span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">PAN No:</span>
                                <span class="invoice-title-value"></span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">GST No:</span>
                                <span class="invoice-title-value">
                                    {{(invoicedata.gst_number)?invoicedata.gst_number:'N/A'}}
                                </span>
                            </div>
                        </div>
                        <div class="col-6 invoice-header-right">
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">From:</span>
                                <span class="invoice-title-value">{{invoicedata.influencer_name}}</span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title col-12 paddingo">Address</span>
                                <span class="invoice-title-value col-12">
                                    <address>
                                        {{invoicedata.influencer_address?invoicedata.influencer_address:"N/A"}}
                                    </address>
                                </span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">Mobile No:</span>
                                <span class="invoice-title-value">{{invoicedata.invoice.prefix}} {{invoicedata.invoice.phone}}</span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle1 invoice-title">State:</span>
                                <span class="invoice-title-value"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row margino invoice-index-header">
                        <table>
                            <tr>
                                <th>SL NO.</th>
                                <th>DESCRIPTION</th>
                                <th>RATE</th>
                                <th>AMOUNT</th>
                            </tr>
                            <tr>
                                <td>1.</td>
                                <td>{{invoicedata.campaign.campaign_name}}</td>
                                <td>{{invoicedata.invoice.amount}}</td>
                                <td>{{invoicedata.invoice.amount}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="subtitle1">Total:</td>
                                <td>{{invoicedata.invoice.amount}}</td>
                                <td>{{invoicedata.invoice.amount}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <span class="subtitle2">Rupees in words:</span> 
                                    <span class="invoice-title-value">{{getamountwords(invoicedata.invoice.amount)}}</span>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                    <div class="row margino">
                        <div class="col-6 invoice-footer-left">
                            <div class="row margino invoice-row">
                                <span class="subtitle2 invoice-title">PAN No:</span>
                                <span class="invoice-title-value"></span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle2 invoice-title">Bank:</span>
                                <span class="invoice-title-value">
                                    {{(invoicedata.bank_detail)?invoicedata.bank_detail.bank_name:'N/A'}}
                                </span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle2 invoice-title">Bank Account:</span>
                                <span class="invoice-title-value">
                                    {{(invoicedata.bank_detail)?invoicedata.bank_detail.account_number:'N/A'}}
                                </span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle2 invoice-title">Branch:</span>
                                <span class="invoice-title-value"></span>
                            </div>
                            <div class="row margino invoice-row">
                                <span class="subtitle2 invoice-title">IFSC:</span>
                                <span class="invoice-title-value">
                                    {{(invoicedata.bank_detail)?invoicedata.bank_detail.ifsc_code:'N/A'}}
                                </span>
                            </div>
                        </div>
                        <div class="col-6 invoice-footer-right"></div>
                    </div>
                </div>
            </div>
        </div>
        <vue-html2pdf
            :show-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="invoicedata.invoice.invoice_number"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"            
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdf-content-main">
                <div class="invoice-detail-page-content-pdf-header col-12">
                    <span class="h2">VidUnit</span>
                </div>
                <div class="invoice-detail-page-content-pdf">
                    <div class="box-content">
                        <div class="row margino">
                            <div class="col-6 invoice-header-left">
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">Invoice No:</span>
                                    <span class="invoice-title-value">{{invoicedata.invoice.invoice_number}}</span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">Date:</span>
                                    <span class="invoice-title-value">{{formatDateGlobal(invoicedata.invoice.invoice_date).substr(0,10)}}</span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title col-12 paddingo">To</span>
                                    <span class="invoice-title-value col-12">
                                        <address>
                                            VidUnit Media Pvt Ltd.
                                            603, Embassy, Shashtri Nagar
                                            Andheri  West, Mumbai-40053
                                        </address>
                                    </span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">PAN No:</span>
                                    <span class="invoice-title-value"></span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">GST No:</span>
                                    <span class="invoice-title-value">
                                        {{(invoicedata.gst_number)?invoicedata.gst_number:'N/A'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 invoice-header-right">
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">From:</span>
                                    <span class="invoice-title-value">{{invoicedata.influencer_name}}</span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title col-12 paddingo">Address</span>
                                    <span class="invoice-title-value col-12">
                                        <address>
                                            {{invoicedata.influencer_address?invoicedata.influencer_address:"N/A"}}
                                        </address>
                                    </span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">Mobile No:</span>
                                    <span class="invoice-title-value">{{invoicedata.invoice.prefix}} {{invoicedata.invoice.phone}}</span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle1 invoice-title">State:</span>
                                    <span class="invoice-title-value"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row margino invoice-index-header">
                            <table>
                                <tr>
                                    <th>SL NO.</th>
                                    <th>DESCRIPTION</th>
                                    <th>RATE</th>
                                    <th>AMOUNT</th>
                                </tr>
                                <tr>
                                    <td>1.</td>
                                    <td>{{invoicedata.campaign.campaign_name}}</td>
                                    <td>{{invoicedata.invoice.amount}}</td>
                                    <td>{{invoicedata.invoice.amount}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="subtitle1">Total:</td>
                                    <td>{{invoicedata.invoice.amount}}</td>
                                    <td>{{invoicedata.invoice.amount}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <span class="subtitle2">Rupees in words:</span> 
                                        <span class="invoice-title-value">{{getamountwords(invoicedata.invoice.amount)}}</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <div class="row margino">
                            <div class="col-6 invoice-footer-left">
                                <div class="row margino invoice-row">
                                    <span class="subtitle2 invoice-title">PAN No:</span>
                                    <span class="invoice-title-value"></span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle2 invoice-title">Bank:</span>
                                    <span class="invoice-title-value">
                                        {{(invoicedata.bank_detail)?invoicedata.bank_detail.bank_name:'N/A'}}
                                    </span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle2 invoice-title">Bank Account:</span>
                                    <span class="invoice-title-value">
                                        {{(invoicedata.bank_detail)?invoicedata.bank_detail.account_number:'N/A'}}
                                    </span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle2 invoice-title">Branch:</span>
                                    <span class="invoice-title-value"></span>
                                </div>
                                <div class="row margino invoice-row">
                                    <span class="subtitle2 invoice-title">IFSC:</span>
                                    <span class="invoice-title-value">
                                        {{(invoicedata.bank_detail)?invoicedata.bank_detail.ifsc_code:'N/A'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 invoice-footer-right"></div>
                        </div>
                    </div>
                </div>
            </section>
        
        </vue-html2pdf>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import VueHtml2pdf from 'vue-html2pdf'
import pdfscss from "../../../assets/css/invoicedetailpage-pdf.scss";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        // TextComponent,
        VueHtml2pdf,
        ButtonComponent
    },
    data () {
        return {
            invoicedata: [],
            progress:0,
            pdfDownloaded:false,
            invstatus: null,
            isInvoice: true,
            campaignstatus: null,
        }
    },
    methods: {
        ...mapActions("admininvoice",["getinvoicebyid"]),

        getamountwords(num) {
            var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
            var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

                if ((num = num.toString()).length > 9) return 'overflow';
                let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
                if (!n) return; var str = '';
                str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
                str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
                str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
                str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
                str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
                return str;

        },

        getInvoiceDetail() {
            this.getinvoicebyid({id : this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.invoicedata = response.data
                if(this.invoicedata.agency_name){
                    this.isInvoice = false
                }
                if(response.data.invoice.status == '1'){
                    this.invstatus ='In Progress';
                }
                else if(response.data.invoice.status == '2'){
                    this.invstatus ='Paid';
                }
                else {
                    this.invstatus ='Created';
                }
                if (response.data.campaign.status == '1') {
                    this.campaignstatus ='In Progress';
                }
                else if (response.data.campaign.status == '2') {
                    this.campaignstatus ='Rejected';
                }
                else if (response.data.campaign.status == '3') {
                    this.campaignstatus ='Completed';
                }
                else{
                    this.campaignstatus ='Pending';
                }
            });
        },
        generateReport () {
            // console.log(this.$refs.html2Pdf)
            this.$refs.html2Pdf.generatePdf()
            // this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`);
        },

        hasStartedGeneration() {
            console.log(`PDF has started generation`);
        },

        hasGenerated(blobPdf) {
            this.pdfDownloaded = true;
            console.log(`PDF has downloaded yehey`);
            console.log(blobPdf);
        },

        domRendered() {
            console.log("Dom Has Rendered");
            this.contentRendered = true;
        },

        onBlobGenerate(blob) {
            console.log(blob);
        },
    },
    created() {
        this.getInvoiceDetail()
    }
}
</script>