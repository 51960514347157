
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let chargesData = [];
    let bankData = [];
    let influencerRating = [];
    let profileData = [];
    let interestData = [];
    let interestListing = [];
    let socialData = [];
    let socialInsights = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        chargesData: chargesData,
        bankData: bankData,
        influencerRating: influencerRating,
        profileData: profileData,
        interestData: interestData,
        interestListing: interestListing,
        socialData: socialData,
        socialInsights: socialInsights
    }

}

const getters = {
    currentUser: state => state.currentUser,
    influencerRating: state => state.influencerRating,
    chargesData: state => state.chargesData,
    bankData: state => state.bankData,
    profileData: state => state.profileData,
    interestData: state => state.interestData,
    interestListing: state => state.interestListing,
    socialData: state => state.socialData,
    socialInsights: state => state.socialInsights
};

const actions = {
  clearAll: ({ commit }) => {
    commit("setCurrentUser", []);
    commit("setCurrentCoa", []);
    commit("setChargesData", []);
    commit("setBankData", []);
    commit("setProfileData", []);
    commit("setInterestData", []);
    commit("setInterestListing", []);
    commit("setSocialData", []);
    commit("setSocialInsights", []);
  },

  fetchData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/me")
        .then((response) => {
          console.log(response);
          if (typeof response !== "undefined" && response.data) {
            console.log("setting current user", response.data.data);
            commit("setCurrentUser", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          console.log("user.js:106 my error");
          console.log(error);

          commit("setIsAuthenticated", false);
          commit("setCurrentUser", null);
          commit("setCurrentCoa", null);

          window.localStorage.setItem("auth", null);
          reject(error);
        });
    });
  },

  changepassword: ({ state, commit }, passwordData) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/change-password", {
          ...passwordData,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  bankdetail: ({ state, commit }, bankdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-bank-details", {
          ...bankdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  charges: ({ state, commit, dispatch }, chargedata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-charges-details", {
          ...chargedata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  interests: ({ state, commit }, interestdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-interest", {
          ...interestdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  basicprofile: ({ commit }, profiledata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-profile", profiledata, {
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getbankdetail: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-bank-details")
        .then((response) => {
          commit("setBankData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("setBankData", []);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getuserprofile: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-user-profile")
        .then((response) => {
          commit("setProfileData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getchargesdetails: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-user-charges-details")
        .then((response) => {
          commit("setChargesData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinterests: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-user-interest")
        .then((response) => {
          commit("setInterestData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("setInterestData", []);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinterestsbyid: ({ commit }, iddata) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-user-interest/" + iddata.id, {
          ...iddata,
        })
        .then((response) => {
          commit("setInterestData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("setInterestData", []);
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinterestlisting: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-interests")
        .then((response) => {
          commit("setInterestListing", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getsocialaccount: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-social-account")
        .then((response) => {
          commit("setSocialData", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  facebookprofileregister: ({ commit }, facebookdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify-facebook-account", {
          ...facebookdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  youtubeprofileregister: ({ commit }, youtubedata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify-youtube-account", {
          ...youtubedata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  instagramprofileregister: ({ commit }, instadata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify-facebook-account", {
          ...instadata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  otherprofileregister: ({ commit }, otherdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify-other-account", {
          ...otherdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  addsocialverifiedaccounts: ({ commit }, accountdata) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-social-account", {
          ...accountdata,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  gettwitteroauthtoken: ({ commit }) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/get-twitter-oauth-token")
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  verifytwitteraccount: ({ commit }, data) => {
    commit("commonfunction/setLoading", false, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/verify-twitter-account", {
          ...data,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getsocialinsights: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-social-insights")
        .then((response) => {
          commit("setSocialInsights", response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  fetchsocialinsights: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/fetch-social-insights")
        .then((response) => {
          commit("setSocialInsights", response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getuserrating: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/get-rating", { ...AUTH_PARAMS })
        .then((response) => {
          console.log(state);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getInfulencerRating: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/rating")
        .then((response) => {
          commit("setInfluencerRating", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
  setCurrentUser: (state, value) => {
    state.currentUser = value;
  },
  setCurrentCoa: (state, value) => {
    state.currentCOA = value;
  },
  setChargesData: (state, value) => {
    state.chargesData = value;
  },
  setBankData: (state, value) => {
    state.bankData = value;
  },
  setProfileData: (state, value) => {
    state.profileData = value;
  },
  setInterestData: (state, value) => {
    state.interestData = value;
  },
  setInterestListing: (state, value) => {
    state.interestListing = value;
  },
  setSocialData: (state, value) => {
    state.socialData = value;
  },
  setSocialInsights: (state, value) => {
    state.socialInsights = value;
  },
  setInfluencerRating: (state, value) => {
    state.influencerRating = value;
  },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};