<template>
    <div class="campaign-rating-page">
        <div class="datatable-main">
            <div class="data-table-header">
                <div class="data-table-header-inner">
                    <search-bar
                        class="header-searchbar"
                        :classname="classname"
                        :type="type"
                        :inputPlaceholder="inputPlaceholder"
                        v-model="filter">
                    </search-bar>
                </div>
            </div>
            <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :columns="columns"
                :rows="rows"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :filter="filter"
                :page="page"
                :per_page="per_page">
            </datatable-component>
        </div>
        <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isRateInfluencer}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close"><img @click="rateInfluencer" src="../../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                <div class="header">
                    <text-component :class="h2txtclass">Rate Influencer</text-component>
                </div>
                <form @submit.prevent="handleRating">
                    <div class="row content-align">
                        <rating-input class="col-12" v-model="item.rating"
                            required="true"
                            :helptext="!$v.item.rating.required && submitted ? validationmessage.rating: false"
                            :labelclass="{'validationerr': !$v.item.rating.required && submitted}"
                            :classname="{'error': !$v.item.rating.required && submitted, 'default': true}"
                        ></rating-input>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Feedback</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.feedback"
                                    required="true"
                                    :helptext="!$v.item.feedback.required && submitted ? validationmessage.feedback: false"
                                    :labelclass="{'validationerr': !$v.item.feedback.required && submitted}"
                                    :classname="{'error': !$v.item.feedback.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Commen</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.comment"
                                    required="true"
                                    :helptext="!$v.item.comment.required && submitted ? validationmessage.comment: false"
                                    :labelclass="{'validationerr': !$v.item.comment.required && submitted}"
                                    :classname="{'error': !$v.item.comment.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                        <div class="txtgroup col-12">
                            <div class="inputtxt">
                                <label>Add Notes</label>
                            </div>
                            <div>
                                <text-area-comp v-model="item.note"
                                    required="true"
                                    :helptext="!$v.item.note.required && submitted ? validationmessage.note: false"
                                    :labelclass="{'validationerr': !$v.item.note.required && submitted}"
                                    :classname="{'error': !$v.item.note.required && submitted, 'default': true}"
                                ></text-area-comp>
                            </div>
                        </div>
                    </div>
                    <hr class="hrline"/>
                    <div class="modal-footer">
                        <div>
                            <button-component buttontype="submit" :clsname="btnprimary">Submit</button-component>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Modal -->
    </div>
</template>
<script>
import RatingInput from "../../../../BasicComponents/rating/ratinginput.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue"
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue"
import ActionBtn from "./ratingpageaction";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import UserIcon from "./ratingpageicon.vue";
import { required } from "vuelidate/lib/validators";
import Rating from "./ratingpagerating.vue";
import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        DatatableComponent,
        searchBar,
        ButtonComponent,
        RatingInput,
        TextAreaComp
    },
    validations: {
        item: {
            influencer_id: { required },
            campaign_id: { required },
            rating: { required },
            feedback: { required },
            comment: { required },
            note: { required }
        }
    },
    data() {
        return{
            submitted:false,
            item:{
                influencer_id:null,
                campaign_id:null,
                rating:null,
                feedback:null,
                comment:null,
                note:null
            },
            validationmessage: {
                influencer_id: "Influencer is required",
                campaign_id: "campign is required",
                rating: "Rating is required",
                feedback: "Feedback is required",
                comment: "Comment is required",
                note: "Note is required"
            },
            isRateInfluencer: false,
            h2txtclass: 'h2',
            btnprimary: 'btnprimary',
            type: "text",
            inputPlaceholder: "Search Influencer",
            classname: "default prefixicon",
            txtclassname: "default",
            filter: "",
            selected: "",
            row: "",
            email: '',
            page: 1,
            per_page: 8,
            selectedRows: [],
            columns: [
                { label: "Profile Photo", component: UserIcon,align: "left",headerAlign: "left",},
                {
                    label: "Influencer’s Name",
                    field: "full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Rating",headerAlign: "left", align: "left", component: (Rating)},
                { label: "Action",headerAlign: "left", align: "left", component: (ActionBtn)},
            ],
            rows: [],
        }
    },
    // computed:{
    //     ...mapGetters("admincampaignaction",["ratingData"]),
    // },
    // watch: {
    //     ratingData:{
    //         immediate:true,
    //         handler(ratingData){
    //             this.rows = ratingData;
    //         }
    //     }
    // },
    methods: {
        ...mapActions("admincampaignaction",["getcampaignrating"]),
        ...mapActions("adminrating",["createrating"]),
        rateInfluencer() {
            if(this.isRateInfluencer == true){
                this.isRateInfluencer = false
            }
            else{
                this.isRateInfluencer = true	
            }
        },
        reserRatingForm(){
            this.item.rating = null
            this.item.feedback = null
            this.item.comment = null
            this.item.note = null
            this.submitted = false;
        },

        getData(){
            this.getcampaignrating({id:this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.rows = response.data
                console.log(this.rows)
            });
        },

        handleRating(){
            const vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                console.log(this.item)
                this.createrating({
                    influencer_id: this.item.influencer_id,
                    campaign_id: this.item.campaign_id,
                    rating: this.item.rating,
                    feedback: this.item.feedback,
                    comment: this.item.comment,
                    note: this.item.note
                }).then(response => {
                    this.isRateInfluencer = false;
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        }
    },
    created: function() {
        this.getData();
        this.$root.$on('onClick', (row) => {
            this.reserRatingForm();
            console.log(row)
            this.item.campaign_id = this.$route.params.id;
            console.log("campaign"+this.item.campaign_id)
            this.item.influencer_id = row.id;
            console.log(this.item.influencer_id)
            this.isRateInfluencer = true
            // if(this.isRateInfluencer == true){
            //     this.isRateInfluencer = false
            // }
            // else{
            //     this.isRateInfluencer = true	
            // }
        })
    }
}
</script>