<template>
    <div class="blog-detail">
        <div class="content-header">
            <text-component class="h2">Create Blog</text-component>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <form @submit.prevent="handleCreateBlog">
                        <input-component
                            class="txtgroup paddingo col-12"
                            v-model="item.title"
                            required="true"
                            :helptext="!$v.item.title.required && submitted ? validationmessage.title: false"
                            :labelclass="{'validationerr': !$v.item.title.required && submitted}"
                            :classname="{'error': !$v.item.title.required && submitted, 'default': true}"
                            @keypushed="getSlug(item.title)"
                        >Blog Title</input-component>
                        <input-component
                            class="txtgroup paddingo col-12"
                            v-model="item.slug"
                            required="true"
                            :helptext="!$v.item.slug.required && submitted ? validationmessage.slug: false"
                            :labelclass="{'validationerr': !$v.item.slug.required && submitted}"
                            :classname="{'error': !$v.item.slug.required && submitted, 'default': true}"
                            :disabledInput="'disabled'"
                        >Slug</input-component>
                        <input-component
                            class="txtgroup paddingo col-12"
                            v-model="item.author"
                            required="true"
                            :helptext="!$v.item.author.required && submitted ? validationmessage.author: false"
                            :labelclass="{'validationerr': !$v.item.author.required && submitted}"
                            :classname="{'error': !$v.item.author.required && submitted, 'default': true}"
                        >Create By</input-component>
                        <!-- <profile-uploader
                            :imageurl="imageurl"
                            :fileuploadid="fileuploadid"
                            @selectfile="getfiledata"
                            :avatar="avatar"
                            :width="width"
                            :height="height"
                            :camicon="camicon"
                        ></profile-uploader> -->
                        <div class="txtgroup">
                            <profile-uploader
                                :class="{'error': !$v.filedata.required && submitted}"
                                :imageurl="imageurl"
                                fileuploadid="bloguploadimg"
                                @selectfile="getfiledata"
                                :avatar="avatar"
                                :width="width"
                                :height="height"
                                :camicon="camicon"
                            ></profile-uploader>
                            <span
                                v-if="!$v.filedata.required && submitted"
                                class="validationerr"
                            >{{validationmessage.banner_image}}</span>
                        </div>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="item.description" 
                                :config="editorConfig"
                                :class="{'error': !$v.item.description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.item.description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.description}}</span>
                        </div>
                        <div class="footer-update-btn">
                            <button-component buttontype="submit" class="btnprimary">Create</button-component>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import ProfileUploader from "./fileupload.vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        TextComponent,
        InputComponent,
        ButtonComponent,
        ProfileUploader
    },
    validations: {
        item: {
            title: { required },
            slug: { required },
            author: { required },
            description: { required },
        },
        filedata: { required }
    },
    data() {
        return {
            item: {
                title: null,
                slug: null,
                author: null,
                description: null,
            },
            validationmessage: {
                title: "cms name is required ",
                slug: "slug is required ",
                author: "meta keyword is required",
                description: "meta description is required",
                banner_image: 'image is required'
            },
            submitted: false,
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: null,
            width: '70px',
            height: '70px',
            camicon: '-21px',
            editorConfig: {},
            filedata: ''
        }
    },
    methods: {
        ...mapActions("blogdata", ["createblog", "getblogdetail"]),
        
        getSlug(slugstring) {
            this.item.slug = this.slugify(slugstring)
        },

        slugify(string) {
            return string
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            // .replace(/[^\w\-]+/g, "")
            // .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
        },

        getfiledata(e) {
            this.filedata = e.target.files[0];
            console.log(e.target.files[0]);
            let image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.avatar = e.target.result;
            };
        },
        handleCreateBlog() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                const Data = new FormData();
                let blog_data = {
                    title: this.item.title,
                    slug: this.item.slug,
                    description: this.item.description,
                    author: this.item.author,
                };
                Data.append("blog_data", JSON.stringify(blog_data));
                Data.append("banner_image", this.filedata);
                this.createblog(Data)
                .then(response => {
                    console.log(response);
                    this.submitted = false
                    this.$router.push({ name: "adminbloglist" });
                    this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                });
            
            }
        },
    },
}
</script>