<template>
    <form class="profileuploader" >
        <div @dragover.prevent class="profileuploader-inner">
            <input :id="fileuploadid" type="file" name="fileUpload"  @change="onFileSelected"/>
            
            <label :for="fileuploadid" id="file-drag" @drop.prevent="onDrop">
                <div class="imagepreviewdiv">
                    <img v-if="!avatar" :src="require(`../../../assets/image/${imageurl}`)" id="shwimg" class="imgnormal">
                    <img class="imgpreviewsection" :src="avatar" v-if="avatar">
                    <img src="../../../assets/image/camera.png" alt="camera-image" class="camera-img">
                </div>
            </label>
        </div>
    </form> 
</template>



<script>
export default {
    name : "FileUpload",
    props :["imageurl", "fileuploadid", "avatar"],
    data() {
        return{
            selectedFile: null,
            files: [],
            images: []
        }
        
    },
    methods: {
        onFileSelected(e){
            // try{
            //     let image = e.target.files[0]
            //     let reader = new FileReader();
            //     reader.readAsDataURL(image);
            //     reader.onload = e =>{
            //         this.avatar = e.target.result
            //     }
            // }
            // catch(e)
            // {
            //     console.log("Please select a valid file")
            // }
            this.$emit('selectfile',e);
        },
        onDrop(e){ 
            try{
                let image = e.dataTransfer.files[0]
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.avatar = e.target.result
                }
            }
            catch(e)
            {
                console.log(e)
            }
        },
    }
}; 
</script>


<style scoped>

</style>