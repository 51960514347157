<template>
    <div class="menumobile" id="mobilemenu">
    <div class="searchvendor-content-left">
      <div class="menu-inner">
        <div class="main-logo">
        <img src="../assets/image/main-logo.png" alt="VidUnit-logo"/>
        </div>
        <navigation-component :navdata="navdata"></navigation-component>
      </div>
    </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "41",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "agencydashboard"
        },
        {
          id: "42",
          item: "Manage Influencer",
          imgpath: "manager.svg",
          child: [
            { 
              id: "43", 
              item: "Influencer List", 
              name: "agencyinfluencerlist" 
            },
            { 
              id: "44", 
              item: "Influencer Category", 
              name: "agencyinfluencercategory" 
            },
            { 
              id: "433", 
              item: "Influencer Invite", 
              name: "agencyinfluencerinvite" 
            },
          ]
        },
        {
          id: "45",
          item: "Invoice & Payments",
          imgpath: "invoice.svg",
          name: "agencypayment"
        },
        {
          id: "46",
          item: "Support",
          imgpath: "chat.svg",
          name: "agencysupport"
        },
      ]
    };
  },

  methods: {

  },
};
</script>