<template>
  <form @submit.prevent="handleLogin">
    <div class="login-page">
      <div class="row">
        <div class="col-12 col-md-6 login-left">
          <text-component :textclass="h2class">Thank You for joining with the VidUnit</text-component><br><br><br>
          <text-component textclass="h4">You need to wait untill admin approve your credentials.</text-component><br>
          <div class="return-login-div">
            <text-component class="mid return-to">Return to</text-component>
            <router-link to="/login">
              <button-component class="btnlogin" :clsname="btnplainclass">Log in</button-component>
            </router-link><br><br><br><br><br><br><br><br>
          </div>
        </div>
        <div class="col-6 login-right"></div>
      </div>
    </div>
  </form>
</template>

<script>
import TextComponent from "../BasicComponents/Text/Text.vue";
import ButtonComponent from "../BasicComponents/Button/Button.vue";
// import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    // InputComponent
  },
  data() {
    return {
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnprimary",
    };
  },
};
</script>

<style lang="scss" scoped>
.return-login-div{
    margin-top: 12px;
    .return-to{
        margin-right: 12px;
    }
}
</style>