<template>
    <div class="rating">
        <div class="rating-main" v-for="(star, index) in stars" :key="index" :class="index">
            <img @click="setRating" @mouseleave="ratingLeave" @mouseenter="ratingEnter" :id="index+1" v-if="index>=value" src="../../assets/image/star.svg" alt="star">
            <img @click="setRating" @mouseleave="ratingLeave" @mouseenter="ratingEnter" :id="index+1" v-if="index<value" src="../../assets/image/starfill.svg" alt="star-fill">
        </div>
        <input :value="value" @input="updateRating($event.target.value)" class="rating-input" type="number" />
        <div v-if="helptext" class="error-helper-msg">
            <label :class="labelclass">{{helptext}}</label>
        </div>
    </div>
</template>
<script>
export default {
    props: ['value','labelclass','helptext'],
    data() {
        return{
            stars: 5,
            hoverrating: '',
            oldrating: '',
        }
    },
    methods: {
        updateRating (value) {
            this.$emit('input', value)
        },
        setRating(e) {
            // this.rating = e.toElement.id;
            // console.log(e.originalTarget.id)
            //console.log(e)
            let star = e.target.id;
            this.$emit('input', star)
            this.$emit('onRating')
            // this.$emit('rating', e)
        },
        ratingEnter(e) {
            this.$emit('ratingEnter', e)
            // this.rating = e.toElement.id
        },
        ratingLeave(e) {
            this.$emit('ratingLeave', e)
            // this.rating = this.oldrating
        }
    },
    // created: function() {
    //     this.oldrating = this.rating
    // }
}
</script>