
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let pendingData = [];
    let approvedData = [];
    let rejectedData = [];
    return {
        pendingData: pendingData,
        approvedData: approvedData,
        rejectedData: rejectedData
    }

}

const getters = {
    pendingData: state => state.pendingData,
    approvedData: state => state.approvedData,
    rejectedData: state => state.rejectedData
};

const actions = {
    clearAll: ({ commit }) => {
        commit("setPendingData", [])
        commit("setApprovedData", [])
        commit("setRejectedData", [])
    },

    getcommunitymanagerpending: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/community_manager/0")
                .then((response) => {
                    commit("setPendingData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getcommunitymanagerapproved: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/community_manager/1")
                .then((response) => {
                    commit("setApprovedData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getcommunitymanagerrejected: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/community_manager/2")
                .then((response) => {
                    commit("setRejectedData", response.data.data);
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    approveorrejectcommunitymanager: ({ commit }, data) => {
        commit('commonfunction/setLoading', true, {root: true})
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.VUE_APP_ROOT_API + "/community_manager-status", { ...data, ...AUTH_PARAMS })
                .then((response) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    console.log(response.data);

                    // setupAxiosDefaults(response.data.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    commit('commonfunction/setLoading', false, {root: true})
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                })
        });
    },

    getcommunitymanagercampaignbydate: ({ commit }, data) => {
        let id = data.id;

        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/community_manager-campaign-by-date/" + id)
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },

    getcommunitymanagerrating: ({ commit }, data) => {
        let id = data.id;

        return new Promise((resolve, reject) => {
            axios
                .get(process.env.VUE_APP_ROOT_API + "/community_manager-rating/" + id)
                .then((response) => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response && error.response.code === 401) {
                        return reject(error.response.message);
                    }
                    reject(error);
                });
        });
    },
}

const mutations = {
    setPendingData: (state, value) => {
        state.pendingData = value;
    },
    setApprovedData: (state, value) => {
        state.approvedData = value;
    },
    setRejectedData: (state, value) => {
        state.rejectedData = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};