<template>
    <div class="user-social-account margin-top16">
        <div class="box margino">
            <div class="box-header social-title-header">
                <text-component :class="'subtitle1'">Social Accounts</text-component>
            </div>
            <div class="box-content">
                <div class="interestdetail-saved">
                    <div v-if="!item[0].id">
                        <p style="text-align: center;">Currently no Social Accounts are Added</p>
                    </div>
                    <div>
                    <div class="row margino socialdetail-info" v-if="item[0].id">
                        <div class="profile-detail-txt col-12" v-for="(data, index) in item" :key="index">
                            <div class="row ac-info-row">
                                <div
                                    class="col-xl-4 col-12 social-image paddingo acname"
                                    :class="capitalizeWord(data.platform)">
                                    <text-component class="col-12">{{capitalizeWord(data.platform)}}</text-component>
                                </div>
                                <text-component v-if="data.name" class="col-xl-4 col-12 acid" :class="subtitle2class" >{{data.name}}</text-component>
                                <text-component v-else class="col-xl-4 col-12 acid" :class="subtitle2class" >{{data.username}}</text-component>
                                <img v-if="data.profile_pic" :src="data.profile_pic" height="30" width="30" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TextComponent,
  },
  computed: {
    ...mapGetters("influencerprofile", ["socialData"]),
    ...mapGetters("commonfunction", ["platformData"]),
  },
  watch: {
    socialData: {
      immediate: true,
      deep: true,
      handler(socialData) {
        if (socialData.length) {
          this.item = socialData;
        }
        else {
          this.isAccountadded = true;
        }
      },
    },

    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.sociallist = this.setsocial(platformData);
      },
    },
    item:{
      immediate:true,
      deep:true,
      handler(item){
        console.log(item);
      }
    }
  },
  data() {
    return {
        classname: "default",
        subtitle1class: "subtitle1",
        subtitle2class: "subtitle2",
        captionclass: "caption",
        black: "dark",
        plainclass: "btnplain",
        basicclass: "btnbasic",
        primaryclass: "btnprimary",
        item: [
            {
                username: null,
                platform: null,
                approved_status:0,
                name:null,
                profile_pic:null
            },
        ],
    };
  },
  created() {
    this.getsdetailext()
  },
  methods: {
    ...mapActions("influencerprofile", [
      "getsocialaccount"
    ]),
    ...mapActions("commonfunction", ["getuserpercentage"]),

    capitalizeWord(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getsdetailext() {
      this.getsocialaccount()
      .then((response) => {
        this.item = response.data;
      });
    },

    setsocial(data) {
      let newarray = [];
      data.map((value) => {
        if (value.name == "Twitter") {
          newarray.push({
            id: "twitter",
            label: "Twitter",
          });
        } else if (value.name == "Facebook") {
          newarray.push({
            id: "facebook",
            label: "Facebook",
          });
        } else if (value.name == "Instagram") {
          newarray.push({
            id: "instagram",
            label: "Instagram",
          });
        } else if (value.name == "Youtube") {
          newarray.push({
            id: "youtube",
            label: "Youtube",
          });
        }
      });
      return newarray;
    },
  },
};
</script>