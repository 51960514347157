<template>
    <div class="cmslist-page">
        <div class="content-header">
            <text-component class="h2">CMS List</text-component>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import Action from "./action.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapActions } from "vuex";
export default {
    components: {
        TextComponent,
        DatatableComponent,
        searchBar
    },
    data() {
        return {
            selectedRows: [],
            type: "text",
            inputPlaceholder: "Search Influencer",
            filter: "",
            classname: "default prefixicon",
            page: 1,
            per_page: 8,
            columns: [
                {
                    label: "CMS Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Action",headerAlign: "left", align: "left", component: (Action)},
            ],
            rows: [],
        }
    },
    methods: {
        ...mapActions("cmsdata", ["getcmslist"]),
        
        getcmslistdata() {
            this.getcmslist()
            .then((response) => {
                this.rows = response.data;
            });
        },
    },
    created() {
        this.getcmslistdata()
    }
}
</script>