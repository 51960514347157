<template>
  <div class="permissions">
    <div class="content-header">
      <label class="h2 header-content-left">Permissions</label>
    </div>

    <div class="content">
      <header-tabs
        :tabdata="tabdata"
        :activetab="activetab"
        :maxLimit="maxLimit"
        :isHiddenbar="isHiddenbar"
      >
        <form @submit.prevent="setuserpermission">
          <div class="tag-bar-bottom-data" v-if="activetab === 'User'">
            <div class="row">
              <div class="col-lg-4">
                <select-dropdown
                  required="true"
                  label="name"
                  :helptext="!$v.user.required && usersubmitted ? validationmessage.user: false"
                  :labelclass="{'validationerr': !$v.user.required && usersubmitted}"
                  :inputclass="{'error': !$v.user.required && usersubmitted, 'dropdownmenu': true}"
                  :placeholder="selectplaceholder"
                  :options="userdata"
                  @handlevalue="getuserpermission()"
                  :multiple="false"
                  v-model="user"
                >User</select-dropdown>
              </div>
            </div>
            <hr class="hr-divider" />
            <div class="permission-details">
              <div v-for="(permissionmodule, index) in permissiondata" :key="index">
                <div class="row inner-padding">
                  <div class="col-lg-3 col-12 text-pading">
                    <text-component :textclass="textclass">{{slugtoname(index)}}</text-component>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-6 row-padding">
                        <toggle-switch
                          class="toggle-switch"
                          v-model="usernonecheckbox[index]"
                          name="usernone"
                        ></toggle-switch>
                        <text-component class="permission-label" :textclass="innerText">None</text-component>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-6 row-padding"
                        v-for="(singledata, index2) in permissionmodule"
                        :key="index2"
                      >
                        <toggle-switch class="toggle-switch" v-model="usercheckbox[singledata.id]"></toggle-switch>
                        <text-component
                          class="permission-label"
                          :textclass="innerText"
                        >{{slugtoname(index2)}}</text-component>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="hr-divider" />
              </div>
              <div class="row inner-padding">
                <div class="col-lg-12">
                  <button-component
                    :clsname="clsname"
                    buttontype="submit"
                    class="update-btn"
                    :isDisabled="!checkPermission('permissions_manage')"
                  >Update Permission</button-component>
                </div>
              </div>
            </div>
          </div>
        </form>

        <form @submit.prevent="setrolepermission">
          <div class="tag-bar-bottom-data" v-if="activetab === 'Role'">
            <div class="row">
              <div class="col-lg-4">
                <select-dropdown
                  required="true"
                  label="role_name"
                  :helptext="!$v.role.required && submitted ? validationmessage.role: false"
                  :labelclass="{'validationerr': !$v.role.required && submitted}"
                  :inputclass="{'error': !$v.role.required && submitted, 'dropdownmenu': true}"
                  :placeholder="selectplaceholder"
                  :options="roledata"
                  @handlevalue="getrolepermission()"
                  :multiple="false"
                  v-model="role"
                >Role</select-dropdown>
              </div>
            </div>
            <hr class="hr-divider" />
            <div class="permission-details">
              <div v-for="(permissionmodule, index) in permissiondata" :key="index">
                <div class="row inner-padding">
                  <div class="col-lg-3 col-12 text-pading">
                    <text-component :textclass="textclass">{{slugtoname(index)}}</text-component>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-6 row-padding">
                        <toggle-switch
                          class="toggle-switch"
                          name="usernone"
                          v-model="rolenonecheckbox[index]"
                        ></toggle-switch>
                        <text-component class="permission-label" :textclass="innerText">None</text-component>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-6 row-padding"
                        v-for="(singledata, index2) in permissionmodule"
                        :key="index2"
                      >
                        <toggle-switch class="toggle-switch" v-model="rolecheckbox[singledata.id]"></toggle-switch>
                        <text-component
                          class="permission-label"
                          :textclass="innerText"
                        >{{slugtoname(index2)}}</text-component>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="hr-divider" />
              </div>
              <div class="row inner-padding">
                <div class="col-lg-12 text-pading">
                  <button-component
                    :clsname="clsname"
                    buttontype="submit"
                    class="update-btn"
                    :isDisabled="!checkPermission('permissions_manage')"
                  >Update Permission</button-component>
                </div>
              </div>
            </div>
          </div>
        </form>
      </header-tabs>
    </div>
  </div>
</template>

<script>
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import SelectDropdown from "../../../../BasicComponents/select/select.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ToggleSwitch from "../../../../BasicComponents/Checkbox/switchbox.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import { eventBus } from "../../../../main";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    HeaderTabs,
    SelectDropdown,
    TextComponent,
    ToggleSwitch,
    ButtonComponent
  },
  computed: {
    ...mapGetters("user", ["userData", "rolesData"]),
    ...mapGetters("adminpermission", ["permissionData"])
  },
  data() {
    return {
      usernonecheckbox: {},
      rolenonecheckbox: {},
      activetab: "User",
      maxLimit: 5,
      isHiddenbar: false,
      isMobileMenu: false,
      selectplaceholder: "Select...",
      user: "",
      role: "",
      validationmessage: {
        user: "please select the user",
        role: "please select the role"
      },
      usersubmitted: false,
      submitted: false,
      textclass: "subtitle2 dark",
      vendorpermission: "",
      innerText: "dark",
      clsname: "btnprimary",

      usercheckbox: {},
      rolecheckbox: {},

      tabdata: [
        { id: "1", value: "User" },
        { id: "2", value: "Role" }
      ],
      userdata: [],
      roledata: [],
      permissiondata: {}
    };
  },
  validations: {
    user: {
      required
    },
    role: { required }
  },

  methods: {
    ...mapActions("user", ["getuserData", "getrolesData"]),
    ...mapActions("adminpermission", [
      "getpermission",
      "singleuserpermission",
      "setpermission"
    ]),

    slugtoname(string) {
      let str = string.replace("_", " ");
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    getuserpermission() {
      let id = this.user;
      let type = "user";
      if (id) {
        this.singleuserpermission({
          id: id,
          type: type
        }).then(response => {
          console.log(response);
          this.usercheckbox = response.data;
          console.log("checking");
        });
      }
    },

    getrolepermission() {
      let id = this.role;
      let type = "role";
      if (id) {
        this.singleuserpermission({
          id: id,
          type: type
        }).then(response => {
          console.log(response);
          this.rolecheckbox = response.data;
          console.log("checking");
        });
      }
    },

    setuserpermission() {
      this.usersubmitted = true;
      if (this.$v.user.$invalid) {
        console.log(this.$v.user);
        this.$v.user.$touch();
      } else {
        this.setpermission({
          id: this.user,
          type: "user",
          permission: this.usercheckbox
        })
          .then(response => {
            console.log(response);
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
            console.log("checking");
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    setrolepermission() {
      this.submitted = true;
      if (this.$v.role.$invalid) {
        console.log(this.$v.role);
        this.$v.role.$touch();
      } else {
        this.setpermission({
          id: this.role,
          type: "role",
          permission: this.rolecheckbox
        })
          .then(response => {
            console.log(response);
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
            console.log("checking");
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    getnonecheckbox() {
      let permissions = Object.keys(this.permissiondata);
      let usernonecheckbox = {};
      let rolenonecheckbox = {};
      for (let data of permissions) {
        // console.log("datadata:",data)
        // console.log("indexdata:", index);
        usernonecheckbox[data] = true;
        rolenonecheckbox[data] = true;
      }
      this.usernonecheckbox = usernonecheckbox;
      this.rolenonecheckbox = rolenonecheckbox;
      // console.log(this.usernonecheckbox)
    }
  },

  watch: {
    userData: {
      immediate: true,
      handler(userData) {
        this.$data.userdata = userData;
      }
    },

    rolesData: {
      immediate: true,
      handler(rolesData) {
        this.$data.roledata = rolesData;
      }
    },

    permissionData: {
      immediate: true,
      handler(permissionData) {
        this.$data.permissiondata = permissionData;
        this.getnonecheckbox();
      }
    },

    usernonecheckbox: {
      immediate: false,
      deep: true,
      handler(usernonecheckbox) {
        let nonearray = Object.keys(usernonecheckbox);
        for (let title of nonearray) {
          if (usernonecheckbox[title]) {
            console.log(title);
            let category = Object.values(this.permissiondata[title]);
            category.map(value => {
              let uncheck = value["id"];
              if (this.usercheckbox[uncheck]) {
                this.usercheckbox[uncheck] = false;
              }
            });
          }
        }
      }
    },

    usercheckbox: {
      immediate: false,
      deep: true,
      handler(usercheckbox) {
        let nonearray = Object.keys(usercheckbox);
        for (let title in this.permissiondata) {
          let isnonecheck = true;
          let titledata = this.permissiondata[title];
          for (let key in titledata) {
            let iddata = titledata[key];
            let inarray = iddata["id"].toString();
            if (nonearray.indexOf(inarray) != -1 && usercheckbox[inarray]) {
              isnonecheck = false;
            }
          }
          if (isnonecheck) {
            this.usernonecheckbox[title] = true;
          } else {
            this.usernonecheckbox[title] = false;
          }
        }
      }
    },

    rolecheckbox: {
      immediate: false,
      deep: true,
      handler(rolecheckbox) {
        let nonearray = Object.keys(rolecheckbox);
        for (let title in this.permissiondata) {
          let isnonecheck = true;
          let titledata = this.permissiondata[title];
          for (let key in titledata) {
            let iddata = titledata[key];
            let inarray = iddata["id"].toString();
            if (nonearray.indexOf(inarray) != -1 && rolecheckbox[inarray]) {
              isnonecheck = false;
            }
          }
          if (isnonecheck) {
            this.rolenonecheckbox[title] = true;
          } else {
            this.rolenonecheckbox[title] = false;
          }
        }
      }
    },

    rolenonecheckbox: {
      immediate: false,
      handler(rolenonecheckbox) {
        let nonearray = Object.keys(rolenonecheckbox);
        for (let title of nonearray) {
          for (let title2 in this.permissiondata) {
            if (title == title2) {
              // console.log(this.permissiondata[title]);
              let category = Object.values(this.permissiondata[title]);
              category.map(value => {
                // console.log(value.id);
                for (let uncheck in this.rolecheckbox) {
                  if (uncheck == value.id) {
                    // console.log("matchhed");
                    this.rolecheckbox[uncheck] = false;
                  }
                }
              });
            }
          }
        }
      }
    }
  },

  created: function() {
    eventBus.$on(
      "changeActivetab",
      function(selected) {
        // console.log(selected);
        this.activetab = selected;
      }.bind(this)
    );

    this.getuserData();
    this.getrolesData();
    this.getpermission();
  }
};
</script>