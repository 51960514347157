import axios from 'axios'
import router from '../routes/routes'
import store from '../store/index'

export const setupAxiosDefaults = function (auth) {
  if (auth && auth.access_token) {
    axios.defaults.headers.common['Authorization'] = auth.token_type + ' ' + auth.access_token
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Methods"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token";

    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.post['Accept'] = 'application/json'
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

    axios.interceptors.response.use(response => {
      return response
    }, error => {
      if (error.response.status === 401) {
        console.log('Got unauthorized response, will try to handle it by refreshing, then logging out')

        const originalRequest = error.config
        originalRequest._retry = true
        let refreshToken = false
        let auth = window.localStorage.getItem('auth', null)

        if (auth) { auth = JSON.parse(auth) }
        if (auth) {
          refreshToken = auth.refresh_token
        }

        if (refreshToken) {
          return axios.post(`${process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX + '/oauth/token/refresh'}`, { refreshToken })
            .then(({ response }) => {
              window.localStorage.setItem('auth', response)
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token
              originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token

              return axios(originalRequest)
            })
            // eslint-disable-next-line handle-callback-err
            .catch(error => {
              console.log('Tried to refresh but got an error there as well. Logging out!')
              console.log(error.response)
              store.dispatch('user/signOut')
              router.push({ name: 'login' })
            })
        }

        console.log('Could not refresh, killing session and logging out:')
        store.dispatch('user/signOut')
        router.push({ name: 'login', query: { redirect: '/login' } })
      }
      return Promise.reject(error)
    })
  }
}
