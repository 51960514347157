import AgencyLayoutMain from '../layoutsagency/main.vue';
import AgencyDashboard from '../pages/agency/dashboard/dashboard.vue';
import AgencyProfile from '../pages/agency/profile/myprofile.vue';
import AgencyInfluencerList from '../pages/agency/influencer/influencerlist.vue';
import AgencyInfluencerInvite from '../pages/agency/influencer/campaigninvites.vue';
import AgencyInfluencerCategory from '../pages/agency/influencer/influencercategory.vue';
import AgencyPayment from '../pages/agency/payment/paymentinvoice.vue';
import AgencyNotification from '../pages/agency/notification/notification.vue';
import AuthInfluencerAgency from "../utils/AuthInfluencerAgency";
import Support from "../pages/agency/support/support.vue";
import SupportChat from "../pages/agency/support/chatdetail.vue"
import AgencyCamapignDetail from "../pages/agency/campaign/detail/main.vue";
import AgencyInvoiceDetail from "../pages/agency/payment/invoicedetail.vue";
import AgencyDetailPage from "../pages/agency/influencer/influencerdetail.vue";
import agencyRating from "../pages/agency/rating/rating.vue";
import agencyRatingDetail from "../pages/agency/rating/ratingdetail.vue";

export default {

    path: "/agency",
    component: AgencyLayoutMain,
    beforeEnter: AuthInfluencerAgency,
    meta: {
        title: "VidUnit",
    },
    children: [
        {
            path: "/agency/dashboard",
            name: "agencydashboard",
            component: AgencyDashboard,
        },
        {
            path: "/agency/myprofile",
            name: "agencyprofile",
            component: AgencyProfile,
        },
        {
            path: "/agency/notification",
            name: "agencynotification",
            component: AgencyNotification,
        },
        {
            path: "/agency/influencerlist",
            name: "agencyinfluencerlist",
            component: AgencyInfluencerList,
        },
        {
            path: "/agency/influencercategory",
            name: "agencyinfluencercategory",
            component: AgencyInfluencerCategory,
        },
        {
            path: "/agency/payment",
            name: "agencypayment",
            component: AgencyPayment,
        },
        {
            path: "/agency/support",
            name: "agencysupport",
            component: Support,
        },
        {
            path: "/agency/supportchat/:id",
            name: "agencysupportchat",
            component: SupportChat,
        },
        {
            path: "/agency/camapigndetail/:id",
            name: "agencycamapigndetail",
            component: AgencyCamapignDetail,
        },
        {
            path: "/agency/invoicedetail/:id",
            name: "agencyinvoicedetail",
            component: AgencyInvoiceDetail,
        },
        {
            path: "/agency/agencydetail/:id",
            name: "agencydetailpage",
            component: AgencyDetailPage,
        },
        {
            path: "/agency/rating",
            name: "agencyrating",
            component: agencyRating,
        },
        {
            path: "/agency/ratingdetail/:id",
            name: "agencyratingdetail",
            component: agencyRatingDetail,
        },
        {
            path: "/agency/influencerinvite",
            name: "agencyinfluencerinvite",
            component: AgencyInfluencerInvite,
        },
    ]

}