<template>
    <img v-if="row.status == '0'" @click="edit(row)" src="../../../assets/image/edit.svg" alt="edit-img" class="website-edit-img">
</template>
<script>
import { mapGetters,mapActions } from "vuex";
export default {
    props: ['row'],
    methods: {
        ...mapActions("publisherdata", ["deletearticle", "getarticlelist"]),

        edit(row) {
            
            this.$router.push({ name: "publisherwebsitetrafficedit", params:{id: row.id}});
        }
    }
}
</script>