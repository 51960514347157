<template>
    <div>
        <rating :rating="rating"></rating>
    </div>
</template>
<script>
import Rating from "../../../BasicComponents/rating/rating.vue";
export default {
    components: {
        Rating
    },
    props: ["row"],
    data() {
        return{
            rating:0
        }
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                if (row.avg_rating) {
                    this.rating = row.avg_rating
                }
            }
        }
    }
}
</script>