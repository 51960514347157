<template>
   <div>
      <div class="navigation-main" id="navpadding">
         
         <div class="navigation-item" v-for="ndata in navdata" :key="ndata.id"
            :class="[(itemA == ndata.id) ? 'activenav':'']">
            <div v-if="ndata.hasPermission || ndata.hasPermission==undefined" @click="itemActive(ndata.id)">
               <div class="navigation-item-inner">
               <router-link  :to="{name:ndata.name}" v-if="!ndata.child">
                  <div class="navigation-item-data">
                     <div :class="ndata.item" class="navigation-icon">
                        <img :src="require(`../../assets/image/${ndata.imgpath}`)" class="navimg-icon">
                     </div>
                     <div class="navigation-des" >
                        <span class="subtitle2">{{ndata.item}}</span>
                     </div>
                  </div>
               </router-link>
               </div>
               <div class="forchild" v-if="ndata.child">
                  <div class="allchild-data">
                     <div class="tab">
                        <input :id="ndata.id" type="checkbox" name="tab" class="extended-menu-box">
                        <label :for="ndata.id" class="extended-menu-txt">
                           <div :class="ndata.item" class="section-menu-icon">
                              <img :src="require(`../../assets/image/${ndata.imgpath}`)" class="navimg-icon">
                           </div>
                           <div class="section-menu-item">
                              {{ndata.item}}
                           </div>
                        </label>
                        <div class="tab-content">
                           <div class="category">
                              <div class="childdata" v-if="ndata.child">
                                 <div class="childdata-inner" v-for="cdata in ndata.child" :key="cdata.id" 
                                    :id="cdata.id"
                                    :class="[(itemB == cdata.id) ? 'activenavinner':'']">
                                    <div v-if="cdata.hasPermission || cdata.hasPermission==undefined" @click="itemActiveInner(cdata.id)">
                                       <div class="childdata-inner-item">
                                          <router-link class="inner-item-link" :to="{name:cdata.name}">
                                             {{cdata.item}}
                                             <div v-if="invitecount>0" :class="cdata.item=='Campaign Invites'?'display-count':'hide-count' ">
                                                {{invitecount}}
                                             </div>
                                          </router-link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="navigation-separator-main" v-if="navseparatordata">
            <div class="separator-line"></div>
            <div class="navigation-separator" v-for="nseparatordata in navseparatordata" :key="nseparatordata.id">
              <div v-if="!nseparatordata.child" class="separatordata">
                <router-link :to="{name:nseparatordata.name}" v-if="!nseparatordata.child">
                  <div class="navigation-item-data">
                    <div class="navigation-icon">
                      <img :src="require(`../../assets/image/${nseparatordata.imgpath}`)">
                    </div>
                    <div class="navigation-des" >
                      <span class="subtitle2">{{nseparatordata.item}}</span>
                    </div>
                  </div>
                </router-link>
              </div>
               <div class="forchild" v-if="nseparatordata.child">
                  <div class="allchild-data">
                     <div class="tab">
                        <input :id="nseparatordata.id" type="checkbox" name="tab" class="extended-menu-box">
                        <label :for="nseparatordata.id" class="extended-menu-txt">
                           <div class="section-menu-icon">
                              <img :src="require(`../../assets/image/${nseparatordata.imgpath}`)">
                           </div>
                           <div class="section-menu-item">
                              {{nseparatordata.item}}
                           </div>
                        </label>
                        <div class="tab-content">
                           <div class="category">
                              <div class="childdata" v-if="nseparatordata.child">
                                 <div class="childdata-inner" v-for="cseparatordata in nseparatordata.child" :key="cseparatordata.id" :id="cseparatordata.id">
                                    <div class="childdata-inner-item">
                                       <router-link :to="{name:cseparatordata.name}">{{cseparatordata.item}}</router-link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="navigation-section-main" v-if="navsectiondata" >
            <div class="section-menu">
            </div>
            <div class="navigation-section" v-for="nsectiondata in navsectiondata" :key="nsectiondata.id">
               <div class="navigation-item-inner">
                  <router-link :to="{name:nsectiondata.name}" v-if="!nsectiondata.child">
                    <div class="navigation-item-data">
                      <div class="navigation-icon">
                        <img :src="require(`../../assets/image/${nsectiondata.imgpath}`)">
                      </div>
                      <div class="navigation-des" >
                        <span class="subtitle2">{{nsectiondata.item}}</span>
                      </div>
                    </div>
                  </router-link>
               </div>
               <div class="forchild" v-if="nsectiondata.child">
                  <div class="allchild-data">
                     <div class="tab">
                        <input :id="nsectiondata.id" type="checkbox" name="tab" class="extended-menu-box">
                        <label :for="nsectiondata.id" class="extended-menu-txt">
                           <div class="section-menu-icon">
                              <img :src="require(`../../assets/image/${nsectiondata.imgpath}`)">
                           </div>
                           <div class="section-menu-item">
                              {{nsectiondata.item}}
                           </div>
                        </label>
                        <div class="tab-content">
                           <div class="category">
                              <div class="childdata" v-if="nsectiondata.child">
                                 <div class="childdata-inner" v-for="csectiondata in nsectiondata.child" :key="csectiondata.id" :id="csectiondata.id">
                                    <div class="childdata-inner-item">
                                       <router-link :to="{name:csectiondata.name}">{{csectiondata.item}}</router-link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name : "Navigation",
   props: {
      navdata: Array,
      navseparatordata: Array,
      navsectiondata: Array,
   },
   computed: {
      ...mapGetters("influencercampaign", ["inviteData"]),
   },
   watch: {
      inviteData: {
         immediate: true,
         handler(inviteData) {
               this.invite = inviteData;
               console.log(inviteData)
               this.invitecount = inviteData.length
         }
      }
   },
   data() {
      return {
         itemA:'0',
         itemB:'0',
         invite: [],
         invitecount: null
      }
   },
   methods: {
      ...mapActions("influencercampaign",["getpendinginvite"]),

      itemActive(e) {
         this.itemA = e;
      },
      itemActiveInner(e) {
         this.itemB = e;
      }
   },
   created() {
      this.getpendinginvite();
   },
}; 
</script>