<template>
    <div class="box margino">
        <div class="box-content">
            <div class="row margino chargesdetail">
                <text-component class="chargesheader-header col-12 paddingo" :class="subtitle1class">Charges Details</text-component>
                <div v-if="chargesData.length <= 0" class="col-12">
                    <div class="subtitle2 tab-no-data-class">Currently no charges are Added</div>
                </div>
                <div v-else class="row margino col-12 paddingo chargedetail" v-for="(data, index) in chargesData" :key="index">
                    <div class="profile-detail-txt col-12 col-lg-6 col-xl-3">
                        <div class="row p-info">
                            <text-component :class="subtitle2class">Platform</text-component>
                            <text-component :class="'black'">{{getplatformname(data.platform)}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-lg-6 col-xl-3">
                        <div class="row p-info">
                            <text-component :class="subtitle2class">Language</text-component>
                            <text-component :class="'black'">{{getlanguagename(data.language)}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-lg-6 col-xl-3">
                        <div class="row p-info">
                            <text-component :class="subtitle2class">Minimum Charges</text-component>
                            <text-component :class="'black'">₹{{data.min_charge}}</text-component>
                        </div>
                    </div>
                    <div class="profile-detail-txt col-12 col-lg-6 col-xl-3">
                        <div class="row p-info">
                            <text-component :class="subtitle2class">Maximum Charges</text-component>
                            <text-component :class="'black'">₹{{data.max_charge}}</text-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";

export default {
    components: {
        TextComponent,
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            chargesData:[],
            platform:[],
            language:[]
        };
    },
    computed:{
        ...mapGetters("commonfunction", ["platformData", "languageData"])
    },
    watch:{
        platformData:{
            immediate:true,
            handler(platformData){
                this.platform = platformData
            }
        },
        languageData:{
            immediate:true,
            handler(languageData){
                this.language = languageData
            }
        },
    },
    created: function() {
        this.getData();
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getchargesdetails"]),
        getData(){
            this.getchargesdetails({id:this.$route.params.id})
            .then(response=>{
                console.log(response.data)
                this.chargesData = response.data
            });
        },
        getplatformname(data) {
            let name = this.platform.filter(value => {
                return value.id == data;
            });
            if(name[0]){
                return name[0].name;
            }
        },

        getlanguagename(data) {
            let name = this.language.filter(value => {
                return value.id == data;
            });
            if(name[0]){
                return name[0].language;
            }
        },
    }
}
</script>