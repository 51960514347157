<template>
  <div>
    <label-component class="inputtxt">
      <slot /><sup v-if="required">*</sup>
    </label-component>
    <!-- <input-component
      :inputPlaceholder="inputPlaceholder"
      v-model="inputGroup"
      :dropdownPosition="dropdownPosition"
      :options="options"
      :classname="classname"
      :type="type"
      :maxlength="maxlength"
      :disabledInput="disabledInput"
      @searchItem="keypushed"
      @change="changefunction()"
      @keypress="keypress()"
    ></input-component> -->
    <div class="otp_fields">
        <input 
            v-for="index in otplen" :key=index
            :class="classname" 
            v-model="otp[index]"
            v-on:change="changefunction()" 
            v-on:input="updateValue()" 
            type="number" 
            :disabled="disabledInput" 
            :readonly="isReadonly" 
            :required="required" 
            @keyup="press($event)"  
            @keypress="detectBack($event)" 
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            :placeholder="inputPlaceholder" 
            :maxlength="1" />
    </div>
    <div v-if="helptext" class="error-helper-msg">
      <label :class="labelclass">{{helptext}}</label>
    </div>
  </div>
</template>

<script>
import LabelComponent from "../Label/Label.vue";
// import InputComponent from "../Input/Input";

export default {
  props: [
    "required",
    "isReadonly",
    "classname",
    "labelclass",
    "otplen",
    "helptext",
    "options",
    "dropdownPosition",
    "inputPlaceholder",
    "type",
    "value",
    "maxlength",
    "disabledInput"
  ],
  data() {
    return {
        otp:[]
      // inputGroup: ""
    };
  },
  components: {
    LabelComponent,
    // InputComponent
  },
  computed: {
    inputGroup: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
//   watch: {
//     otplen() {
//       console.log("otplen",otplen)
//     }
//   },
  watch:{
    otplen:{
      immediate:true,
      handler(otplen){
        console.log("otplen",otplen)
      }
    }
  },
  methods: {
    changefunction() {
      this.$emit('change')
      // console.log("emit:")
    },
    updateValue: function () {
        let finalVal = "";
        for (let i = 1; i <= this.otplen; i++) {
            if(this.otp[i]){
                finalVal += this.otp[i];
            }
        }

        this.$emit('input', finalVal)
    },
    press: function (event) {
        if(event.key=="Backspace"){
            if(!event.target.value && event.target.previousElementSibling){
                event.target.previousElementSibling.focus()
            }
        }else{
            if(event.target.value && event.target.nextElementSibling){
                event.target.nextElementSibling.focus()
            }
        }
    },
    detectBack: function (event) {
        // console.log(event.which)
    }
  }
};
</script>