<template>
    <div class="rating-detail">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">My Ratings</text-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino paddingo">
                <div class="row margino custmor-admin-rating">
                    <div class="col-12 col-md-6 custmor-admin-rating-left">
                        <div class="box-header">
                            <text-component class="subtitle1">Brand/Agency Ratings</text-component>
                        </div>
                        <div class="box-content">
                            <five-star-progres :ratingData="brandRatingCount"></five-star-progres>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 custmor-admin-rating-right">
                        <div class="box-header">
                            <text-component class="subtitle1">Admin Ratings</text-component>
                        </div>
                        <div class="box-content">
                            <five-star-progres :ratingData="adminRatingCount"></five-star-progres>
                        </div>
                    </div>
                </div>
                <hr class="hr-divider" />
                <div class="comment-header">
                     <text-component class="subtitle1 comment-header-txt">Comment Details</text-component>
                     <div class="filter-selector">
                        <select-component
                            class="txtgroup"
                            :options="filteroption"
                            v-model="filtervalue"
                            :multiple="false"
                            :placeholder="selectplaceholder">
                            Filter Commments
                        </select-component>
                    </div>
                </div>
                <div class="all-comments">
                    <div class="all-comments-main" v-if="all_comments.length">
                        <div class="row margino all-comments-main-inner" v-for="(row,index) in all_comments" :key="index">
                            <div class="col-12">
                                <div class="row margino user-img-name">
                                    <div class="col-12">
                                        <img v-if="row.profile_pic" :src="row.baseUrl+'/'+row.user_id+'/'+row.profile_pic" class="comment-user-icon">
                                        <img v-else  src="../../../assets/image/usericon.png" class="comment-user-icon">
                                        <text-component class="subtitle1 user-name">{{row.full_name}}</text-component>
                                    </div>
                                </div>
                                <div class="row margino user-rating-feedback">
                                    <div class="col-12">
                                        <rating-component :rating="row.rating"></rating-component>
                                        <div class="feedback-txt">
                                            <text-component class="subtitle2">{{row.rating}} out of 5</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="row margino user-rating-detail feedback" v-if="row.campaign_name">
                                    <div class="col-12">
                                        <div class="user-rating-detail-txt">
                                            <text-component class="subtitle2">Campaign:</text-component>
                                        </div>
                                        <div class="user-rating-detail-desc">
                                            <text-component class="dark note-txt">{{row.campaign_name}}</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="row margino user-rating-detail feedback">
                                    <div class="col-12">
                                        <div class="user-rating-detail-txt">
                                            <text-component class="subtitle2">Feedback:</text-component>
                                        </div>
                                        <div class="user-rating-detail-desc">
                                            <text-component class="dark note-txt">{{row.feedback}}</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="row margino user-rating-detail comment">
                                    <div class="col-12">
                                       <div class="user-rating-detail-txt">
                                            <text-component class="subtitle2">Comment:</text-component>
                                        </div>
                                        <div class="user-rating-detail-desc">
                                            <text-component class="dark note-txt">{{row.comment}}</text-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="row margino user-rating-detail note">
                                    <div class="col-12">
                                        <div class="user-rating-detail-txt">
                                            <text-component class="subtitle2">Note:</text-component>
                                        </div>
                                        <div class="user-rating-detail-desc">
                                            <text-component class="dark note-txt">{{row.note}}</text-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import RatingComponent from "../../../BasicComponents/rating/rating.vue"
import SelectComponent from "../../../BasicComponents/select/select.vue";
import FiveStarProgres from "./fivestarprogress.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        RatingComponent,
        FiveStarProgres,
        SelectComponent
    },
    computed:{
        ...mapGetters("influencerprofile",["influencerRating"]),
    },
    data() {
        return {
            h2txtclass: 'h2',
            customerrating: 3.6,
            adminrating: 4,
            cprogress5: 23,
            cprogress4: 54,
            cprogress3: 75,
            cprogress2: 28,
            cprogress1: 48,
            selectplaceholder: 'Select Filter...',
            filtervalue: '1',
            customertotalrating: '3.6',
            admintotalrating: '3.6',
            filteroption: [
                {id:'1', label:'All'},
                {id:'2', label:'Admin'},
                {id:'3', label:'Brands'},
            ],
            adminRatingCount:[],
            brandRatingCount:[],
            all_comments:[],
            rows:[],
        }
    },
    created() {
        this.getInfulencerRating();
    },
    watch:{
        influencerRating:{
            immediate:true,
            handler(influencerRating){
                console.log('influencerRating',influencerRating)
                this.adminRatingCount = influencerRating.adminRatingCount
                this.brandRatingCount = influencerRating.brandRatingCount
                this.rows = influencerRating.all_comments
                this.filterData();
            }
        },
        filtervalue:{
            immediate:false,
            handler(filtervalue){
                this.filterData();
            }
        }
    },
    methods: {
        ...mapActions("influencerprofile", ["getInfulencerRating"]),
        
        filterData(){
            let filtervalue = this.filtervalue
            if (filtervalue!=1) {
                let all_comments = [];
                if (filtervalue==2) {
                    all_comments = this.rows.filter(row=>{
                        return row.rated_by_role==1
                    })
                }
                if (filtervalue==3) {
                    all_comments = this.rows.filter(row=>{
                        return row.rated_by_role==4
                    })
                }
                this.all_comments = all_comments;
            }else{
                this.all_comments = this.rows;
            }
        }
    },
}
</script>