<template>
    <div class="box margino website-detail">
        <div class="web-details-header">
            <span class="subtitle2">Website Details</span>
        </div>
        <div v-if="websitedata.length">
            <div class="website-detail-txt col-12" v-for="(data, index) in websitedata" :key="index">
                <div class="row">
                    <div class="col-12 col-md-6 websitedata">
                        <text-component class="paddingo subtitle2">Website Link</text-component>
                        <text-component class="paddingo datatxt">{{websitedata[index].website_link}}</text-component>
                    </div>
                    <div class="col-12 col-md-6 websitedata">
                        <text-component class="paddingo subtitle2">Category</text-component>
                        <text-component class="paddingo datatxt">{{websitedata[index].category}}</text-component>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-data" v-else>
            <span class="subtitle2">no data avaliable</span>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        TextComponent
    },
    data() {
        return {
            websitedata: []
        }
    },
    methods: {
        ...mapActions("publisherdata", ["websitedetailid"]),
    },
    created() {
        this.websitedetailid({id : this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.websitedata = response.data
            });
    }
}
</script>