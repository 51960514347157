<template>
    <div class="box margino box-first">
        <div class="box-content social-accounts">
            <text-component class="chargesheader-header col-12 paddingo" :class="subtitle1class">Connected Social Accounts</text-component>
            <div class="connected-social-acc-logo-main"
                v-if="socialData.length">
                <div 
                    v-for="sdata in socialData" :key="sdata.id"
                    class="connected-social-acc-logo pointer"
                    @click="redirectAccount(sdata)"
                    :class="sdata.platform">
                </div>
            </div>
            <div v-else>
                <div class="subtitle2 tab-no-data-class">Currently no Social media accounts created.</div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";

export default {
    components: {
        // TextComponent,
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            socialData:[],
        };
    },
    created: function() {
        this.getData();
    },
    computed: {
        // ...mapGetters("influencerprofile", ["profileData"]),
        ...mapGetters("commonfunction", ["countryData", "cityData"])
    },
    watch: {
        countryData: {
            immediate: true,
            handler(countryData) {
                this.$data.country = countryData;
                // console.log("country data"+ countryData)
            }
        },
        cityData: {
            immediate: true,
            handler(cityData) {
                this.$data.city = cityData;
                // console.log("city data"+ cityData)
            }
        }
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getuserprofile","getsocialaccount"]),
        ...mapActions("commonfunction", ["getcity"]),
        getData(){
            this.getsocialaccount({id:this.$route.params.id})
            .then(response=>{
                console.log('socialData',response.data)
                this.socialData = response.data
            });
        },

        redirectAccount(data){
            // console.log('data clicked',data)
            if(data.platform=='facebook'){
                window.open(`https://www.facebook.com/${data.username}/`, '_blank')
            }

            if(data.platform=='twitter'){
                window.open(`https://twitter.com/${data.username}/`, '_blank')
            }

            if(data.platform=='youtube'){
                window.open(`https://www.youtube.com/channel/${data.username}/`, '_blank')
            }

            if(data.platform=='instagram'){
                window.open(`https://www.instagram.com/${data.username}/`, '_blank')
            }
        }
    }
}
</script>