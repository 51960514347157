<template>
  <form @submit.prevent="setpassword">
    <div class="login-page">
      <div class="row margino">
        <div class="col-12 col-md-6 login-left">
          <text-component :textclass="h2class">Reset Password</text-component>
          <div class="row rowpadding noaccount">
            <text-component class="mid">Set new password for sign in to VidUnit.</text-component>
            <!-- <router-link to="signup">
              <button-component class="btnsignup" :clsname="btnplainclass">Sign up</button-component>
            </router-link>-->
          </div>
          <input-component
            required="true"
            class="txtgroup"
            :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
            :labelclass="{'validationerr': !$v.item.password.required && submitted}"
            :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
            type="password"
            v-model="item.password"
          >New Password</input-component>
          <input-component
            required="true"
            class="txtgroup"
            :helptext="passwordMsg()"
            :labelclass="{'validationerr': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted}"
            :classname="{'error': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted, 'default': true}"
            type="password"
            v-model="item.confirmpassword"
          >Confirm Password</input-component>

          <br />
          <button-component
            class="loginbtn"
            buttontype="submit"
            :clsname="btnregularclass"
          >Reset password</button-component>

          <div class="return-login-div">
            <text-component class="mid return-to">Return to</text-component>
            <router-link to="/login">
              <button-component class="btnlogin" :clsname="btnplainclass">Log in</button-component>
            </router-link>
          </div>
        </div>
        <div class="col-6 login-right"></div>
      </div>
    </div>
  </form>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { mapActions } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent
  },
  validations: {
    item: {
      password: {
        required
      },
      confirmpassword: { required, sameAsPassword: sameAs("password") }
    }
  },
  data() {
    return {
      item: {
        password: null,
        confirmpassword: null
      },
      validationmessage: {
        password: "password is required",
        confirmpassword: "confirm password is required",
        sameAsPassword: "password did not match"
      },
      submitted: false,
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnprimary"
    };
  },

  methods: {
    ...mapActions("user", ["resetpassword"]),

    passwordMsg() {
      if (!this.$v.item.confirmpassword.required && this.submitted) {
        return this.validationmessage.confirmpassword;
      } else if (
        !this.$v.item.confirmpassword.sameAsPassword &&
        this.submitted
      ) {
        return this.validationmessage.sameAsPassword;
      } else {
        return false;
      }
    },

    setpassword() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.resetpassword({
          token: this.$route.params.token,
          email: this.$route.params.email,
          password: this.item.password,
          password_confirmation: this.item.confirmpassword
        }).then(response => {
          console.log(response);
          vm.$router.push({ name: "Login" });
          console.log("checking");
        });
      }
    }
  },
  // created() {
  //   console.log(window.location.hostname)
  //   console.log(window.location.href)
  //   console.log(window.location.pathname)
  // },
};
</script>

<style lang="scss" scoped>
.return-login-div{
    margin-top: 12px;
    .return-to{
        margin-right: 12px;
    }
}
</style>