<template>
    <div class="userdashboard agency brand">
        <!-- <div class="content-header">
            <div class="page-header">
                <text-component :class="h2txtclass">Dashboard</text-component>
                <div class="page-header-right">
                    <button-component @onBtnClick="createCampaign" :clsname="btninvitecls">Create Campaign</button-component>
                </div>
            </div>
        </div> -->
        <div class="content padding-topo">
            <notification-page></notification-page>
            <campaign-list-page></campaign-list-page>
            <event-calendar-page></event-calendar-page>
        <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isCreateCampaign}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close"><img @click="createCampaign" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                <div class="header"><text-component :class="h2txtclass">Create Campaign</text-component></div>
                <div class="row content-align">
                    <input-component
                        class="txtgroup col-12 col-sm-6 col-md-4"
                        :classname="defaultclassname"
                        v-model="invoiceid">
                        Campaign Name
                    </input-component>
                    <div class="txtgroup col-12 col-sm-6 col-md-4">
                        <div class="row">
                        <input-component
                            class="gen-ratio-left txtgroup padding-topo col-12 col-sm-6"
                            :classname="defaultclassname"
                            v-model="agencyemail"
                            inputPlaceholder="Male">
                            Gender Ratio
                        </input-component>
                        <input-component
                            class="gen-ratio-right txtgroup padding-topo col-12 col-sm-6"
                            :classname="defaultclassname"
                            v-model="agencyemail"
                            inputPlaceholder="Female">
                        </input-component>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 txtgroup datetimepicker row-height">
                        <div class="datetimepicker-txt inputtxt">
                            <text-component>
                                Start Date & End Date
                            </text-component>
                        </div>
                        <div class="datetimepicker-main">
                            <date-picker
                                v-model="value1" 
                                type="date"
                                range
                                :editable="editable"
                                placeholder="Select date range">
                            </date-picker>
                        </div>  
                    </div>
                    <input-component
                        class="txtgroup col-12 col-sm-6 col-md-4"
                        :classname="defaultclassname"
                        v-model="agencyemail">
                        Number of Influencers
                    </input-component>
                    <select-component
                        class="txtgroup col-12 col-sm-6 col-md-4"
                        :options="campaignnameoption"
                        v-model="campaignname"
                        :multiple="false"
                        :placeholder="selectplaceholder">
                        Influencer category 
                    </select-component>
                    <select-component
                        class="txtgroup col-12 col-sm-6 col-md-4"
                        :options="campaignstatusoption"
                        v-model="campaignstatus"
                        :multiple="false"
                        :placeholder="selectplaceholder">
                        Social Media Platform
                    </select-component>
                    <select-component
                        class="txtgroup col-12 col-sm-6 col-md-4"
                        :options="campaignstatusoption"
                        v-model="campaignstatus"
                        :multiple="false"
                        :placeholder="selectplaceholder">
                        Content Format
                    </select-component>
                    <input-component
                        class="txtgroup amountinputbox col-12 col-sm-6 col-md-4"
                        :classname="defaultclassname"
                        v-model="amount">
                        Campaign Budget
                    </input-component>
                    <div class="txtgroup col-12 col-sm-6 col-md-4">
                        <div class="inputtxt">
                            <text-component>Campaign Hashtags</text-component>
                        </div>
                        <vue-tags-input
                        v-model="tag"
                        :tags="tags"
                        @tags-changed="newTags => tags = newTags"/>
                    </div>
                    <div class="txtgroup description col-12 col-sm-6 col-md-4">
                        <div class="inputtxt">
                            <text-component>Brief Description</text-component>
                        </div>
                        <textarea class="txtarea"></textarea>
                    </div>
                </div>
                <hr class="hrline"/>
                <div class="modal-footer">
                    <div>
                        <button-component @onBtnClick="createCampaign" :clsname="btninvitecls">Create</button-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
        </div>
    </div>
</template>
<script>
import NotificationPage from './notification.vue';
import CampaignListPage from './campaignlist.vue';
import EventCalendarPage from './eventcalendar.vue';
import ButtonComponent from '../../../BasicComponents/Button/Button.vue';
import TextComponent from '../../../BasicComponents/Text/Text.vue';
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    components: {
        NotificationPage, 
        CampaignListPage,
        EventCalendarPage,
        TextComponent,
        ButtonComponent,
        InputComponent,
        SelectComponent,
        VueTagsInput
    },
    data() {
        return{
            h2txtclass: 'h2',
            btninvitecls: 'btnprimary',
            isCreateCampaign: false,
            selectplaceholder: 'Select...',
            defaultclassname: 'default',
            invoiceid: '',
            agencyemail: '',
            editable: '',
            campaignname: '',
            campaignstatus: '',
            amount: '',
            tags: [],
            tag: '',
            value1: [],
            campaignnameoption: [],
            campaignstatusoption: []
        }
    },
    methods: {
        createCampaign() {
            if(this.isCreateCampaign == true){
                this.isCreateCampaign = false
            }
            else{
                this.isCreateCampaign = true	
            }
        },
    }
}
</script>