<template>
  <div class="socialdetail boxmargin">
    <div class="box margino">
      <div class="box-content">
        <div class="myprofile-header">
          <text-component
            class="myprofile-header-txt"
            :class="subtitle1class"
          >Connect Social Accounts</text-component>
          <div v-if="!IsSocialDetailEditable" class="myprofile-header-right">
            <button-component 
              @onBtnClick="socialDetailToggle" 
              :clsname="basicclass">
                <div v-if="isAccountadded">
                  Add
                </div>
                <div v-else>
                  Edit
                </div>
              </button-component>
          </div>
        </div>
        <!-- <button @click="handleAuthClick">Google Auth</button> -->
        <div v-if="!IsSocialDetailEditable" class="socialdetail-saved">
          <div v-if="isAccountadded">
            <p style="text-align: center;">Currently no social accounts are Added</p>
          </div>
          <div v-else>
            <div class="row margino socialdetail-info" v-if="item[0].id">
              <div class="profile-detail-txt col-12" v-for="(data, index) in item" :key="index">
                <div class="row">
                  <img class="social-account-row-profile" v-if="data.profile_pic" :src="data.profile_pic" height="30" width="30" />
                  <img class="social-account-row-profile" v-else src="../../../assets/image/usericon.png" height="30" width="30" />
                  
                  <text-component v-if="data.name" class="col-xl-4 col-12 acid" :class="subtitle2class" >{{data.name}}</text-component>
                  <text-component v-else class="col-xl-4 col-12 acid" :class="subtitle2class" >{{data.username}}</text-component>

                  <div
                    class="col-xl-4 col-12 social-image acname"
                    :class="capitalizeWord(data.platform)"
                  >
                    <text-component class="col-12">{{capitalizeWordWithDash(data.platform)}}</text-component>
                  </div>
                  <!-- <div class="col-xl-4 col-12 actype">
                    <text-component :class="subtitle2class">Account Type</text-component>
                    <text-component :class="black">Travel & Lifestyle</text-component>
                  </div>-->
      
                </div>
              </div>
              <!-- <div class="profile-detail-txt col-12">
                <div class="row">
                  <div class="col-xl-4 col-12 social-image acname Facebook">
                    <text-component class="col-12">Facebook</text-component>
                  </div>
                  <div class="col-xl-4 col-12 actype">
                    <text-component :class="subtitle2class">Account Type</text-component>
                    <text-component :class="black">Travel & Lifestyle</text-component>
                  </div>
                  <text-component
                    class="col-xl-4 col-12 acid"
                    :class="subtitle2class"
                  >@jacquelinehart06</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12">
                <div class="row">
                  <div class="col-xl-4 col-12 social-image acname Youtube">
                    <text-component class="col-12">Youtube</text-component>
                  </div>
                  <div class="col-xl-4 col-12 actype">
                    <text-component :class="subtitle2class">Account Type</text-component>
                    <text-component :class="black">Travel & Lifestyle</text-component>
                  </div>
                  <text-component
                    class="col-xl-4 col-12 acid"
                    :class="subtitle2class"
                  >@jacquelinehart06</text-component>
                </div>
              </div>
              <div class="profile-detail-txt col-12">
                <div class="row">
                  <div class="col-xl-4 col-12 social-image acname Twitter">
                    <text-component class="col-12">Twitter</text-component>
                  </div>
                  <div class="col-xl-4 col-12 actype">
                    <text-component :class="subtitle2class">Account Type</text-component>
                    <text-component :class="black">Travel & Lifestyle</text-component>
                  </div>
                  <text-component
                    class="col-xl-4 col-12 acid"
                    :class="subtitle2class"
                  >@jacquelinehart06</text-component>
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <div v-if="IsSocialDetailEditable" class="socialdetail-editable">
          <form @submit.prevent="addSocialAccount">
            <div v-for="(data, index) in item" :key="index">
              <div class="row socialdetail-row">
                <div class="row col-9 socialdetail-box">
                  <select-component
                    class="txtgroup col-12 col-xl-3 col-lg-4"
                    required="true"
                    :options="sociallist"
                    :helptext="!$v.item.$each[index].platform.required && submitted ? validationmessage.platform: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].platform.required && submitted}"
                    :inputclass="{'error': !$v.item.$each[index].platform.required && submitted, 'dropdownmenu': true}"
                    v-model="item[index].platform"
                    :multiple="false"
                    :placeholder="selectplaceholder"
                    :disabled="(item[index].approved_status == '1')">
                    Platform
                  </select-component>
                  <input-component
                    required="true"
                    @mouseover="setpopupdata(item[index].platform)"
                    class="txtgroup col-12 col-xl-5 col-lg-4"
                    :helptext="!$v.item.$each[index].username.required && submitted ? validationmessage.username: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].username.required && submitted}"
                    :classname="{'error': !$v.item.$each[index].username.required && submitted, 'default': true}"
                    v-model="item[index].username"
                    :disabledInput="(item[index].approved_status == '1')">
                    User Name
                    <tooltip-component 
                      :width="'14px'" 
                      :height="'14px'"
                      :inmodal="false"
                      v-if="item[index].approved_status == '0'">
                      <!-- {{(selectedplatform)?selectedplatform+' is selected':'please select a platform'}} -->
                      <div v-if="selectedplatform == null">
                        <ul>
                          <li>please select a platform</li>
                        </ul>
                      </div>
                      <div v-if="selectedplatform == 'facebook'">
                        <ul>
                          <li>In Username field you have to enter your email which is connected to your facebook account</li>
                        </ul>
                      </div>
                      <div v-if="selectedplatform == 'instagram'">
                        <ul>
                          <li>In Username field you have to enter your instagram username which you can find in your account.
                            Go to edit profile in your account -> username field will be your username
                          </li>
                        </ul>
                      </div>
                      <div v-if="selectedplatform == 'twitter'">
                        <ul>
                          <li>In Username field you have to enter your twitter username which you can find in your account.
                            Go to your profile in your account -> below your name there will be text with @ symbol will be your username
                          </li>
                        </ul>
                        
                      </div>
                      <div v-if="selectedplatform == 'youtube'">
                        <ul>
                          <li>In Username field you have to enter your youtube channel Id which you can find in URL.
                              For Ex. https://www.youtube.com/channel/UC2OC3OsYdeVfgnEVCpcWvBg
                              In above URL UC2OC3OsYdeVfgnEVCpcWvBg is your channel Id.
                          </li>
                        </ul>
                        
                      </div>
                    </tooltip-component>
                  </input-component>
                  <div class="col-12 col-xl-4 col-lg-4">
                    <div class="row">
                      <div class="col-9 social-name" v-if="data.name">
                        <text-component :class="'subtitle2'">Name</text-component><br>
                        <text-component :class="black">{{data.name}}</text-component>
                      </div>
                      <div class="col-3 social-profile"  v-if="data.profile_pic">
                        <img :src="data.profile_pic" height="30" width="30" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 socialdetail-btn">
                  <button-component
                      v-if="item[index].platform=='youtube' && data.approved_status!='1'"
                      class="social-login-btn-g"
                      :clsname="'btnbasic'"
                      @onBtnClick="addSocialAccount(data, index)"
                    >
                  </button-component>
                  <button-component
                    v-else
                    :clsname="primaryclass"
                    @onBtnClick="addSocialAccount(data, index)"
                    :isDisabled="data.approved_status == '1'"
                  >{{(data.approved_status == '1')?'Verified':'Verify'}}</button-component>
                </div>
                <div class="col-1 socialdetail-image paddingo" 
                  v-if="index < Object.keys(item).length - 1 && item.length != 1">
                  <!-- <img
                    @click="addrowclicked()"
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="plus-img"
                  /> -->
                  <img
                    @click="removerowclicked(data, index)"
                    src="../../../assets/image/minus.svg"
                    alt="minus-circle"
                    class="minus-img"
                  />
                </div>
                <div class="col-1 socialdetail-image paddingo" 
                  v-if="index >= Object.keys(item).length - 1 && item.length != 1">
                  <img
                    @click="addrowclicked()"
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="plus-img"
                  />
                  <img
                    @click="removerowclicked(data, index)"
                    src="../../../assets/image/minus.svg"
                    alt="minus-circle"
                    class="minus-img"
                  />
                </div>
                <div class="col-1 socialdetail-image paddingo" v-if="item.length == 1">
                  <img
                    @click="addrowclicked()"
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="plus-img"
                  />
                  <!-- <img
                    @click="removerowclicked(data)"
                    src="../../../assets/image/minus.svg"
                    alt="minus-circle"
                    class="minus-img"
                  /> -->
                </div>
              </div>
            </div>
            <!-- {{item}} -->
          </form>
          <hr class="hrline-footer" />
          <div class="width edit-footer">
            <button-component
              class="footer-btn"
              @onBtnClick="addsocialaccounts"
              :clsname="primaryclass"
            >Save</button-component>
          </div>
        </div>
      </div>
    </div>
      <!-- Modal -->
      <div :class="{'modal-window': true , 'popupopen': isPopupOpen}">
        <div class="modal-window-main">
          <a title="Close" class="modal-close">
            <img
              @click="popUp"
              src="../../../assets/image/cancel.svg"
              class="cancel-icon"
              alt="cancel-icon"
            />
          </a>
          <div class="popheader">
            <text-component :class="h2txtclass">Verify Pin</text-component>
          </div>
          <div class="row">
            <input-component
              required="true"
              class="txtgroup col-12 col-md-12"
              :classname="{'error': pin == '' && pinverify, 'default': true}"
              :helptext="pin == '' && pinverify ? 'pin is required': false"
              :labelclass="{'validationerr': pin == '' && pinverify}"
              v-model="pin"
            >Enter Pin</input-component>
          </div>
          <hr class="hrline" />
          <div class="modal-footer">
            <div>
              <button-component @onBtnClick="verifypin" :clsname="btninvitecls">Submit</button-component>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import TooltipComponent from "../../../BasicComponents/tooltip/tooltip.vue";
import { mapGetters, mapActions } from "vuex";
import { initFbsdk } from "@/config/facebook_oAuth.js";
import { required } from "vuelidate/lib/validators";
import Vue from 'vue'
// import GoogleAuth from 'vue-google-oauth2'
// import axios from "axios";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent,
    TooltipComponent
  },
  validations: {
    item: {
      $each: {
        username: { required },
        platform: { required },
      },
    },
  },
  computed: {
    ...mapGetters("influencerprofile", ["socialData"]),
    ...mapGetters("commonfunction", ["platformData"]),
  },
  watch: {
    socialData: {
      immediate: true,
      deep: true,
      handler(socialData) {
        if (socialData.length) {
          this.item = socialData;
          this.isAccountadded = false;
        }
        else {
          this.isAccountadded = true;
        }
      },
    },

    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.sociallist = this.setsocial(platformData);
      },
    },
    item:{
      immediate:true,
      deep:true,
      handler(item){
        console.log(item);
      }
    }
  },
  data() {
    return {
      oauthtoken: "",
      item: [
        {
          username: null,
          platform: null,
          approved_status: 0,
          name:null,
          profile_pic:null
        },
      ],
      validationmessage: {
        username: "username is required",
        platform: "platform is required",
      },
      twitterindex: "",
      pin: "",
      GoogleAuth: "",
      approvestatus: "",
      tooltipmsg: "please select platform",
      approveuserid: "",
      isAccountadded: false,
      pinverify: false,
      submitted: false,
      isPopupOpen: false,
      IsSocialDetailEditable: false,
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      captionclass: "caption",
      black: "dark",
      selectplaceholder: "Select...",
      plainclass: "btnplain",
      classname: "default",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      selectedplatform: null,
      platform: "",
      accounttype: "",
      username: "",
      platform1: "",
      actype1: "",
      username1: "",
      h2txtclass: "h2",
      btninvitecls: "btnprimary",
      genderoption: [
        {
          id: "1",
          label: "Male",
        },
        {
          id: "2",
          label: "Female",
        },
        {
          id: "3",
          label: "Others",
        },
      ],
      sociallist: [],
    };
  },
  mounted() {
    initFbsdk();
    this.injectGapi()
  },
  created() {
    this.getsocialaccount();
    // this.injectGapi()
  },
  methods: {
    ...mapActions("influencerprofile", [
      "facebookprofileregister",
      "getsocialaccount",
      "youtubeprofileregister",
      "instagramprofileregister",
      "addsocialverifiedaccounts",
      "otherprofileregister",
      "gettwitteroauthtoken",
      "verifytwitteraccount",
    ]),
    ...mapActions("commonfunction", ["getuserpercentage"]),
    ...mapActions("influencerprofile", ["fetchsocialinsights"]),

    setpopupdata(platform) {
      // alert(platform)
      this.selectedplatform = platform
      // if(platform == null){
      //   this.tooltipmsg = "please select platform"
      // }
      // else if(platform == "instagram"){
      //   this.tooltipmsg = "instagram platform selected"
      // }
      // else if(platform == "youtube"){
      //   this.tooltipmsg = "youtube platform selected"
      // }
      // else if(platform == "facebook"){
      //   this.tooltipmsg = "facebook<br> platform selected"
      // }
      // else if(platform == "twitter"){
      //   this.tooltipmsg = "twitter platform selected"
      // }
    },

    setsocial(data) {
      let newarray = [];
      data.map((value) => {
        if (value.name == "Twitter") {
          newarray.push({
            id: "twitter",
            label: "Twitter",
          });
        } else if (value.name == "Facebook") {
          newarray.push({
            id: "facebook",
            label: "Facebook",
          });
        } else if (value.name == "Instagram") {
          newarray.push({
            id: "instagram",
            label: "Instagram",
          });
        } else if (value.name == "Youtube") {
          newarray.push({
            id: "youtube",
            label: "Youtube",
          });
        } else {
          newarray.push({
            id: value.name.split(" ").join("-").toLowerCase(),
            label: value.name,
          });
        }
      });
      return newarray;
    },

    verifypin() {
      let index = this.twitterindex;
      console.log(index);
      console.log(this.item);
      console.log(this.item[index]);
      this.pinverify = true;
      if (this.pin != "") {
        this.verifytwitteraccount({
          oauth_token: this.oauthtoken,
          oauth_verifier: this.pin,
          type: "twitter",
          username: this.item[index].username,
        })
          .then((response) => {
            this.isPopupOpen = false;
            // this.item[index].username = response.data.userid;
            this.item[index].approved_status = "1";
            this.item[index].name = (response.data.name)?response.data.name:'';
            this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
            console.log("item:", this.item);
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
            // this.getuserpercentage()
            // this.fetchsocialinsights()
            // this.getsocialaccount()
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },

    popUp() {
      if (this.isPopupOpen == true) {
        this.isPopupOpen = false;
      } else {
        this.isPopupOpen = true;
      }
    },

     getsdetailext() {
      this.getsocialaccount()
      .then((response) => {
        this.item = response.data;
      });
    },

    capitalizeWord(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    capitalizeWordWithDash(string) {
      var result = this.sociallist.filter(function(v,i) {
          return v.id == string
      });
      if(result){
        return result[0].label
      }else{
        var returnStr = '';
        string = string.split('-');
        var strlength = string.length;
        for(var i=0;i<strlength;i++){
          string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
        }
        return string.join(' ');
      }
    },

    // changeusername(index) {
    //   // this.item[index].approved_status = 0;
    //   console.log(index)
    //   alert(index)
    //   // console.log("item:", this.item);
    // },

    addrowclicked() {
      // console.log("itm:", this.item);
      // console.log("add row clicked");
      this.item.push({
          username: null,
          platform: null,
          approved_status: 0,
          name:null,
          profile_pic:null
        });
      // console.log("itm:", this.item);
    },

    removerowclicked(data, index) {
      // alert(index)
      this.item.splice(index, 1);
      // this.keywords.splice(item - 1, 1)
      // this.item.splice(data - 1, 1);
      // console.log(data.id)
      // console.log(this.item)
      // for (let i = 0; i < this.item.length; i++) {
      //   if(this.item[i].id == data.id)
      //   {
      //     this.item.splice(i,1)
      //     // console.log("Matched"+this.item[i].id)
      //   }
      // }
    },

    addsocialaccounts() {
      let vm = this;
      for (let key in this.item) {
        // console.log("key:", this.item[key])
        if (this.item[key].approved_status == null) {
          this.item[key].approved_status = "0";
        }
      }
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        this.addsocialverifiedaccounts({
          social_account: this.item,
        })
          .then((response) => {
            console.log(response);
            this.submitted = false;
            this.IsSocialDetailEditable = false;
            this.getuserpercentage()
            this.fetchsocialinsights()
            this.getsocialaccount()
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
            console.log("checking");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
          this.getsocialaccount()
      }
    },

    socialDetailToggle() {
      if (this.IsSocialDetailEditable == false) {
        this.IsSocialDetailEditable = true;
      } else {
        this.IsSocialDetailEditable = false;
      }
    },

    PopupCenter(pageURL, title, w, h) {
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;
      var targetWin = window.open(
        pageURL,
        title,
        "toolbar=yes, location=no, directories=no, status=yes, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          top +
          ", left=" +
          left
      );
      return targetWin;
    },

    addSocialAccount(data, index) {
      // console.log("data:", data);
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        if (data.platform == "facebook") {
          let vm = this;
          window.FB.login((response) => {
            let authresponse = response.authResponse;
            console.log("fb response", response);
            vm.facebookprofileregister({
              username: data.username,
              authresponse: authresponse,
              type: "facebook",
            })
              .then((response) => {
                console.log(response);
                this.submitted = false;
                this.item[index].username = response.data.userid;
                this.item[index].approved_status = "1";
                this.item[index].name = (response.data.name)?response.data.name:'';
                this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
                // this.getuserpercentage()
                // this.fetchsocialinsights()
                // this.getsocialaccount()
                this.$toast.open({
                  message: response.message,
                  type: "success",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
                console.log("checking");
              })
              .catch((err) => {
                this.$toast.open({
                  message: err.response.data.message,
                  type: "error",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
              });
          },{scope: 'email,read_insights,pages_show_list'});
        } else if (data.platform == "twitter") {
          this.gettwitteroauthtoken()
            .then((response) => {
              this.isPopupOpen = true;
              this.twitterindex = index;
              this.oauthtoken = response.data.oauth_token;
              this.PopupCenter(
                `https://api.twitter.com/oauth/authorize?oauth_token=${response.data.oauth_token}`,
                "",
                400,
                400
              );
              // this.getuserpercentage()
              // this.fetchsocialinsights()
              // this.getsocialaccount()
            })
            .catch((err) => {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "top",
              });
            });
        } else if (data.platform == "youtube") {
          const vm = this
          this.$gAuth
            .getAuthCodeYoutube()
            .then(GoogleUser => {
              let access_token = GoogleUser;
              this.youtubeprofileregister({
                code: access_token,
                channelname: data.username,
                type: "youtube",
              })
              .then((response) => {
                console.log(response);
                this.submitted = false;
                // this.item[index].username = response.data.userid;
                this.item[index].approved_status = "1";
                // console.log('asd',index)
                // console.log('asd2',this.item)
                this.item[index].name = (response.data.name)?response.data.name:'';
                this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
                // this.getuserpercentage()
                // this.fetchsocialinsights()
                // this.getsocialaccount()
                this.$toast.open({
                  message: response.message,
                  type: "success",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
                console.log("checking");
              })
              .catch((err) => {
                this.$toast.open({
                  message: err.response.data.message,
                  type: "error",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
              });
            })
          // Vue.googleAuth()
          //   .signIn( function (googleUser) { 
          //     // console.log('googleUser',googleUser)

          //     let access_token = googleUser;
          //     vm.youtubeprofileregister({
          //       code: access_token,
          //       channelname: data.username,
          //       type: "youtube",
          //     })
          //     .then((response) => {
          //       console.log(response);
          //       vm.submitted = false;
          //       // vm.item[index].username = response.data.userid;
          //       vm.item[index].approved_status = "1";
          //       // console.log('asd',index)
          //       // console.log('asd2',vm.item)
          //       vm.item[index].name = (response.data.name)?response.data.name:'';
          //       vm.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
          //       // vm.getuserpercentage()
          //       // vm.fetchsocialinsights()
          //       // vm.getsocialaccount()
          //       vm.$toast.open({
          //         message: response.message,
          //         type: "success",
          //         duration: 5000,
          //         dismissible: true,
          //         position: "top",
          //       });
          //       console.log("checking");
          //     })
          //     .catch((err) => {
          //       vm.$toast.open({
          //         message: err.response.data.message,
          //         type: "error",
          //         duration: 5000,
          //         dismissible: true,
          //         position: "top",
          //       });
          //     });
          // }, function (error) {
          //   console.log('error',error)
          //   // things to do when sign-in fails
          // })
          // this.GoogleAuth.grantOfflineAccess().then(res=>{
          //   // console.log('this.GoogleAuth',this.GoogleAuth.grantOfflineAccess())
          //   // let authResponse = res.getAuthResponse();
          //   // console.log('res',res)
          //   let access_token = res.code;
          //   this.youtubeprofileregister({
          //     code: access_token,
          //     channelname: data.username,
          //     type: "youtube",
          //   })
          //   .then((response) => {
          //     console.log(response);
          //     this.submitted = false;
          //     // this.item[index].username = response.data.userid;
          //     this.item[index].approved_status = "1";
          //     // console.log('asd',index)
          //     // console.log('asd2',this.item)
          //     this.item[index].name = (response.data.name)?response.data.name:'';
          //     this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
          //     // this.getuserpercentage()
          //     // this.fetchsocialinsights()
          //     // this.getsocialaccount()
          //     this.$toast.open({
          //       message: response.message,
          //       type: "success",
          //       duration: 5000,
          //       dismissible: true,
          //       position: "top",
          //     });
          //     console.log("checking");
          //   })
          //   .catch((err) => {
          //     this.$toast.open({
          //       message: err.response.data.message,
          //       type: "error",
          //       duration: 5000,
          //       dismissible: true,
          //       position: "top",
          //     });
          //   });
          // });
        } else if (data.platform == "instagram") {
          let vm = this;
          window.FB.login((response) => {
            let authresponse = response.authResponse;
            console.log("fb response", response);
            vm.instagramprofileregister({
              username: data.username,
              authresponse: authresponse,
              type: "instagram",
            })
              .then((response) => {
                console.log(response);
                this.submitted = false;
                // this.item[index].username = response.data.userid;
                this.item[index].approved_status = "1";
                this.item[index].name = (response.data.name)?response.data.name:'';
                this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
                // this.getuserpercentage()
                // this.fetchsocialinsights()
                // this.getsocialaccount()
                this.$toast.open({
                  message: response.message,
                  type: "success",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
                console.log("checking");
              })
              .catch((err) => {
                this.$toast.open({
                  message: err.response.data.message,
                  type: "error",
                  duration: 5000,
                  dismissible: true,
                  position: "top",
                });
              });
          }, {scope: 'email,pages_show_list,instagram_basic,instagram_manage_insights'});
        }else{
          // OtherVerify
          this.otherprofileregister({
            username: data.username,
            // authresponse: authresponse,
            type: data.platform,
          })
          .then((response) => {
            console.log(response);
            this.submitted = false;
            // this.item[index].username = response.data.userid;
            this.item[index].approved_status = "1";
            this.item[index].name = (response.data.name)?response.data.name:'';
            this.item[index].profile_pic = (response.data.profile_pic)?response.data.profile_pic:'';
            // this.getuserpercentage()
            // this.fetchsocialinsights()
            // this.getsocialaccount()
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
            console.log("checking");
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
        }
      }
    },

    injectGapi(){
      console.log('injectGapi')
        // this.$gapi.getGapiClient().then((gapi) => {
        //   this.GoogleAuth = gapi.auth2.getAuthInstance();
        //   console.log('gapi',gapi);
        //   console.log(this.GoogleAuth);
        //   // this.GoogleAuth.isSignedIn.listen(this.updateSigninStatus());
        // }).catch((err) => {
        //   console.log('gapi err',err);
        //   // this.$toast.open({
        //   //   message: err.response.data.message,
        //   //   type: "error",
        //   //   duration: 5000,
        //   //   dismissible: true,
        //   //   position: "top",
        //   // });
        // });
    },
    prepareToExit(){
        if (this.IsSocialDetailEditable) {
          if(confirm('Do you want save your social account data?')){
            this.addsocialaccounts();
          }
        }
    }
  },
  // beforeRouteLeave (to, from, next) {
  //   if(confirm('Do you want to proceed?')){
  //     next();
  //   }else{
  //     next();
  //   }
  // }
};
</script>

<style lang="scss" scopped>
.modal-window {
  .modal-window-main {
    width: 30%;
    @media (max-width: 575px) and (min-width: 280px) {
      width: 80%;
    }
  }
}
</style>