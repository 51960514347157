<template>
  <div class="livebrandcampaign">
    <div class="content-header">
      <text-component class="h2">Live Brand Campaigns</text-component>
    </div>
    <div class="content">
      <div class="box margino">
        <div class="box-header">
          <text-component class="subtitle1">Total Live Campaigns</text-component>
          <br />
          <text-component class="h2">{{showTotal()}}</text-component>
        </div>
        <hr class="hr-divider" />
        <div class="box-content">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :columns="columns"
              :rows="rows"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :filter="filter"
              :page="page"
              :per_page="per_page"
              @onrowclick="goToCampaigndetail"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapActions } from "vuex";

export default {
  components: {
    TextComponent,
    DatatableComponent,
    searchBar,
  },
  data() {
    return {
      txtclassname: "default",
      classname: "default prefixicon",
      page: 1,
      per_page: 8,
      type: "text",
      inputPlaceholder: "Search Campaign",
      filter: "",
      to: "",
      selectedRows: [],
      columns: [
        {
          label: "Campaign Name",
          field: "campaign_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Brand's Name",
          field: "user.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        // {
        //     label: "Total Views",
        //     field: "totalview",
        //     align: "left",
        //     headerAlign: "left",
        //     headerClass: "class-in-header second-class",
        //     sortable: false
        // },
        {
          label: "Category",
          //   field: "category",
          interpolate: true,
          representedAs: (row) => {
            return this.getcategoryname(row.influencer_category.split(","));
          },
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
      ],
      rows: [],
      category: [],
    };
  },

  methods: {
    ...mapActions("admincampaignaction", [
      "getbrandlivecampaigns",
      "getcategories",
    ]),

    goToCampaigndetail(row){
      // console.log(row)
      this.$router.push({name: "admincampaigndetail",params: { id: row.id }});
    },

    getcategoryname(id) {
      let newArray = [];
      id.map((data) => {
        let name = this.category.filter((value) => {
          if (value.id == data) {
            return value.id == data;
          }
        });
        if (name[0]) {
          newArray.push(name[0].name);
        }
      });
      return newArray.toString();
    },
    showTotal(){
      let returnis = '00';
      let rowCount = this.rows.length;
      returnis = (rowCount<99)?('0' + rowCount).slice(-2):rowCount;
      return returnis;
    }
  },

  created() {
    this.getbrandlivecampaigns().then((response) => {
      this.$data.rows = response.data;
    });

    this.getcategories().then((response) => {
      this.$data.category = response.data;
      //   console.log("category:", this.category);
    });
  },
};
</script>