<template>
    <div class="user-icon">
        <img v-if="!profile_pic" src="../../../assets/image/usericon.png" alt="user-image">
        <img v-else :src="profile_pic" alt="user-image">
    </div>
</template>

<script>
// import { eventBus } from "../../main";
export default {
    props: ["row"],
    components: {
        
    },
    data() {
        return {
            profile_pic:null
        };
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                if (row.user_profile && row.user_profile.profile_pic) {
                    console.log(row.user_profile.baseUrl+'/'+row.id+'/'+row.user_profile.profile_pic)
                    this.profile_pic = row.user_profile.baseUrl+'/'+row.id+'/'+row.user_profile.profile_pic
                }
            }
        }
    }
};
</script>
