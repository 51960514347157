<template>
  <div class="myprofile websitedetail">
    <div class="box margino">
      <div class="box-content">
        <div class="myprofile-header">
          <text-component
            class="myprofile-header-txt"
            :class="subtitle1class"
          >Website Details</text-component>
          <div v-if="!IsSocialDetailEditable" class="myprofile-header-right">
            <button-component 
              @onBtnClick="socialDetailToggle" 
              :clsname="basicclass">
                <div v-if="item.length <= 0">
                  Add
                </div>
                <div v-else>
                  Edit
                </div>
              </button-component>
          </div>
        </div>
        <div v-if="!IsSocialDetailEditable" class="socialdetail-saved">
          <div v-if="item.length <= 0">
            <p style="text-align: center;">Currently no social accounts are Added</p>
          </div>
            <div>
                <div class="row margino socialdetail-info">
                    <div class="website-detail-txt col-12" v-for="(data, index) in item" :key="index">
                        <div class="row">
                            <div v-if="data.website_link" class="col-12 col-md-6 websitedata">
                                <text-component class="paddingo subtitle2">Website Link</text-component>
                                <text-component class="paddingo datatxt">{{data.website_link}}</text-component>
                            </div>
                            <div v-if="data.category" class="col-12 col-md-6 websitedata">
                                <text-component class="paddingo subtitle2">Category</text-component>
                                <text-component class="paddingo datatxt">{{data.category}}</text-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="IsSocialDetailEditable" class="socialdetail-editable">
          <form @submit.prevent="addSocialAccount">
            <div v-for="(data, index) in item" :key="index">
              <div class="row socialdetail-row">
                <div class="row col-9 socialdetail-box">
                  <input-component
                    required="true"
                    class="txtgroup col-12 col-xl-5 col-lg-4"
                    :helptext="!$v.item.$each[index].website_link.required && submitted ? validationmessage.website_link: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].website_link.required && submitted}"
                    :classname="{'error': !$v.item.$each[index].website_link.required && submitted, 'default': true}"
                    v-model="item[index].website_link">
                    Website Link
                  </input-component>
                  <input-component
                    required="true"
                    class="txtgroup col-12 col-xl-5 col-lg-4"
                    :helptext="!$v.item.$each[index].category.required && submitted ? validationmessage.category: false"
                    :labelclass="{'validationerr': !$v.item.$each[index].category.required && submitted}"
                    :classname="{'error': !$v.item.$each[index].category.required && submitted, 'default': true}"
                    v-model="item[index].category">
                    Category
                  </input-component>

                </div>
                <!-- <div class="col-2 socialdetail-btn">
                </div> -->
                <div class="col-2 socialdetail-image paddingo" 
                  v-if="index < Object.keys(item).length - 1 && item.length != 1">
                  <img
                    @click="removerowclicked(data, index)"
                    src="../../../assets/image/minus.svg"
                    alt="minus-circle"
                    class="minus-img"
                  />
                </div>
                <div class="col-2 socialdetail-image paddingo" 
                  v-if="index >= Object.keys(item).length - 1 && item.length != 1">
                  <img
                    @click="addrowclicked()"
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="plus-img"
                  />
                  <img
                    @click="removerowclicked(data, index)"
                    src="../../../assets/image/minus.svg"
                    alt="minus-circle"
                    class="minus-img"
                  />
                </div>
                <div class="col-2 socialdetail-image paddingo" v-if="item.length == 1">
                  <img
                    @click="addrowclicked()"
                    src="../../../assets/image/plus-circle.png"
                    alt="plus-circle"
                    class="plus-img"
                  />
                </div>
              </div>
            </div>
          </form>
          <hr class="hrline-footer" />
          <div class="width edit-footer">
            <button-component
              class="footer-btn"
              @onBtnClick="addwebsitedetail"
              :clsname="primaryclass"
            >Save</button-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import { mapGetters, mapActions } from "vuex";
import { initFbsdk } from "@/config/facebook_oAuth.js";
import { required } from "vuelidate/lib/validators";
// import GoogleAuth from 'vue-google-oauth2'
// import axios from "axios";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
  },
  validations: {
    item: {
      $each: {
        website_link: { required },
        category: { required },
      },
    },
  },
  computed: {

  },
  watch: {
    
  },
  data() {
    return {
      item: [
        {
          website_link: null,
          category: null,
        },
      ],
      validationmessage: {
        website_link: "website link is required",
        category: "category is required",
      },
      isAccountadded: false,
      submitted: false,
      isPopupOpen: false,
      IsSocialDetailEditable: false,
      subtitle1class: "subtitle1",
      subtitle2class: "subtitle2",
      black: "dark",
      classname: "default",
      basicclass: "btnbasic",
      primaryclass: "btnprimary",
      h2txtclass: "h2",
      sociallist: [],
    };
  },

  created() {
      this.getWebsiteData()
  },
  methods: {

      ...mapActions("publisherdata", ["websitedetail", "getwebsitedetail"]),

        removerowclicked(data, index) {
            this.item.splice(index, 1);
        },

        addrowclicked() {
            this.item.push({
                website_link: null,
                category: null,
            });
        },

        socialDetailToggle() {
            if (this.IsSocialDetailEditable == false) {
            this.IsSocialDetailEditable = true;
            } else {
            this.IsSocialDetailEditable = false;
            }
        },

        addwebsitedetail() {
            console.log(this.item)
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.websitedetail({
                    websiteDetails: this.item,
                }).then(response => {
                    this.getWebsiteData()
                    this.IsSocialDetailEditable = false
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        getWebsiteData() {
            this.getwebsitedetail()
            .then(response=>{
                this.item = response.data
                if(response.data.length) {
                    this.isAccountadded = true
                }
                else{
                    this.isAccountadded = false
                }
            });
        },
    },
};
</script>