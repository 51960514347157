<template>
    <div class="progress-horizontal row margino paddingo">
        <div class="bar">
            <div class="progress-lower margino"></div>
            <div class="progress-upper margino" :style="{width: getWidth + '%' }" id="p-upper"></div>
        </div>
        <div class="percentage-value margino ">
            {{progress}}%
        </div>
    </div>
</template>
<script>
export default {
    props: ['progress'],
    data() {
        return{
            // progress: 50
        }
    },
    computed: {
        getWidth () {
            let width = this.progress;
            return width;
        }
    }
}
</script>