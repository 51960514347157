<template>
    <div>
        <div class="row margino star-rating-detail">
            <rating-component :rating="average_rate"></rating-component>
            <text-component class="subtitle1">{{average_rate}} out of 5</text-component>
        </div>
        <text-component class="mid">{{total_rate}} Total Ratings</text-component>
        <div class="star-wise-rating">
            <div class="row margino">
                <div class="star-number">
                    <text-component>5 star</text-component>    
                </div>
                <progress-component class="progress-component" :progress="rating5"></progress-component>
            </div>
            <div class="row margino">
                <div class="star-number">
                    <text-component>4 star</text-component>    
                </div>
                <progress-component class="progress-component" :progress="rating4"></progress-component>
            </div>
            <div class="row margino">
                <div class="star-number">
                    <text-component>3 star</text-component>    
                </div>
                <progress-component class="progress-component" :progress="rating3"></progress-component>
            </div>
            <div class="row margino">
                <div class="star-number">
                    <text-component>2 star</text-component>    
                </div>
                <progress-component class="progress-component" :progress="rating2"></progress-component>
            </div>
            <div class="row margino">
                <div class="star-number">
                    <text-component>1 star</text-component>    
                </div>
                <progress-component class="progress-component" :progress="rating1"></progress-component>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import RatingComponent from "../../../BasicComponents/rating/rating.vue"
import ProgressComponent from "../../../BasicComponents/progressbar/progressbarhorizontal.vue"
export default {
    components: {
        TextComponent,
        RatingComponent,
        ProgressComponent
    },
    props:["ratingData"],
    data(){
        return{
            average_rate:20,
            total_rate:20,
            rating1:20,
            rating2:20,
            rating3:20,
            rating4:20,
            rating5:20,
        }
    },
    watch:{
        ratingData:{
            immediate:true,
            handler(ratingData){
                this.average_rate = (ratingData.average_rate)?parseFloat(ratingData.average_rate).toFixed(1):0;
                this.total_rate = ratingData.total_rate;
                this.rating1 = (ratingData.rating1s)?((ratingData.rating1s/ratingData.total_rate)*100).toFixed(0):0;
                this.rating2 = (ratingData.rating2s)?((ratingData.rating2s/ratingData.total_rate)*100).toFixed(0):0;
                this.rating3 = (ratingData.rating3s)?((ratingData.rating3s/ratingData.total_rate)*100).toFixed(0):0;
                this.rating4 = (ratingData.rating4s)?((ratingData.rating4s/ratingData.total_rate)*100).toFixed(0):0;
                this.rating5 = (ratingData.rating5s)?((ratingData.rating5s/ratingData.total_rate)*100).toFixed(0):0;
            }
        }
    }
}
</script>