<template>
    <div class="user-icon">
        <img v-if="!profile_pic" src="../../../../assets/image/usericon.png" alt="user-image">
        <img v-else :src="profile_pic" alt="user-image">
    </div>
</template>

<script>
// import { eventBus } from "../../main";
export default {
    props: ["row"],
    components: {
        
    },
    data() {
        return {
            profile_pic:null
        };
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                // console.log(row)
                if (row.image) {
                    this.profile_pic = row.baseUrl+'/'+row.id+'/'+row.image
                }
            }
        }
    }
};
</script>
