<template>
    <div class="platform" :class="row.platform">
        <!-- {{row.post_type}}{{post}} -->
        {{postname}}
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    props: ["row"],
    components: {
        
    },
    data() {
        return {
            post: [],
            postname: null
        };
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                if(row.post_type){
                    for (let i = 0; i < this.post.length; i++) {
                       if(row.post_type == this.post[i].id){
                           this.postname = this.post[i].name
                       } 
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions("brandinfluencerdata", ["getposttype"]),

         getpost() {
            this.getposttype()
            .then((response) => {
                this.post = response.data
            });
        },
    },
    created() {
        this.getpost()
    }
};
</script>
