<template>
    <div>
        <section class="terms-condition">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">{{cms_detail.name}}</h1>
                        
                        <div v-html="cms_detail.description"></div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{},
            cms_field:[],
            cms_image:[],
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail.name,
            meta: [
                { name: 'description', content:  this.cms_detail.meta_desc},
                { name: 'keyword', content:  (this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    created(){
        this.getData();
    },
    mounted(){
        this.getData();
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsTermsOfUse"]),
    },
    watch:{
        cmsDetailsTermsOfUse:{
            immediate:true,
            handler(cmsDetailsTermsOfUse){
                if (cmsDetailsTermsOfUse) {
                    this.baseUrl = cmsDetailsTermsOfUse.baseUrl;
                    this.cms_detail = cmsDetailsTermsOfUse.cms_detail;
                    this.cms_field = cmsDetailsTermsOfUse.cms_field;
                    this.cms_image = cmsDetailsTermsOfUse.cms_image;
                }
            }
        }
    },
    methods:{
        ...mapActions("frontcms",["getCmsData"]),
        getData(){
            this.getCmsData({slug:'terms-of-use'});
        }
    }
}
</script>