<template>
  <div class="progress boxmargin">
    <div class="box margino">
      <div class="box-content">
        <div class="row margino paddingo">
          <div class="progress-left row col-xl-7">
            <div class="progress-left-left col-sm-5 paddingo col-12">
              <text-component :class="subtitle1txtclass">Profile Completeness</text-component>
              <c-progress-bar :progress="progress"></c-progress-bar>
            </div>
            <div class="progress-left-right col-sm-7">
              <text-component :class="subtitle1txtclass" v-if="progress != 100">Tips:</text-component>
              <ul class="profile-tips">
                <li v-for="(tip, index) in tips" :key="index">{{tip.tip}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CProgressBar from "../../../BasicComponents/progressbar/progressbar.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CProgressBar,
    TextComponent
  },
  computed: {
    ...mapGetters("commonfunction", ["percentData"])
  },
  watch: {
    percentData: {
      immediate: true,
      handler(percentData) {
        this.progress = percentData.total_per;
        this.$data.tips = this.gettips(percentData.tips);
      }
    }
  },
  data() {
    return {
      subtitle1txtclass: "subtitle1",
      progress: "",
      tips: [],
      interests: [
        { interest: "Arts" },
        { interest: "Fitness & Health" },
        { interest: "Active Lifestyle" },
        { interest: "Business & Startups" },
        { interest: "Home & Garden" },
        { interest: "Gaming" },
        { interest: "Education" },
        { interest: "Finance" }
      ]
    };
  },
  created() {
    this.getuserpercentage();
  },
  methods: {
    ...mapActions("commonfunction", ["getuserpercentage"]),

    gettips(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          tip: value
        })
      })
      return newarray.slice(0,3);
    }
  }
};
</script>