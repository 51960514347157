<template>
    <div class="menumobile" id="mobilemenu">
    <div class="searchvendor-content-left">
      <div class="menu-inner">
        <div class="main-logo">
          <img src="../assets/image/main-logo.png" alt="VidUnit-logo"/>
        </div>
        <navigation-component :navdata="navdata"></navigation-component>
      </div>
    </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },
  created() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    // console.log(auth)
    this.role = auth.role_id;
    this.sub_role = auth.sub_role_id;
    this.permissions = auth.permissions;
    console.log(auth.permissions);
    this.getPermissionCheck();
  },

  data() {
    return {
      // navdata: [
      //   {
      //     id: "56",
      //     item: "Dashboard",
      //     imgpath: "dashboard.svg",
      //     name: "admindashboard"
      //   },
      //   {
      //     id: "41",
      //     item: "Admin Data",
      //     imgpath: "dashboard.svg",
      //     child: [
      //       { 
      //         id: "43", 
      //         item: "Support", 
      //         name: "adminchat" 
      //       },
      //       { 
      //         id: "44", 
      //         item: "Influencers", 
      //         name: "admininfluencer" 
      //       },
      //       { 
      //         id: "45", 
      //         item: "Live Brand Campaigns", 
      //         name: "Adminlivecampaign" 
      //       },
      //     ]
      //   },
      //   {
      //     id: "46",
      //     item: "Master Data",
      //     imgpath: "campaign.svg",
      //     child: [
      //       { 
      //         id: "47", 
      //         item: "Manage Rights", 
      //         name: "" 
      //       },
      //       { 
      //         id: "48", 
      //         item: "Manage Roles", 
      //         name: "" 
      //       },
      //       { 
      //         id: "49", 
      //         item: "Manage Users", 
      //         name: "" 
      //       },
      //       { 
      //         id: "50", 
      //         item: "Manage Genres", 
      //         name: "adminmanagegenres" 
      //       },
      //       { 
      //         id: "52", 
      //         item: "Manage Categories", 
      //         name: "adminmanagecategory" 
      //       },
      //     ]
      //   },
      //   {
      //     id: "53",
      //     item: "Agency Influencers",
      //     imgpath: "sidebar_influencer_agency.svg",
      //     name: "adminagencydata"
      //   },
      //   {
      //     id: "54",
      //     item: "Brands",
      //     imgpath: "sidebar_brand.svg",
      //     name: "adminbranddata"
      //   },
      //   {
      //     id: "55",
      //     item: "Concents",
      //     imgpath: "sidebar_concents.svg",
      //     name: "adminconcents"
      //   },
      //   {
      //     id: "59",
      //     item: "Campaigns",
      //     imgpath: "sidebar_campaign.svg",
      //     name: "admincampaigns"
      //   },
      //   {
      //     id: "57",
      //     item: "Rating",
      //     imgpath: "sidebar_rating.svg",
      //     name: "adminrating"
      //   },
      //   {
      //     id: "58",
      //     item: "Publishers",
      //     imgpath: "sidebar_publisher.svg",
      //     name: "adinpublisherdata"
      //   },
      //   {
      //     id: "591",
      //     item: "Manage Payments",
      //     imgpath: "invoice.svg",
      //     name: "admininvoice"
      //   },
      // ]
      navdata: [
        {
          id: "516",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "admindashboard",
        },
        {
          id: "51",
          item: "Admin Data",
          imgpath: "admindata.png",
          child: [
            {
              id: "53",
              item: "Support",
              name: "adminchat",
              permKey: "adminchat_list",
            },
            {
              id: "54",
              item: "Influencers",
              name: "admininfluencer",
              permKey: "admin_influencer_list",
            },
            {
              id: "55",
              item: "Live Brand Campaigns",
              name: "Adminlivecampaign",
              permKey: "admin_live_campaign_list",
            },
          ],
        },
        {
          id: "56",
          item: "Master Data",
          imgpath: "sidebar_master_data.svg",
          child: [
            {
              id: "57",
              item: "Manage Permissions",
              name: "Permissions",
              permKey: "permissions_list",
            },
            {
              id: "58",
              item: "Manage Roles",
              name: "Roles",
              permKey: "roles_list",
            },
            {
              id: "59",
              item: "Manage Users",
              name: "User",
              permKey: "user_list",
            },
            // {
            //   id: "510",
            //   item: "Manage Genres",
            //   name: "adminmanagegenres",
            //   permKey: "admin_manage_genres_list",
            // },
            {
              id: "512",
              item: "Manage Categories",
              name: "adminmanagecategory",
              permKey: "admin_manage_category_list",
            },
          ],
        },
        {
          id: "513",
          item: "Influencers",
          imgpath: "sidebar_influencer.svg",
          // name: "admininfluencerdata",
          child: [
            {
              id: "5131",
              item: "Influencers",
              name: "admininfluencerdata",
              permKey: "admin_influencer_data_list",
            },
            {
              id: "5132",
              item: "Influencer Categories",
              name: "admininfluencercategory",
              permKey: "admin_influencer_category_list",
            },
          ],
        },
        {
          id: "514",
          item: "Agency Influencers",
          imgpath: "sidebar_influencer_agency.svg",
          name: "adminagencydata",
          permKey: "admin_agency_data_list",
        },
        {
          id: "515",
          item: "Brands",
          imgpath: "sidebar_brand.svg",
          name: "adminbranddata",
          permKey: "admin_brand_data_list",
        },
        {
          id: "517",
          item: "Concents",
          imgpath: "sidebar_concents.svg",
          name: "adminconcents",
          permKey: "admin_concents_list",
        },
        {
          id: "518",
          item: "Campaigns",
          imgpath: "sidebar_campaign.svg",
          name: "admincampaigns",
          permKey: "admin_campaigns_list",
        },
        {
          id: "519",
          item: "Rating",
          imgpath: "sidebar_rating.svg",
          name: "adminrating",
          permKey: "admin_rating_list",
        },
        {
          id: "520",
          item: "Publishers",
          imgpath: "sidebar_publisher.svg",
          permKey: "adin_publisher_data_list",
          child: [
            {
              id: "5201",
              item: "Publisher List",
              name: "adminpublisherdata",
            },
            {
              id: "5202",
              item: "Article List",
              name: "adminicontentlist",
            },
            {
              id: "5203",
              item: "Website Traffic",
              name: "adminwebsitelist",
            },
            {
              id: "5204",
              item: "Article Request",
              name: "admincontentrequestlist",
            },
          ],
        },
        {
          id: "521",
          item: "Manage Payments",
          imgpath: "invoice.svg",
          name: "admininvoice"
        },
        {
          id: "522",
          item: "Front Data",
          imgpath: "sidebar_master_data.svg",
          child: [
            {
              id: "5221",
              item: "Website Setting List",
              name: "adminwebsitesetting",
            },
            {
              id: "5222",
              item: "Testimonial List",
              name: "admintestimoniallist",
            },
            {
              id: "5223",
              item: "Celebrity Client List",
              name: "admincelebrityclient",
            },
            {
              id: "5224",
              item: "CMS List",
              name: "admincmslist",
            },
            {
              id: "5225",
              item: "Blog List",
              name: "adminbloglist",
            },
          ],
        },
      ],
    };
  },

  methods: {
    getPermissionCheck: function () {
      let navdata = [];
      if (this.role == 3) {
        navdata = this.navdata2;
      } else if (this.role == 2) {
        navdata = this.navdata;
      }
      for (let nav in navdata) {
        // console.log('nav',navdata[nav].item)
        if (navdata[nav].permKey) {
          navdata[nav].hasPermission = this.checkHasPermission(
            navdata[nav].permKey
          );
        } else {
          navdata[nav].hasPermission = true;
        }
        if (navdata[nav].child) {
          let childis = navdata[nav].child;
          for (let child in childis) {
            navdata[nav].child[child].permKey;
            if (navdata[nav].child[child].permKey) {
              navdata[nav].child[child].hasPermission = this.checkHasPermission(
                navdata[nav].child[child].permKey
              );
            } else {
              navdata[nav].child[child].hasPermission = true;
            }
          }
        }
      }
      if (this.role == 3) {
        this.navdata2 = navdata;
      } else if (this.role == 2) {
        this.navdata = navdata;
      }
    },
    checkHasPermission: function (item) {
      let returnis = false;
      let permissions = this.permissions;
      if (permissions.indexOf(item) != -1 || this.sub_role == 0) {
        returnis = true;
      }
      return returnis;
    },
  },
};
</script>