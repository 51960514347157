<template>
    <div class="box margino">
    <div class="box-content">
        <div class="row margino bankdetail">
            <text-component class="bankdetail-header col-12 paddingo" :class="subtitle1class">Bank Details</text-component>
            <div v-if="!bankData" class="col-12">
                <div class="subtitle2 tab-no-data-class">Currently no bank details are Added</div>
            </div>
            <div v-else class="row margino">
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-8">
                    <div class="row p-info">
                        <text-component :class="subtitle2class">Full Account Name</text-component>
                        <text-component :class="'black'">{{bankData.account_name}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-8">
                    <div class="row p-info">
                        <text-component :class="subtitle2class">Account Number</text-component>
                        <text-component :class="'black'">{{hideaccnumber(bankData.account_number)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-8">
                    <div class="row p-info">
                        <text-component :class="subtitle2class">Bank Name</text-component>
                        <text-component :class="'black'">{{bankData.bank_name}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-8">
                    <div class="row p-info">
                        <text-component :class="subtitle2class">IFSC Code</text-component>
                        <text-component :class="'black'">{{bankData.ifsc_code}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-8">
                    <div class="row p-info">
                        <text-component :class="subtitle2class">Contact Number</text-component>
                        <text-component :class="'black'">{{bankData.contact}}</text-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapActions } from "vuex";
export default {
    components: {
        TextComponent,
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            bankData:null
        };
    },
    created:function () {
        this.getData();
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getbankdetail"]),
        getData(){
            this.getbankdetail({id:this.$route.params.id})
            .then(response=>{
                console.log(response.data)
                this.bankData = response.data
            });
        },
        hideaccnumber(account_number) {
            if (account_number) {
                var trailingCharsIntactCount = 4;
                let str = account_number;
                str = new Array(str.length - trailingCharsIntactCount + 1).join("x") +
                    str.slice(-trailingCharsIntactCount);
                return str;
            }
        },
    }
}
</script>