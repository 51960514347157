<template>
    <div>
        <badge :badgeclass="BadgeClass">{{BadgeText}}</badge>
    </div>
</template>

<script>
import Badge from '../../../BasicComponents/Badge/Badge';
export default {
    components: {
        Badge,
    },
    props: ['row'],
    watch:{
        row:{
            immediate:true,
            handler(row){
                console.log(row)
                if (row.admin_status==1) {
                    this.BadgeText="Approved";
                    this.BadgeClass="Success-Badge";
                }else if (row.admin_status==2) {
                    this.BadgeText="Rejected";
                    this.BadgeClass="Warning-Badge";
                }else{
                    this.BadgeText="Pending";
                    this.BadgeClass="Default-Badge";
                }
            }
        }
    },
    data() {
        return {
            BadgeText:"Pending",
            BadgeClass:"Default-Badge",
        }
    },
}
</script>