<template>
  <div>
    <div class="dtable">
      <!-- <div class="actions-div" v-if="selectedRows.length">
        <intermediate-checkbox class="action-checkbox" @onCbxClick="onCbClick" :cboxindeterminatecls="cboxindeterminatecls">{{selectedRows.length}} selected</intermediate-checkbox>
        <more-actions class="action-dropdown-display" :btnid="actionid" :clsname="dropBtn" :popoverList="actionList">{{actionText}}</more-actions>
      </div> -->
      <datatable :columns="columns" :data="rows" :filter="filter" :per-page="per_page">
        <template slot-scope="{ row, columns }">
                <tr @dblclick="selectrow(row)" style="cursor:pointer">
                    <template>
                        <datatable-cell v-for="(column, j) in columns" :key="j" :column="column" :row="row"></datatable-cell>
                    </template>
                </tr>
        </template>
      </datatable>
      <div v-if="!totalLength" class="no_data_msg">
        {{(no_data_msg)?no_data_msg:'Currently, No rows available.'}}
      </div>
      <div style="display:inline-block; float:right">
        <div v-if="rows.length > 0" style="display:inline-flex; vertical-align: -18px;" :class="'subtitle2'">
            Page
        </div>
        <datatable-pager style="display:inline-flex" :id="'display-pager'"  v-model="page" type="abbreviated"></datatable-pager>
      </div>
      <!-- <button @click="loadmore">Load More</button> -->
    </div>
    <!-- {{selectedRows.length}} -->
    
    <!-- <strong>Selected Users:</strong>
    <span
      v-for="(row, i) in selected_rows"
      :key="row.id"
    >{{ row.id + (i == selected_rows.length -1 ? '' : ',') }}</span> -->
  </div>
</template>

<script>
// import searchBar from "../Input/Input.vue";
// import sortButton from "../Dropdown/sortableDropdown.vue";
// import statusDropdown from "../Dropdown/checkboxDropdown.vue";
import moreActions from "../Dropdown/dropdown.vue";
import {eventBus} from '../../main';
import intermediateCheckbox from "../Checkbox/indeterminatecheckbox.vue";
export default {
  name: "dtable",
  props: [
    "row",
    "rows",
    "columns",
    "clsname",
    // "btnText",
    // "type",
    // "inputPlaceholder",
    // "classname",
    "dropBtn",
    "popoverList",
    "dropdownText",
    "selectedRows",
    "actionList",
    "actionText",
    "cboxindeterminatecls",
    "statusClear",
    "clearClass",
    "sortList",
    "statusid",
    "actionid",
    "filter",
    "page",
    "per_page",
    'no_data_msg'
  ],
  components: {
    // sortButton,
    // searchBar,
    // statusDropdown,
    // moreActions,
    // intermediateCheckbox
  },
  watch:{
    rows:{
      immediate:true,
      handler(rows){
        this.totalLength = rows.length;
      }
    }
  },
  created(){
    // this.filterColumnData = this.columns.filter(x=>(x.show==undefined || x.show))
  },

  methods:{

    onCbClick(e) {
      let cbstatus=e.toElement.checked;
      console.log(cbstatus)
      var mastercbox = document.getElementsByClassName("data-checkbox");
      console.log(mastercbox)
      for (let i = 0; i < mastercbox.length; i++) {
        mastercbox[i].checked= false
      }
      var elems = document.getElementsByTagName("tr");
        // console.log(elems);
        for (let j = 0; j < elems.length; j++) {
          if (j >= 1) {
            elems[j].style.backgroundColor = "#FFFFFF";
          }
        }
      // let thead = document.getElementsByTagName("thead");
      //   for (let k = 0; k < thead.length; k++) {
      //     thead[k].style.visibility = "visible";
      //   }
      // let actiondiv = document.getElementsByClassName("actions-div");
      //   for (let p = 0; p < actiondiv.length; p++) {
      //     actiondiv[p].style.visibility = "hidden";
      //   }
        this.selectedRows.length = 0
      // if(cbstatus == true)
      // {
      //   alert("t")
      // }
      // else
      // {
      //   alert("f")
      // }
    },

    selectrow: function (row) {
        // eventBus.$emit("onrowclick", row);
        this.$emit('onrowclick', row)
    },

    // selectRow: function(row) {
    //   this.$emit('onrowenter',row);
    // },
    // leaveRow: function(row) {
    //   this.$emit('onrowleave',row);
    // },
    // loadmore: function (value) {
    //   this.$emit('loadmoreclick',value)
    // }
  },
  data() {
    return {
      totalLength:0,
      widthval: '100px',
      filterColumnData:{}
      // per_page: "",
      // filter : "",
    }
  },

};
</script>