<template>
    <div class="action-btns">
        <button-component v-if="row.status == null || row.status == '0' || row.status == '3' || row.status == '4'" @onBtnClick="onApprove(row)" :clsname="'btnprimary'">Approve</button-component>
        <button-component v-if="row.status == null || row.status == '0' || row.status == '3' || row.status == '4'" @onBtnClick="onReject(row)" :clsname="'btndestructive'">Reject</button-component>
        <button-component v-if="row.status == null || row.status == '0' || row.status == '3' || row.status == '4'" @onBtnClick="onImprovement(row)" :clsname="'btnbasic improve'">Need Imporvement</button-component>
        <button-component @onBtnClick="onView(row)" :clsname="'btnplain'">View</button-component>
        <button-component v-if="row.status == '1' && articletrafficdatamsg != 'Website Traffic Not Added.'" @onBtnClick="onTraffic(row)" :clsname="'btnplain'">Website Traffic</button-component>
    </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    props: ["row"],
    components: {
        ButtonComponent
    },
    data() {
        return {
            articletrafficdatamsg: null
        }
    },
    watch:{
        row:{
            immediate:true,
            handler(row){
                this.articletraffic({id: row.id})
                .then(response => {
                    this.articletrafficdatamsg = response.message
                    
                })
            }
        }
    },
    methods: {
        ...mapActions("publisherdata", ["approveorrejectcontent", "requestedarticlestatus", "articletraffic"]),

        onTraffic(row){
            this.$root.$emit('trafficClick', row)
        },

        onImprovement(row){
            this.$root.$emit('onimprovement', row)
            // let vm = this;
            // this.requestedarticlestatus({
            //     id: row.id,
            //     status: "3"
            // })
            //     .then(response => {
            //     this.$root.$emit('adminContentDeleteClick', row)
            //     this.$toast.open({
            //         message: response.message,
            //         type: "success",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            //     })
            //     .catch(err => {
            //     this.$toast.open({
            //         message: err.response.data.message,
            //         type: "error",
            //         duration: 5000,
            //         dismissible: true,
            //         position: "top"
            //     });
            // });
        },

        onApprove(row) {
            console.log(row)
            this.approveorrejectcontent({
                id: row.id,
                status: "1",
            }).then(response => {
                this.$root.$emit('adminContentDeleteClick', row)
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        },
        onReject(row) {
            console.log(row)
            this.approveorrejectcontent({
                id: row.id,
                status: "2",
            }).then(response => {
                this.$root.$emit('adminContentDeleteClick', row)
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        },

        onView(row){
            
            this.$root.$emit('adminContentViewClick', row)
        }
    }
}
</script>