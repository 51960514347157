<template>
    <div class="box margino box-first">
        <div class="box-content">
            <div class="row margino">
                <img v-if="profileData.profile_pic" :src="profileData.base_url + '/' + profileData.profile_pic" class="usericon" alt="user-image"/>
                <img v-else src="../../../../assets/image/usericon.png" class="usericon" alt="user-image"/>
                <text-component class="h4 username">{{profileData.full_name}}</text-component>
            </div>
            <div class="row margino personaldetail">
                <text-component class="personaldetail-header col-12 paddingo" :class="subtitle1class">Personal Details</text-component>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Full Name</text-component>
                    <text-component :class="'black'">{{profileData.full_name}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4" v-if="profileData.gender">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Gender</text-component>
                    <text-component :class="'black'">{{capitalizeFirstLetter(profileData.gender)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Date of Birth</text-component>
                    <text-component :class="'black'">{{formatDateGlobal(profileData.dob)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Email Address</text-component>
                    <text-component :class="'black'">{{profileData.email}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Contact Number</text-component>
                    <text-component :class="'black'">{{profileData.prefix}} {{profileData.phone}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">Country</text-component>
                    <text-component :class="'black'">{{getcountryname(profileData.country)}}</text-component>
                    </div>
                </div>
                <div class="profile-detail-txt col-12 col-lg-6 col-xl-4">
                    <div class="row p-info">
                    <text-component :class="subtitle2class">City</text-component>
                    <text-component :class="'black'">{{getcityname(profileData.city)}}</text-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";

export default {
    components: {
        TextComponent,
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            profileData:[],
            country:[],
            city:[]
        };
    },
    created: function() {
        this.getData();
    },
    computed: {
        // ...mapGetters("influencerprofile", ["profileData"]),
        ...mapGetters("commonfunction", ["countryData", "cityData"])
    },
    watch: {
        countryData: {
            immediate: true,
            handler(countryData) {
                this.$data.country = countryData;
                // console.log("country data"+ countryData)
            }
        },
        cityData: {
            immediate: true,
            handler(cityData) {
                this.$data.city = cityData;
                // console.log("city data"+ cityData)
            }
        }
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getuserprofile"]),
        ...mapActions("commonfunction", ["getcity"]),
        getData(){
            this.getuserprofile({id:this.$route.params.id})
            .then(response=>{
                this.profileData = response.data
                this.getCityData(response.data.country)
            });
        },

        getCityData(id){
            this.getcity({id:id})
        },
        
        getcountryname(data) {
            let countryname = this.country.filter(value => {
                return value.id == data;
            });
            if (countryname.length) {
                return countryname[0].name;
            } else {
                return "";
            }
        },

        getcityname(data) {
            let cityname = this.city.filter(value => {
                return value.id == data;
            });
            if (cityname.length) {
                return cityname[0].name;
            } else {
                return "";
            }
        },
    }
}
</script>