<template>
    <div class="content-list website-list">
        <div class="content-header">
            <span class="h2">Website Traffic</span>
            <div class="page-header-right">
                <button-component @onBtnClick="createContent" :clsname="btninvitecls">Create</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import StatusBadge from "./websitetrafficbadge.vue";
import EditBtn from "./websitetrafficediticon.vue";
import DeleteBtn from "./websitetrafficdelete.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        DatatableComponent,
        searchBar,
        ButtonComponent
    },
    data() {
        return {
            type: "text",
            inputPlaceholder: "Search Content Name",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            columns: [
                {
                    label: "Website Name",
                    field: "web_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Article Name",
                    field: "article.article_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Website URL",
                    field: "url",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Status", headerAlign: "left", align: "right", component: StatusBadge },
                { label: "", headerAlign: "left", align: "right", component: EditBtn },
                { label: "Action", headerAlign: "left", align: "left", component: DeleteBtn },
            ],
            rows: [
                // {
                //     website_name: 'Google',
                //     url: 'https://www.google.com/',
                //     status: '1'
                // },
                // {
                //     website_name: 'Facebook',
                //     url: 'https://www.facebook.com/',
                //     status: '0'
                // },
            ],
        };
    },
    methods: {
        ...mapActions("publisherdata", ["getwebsitelist"]),

        getwebsitelistdata() {
            this.getwebsitelist()
            .then(response=>{
                this.rows = response.data
            });
        },

        createContent() {
            this.$router.push({ name: "publisherwebsitetraffic"});
        }
    },
    created() {
        this.getwebsitelistdata()
        this.$root.$on("websiteDeleteClick", () => {
            this.getwebsitelistdata()
        });
    }
}
</script>