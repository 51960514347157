<template>
    <div class="menudesktop">
        <div class="searchvendor-content-left smallMenu" id="desktopmenu">
          <div class="menu-inner">
            <div class="main-logo" id="mlogo">
              <img src="../assets/image/main-logo.png" alt="VidUnit-logo" class="vidunitlogo"/>
            </div>
            <navigation-component :navdata="navdata" v-if="userinfo.parent_id == null"></navigation-component>
            <navigation-component :navdata="navdata1" v-else></navigation-component>
          </div>
        </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
import { mapActions } from "vuex";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      userinfo: {},
      navdata: [
        {
          id: "4",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "UserDashboard"
        },
        {
          id: "5",
          item: "Campaigns",
          imgpath: "campaign.svg",
          child: [
            { 
              id: "6", 
              item: "Campaign Calander", 
              name: "CampaignCalendarPage" 
            },
            { 
              id: "7", 
              item: "Campaign Invites", 
              name: "CampaignInvitePage" 
            },
            { 
              id: "8", 
              item: "Campaign List", 
              name: "CampaignListPage"
            }
          ]
        },
        {
          id: "9",
          item: "My Consent",
          imgpath: "consent.svg",
          name: "MyConsentPage"
        },
        {
          id: "10",
          item: "Invoice & Payments",
          imgpath: "invoice.svg",
          name: "PaymentInvoicePage"
        },
        // {
        //   id: "11",
        //   item: "Logout",
        //   imgpath: "logout.svg",
        // },
        {
          id: "12",
          item: "Support",
          imgpath: "chat.svg",
          name: "influencersupport"
        },
        {
          id: "13",
          item: "My Ratings",
          imgpath: "sidebar_rating.svg",
          name: "InfluencerRating"
        },
      ],

      navdata1: [
        {
          id: "4",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "UserDashboard"
        },
        {
          id: "5",
          item: "Campaigns",
          imgpath: "campaign.svg",
          child: [
            { 
              id: "6", 
              item: "Campaign Calander", 
              name: "CampaignCalendarPage" 
            },
            // { 
            //   id: "7", 
            //   item: "Campaign Invites", 
            //   name: "CampaignInvitePage" 
            // },
            { 
              id: "8", 
              item: "Campaign List", 
              name: "CampaignListPage"
            }
          ]
        },
        {
          id: "9",
          item: "My Consent",
          imgpath: "consent.svg",
          name: "MyConsentPage"
        },
        // {
        //   id: "10",
        //   item: "Invoice & Payments",
        //   imgpath: "invoice.svg",
        //   name: "PaymentInvoicePage"
        // },
        // {
        //   id: "11",
        //   item: "Logout",
        //   imgpath: "logout.svg",
        // },
        {
          id: "12",
          item: "Support",
          imgpath: "chat.svg",
          name: "influencersupport"
        },
        {
          id: "13",
          item: "My Ratings",
          imgpath: "sidebar_rating.svg",
          name: "InfluencerRating"
        },        
      ]
    };
  },

  methods: {
    ...mapActions("user", ["signOut"]),
  },

  created() {
    let auth = JSON.parse(localStorage.getItem("auth"));
    this.userinfo = auth;
    console.log("info:", this.userinfo)
  }
};
</script>
