<template>
    <div>
        <section class="contact-us">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-7" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img src="../../../assets/image/front/Getintouchimg.png">
                    </div>
                    <div class="col-sm-12 col-md-5" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <h2>Get in touch</h2>
                        <div v-html="cms_detail.description"></div>
                        <!-- <p>Mauris et sapien nec justo ornare tristique non vel orci etiam in ultrices.</p> -->
                        <form>
                            <input type="" placeholder="Your Name*" name="">
                            <input type="" class="mb24" placeholder="Your Email*" name="">
                            <textarea placeholder="Your Message"></textarea>
                            <input type="submit" value="Submit" name="">
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
export default {
    components: {
        // carousel
    },
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:'Contact Us',
                meta_title:null,
                meta_keyword:null,
                meta_desc:'Contact Us',
            },
            cms_field:[],
            cms_image:[],
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail?this.cms_detail.meta_title:'',
            meta: [
                { name: 'description', content:  (this.cms_detail)?this.cms_detail.meta_desc:''},
                { name: 'keyword', content:  (this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    created(){
        this.getData();
    },
    mounted(){
        this.getData();
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsContactUs"]),
    },
    watch:{
        cmsDetailsContactUs:{
            immediate:true,
            handler(cmsDetailsContactUs){
                if (cmsDetailsContactUs) {
                    this.baseUrl = cmsDetailsContactUs.baseUrl;
                    this.cms_detail = cmsDetailsContactUs.cms_detail;
                    this.cms_field = cmsDetailsContactUs.cms_field;
                    this.cms_image = cmsDetailsContactUs.cms_image;
                }
            }
        }
    },
    methods:{
        ...mapActions("frontcms",["getCmsData"]),
        getData(){
            this.getCmsData({slug:'contact-us'});
        }
    }
}
</script>