<template>
    <!-- <div class="date-time-picker">
        <datetime
            :input-id="inputid"
            :input-class="classname">
        </datetime>
    </div> -->
    <div class="date-time-picker">
        <div class="datetimepicker-txt">
            <text-component>
                <slot/>
                <sup v-if="required">*</sup>
            </text-component>
        </div>
        <div class="datetimepicker-main">
            <datetime
                :input-id="inputid"
                :input-class="classname"
                v-model="selecteddate">
            </datetime>
        </div>
        <div v-if="helptext" class="error-helper-msg">
            <label :class="labelclass">{{helptext}}</label>
        </div>
    </div>
</template>
<script>
import TextComponent from "../Text/Text.vue";
export default {
    components: {
        TextComponent
    },
    props: ["value", "classname","inputid","required","helptext","labelclass"],
    computed: {
        selecteddate: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
}
</script>