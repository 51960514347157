
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let pendingData = [];
    let approvedData = [];
    let rejectedData = [];
    let topInfluencers = [];
    let topEarning = [];
    return {
        currentUser: {
            id: null,
            name: null,
            email: null,
            mobile: null,
            password: null
        },
        pendingData: pendingData,
        approvedData: approvedData,
        rejectedData: rejectedData,
        topInfluencers: topInfluencers,
        topEarning: topEarning
    }

}

const getters = {
    currentUser: state => state.currentUser,
    pendingData: state => state.pendingData,
    approvedData: state => state.approvedData,
    rejectedData: state => state.rejectedData,
    topInfluencers: state => state.topInfluencers,
    topEarning: state => state.topEarning
};

const actions = {
  clearAll: ({ commit }) => {
    commit("setPendingData", []);
    commit("setApprovedData", []);
    commit("setRejectedData", []);
    commit("setTopInfluencers", []);
    commit("setTopEarning", []);
  },

  fetchData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/me")
        .then((response) => {
          console.log(response);
          if (typeof response !== "undefined" && response.data) {
            console.log("setting current user", response.data.data);
            commit("setCurrentUser", response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          console.log("user.js:106 my error");
          console.log(error);

          commit("setIsAuthenticated", false);
          commit("setCurrentUser", null);
          commit("setCurrentCoa", null);

          window.localStorage.setItem("auth", null);
          reject(error);
        });
    });
  },

  getinfluencerpending: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer/0")
        .then((response) => {
          commit("setPendingData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinfluencerapproved: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer/1")
        .then((response) => {
          commit("setApprovedData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinfluencerrejected: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/influencer/2")
        .then((response) => {
          commit("setRejectedData", response.data.data);
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  approveorrejectinfluencer: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/influencer-status", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinfluencercampaignbydate: ({ commit }, data) => {
    let id = data.id;
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/campaign-by-date/" + id)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getTopInfluencers: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/top-influencer")
        .then((response) => {
          commit("setTopInfluencers", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getTopEarning: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/top-earning-influencer")
        .then((response) => {
          commit("setTopEarning", response.data.data);
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  getinfluencercampaindata: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/influencer-campaign-rating", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },

  activeinactiveinfluencer: ({ commit }, data) => {
    commit("commonfunction/setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/user-admin-status", {
          ...data,
          ...AUTH_PARAMS,
        })
        .then((response) => {
          commit("commonfunction/setLoading", false, { root: true });
          console.log(response.data);

          // setupAxiosDefaults(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit("commonfunction/setLoading", false, { root: true });
          if (error.response && error.response.code === 401) {
            return reject(error.response.message);
          }
          reject(error);
        });
    });
  },
};

const mutations = {
    setPendingData: (state, value) => {
        state.pendingData = value;
    },
    setApprovedData: (state, value) => {
        state.approvedData = value;
    },
    setRejectedData: (state, value) => {
        state.rejectedData = value;
    },
    setTopInfluencers: (state, value) => {
        state.topInfluencers = value;
    },
    setTopEarning: (state, value) => {
        state.topEarning = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};