<template>
    <div class="branddetail-rating">
        <div class="box margino">
            <div class="box-header">
                <text-component class="subtitle1">Rating</text-component>
            </div>
            <div class="box-content">
                <div class="row table-row">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter">
                                </search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :actionid="actionid"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page">
                        </datatable-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import Rating from "./rating.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        DatatableComponent,
        searchBar,
        TextComponent
    },
    data() {
        return {
            ratingData: [],
            activetab: "All Registration",
            maxLimit: 9,
            h2txtclass: 'h2',
            isHiddenbar: false,
            isChat: false,
            btnprimary: 'btnprimary',
            subtitle2class: 'subtitle2',
            midclass: 'mid',
            txtclassname: 'default',
            actionid: "moreactionbtn",
            classname: "default prefixicon",
            page: 1,
            per_page: 8,
            type: "text",
            inputPlaceholder: "Search Agency",
            filter: "",
            to: '',
            selectedRows: [],
            columns: [
                {
                    label: "Influencer Name",
                    field: "user_profile.full_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Campaign Name",
                    field: "campaigns.campaign_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Rating",headerAlign: "left", align: "left", component: (Rating)},
            ],
            rows: [],
        }
    },
    methods: {
        ...mapActions("adminbrandaction", ["getbrandrating"]),

        getData() {
        this.getbrandrating({ id: this.$route.params.id })
            .then(response => {
            console.log(response);
            this.rows = response.data;
            console.log(this.ratingData)
        })
        .catch(err => {
            console.log("error msg:" + err);
        });
        }
    },
    created() {
        this.getData();
    },
}
</script>