<template>
    <div class="menudesktop">
        <div class="searchvendor-content-left smallMenu" id="desktopmenu">
          <div class="menu-inner">
            <div class="main-logo" id="mlogo">
              <img src="../assets/image/main-logo.svg" alt="VidUnit-logo" class="vidunitlogo"/>
            </div>
            <navigation-component :navdata="navdata"></navigation-component>
          </div>
        </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "1",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "agencydashboard"
        },
        {
          id: "2",
          item: "Manage Influencer",
          imgpath: "manager.svg",
          child: [
            { 
              id: "3", 
              item: "Influencer List", 
              name: "agencyinfluencerlist" 
            },
            { 
              id: "4", 
              item: "Influencer Category", 
              name: "agencyinfluencercategory" 
            },
            { 
              id: "33", 
              item: "Influencer Invite", 
              name: "agencyinfluencerinvite" 
            },
          ]
        },
        {
          id: "5",
          item: "Invoice & Payments",
          imgpath: "invoice.svg",
          name: "agencypayment"
        },
        {
          id: "6",
          item: "Support",
          imgpath: "chat.svg",
          name: "agencysupport"
        },
      ]
    };
  },
};
</script>
