<template>
  <div>
    <button-component @onBtnClick="handleApprove(row)" :clsname="btnprimary">Approve</button-component>
    <button-component @onBtnClick="handleReject(row)" :clsname="btndestructive">Reject</button-component>
  </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnprimary: "btnprimary",
      btndestructive: "btndestructive"
    };
  },
  methods: {
    ...mapActions("admininfluenceraction", [
      "getinfluencerpending",
      "approveorrejectinfluencer",
      "getinfluencerapproved",
      "getinfluencerrejected"
    ]),

    handleApprove(row) {
      let vm = this;
      this.approveorrejectinfluencer({
        id: row.id,
        approved: "1"
      })
        .then(response => {
          vm.getinfluencerpending();
          vm.getinfluencerapproved();
          vm.getinfluencerrejected();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },

    handleReject(row) {
      let vm = this;
      this.approveorrejectinfluencer({
        id: row.id,
        approved: "2"
      })
        .then(response => {
          vm.getinfluencerpending();
          vm.getinfluencerapproved();
          vm.getinfluencerrejected();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    }
  }
};
</script>