<template>
    <div class="box margino box-first" v-if="socialInsightsData.length">
        <div class="box-content">
            <div class="row">
                <div class="col-6">
                    <div class="last-updated-data-inner">
                        <text-component class="subtitle2 paddingo">Last Updated</text-component>
                        <text-component class="dark mid left-txt"> {{lastSync}}</text-component>
                    </div>
                </div>
                <div class="col-6 last-updated-btn">
                    <button-component @onBtnClick="fetchLatest()" :clsname="btninvitecls">Fetch Live</button-component>
                </div>
            </div>
            <header-tabs
                class="tabs"
                :tabdata="tabdata"
                :activetab="activetab"
                :maxLimit="maxLimit"
                :isHiddenbar="isHiddenbar">
                <div v-for="(singleSocial,index) in socialInsightsData" :key="index">
                    <div class="tag-bar-bottom-data" v-if="activetab == (singleSocial.plateform+index)">
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='facebook' && singleSocial.data.profile">
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.name">
                                <div class="row margino">
                                    <text-component class="dark">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.birthday">
                                <div class="row margino">
                                    <text-component class="dark">Birthday</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.birthday}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.age_range">
                                <div class="row margino">
                                    <text-component class="dark">Age Range</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.age_range}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.profile.email">
                                <div class="row margino">
                                    <text-component class="dark">Email</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.profile.email}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.posts.data">
                                <div class="row margino">
                                    <text-component class="dark">Total Posts</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.posts.data.length}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.pages.length">
                                <div class="row margino">
                                    <text-component class="dark">Total Pages</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.pages.length}}</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='youtube' && singleSocial.data">
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.title">
                                <div class="row margino">
                                    <text-component class="dark">Channel Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.title}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.viewCount">
                                <div class="row margino">
                                    <text-component class="dark">Total Views</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.viewCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.commentCount">
                                <div class="row margino">
                                    <text-component class="dark">Total Comments</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.commentCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.subscriberCount">
                                <div class="row margino">
                                    <text-component class="dark">Total Subscribers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.subscriberCount}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.videoCount">
                                <div class="row margino">
                                    <text-component class="dark">Total Videos</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.videoCount}}</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='twitter' && singleSocial.data.basic">
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.name">
                                <div class="row margino">
                                    <text-component class="dark">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.screen_name">
                                <div class="row margino">
                                    <text-component class="dark">Twitter Id</text-component>
                                    <text-component class="dark mid">@{{singleSocial.data.basic.screen_name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Followers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.friends_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Friends</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.friends_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.favourites_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Likes</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.favourites_count}}</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="row margino socialinsight-info" v-if="singleSocial.plateform=='instagram' && singleSocial.data.basic">
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.name">
                                <div class="row margino">
                                    <text-component class="dark">Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.name}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.username">
                                <div class="row margino">
                                    <text-component class="dark">User Name</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.username}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.media_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Media</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.media_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.followers_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Followers</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.followers_count}}</text-component>
                                </div>
                            </div>
                            <div class="profile-detail-txt col-12 col-xl-6" v-if="singleSocial.data.basic.follows_count">
                                <div class="row margino">
                                    <text-component class="dark">Total Follows</text-component>
                                    <text-component class="dark mid">{{singleSocial.data.basic.follows_count}}</text-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header-tabs>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
// import RatingComponent from "../../../../BasicComponents/rating/rating.vue";
import TabComponent from "../../../../BasicComponents/Tabs/Tabs.vue";
import HeaderTabs from "../../../../BasicComponents/Tabs/Tabs.vue";
import { eventBus } from "../../../../main";
import { mapActions } from "vuex";

export default {
    components: {
        TextComponent,
        ButtonComponent,
        // TabComponent,
        // RatingComponent,
        HeaderTabs
    },
    data(){
        return {
            maxLimit: 8,
            isHiddenbar: false,
            activetab: 'Instagram',
            rating: 5,
            btninvitecls: "btnprimary",
            tabdata: [],
            socialInsightsData:[],
            lastSync:null,
        }
    },
    created: function() {
        eventBus.$on("changeActivetab",function(selected) {
            // console.log('selected',selected)
            // this.activetab = selected;
            for (let i = 0; i < this.tabdata.length; i++) {
                if(this.tabdata[i].value == selected || this.tabdata[i].key == selected){
                    this.activetab = selected
                }
            }
        }.bind(this));

        this.getData();
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getsocialinsights","fetchsocialinsights"]),
        
        fetchLatest(){
            this.fetchsocialinsights({id:this.$route.params.id})
            .then(response=>{
                this.socialInsightsData = response.data.data;
                this.lastSync = response.data.last_update;
                let tabData = [];
                let socialData = response.data.data;
                for (let i = 0; i < socialData.length; i++) {
                    if(i==0){
                        this.activetab = socialData[i].plateform+i;
                    }
                    tabData.push({id:i,key:socialData[i].plateform+i,value:this.capitalizeWord(socialData[i].plateform)})
                }
                this.tabdata = tabData;
            });
        },

        getData(){
            this.getsocialinsights({id:this.$route.params.id})
            .then(response=>{
                this.socialInsightsData = response.data.data;
                this.lastSync = response.data.last_update;
                let tabData = [];
                let socialData = response.data.data;
                for (let i = 0; i < socialData.length; i++) {
                    if(i==0){
                        this.activetab = socialData[i].plateform+i;
                    }
                    tabData.push({id:i,key:socialData[i].plateform+i,value:this.capitalizeWord(socialData[i].plateform)})
                }
                this.tabdata = tabData;
            });
        },

        capitalizeWord(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
}
</script>