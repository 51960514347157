<template>
    <div class="front-home-page-footer">
        <footer class="footer">
            <div class="columns-10">
                <div class="first-container">
                    <div class="my-row">
                        <div class="my-col-4" data-aos="zoom-in-down">
                            <a href=""><img src="../assets/image/front/LogoWhite.svg" class="logo-white"></a>

                            <ul class="footer-menu">
                                <li><router-link :to="{name:'OurServices'}">Our Services</router-link></li>
                                <li><router-link :to="{name:'Solution'}">Solutions</router-link></li>
                                <li><router-link :to="{name:'AboutUs'}">About</router-link></li>
                                <li><router-link :to="{name:'Blogs'}">Blogs</router-link></li>
                                <li><router-link :to="{name:'ContactUs'}">Contact Us</router-link></li>
                                <li><router-link :to="{name:'TermsOfUse'}">Terms Of Use</router-link></li>
                                <li><router-link :to="{name:'PrivacyPolicy'}">Privacy Policy</router-link></li>
                            </ul>
                        </div>
                        <div class="my-col-6" data-aos="zoom-in-down">
                            <div class="w392">
                                <h3>Office</h3>
                                <p v-if="settings.footer_address">{{settings.footer_address}}</p>
                                <h3>Enquiries</h3>
                                <p v-if="settings.footer_mail"><a :href="'mailto:'+settings.footer_mail">{{settings.footer_mail}}</a></p>
                                <h3>Call</h3>
                                <p v-if="settings.footer_phone"><a :href="'tel:'+settings.footer_phone">{{settings.footer_phone}}</a></p>


                            </div>
                            <div class="footer-social">
                                <a v-if="settings.footer_twitter_link" target="_blank" :href="settings.footer_twitter_link"><img src="../assets/image/front/Tw.svg"></a>
                                <a v-if="settings.footer_facebook_link" target="_blank" :href="settings.footer_facebook_link"><img src="../assets/image/front/Fb.svg"></a>
                                <a v-if="settings.footer_instagram_link" target="_blank" :href="settings.footer_instagram_link"><img src="../assets/image/front/Inst.svg"></a>
                                <a v-if="settings.footer_linkedin_link" target="_blank" :href="settings.footer_linkedin_link"><img src="../assets/image/front/Linkedin.svg"></a>
                            </div>

                            <div class="copy-right">
                                <span>Copyright © {{momentYear()}} VidUnit</span>
                                <div></div>
                                <a target="_blank" href="https://magnetoitsolutions.com/?utm_source=vidunit&utm_medium=website&utm_campaign=vidunit_clicks">Made by Magneto IT Solutions</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="columns-2"> -->
                
            <!-- </div> -->
        </footer>
        <a v-if="isTop" class="top-arrow" @click="scrollTop">
            <img src="../assets/image/front/TopF.png">
            <span>Top</span>
        </a>
    </div>
</template>
<script>
import moment from "moment";
import { mapActions,mapGetters } from "vuex";
export default {
    data() {
        return{
            isTop: false,
            settings:null
        }
    },
    computed: {
        ...mapGetters("frontcms", ["websiteSettings"]),
    },
    watch:{
        websiteSettings:{
            immediate:true,
            handler(websiteSettings){
                console.log('websiteSettings',websiteSettings)
                this.settings = websiteSettings.setting_data
            }
        }
    },
    methods: {
        ...mapActions("frontcms",["getWebsiteSettings"]),
        scrollTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        handleTop() {
            if(window.pageYOffset >= '633'){
                this.isTop = true
            }
            else{
                this.isTop = false
            }
        },
        momentYear(){
            return moment(new Date()).format('YYYY')
        }
    },
    created(){
        window.addEventListener('scroll', this.handleTop);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleTop);
    },
}
</script>