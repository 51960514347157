
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let celebrityClient = [];
    return {
        celebrityClient: celebrityClient,
    }

}

const getters = {
    celebrityClient: state => state.celebrityClient,
};

const actions = {
    getCelebrityClient: ({ commit }) => {
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/celebrity-client")
            .then((response) => {
                console.log(response.data);
                commit("setCelebrityClient", response.data.data);
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    getSingleCelebrityClient: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/celebrity-client/" + id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    createCelebrityClient: ({ dispatch }, data) => {
        // commit("setLoading", true);
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/celebrity-client", data, {...AUTH_PARAMS,})
            .then((response) => {
                dispatch("getCelebrityClient");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    updateCelebrityClient: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        let formData = data.formData;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/celebrity-client/" + id, formData, {...AUTH_PARAMS})
            .then((response) => {
                dispatch("getCelebrityClient");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    deleteCelebrityClient: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/celebrity-client/" + id+'/delete',{...AUTH_PARAMS})
            .then((response) => {
                dispatch("getCelebrityClient");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
};

const mutations = {
    setCelebrityClient: (state, value) => {
        state.celebrityClient = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};