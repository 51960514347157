<template>
    <div class="content-list admin-list content-request">
        <div class="content-header">
            <span class="h2">Article Request</span>
            <div class="page-header-right">
                <button-component @onBtnClick="showPopup" :clsname="btninvitecls">Create Request</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isContentRequest}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close"><img @click="showPopup" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                <div class="header">
                    <text-component :class="'h2'">Content Request</text-component>
                </div>
                <div class="row margino">
                    <input-component
                        required="true"
                        class="txtgroup paddingo col-12"
                        :helptext="!$v.item.topic.required && submitted ? validationmessage.topic: false"
                        :labelclass="{'validationerr': !$v.item.topic.required && submitted}"
                        :classname="{'error': !$v.item.topic.required && submitted, 'default': true}"
                        v-model="item.topic">
                        Request Topic
                    </input-component>
                    <input-component
                        required="true"
                        class="txtgroup paddingo col-12"
                        :helptext="!$v.item.description.required && submitted ? validationmessage.description: false"
                        :labelclass="{'validationerr': !$v.item.description.required && submitted}"
                        :classname="{'error': !$v.item.description.required && submitted, 'default': true}"
                        v-model="item.description">
                        Description
                    </input-component>
                </div>
                <hr class="hrline" />
                <div class="modal-footer">
                    <div>
                        <button-component
                        :clsname="btninvitecls"
                        @onBtnClick="createRequest"
                        v-if="!isedit"
                        >Create Request</button-component>
                        <button-component
                        :clsname="btninvitecls"
                        @onBtnClick="editRequest"
                        v-if="isedit"
                        >Edit Request</button-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
        
    </div>
</template>

<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue"
import StatusBadge from "./statusbadge.vue";
import EditBtn from "./contentrequestedit.vue";
import Action from "./contentrequestaction.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        DatatableComponent,
        searchBar,
        ButtonComponent,
        TextComponent,
        InputComponent
    },
    validations: {
    item: {
      topic: { required },
      description: { required },
    }
  },
    data() {
        return {
            type: "text",
            inputPlaceholder: "Search Request Name",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            isedit: false,
            currenteditid: null,
            isContentRequest: false,
            item: {
                topic: null,
                description: null,
            },
            validationmessage: {
                topic: "topic is required",
                description: "description is required",
            },
            submitted: false,
            columns: [
                {
                    label: "Topic",
                    field: "topic",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Description",
                    field: "description",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                // { label: "Status", headerAlign: "left", align: "right", component: StatusBadge },
                { label: "", headerAlign: "left", align: "right", component: EditBtn },
                { label: "Action", headerAlign: "left", align: "left", component: Action },
            ],
            rows: [],
        };
    },
    methods: {
        ...mapActions("publisherdata", ["articlerequest", "articlerequestlist", "updatearticlerequest"]),

        showPopup() {
            if(this.isContentRequest == false) {
                this.isContentRequest = true
            }
            else{
                this.isContentRequest = false
            }
            this.isedit = false
            this.item.topic = null
            this.item.description = null

        },

        showEditPopup() {
            if(this.isContentRequest == false) {
                this.isContentRequest = true
            }
            else{
                this.isContentRequest = false
            }
        },

        createRequest() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.articlerequest({
                    topic: this.item.topic,
                    description: this.item.description,
                }).then(response => {
                    this.submitted = false;
                    this.articlerequestlistdata()
                    this.showPopup()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        editRequest() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.updatearticlerequest({
                    id: this.currenteditid,
                    topic: this.item.topic,
                    description: this.item.description,
                }).then(response => {
                    this.articlerequestlistdata()
                    this.showEditPopup()
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        articlerequestlistdata() {
            this.articlerequestlist()
            .then(response=>{
                this.rows = response.data
            });
        },

    },
    created() {
        this.articlerequestlistdata()
        this.$root.$on("onrequestEdit", (row) => {
            this.isedit = true
            this.showEditPopup()
            this.item.topic = row.topic
            this.item.description = row.description
            this.currenteditid = row.id
        });
        this.$root.$on("adminRequestDeleteClick", (row) => {
            this.articlerequestlistdata()
        });
    }
}
</script>