<template>
  <div>
    <input
      type="checkbox"
      name="cb"
      v-model="selected"
      class="data-checkbox"
      @change="emitSelected"
    />
  </div>
</template>

<script>
import { eventBus } from "../../../main";
export default {
  props: ["row"],
  data() {
    return {
      selected: false
    };
  },
  methods: {
    emitSelected: function(e) {
    var i;
      let actiondivcount = document.getElementsByClassName("actions-div");
      for ( i = 0; i < actiondivcount.length; i++) {
        actiondivcount[i].style.visibility = "visible";
      }
      var inputcboxcount = document.getElementsByClassName("data-checkbox");
      // console.log(inputcboxcount)
      let count = 0;
      for ( i = 0; i < inputcboxcount.length; i++) {
        if (
          inputcboxcount[i].type === "checkbox" &&
          inputcboxcount[i].checked === true &&
          i != 0
        ) {
          count++;
        }
      }
      if (count >= 1) {
        let thead = document.getElementsByTagName("thead");
        for (i = 0; i < thead.length; i++) {
          thead[i].style.visibility = "hidden";
        }
      }
      if (count <= 0) {
        let actiondivcount = document.getElementsByClassName("actions-div");
        for ( i = 0; i < actiondivcount.length; i++) {
          actiondivcount[i].style.visibility = "hidden";
        }
        let thead = document.getElementsByTagName("thead");
        for ( i = 0; i < thead.length; i++) {
          thead[i].style.visibility = "visible";
        }
      }
      let strid = e.path[3].rowIndex;
      let cbstatus = e.target.checked;
      // console.log(strid)
      // console.log(cbstatus)
      var elems = document.getElementsByTagName("tr");
      // console.log(elems)
      for ( i = 0; i < elems.length; i++) {
        if (strid == i) {
          elems[i].style.backgroundColor = "rgb(255, 243, 224)";
        }
        if (
          strid == i &&
          elems[i].style.backgroundColor == "rgb(255, 243, 224)" &&
          cbstatus == false
        ) {
          elems[i].style.backgroundColor = "rgba(255, 255, 255, 0)";
          // console.log(elems[i].style.backgroundColor);
        }
      }
      if (this.selected) {
        eventBus.$emit("selected", this.row);
      } else {
        eventBus.$emit("deselected", this.row);
      }
    }
  },
  created: function() {
    eventBus.$on(
      "select-all",
      function(selected) {
        console.log(selected);
        this.selected = true;
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function(selected) {
        console.log(selected);
        this.selected = false;
      }.bind(this)
    );
  }
};
</script>