<template>
    <div class="tool-tip" :style="{width:width,height:height}">
        <img 
            src="../../assets/image/information.svg" 
            alt="info-icon" 
            class="info-img" 
            :style="{width:width,height:height}"
            @mouseenter="showToolTip"
            @mouseleave="hideToolTip"
            v-tooltip.top-center="msgdata"
        />
        <div 
            class="tool-tip-des" 
            :class="{'tool-tip-des-visible': tooltipvisible}"
            id="tooltip-width"
            :style="{left:tooltipleft+'px',width:outwidth}">
            <div class="tool-tip-des-txt"
                :style="{width:inwidth}">
                <slot/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["height", "width", "inmodal"],
    data() {
        return {
            tooltipvisible: false,
            left:10,
            msgdata: null,
            screenwidth: null,
            tooltipleft: 'null',
            outwidth: 'calc(100vw - 20px)',
            inwidth: 'calc(100vw - 70px)'
        }
    },
    methods: {
        showToolTip(e) {
            if(this.inmodal == true) {
                this.tooltipleft = '-40'
                this.outwidth = '323px'
                this.inwidth = '319px'
            }
            else{
               this.tooltipleft = '-'+e.x
            }
            console.log(e)
            this.tooltipvisible = true
        },
        hideToolTip() {
            this.tooltipvisible = false
        },

    },

}
</script>