<template>
  <div>
    <input
      type="checkbox"
      statusid="btnstatus"
      actionid="btnaction"
      name="cb"
      v-model="selected"
      @change="emitSelection"
      class="data-checkbox"
    />
  </div>
</template>

<script>
import { eventBus } from "../../../main";
export default {
  data: function() {
    return {
      selected: false
    };
  },
  methods: {
    emitSelection: function() {
      
      let actiondivcount = document.getElementsByClassName("actions-div");
      for (let i = 0; i < actiondivcount.length; i++) {
        actiondivcount[i].style.visibility = "false";
      }
      
      var mastercbox = document.getElementsByClassName("data-checkbox");
      for (let i = 1; i < mastercbox.length; i++) {
        mastercbox[i].checked= true
      }
      let mastercboxstatus = mastercbox[0].checked;
      // console.log(mastercboxstatus)
      if (mastercboxstatus == true) {
        let thead = document.getElementsByTagName("thead");
        for (let j = 0; j < thead.length; j++) {
          thead[j].style.visibility = "hidden";
        }
        let actiondivcount = document.getElementsByClassName("actions-div");
        for (let k = 0; k < actiondivcount.length; k++) {
          actiondivcount[k].style.visibility = "visible";
        }
        var elems = document.getElementsByTagName("tr");
        // console.log(elems);
        for (let p = 0; p < elems.length; p++) {
          if (p >= 1) {
            elems[p].style.backgroundColor = "rgb(255, 243, 224)";
          }
        }
      }
      if (mastercboxstatus == false) {
        let thead = document.getElementsByTagName("thead");
        for (let q = 0; q < thead.length; q++) {
          thead[q].style.visibility = "visible";
        }
      }
      eventBus.$emit(this.selected ? "select-all" : "deselect-all");
    }
  },
  created: function() {
    eventBus.$on(
      "deselected",
      function(row) {
        console.log(row);
        this.selected = false;
      }.bind(this)
    );

    eventBus.$on(
      "all-selected",
      function(row) {
        console.log(row);
        this.selected = true;
      }.bind(this)
    );
  }
};
</script>