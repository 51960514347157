<template>
    <div>
        <badge class="badge" :badgeclass="StatusClass">{{StatusText}}</badge>
    </div>
</template>

<script>
import Badge from '../../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],
    watch:{
        row:{
            immediate:true,
            handler(row){
                if (row.approved=='1') {
                    this.StatusText ='Enrolled';
                    this.StatusClass ='Success-Badge';
                }else{
                    this.StatusText ='Pending';
                    this.StatusClass ='Attention-Badge';
                }
            }
        }
    },
    data() {
        return {
            StatusText:'Pending',
            StatusClass:'Attention-Badge'
        }
    },
}
</script>