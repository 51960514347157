<template>
  <div>
    <input
      type="checkbox"
      :name="checkboxName"
      v-model="selected"
      class="data-checkbox"
      @change="emitSelected"
    />
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapGetters} from "vuex";
export default {
  props: ["row"],
  computed: {
    ...mapGetters("manageinfluencer", ["selectedIds"])
  },
  data() {
    return {
      selected: false,
      totalSelected: 0,
      checkboxName: "cb["+this.row.id+"]"
    };
  },
  // computed: {
  //   selected: {
  //     get() {
  //       return this.value;
  //     },
  //     set(val) {
  //       this.$emit('input', val);
  //     }
  //   }
  // },
  watch: {
    row: {
      immediate: true,
      handler (row) {
        this.checkboxName= "cb["+row.id+"]";
        if (this.selectedIds.indexOf(row.id)!=-1) {
            this.selected = true
        }else{
            this.selected = false
        }
      }
    },
    selectedIds: {
      immediate: true,
      handler (selectedIds) {
        this.totalSelected =selectedIds.length
        this.onTotalLength();
      }
    },
  },
  methods: {
    onTotalLength:function () {
        var i;
        if (this.totalSelected) {
            let thead = document.getElementsByTagName("thead");
            for (i = 0; i < thead.length; i++) {
                thead[i].style.visibility = "hidden";
            }
        }else{
            let actiondivcount = document.getElementsByClassName("actions-div");
            for ( i = 0; i < actiondivcount.length; i++) {
                actiondivcount[i].style.visibility = "hidden";
            }
            let thead = document.getElementsByTagName("thead");
            for ( i = 0; i < thead.length; i++) {
                thead[i].style.visibility = "visible";
            }
        }  
    },
    emitSelected: function(e) {
      let cbstatus = e.target.checked;
      console.log(cbstatus)
      if (cbstatus) {
        e.currentTarget.parentNode.parentNode.parentNode.style.backgroundColor = "#F4F5FE";  
      }else{
        e.currentTarget.parentNode.parentNode.parentNode.style.backgroundColor = "";
      }
      if (this.selected) {
        console.log("selected row",this.row);
        eventBus.$emit("selected", this.row);
      } else {
        eventBus.$emit("deselected", this.row);
      }
    },
    // emitSelected: function(e) {
    //   // console.log("inside emit selected");
    //   // console.log(e)

    //   var i;
    //   var inputcboxcount = document.getElementsByClassName("data-checkbox");
    //   // console.log(inputcboxcount)
    //   let count = 0;
    //   for ( i = 0; i < inputcboxcount.length; i++) {
    //     if (
    //       inputcboxcount[i].type === "checkbox" &&
    //       inputcboxcount[i].checked === true &&
    //       i != 0
    //     ) {
    //       count++;
    //     }
    //   }
    //   if (count >= 1) {
    //     let thead = document.getElementsByTagName("thead");
    //     for (i = 0; i < thead.length; i++) {
    //       thead[i].style.visibility = "hidden";
    //     }
    //   }
    //   if (count <= 0) {
    //     let actiondivcount = document.getElementsByClassName("actions-div");
    //     for ( i = 0; i < actiondivcount.length; i++) {
    //       actiondivcount[i].style.visibility = "hidden";
    //     }
    //     let thead = document.getElementsByTagName("thead");
    //     for ( i = 0; i < thead.length; i++) {
    //       thead[i].style.visibility = "visible";
    //     }
    //   }

    //   let cbstatus = e.target.checked;
    //   if (cbstatus) {
    //     e.currentTarget.parentNode.parentNode.parentNode.style.backgroundColor = "#F4F5FE";  
    //   }else{
    //     e.currentTarget.parentNode.parentNode.parentNode.style.backgroundColor = "";
    //   }
    //   if (this.selected) {
    //     console.log("selected row",this.row);
    //     eventBus.$emit("selected", this.row);
    //   } else {
    //     eventBus.$emit("deselected", this.row);
    //   }
    // }
  },
  created: function() {
    eventBus.$on(
      "select-all",
      function(selected) {
        console.log('selected',selected);
        this.selected = true;
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function(selected) {
        console.log('selected',selected);
        this.selected = false;
      }.bind(this)
    );
  }
};
</script>