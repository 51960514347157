<template>
    <div class="cms-detail">
        <div class="content-header">
            <text-component class="h2">CMS Page Detail</text-component>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <form @submit.prevent="handleCms">
                        <div class="row margino name-slug">
                            <input-component
                                class="txtgroup col-12 col-md-6"
                                v-model="item.name"
                                required="true"
                                :helptext="!$v.item.name.required && submitted ? validationmessage.name: false"
                                :labelclass="{'validationerr': !$v.item.name.required && submitted}"
                                :classname="{'error': !$v.item.name.required && submitted, 'default': true}"
                            >Name</input-component>
                            <input-component
                                class="txtgroup col-12 col-md-6"
                                v-model="item.slug"
                                required="true"
                                :helptext="!$v.item.slug.required && submitted ? validationmessage.slug: false"
                                :labelclass="{'validationerr': !$v.item.slug.required && submitted}"
                                :classname="{'error': !$v.item.slug.required && submitted, 'default': true}"
                            >Slug</input-component>
                        </div>
                        <input-component
                            class="txtgroup col-12"
                            v-model="item.meta_keyword"
                            required="true"
                            :helptext="!$v.item.meta_keyword.required && submitted ? validationmessage.meta_keyword: false"
                            :labelclass="{'validationerr': !$v.item.meta_keyword.required && submitted}"
                            :classname="{'error': !$v.item.meta_keyword.required && submitted, 'default': true}"
                        >Meta Keyword</input-component>
                        <input-component
                            class="txtgroup col-12"
                            v-model="item.meta_title"
                            required="true"
                            :helptext="!$v.item.meta_title.required && submitted ? validationmessage.meta_title: false"
                            :labelclass="{'validationerr': !$v.item.meta_title.required && submitted}"
                            :classname="{'error': !$v.item.meta_title.required && submitted, 'default': true}"
                        >Meta Title</input-component>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>Meta Description</text-component>
                            </div>
                            <text-area-comp v-model="item.meta_desc"
                                required="true"
                                :helptext="!$v.item.meta_desc.required && submitted ? validationmeta_desc.meta_desc: false"
                                :labelclass="{'validationerr': !$v.item.meta_desc.required && submitted}"
                                :classname="{'error': !$v.item.meta_desc.required && submitted, 'default': true,'txtarea':true}"
                            ></text-area-comp>
                        </div>
                        <div class="txtgroup">
                            <div class="inputtxt">
                                <text-component>
                                    Description
                                    <sup>*</sup>
                                </text-component>
                            </div>
                            <ckeditor 
                                v-model="item.description" 
                                :config="editorConfig"
                                :class="{'error': !$v.item.description.required && submitted}">
                            </ckeditor>
                            <span
                                v-if="!$v.item.description.required && submitted"
                                class="validationerr"
                            >{{validationmessage.description}}</span>
                        </div>
                        <about-us ref="mychild" :submitted="submitted"  v-if="item.template == 'aboutus'" :content-data="contentData" :base-url="baseUrl" :image-data="imageData" :image-data-show="imageDataShow"></about-us>
                        <home-page ref="mychild" :submitted="submitted"  v-if="item.template == 'home'" :content-data="contentData" :base-url="baseUrl" :image-data="imageData" :image-data-show="imageDataShow"></home-page>
                        <our-service ref="mychild" :submitted="submitted"  v-if="item.template == 'ourservices'" :content-data="contentData" :base-url="baseUrl" :image-data="imageData" :image-data-show="imageDataShow"></our-service>
                        <solution-page ref="mychild" :submitted="submitted"  v-if="item.template == 'solution'" :content-data="contentData" :base-url="baseUrl" :image-data="imageData" :image-data-show="imageDataShow"></solution-page>
                        <div class="footer-update-btn">
                            <button-component buttontype="submit" :clsname="btnprimary">Update</button-component>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import AboutUs from "./content/aboutus.vue";
import HomePage from "./content/homepage.vue";
import OurService from "./content/ourservice.vue";
import SolutionPage from "./content/solution.vue"
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
    components: {
        TextComponent,
        InputComponent,
        ButtonComponent,
        AboutUs,
        TextAreaComp,
        HomePage,
        OurService,
        SolutionPage
    },
    validations: {
        item: {
            name: { required },
            slug: { required },
            meta_keyword: { required },
            meta_title: { required },
            meta_desc: { required },
            description: { required }
        },
    },
    data() {
        return {
            item: {
                name: null,
                slug: null,
                meta_keyword: null,
                meta_title: null,
                meta_desc: null,
                description: null
            },
            validationmessage: {
                name: "cms name is required ",
                slug: "slug is required ",
                meta_keyword: "meta keyword is required",
                meta_title: "meta title is required",
                meta_desc: "meta description is required",
                description: "description is required"
            },
            submitted: false,
            btnprimary: 'btnprimary',
            editorConfig: {allowedContent:true},
            imageData: [],
            contentData: {},
            imageDataShow: {},
            baseUrl: null
        }
    },
    methods: {
        ...mapActions("cmsdata", ["getcmsdetailbyid", "updatecmsdata"]),

        handleCms() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            }else if(this.$refs.mychild && this.$refs.mychild.$v.contentData.$invalid){
                console.log('asdasss error')
                // this.$refs.mychild.$v.contentData.$touch();
                this.$refs.mychild.touch()
            // }else if (this.$v.contentData.$invalid) {
            //     this.$v.contentData.$touch();
            } else {
                console.log("imgdata"+this.imageData)
                const Data = new FormData();
                let cms_detail = {
                    name: this.item.name,
                    slug: this.item.slug,
                    meta_keyword: this.item.meta_keyword,
                    meta_title: this.item.meta_title,
                    meta_desc: this.item.meta_desc,
                    description: this.item.description
                };
                Data.append("id", this.$route.params.id);
                Data.append("cms_detail", JSON.stringify(cms_detail));
                Data.append("cms_field", JSON.stringify(this.contentData));
                // Data.append("cms_image",this.imageData);
                let imageData = this.imageData;
                for (let index = 0; index < imageData.length; index++) {
                    Data.append("cms_image["+imageData[index]['name']+"]",imageData[index]['file']);
                }
                this.updatecmsdata(Data)
                .then(response => {
                    console.log(response);
                    this.submitted = false
                    this.getcmsdetaildata()
                    this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                });
            }
        },

        getcmsdetaildata() {
            this.getcmsdetailbyid({id:this.$route.params.id})
            .then((response) => {
                this.item = response.data.cms_detail
                // this.item.name = response.data.cms_detail.name,
                // this.item.slug = response.data.cms_detail.slug,
                // this.item.meta_keyword = response.data.cms_detail.meta_keyword
                // this.item.meta_title = response.data.cms_detail.meta_title,
                // this.item.meta_desc = response.data.cms_detail.meta_desc
                // this.item.description = response.data.cms_detail.description
                if(response.data.cms_field.length){
                    this.contentData = response.data.cms_field
                }
                else{
                    this.contentData = Object.assign({}, response.data.cms_field)
                }

                this.imageDataShow = response.data.cms_image
                this.baseUrl = response.data.baseUrl
                
            });
        },
    },
    created() {
        this.getcmsdetaildata()
    }
}
</script>