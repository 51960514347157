<template>
    <div class="login-page">
      <div class="row margino">
        <div class="col-12 col-md-6 login-left">
          <div v-if="!otp_screen">
            <form @submit.prevent="handleLogin">
              <text-component :textclass="h2class">Sign in into your Account</text-component>
              <div class="row rowpadding noaccount">
                <text-component class="mid">Don't have an account yet?</text-component>
                <router-link to="signup">
                  <button-component class="btnsignup" :clsname="btnplainclass">Sign up</button-component>
                </router-link>
              </div>
              <input-component
                required="true"
                class="txtgroup"
                v-model="item.email"
                :helptext="(!$v.item.email.required || !$v.item.email.email) && submitted ? validationmessage.email: false"
                :labelclass="{'validationerr': (!$v.item.email.required || !$v.item.email.email) && submitted}"
                :classname="{'error': (!$v.item.email.required || !$v.item.email.email) && submitted, 'default': true}"
              >Email address</input-component>
              <input-component
                required="true"
                class="txtgroup"
                v-model="item.password"
                :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
                :labelclass="{'validationerr': !$v.item.password.required && submitted}"
                :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
                :type="passwordtype"
              >Password</input-component>
              <router-link to="forgotpassword">
                <button-component class="forgotpass" :clsname="btnplainclass">Forgot password?</button-component>
              </router-link>
              <br />
              <button-component class="loginbtn" buttontype="submit" :clsname="btnregularclass">Log in</button-component>
            </form> 
          </div>
          <div v-else>
            <form @submit.prevent="handleOTPValidation">
              <text-component :textclass="h2class">Verify OTP</text-component>
              <div class="row rowpadding noaccount">
                <text-component class="mid">We have sent you an otp at {{verify.mobile_prefix}} xxx-xxx-{{lastfour(verify.mobile_number)}}</text-component>
              </div>
              
              <otp-input-component
                required="true"
                class="txtgroup"
                v-model="verify.otp"
                :helptext="!$v.verify.otp.required && submittedotp ? validationmessage.otp: false"
                :labelclass="{'validationerr': !$v.verify.otp.required && submittedotp}"
                :classname="{'error': !$v.verify.otp.required && submittedotp, 'default': true}"
                :type="passwordtype"
                :otplen="4"
              >OTP</otp-input-component>
              <button-component class="loginbtn" buttontype="submit" :clsname="btnregularclass">Verify</button-component>
            </form>
          </div>
        </div>
        <div class="col-6 login-right"></div>
      </div>
    </div>
</template>

<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import OtpInputComponent from "../../../BasicComponents/Input Group/otpInput.vue";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  components: {
    TextComponent,
    ButtonComponent,
    InputComponent,
    OtpInputComponent
  },
  validations: {
    item: {
      email: { required, email },
      password: { required }
    },
    verify:{
      mobile_prefix: { required },
      mobile_number: { required },
      otp: { required },
    }
  },
  data() {
    return {
      item: {
        email: null,
        password: null
      },
      verify: {
        mobile_prefix: '+91',
        mobile_number: null,
        otp: null,
      },      
      validationmessage: {
        email: "please enter valid email",
        password: "password is required",
        otp: "OTP is required"
      },
      submitted: false,
      submittedotp: false,
      otp_screen: false,
      h2class: "h2",
      btnplainclass: "btnplain",
      classname: "default",
      passwordtype: "password",
      btnregularclass: "btnprimary"
    };
  },

  methods: {
    ...mapActions("user", ["login","verifyOTPRegLogin"]),
    lastfour(value){
      return value.substr(value.length - 4);
    },

    handleLogin() {
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
      } else {
        let vm = this;
        this.login({
          email: this.item.email,
          password: this.item.password
        })
          .then(response => {
            console.log(response);
            if(response.data.otp_screen){
              this.otp_screen = true;
              this.verify.mobile_number = response.data.phone;
              this.verify.mobile_prefix = response.data.prefix;
            }else{
              if (response.data.role_id == "2") {
                if (response.data.login_count == "0") {
                  vm.$router.push({ name: "MyProfile" });
                } else {
                  vm.$router.push({ name: "dashboard" });
                }
              } else if (response.data.role_id == "3") {
                if (response.data.login_count == "0") {
                  vm.$router.push({ name: "agencyprofile" });
                } else {
                  vm.$router.push({ name: "agencydashboard" });
                }
              } else if (response.data.role_id == "1") {
                vm.$router.push({ name: "admindashboard" });
              } else if (response.data.role_id == "5") {
                vm.$router.push({ name: "publisherdashboard" });
              } else if (response.data.role_id == "4") {
                if (response.data.login_count == "0") {
                  vm.$router.push({ name: "brandprofile" });
                } else {
                  vm.$router.push({ name: "branddashboard" });
                }
              }
            }
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    handleOTPValidation(){
      this.submittedotp = true;
      if (this.$v.verify.$invalid) {
        this.$v.verify.$touch();
      } else {
        let vm = this;
        this.verifyOTPRegLogin({
          prefix: this.verify.mobile_prefix,
          phone: this.verify.mobile_number,
          otp: this.verify.otp,
        })
          .then(response => {
            console.log(response);
            if (response.data.role_id == "2") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "MyProfile" });
              } else {
                vm.$router.push({ name: "dashboard" });
              }
            } else if (response.data.role_id == "3") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "agencyprofile" });
              } else {
                vm.$router.push({ name: "agencydashboard" });
              }
            } else if (response.data.role_id == "1") {
              vm.$router.push({ name: "admindashboard" });
            } else if (response.data.role_id == "5") {
              vm.$router.push({ name: "publisherdashboard" });
            } else if (response.data.role_id == "4") {
              if (response.data.login_count == "0") {
                vm.$router.push({ name: "brandprofile" });
              } else {
                vm.$router.push({ name: "branddashboard" });
              }
            }
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },
  },
  // created() {
  //   console.log(window.location.href)
  //   let path = window.location.href.split("/")
  //   console.log(path)
  // },
};
</script>