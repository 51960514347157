<template>
    <div class="action-btns">
        <button-component v-if="row.status == '0'" @onBtnClick="onApprove(row)" :clsname="'btnprimary'">Approve</button-component>
        <button-component v-if="row.status == '0'" @onBtnClick="onReject(row)" :clsname="'btndestructive'">Reject</button-component>
    </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    props: ["row"],
    components: {
        ButtonComponent
    },
    methods: {
        ...mapActions("publisherdata", ["approveorrejectwebsite"]),

        onApprove(row) {
            console.log(row)
            this.approveorrejectwebsite({
                id: row.id,
                status: "1",
            }).then(response => {
                this.$root.$emit('adminWebsiteDeleteClick', row)
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        },
        onReject(row) {
            console.log(row)
            this.approveorrejectwebsite({
                id: row.id,
                status: "2",
            }).then(response => {
                this.$root.$emit('adminWebsiteDeleteClick', row)
                this.$toast.open({
                    message: response.message,
                    type: "success",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            })
            .catch(err => {
                this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                });
            });
        }
    }
}
</script>