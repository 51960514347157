<template>
  <div class="influencer-campaign-detail">
    <div class="content-header">
      <text-component class="h2">Campaign Details</text-component>
    </div>
    <div class="content">
      <div class="box margino paddingo">
        <div class="box-content">
          <div class="campaign-detail-section">
            <div class="row campaign-detail-section-main margino">
              <div class="col-12 col-xl-8 campaign-data margino">
                <div class="box-header"
                  style="display:inline-block;width:100%;">
                    <text-component class="subtitle2">Campaign Name</text-component>
                    <text-component class="black campaign-data-txt">{{request.campaign_name}}</text-component>
                  <!-- <text-component class="subtitle1">Campaign Basic detail</text-component> -->
                  <div class="campaign-data-header-btn">
                    <badge-component class="status-badge" :badgeclass="badgeclass">{{badgetext}}</badge-component>
                  </div>
                </div>
                <div class="camp-detail-header row margino">
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Description:</text-component>
                    <text-component class="pre-white-space black campaign-data-txt">{{request.discription}}</text-component>
                  </div>
                </div>
                <div class="camp-detail-data row margino">
                  <div class="campaign-data-main col-12" v-if="request.hashtags">
                    <text-component class="subtitle2">Campaign Hashtags</text-component>
                    <!-- <text-component class="black campaign-data-txt">{{request.hashtags.toString()}}</text-component> -->
                    <text-component class="black tags-card campaign-data-txt"
                      v-for="(htags,index) in request.hashtags" :key="index"
                      :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                      {{htags}}
                    </text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Schedule</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{formatDateGlobal(request.start_date)}} ~ {{formatDateGlobal(request.end_date)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Influencer category</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getCategory(request.influencer_category)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Number of Influencers</text-component>
                    <text-component class="black campaign-data-txt">{{request.influencers}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Gender Ratio</text-component>
                    <text-component class="black campaign-data-txt">{{request.male_ratio}}:{{request.female_ratio}} (M:F)</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Social Media Platform</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getSocialPlatform(request.social_platform)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12">
                    <text-component class="subtitle2">Content Format</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getContentFormat(request.content_format)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brand</text-component>
                    <text-component class="black campaign-data-txt">{{request.user?request.user.name:'N/A'}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12" v-if="request.brand_hashtags">
                    <text-component class="subtitle2">Brand Hashtags</text-component>
                    <text-component class="black tags-card campaign-data-txt"
                      v-for="(btags,index) in request.brand_hashtags" :key="index"
                      :style="{ background: colors[Math.floor(Math.random() * 6)]}">
                      {{btags}}
                    </text-component>
                  </div>
                  <div v-if="!request.budget_visibility == '0'" class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Budget</text-component>
                    <text-component class="black campaign-data-txt">₹ {{request.budget}}</text-component>
                  </div>


                  <!-- <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Number of Influencers</text-component>
                    <text-component class="black campaign-data-txt">{{request.influencers}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Influencer category</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getCategory(request.influencer_category)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Social Media Platform</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getSocialPlatform(request.social_platform)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Content Format</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{getContentFormat(request.content_format)}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6" v-if="request.hashtags">
                    <text-component class="subtitle2">Campaign Hashtags</text-component>
                    <text-component class="black campaign-data-txt">{{request.hashtags.toString()}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Campaign Budget</text-component>
                    <text-component class="black campaign-data-txt">₹ {{request.budget}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6" v-if="request.brand_hashtags">
                    <text-component class="subtitle2">Brand Hashtags</text-component>
                    <text-component
                      class="black campaign-data-txt"
                    >{{request.brand_hashtags.toString()}}</text-component>
                  </div>
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brief Description</text-component>
                    <text-component class="black campaign-data-txt">{{request.discription}}</text-component>
                  </div> -->
                </div>
                <!-- <div class="box-header-brand" v-if="request.user">
                  <text-component class="subtitle1">Brand detail</text-component>
                </div>
                <div class="row margino" v-if="request.user">
                  <div class="campaign-data-main col-12 col-lg-6">
                    <text-component class="subtitle2">Brand Name</text-component>
                    <text-component class="black campaign-data-txt">{{request.user.name}}</text-component>
                  </div>
                </div> -->

                <div class="row margino" v-if="influenceStatus.influencer_status == '1'">
                  <div class="datatable-main">
                    <div class="table-header">
                      <text-component class="subtitle1">Campaign Insights</text-component>
                      <button-component
                        @onBtnClick="addLink"
                        clsname="btnprimary link-right"
                        v-if="!rows.length"  
                      >Add Link</button-component>
                    </div>
                    <div class="data-table-header">
                      <div class="data-table-header-inner">
                        <search-bar
                          class="header-searchbar"
                          :classname="classname"
                          :type="type"
                          :inputPlaceholder="inputPlaceholder"
                          v-model="filter"
                        ></search-bar>
                      </div>
                    </div>
                    <datatable-component
                      class="dtable-main"
                      :selected-rows="selectedRows"
                      :columns="columns"
                      :rows="rows"
                      :type="type"
                      :inputPlaceholder="inputPlaceholder"
                      :classname="classname"
                      :filter="filter"
                      :page="page"
                      :per_page="per_page"
                    ></datatable-component>
                  </div>
                </div>
              </div>
              <div class="col-4 campaign-chat" id="chatsection">
                <campaign-chat></campaign-chat>
                <div @click="openChat" class="chat-btn">
                  <img click src="../../../assets/image/chat.svg" class="chat-btn-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div :class="{'modal-window': true , 'popupopen': isInvite}">
      <div class="modal-window-main">
        <a title="Close" class="modal-close">
          <img
            @click="addLink"
            src="../../../assets/image/cancel.svg"
            class="cancel-icon"
            alt="cancel-icon"
          />
        </a>
        <form @submit.prevent="handleSendLink">
          <div class="header">
            <text-component :class="h2txtclass">Add Link</text-component>
          </div>
          <div class="row message-desc">
            <select-component
              class="txtgroup col-12"
              required="true"
              :helptext="!$v.item.account.required && submitted ? validationmessage.account: false"
              :labelclass="{'validationerr': !$v.item.account.required && submitted}"
              :inputclass="{'error': !$v.item.account.required && submitted, 'dropdownmenu': true}"
              :options="accountoption"
              v-model="item.account"
              @handlevalue="showPageid(item.account)"
              :multiple="false"
              :placeholder="selectplaceholder"
            >Accounts</select-component>
            <input-component
              class="txtgroup col-12"
              v-model="item.link"
              required="true"
              :helptext="!$v.item.link.required && submitted ? validationmessage.link: false"
              :labelclass="{'validationerr': !$v.item.link.required && submitted}"
              :classname="{'error': !$v.item.link.required && submitted, 'default': true}"
            >Link
              <tooltip-component
                v-if="!showScreenshotUpload" 
                :width="'14px'" 
                :height="'14px'"
                :inmodal="true">
                <!-- {{(selectedplatform)?selectedplatform+' is selected':'please select a platform'}} -->
                <div v-if="selectedaccount == null">
                  <ul>
                    <li>please select a platform</li>
                  </ul>
                </div>
                <div v-if="selectedaccount == '3'">
                  <ul>
                    <li>Enter your Post Link here in link field and then enter post id and page id.
                      for eg. https://www.facebook.com/112730137180327/posts/10153577510711840
                      in above URL 112730137180327 is page ID and 10153577510711840 is post ID.</li>
                  </ul>
                </div>
                <div v-if="selectedaccount == '1'">
                  <ul>
                    <li>Enter your Post Link here in link field and then enter shortcode.
                        for eg. https://www.instagram.com/p/CCqLFJ2BvRV/
                        in above URL CCqLFJ2BvRV is shortcode.
                    </li>
                  </ul>
                </div>
                <div v-if="selectedaccount == '4'">
                  <ul>
                    <li>Enter your Post Link here in link field and then enter post ID.
                        https://twitter.com/dilpesh001/status/1269929316734959616
                        in above URL 1269929316734959616 is post ID.

                    </li>
                  </ul>
                  
                </div>
                <div v-if="selectedaccount == '2'">
                  <ul>
                    <li>Enter your Video Link here in link field and then enter video Id.
                        https://www.youtube.com/watch?v=dR8bxrnW8rY
                        in above URL dR8bxrnW8rY is Video ID.
                    </li>
                  </ul>
                  
                </div>
              </tooltip-component>
            </input-component>
            <div class="col-12 ">
              <div v-if="showScreenshotUpload">
                <div class="row">
                  <div class="col-12">
                    <text-component :class="''">Screenshot <sup>*</sup></text-component>
                    <profile-uploader
                      :imageurl="imageurl"
                      :fileuploadid="fileuploadid"
                      @selectfile="getfiledata"
                      :avatar="avatar"
                    ></profile-uploader>
                  </div>
                  <input-component
                    class="txtgroup col-6"
                    v-model="item.num_likes"
                    required="true"
                    :helptext="!$v.item.num_likes.required && submitted ? validationmessage.num_likes: false"
                    :labelclass="{'validationerr': !$v.item.num_likes.required && submitted}"
                    :classname="{'error': !$v.item.num_likes.required && submitted, 'default': true}"
                  >No. of Likes</input-component>
                  <input-component
                    class="txtgroup col-6"
                    v-model="item.num_views"
                    required="true"
                    :helptext="!$v.item.num_views.required && submitted ? validationmessage.num_views: false"
                    :labelclass="{'validationerr': !$v.item.num_views.required && submitted}"
                    :classname="{'error': !$v.item.num_views.required && submitted, 'default': true}"
                  >No. of Views/Comments</input-component>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <input-component
                    class="txtgroup col-12"
                    v-model="item.postid"
                    required="true"
                    :helptext="!$v.item.postid.required && submitted ? validationmessage.postid: false"
                    :labelclass="{'validationerr': !$v.item.postid.required && submitted}"
                    :classname="{'error': !$v.item.postid.required && submitted, 'default': true}"
                  >Post Id</input-component>
                  <input-component
                    required="true"
                    v-if="isPageid == true"
                    class="txtgroup col-12"
                    v-model="item.pageid"
                    :helptext="!$v.item.pageid.required && submitted ? validationmessage.pageid: false"
                    :labelclass="{'validationerr': !$v.item.pageid.required && submitted}"
                    :classname="{'error': !$v.item.pageid.required && submitted, 'default': true}"
                  >Page Id</input-component>
                </div>
              </div>
            </div>
            <select-component
              class="txtgroup col-12"
              required="true"
              :helptext="!$v.item.posttype.required && submitted ? validationmessage.posttype: false"
              :labelclass="{'validationerr': !$v.item.posttype.required && submitted}"
              :inputclass="{'error': !$v.item.posttype.required && submitted, 'dropdownmenu': true}"
              :options="posttype"
              v-model="item.posttype"
              :multiple="false"
              :placeholder="selectplaceholder"
            >Post type</select-component>
            <div class="txtgroup col-12">
              <div class="inputtxt">
                <text-component>
                  Post Description
                  <sup>*</sup>
                </text-component>
              </div>
              <textarea
                class="txtarea"
                :class="{'error': !$v.item.post_description.required && submitted}"
                v-model="item.post_description"
              ></textarea>
              <span
                v-if="!$v.item.post_description.required && submitted"
                class="validationerr"
              >{{validationmessage.post_description}}
              </span>
            </div>
          </div>
          <hr class="hrline" />
          <div class="modal-footer">
            <div>
              <button-component buttontype="submit" class="btnprimary">Send Link</button-component>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /Modal -->
  </div>
</template>
<script>
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import TooltipComponent from "../../../BasicComponents/tooltip/tooltip.vue";
import BadgeComponent from "../../../BasicComponents/Badge/Badge.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import CampaignChat from "./campaignchat.vue";
import ProfileUploader from "./fileupload.vue";
import status from "./linkstatusbadge.vue";
import { mapGetters, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    TextComponent,
    CampaignChat,
    DatatableComponent,
    searchBar,
    ButtonComponent,
    TooltipComponent,
    SelectComponent,
    InputComponent,
    BadgeComponent,
    ProfileUploader,
  },
  computed: {
    ...mapGetters("brandcampaign", ["contentFormat", "influencerCategory"]),
    ...mapGetters("commonfunction", ["platformData"]),

  },
  watch: {
    influencerCategory: {
      immediate: true,
      handler(influencerCategory) {
        this.$data.influencecat = influencerCategory;
      },
    },
    platformData: {
      immediate: true,
      handler(platformData) {
        this.$data.socialplatform = platformData;
      },
    },
    item:{
      immediate:true,
      deep:true,
      handler(item){
        console.log('as',this.automatedPostAccounts);
        console.log('as',item.account);
        console.log('asd',this.automatedPostAccounts.indexOf(item.account))
        // console.log('item.account',item.account)
        if(item.account && this.automatedPostAccounts.indexOf(item.account)==-1){
          this.showScreenshotUpload = true
        }else{
          this.showScreenshotUpload = false
        }
      }
    }
  },
  validations: {
    item: {
      link: { required },
      account: { required },
      // postid: { required },
      pageid: {
        required: requiredIf(function () {
          return (this.isPageid && !this.showScreenshotUpload);
        }),
      },
      postid: {
        required: requiredIf(function () {
          return !this.showScreenshotUpload;
        }),
      },
      num_likes: {
        required: requiredIf(function () {
          return this.showScreenshotUpload;
        }),
      },
      num_views: {
        required: requiredIf(function () {
          return this.showScreenshotUpload;
        }),
      },
      posttype: { required },
      post_description: { required }
    },
  },
  data() {
    return {
      colors: ["#AAEEF6", "#FFB480", "#BDC5ED", "#FFE474", "#BBE5B3", "#FFC4BE"],
      item: {
        link: null,
        account: null,
        postid: null,
        pageid: null,
        posttype: null,
        num_likes: null,
        num_views: null,
        post_description: null
      },
      validationmessage: {
        link: "link is required ",
        account: "account is required ",
        postid: "post id is required",
        pageid: "page id is required",
        posttype: 'post type is required',
        num_likes: 'No. of likes is required',
        num_views: 'No. of views is required',
        post_description: 'post description is required'
      },
      imageurl: "usericon.png",
      fileuploadid: "profileuploader",
      submitted: false,
      isInvite: false,
      isPageid: false,
      selectplaceholder: "Select...",
      influenceStatus: "",
      h2txtclass: "h2",
      accountoption: [],
      posttype: [],
      selectedRows: [],
      badgeclass: null,
      avatar: null,
      filedata: null,
      badgetext: null,
      nputPlaceholder: "Search Influencer",
      type: "text",
      classname: "default prefixicon",
      filter: "",
      destructivecls: "btndestructive",
      primarycls: "btnprimary",
      page: 1,
      per_page: 8,
      inputPlaceholder: "Search Platform",
      request: {},
      selectedaccount: null,
      socialplatform: [],
      influencecat: [],
      columns: [
        {
          label: "Platform",
          field: "social_platform.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false,
        },
        {
          label: "Link",
          headerAlign: "left",
          align: "left",
          representedAs: (row) =>{
            return '<a href="'+row.link+'" style="text-decoration: none; color: #F49342;" target="_blank">'+row.link+'</a>';
          },
          // field: "link",
          sortable: false,
          interpolate: true,
        },
        // {
        //   label: "Status",
        //   field: "status",
        //   align: "left",
        //   headerAlign: "left",
        //   headerClass: "class-in-header second-class",
        //   sortable: false,
        // },
        { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
      ],
      rows: [],
      showScreenshotUpload:false,
      automatedPostAccounts:[],
      // automatedPostAccounts:[1,2,3,4],
    };
  },
  methods: {
    ...mapActions("influencercampaign", [
      "getsinglecamaigndetails",
      "getLinklist",
      "getVerifiedaccounts",
      "sendlink",
      "getInfluencerStatus",
      "getsocialaccountbyid"
    ]),
    ...mapActions("influencerconsent", ["getposttype"]),
    ...mapActions("brandcampaign", ["getinfluencercategory","getcontentformat"]),

    showPageid(data) {
      if (data == 3) {
        this.isPageid = true;
      } else {
        this.isPageid = false;
      }

      this.selectedaccount = data
    },

    getfiledata(e) {
      this.filedata = e.target.files[0];
      console.log(e.target.files[0]);
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.avatar = e.target.result;
      };
    },

    getposttypedata() {
      this.getposttype()
      .then((response) => {
        // this.posttype = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.posttype.push({"id":response.data[i].id, "label":response.data[i].name})
        }
      });
    },

    getcampaigninsights() {
      this.getLinklist({
        id: this.$route.params.id,
      }).then((response) => {
        this.$data.rows = response.data;
      });
    },

    getStatus() {
      this.getInfluencerStatus({
        id: this.$route.params.id,
      }).then((response) => {
        this.influenceStatus = response.data;
        console.log(this.influenceStatus);
      });
    },

    handleSendLink() {
      let vm = this;
      this.submitted = true;
      if (this.$v.item.$invalid) {
        this.$v.item.$touch();
        console.log('asd',this.$v.item)
      } else {
        const formData = new FormData();
        let attachment = this.filedata;
        console.log('attachment',attachment)
        formData.append("screenshot", attachment);
        let Data = {
          screenshotUpload: this.showScreenshotUpload,
          campaign_id: this.$route.params.id,
          plateform_id: this.item.account,
          num_likes: this.item.num_likes,
          num_views: this.item.num_views,
          post_id: this.item.postid,
          page_id: this.item.pageid,
          link: this.item.link,
          post_type: this.item.posttype,
          post_description: this.item.post_description,
        };
        console.log('Data',Data)
        formData.append("Data", JSON.stringify(Data));
        console.log('formData',formData)
        this.sendlink(formData)
          .then((response) => {
            vm.getcampaigninsights();
            this.isInvite = false;
            this.item.account = null;
            this.item.link = null;
            this.item.postid = null;
            this.item.pageid = null;
            this.item.num_likes = null;
            this.item.num_views = null;
            this.filedata = null;
            this.submitted = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          })
          .catch((err) => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top",
            });
          });
      }
    },

    getcampaigndetail() {
      this.getsinglecamaigndetails({
        id: this.$route.params.id,
      }).then((response) => {
        this.request = response.data;
        if(this.request.status == '1'){
            this.badgeclass = 'Success-Badge'
            this.badgetext = 'In Progress'
          }
          else if(this.request.status == '2'){
            this.badgeclass = 'Warning-Badge'
            this.badgetext = 'Rejected'
          }
          else if(this.request.status == '3'){
            this.badgeclass = 'Default-Badge'
            this.badgetext = 'Completed'
          }
          else{
            this.badgeclass = 'Attention-Badge'
            this.badgetext = 'Pending'
          }
      });
    },

    addLink() {
      this.getsocialaccountbyid({id: this.$route.params.id})
      .then((response) => {
        this.accountoption = this.setdata(response.data);
      });
      if (this.isInvite == true) {
        this.isInvite = false;
      } else {
        this.isInvite = true;
      }
    },

    setdata(data) {
      let newArray = [];
      data.map((value) => {
        newArray.push({
          id: value.id,
          label: value.name,
        });
      });
      return newArray;
    },

    openChat() {
      var x = document.getElementById("chatsection");
      x.classList.toggle("chatsize");
      x.style.transition = "all 0.5s ease-in-out";
    },

    getSocialPlatform(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.socialplatform.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    // getCategory(ids) {
    //   var id = ids.split(",");
    //   if(id.length <= 1){
    //     for (let i = 0; i < this.influencecat.length; i++) {
    //       console.log(this.influencecat[i])
    //     }
    //   }
    // },
    getCategory(ids) {
      if (ids) {
        let multiple = ids.split(",").map((x) => +x);
        let name = this.influencecat.filter((value) => {
          return multiple.indexOf(value.id) > -1;
        });
        let returnstring = [];
        for (let i = 0; i < name.length; i++) {
          returnstring.push(name[i].name);
        }
        return returnstring.toString();
      }
    },

    getContentFormat(id) {
      if (id) {
        let name = this.contentFormat.filter((value) => {
          return value.id == id;
        });
        if (name[0]) {
          return name[0].name;
        }
      }
    },
  },
  created() {
    // alert(Math.floor(Math.random() * 5))
    this.getcampaigndetail();
    this.getcampaigninsights();
    this.getStatus();
    this.getposttypedata();
    this.getinfluencercategory();
    this.getcontentformat();
  },
};
</script>

<style lang="scss" scoped>
.influencer-campaign-detail .datatable-main .table-header .link-right {
  float: right;
}
</style>