<template>
  <div class="roles">
    <div class="content-header">
      <label class="h2 header-content-left">Roles</label>
      <router-link to="createrole" v-if="checkPermission('roles_create')">
        <button-component class="header-content-right" :clsname="createuser">Create role</button-component>
      </router-link>
    </div>

    <div class="content">
      <div class="box">
        <div class="data-table-header">
          <div class="data-table-header-inner">
            <search-bar
              class="header-searchbar"
              :classname="classname"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              v-model="filter"
            ></search-bar>
            <status-dropdown
              class="header-status"
              :clsname="dropBtn"
              v-model="statusDrop"
              :popoverList="popoverList"
              :clearClass="clearClass"
              :btnText="dropdownText"
            >{{statusClear}}</status-dropdown>
            <!-- <sort-button
              :btnid="statusid"
              class="header-sortbutton"
              :popoverList="sortList"
              :clsname="clsname"
              :btnText="btnText"
            ></sort-button>-->
          </div>
        </div>
        <datatable-component
          class="dtable-main"
          :selected-rows="selectedRows"
          :actionid="actionid"
          :sortList="sortList"
          :columns="columns"
          :cboxindeterminatecls="cboxindeterminatecls"
          :actionText="actionText"
          :actionList="actionList"
          :rows="rows"
          :clsname="clsname"
          :btnText="btnText"
          :type="type"
          :inputPlaceholder="inputPlaceholder"
          :classname="classname"
          :dropBtn="dropBtn"
          :popoverList="popoverList"
          :dropdownText="dropdownText"
          :statusClear="statusClear"
          :clearClass="clearClass"
          :filter="filter"
          :page="page"
          :per_page="per_page"
          :no_data_msg="no_data_msg"
          @actiondrop="getactiondropvalue"
        ></datatable-component>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import SelectRow from "./selectrow.vue";
import SelectAll from "../../../../BasicComponents/Createaccount/selectall.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
// import sortButton from "../../BasicComponents/Dropdown/sortableDropdown.vue";
import statusDropdown from "../../../../BasicComponents/Dropdown/checkboxDropdown.vue";
import StatusBadge from "./rolestatusbadge.vue";
import { eventBus } from "../../../../main";
import { mapGetters, mapActions } from "vuex";
import ActionBtn from "./actionbtnconsent.vue";

export default {
  components: {
    ButtonComponent,
    DatatableComponent,
    // sortButton,
    searchBar,
    statusDropdown
  },
  computed: {
    ...mapGetters("user", ["rolesData"])
  },
  data() {
    return {
      page: 1,
      per_page: 10,
      createuser: "btnprimary",
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      inputPlaceholder: "Search role",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Status",
      statusClear: "Clear",
      clearClass: "btnplain",
      btninvitecls: "btnprimary",
      acname: "Mahnaz Farzin",
      myacimageurl: "alert.svg",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      no_data_msg: "Currently, No roles available.",
      selectedRows: [],
      columns: [
        // { label: "", component: SelectRow, headerComponent: SelectAll },
        {
          label: "Role ID",
          align: "left",
          headerAlign: "left",
          field: "id",
          sortable: true
        },
        {
          label: "Name",
          align: "left",
          headerAlign: "left",
          field: "role_name",
          sortable: false
        },
        {
          label: "Status",
          align: "left",
          headerAlign: "left",
          component: StatusBadge,
          sortable: false
        },
        {
          label: "Action",
          headerAlign: "right",
          align: "right",
          component: ActionBtn
        }
      ],
      rows: [
        //...
        {
          id: "1",
          user_id: "27834",
          name: "Dameon Peterson",
          status: "Active"
        },
        {
          id: "2",
          user_id: "65472",
          name: "Emmalynn Mazzia",
          status: "Active"
        },
        {
          id: "3",
          user_id: "58319",
          name: "Lumir Sacharov",
          status: "Inactive"
        },
        {
          id: "4",
          user_id: "52673",
          name: "Phawta Tuntayakul",
          status: "Active"
        }
        //...
      ],
      popoverList: [
        { id: "0", value: "Inactive" },
        { id: "1", value: "Active" },
      ],
      actionList: [
        // { id: "0", value: "Active" },
        // { id: "1", value: "Inactive" },
        // { id: "2", value: "Delete role" },
      ],
      sortList: [
        { id: "0", value: "User ID (ascending)" },
        { id: "1", value: "User ID (descending)" }
      ],

      apirows: [],
      statusDrop: [],
      selectedRowsIds: []
    };
  },
  watch: {
    statusDrop: {
      immediate: false,
      handler(statusDrop) {
        console.log("statusDrop", statusDrop);
        this.filterData();
      }
    },
    selectedRows: {
      immediate: false,
      handler(selectedRows) {
        // console.log('asdasdasdasdasdasdasdasd')
        let selectedRowsIds = [];
        selectedRows.forEach(row => {
          selectedRowsIds.push(row.id);
        });
        this.selectedRowsIds = selectedRowsIds;
        this.addSelectedIds(selectedRowsIds);
      }
    },

    selected_rows: function() {
      for (var row of this.rows) {
        // console.log(this.rows);
        if (this.selectedRows.indexOf(row) === -1) {
          return;
        }
      }
      console.log(this.selectedRows);

      eventBus.$emit("all-selected");
    },

    rolesData: {
      immediate: true,
      handler(rolesData) {
        this.$data.apirows = rolesData;
        this.filterData();
      }
    }
  },
  created: function() {
    if(!this.checkPermission('roles_delete')){
        this.columns.splice((this.columns.length-1), 1)
    }
    eventBus.$on(
      "selected",
      function(row) {
        // console.log(row);
        if (this.selectedRows.indexOf(row) === -1) {
          this.selectedRows.push(row);
        }
      }.bind(this)
    );

    eventBus.$on(
      "deselected",
      function(row) {
        if (this.selectedRows.indexOf(row) !== -1) {
          let index = this.selectedRows.indexOf(row);

          this.selectedRows.splice(index, 1);
        }
      }.bind(this)
    );

    eventBus.$on(
      "select-all",
      function(selected) {
        console.log(selected);
        this.selectedRows = this.rows.slice(0);
        // Vue.set(this, "selected_rows", this.rows.slice(0));
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function(selected) {
        console.log(selected);
        this.selectedRows = [];
        // Vue.set(this, "selected_rows", []);
      }.bind(this)
    );

    this.getrolesData();
    this.addSelectedIds([]);
  },

  methods: {
    ...mapActions("user", [
      "getrolesData",
      "addSelectedIds",
      "rolemultidelete",
      "roleinactive",
      "roleactive"
    ]),

    getactiondropvalue(value) {
      let vm = this;
      if (value.target.text == "Delete role") {
        this.rolemultidelete({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getrolesData();
        });
      } else if(value.target.text == "Inactive") {
        this.roleinactive({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getrolesData();
        });
      } else if(value.target.text == "Active") {
        this.roleactive({
          ids: this.selectedRowsIds
        }).then(() => {
          vm.getrolesData();
        });
      }
    },

    filterData: function() {
      // let filterStatus = this.activetab;
      let filterstatusDrop = this.statusDrop;
      // console.log("filter:",filterStatus);
      this.rows = this.apirows.filter(row => {
        let status = ["0","1", "2"];
        // let request_status = ["0", "1", "2"];
        // if (filterStatus == "Unpaid") {
        //   paid_status = ["0"];
        // } else if (filterStatus == "Paid") {
        //   paid_status = ["1"];
        // }
        if (!filterstatusDrop.length) {
          filterstatusDrop = ["0","1", "2"];
        }
        return (
          status.indexOf(row.status) != -1 &&
          // request_status.indexOf(row.status) != -1 &&
          filterstatusDrop.indexOf(row.status) != -1
        );
      });
    }
  }
};
</script>