<template>
    <div class="box margino box-first">
        <div class="box-content">
            <div class="row margino">
                <img v-if="profileData.profile_pic" :src="profileData.base_url + '/' + profileData.profile_pic" class="usericon" alt="user-image"/>
                <img v-else src="../../../../assets/image/usericon.png" class="usericon" alt="user-image"/>
                <text-component class="h4 username">{{profileData.full_name}}</text-component>
            </div>
            <div class="row margino personaldetail">
                <div class="col-8">
                    <div class="row margino">
                        <text-component class="personaldetail-header col-12 paddingo" :class="subtitle1class">Personal Details</text-component>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Full Name</text-component>
                            <text-component :class="'black'">{{profileData.full_name}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Gender</text-component>
                            <text-component :class="'black'">{{profileData.gender}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Date of Birth</text-component>
                            <text-component :class="'black'">{{formatDateGlobal(profileData.dob)}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Email Address</text-component>
                            <text-component :class="'black'">{{profileData.email}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Contact Number</text-component>
                            <text-component :class="'black'">{{profileData.prefix}} {{profileData.phone}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">Country</text-component>
                            <text-component :class="'black'">{{getcountryname(profileData.country)}}</text-component>
                            </div>
                        </div>
                        <div class="profile-detail-txt col-12 col-lg-6 col-xl-6">
                            <div class="row p-info">
                            <text-component :class="subtitle2class">City</text-component>
                            <text-component :class="'black'">{{getcityname(profileData.city)}}</text-component>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 interest-generes-section">
                    <div class="row margino">
                        <span class="subtitle1 col-12 paddingo">Interest/Genres</span>
                        <div>
                            <div v-if="isInterestdata.length <= 0">
                                <p style="text-align: center;">Currently no interest/genres are Added</p>
                            </div>
                            <ul v-else class="profile-interest">
                                <li  class="col-12 paddingo"
                                    v-for="(interest, index) in interestdatastring" 
                                    :key="index">{{interest.value}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
import { mapGetters,mapActions } from "vuex";

export default {
    components: {
        TextComponent,
    },
    data() {
        return {
            type: "text",
            subtitle2class: 'subtitle2',
            subtitle1class: 'subtitle1',
            profileData:[],
            country:[],
            city:[],


            tag: "",
            tags: [],
            listingdata: {},
            selectedval: [],
            isInterestdata: [],
            interestedit: [],
            interestdatastring: [],
        };
    },
    created: function() {
        this.getData();
         this.getinterestlisting();
        this.getinterestsbyid({id : this.$route.params.id});
    },
    computed: {
        // ...mapGetters("influencerprofile", ["profileData"]),
        ...mapGetters("commonfunction", ["countryData", "cityData"]),
        ...mapGetters("influencerprofile", ["interestData", "interestListing"]),
    },
    watch: {
        countryData: {
            immediate: true,
            handler(countryData) {
                this.$data.country = countryData;
                // console.log("country data"+ countryData)
            }
        },
        cityData: {
            immediate: true,
            handler(cityData) {
                this.$data.city = cityData;
                // console.log("city data"+ cityData)
            }
        },
        interestData: {
        immediate: true,
        deep: true,
        handler(interestData) {
            this.isInterestdata = interestData
            if(interestData) {
                this.$data.interestdatastring = this.getuserinterest(interestData);
                if (interestData.other_interest != null) {
                    this.tags = this.setedittags(interestData.other_interest);
                }
                if (interestData.interest) {
                    this.selectedval = interestData.interest.split(",");
                    this.$data.interestdatastring = this.getuserinterest(interestData);
                }
                }else {
                this.isInterestdata = [];
                }
            },
        },

        interestListing: {
        immediate: true,
            handler(interestListing) {
                // console.log("interest listing:", interestListing);
                this.listingdata = interestListing;
                this.$data.interestedit = this.getlisting(interestListing);
            },
        },
    },
    methods:{
        ...mapActions("admininfluencerprofile", ["getuserprofile"]),
        ...mapActions("commonfunction", ["getcity"]),
        ...mapActions("influencerprofile", ["getinterestsbyid","getinterestlisting" ]),
        getData(){
            this.getuserprofile({id:this.$route.params.id})
            .then(response=>{
                console.log(response);
                this.profileData = response.data
                this.getCityData(response.data.country)
            });
        },
        
        getCityData(id){
            this.getcity({id:id})
        },
        
        getcountryname(data) {
            let countryname = this.country.filter(value => {
                return value.id == data;
            });
            if (countryname.length) {
                return countryname[0].name;
            } else {
                return "";
            }
        },

        getcityname(data) {
            let cityname = this.city.filter(value => {
                return value.id == data;
            });
            if (cityname.length) {
                return cityname[0].name;
            } else {
                return "";
            }
        },

        setedittags(data) {
        // "text": "dcs", "tiClasses": [ "ti-valid" ] }
        let newarray = [];
            if (data) {
                data.map((value) => {
                newarray.push({
                    text: value,
                    tiClasses: ["ti-valid"],
                });
                });
                return newarray;
            } else {
                return newarray;
            }
        },

        getuserinterest(ids) {
        let valuearray = [];
            if (ids.other_interest != null) {
                ids.other_interest.map((value) => {
                valuearray.push({ value: value });
                });
            }
            if (ids.interest) {
                let tagids = ids.interest.split(",");
                // console.log("arrayid:", ids);
                for (let id in tagids) {
                // console.log("selectedid:", tagids[id])
                for (let data in this.listingdata) {
                    // console.log("listdata:", this.listingdata[data].id);
                    if (tagids[id] == this.listingdata[data].id) {
                    valuearray.push({
                        value: this.listingdata[data].interest,
                    });
                    }
                }
                }
            }
            return valuearray;
            },
        },

        getlisting(data) {
            let newarray = [];
            data.map((value) => {
                newarray.push({
                id: value.id,
                checkboxname: value.interest,
                });
            });
            return newarray;
        },
}
</script>