<template>
    <div>
        <badge class="badge" :badgeclass="StatusClass">{{StatusText}}</badge>
    </div>
</template>

<script>
import Badge from '../../../BasicComponents/Badge/Badge.vue';
export default {
    components: {
        Badge,
    },
    props: ['row'],
    watch:{
        row:{
            immediate:true,
            handler(row){
                let invdate = this.formatDateGlobal(row.updated_at)
                if (row.status=='1') {
                    this.StatusText ='In Progress';
                    this.StatusClass ='Success-Badge';
                }else if (row.status=='2') {
                    this.StatusText ='Paid ' + invdate.substring(0, 10);
                    this.StatusClass ='Default-Badge';
                }else{
                    this.StatusText ='Created';
                    this.StatusClass ='Attention-Badge';
                }
            }
        }
    },
    data() {
        return {
            StatusText:'created',
            StatusClass:'Attention-Badge'
        }
    },
    
}
</script>