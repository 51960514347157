<template>
    <div class="actionbtn">
        <button-component @onBtnClick="viewRating(row)" class="view-rating" :clsname="plaincls">View</button-component>
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import ButtonComponent from '../../../BasicComponents/Button/Button.vue';
export default {
  props: ["row"],
  components: {
    ButtonComponent
  },
  data() {
    return {
      plaincls: 'btnplain'
    };
  },
  methods: {
    viewRating(row) {
      this.$router.push({name: "brandratingdetail",params: { id: row.id }});
    }
  },
};
</script>
