<template>
  <div>
    <button-component @onBtnClick="handleApproveReject(row.id,1)" :clsname="btnprimary">Approve</button-component>
    <button-component @onBtnClick="handleApproveReject(row.id,2)" :clsname="btndestructive">Reject</button-component>
  </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnprimary: "btnprimary",
      btndestructive: "btndestructive"
    };
  },
  methods: {
    ...mapActions("admininfluencerAgencyaction", ["approveorrejectbrand"]),

    handleApproveReject(rowid,approved) {
      let vm = this;
      this.approveorrejectbrand({
        id: rowid,
        approved: approved.toString()
      })
        .then(response => {
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },

    // handleApprove(row) {
    //   let vm = this;
    //   this.approveorrejectbrand({
    //     id: row.id,
    //     approved: "1"
    //   })
    //     .then(response => {
    //       this.$toast.open({
    //         message: response.message,
    //         type: "success",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top"
    //       });
    //     })
    //     .catch(err => {
    //       this.$toast.open({
    //         message: err.response.data.message,
    //         type: "error",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top"
    //       });
    //     });
    // },

    // handleReject(row) {
    //   let vm = this;
    //   this.approveorrejectbrand({
    //     id: row.id,
    //     approved: "2"
    //   })
    //     .then(response => {
    //       this.$toast.open({
    //         message: response.message,
    //         type: "success",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top"
    //       });
    //     })
    //     .catch(err => {
    //       this.$toast.open({
    //         message: err.response.data.message,
    //         type: "error",
    //         duration: 5000,
    //         dismissible: true,
    //         position: "top"
    //       });
    //     });
    // }
  }
};
</script>