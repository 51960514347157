<template>
    <div>
        <section class="service-banner">
            <img src="../../../assets/image/front/Banner-Service.jpg">
        </section>

        <section class="service-m-img">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section5_image" :src="getImageURL(baseUrl,cms_image.section5_image)">
                        <img v-else src="../../../assets/image/front/InfluencerMarketing.png">
                    </div>
                    <div class="service-content col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="pl24 service-content-title pink">
                            <h1>{{cms_field.section5_title}}</h1>
                            <div v-html="cms_field.section5_description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="service-our-team">
            <div class="container">
                <div class="hiw-content service-cus-title">
                    <h1>{{cms_field.section1_title}}</h1>
                </div>
                <section class="top-second-img">
                    <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-7" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-cus-content">
                                <p>{{cms_field.section1_description}}</p>
                            </div>
                        </div>
                        <div class="service-img col-sm-12 col-md-12 col-lg-5" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section1_image" :src="getImageURL(baseUrl,cms_image.section1_image)">
                            <img v-else src="../../../assets/image/front/VidUnit_Service_Banner.jpg">
                        </div>
                    </div>
                    </div>
                </section>
                <div class="service-box">
                    <div class="row">
                        <div v-if="cms_field.section1_service1_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>1</h5>
                                <p>{{cms_field.section1_service1_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service2_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>2</h5>
                                <p>{{cms_field.section1_service2_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service3_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>3</h5>
                                <p>{{cms_field.section1_service3_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service4_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>4</h5>
                                <p>{{cms_field.section1_service4_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service5_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>5</h5>
                                <p>{{cms_field.section1_service5_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service6_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>6</h5>
                                <p>{{cms_field.section1_service6_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service7_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>7</h5>
                                <p>{{cms_field.section1_service7_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service8_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>8</h5>
                                <p>{{cms_field.section1_service8_title}}</p>
                            </div>
                        </div>
                        <div v-if="cms_field.section1_service9_title" class="emp-id col-sm-12 col-md-6 col-lg-4" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="service-team-box">
                                <h5>9</h5>
                                <p>{{cms_field.section1_service9_title}}</p>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </section>


        <section class="join-vidunit">
            <div class="container">
                <div class="row">
                    <div class="col-12 pink">
                        <h1>{{cms_field.section3_title}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 service-forward" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000" v-html="cms_field.section3_description">
                    </div>
                </div>

                <div class="border-section">
                    <div class="row">
                        <div class="service-join col-sm-12 col-md-6 col-lg-3 col-xl-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section3_1_image" :src="getImageURL(baseUrl,cms_image.section3_1_image)">
                            <img v-else src="../../../assets/image/front/InfluencerMarketing.png">
                            <h3>{{cms_field.section3_1_subtitle}}</h3>
                            <p>{{cms_field.section3_1_description}}</p>
                        </div>
                        <div class="service-join col-sm-12 col-md-6 col-lg-3 col-xl-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section3_2_image" :src="getImageURL(baseUrl,cms_image.section3_2_image)">
                            <img v-else src="../../../assets/image/front/InfluencerMarketing.png">
                            <h3>{{cms_field.section3_2_subtitle}}</h3>
                            <p>{{cms_field.section3_2_description}}</p>
                        </div>
                        <div class="service-join col-sm-12 col-md-6 col-lg-3 col-xl-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section3_3_image" :src="getImageURL(baseUrl,cms_image.section3_3_image)">
                            <img v-else src="../../../assets/image/front/InfluencerMarketing.png">
                            <h3>{{cms_field.section3_3_subtitle}}</h3>
                            <p>{{cms_field.section3_3_description}}</p>
                        </div>
                        <div class="service-join col-sm-12 col-md-6 col-lg-3 col-xl-3" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <img v-if="cms_image.section3_4_image" :src="getImageURL(baseUrl,cms_image.section3_4_image)">
                            <img v-else src="../../../assets/image/front/InfluencerMarketing.png">
                            <h3>{{cms_field.section3_4_subtitle}}</h3>
                            <p>{{cms_field.section3_4_description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="service-channel">
            <div class="container">
                <div class="row">
                    <div class="col-12 center-content white">
                        <h1>{{cms_field.section4_title}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="youtube-content-img col-sm-12 col-md-6 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section4_image" :src="getImageURL(baseUrl,cms_image.section4_image)">
                        <img v-else src="../../../assets/image/front/YouTube_Channel_Management.png">
                    </div>
                <div class="col-sm-12 col-md-6 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                    <div class="youtube-main-content" v-html="cms_field.section4_description"></div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section4_1_image" :src="getImageURL(baseUrl,cms_image.section4_1_image)">
                                <img v-else src="../../../assets/image/front/Channel_Development.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section4_1_subtitle}}</h3>
                                <p>{{cms_field.section4_1_description}}</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section4_2_image" :src="getImageURL(baseUrl,cms_image.section4_2_image)">
                                <img v-else src="../../../assets/image/front/Audience_Development.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section4_2_subtitle}}</h3>
                                <p>{{cms_field.section4_2_description}}</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                            <div class="youtube-service">
                            <div class="youtube-img">
                                <img v-if="cms_image.section4_3_image" :src="getImageURL(baseUrl,cms_image.section4_3_image)">
                                <img v-else src="../../../assets/image/front/Rights_Management.png">
                            </div>
                            <div class="Youtube-content">
                                <h3>{{cms_field.section4_3_subtitle}}</h3>
                                <p>{{cms_field.section4_3_description}}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section class="service-distribution-img">
            <div class="container">
                <div class="row">
                    <div class="service-content col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <div class="pl24 service-content-title pink">
                            <h1>{{cms_field.section2_title}}</h1>
                            <div v-html="cms_field.section2_description" ></div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6" data-aos="zoom-in-down" data-aos-once="true" data-aos-duration="1000">
                        <img v-if="cms_image.section2_image" :src="getImageURL(baseUrl,cms_image.section2_image)">
                        <img v-else src="../../../assets/image/front/Content_Acquisition.png">
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
import { mapActions,mapGetters } from "vuex";
export default {
    data() {
        return {
            baseUrl:null,
            cms_detail:{
                name:'Ourservice',
                meta_title:null,
                meta_keyword:null,
                meta_desc:'Ourservice',
            },
            cms_field:[],
            cms_image:[],
            testimonials:[],
        }
    },
    metaInfo() {
        return { 
            title: this.cms_detail?this.cms_detail.name:'Ourservice',
            meta: [
                { name: 'description', content:  (this.cms_detail)?this.cms_detail.meta_desc:''},
                { name: 'keyword', content:  (this.cms_detail.meta_keyword)?this.cms_detail.meta_keyword:''},
                { name: 'title', content:  (this.cms_detail.meta_title)?this.cms_detail.meta_title:''},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    mounted(){
        this.getData();
    },
    created(){
        this.getData();
    },
    computed: {
        ...mapGetters("frontcms", ["cmsDetailsOurService"]),
    },
    watch:{
        cmsDetailsOurService:{
            immediate:true,
            handler(cmsDetailsOurService){
                if (cmsDetailsOurService) {
                    this.baseUrl = cmsDetailsOurService.baseUrl;
                    this.cms_detail = cmsDetailsOurService.cms_detail;
                    this.cms_field = cmsDetailsOurService.cms_field;
                    this.cms_image = cmsDetailsOurService.cms_image;
                }
            }
        },
    },
    methods:{
        ...mapActions("frontcms",["getCmsData"]),
        getData(){
            this.getCmsData({slug:'our-service'});
        }
    }
}
</script>