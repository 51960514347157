<template>
  <div class="action-btn">
      <!-- <button-component @onBtnClick="agencyDetail(row.id)" :clsname="btnplain">Details</button-component> -->
      <maincategory-dropdown
            class="header-main-category inline-block"
            :clsname="'btnbasic'"
            v-if="checkPermission('publisher_action')"
            v-model="statusDrop"
            :popoverList="(row.admin_status==1)?dropCatList:dropCatListInactive"
            >Status</maincategory-dropdown>
  </div>
</template>
<script>
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import MaincategoryDropdown from "../../../../BasicComponents/Dropdown/dropdown.vue";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../../../main";
export default {
  components: {
    // ButtonComponent,
    MaincategoryDropdown
  },
  props: ["row"],
  data() {
    return {
      btnplain: "btnplain inline-block",
      statusDrop: null,
      dropCatList: [
        // { id: "1", value: "Active" },
        { id: "2", value: "Inactive" },
        { id: "3", value: "Delete" }
      ],
      allDropCatList: [
        { id: "1", value: "Active" },
        { id: "2", value: "Inactive" },
        { id: "3", value: "Delete" }
      ],
      dropCatListInactive: [
        { id: "1", value: "Active" },
        // { id: "2", value: "Inactive" },
        { id: "3", value: "Delete" }
      ]
    };
  },
  watch: {
    statusDrop: {
      immediate: false,
      handler(statusDrop) {
        if(this.statusDrop != null){
          let status = this.getIdByName(statusDrop);
          let id = this.row.id;
          this.handleAction(id, status);
        }
      }
    }
  },
  methods: {
    ...mapActions("admininfluenceraction", [
      "activeinactiveinfluencer"
    ]),
    // ...mapActions("adminpublisherdata", [
    //     "getNewRegistration",
    //     "getAllRegistration",
    //     "getRejectedRegistration",
    //     ]),

    getIdByName(name) {
      let dropdown = this.allDropCatList.filter(row => {
        return row.value == name;
      });
      if (dropdown.length) {
        return dropdown[0].id;
      } else {
        return "";
      }
    },

    agencyDetail(id){
      this.$router.push({name: "adminbranddetail",params: { id: id }});
    },

    handleAction(id, status) {
      this.statusDrop = null;
      // console.log('id, status',id, status)
      let vm = this;
      if(status==3){
        var deleterow = confirm("Are you sure to delete this user?");
        if(deleterow){
          this.activeinactiveinfluencer({
            id: id,
            status: status
          }).then(response => {
            // this.$root.$emit("approveorrejectclicked");
            // this.getinfluencerpending();
            // this.getRefreshData();
            // this.$emit('afterPublisherAction', row)
            eventBus.$emit("afterPublisherAction", this.row);
            // this.getinfluencerrejected();
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
        }
      }else{
        this.activeinactiveinfluencer({
          id: id,
          status: status
        }).then(response => {
        //   this.getRefreshData();
        //   this.$emit('afterPublisherAction', row)
          eventBus.$emit("afterPublisherAction", this.row);
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
      }
      // const vm = this;
    },

    // getRefreshData(){
    //     this.getAllRegistration().then((response) => {
    //         // this.$data.brandrows = response.data;
    //         // if(this.activetab == 'All Registration'){
    //         //     this.json_data = []
    //         //     for (let i = 0; i < this.brandrows.length; i++) {
    //         //         this.json_data.push(
    //         //             {"name": this.brandrows[i].name, "email": this.brandrows[i].email}
    //         //         )
    //         //     }
    //         // }
    //     });
    // }
  }
};
</script>