<template>
    <div class="calender-main">
        <calendar-view
            :show-date="showDate"
            class="theme-default calender"
            :events="events"
            :eventTop="eventheight"
            :eventContentHeight="eventContentHeight"
            @click-event="navigatePage">
            <calendar-view-header
                slot="header"
                slot-scope="t"
                :header-props="t.headerProps"
                @input="setShowDate" />
        </calendar-view>
    </div>
</template>
<script>
    export default {
        name: 'app',
        props: ["events","eventheight","eventContentHeight",'role'],
        data: function() {
            return { showDate: new Date(),
            }
        },
        methods: {
            setShowDate(d) {
                this.showDate = d;
            },
            navigatePage(event) {
                // console.log(event.id)
                if(this.role == "agency"){
                    this.$router.push({name: "agencycamapigndetail",params: { id: event.id }});
                }
                if(this.role == "admin"){
                    this.$router.push({name: "admincampaigndetail",params: { id: event.id }});
                }
                if(this.role == "brand"){
                    this.$router.push({name: "brandcampaigndetail",params: { id: event.id }});
                }
                if(this.role == "influencer"){
                    this.$router.push({name: "influencercampaigndetail",params: { id: event.id }});
                }
            }
        }
    }
</script>