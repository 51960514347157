<template>
  <div class="viewuser">
    <div class="content-header">
      <div class="back-path">
        <router-link class="remove-underline" :to="{name: 'User'}"><text-component :class="pathtxtclass">Users</text-component></router-link>
      </div>
      <div class="row">
        <div col-md-12>
          <text-component :class="headertxtclass" class="user_id">{{user.id}}</text-component>
          <span class="dark">{{user.name}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 edit-pencil">
          <span @click="edituser"><text-component class="edit" :class="pathtxtclass">Edit</text-component></span>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="box">
        <div class="box-header">
          <text-component :class="boxheaderclass">User information</text-component>
        </div>
        <div class="box-content">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="row row-padding">
                <div class="col-md-3 col-6">
                  <span class="dark">Full name</span>
                </div>
                <div class="col-md-9 col-6">
                  <span class="mid">{{user.name}}</span>
                </div>
              </div>
              <!-- <div class="row row-padding">
                <div class="col-md-3 col-6">
                  <span class="dark">Last name</span>
                </div>
                <div class="col-md-9 col-6">
                  <span class="mid">{{user.last_name}}</span>
                </div>
              </div> -->
              <div class="row row-padding">
                <div class="col-md-3 col-6">
                  <span class="dark">Email address</span>
                </div>
                <div class="col-md-9 col-6">
                  <span class="mid">{{user.email}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="row row-padding">
                <div class="col-md-3 col-6">
                  <span class="dark">Phone number</span>
                </div>
                <div class="col-md-9 col-6">
                  <span class="mid">{{user.prefix}} {{user.phone}}</span>
                </div>
              </div>
              <div class="row row-padding">
                <div class="col-md-3 col-6">
                  <span class="dark">Role</span>
                </div>
                <div class="col-md-9 col-6">
                  <span class="mid">{{user.role.role_name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <time-line :timeline="timeline"></time-line> -->
    </div>
  </div>
</template>

<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
// import TimeLine from "../../../../BasicComponents/Createaccount/timeline.vue";
import { mapGetters, mapActions } from "vuex";
import router from '../../../../routes/routes';
export default {
  components: {
    TextComponent,
    // TimeLine
  },
  computed: {
    ...mapGetters("user", ["singleData"])
  },
  // mounted: function () {
  //   this.singledata()
  // },
  created() {
    // console.log(this.$route.params.id);
    this.singledata();
  },

  methods: {
    ...mapActions("user", ["singleuserData"]),

    singledata() {
      this.singleuserData({
        id: this.$route.params.id
      }).then(response => {
        console.log(response);
        console.log("checking");
      });
    },

    edituser() {
      let userid = this.$route.params.id;
      router.push({name: 'EditUser', params: { id: userid }});
    }
  },

  watch: {
    singleData: {
      immediate: true,
      handler(singleData) {
        this.$data.user = singleData;
      }
    }
  },

  data() {
    return {
      user: { },
      pathtxtclass: "mid",
      headertxtclass: "h2",
      boxheaderclass: "subtitle1 dark",

      timeline: [
        {
          id: "0",
          msg: "You sent a direct request to Rudranyl Mukher",
          email: "rudranyl.m@gmail.com",
          dateTime: "April 6, 2020 at 9:19 am"
        },
        {
          id: "1",
          msg: "You sent a direct request to Rudranyl Mukher",
          email: "rudranyl.m@gmail.com",
          dateTime: "April 6, 2020 at 9:19 am"
        },
        {
          id: "2",
          msg: "You sent a direct request to Rudranyl Mukher",
          email: "rudranyl.m@gmail.com",
          dateTime: "April 6, 2020 at 9:19 am"
        },
        {
          id: "3",
          msg: "You sent a direct request to Rudranyl Mukher",
          email: "rudranyl.m@gmail.com",
          dateTime: "April 6, 2020 at 9:19 am"
        }
      ]
    };
  }
};
</script>