<template>
  <div>
    <label class="mid"><slot/></label>
    <toggle-button
      v-model="permission"
      :name="name"
      :width="37"
      :height="17"
      :color="{checked: '#F35F22', unchecked: '#C4CDD5'}"
      switch-color="#F9FAFB"
      :sync="true"
      :labels="false"
    />
  </div>
</template>

<script>
export default {
  props: ["value", "name"],
  data() {
    return {
      // permission: false
    };
  },
  computed: {
    permission: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  // watch: {
  //   permission() {
  //     this.$emit("input", this.permission);
  //   }
  // },
  // created: function () {
  //   this.permission = this.value;
  // }
};
</script>