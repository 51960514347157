<template>
    <div class="chat-detail">
        <!-- <div class="content-header">
            <text-component class="h2">Chat Details</text-component>
        </div> -->
        <div class="content paddingo">
            <div class="box margino">
                <div class="box-content">
                    <div class="sender-detail">
                        <div class="row margino">
                            <div class="paddingo sender-detail-main col-12">
                                <text-component class="subtitle2 sender-detail-title">Sender's Name</text-component>
                                <text-component class="sender-detail-txt">Lungelo Ngcaba</text-component>
                            </div>
                        </div>
                    </div>
                    <hr class="hr-divider"/>
                    <div class="message-section">
                        <div class="incoming">
                            <div class="user-img">
                                <img src="../../../../assets/image/usericon2.png" alt="user-img-main">
                            </div>
                            <div class="message-content">
                                <div class="user-name-message">
                                    <div class="user-name">
                                        <text-component class="subtitle2">Sachin Patel</text-component>
                                    </div>
                                    <div class="user-message">
                                        <text-component>Hello Text Message</text-component>
                                    </div>                            
                                </div>
                                <div class="message-time">
                                    <text-component class="mid">30-July-2020 20:00</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="incoming">
                            <div class="user-img">
                                <img src="../../../../assets/image/usericon2.png" alt="user-img-main">
                            </div>
                            <div class="message-content">
                                <div class="user-name-message">
                                    <div class="user-name">
                                        <text-component class="subtitle2">Sachin Patel</text-component>
                                    </div>
                                    <div class="user-message">
                                        <text-component>Hello Text Message</text-component>
                                    </div>                            
                                </div>
                                <div class="message-time">
                                    <text-component class="mid">30-July-2020 20:00</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="incoming">
                            <div class="user-img">
                                <img src="../../../../assets/image/usericon2.png" alt="user-img-main">
                            </div>
                            <div class="message-content">
                                <div class="user-name-message">
                                    <div class="user-name">
                                        <text-component class="subtitle2">Sachin Patel</text-component>
                                    </div>
                                    <div class="user-message">
                                        <text-component>Hello Text Message</text-component>
                                    </div>                            
                                </div>
                                <div class="message-time">
                                    <text-component class="mid">30-July-2020 20:00</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="incoming">
                            <div class="user-img">
                                <img src="../../../../assets/image/usericon2.png" alt="user-img-main">
                            </div>
                            <div class="message-content">
                                <div class="user-name-message">
                                    <div class="user-name">
                                        <text-component class="subtitle2">Sachin Patel</text-component>
                                    </div>
                                    <div class="user-message">
                                        <text-component>Hello Text Message</text-component>
                                    </div>                            
                                </div>
                                <div class="message-time">
                                    <text-component class="mid">30-July-2020 20:00</text-component>
                                </div>
                            </div>
                        </div>
                        <div class="incoming">
                            <div class="user-img">
                                <img src="../../../../assets/image/usericon2.png" alt="user-img-main">
                            </div>
                            <div class="message-content">
                                <div class="user-name-message">
                                    <div class="user-name">
                                        <text-component class="subtitle2">Sachin Patel</text-component>
                                    </div>
                                    <div class="user-message">
                                        <text-component>Hello Text Message</text-component>
                                    </div>                            
                                </div>
                                <div class="message-time">
                                    <text-component class="mid">30-July-2020 20:00</text-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="send-message box margino" id="sendmsg">
                <textarea></textarea>
                <img src="../../../../assets/image/send.svg" alt="send-icon" class="send-img">
            </div>
        </div>
    </div>
</template>
<script>
import TextComponent from "../../../../BasicComponents/Text/Text.vue"
export default {
    components: {
        TextComponent
    }
}
</script>