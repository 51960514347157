<template>
  <div>
    <button-component 
      @onBtnClick="handleApprove(row)" 
      :clsname="btnprimary"
      v-if="row.status == '3' || row.status == '4' || row.status == ''">
      Approve
    </button-component>
    <button-component 
      @onBtnClick="handleReject(row)" 
      :clsname="btndestructive"
      v-if="row.status == '3' || row.status == '4' || row.status == ''">
        Reject
      </button-component>
    <button-component 
      @onBtnClick="handleImprovement(row)" 
      :clsname="'btnbasic'"
      v-if="row.status == '3' || row.status == '4' || row.status == ''">
        Need Improvement
      </button-component>
    <button-component 
      @onBtnClick="handleView(row)" 
      :clsname="'btnplain'">
        View
      </button-component>
    <button-component 
    @onBtnClick="handleTraffic(row)" 
    :clsname="'btnplain'"
    v-if="row.status == '1'">
      Website Traffic
    </button-component>
  </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnprimary: "btnprimary",
      btndestructive: "btndestructive"
    };
  },
  methods: {
    ...mapActions("publisherdata", [
      "requestedarticlestatus",
    ]),

    handleApprove(row) {
      let vm = this;
      this.requestedarticlestatus({
        id: row.id,
        status: "1"
      })
        .then(response => {
          this.$root.$emit('statuschanged');
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },

    handleReject(row) {
      let vm = this;
      this.requestedarticlestatus({
        id: row.id,
        status: "2"
      })
        .then(response => {
          this.$root.$emit('statuschanged');
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },

    handleImprovement(row) {
      let vm = this;
      this.requestedarticlestatus({
        id: row.id,
        status: "3"
      })
        .then(response => {
          this.$root.$emit('statuschanged');
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    },
    
    handleView(row) {
      this.$root.$emit('onView', row);
    },

    handleTraffic(row) {
      this.$root.$emit('gettraffic');
    }
  }
};
</script>