import Vue from 'vue'
import moment from "moment";
import router from "../routes/routes";

Vue.mixin({
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    formatDateGlobal: (value) => {
      if (value) {
        if (value.length < 11) {
          let newDate = moment(new Date(value))
          let momentDate = moment(newDate);
          return momentDate.format("DD-MM-YYYY");
        } else {
          let newDate = moment(new Date(value)).add(new Date().getTimezoneOffset() * -1, "m").toDate();
          let momentDate = moment(newDate);
          return momentDate.format("DD-MM-YYYY hh:mm A");
        }
      }
    },

    dateWithUserTimeStamp: (value) => {
      if (value) {
        if (value.length < 11) {
          let newDate = moment(new Date(value))
          let momentDate = moment(newDate);
          return momentDate.format("YYYY-MM-DD");
        } else {
          let newDate = moment(new Date(value)).add(new Date().getTimezoneOffset() * -1, "m").toDate();
          let momentDate = moment(newDate);
          return momentDate.format("YYYY-MM-DD hh:mm:ss");
        }
      }
    },

    setDateFn: function(getdate,type=""){
      let momentDate = moment(getdate);
      return momentDate.format("YYYY-MM-DD");
    },
    getImageURL: function(baseUrl,ImageName){
      return baseUrl+'/'+ImageName;
    },

    timeformessage: (value) => {
      if (value) {
        let startTime = moment(new Date()); //todays date
        let newDate = moment(new Date(value)).add(new Date().getTimezoneOffset() * -1, "m")
        var duration = moment.duration(newDate.diff(startTime));
        let days = duration.asDays();
        days = parseInt(days, 10)*-1;
        if (!days) {
          let hours = duration.asHours();
          hours = parseInt(hours, 10) * -1;
          if (!hours) {
            let mins = duration.asMinutes();
            mins = parseInt(mins, 10) * -1;
            if (!mins) {
              return 'a moment ago';
            }else{
              return mins == 1 ? "a minute ago" : mins + " minutes ago";
            }
          }else{
            return hours == 1 ? "an hour ago" : hours + " hours ago";
          }
        }else{
          let momentDate = moment(newDate);
          return momentDate.format("DD-MM-YYYY hh:mm A");
        }
      }
    },

    gotoRoute: (name,params='') => {
      router.push({ name: name, params: params });
    },
  },
});
