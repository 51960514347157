<template>
  <div class="brand myconsent campaign">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">Campaigns</text-component>
        <div class="page-header-right">
          <button-component v-if="checkPermission('campaign_create')" @onBtnClick="createCampaign" :clsname="btninvitecls">Create Campaign</button-component>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box paddingo margino">
        <div class="box-content">
          <div class="datatable-main">
            <div class="data-table-header">
              <div class="data-table-header-inner">
                <search-bar
                  class="header-searchbar"
                  :classname="classname"
                  :type="type"
                  :inputPlaceholder="inputPlaceholder"
                  v-model="filter"
                ></search-bar>
                <maincategory-dropdown
                  class="header-main-category"
                  :clsname="dropCat"
                  v-model="statusDrop"
                  :popoverList="dropCatList"
                >Status</maincategory-dropdown>
              </div>
            </div>
            <datatable-component
              class="dtable-main"
              :selected-rows="selectedRows"
              :actionid="actionid"
              :columns="columns"
              :cboxindeterminatecls="cboxindeterminatecls"
              :actionText="actionText"
              :rows="rows"
              :clsname="clsname"
              :btnText="btnText"
              :type="type"
              :inputPlaceholder="inputPlaceholder"
              :classname="classname"
              :dropBtn="dropBtn"
              :dropdownText="dropdownText"
              :statusClear="statusClear"
              :clearClass="clearClass"
              :filter="filter"
              :page="page"
              :per_page="per_page"
              @onrowenter="onEnter"
              @onrowleave="onLeave"
            ></datatable-component>
          </div>
        </div>
      </div>
    </div>
    <popup-page :editelement="editelement" :copyelement="copyelement"></popup-page>
  </div>
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
// import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
// import SelectComponent from "../../../BasicComponents/select/select.vue";
import MaincategoryDropdown from "../../../BasicComponents/Dropdown/dropdown.vue";
import status from "./statusbadge.vue";
import edit from "./edit.vue";
import { eventBus } from "../../../main";
import copy from "./copy.vue";
import action from "./action.vue";
// import VueTagsInput from '@johmun/vue-tags-input';
import PopupPage from "./popup.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    ButtonComponent,
    searchBar,
    MaincategoryDropdown,
    // InputComponent,
    // SelectComponent,
    // VueTagsInput,
    PopupPage
  },
  computed: {
    ...mapGetters("brandcampaign", ["campaignData"]),
  },
  watch: {
    campaignData: {
      immediate: true,
      handler(campaignData) {
        this.$data.datarows = campaignData;
        this.filterData();
        // this.$data.rows = campaignData;
      }
    },
    statusDrop: {
      immediate: true,
      handler(statusDrop) {
        this.filterData();
      }
    },
  },
  data() {
    return {
      statusDrop: null,
      editelement: "0",
      copyelement: "0",
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      inputPlaceholder: "Search Campaign Name",
      classname: "default prefixicon",
      defaultclassname: "default",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      btninvitecls: "btnprimary",
      acname: "Mahnaz Farzin",
      myacimageurl: "alert.svg",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      editable: false,
      selectplaceholder: "Select...",
      filter: "",
      selected: "",
      row: "",
      h2txtclass: "h2",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      page: 1,
      per_page: 8,
      selectedRows: [],
      columns: [
        {
          label: "Campaign Name",
          field: "campaign_name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class width-20",
          sortable: false
        },
        {
          label: "Brand Agency",
          field: "user.name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class width-20",
          sortable: false
        },
        // {
        //   label: "Description",
        //   field: "discription",
        //   align: "left",
        //   headerAlign: "left",
        //   sortable: false
        // },
        {
          label: "Budget",
          field: "budget",
          align: "left",
          headerAlign: "left",
          headerClass: "width-10",
          sortable: false
        },
        { label: "Status", headerClass:"width-10", headerAlign: "left", component: status },
        { label: "", headerAlign: "left",headerClass:"width-5", align:"right", component: edit },
        { label: "", headerAlign: "left",headerClass:"width-5",align:"right", component: copy },
        { label: "Action",headerClass:"width-5", align:"left", headerAlign: "left", component: action }
      ],
      rows: [],
      datarows: [],
      dropCatList: [
        { id: "4", value: "All" },
        { id: "0", value: "Pending" },
        { id: "1", value: "In Progress" },
        { id: "2", value: "Rejected" },
        { id: "3", value: "Completed"}
      ]
    };
  },

  methods: {
    ...mapActions("brandcampaign", ["getcampaign"]),

    onEnter(row) {
      // console.log(row);
      row.editable = "true";
    },
    onLeave(row) {
      // console.log(row);
      row.editable = "false";
    },
    createCampaign() {
      this.editelement = "0";
      this.copyelement = "0";
      this.$root.$emit("openPopup");
    },

    filterData(){
      let status = this.statusTextToId(this.statusDrop);
      if (status) {
        let filterData = this.datarows.filter(row=>{
          return row.status == status
        });
        this.rows = filterData;
      }else{
        this.rows = this.datarows;
      }
    },

    statusTextToId(statusText){
      if (statusText=="Pending") {
        return '0';
      }else if (statusText=="In Progress") {
        return '1';
      }else if (statusText=="Rejected") {
        return '2';
      }else if (statusText=="Completed") {
        return '3';
      }else{
        return "";
      }
    }

  },
  created: function() {
    if(!this.checkPermission('campaign_action')){
      // this.columns[this.columns.length-1].show = false
      this.columns.splice((this.columns.length-1), 1)
    }
    this.$root.$on("getEditData", (row) => {
      this.editelement = row.id;
      this.$root.$emit("openPopup",row,'edit');
    });

    this.$root.$on("getCopyData", (row) => {
      this.copyelement = row.id;
      this.$root.$emit("openPopup",row,'copy');
    });

    eventBus.$on("onrowclick",function(row) { 
        console.log("S")
    }.bind(this)
    );

    this.getcampaign();
  },
  beforeDestroy() {
    this.$root.$off("getCopyData", this.fn);
    this.$root.$off("getEditData", this.fn);
    this.$root.$off("onrowclick", this.fn);
  }
};
</script>
