<template>
    <div class="actionbtn" v-if="checkPermission('genre_delete')">
        <img src="../../../../assets/image/delete.svg" @click="clickEvent" alt="delete-image">
    </div>
</template>

<script>
// import { eventBus } from "../../main";
import { mapActions } from "vuex";
export default {
  props: ["row"],
  components: {
    
  },
  data() {
    return {

    };
  },
  methods: {
    ...mapActions("admingenre", ["getgenres", "deletegenre"]),

    clickEvent: function() {
      var deleterow = confirm("Are you sure?");
      if(deleterow){
        let vm = this;
        let id = [];
        id.push(this.row.id);
        this.deletegenre({ ids: id })
          .then(response => {
            console.log(response);
            vm.getgenres();
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },
  },
};
</script>
