<template>
    <div class="content-list admin-content-list requested-article-list">
        <div class="content-header">
            <span class="h2">Article List</span>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                            <search-bar
                                class="header-searchbar"
                                :classname="classname"
                                :type="type"
                                :inputPlaceholder="inputPlaceholder"
                                v-model="filter"
                            ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isView}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="onPreview" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <span class="subtitle1">Article Name :</span>&nbsp;&nbsp;&nbsp;
                    <span v-if="viewdata">{{viewdata.article_name}}</span><br/>
                    <span class="subtitle1">Content Data</span>
                    <div v-if="viewdata" v-html="viewdata.content"></div>
                </div>
            </div>
        <!-- /Modal -->
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isTrafficView}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="onTrafficPreview" src="../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="row margino">
                        <span class="h2">Website Traffic</span>
                    </div>
                    <div v-if="articletrafficdata == ''" class="row margino" style="text-align:center">
                        <span class="subtitle1">Website traffic data is not avaliable.</span>
                    </div>
                    <div v-else class="row margino">
                        <div class="col-12 col-lg-6 paddingo traffic-data" v-if="articletrafficdata.web_name">
                            <span class="subtitle2">Website Name</span>
                            <span class="black">{{articletrafficdata.web_name}}</span>
                        </div>
                         <div class="col-12 col-lg-6 paddingo traffic-data" v-if="articletrafficdata.type">
                            <span class="subtitle2">Website Type</span>
                            <span class="black">{{articletrafficdata.type}}</span>
                        </div>
                        <div class="col-12 col-lg-6 paddingo traffic-data" v-if="articletrafficdata.traffic_data">
                            <span class="subtitle2">Traffic Data</span>
                            <span class="black">{{articletrafficdata.traffic_data}}</span>
                        </div>
                        <div class="col-12 col-lg-6 paddingo traffic-data" v-if="articletrafficdata.traffic_data">
                            <span class="subtitle2">URL</span>
                            <a :href="articletrafficdata.url" target="_blank"><span class="black">{{articletrafficdata.url}}</span></a>
                        </div>
                        <div class="traffic-data col-12 paddingo">
                            <a :href="articletrafficdata.baseUrl+ '/' + articletrafficdata.id + '/' + articletrafficdata.image"
                                target="_blank">
                                <img
                                v-if="articletrafficdata.image"
                                :src="articletrafficdata.baseUrl+ '/' + articletrafficdata.id + '/' + articletrafficdata.image" 
                                alt="traffic-image"
                                width="100px"
                                height="100px">
                            </a>
                        </div>
                        <img
                            v-if="!articletrafficdata.image"
                            src="../../../assets/image/usericon.png" 
                            alt="traffic-image">
                    </div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import StatusBadge from "./requestedarticlestatus.vue";
// import EditBtn from "./websitetrafficediticon.vue";
import Action from "./requestedarticleaction.vue";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        DatatableComponent,
        searchBar,
        // ButtonComponent
    },
    data() {
        return {
            type: "text",
            inputPlaceholder: "Search Content Name",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            isView: false,
            rowviewdata: [],
            viewdata: null,
            articletrafficdata: null,
            isTrafficView: false,
            columns: [
                {
                    label: "Article Name",
                    field: "article_name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Topic",
                    field: "article_request.topic",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "Status", headerAlign: "left", align: "right", component: StatusBadge },
                // { label: "", headerAlign: "left", align: "right", component: EditBtn },
                { label: "Action", headerAlign: "left", align: "left", component: Action },
            ],
            rows: [
                // {
                //     website_name: 'Google',
                //     url: 'https://www.google.com/',
                //     status: '1'
                // },
                // {
                //     website_name: 'Facebook',
                //     url: 'https://www.facebook.com/',
                //     status: '0'
                // },
            ],
        };
    },
    methods: {
        ...mapActions("publisherdata", ["requestedarticle", "articletraffic"]),

        requestedarticledata() {
            this.requestedarticle({id: this.$route.params.id})
            .then(response=>{
                this.rows = response.data
            });
        },

        onPreview() {
            if(this.isView == false){
               this.isView = true
            }else{
                this.isView = false
            }
        },

        onTrafficPreview() {
            if(this.isTrafficView == false){
               this.isTrafficView = true
            }else{
                this.isTrafficView = false
            }
        }
    },
    created() {
        this.requestedarticledata()
        this.articletraffic({id: this.$route.params.id})
            .then(response => {
                this.articletrafficdata = response.data
                
            })
        this.$root.$on("onView", (row) => {
            this.viewdata = row
            this.onPreview()
        });
        this.$root.$on("statuschanged", () => {
            this.requestedarticledata()
        });
        this.$root.$on("gettraffic", () => {
            this.onTrafficPreview()
        });
    }
}
</script>