<template>
  <div v-if="checkPermission('consent_action')">
    <button-component @onBtnClick="onClick(row)" :clsname="btnprimary">Approve</button-component>
    <button-component @onBtnClick="handleReject(row)" :clsname="btndestructive">Reject</button-component>
    <button-component @onBtnClick="goToInfluencer(row.user_id)" :clsname="'btnplain'">Details</button-component>
  </div>
</template>
<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ButtonComponent
  },
  props: ["row"],
  data() {
    return {
      btnprimary: "btnprimary",
      btndestructive: "btndestructive"
    };
  },
  methods: {
    ...mapActions("adminconcentaction", [
      "pendingConcentData",
      "approveorrejectconcent",
      "approvedConcentData",
      "rejectedConcentData"
    ]),

    goToInfluencer(id) {
      this.$router.push({name: "admininfluencerdetail",params: { id: id }});
    },

    onClick(row) {
      this.$root.$emit('onApprove', this.row)
    },

    handleReject(row) {
      let vm = this;
      this.approveorrejectconcent({
        id: row.id,
        status: "2"
      })
        .then(response => {
          // vm.pendingConcentData();
          // vm.approvedConcentData();
          // vm.rejectedConcentData();
          this.$toast.open({
            message: response.message,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        })
        .catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "top"
          });
        });
    }
  }
};
</script>