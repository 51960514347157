<template>
    <div class="content-list website-traffic">
        <div class="content-header">
            <span class="h2">Website Traffic</span>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="row margino">
                        <select-component
                            class="txtgroup col-12 col-md-6"
                            required="true"
                            :helptext="!$v.item.requesttopic.required && submitted ? validationmessage.requesttopic: false"
                            :labelclass="{'validationerr': !$v.item.requesttopic.required && submitted}"
                            :inputclass="{'error': !$v.item.requesttopic.required && submitted, 'dropdownmenu': true}"
                            :options="requesttopicoptions"
                            v-model="item.requesttopic"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                        >Article</select-component>
                        <input-component  
                            class="txtgroup col-12 col-md-6"
                            required="true"
                            :helptext="!$v.item.web_site.required && submitted ? validationmessage.web_site: false"
                            :labelclass="{'validationerr': !$v.item.web_site.required && submitted}"
                            :classname="{'error': !$v.item.web_site.required && submitted, 'default': true}"
                            v-model="item.web_site">
                            Web Name
                        </input-component>
                        <input-component  
                            class="txtgroup col-12 col-md-6"
                            required="true"
                            :helptext="!$v.item.url.required && submitted ? validationmessage.url: false"
                            :labelclass="{'validationerr': !$v.item.url.required && submitted}"
                            :classname="{'error': !$v.item.url.required && submitted, 'default': true}"
                            v-model="item.url">
                            URL
                        </input-component>
                        <input-component  
                            class="txtgroup col-12 col-md-6"
                            required="true"
                            :helptext="!$v.item.site_type.required && submitted ? validationmessage.site_type: false"
                            :labelclass="{'validationerr': !$v.item.site_type.required && submitted}"
                            :classname="{'error': !$v.item.site_type.required && submitted, 'default': true}"
                            v-model="item.site_type">
                            Type of Website
                        </input-component>
                        <!-- <select-component
                            class="txtgroup col-12 col-md-6"
                            :options="websiteoption"
                            :multiple="false"
                            :placeholder="selectplaceholder"
                            required="true"
                            :helptext="!$v.item.site_type.required && submitted ? validationmessage.site_type: false"
                            :labelclass="{'validationerr': !$v.item.site_type.required && submitted}"
                            :inputclass="{'error': !$v.item.site_type.required && submitted, 'dropdownmenu': true}"
                            v-model="item.site_type">
                            Type of Website
                        </select-component> -->
                        <input-component  
                            class="txtgroup col-12 col-md-6"
                            :classname="{'default': true}"
                            v-model="item.traffic_data">
                            Traffic Data
                        </input-component>
                        <div class="col-12">
                            <file-uploader 
                                :imageurl="imageurl" 
                                :fileuploadid="'websitetrafficuploader'"
                                :avatar="avatar"
                                @selectfile="selectfile"></file-uploader>
                        </div>
                    </div>
                    <hr class="hrline"/>
                    <div class="content-publish-footer">
                        <button-component @onBtnClick="createContent" :clsname="btninvitecls">Submit</button-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import SelectComponent from "../../../BasicComponents/select/select.vue";
import FileUploader from "./websitetrafficupload.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        ButtonComponent,
        InputComponent,
        FileUploader,
        SelectComponent
    },
    validations: {
        item: {
            web_site: { required },
            url: { required },
            site_type: { required },
            // traffic_data: { required },
            requesttopic: { required }
        },
    },
    data() {
        return {
            btninvitecls: "btnprimary",
            item: {
                web_site: null,
                url: null,
                site_type: null,
                traffic_data: null,
                requesttopic: null
            },
            validationmessage: {
                web_site: "content name id is required",
                url: "URL is required",
                site_type: "type of site is required",
                traffic_data: 'traffic data is important',
                requesttopic: "request topic is required"
            },
            submitted: false,
            selectplaceholder: 'Select...',
            websiteoption: [
                {id:'1', label:'food'},
                {id:'2', label:'fashion'},
                {id:'3', label:'cars'},
                {id:'4', label:'travel'},
            ],
            imageurl: "upload.svg",
            avatar: null,
            requesttopicoptions: [],
            filedata: ""

        };
    },
    methods: {
        ...mapActions("publisherdata", ["createwebsitelist", "approvedarticle"]),


        createContent() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                console.log(this.item)
                const formData = new FormData();
                let attachment = this.filedata;
                let Data = {
                    web_name: this.item.web_site,
                    url: this.item.url,
                    type: this.item.site_type,
                    traffic_data: this.item.traffic_data,
                    article_id: this.item.requesttopic,
                };
                formData.append("Data", JSON.stringify(Data));
                formData.append("image", attachment);
                this.createwebsitelist(formData)
                .then(response => {
                    this.submitted =false
                    this.$router.push({ name: "publisherwebsitetrafficlist"});
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        selectfile(e) {
            try{
                this.filedata = e.target.files[0]
                let image = e.target.files[0]
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.avatar = e.target.result
                }
            }
            catch(e)
            {
                console.log("Please select a valid file")
            }
        }
    },
    created() {
        this.approvedarticle()
        .then(response=>{
            console.log(response.data)
            for (let i = 0; i < response.data.length; i++) {
                this.requesttopicoptions.push({'id':response.data[i].id, 'label':response.data[i].article_name})
            }
        });
    }

}
</script>