<template>
    <div class="menudesktop">
        <div class="searchvendor-content-left smallMenu" id="desktopmenu">
          <div class="menu-inner">
            <div class="main-logo" id="mlogo">
              <img src="../assets/image/main-logo.svg" alt="VidUnit-logo" class="vidunitlogo"/>
            </div>
            <navigation-component :navdata="navdata"></navigation-component>
          </div>
        </div>
    </div>
</template>

<script>
import NavigationComponent from "../BasicComponents/Navigation/Navigation";
export default {
  components: {
    NavigationComponent
  },

  data() {
    return {
      navdata: [
        {
          id: "1",
          item: "Dashboard",
          imgpath: "dashboard.svg",
          name: "branddashboard"
        },
        {
          id: "2",
          item: "Report",
          imgpath: "report.svg",
          child: [
            { 
              id: "3", 
              item: "Campaign Report", 
              name: "brandcampaignreport" 
            },
            // { 
            //   id: "4", 
            //   item: "Influencer Report", 
            //   name: "brandinfluencerreport" 
            // },
          ]
        },
        {
          id: "5",
          item: "Campaigns",
          imgpath: "campaign.svg",
          name: "brandcampaigns"
        },
        {
          id: "6",
          item: "Influencer",
          imgpath: "rating.svg",
          name: "brandinfluencerdata"
        },
        {
          id: "7",
          item: "Rating",
          imgpath: "rating.svg",
          name: "brandrating"
        },
        {
          id: "87",
          item: "Support",
          imgpath: "chat.svg",
          name: "brandsupport"
        },
      ]
    };
  },
};
</script>
