<template>
    <button :type="buttontype?buttontype:'button'" :class="clsname" :disabled="isDisabled" @click="btnClick" value="button"><slot/></button> 
</template>



<script>
export default {
    name : "Button",
    props: ["clsname","isDisabled","buttontype"],
    data: function() {
        return{
            btnvalue:null
        }
    },
    methods: {
        btnClick: function(value) {
            this.$emit('onBtnClick',value)
        }
    }
}; 
</script>

<style scoped>


</style>