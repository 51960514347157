<template>
  <div class="actionbtn">
    <img src="../../../assets/image/delete.svg" alt="delete-image" @click="clickEvent" />
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapActions } from "vuex";
// import router from "../../router/index";
export default {
  props: ["row"],
  data() {
    return {
      selected: false,
      totalSelected: 0
    };
  },
//   watch: {
//     row: {
//       immediate: true,
//       handler(row) {
//         this.checkboxName = "cbstar[" + row.id + "]";
//         if (this.favIds.indexOf(row.id.toString()) != -1) {
//           this.selected = true;
//         } else {
//           this.selected = false;
//         }
//       }
//     }
//   },
  methods: {
    ...mapActions("manageinfluencer", ["deletecategoryinfluencer", "getregisteredinfluencer"]),

    onTotalLength: function() {
      console.log("asdas");
      var i;
      if (this.totalSelected) {
        console.log("asdas", this.totalSelected);
        let thead = document.getElementsByTagName("thead");
        for (i = 0; i < thead.length; i++) {
          thead[i].style.visibility = "hidden";
        }
      } else {
        let thead = document.getElementsByTagName("thead");
        for (i = 0; i < thead.length; i++) {
          thead[i].style.visibility = "visible";
        }
      }
    },
    clickEvent: function() {
       var deleterow = confirm("Are you sure?");
      if(deleterow){
        let vm = this;
        let id = [];
        id.push(this.row.id);
        this.deletecategoryinfluencer({ ids: id })
          .then(response => {
            console.log(response);
            vm.getregisteredinfluencer();
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },
    emitSelected: function() {
      if (this.selected) {
        eventBus.$emit("selectedfav", this.row);
      } else {
        eventBus.$emit("deselectedfav", this.row);
      }
    }
  },
  created: function() {
    eventBus.$on(
      "select-all",
      function(selected) {
        console.log("selected", selected);
        this.selected = true;
      }.bind(this)
    );

    eventBus.$on(
      "deselect-all",
      function(selected) {
        console.log("selected", selected);
        this.selected = false;
      }.bind(this)
    );
  }
};
</script>