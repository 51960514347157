<template>
  <div class="auth-layout">
    <div class="container-fluid verify-background">
      <div class="VidUnit-logo">
        <router-link :to="{name:'Home'}">
          <img src="../assets/image/front/logo.svg" alt="VidUnit-logo" />
        </router-link>
      </div>
    </div>

    <div class="container">
        <router-view></router-view>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import FooterBar from "./authfooter.vue";
export default {
  components: {
    FooterBar
  },
}
</script>