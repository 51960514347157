<template>
    <img @click="onEdit(row)" src="../../../assets/image/edit.svg" alt="edit-img" class="edit-image">
</template>
<script>
export default {
    props: ["row"],
    methods: {
       onEdit(row) {
           this.$root.$emit('onrequestEdit',row);
       } 
    }
}
</script>