<template>
    <div class="campaigncalendar">
        <div class="box margino">
            <!-- <div class="box-header">

            </div> -->
            <div class="box-content">
                <calendar-component :role="'brand'" :events="events" :eventheight="eventheight" :eventContentHeight="eventContentHeight"></calendar-component>
            </div>
        </div>
    </div>    
</template>
<script>
import CalendarComponent from "../../../BasicComponents/calender/calender.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        CalendarComponent
    },
    computed: {
        ...mapGetters("influencercampaign", ["brandCampaignData"]),
    },
    watch: {
        brandCampaignData: {
            immediate: true,
            handler(brandCampaignData) {
                this.eventsData = brandCampaignData.live_campaigns.concat(brandCampaignData.past_campaigns,brandCampaignData.upcoming_campaigns);
            }
        }
    },
    methods: {
        ...mapActions("influencercampaign",["getbrandcampaignbydate"]),
    },
    created() {
        this.getbrandcampaignbydate()
        for (let i = 0; i < this.eventsData.length; i++) {
            // console.log(this.eventsData[i])
            this.events.push({
                "id": this.eventsData[i].id,
                "startDate": this.eventsData[i].start_date,
                "endDate": this.eventsData[i].end_date,
                "title": this.eventsData[i].campaign_name,
            });
        }
    },
    data() {
        return {
            h2txtclass: 'h2',
            eventheight: '71px',
            eventContentHeight: '40px',
            eventsData: [],
            events: []
        }
    }
}
</script>