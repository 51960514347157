<template>
    <div class="publisher-detail-page">
        <div class="content-header">
            <span class="h2">Publisher Details</span>
        </div>
        <div class="content">
            <personal-detail></personal-detail>
            <website-detail></website-detail>
        </div>
    </div>
</template>
<script>
import PersonalDetail from "./personaldetail.vue";
import WebsiteDetail from "./websitedetail.vue";
export default {
    components: {
        PersonalDetail,
        WebsiteDetail
    }
}
</script>