<template>
    <div>
        <badge :badgeclass="{'Success-Badge': (row.status=='1'),'Default-Badge': (row.status=='0')}">{{(row.status=='1')?'Active': 'Inactive'}}</badge>
    </div>
</template>

<script>
import Badge from '../../../../BasicComponents/Badge/Badge';
export default {
    components: {
        Badge,
    },
    props: ['row'],

    data() {
        return {
        }
    },
}
</script>