<template>
    <div class="graph">
        <graph-bar
                :width="width"
                :height="height"
                :padding-top="10"
                :padding-right="15"
                :padding-bottom="50"
                :padding-left="50"
                :axis-min="axismin"
                :axis-max="axismax"
                :axisStep="axisStep"
                :styles="styles"
                :labels="labels"
                :values="values"
                :barMargin="barMargin"
                :axis-reverse="axisreverse"
                :colors="['#F49342']"
                :format="format">
            <!-- <note :text="'Bar Chart'"></note> -->
            <tooltip :names="names" :position="'left'"></tooltip>
            <!-- <legends :names="names" :filter="false"></legends> -->
        </graph-bar>
    </div>
</template>
<script>
export default {
    props: 
    [
        "width",
        "height",
        "axismin",
        "axismax",
        "axisStep",
        "labels",
        "values",
        "names",
        "barMargin",
        "axisreverse",
        "format"
    ],
    data() {
        return {
            styles: 
            {
                gridXAxisBorderWidth: 1,
                gridYAxisBorderWidth: 1,
                barBorderRadius: 0,
                gridXAxisBorderColor: '#DFE3E8',
                gridYAxisBorderColor: '#DFE3E8',
                axisBackgroundColor: '#FFFFFF',
                fontFamily: 'regular',
                backgroundColor: '#FFFFFF',
                colors: '#212b36',
                gridXFontSize : 12,
                gridYFontSize : 12,
                gridXFontColor : "#212b36",
                gridYFontColor : "#212b36",
            }
        }
    }
}
</script>