<template>
  <div class="myprofile">
    <div class="content-header">
      <div class="page-header">
        <text-component :class="h2txtclass">My Profile</text-component>
      </div>
    </div>
    <div class="content">
      <progress-bar></progress-bar>
      <social-detail ref="socialDetail"></social-detail>
      <personal-detail></personal-detail>
      <change-password></change-password>
      <charge-detail></charge-detail>
      <bank-detail></bank-detail>
      <interest-detail></interest-detail>
      <social-insight v-if="socialInsight"></social-insight>
    </div>
  </div>
</template>
<script>
import ProgressBar from "./progress";
import PersonalDetail from "./personaldetail";
import ChargeDetail from "./chargesdetail";
import ChangePassword from "./changepassword";
import BankDetail from "./bankdetail";
import InterestDetail from "./interestdetail";
import SocialDetail from "./socialdetail";
import SocialInsight from "./socialinsight";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ProgressBar,
    PersonalDetail,
    ChargeDetail,
    ChangePassword,
    BankDetail,
    InterestDetail,
    SocialDetail,
    SocialInsight,
    TextComponent,
  },
  data() {
    return {
      h2txtclass: "h2",
      socialInsight: false,
    };
  },
  computed: {
    ...mapGetters("influencerprofile", ["socialData"]),
  },
  watch: {
    socialData: {
      immediate: true,
      handler(socialData) {
        if (socialData.length) {
          this.socialInsight = true;
        } else {
          this.socialInsight = false;
        }
      },
    },
  },
  // beforeDestroy(){
  //   confirm("Do you really want to delete?")
  // }
  beforeRouteLeave (to, from, next) {
    this.$refs.socialDetail.prepareToExit();
    next();
  }

};
</script>