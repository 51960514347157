<template>
    <div class="myprofile">
        <div class="content-header">
            <div class="page-header">
                <text-component :class="'h2'">Change Password</text-component>
            </div>
        </div>
        <div class="content">
            <div id="chngpassword" class="changepassword boxmargin">
                <form @submit.prevent="changePassword">
                    <div class="box margino">
                        <div class="box-content">
                            <div class="myprofile-header">
                                <text-component class="myprofile-header-txt" :class="subtitle1class">Change Password</text-component>
                            </div>
                            <div class="changepassword-editable">
                                <div class="row">
                                    <input-component
                                        required="true"
                                        class="txtgroup col-12 col-sm-6 col-md-4"
                                        v-model="item.currentpassword"
                                        :helptext="!$v.item.currentpassword.required && submitted ? validationmessage.currentpassword: false"
                                        :labelclass="{'validationerr': !$v.item.currentpassword.required && submitted}"
                                        :classname="{'error': !$v.item.currentpassword.required && submitted, 'default': true}"
                                        :type="passwordtype"
                                    >Current password</input-component>
                                    <input-component
                                        required="true"
                                        class="txtgroup col-12 col-sm-6 col-md-4"
                                        v-model="item.password"
                                        :helptext="!$v.item.password.required && submitted ? validationmessage.password: false"
                                        :labelclass="{'validationerr': !$v.item.password.required && submitted}"
                                        :classname="{'error': !$v.item.password.required && submitted, 'default': true}"
                                        :type="passwordtype"
                                    >New password</input-component>
                                    <input-component
                                        required="true"
                                        class="txtgroup col-12 col-sm-6 col-md-4"
                                        :helptext="passwordMsg()"
                                        :labelclass="{'validationerr': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted}"
                                        :classname="{'error': (!$v.item.confirmpassword.required || !$v.item.confirmpassword.sameAsPassword) && submitted, 'default': true}"
                                        type="password"
                                        v-model="item.confirmpassword"
                                    >Confirm password</input-component>
                                    </div>
                                <hr class="hrline-footer" />
                                <div class="width edit-footer">
                                    <button-component class="footer-btn" buttontype="submit" :clsname="primaryclass">Save</button-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import InputComponent from "../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import TextAreaComp from "../../../BasicComponents/Input/textarea.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import { required, sameAs } from "vuelidate/lib/validators";
import { mapGetters,mapActions } from "vuex";
export default {
    components: {
        InputComponent,
        TextComponent,
        // TextAreaComp,
        ButtonComponent
    },
    validations: {
        item: {
        password: {
            required
        },
        currentpassword: { required },
        confirmpassword: { required, sameAsPassword: sameAs("password") }
        }
    },
    data() {
        return {
            item: {
                password: null,
                confirmpassword: null,
                currentpassword: null
            },
            validationmessage: {
                currentpassword: "current password is required",
                password: "password is required",
                confirmpassword: "confirm password is required",
                sameAsPassword: "password did not match"
            },
            submitted: false,
            passwordtype: "password",
            classname: "default",
            subtitle1class: "subtitle1",
            primaryclass: "btnprimary",
        }
    },
    computed:{
        ...mapGetters("websitesetting",["settingsData"]),
    },
    watch:{
        settingsData:{
            immediate:true,
            handler(settingsData){
                // console.log('asd',settingsData.data.setting_data)
                this.settingData = settingsData.data.setting_data;
            }
        }
    },
    methods: {
        ...mapActions("websitesetting",["changepassword"]),

        passwordMsg() {
            if (!this.$v.item.confirmpassword.required && this.submitted) {
                return this.validationmessage.confirmpassword;
            } else if (
                !this.$v.item.confirmpassword.sameAsPassword &&
                this.submitted
            ) {
                return this.validationmessage.sameAsPassword;
            } else {
                return false;
            }
        },
        changePassword() {
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                this.changepassword({
                    old_password: this.item.currentpassword,
                    password: this.item.password,
                    password_confirmation: this.item.confirmpassword
                })
                .then(response => {
                    console.log(response);
                    this.submitted = false;
                    this.item.currentpassword = null;
                    this.item.password = null;
                    this.item.confirmpassword = null;
                    this.IsChangePasswordEditable = false;
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                    message: err.response.data.message,
                    type: "error",
                    duration: 5000,
                    dismissible: true,
                    position: "top"
                    });
                });
            }
        },
        submitSetting() {
            this.submitted = true;
            if (this.$v.settingData.$invalid) {
                this.$v.settingData.$touch();
            } else {
                console.log(this.settingData)
                this.updateSettingsData({
                    setting: this.settingData,
                }).then(response => {
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
    },

    // created() {
    //     // this.getSettingsData()   
    // }
}
</script>